import helpers from '@/helpers/helpers.js'
import moment from 'moment'

export default {
  retrieveSuite (state, suite) {
    suite.map((item) => {
      item.start = item.start ? moment(item.start).format('DD.MM.YYYY') : ''
      item.end = item.end ? moment(item.end).format('DD.MM.YYYY') : ''
    })
    state.suite = suite
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  },
  retrieveEdit (state, data) {
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) continue
      state.edit[helpers.kebabCaseToCamelCase(prop)] = (data[prop] === null) ? '' : data[prop]
    }
  },
  retrieveList (state, list) {
    state.list = list
  },
  removeChallenge (state, index) {
    state.suite.splice(index, 1)
  },
  copyChallenge (state, [index, item]) {
    state.suite.splice(index + 1, 0, item)
  }
}
