<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
    <feather-icon v-if="!isOnlyRead" icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="confirmDeleteRecord()" />
  </div>
</template>

<script>

export default {
  name: 'CellRendererActions',
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-announcements'] === 'read'
      }
    }
  },
  methods: {
    editRecord () {
      this.$store.commit('setListPage', this.params.api.paginationGetCurrentPage())
      this.$router.push(`${this.$route.path}/edit/${this.params.value}`).catch(() => {})
    },
    confirmDeleteRecord () {
      this.params.context.componentParent.deleteAnnouncementPopupActive = true
      this.params.context.componentParent.deleteAnnouncementPopupContent = this.$t('users.suite.popups.confirm_delete_user.message').replace('[EMAIL]', this.params.data.title)
      this.params.context.componentParent.deleteAnnouncementId = this.params.value
      this.params.context.componentParent.deleteAnnouncementPopupActive = true
    }
  }
}
</script>
