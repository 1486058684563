<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
    <feather-icon v-if="!isOnlyRead" icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="confirmDeleteRecord()" />
    <feather-icon  icon="DownloadIcon" svgClasses="h-4 w-4 mr-2 hover:text-primary cursor-pointer" @click="exportMission()" />
    <feather-icon v-if="!isOnlyRead" icon="LayersIcon" svgClasses="h-4 w-4 mr-2 hover:text-primary cursor-pointer" @click="exportMission(1)" />
  </div>
</template>

<script>

export default {
  name: 'CellRendererActions',
  methods: {
    exportMission (isExportBySequential = 0) {
      const payload = {
        id: this.params.value,
        isExportBySequential: isExportBySequential
      }
      this.$store.dispatch('mission/exportMission', payload).catch((error) => {
        error.response.data.text().then(text => {
          const errorData = JSON.parse(text)
          const errorMessage = errorData.data.data[0]
          this.$vs.notify({
            title: this.$t('notify.errors.title'),
            text: errorMessage,
            color: 'danger',
            time: 5000
          })
        })
      })
    },
    editRecord () {
      this.$store.commit('setListPage', this.params.api.paginationGetCurrentPage())
      this.$router.push(`${this.$route.path}/edit/${this.params.value}`).catch(() => {})
    },
    confirmDeleteRecord () {
      this.params.context.componentParent.deleteItemPopupActive = true
      this.params.context.componentParent.deleteItemPopupContent = this.$t('personal_goals.suite.popup.delete_title').replace('[item]', this.params.data.name)
      this.params.context.componentParent.deleteItemId = this.params.value
      this.params.context.componentParent.deleteItemPopupActive = true
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-missions'] === 'read'
      }
    }
  }
}
</script>
