import FormError from './../../modules/formError'

export default {
  suite: [],
  select: [],
  edit: {
    name: '',
    point_type: '',
    point: '',
    coins: '',
    plus_point: '',
    is_public: '',
    max_appends: ''
  },
  formError: new FormError()
}
