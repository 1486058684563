<template>
  <div class="h-full">
    <vs-navbar v-if="$route.params.id" type="gradient" v-model="activeItem" class="nabarx mailing-tabs-wrapper">
      <vs-navbar-item index="edit">
        <a href="#">{{ $t('mailings.edit.tabs.edit') }}</a>
      </vs-navbar-item>

      <vs-navbar-item index="users">
        <a href="#">{{ $t('mailings.edit.tabs.users') }}</a>
      </vs-navbar-item>
    </vs-navbar>

    <edit-tab v-show="activeItem==='edit'"></edit-tab>
    <users-tab v-show="activeItem==='users'" :is-active="activeItem==='users'"></users-tab>

  </div>
</template>

<script>
import EditTab from '../../components/mailing/EditTab.vue'
import UsersTab from '../../components/mailing/UsersTab.vue'

export default {
  components: {
    EditTab,
    UsersTab
  },
  data () {
    return {
      activeItem: 'edit'
    }
  }
}
</script>

<style lang="scss">
.mailing-tabs-wrapper {
  margin-bottom: 30px;
}
</style>
