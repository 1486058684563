<template>
  <section class="statistics">
    <h3 class="statistics-h3">{{ $t('dashboard.titles.courses_and_programs') }}</h3>
    <div class="card-statistic-grid" v-if="!loading">
      <div v-for="(value, key) in statisticsData" :key="key" class="card-statistic vx-col w-full flex">
        <statistics-card-line :chartData="value.series" :countData="value" type="area" :icon="statisticsData[key].icon"
          icon-left :statisticTitle="statisticsData[key].title"
          :color="statisticsData[key].color" :period="statisticsPeriod" diffPercent />
      </div>
    </div>
    <div class="top-section" v-if="!loading">
      <vx-card v-if="topActiveUsers">
        <div>
          <h4 class="top-title">{{ $t('dashboard.titles.top_active_users') }}</h4>
          <ol class="">
            <li class="top-item">
              <span class="top-thead">{{ $t('dashboard.titles.users') }}</span>
              <span class="top-thead text-right">{{ $t('dashboard.titles.points') }}</span>
            </li>
            <li class="top-item" v-for="(item, index) of topActiveUsers" :key="item.user_id">
              <div class="top-item__name">
                <span class="place">{{ index + 1 }}</span>
                <img class="top-item__img rounded-full bg-grey" :src="
                  item.avatar_path_thumbnail ? getUrl(item.avatar_path_thumbnail) : require('@/assets/images/user/avatar/default.svg')
                " />
                <div class="flex-column">
                  <span class="top-item__text">{{ item.name }}</span>
                  <span class="top-item__type">{{ item.city }}</span>
                </div>
              </div>
              <span class="top-item__stat">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.80496 10.9156C1.25629 10.2897 0.707638 9.63878 0.358471 8.86266C0.00932714 8.03648 -0.0904382 7.08513 0.0841452 6.18384C0.258729 5.28255 0.657743 4.43133 1.23136 3.7053C1.18149 4.3312 1.40595 4.98213 1.82991 5.45781C1.58051 4.2561 1.97955 2.95423 2.7776 2.00288C3.57565 1.05153 4.673 0.4256 5.8202 0C5.2466 0.600858 5.17178 1.55221 5.39623 2.35337C5.62069 3.15451 6.09454 3.85552 6.51851 4.58154C6.94247 5.30757 7.34151 6.05865 7.36645 6.88483C7.59091 6.45923 7.84031 6.0336 7.98995 5.55794C8.13958 5.08225 8.2144 4.58155 8.08971 4.10586C8.53862 4.65665 8.78802 5.20742 8.91271 5.90843C9.0374 6.60944 9.01248 7.33547 8.93766 8.03648C8.83789 8.8126 8.63838 9.61374 8.21442 10.2647C7.71564 11.0408 6.91757 11.5915 6.0447 11.892C4.49842 12.1674 2.97711 11.9421 1.80496 10.9156Z" fill="#F74D30"/>
                  <path d="M3.70035 11.8423C5.74536 12.6435 7.76545 10.215 6.64318 8.3373C6.64318 8.31225 6.61823 8.31225 6.61823 8.28723C6.718 9.31369 6.46859 10.0648 5.99474 10.4904C6.24414 9.88953 6.06956 9.18852 5.77028 8.58766C5.47101 8.01185 5.04705 7.48609 4.72283 6.9353C4.39861 6.35949 4.14923 5.70854 4.29887 5.05762C3.70032 5.50826 3.27636 6.18423 3.10178 6.91026C2.92719 7.63629 3.02696 8.43745 3.35118 9.11341C2.97709 8.83803 2.70276 8.38738 2.67781 7.91169C2.27878 8.38738 2.0294 9.01326 2.05432 9.63916C2.05435 10.6156 2.82745 11.4918 3.70035 11.8423Z" fill="#FFD15C"/>
                </svg>
                <span>{{ (+item.sum).toLocaleString() }}</span>
              </span>
            </li>

          </ol>
        </div>
      </vx-card>
      <vx-card v-if="topSociableUsers">
        <div>
          <h4 class="top-title">{{ $t('dashboard.titles.top_sociable_users') }}</h4>
          <ol class="">
            <li class="top-item">
              <span class="top-thead">{{ $t('dashboard.titles.users') }}</span>
              <span class="top-thead text-right">{{ $t('dashboard.titles.messages') }}</span>
            </li>
            <li class="top-item" v-for="(item, index) of topSociableUsers" :key="item.id">
              <div class="top-item__name">
                <span class="place">{{ index + 1 }}</span>
                <img class="top-item__img rounded-full bg-grey" :src="
                  item.avatar_path_thumbnail ? getUrl(item.avatar_path_thumbnail) : require('@/assets/images/user/avatar/default.svg')
                " />
                <div class="flex-column">
                  <span class="top-item__text">{{ item.name }}</span>
                  <span class="top-item__type">{{ item.city }}</span>
                </div>
              </div>
              <span class="top-item__stat">
                <span>{{ (+item.count_messages).toLocaleString() }}</span>
              </span>
            </li>

          </ol>
        </div>
      </vx-card>
      <vx-card v-if="topActiveTeams">
        <div>
          <h4 class="top-title">{{ $t('dashboard.titles.top_active_teams') }}</h4>
          <ol class="">
            <li class="top-item">
              <span class="top-thead">{{ $t('dashboard.titles.teams') }}</span>
              <span class="top-thead text-right">{{ $t('dashboard.titles.points') }}</span>
            </li>
            <li class="top-item my-2" v-for="(item, index) of topActiveTeams" :key="item.id">
              <div class="top-item__name">
                <span class="place mr-4">{{ index + 1 }}</span>
                <div class="flex-column">
                  <span class="top-item__text">{{ item.name }}</span>
                  <!-- <span class="top-item__type">{{ item.city }}</span> -->
                </div>
              </div>
              <span class="top-item__stat">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.80496 10.9156C1.25629 10.2897 0.707638 9.63878 0.358471 8.86266C0.00932714 8.03648 -0.0904382 7.08513 0.0841452 6.18384C0.258729 5.28255 0.657743 4.43133 1.23136 3.7053C1.18149 4.3312 1.40595 4.98213 1.82991 5.45781C1.58051 4.2561 1.97955 2.95423 2.7776 2.00288C3.57565 1.05153 4.673 0.4256 5.8202 0C5.2466 0.600858 5.17178 1.55221 5.39623 2.35337C5.62069 3.15451 6.09454 3.85552 6.51851 4.58154C6.94247 5.30757 7.34151 6.05865 7.36645 6.88483C7.59091 6.45923 7.84031 6.0336 7.98995 5.55794C8.13958 5.08225 8.2144 4.58155 8.08971 4.10586C8.53862 4.65665 8.78802 5.20742 8.91271 5.90843C9.0374 6.60944 9.01248 7.33547 8.93766 8.03648C8.83789 8.8126 8.63838 9.61374 8.21442 10.2647C7.71564 11.0408 6.91757 11.5915 6.0447 11.892C4.49842 12.1674 2.97711 11.9421 1.80496 10.9156Z" fill="#F74D30"/>
                  <path d="M3.70035 11.8423C5.74536 12.6435 7.76545 10.215 6.64318 8.3373C6.64318 8.31225 6.61823 8.31225 6.61823 8.28723C6.718 9.31369 6.46859 10.0648 5.99474 10.4904C6.24414 9.88953 6.06956 9.18852 5.77028 8.58766C5.47101 8.01185 5.04705 7.48609 4.72283 6.9353C4.39861 6.35949 4.14923 5.70854 4.29887 5.05762C3.70032 5.50826 3.27636 6.18423 3.10178 6.91026C2.92719 7.63629 3.02696 8.43745 3.35118 9.11341C2.97709 8.83803 2.70276 8.38738 2.67781 7.91169C2.27878 8.38738 2.0294 9.01326 2.05432 9.63916C2.05435 10.6156 2.82745 11.4918 3.70035 11.8423Z" fill="#FFD15C"/>
                </svg>
                <span>{{ (+item.points).toLocaleString() }}</span>
              </span>
            </li>

          </ol>
        </div>
      </vx-card>
      <vx-card v-if="topCoursesAndChallenges">
        <div>
          <h4 class="top-title">{{ $t('dashboard.titles.top_courses_and_challenges') }}</h4>
          <ol class="">
            <li class="top-item">
              <span class="top-thead">{{ $t('dashboard.titles.courses_and_challenges') }}</span>
              <span class="top-thead text-right">{{ $t('dashboard.titles.participants') }}</span>
            </li>
            <li class="top-item" v-for="(item, index) of topCoursesAndChallenges" :key="item.id">
              <div class="top-item__name">
                <span class="place">{{ index + 1 }}</span>
                <img v-img="{src: item.image_path ? getUrl(item.image_path) : ''}" class="top-item__img rounded-lg bg-grey" :src=" item.image_path_thumbnail ? getUrl(item.image_path_thumbnail) : require('@/assets/images/pages/not-img.png')
                " />
                <div class="flex-column">
                  <span class="top-item__text">{{ item.name }}</span>
                  <span class="top-item__type">{{ $t(`dashboard.titles.${item.type}`) }}</span>
                </div>
              </div>
              <span class="top-item__stat">
                <span>{{ (+item.count).toLocaleString() }}</span>
              </span>
            </li>

          </ol>
        </div>
      </vx-card>
    </div>
    <vx-card v-if="topReports" class="mt-8">
      <h4 class="top-title">{{ $t('dashboard.titles.top_reports') }}</h4>
      <div class="top-row">
        <div class="top-row__item row-item" v-for="(item) of topReports" :key="item.id">
          <div class="row-item__name">
            <img class="row-item__avatar rounded-full bg-grey object-cover" :src="
              item.avatar_path_thumbnail ? getUrl(item.avatar_path_thumbnail) : require('@/assets/images/user/avatar/default.svg')
            " />
            <div class="flex-column">
              <span class="row-item__title">{{ item.name }}</span>
              <span class="row-item__info">{{ item.city }}</span>
            </div>
          </div>
          <img v-img="{src: item.photo_path ? getUrl(item.photo_path) : ''}" class="row-item__img rounded-lg bg-grey" :src="
              item.photo_path ? getUrl(item.photo_path) : require('@/assets/images/pages/not-img.png')
            " />
          <div class="row-item__tag bg-violet-100 text-violet rounded-2xl">{{ item.clubs }}</div>
          <p class="row-item__comment">{{ item.comment }}</p>
          <div v-if="item.count_likes" class="count-likes flex items-center">
            <feather-icon icon="HeartIcon" svgClasses="like-icon mr-1" />
            <span class="text-lg">{{ +item.count_likes.toLocaleString() }}</span>
          </div>
        </div>
      </div>
    </vx-card>

  </section>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import loading from '@/views/components/Loading.vue'

export default {
  name: 'courses-and-programs',
  props: ['statisticsPeriod'],
  components: {
    StatisticsCardLine,
    loading
  },
  data () {
    return {
      loading: true,
      chartData: [],
      statisticsData: {
        programs: {
          icon: 'Book',
          title: this.$t('dashboard.titles.programs'),
          color: '#299AFF'
        },
        challenges: {
          icon: 'Challenge',
          title: this.$t('dashboard.titles.challenges'),
          color: '#2CB573'
        },
        completedTrainings: {
          icon: 'Clipboard',
          title: this.$t('dashboard.titles.total_tasks'),
          color: '#7367F0'
        }
      }
    }
  },
  methods: {

    getPeriodStatisticsData (statistics) {
      const dataByPeriod = {
        [this.$t('dashboard.labels.month')]: statistics.for_month,
        [this.$t('dashboard.labels.year')]: statistics.for_year,
        [this.$t('dashboard.labels.all_time')]: statistics.for_all_time
      }
      return dataByPeriod[this.statisticsPeriod] || statistics.for_month
    },
      

    fetchData (dataName, dispatchName, seriesName) {
      this.$store.dispatch(dispatchName).then(() => {
        this.statisticsData[dataName] = { ...this.statisticsData[dataName], ...this.$store.getters[`statistics/${dataName}`] }
        this.statisticsData[dataName].series[0].name = seriesName
      })
    }
  },

  computed: {
    topActiveUsers () {
      const topActiveUsers = this.$store.getters['statistics/topActiveUsers']
      return this.getPeriodStatisticsData(topActiveUsers)
    },
    topSociableUsers () {
      const topSociableUsers = this.$store.getters['statistics/topSociableUsers']
      return this.getPeriodStatisticsData(topSociableUsers)
    },
    topActiveTeams () {
      const topActiveTeams = this.$store.getters['statistics/topActiveTeams']
      return this.getPeriodStatisticsData(topActiveTeams)
    },
    topCoursesAndChallenges () {
      const topCoursesAndChallenges = this.$store.getters['statistics/topCoursesAndChallenges']
      return this.getPeriodStatisticsData(topCoursesAndChallenges)
    },
    topReports () {
      const topReports = this.$store.getters['statistics/topReports']
      return this.getPeriodStatisticsData(topReports)
    }
  },

  created () {
    const fetchPrograms = this.fetchData('programs', 'statistics/getPrograms', this.statisticsData.programs.title)
    
    const fetchChallenges = this.fetchData('challenges', 'statistics/getChallenges', this.statisticsData.challenges.title)

    const fetchCompletedTrainings = this.fetchData('completedTrainings', 'statistics/getCompletedTrainings', this.statisticsData.completedTrainings.title)

    const dispatches = [
      this.$store.dispatch('statistics/getTopActiveUsers'),
      this.$store.dispatch('statistics/getTopSociableUsers'),
      this.$store.dispatch('statistics/getTopActiveTeams'),
      this.$store.dispatch('statistics/getTopCoursesAndChallenges'),
      this.$store.dispatch('statistics/getTopReports')
    ]

    Promise.all([fetchPrograms, fetchChallenges, fetchCompletedTrainings, ...dispatches]).then(() => {
      this.loading = false
    })
  
  }
}
</script>

<style lang="scss" scoped>
.card-statistic-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  
  gap: 24px;
  margin-bottom: 24px;
}

.top-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 24px;

  & > div {
    max-height: 463px;
    overflow-y: auto;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    scroll-padding-top: 10px;

    &::-webkit-scrollbar {
      width: 7px;
      background-color: #f9f9fd;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #7367f0;
      border-radius: 5px;
    }
  }
}

.top-title {
  position: sticky;
  top: 0px;
  margin-top: -21px;
  padding-top: 21px;
  padding-bottom: 16px;
  background: #fff;
  z-index: 1;
  font-size: 18px;
  font-weight: 400;

}

.top-item {
  padding: 16px 24px 16px 0;
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;

  &:not(:last-child) {
    border-bottom: 0.5px solid #C2C2C2;
  }

  &:first-child {
    padding-top: 0;
  }

  .top-thead {
    font-size: 12px;
    color: #626262;
  }

  &:first-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    display: flex;
    align-items: center;

    color: #2C2C2C;
  }

  &__img {
    width: 40px;
    height: 40px;
    margin: 0 8px 0 13px;
  }

  &__type {
    font-weight: 400;
    font-size: 12px;
  }

  &__stat {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;

    svg {
      margin-right: 4px;
      margin-bottom: 2px;
    }
  }
}


.list {

  &__item:not(:last-child) {
    border-bottom: 0.5px solid #C2C2C2;
  }
}

.item-list {

  padding: 16px 0;

  &__user {
    h5 {
      font-size: 12px;
    }
  }

  &__post {}
}

.user-block {

  &__img {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
  }

  &__body {}

  &__city {}
}

.post-block {
  display: flex;
  align-items: stretch;
  font-size: 12px;

  &__img {
    width: 62px;
    height: 62px;
  }

  &__tag {
    display: inline-block;
    width: fit-content;
    line-height: 14px;
    margin: 0px 0px 5px 0px;
    padding: 2px 8px;
    font-size: 12px;
  }

  &__comment {
    font-weight: 600;
  }
}

.top-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  gap: 16px;
  & > div {
    flex: 0 1 185px;
  }
}
.row-item {
  display: flex;
  flex-direction: column;
  &__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 12px;
    min-height: 60px;

    display: flex;
    align-items: center;

    color: #2C2C2C;

  }

  &__avatar {
    margin-right: 8px;
    width: 32px;
    height: 32px;
  }

  &__info {
    font-weight: 400;
    font-size: 12px;
  }

  &__img {
    width: 180px;
    height: 180px;
    object-fit: cover;
    margin-bottom: 16px;
  }

  &__tag {
    display: inline-block;
    width: fit-content;
    line-height: 14px;
    margin: 0px 0px 8px 0px;
    padding: 2px 8px;
    font-size: 12px;
  }

  &__comment {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.count-likes {
  align-self: self-end;
  margin-right: auto;
  margin-top: auto;
  padding-top: 16px;
}

.like-icon {
  color: red;
  fill: red;
  width: 14px;
  height: 14px;
  margin-right: 6px !important;
}
</style>