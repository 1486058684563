import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'

export default {

  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/clubs')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/clubs/${payload.id}/edit`, { params: payload })
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  list ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/clubs/list')
        .then((response) => {
          commit('retrieveList', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addDefaultClub ({ commit }) {
    return commit('addElement', 'Без клуба')
  },
  create ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/clubs', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  update ({ commit }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    formData.append('_method', 'PATCH')
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/clubs/${payload.id}`, formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  sort ({ commit }, payload) {
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    formData.append('_method', 'PATCH')
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/clubs/sort', formData)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
