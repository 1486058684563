import helpers from '@/helpers/helpers.js'

export default {
  retrieveSuite (state, suite) {
    state.suite = suite
  },
  retrieveGroupsOptions (state, groupsOptions) {
    state.groupsOptions = groupsOptions
  },
  retrieveEdit (state, data) {
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) continue
      state.edit[helpers.kebabCaseToCamelCase(prop)] = (data[prop] === null) ? '' : data[prop]
    }
  },
  removeServiceById (state, id) {
    const index = helpers.searchInArray('id', id, state.suite, true)
    state.suite.splice(index, 1)
  },

  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  }
}

