<template>
  <div id="demo-basic-card">
    <div class="flex flex-wrap justify-evenly gap-6">

      <article v-for="template in templates" :key="template.name"
        class="flex flex-col bg-white shadow rounded-lg p-6 sm:w-1/2 lg:w-1/3 min-w-sm max-w-sm basis-72 flex-1">
        <h1 class="font-bold text-base pb-2">{{ $t(`categories.template.${template.name}.name`) }}</h1>
        <p class="text-gray-700 text-xs text-[#626262] mb-8">{{ $t(`categories.template.${template.description}`) }}</p>
        <router-link :to="goToTemplate(template)"
          class="link-btn mt-auto ml-auto rounded-lg py-3 px-6 text-center font-bold bg-white text-orange cursor-pointer border-orange border-solid text-base self-start">{{ $t('categories.template.buttons.choose') }}</router-link>
      </article>

    </div>
  </div>
</template>

<script>

export default {

  data () {
    return {
      prevRoute: null,
      isEdit: false
    }
  },

  computed: {
    templates () {
      return this.$store.getters['category/typeProgram']
    }
  },

  methods: {
    goToTemplate (template) {
      return this.isEdit ? {path: this.prevRoute.path, query: { type: template.name } } : { name: 'create-programm', query: { type: template.name } }
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.isEdit = false
      if (from.name === 'edit-programm') {
        vm.prevRoute = from
        vm.isEdit = true
      } 
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
