<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading></loading>
      </div>
    </div>
    <div v-show="!loading">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col flex w-full md:w-1/4 mb-4 items-center">
          <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
          <label class="ml-4 text-lg" :class="{'text-orange': isActive}">{{ $t('pedometer.challenges.' + type + '.labels.is_active') }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active')) }}</span>
        </div>
        <div class="vx-col flex w-full md:w-3/4 mb-6 justify-end">
          <label class="mx-4 text-grey font-bold" :class="[!isPickable ? ' text-orange' : '']">{{
            $t('personal_goals.suite.title.is_pickable.false')
          }}</label>
          <vs-switch v-model="isPickable" class="is-pickable-switch" icon-pack="feather" vs-icon="icon-check"></vs-switch>
          <label class="ml-4 text-grey font-bold" :class="[isPickable ? ' text-orange' : '']">{{
            $t('personal_goals.suite.title.is_pickable.true')
          }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active')) }}</span>
        </div>
      </div>

      <content-filter :targetId="id" :targetModel="'pedometer_challenge'"></content-filter>

      <div class="vx-card tabs-container px-6 pt-6 pb-3">
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full" :label-placeholder="$t('pedometer.challenges.' + type + '.labels.title')" v-model="title" />
            <span class="text-danger text-sm" v-show="formError.has('title')">{{ $t(formError.get('title')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <v-select
              v-model="tags"
              multiple
              :placeholder="$t('categories.edit.labels.tags')"
              :closeOnSelect="false"
              :options="tagsOptions"
              :selectable="option => tags.indexOf(option) < 0"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
            <span class="text-danger text-sm" v-show="formError.has('tags')">{{ $t(formError.get('tags')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <v-select
              v-model="segments"
              multiple
              :placeholder="$t('segments.suite.title')"
              :closeOnSelect="false"
              :options="segmentsOptions"
              :selectable="option => segments.indexOf(option) < 0"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
            <span class="text-danger text-sm" v-show="formError.has('segments')">{{ $t(formError.get('segments')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <small>
              {{ $t('pedometer.challenges.' + type + '.labels.challenge_type') }}
            </small>

            <v-select v-model="challengeType" :options="challengeTypesDigest" label="text" />
            <span class="text-danger text-sm" v-show="formError.has('challenge_type')">{{ $t(formError.get('challenge_type')) }}</span>
          </div>
        </div>
        <div v-if="challengeType !== null && (challengeType === 'goal' || challengeType.value === 'goal')" class="vx-row">
          <div class="vx-col w-full mb-2">
            <v-select
              v-model="activities"
              multiple
              :placeholder="$t('categories.edit.labels.activities')"
              :closeOnSelect="false"
              :options="activitiesOptions"
              :selectable="option => activities.indexOf(option) < 0"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
            <span class="text-danger text-sm" v-show="formError.has('activities')">{{ $t(formError.get('activities')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <datepicker
              class="w-full mt-5"
              :format="dateFormat"
              v-model="start"
              :placeholder="$t('pedometer.challenges.' + type + '.labels.start')"
              :language="datepicker.locales[$i18n.locale]"
              :monday-first="$i18n.locale === 'ru' ? true : false"
            ></datepicker>
            <span class="text-danger text-sm" v-show="formError.has('start')">{{ $t(formError.get('start')) }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-4">
            <datepicker
              class="w-full mt-5"
              :format="dateFormat"
              v-model="end"
              :placeholder="$t('pedometer.challenges.' + type + '.labels.end')"
              :language="datepicker.locales[$i18n.locale]"
              :monday-first="$i18n.locale === 'ru' ? true : false"
              ref="endDatepicker"
              :clear-button="true"
              :clear-button-icon="''"
            ></datepicker>
            <span class="text-danger text-sm" v-show="formError.has('end')">{{ $t(formError.get('end')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <vs-input
              type="number"
              class="w-full mb-4"
              :label="$t('pedometer.challenges.' + type + '.labels.count_steps')"
              v-model="countSteps"
            />
            <span class="text-danger text-sm" v-show="formError.has('count_steps')">{{ $t(formError.get('count_steps')) }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <vs-input
              type="number"
              class="w-full mb-4"
              :label="$t('pedometer.challenges.' + type + '.labels.coins')"
              v-model="coins"
            />
            <span class="text-danger text-sm" v-show="formError.has('coins')">{{ $t(formError.get('coins')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-4">
            <label>{{ $t('pedometer.challenges.' + type + '.labels.is_team') }}</label>
            <vs-switch v-model="isTeam" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
            <span class="text-danger text-sm" v-show="formError.has('is_team')">{{ $t(formError.get('is_team')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input type="text" class="w-full mb-4" :label="$t('pedometer.challenges.' + type + '.labels.announcement')" v-model="announcement" />
          </div>
        </div>

        <div class="vx-row" v-if="loaded">
          <div class="vx-col w-full mb-2">
            <vx-card no-shadow card-border :title="$t('pedometer.challenges.' + type + '.labels.content')">
              <editor ref="editor" :config="editorJsConfig" />
              <span class="text-danger text-sm" v-show="formError.has('content')">{{ $t(formError.get('content')) }}</span>
            </vx-card>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-container" style="width: 150px; height: 150px; margin: 0 auto">
              <picture-input
                v-show="!loading"
                ref="pictureInput"
                @prefill="onImgPrefill"
                @change="onImgChange"
                @error="onImgError"
                width="150"
                height="150"
                margin="16"
                :accept="this.$baseImageAcceptTypes"
                buttonClass="btn"
                size="10"
                :hideChangeButton="true"
                :crop="false"
                :prefill="imageUrl"
                :alertOnError="false"
                :customStrings="{
                  drag: $t('pedometer.challenges.' + type + '.labels.image'),
                }"
              >
              </picture-input>
              <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
            </div>
          </div>
        </div>
        <div v-if="imgCrop" class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-crop-container">
              <cropper
                ref="cropper"
                classname="cropper"
                :src="imgCrop"
                :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }"
                @change="changeCropImg"
                :stencil-props="{
                  aspectRatio: 3 / 2
                }"
              ></cropper>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row fixed-buttons">
      <div class="vx-col w-full flex gap-3">
        <vs-button 
          @click="action(stayAfter = true)" 
          :disabled="actionButtonDisabled || isOnlyRead">
          {{ $t('pedometer.challenges.' + type + '.buttons.' + type) }}
        </vs-button>

        <vs-button 
          @click="action()" 
          :disabled="actionButtonDisabled || isOnlyRead">
          {{ $t('pedometer.challenges.' + type + '.buttons.' + type) }} {{ $t('suite.and_exit') }}
        </vs-button>
      </div>
    </div>
    <vs-popup class="holamundo" title="" :active.sync="ErrorParsePopupActive">
      <p>{{ $t('pedometer.challenges.edit.error_parse.content') }}</p>
      <div class="error-parse-button-confirm">
        <vs-button color="warning" type="filled" @click="ErrorParsePopupActive=false">{{ $t('pedometer.challenges.edit.error_parse.button_confirm') }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { en, ru } from 'vuejs-datepicker/dist/locale'
import helpers from '@/helpers/helpers.js'
import moment from 'moment'
import loading from '@/views/components/Loading.vue'
import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import editorJsConfig from '@/editorjs/config.js'
import vSelect from 'vue-select'
import ContentFilter from '@/views/components/contentFilter.vue'

export default {
  components: {
    PictureInput,
    Cropper,
    Datepicker,
    vSelect,
    loading,
    ContentFilter
  },
  data () {
    return {
      ErrorParsePopupActive: false,
      today: helpers.today('-'),
      dateFormat: 'dd.MM.yyyy',
      datepicker: {
        locales: {
          en,
          ru
        }
      },
      id: 0,
      start: null,
      end: null,
      countSteps: null,
      coins: 0,
      challengeType: null,
      challengeTypesDigest: [],
      isActive: false,
      isPickable: true,
      isTeam: false,
      type: 'create',
      actionButtonDisabled: false,
      loading: true,
      title: '',
      content: '',
      announcement: '',
      segments: [],
      segmentsOptions: [],
      tags: [],
      tagsOptions: [],
      activities: [],
      activitiesOptions: [],
      image: null,
      imageUrl: '',
      imgCrop: null,
      imgWidth: null,
      imgHeight: null,
      isChangedImg: false,
      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error',
      editorJsConfig: Object.assign({}, editorJsConfig),
      loaded: false
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-pedometer'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['pedometer_challenge/formError']
    }
  },
  methods: {
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'pedometer_challenge/store' : 'pedometer_challenge/update'
      this.dispatch(patch, stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        const blockDescription = await this.$refs.editor.state.editor.save()
        const payload = {
          id: this.id,
          start: moment(this.start).format('DD.MM.YYYY'),
          end: this.end === null ? null : moment(this.end).format('DD.MM.YYYY'),
          count_steps: this.countSteps,
          coins: this.coins,
          challenge_type: this.challengeType ? this.challengeType.value : null,
          is_active: +this.isActive,
          is_pickable: +this.isPickable,
          is_team: +this.isTeam,
          title: this.title,
          segments: this.segments,
          tags: this.tags,
          activities: this.activities,
          content: JSON.stringify(blockDescription),
          announcement: this.announcement,
          image:
            (this.isChangedImg && this.image !== null) ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : null
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name: 'pedometer-challenges'
          },
          routerPushToEdit: {
            name: 'pedometer-edit-challenge'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    },
    onImgPrefill () {
      this.imgCrop = this.$refs.pictureInput.image
      this.image = this.imgCrop
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    changeCropImg ({coordinates, canvas}) {
      if (!this.imgWidth && !this.imgHeight) {
        this.imgWidth = coordinates.width
        this.imgHeight = coordinates.height
      }
      if ((coordinates.width === this.imgWidth || coordinates.height === this.imgHeight) && this.type === 'edit' && this.imageUrl) {
        this.isChangedImg = false
      } else {
        this.isChangedImg = true
      }
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    }
  },
  created () {
    this.$store.dispatch('tag/getTagsOptions').then(() => {
      this.tagsOptions = this.$store.getters['tag/tagsOptions']
    })

    this.$store.dispatch('activity/getActivitiesOptions').then(() => {
      this.activitiesOptions = this.$store.getters['activity/activitiesOptions']
    })

    this.$store.dispatch('segment/getSegmentsOptions').then(() => {
      this.segmentsOptions = this.$store.getters['segment/segmentsOptions']
    })

    this.id = this.$route.params.id
    if (this.$router.currentRoute.name === 'pedometer-edit-challenge') {
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('pedometer_challenge/edit', { id: this.id }).then(() => {
        const edit = this.$store.getters['pedometer_challenge/edit']
        this.start = new Date(edit.start)
        this.end = edit.end === '' ? null : new Date(edit.end)
        this.countSteps = edit.countSteps
        this.coins = edit.coins
        this.challengeType = edit.challengeTypeAdmin
        this.challengeTypesDigest = edit.challengeTypesDigest
        this.isActive = edit.isActive
        this.isPickable = edit.isPickable
        this.isTeam = edit.isTeam
        this.id = edit.id
        this.loading = false
        this.segments = edit.segments
        this.tags = edit.tags
        this.tags.map((tag) => {
          tag.id = tag.value
        })
        this.activities = edit.activities
        this.loaded = true
        this.title = edit.title
        this.content = edit.content
        this.announcement = edit.announcement
        if (this.content !== '') {
          try {
            this.editorJsConfig.data = JSON.parse(edit.content)
          } catch (e) {
            this.ErrorParsePopupActive = true
            this.editorJsConfig.data = ''
          }
        }
        this.imageUrl = this.getUrl(edit.imagePath, `${this.$http.defaults.baseURL}/api/admin-panel`)
      })
    } else {
      this.$store.dispatch('pedometer_challenge/create').then(() => {
        const edit = this.$store.getters['pedometer_challenge/edit']
        this.challengeTypesDigest = edit.challengeTypesDigest
      })

      this.loading = false
      this.loaded = true
    }
    this.$store.commit('pedometer_challenge/clearFormError')
  }
}
</script>

<style>
.error-parse-button-confirm {
  margin-top: 20px;
}
</style>
