export default {
  suite (state) {
    return state.suite
  },
  list (state) {
    return state.list
  },
  editData (state) {
    return state.editData
  },
  editPointData (state) {
    return state.editPointData
  },
  points (state) {
    return state.points
  },
  coins (state) {
    return state.coins
  },
  steps (state) {
    return state.steps
  },
  challenges (state) {
    return state.challenges
  },
  userData (state) {
    return state.userData
  },
  formError (state) {
    return state.formError
  },
  trainings (state) {
    return state.trainings
  },
  adminAccesses (state) {
    return state.adminAccesses
  },
  adminAccessesModules (state) {
    return state.adminAccessesModules
  }
}
