export default {
  suite (state) {
    return state.suite
  },
  catalogTree (state) {
    return state.catalogTree
  },
  getProduct (state) {
    return state.product
  },
  formError (state) {
    return state.formError
  }
}
