<!-- =========================================================================================
  File Name: Teams.vue
  Description: Teams List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="demo-basic-card">
    <div v-if="!isOnlyRead" class="upload-excel-users-container">
      <div class="excel-file-buttons-container">
        <vs-button
          color="primary"
          type="gradient"
          icon-pack="feather"
          icon="icon-plus"
          class="add-button"
          @click="$router.push({ name: 'teams-create' })"
        ></vs-button>
        <vs-button color="primary" type="filled" @click="$refs.file.click()"
          >{{ $t('teams.suite.buttons.upload_file') }} <font-awesome-icon :icon="['fas', 'file-excel']"
        /></vs-button>
        <vs-button color="primary" type="filled" @click="downloadTeamsFile"
          >{{ $t('teams.suite.buttons.download') }} <font-awesome-icon :icon="['fas', 'download']"
        /></vs-button>

        <input type="file" ref="file" style="display: none" @change="previewFile" />
          <vs-button 
            color="primary" 
            type="border" 
            icon-pack="feather" 
            icon="icon-list" 
            @click="showSortTable = !showSortTable"
          >
          {{$t('activities.sort')}}
          </vs-button>
      </div>

      <vs-alert :active.sync="showFileName" closable icon-pack="feather" close-icon="icon-x">
        {{ fileName }}
      </vs-alert>
      <div class="demo-alignment" v-if="showUploadButtons">
        <vs-button color="success" type="filled" icon-pack="feather" icon="icon-upload" @click="uploadUsersFile('add')"></vs-button>
      </div>
      <div v-if="uploading">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>
    <vx-row>
      <loading v-if="loading"></loading>
      <div class="vx-card p-6" v-if="!showSortTable && !loading">
        <!-- AgGrid Table -->
        <ag-grid-vue
          @gridReady="onGridReady"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4"
          domLayout="autoHeight"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="suite"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
        >
        </ag-grid-vue>

        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </div>

      <div v-if="showSortTable&&!loading" class="sort-table-wrapper">
        <vs-list>
          <draggable :list="suite" @change="sort()">
            <transition-group>
              <vs-list-item class="list-item" v-for="activity in suite" :key="activity.id" :title="activity.name">
              </vs-list-item>
            </transition-group>
          </draggable>
        </vs-list>
      </div>

      <vs-popup class="holamundo" :title="$t('teams.suite.popups.confirm_delete.title')" :active.sync="deleteTeamPopupActive">
        <p>{{ deleteTeamPopupContent }}</p>
        <div v-if="deleteProcess">
          <font-awesome-icon icon="spinner" spin />
        </div>
        <div class="delete-confirm-popup-buttons" v-else>
          <vs-button color="primary" type="filled" @click="deleteTeam()">{{
            $t('teams.suite.popups.confirm_delete.buttons.confirm')
          }}</vs-button>
          <vs-button color="warning" type="filled" @click="deleteTeamPopupActive = false">{{
            $t('teams.suite.popups.confirm_delete.buttons.cancel')
          }}</vs-button>
        </div>
      </vs-popup>
    </vx-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererLink from '../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../components/cell-renderer/teams/CellRendererActions.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)

export default {
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,

    loading,
    draggable
  },
  data () {
    return {
      loading: true,
      // AgGrid
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      showSortTable: false,
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        headerClass: 'text-center',
        sortable: false
      },
      showFileName: false,
      fileName: '',
      file: null,
      showUploadButtons: false,
      uploading: false,
      columnDefs: [
        {
          headerName: this.$t('teams.table.id'),
          field: 'id',
          minWidth: 70,
          maxWidth: 100
        },
        {
          headerName: this.$t('teams.table.name'),
          field: 'name',
          minWidth: 300,
          maxWidth: 800,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'justify-content': 'flex-start', 'word-break': 'break-word'},
          headerClass: 'text-left',
          filter: true
        },
        {
          headerName: this.$t('teams.table.created_at'),
          field: 'created_at',
          minWidth: 150,
          maxWidth: 170
        },
        {
          headerName: this.$t('teams.table.actions'),
          field: 'id',
          minWidth: 110,
          maxWidth: 130,
          cellRenderer: 'CellRendererActions'
        }
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deleteTeamPopupActive: false,
      deleteTeamPopupContent: '',
      deleteProcess: false,
      deleteTeamId: 0
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-settings-teams'] === 'read' ||  
               accessUser['admin-settings'] === 'read'
      }
    },
    suite () {
      return this.$store.getters['team/suite']
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },
  created () {
    this.$store.dispatch('team/getSuite').then(() => {
      this.loading = false
    })
  },
  methods: {
    deleteTeam () {
      this.deleteProcess = true
      this.$store
        .dispatch('team/delete', { id: this.deleteTeamId })
        .catch((error) => {
          this.$vs.notify({
            title: this.$t('teams.suite.errors.title'),
            text: error,
            color: 'danger',
            time: 10000
          })
        })
        .then(() => {
          this.deleteProcess = false
          this.deleteTeamPopupActive = false
        })
    },
    previewFile (event) {
      this.file = event.target.files[0]
      this.fileName = this.file.name
      this.showFileName = true
      this.showUploadButtons = true
    },
    uploadUsersFile (type) {
      this.uploading = true
      switch (type) {
      case 'add':
        this.$store
          .dispatch('team/uploadAddExcelFile', { file: this.file })
          .then((response) => {
            this.$vs.notify({
              title: this.$t('teams.suite.notifications.succsess_created_title'),
              text: this.$t('teams.suite.notifications.succsess_created_message').replace('[COUNT]', response.data.data.count),
              color: 'success',
              time: 10000
            })
            this.showFileName = this.showUploadButtons = false
          })
          .catch((error) => {
            const errorString = this.getErrorString(error.response.data)
            this.$vs.notify({
              title: this.$t('teams.suite.errors.title'),
              text: errorString === '' ? error : errorString,
              color: 'danger',
              time: 10000
            })
          })
          .then(() => {
            this.$store.dispatch('team/getSuite').then(() => {
              this.uploading = false
            })
          })
        break
      }
    },
    downloadTeamsFile () {
      this.$store.dispatch('team/downloadExcelFileTeams')
    },
    onGridReady (params) {
      this.gridApi = params.api
    },
    sort () {
      const payload = {
        suite: this.suite
      }
      this.$store.dispatch('team/sort', payload)
    }
  }
}
</script>

<style lang="scss" scoped>
.add-button {
  margin-bottom: 30px;
}
.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.buttons-wrapper {
  margin-bottom: 30px;
}
.buttons-wrapper > button {
  display: inline-block;
  margin-right: 30px;
}
.sort-table-wrapper {
  width: 100%;
}
.list-item {
  transition: all 1s;
}
.list-item:hover {
  cursor: all-scroll;
}
.excel-file-buttons-container {
  display: flex;
  //justify-content: space-between;
  align-items: flex-end;
  justify-content: flex-end;

  & > button {
    margin-left: 15px;
    margin-bottom: 15px;
  }

  & > button:first-child {
    margin-left: 0;
    margin-right: auto;
  }
}

.sort-table-wrapper {
  width: 100%;
}
.list-item {
  transition: all 1s;
}
.list-item:hover {
  cursor: all-scroll;
}
::v-deep {
    .ag-cell {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
