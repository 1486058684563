/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import FormError from './../../modules/formError'

export default {
  isUserLoggedIn: () => {
    return false
  },
  loginData: null,
  passwordResetTokenExists: null,
  formError: new FormError()
}
