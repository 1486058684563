export default class FormError {
  errorsMessages = {
    '811': 'form.errors.required_field',
    '814': 'form.errors.required_recipients',
    '821': 'form.errors.doesnt_exist',
    '823': 'form.errors.mailing_sending_exist',
    '862': 'form.errors.max_size',
    '861': 'form.errors.password_min_size',
    '871': 'form.errors.letters_and_digits',
    '872': 'form.errors.time_format',
    '881': 'form.errors.unique_field',
    '981': 'form.errors.inccorect_email',
    '922': 'form.errors.only_future_date',
    '923': 'form.errors.only_past_date',
    '0': 'form.errors.invalid_data'
  }
  /**
   * Create a new Errors instance.
   */
  constructor () {
    this.errors = {}
  }

  /**
   * Determine if an errors exists for the given field.
   *
   * @param {string} field
   */
  has (field) {
    return this.errors.hasOwnProperty(field)
  }

  /**
   * Determine if we have any errors.
   */
  any () {
    return Object.keys(this.errors).length > 0
  }

  /**
   * Retrieve the error message for a field.
   *
   * @param {string} field
   */
  get (field) {
    if (this.errors[field]) {
      const errorCode = this.errors[field][0].substring(0, 3)
      return (errorCode in this.errorsMessages) ? this.errorsMessages[errorCode] : this.errorsMessages['0']
    } else {
      return null
    }
  }

  /**
   * Record the new errors.
   *
   * @param {object} errors
   */
  record (errors) {
    this.errors = errors
  }

  /**
   * Clear one or all error fields.
   *
   * @param {string|null} field
   */
  clear (field) {
    if (field) {
      delete this.errors[field]
      return
    }

    this.errors = {}
  }
}
