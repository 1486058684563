import axios from '@/axios.js'

export default {
  getStatistics ({ commit }) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/dashboard')
        .then((response) => {
          commit('retrieveStatistics', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .then(() => {
          commit('setLoading', false)
        })
    })
  }
}
