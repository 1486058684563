import state from './moduleAchievementState.js'
import mutations from './moduleAchievementMutations.js'
import actions from './moduleAchievementActions.js'
import getters from './moduleAchievementGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
