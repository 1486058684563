<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-else>
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row item-between w-full">
        <div class="vx-col mb-4 flex">
          <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
          <label class="ml-4 text-lg" :class="{ 'text-orange': isActive }">{{ $t('teams.fields.is_active') }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active')) }}</span>
        </div>
        <div class="vx-col mb-4 flex ml-auto">
          <vs-switch v-model="isEnableChat" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
          <label class="ml-4 text-lg" :class="{ 'text-orange': isEnableChat }">{{ $t('teams.fields.is_enable_chat') }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_enable_chat')">{{ $t(formError.get('is_enable_chat')) }}</span>
        </div>
      </div>
      <div class="vx-card tabs-container px-6 pt-6 pb-3">
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('teams.fields.name')" v-model="name" />
            <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-textarea class="w-full mb-2" :label="$t('teams.fields.description')" v-model="description" />
            <span class="text-danger text-sm" v-show="formError.has('description')">{{ $t(formError.get('description')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-container" style="width: 150px; height: 150px; margin: 0 auto">
              <picture-input
                v-if="!loading"
                ref="pictureInput"
                @prefill="onImgPrefill"
                @change="onImgChange"
                @error="onImgError"
                width="150"
                height="150"
                margin="16"
                :accept="this.$baseImageAcceptTypes"
                buttonClass="btn"
                size="10"
                :hideChangeButton="true"
                :crop="false"
                :prefill="imageUrl"
                :alertOnError="false"
                :customStrings="{
                  drag: $t('teams.fields.image'),
                }"
              >
              </picture-input>
              <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
            </div>
          </div>
        </div>
        <div v-if="imgCrop" class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-crop-container">
              <cropper
                ref="cropper"
                classname="cropper"
                :src="imgCrop"
                :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }"
                @change="changeCropImg"
                :stencil-props="{
                  aspectRatio: 1 / 1,
                }"
              ></cropper>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isOnlyRead" class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button 
            @click="action(stayAfter = true)" 
            :disabled="actionButtonDisabled">
            {{$t('teams.' + type + '.buttons.' + type)}}
          </vs-button>

          <vs-button 
            @click="action()" 
            :disabled="actionButtonDisabled">
            {{$t('teams.' + type + '.buttons.' + type)}} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '@/helpers/helpers.js'
import loading from '@/views/components/Loading.vue'
import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  components: {
    PictureInput,
    Cropper,
    loading
  },
  data () {
    return {
      id: 0,
      type: 'create',
      actionButtonDisabled: false,
      loading: true,
      name: '',
      isActive: true,
      isEnableChat: false,
      description: '',
      image: null,
      imageUrl: '',
      imgCrop: null,
      imgWidth: null,
      imgHeight: null,
      isChangedImg: false,
      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error'
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-settings-teams'] === 'read' ||  
               accessUser['admin-settings'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['team/formError']
    }
  },
  methods: {
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'team/create' : 'team/update'
      this.dispatch(patch, stayAfter)
    },
    async dispatch (patch, stayAfter) {
      try {
        const payload = {
          id: this.id,
          name: this.name,
          description: this.description,
          image:
            (this.isChangedImg && this.image !== null) ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : null,
          is_active: +this.isActive,
          is_enable_chat: +this.isEnableChat
        }

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name:'teams'
          },
          routerPushToEdit: {
            name: 'teams-edit'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
        
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
      
    },
    onImgPrefill () {
      this.imgCrop = this.$refs.pictureInput.image
      this.image = this.imgCrop
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    changeCropImg ({coordinates, canvas}) {
      if (!this.imgWidth && !this.imgHeight) {
        this.imgWidth = coordinates.width
        this.imgHeight = coordinates.height
      }
      if ((coordinates.width === this.imgWidth || coordinates.height === this.imgHeight) && this.type === 'edit' && this.imageUrl) {
        this.isChangedImg = false
      } else {
        this.isChangedImg = true
      }
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    }
  },
  created () {
    this.id = this.$route.params.id
    if (this.$router.currentRoute.name === 'teams-edit') {
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('team/edit', { id: this.id }).then(() => {
        const edit = this.$store.getters['team/edit']
        this.name = edit.name
        this.description = edit.description
        this.isActive = edit.isActive
        this.isEnableChat = edit.isEnableChat
        this.id = edit.id

        this.imageUrl = this.getUrl(edit.imagePath, `${this.$http.defaults.baseURL}/api/admin-panel`)

        this.loading = false
      })
    } else {
      this.loading = false
    }
    this.$store.commit('team/clearFormError')
  }
}
</script>

<style>
</style>
