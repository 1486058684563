<template>
  <div v-if="params" class="h-full w-full flex items-center gap-4 justify-center text-center">
    <vs-tooltip v-if="params.value == 'pending'" :text=" $t('reports.table.approved_report')">
      <vs-button  text-color="green" class="btn" @click.stop="changeStatusReport('approved')">
        <feather-icon icon="CheckCircleIcon" svgClasses="h-6 w-6" class="" />
      </vs-button>
    </vs-tooltip>
    <vs-tooltip v-if="params.value == 'pending'" :text=" $t('reports.table.reject')">
      <vs-button text-color="red" class="btn" @click.stop="changeStatusReport('rejected')">
        <feather-icon icon="XCircleIcon" svgClasses="h-6 w-6" class="" />
      </vs-button>
    </vs-tooltip>
    <span class="cursor-pointer block p-4" v-if="params.value == 'rejected'" @click.stop="changeStatusReport('pending')">{{ $t('reports.table.report_rejected') }}</span>
  </div>
</template>

<script>

export default {
  name: 'CellRendererActions',
  data () {
    return {
      btnColor:'#ddddd'
    }
  },
  methods: {
    changeStatusReport (status) {
      this.$store.dispatch('reports/rejectRecoverReport', { id: this.params.data.id, moderation_status: status }).then(() => {
        this.params.context.componentParent.updateCellData(
          this.params.data.id,
          'moderation_status',
          status
        )
      }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .btn {
    background: transparent !important;
    padding: 4px !important;
    border-radius: 50%;

    .feather-icon {
      
    }
    &:hover {
      background: #fcf3ec !important;
    }
  }
</style>
