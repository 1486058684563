<template class="h-full">
  <div class="vx-card tabs-container px-6 pt-6">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-else>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-input type="number" class="w-full" :label-placeholder="$t('users.edit.points.labels.point')" v-model="point" />
          <span class="text-danger text-sm" v-show="formError.has('point')">{{ $t(formError.get('point')) }}</span>
        </div>
      </div>
      <div class="vx-row mt-4">
        <div class="vx-col w-full mb-2">
          <vs-input class="w-full mb-4" :label-placeholder="$t('users.edit.points.labels.notice')" v-model="notice" />
          <span class="text-danger text-sm" v-show="formError.has('notice')">{{ $t(formError.get('notice')) }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <flat-pickr :config="configdateTimePicker" v-model="created_at" :placeholder="$t('users.edit.points.labels.created_at')" :monday-first="($i18n.locale === 'ru') ? true : false" />
          <span class="text-danger text-sm" v-show="formError.has('created_at')">{{ $t(formError.get('created_at')) }}</span>
        </div>
      </div>

      <div class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button @click="action(stayAfter = true)" :disabled="actionButtonDisabled">{{ $t('users.edit.points.' + type + '.buttons.' + type) }}</vs-button>
          <vs-button @click="action()" :disabled="actionButtonDisabled">{{ $t('users.edit.points.' + type + '.buttons.' + type) }} {{ $t('suite.and_exit') }}</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from '@/views/components/Loading.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import locale from 'flatpickr/dist/l10n/ru'
import helpers from '@/helpers/helpers.js'
import moment from 'moment'

export default{
  components: {
    flatPickr,
    loading
  },
  data () {
    return {
      id: 0,
      type: 'create',
      actionButtonDisabled: false,
      loading: true,
      point: '',
      notice: '',
      created_at: '',
      dateFormat: 'Y-m-d H:i:S',
      datepicker: {
      },
      configdateTimePicker: {
        enableTime: true,
        enableSeconds: true,
        dateFormat: 'Y-m-d H:i:S',
        locale: locale[this.$i18n.locale],
        time_24hr: true
      }
    }
  },
  computed: {
    userId () {
      return this.$route.params.user_id
    },
    formError () {
      return this.$store.getters['user/formError']
    }
  },
  methods: {
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'user/createPoint' : 'user/updatePoint'
      this.dispatch(patch, stayAfter)
    },
    async dispatch (patch, stayAfter) {
      try {
        const payload = {
          id: this.id,
          point: this.point,
          notice: this.notice ? this.notice : '',
          created_at: this.created_at ? moment(this.created_at).toISOString() : '',
          user_id: this.$route.params.user_id
        }

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name: 'points'
          },
          routerPushToEdit: {
            path: `/users/edit-user/${this.userId}/points`
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    } 
  },
  created () {
    this.id = this.$route.params.point_id
    if (this.$router.currentRoute.name === 'point-edit') {
      this.type = 'edit'
      this.$store.dispatch('user/editPoint', { user_id: this.$route.params.user_id, point_id: this.id })
        .then(() => {
          const edit = this.$store.getters['user/editPointData']
          this.point = edit.point
          this.notice = edit.notice
          this.created_at = edit.created_at
          this.id = edit.id

          this.loading = false
        })
    } else {
      this.loading = false
    }
    this.$store.commit('user/clearFormError')
  }
}
</script>

<style>
</style>
