import helpers from '@/helpers/helpers.js'

export default {
  retrieveSuite (state, suite) {
    state.suite = suite
  },

  retrieveUpdateCode (state, data) {
    const indexEl = helpers.searchInArray('id', data.id, state.suite, true)
    state.suite[indexEl] = data
  },

  retrieveDeleteCode (state, id) {
    const index = helpers.searchInArray('id', id, state.suite, true)
    state.suite.splice(index, 1)
  },

  retrieveCreateCode (state, data) {
    state.suite.push(data)
  },

  retrieveEdit (state, data) {
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) continue
      state.edit[helpers.kebabCaseToCamelCase(prop)] = (data[prop] === null) ? '' : data[prop]
    }
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  },
  removeTeamById (state, id) {
    const index = helpers.searchInArray('id', id, state.suite, true)
    state.suite.splice(index, 1)
  }
}
