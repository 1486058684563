import FormError from './../../modules/formError'

export default {
  suite: [],
  select: [],
  edit: {
    name: '',
    is_active: '',
    google_fit: '',
    health_kit: '',
    time_points: '',
    distance_points: '',
    amount_points: '',
    image_path: ''
  },
  activitiesOptions: [],
  formError: new FormError()
}
