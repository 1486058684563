<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <div v-if="!isOnlyRead" class="vx-col w-full">
        <vs-button color="primary" type="gradient" icon-pack="feather" icon="icon-plus" class="add-button" @click="$router.push({ name: 'pedometer-create-challenge'})"></vs-button>
      </div>
      <div class="vx-col w-full mb-base">
        <loading v-if="loading"></loading>
        <vs-table :data="suite" v-if="!loading">

          <template slot="thead">
            <vs-th>{{ $t('pedometer.challenges.suite.table.is_active') }}</vs-th>
            <vs-th>{{ $t('pedometer.challenges.suite.table.title') }}</vs-th>
            <vs-th>{{ $t('pedometer.challenges.suite.table.count_steps') }}</vs-th>
            <vs-th>{{ $t('pedometer.challenges.suite.table.start') }}</vs-th>
            <vs-th>{{ $t('pedometer.challenges.suite.table.end') }}</vs-th>
            <vs-th>{{ $t('pedometer.challenges.suite.table.completed') }}</vs-th>
            <vs-th>{{ $t('pedometer.challenges.suite.table.passed') }}</vs-th>
            <vs-th>{{ $t('pedometer.challenges.suite.table.leaderboard') }}</vs-th>
            <vs-th>{{ $t('pedometer.challenges.suite.table.team_leaderboard') }}</vs-th>
            <vs-th class="center" colspan="3">{{ $t('pedometer.challenges.suite.table.actions') }}</vs-th>
          </template>

          <template slot-scope="{data}">
             <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td :data="data[indextr].is_active">
                <font-awesome-icon icon="check" :class="[data[indextr].is_active ? iconTrueClass : iconFalseClass]"/>
              </vs-td>

              <vs-td :data="data[indextr].title">
                {{ data[indextr].title }}
              </vs-td>

              <vs-td :data="data[indextr].count_steps">
                {{ data[indextr].current_count_steps + '/' + data[indextr].count_steps }}
              </vs-td>

              <vs-td :data="data[indextr].start">
                {{ data[indextr].start }}
              </vs-td>

              <vs-td :data="data[indextr].end">
                {{ data[indextr].end }}
              </vs-td>

              <vs-td :data="data[indextr].completed">
                <font-awesome-icon icon="check" :class="[data[indextr].completed ? iconTrueClass : iconFalseClass]"/>
              </vs-td>

              <vs-td :data="data[indextr].passed">
                <font-awesome-icon icon="check" :class="[data[indextr].passed ? iconTrueClass : iconFalseClass]"/>
              </vs-td>

              <vs-td :data="data[indextr].date">
                <vs-button type="line"  @click="$store.dispatch('pedometer_challenge/downloadLeaderboard', { id: data[indextr].id, challenge_type: data[indextr].challenge_type })"><font-awesome-icon :icon="['fas', 'download']" /></vs-button>
              </vs-td>

              <vs-td :data="data[indextr].date">
                <vs-button type="line"  @click="$store.dispatch('pedometer_challenge/downloadTeamLeaderboard', { id: data[indextr].id, challenge_type: data[indextr].challenge_type })"><font-awesome-icon :icon="['fas', 'download']" /></vs-button>
              </vs-td>

              <vs-td :data="data[indextr].date" width="15" class="px-3 text-center">
                <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="$router.push({ name: 'pedometer-edit-challenge', params: { id: data[indextr].id }})" />
              </vs-td>

              <vs-td  v-if="!isOnlyRead" :data="data[indextr].date" width="15" class="px-3 text-center">
                <feather-icon icon="CopyIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="copyChallenge(data[indextr].id, indextr)" />
              </vs-td>

              <vs-td  v-if="!isOnlyRead" :data="data[indextr].date" width="15" class="px-3 text-center">
                <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="openDeletePopup(data[indextr].id, indextr, data[indextr].title)" />
              </vs-td>

            </vs-tr>
          </template>

        </vs-table>
      </div>
    </div>
    <vs-popup class="holamundo" :title="$t('pedometer.challenges.suite.popups.delete.title')" :active.sync="deletePopupActive">
      <p>{{ deletePopupContent }}</p>
      <div class="delete-confirm-popup-buttons" v-if="!showDeletePopupWaitMessage">
        <vs-button color="primary" type="filled" @click="deleteChallenge">{{ $t('pedometer.challenges.suite.popups.delete.buttons.confirm') }}</vs-button>
        <vs-button color="warning" type="filled" @click="deletePopupActive=false">{{ $t('pedometer.challenges.suite.popups.delete.buttons.cancel') }}</vs-button>
      </div>
      <p v-if="showDeletePopupWaitMessage" class="delete-popup-wait-message">{{ $t('pedometer.challenges.suite.popups.delete.wait_message') }}<font-awesome-icon icon="spinner" spin /></p>
    </vs-popup>
  </div>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core'
import {faCheck, faDownload, faSpinner} from '@fortawesome/free-solid-svg-icons'
import loading from '@/views/components/Loading.vue'

library.add(faSpinner)
library.add(faCheck)
library.add(faDownload)

export default{
  components: {
    loading
  },
  data () {
    return {
      iconTrueClass: 'icon-true',
      iconFalseClass: 'icon-false',
      loading: true,
      deletePopupActive: false,
      deletePopupContent: '',
      showDeletePopupWaitMessage: false,
      deleteChallengeId: 0,
      deleteChallengeIndex: 0
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-pedometer'] === 'read'
      }
    },
    suite () { return this.$store.getters['pedometer_challenge/suite'] }
  },
  methods: {
    copyChallenge (id, index) {
      this.$store.dispatch('pedometer_challenge/copy', { id: id, index: index }).catch(error => {
        this.$vs.notify({
          title: 'Server error',
          text: error,
          color: 'danger'
        })
      }).then(() => {
        this.params.context.componentParent.showCopyPopupWaitMessage = false
      })
    },
    openDeletePopup (id, index, name) {
      this.deletePopupContent = this.$t('pedometer.challenges.suite.popups.delete.message').replace('[NAME]', name)
      this.deleteChallengeId = id
      this.deleteChallengeIndex = index
      this.deletePopupActive = true
    },
    deleteChallenge () {
      this.showDeletePopupWaitMessage = true
      this.$store.dispatch('pedometer_challenge/destroy', { id: this.deleteChallengeId, index: this.deleteChallengeIndex }).catch(error => {
        this.$vs.notify({
          title: 'Server error',
          text: error,
          color: 'danger'
        })
      }).then(() => {
        this.deletePopupActive = false
        this.showDeletePopupWaitMessage = false
      })
    }
  },
  created () {
    this.$store.dispatch('pedometer_challenge/getSuite').then(() => {
      this.loading = false
    })
  }
}
</script>

<style lang="scss" scoped>
  .add-button {
    margin-bottom: 30px;
  }
  .icon-true {
    color: yellow;
  }
  .icon-false {
    color: grey;
  }
  .delete-confirm-popup-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  .delete-popup-wait-message {
    margin-top: 30px;
  }
</style>
