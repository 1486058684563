<template>
   <div class="flex flex-col">
      <vs-tooltip :text="$t('contentFilters.tooltip.disabled')" class="ml-auto" :delay="targetId ? '10s' : '.1s'">
        <vs-button 
          type="line" 
          text-color="#7367F0" 
          @click="toggleShowFilter()"
          :disabled="!targetId">
            {{ showFilter ? $t('contentFilters.suite.hide_filters') : $t('contentFilters.suite.filters')}}
        </vs-button>
      </vs-tooltip>
      <loading v-if="loading && showFilter"></loading> 
      <transition name="fade">
        <vx-card v-show="!loading && showFilter" class="mb-6 w-fill">
          <div class="grid">
            <div v-for="(item) in filtersData" :key="item.id" class="grid__item">
              <div class="grid__label">{{ item.title }}</div>
              <ul>
                <li v-for="(value) in item.values" :key="value.id">
                  <vs-checkbox  v-model="checkBoxValue[item.id - 1]" :vs-value="value.id">{{value.title}}</vs-checkbox>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex">
            <vs-button @click="submit()" class="ml-auto">{{ $t('contentFilters.suite.save_filters') }}</vs-button>
          </div>
        </vx-card>
      </transition>
   </div>
</template>

<script>

import loading from '@/views/components/Loading.vue'

export default {
  components: {
    loading
  },
  props: {
    targetId: 0,
    targetModel: ''
  },
  data () {
    return {
      loading: true,
      filtersData: {},
      checkBoxValue: [],
      showFilter: false
    }
  },

  watch: {
    filtersById (newVal) {
      this.getCheckBoxValue()
    }
  },
  computed: {
    filtersById () {
      return this.$store.getters['contentFilter/contentFiltersById']

    }
  },
  

  created () {
    this.$store.dispatch('contentFilter/getAllFilters').then((res) => {
      this.filtersData = res.data.data
    })
  },
  mounted () {
  },
  methods: {

    toggleShowFilter () {
      if (this.checkBoxValue.length) {
        this.showFilter = !this.showFilter
      } else {
        this.getFilters()
        this.showFilter = !this.showFilter
      }
    },  
    getFilters () {
      console.log('target_id', this.targetId)
      console.log('target_model', this.targetModel)
      this.$store.dispatch('contentFilter/getFiltersById', {
        target_id: this.targetId,
        target_model: this.targetModel
      })
    },
    getCheckBoxValue () {
      this.checkBoxValue = []
      for (const [key, value] of Object.entries(this.filtersData)) {
        this.checkBoxValue.push([]) 
      }
      if (this.filtersById.length) {
        this.filtersById.forEach((item) => {
          this.checkBoxValue[+item.id - 1] = item.values.map((value) => value.id)
        })
      }
      this.loading = false
      return this.checkBoxValue
    },
    submit () {
      const filters = [] 
      this.checkBoxValue.map((item, index) => {
        if (item.length) {
          filters.push({
            id: index + 1,
            values: item
          })
        }
      })
      const payload = {
        target_model: this.targetModel,
        target_id: this.targetId,
        filters: filters
      }
      this.$store.dispatch('contentFilter/update', payload)
        .then((res) => {
          this.$vs.notify({
            title: this.$t('contentFilters.notify.saved'),
            text: this.$t('contentFilters.notify.success_text'),
            color: 'success'
          })
        })
        .catch((error) => {
          this.$vs.notify({
            title: this.$t('contentFilters.notify.saved'),
            text: error,
            color: 'error'
          })
        })
    }
  }
}


</script>

<style lang="scss" scoped>
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin-bottom: 32px;
    &__label {
      font-weight: 700;
      margin-bottom: 1rem;
    }
    li {
      padding-bottom: 0.3em;
    }
  }
</style>
