<template>
  <div id="page-category-members-list">

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-button color="primary" type="gradient" icon-pack="feather" icon="icon-plus" class="add-button" @click="$router.push({ name: 'coin-create'})"></vs-button>
      </div>
      <user-nav-bar :user_id="$route.params.user_id"></user-nav-bar>
    </div>

    <loading v-if="loading"></loading>

    <div v-if="!loading" class="flex vx-col w-full vx-card p-3 mb-3">
      <div class="p-2">
        <strong>{{$t('users.edit.points.labels.user')}} #{{ userData.id }}:</strong> {{ userData.email }} | <strong>{{$t('users.edit.points.labels.point')}}:</strong> {{ userData.all_point }} | <strong>{{$t('users.edit.points.labels.shop_point')}}:</strong> {{ userData.shop_point }} | <strong>{{$t('users.edit.points.labels.coin')}}:</strong> {{ userData.coins }}
      </div>
    </div>

    <div class="vx-card p-6" v-if="!loading">
      <!-- AgGrid Table -->
      <ag-grid-vue
        @gridReady="onGridReady"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4"
        domLayout="autoHeight"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="suite"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage" />

    </div>

  </div>

</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererLink from '../../components/cell-renderer/CellRendererLink.vue'
import loading from '@/views/components/Loading.vue'
import UserNavBar from '../../components/user/Nav.vue'

export default {
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    UserNavBar,
    loading
  },
  data () {
    return {
      // AgGrid
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: { 'textAlign': 'center'},
        headerClass: 'text-center'
      },
      columnDefs: [
        {
          headerName: this.$t('users.edit.points.table.coin'),
          field: 'coins',
          minWidth: 80,
          maxWidth: 120,
          sortable: true
        },
        {
          headerName: this.$t('users.edit.points.table.type'),
          field: 'type_title',
          filter: true,
          minWidth: 150,
          maxWidth: 500,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 24px'},
          headerClass: 'text-left'
        },
        {
          headerName: this.$t('users.edit.points.table.target_title'),
          field: 'target_title',
          filter: true,
          minWidth: 150,
          maxWidth: 400,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 24px'},
          headerClass: 'text-left'
        },
        {
          headerName: this.$t('users.edit.points.table.created_at'),
          field: 'created_at',
          minWidth: 170,
          maxWidth: 200,
          sortable: true
        },
        {
          headerName: this.$t('users.edit.points.table.notice'),
          field: 'notice',
          minWidth: 100,
          maxWidth: 300,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 24px'},
          headerClass: 'text-center'
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink
      },
      loading: true
    }
  },
  created () {
    this.$store.dispatch('user/getCoins', { user_id: this.$route.params.user_id }).then(() => {
      this.loading = false
    })
  },
  methods: {
    onGridReady (params) {
      this.gridApi = params.api
    }
  },
  computed: {
    suite () {
      return this.$store.getters['user/coins']
    },
    userData () {
      return this.$store.getters['user/userData']
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  }
}

</script>

<style lang="scss">
  #page-clubadmins-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }

  .vs-button-filled {
    color: #fff !important;
    margin-left: 15px;
  }
</style>
