var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"statistics"},[_c('h3',{staticClass:"statistics-h3"},[_vm._v(_vm._s(_vm.$t('dashboard.titles.shop')))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"card-statistic-grid"},_vm._l((_vm.statisticsData),function(value,key){return _c('div',{key:key,staticClass:"card-statistic vx-col w-full flex"},[_c('statistics-card-line',{attrs:{"chartData":value.series,"countData":value,"type":"area","icon":_vm.statisticsData[key].icon,"icon-left":"","statisticTitle":_vm.statisticsData[key].title,"color":_vm.statisticsData[key].color,"period":_vm.statisticsPeriod,"diffPercent":""}})],1)}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"top-section"},[_c('vx-card',{},[_c('div',[_c('h4',{staticClass:"top-title"},[_vm._v(_vm._s(_vm.$t('dashboard.titles.top_positions')))]),_c('ol',{},[_c('li',{staticClass:"top-item"},[_c('span',{staticClass:"top-thead"},[_vm._v(_vm._s(_vm.$t('dashboard.titles.product')))]),_c('span',{staticClass:"top-thead text-right"},[_vm._v(_vm._s(_vm.$t('dashboard.titles.number_of_purchases')))])]),_vm._l((_vm.topProducts),function(item,index){return _c('li',{key:item.id,staticClass:"top-item"},[_c('div',{staticClass:"top-item__name"},[_c('span',{staticClass:"place"},[_vm._v(_vm._s(index + 1))]),_c('img',{directives:[{name:"img",rawName:"v-img",value:({src: item.image_path
                  ? _vm.getUrl(item.image_path)
                  : ''}),expression:"{src: item.image_path\n                  ? getUrl(item.image_path)\n                  : ''}"}],staticClass:"top-item__img rounded-lg bg-grey",attrs:{"src":item.image_path_thumbnail
                  ? _vm.getUrl(item.image_path_thumbnail)
                  : require('@/assets/images/pages/not-img.png')}}),_c('div',{staticClass:"flex-column"},[_c('span',{staticClass:"top-item__text"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"top-item__type"},[_vm._v(_vm._s(item.is_digital ? _vm.$t('dashboard.titles.digital_goods') : _vm.$t('dashboard.titles.warehouse_goods')))])])]),_c('span',{staticClass:"top-item__stat"},[_vm._v(_vm._s(item.count))])])})],2)])]),_c('vx-card',{},[_c('div',[_c('h4',{staticClass:"top-title"},[_vm._v(_vm._s(_vm.$t('dashboard.titles.remains')))]),_c('ol',{},[_c('li',{staticClass:"top-item"},[_c('span',{staticClass:"top-thead"},[_vm._v(_vm._s(_vm.$t('dashboard.titles.product')))]),_c('span',{staticClass:"top-thead text-right"},[_vm._v(_vm._s(_vm.$t('dashboard.titles.quantity')))])]),_vm._l((_vm.stockProducts),function(item){return _c('li',{key:item.id,staticClass:"top-item"},[_c('div',{staticClass:"top-item__name"},[_c('img',{directives:[{name:"img",rawName:"v-img",value:({src: item.image_path
                  ? _vm.getUrl(item.image_path)
                  : ''}),expression:"{src: item.image_path\n                  ? getUrl(item.image_path)\n                  : ''}"}],staticClass:"top-item__img rounded-lg bg-grey",attrs:{"src":item.image_path_thumbnail
                  ? _vm.getUrl(item.image_path_thumbnail)
                  : require('@/assets/images/pages/not-img.png')}}),_c('div',{staticClass:"flex-column"},[_c('span',{staticClass:"top-item__text"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"top-item__type"},[_vm._v(_vm._s(item.is_digital ? _vm.$t('dashboard.titles.digital_goods') : _vm.$t('dashboard.titles.warehouse_goods')))])])]),_c('span',{staticClass:"top-item__stat"},[_vm._v(_vm._s(item.stock))])])})],2)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }