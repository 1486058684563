import FormError from '../../modules/formError'

export default {
  suite: [],
  select: [],
  edit: {
    // publication_until: '',
    // publication_at: '',
    // target_model: '',
    // target_id: ''
  },
  formError: new FormError()
}
