import helpers from '@/helpers/helpers.js'
// import moment from 'moment'
export default {
  retrieveFiltersById (state, suite) {
    state.contentFiltersById = suite
  },

  retrieveAllFilters (state, suite) {
    state.contentAllFilters = suite
  }
}
