<template>
  <div class="h-full">
    <div class="vx-card tabs-container px-6 pt-6 pb-3">
      <div class="vx-row">
        <div class="vx-col w-full mb-4">
          <vs-input class="w-full" :label-placeholder="$t('users.create.labels.name')" v-model="name" />
          <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-input class="w-full" type="email" :label-placeholder="$t('users.create.labels.email')" v-model="email" />
          <span class="text-danger text-sm" v-show="formError.has('email')">{{ $t(formError.get('email')) }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-input class="w-full" type="password" :label-placeholder="$t('users.create.labels.password')" v-model="password"/>
          <span class="text-danger text-sm w-full" v-show="!isValidPassword">{{ $t('users.create.errors.valid_password') }}</span>
          <span class="text-danger text-sm" v-show="formError.has('password') && isValidPassword">{{ $t(formError.get('password')) }}</span>
        </div>

        <div class="vx-col w-full mb-2">
          <vs-input class="w-full" type="password" :label-placeholder="$t('users.create.labels.password_confirmation')" v-model="passwordConfirmation" />
          <span class="text-danger text-sm w-full" v-show="!isValidPasswordConfirmation">{{ $t('users.create.errors.valid_password_confirmation') }}</span>
        </div>
      </div>
    </div>

    <div class="vx-row fixed-buttons">
      <div class="vx-col w-full flex gap-3">
        <vs-button @click="action(stayAfter = true)" >{{$t('users.create.buttons.create') }}</vs-button>
        <vs-button @click="action()" >{{$t('users.create.buttons.create') }} {{ $t('suite.and_exit') }}</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '@/helpers/helpers.js'

export default {
  data () {
    return {
      loading: true,
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      actionButtonDisabled: false
    }
  },
  computed: {
    formError () {
      return this.$store.getters['user/formError']
    },
    isValidPassword () {
      return this.password.length >= 5 && this.password.length <= 15
    },
    isValidPasswordConfirmation () {
      return this.password === this.passwordConfirmation
    }
  },
  methods: {
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      this.dispatch('user/createUser', stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        const payload = {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation
        }

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          type: 'create',
          stayAfter: stayAfter,
          routerPush: {
            name: 'users'
          },
          routerPushToEdit: {
            name: 'edit-user'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    }
  }
}
</script>

<style scoped>
</style>
