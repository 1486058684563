import FormError from '../../modules/formError'

export default {
  suite: [],
  catalogTree: [],
  product: {
    id: Number,
    title: String,
    subtitle: String,
    description: String,
    info_link: String,
    price: Number,
    category_id: Number,
    is_active: Number,
    external_id: Number,
    sorting: Number
  },
  formError: new FormError()
}
