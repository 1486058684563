import FormError from '../../modules/formError'

export default {
  live: {
    countCompletedTrainings: {},
    topLikes: {},
    countMessages: {},
    countPosts: {},
    countReceivedPoints: {},
    countRegistrations: {},
    countFamilyUsers: {}
  },
  usersActivity: {
  },

  general: {
    countUsers: {},
    countUsersLogIn: {},
    timeSpentInApp: {},
    countUniqueUsersLogIn: {},
    interests: {}
  },

  events: {
    pastEvents: {},
    usersParticipatedInEvents: {},
    reports: {},
    topLikesForReports: {},
    topEvents: {}
  },

  shop: {
    shopOrders: {},
    shopOrdersFromWarehouse: {},
    shopOrderedDigital: {},
    topProducts: {},
    stockProducts: []
  },

  coursesAndPrograms: {
    programs: {},
    challenges: {},
    completedTrainings: {},

    topActiveUsers: {},
    topSociableUsers: {},
    topActiveTeams: {},
    topCoursesAndChallenges: {},
    topReports: {}
  },

  communications: {
    email: {},
    message: {},
    push: {},
    posts: {},
    likes: {}
  }

}