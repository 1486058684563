export default {
  suite (state) {
    return state.suite
  },
  formError (state) {
    return state.formError
  },
  edit (state) {
    return state.edit
  },
  programmsAndChallenges (state) {
    return state.programmsAndChallenges
  },
  challenges (state) {
    return state.challenges
  },
  programms (state) {
    return state.programms
  },
  events (state) {
    return state.events
  },
  trainings (state) {
    return state.trainings
  }
}
