<template>
<div id="demo-basic-card">

  <div class="vx-row" v-if="loading">
    <div class="vx-col w-full mb-2">
      <loading v-if="loading"></loading>
    </div>
  </div>

  <div v-else class="vx-card tabs-container px-6 pt-6 pb-3">
    <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
  <div class="vx-row">
    <div class="vx-col w-full mb-2">
      <label>{{ $t('shop_settings.notify_manager_new_order.label') }}</label>
      <vs-switch v-model="notifyManagerNewOrder" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
      <span class="text-danger text-sm" v-show="formError.has('notify_manager_new_order')">{{ $t(formError.get('notify_manager_new_order')) }}</span>
    </div>
  </div>


    <div class="vx-row" v-if="notifyManagerNewOrder">
      <div class="vx-col w-full mb-2">
        <p>{{ $t('shop_settings.notify_manager_new_order.description') }}</p>
        <vs-input class="w-full" v-model="managerEmails" />
        <span class="text-danger text-sm" v-show="formError.has('title')">{{ $t(formError.get('title')) }}</span>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <p>{{ $t('shop_settings.limit_count_purchases_per_month') }}</p>
        <vs-input class="w-full" v-model="limitCountProduct" />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <p>{{ $t('shop_settings.limit_count_purchases_identical_product_per_month') }}</p>
        <vs-input class="w-full" v-model="limitIdenticalProduct" />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full text-right mt-5">
        <vs-button class="mb-2" @click="action()" :disabled="actionButtonDisabled || isOnlyRead">{{ $t('shop_settings.buttons.save') }}</vs-button>
      </div>
    </div>

    <vs-popup class="holamundo" :title="$t('shop_settings.popups.message.title')" :active.sync="messagePopupActive">
      <p>{{ popupMessage }}</p>
      <div class="message-popups-button">
        <vs-button color="primary" type="filled" @click="messagePopupActive=false">{{ $t('shop_settings.popups.message.button') }}</vs-button>
      </div>
    </vs-popup>

  </div>
</div>
</template>

<script>
import loading from '@/views/components/Loading.vue'


export default {
  components: {
    loading
  },
  data () {
    return {
      notifyManagerNewOrder: false,
      managerEmails: '',
      actionButtonDisabled: false,
      messagePopupActive: false,
      popupMessage: '',
      limitCountProduct: '',
      limitIdenticalProduct: ''
    }
  },
  created () {
    this.$store.dispatch('shop_settings/getManagerEmails').then(() => {
      const data = this.$store.getters['shop_settings/suite']
      this.managerEmails = data.emails.map((e) => e.email).join(',')
      this.notifyManagerNewOrder = data.settings.notify_manager_new_order === '1'
      this.limitCountProduct = data.settings.limit_count_purchases_per_month === 'null' ? '' : data.settings.limit_count_purchases_per_month
      this.limitIdenticalProduct = data.settings.limit_count_purchases_identical_product_per_month === 'null' ? '' : data.settings.limit_count_purchases_identical_product_per_month
      this.loading = false
    })
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-shop'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['shop_categories/formError']
    }
  },
  methods: {
    action () {
      this.actionButtonDisabled = true
      const payload = {
        settings: {
          notify_manager_new_order: this.notifyManagerNewOrder,
          limit_count_purchases_per_month: this.limitCountProduct,
          limit_count_purchases_identical_product_per_month: this.limitIdenticalProduct
        },
        manager_emails: this.managerEmails === '' ? [] : this.managerEmails.split(',')
      }
      this.$store.dispatch('shop_settings/update', payload)
        .then(() => {
          this.actionButtonDisabled = false
          this.messagePopupActive = true
          this.popupMessage = this.$t('shop_settings.popups.message.success')
        })
        .catch(() => {
          this.actionButtonDisabled = false
          this.messagePopupActive = true
          this.popupMessage = this.$t('shop_settings.popups.message.error')
        })
    }
  }
}
</script>

<style>
.message-popups-button{
  margin-top: 30px;
}
</style>
