<template>
  <div class="fill-row-loading">
    <div :id="[`loading-${loadingType}`]" class="vs-con-loading__container loading-data"></div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      loadingType: 'radius'
    }
  },
  mounted () {
    this.$vs.loading({
      container: `#loading-${this.loadingType}`,
      type: this.loadingType
    })
  }
}
</script>

<style lang="scss" scoped>
  .fill-row-loading {
    width: 100%;
    .loading-data {
      width: 100%;
      height: 120px;
      transition: all 0.3s ease;
    }
  }
</style>
