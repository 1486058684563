import axios from '../../../axios/index.js'
import store from '../../../../store/store.js'
import router from '@/router.js'
import helpers from '@/helpers/helpers.js'

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched (access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber (callback) {
  subscribers.push(callback)
}

function setAccessToken () {
  axios.defaults.headers.common['Authorization'] = `Bearer ${  localStorage.getItem('accessToken')}`
}

export default {
  init () {
    setAccessToken()
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      // const { config, response: { status } } = error
      const { config, response } = error
      const originalRequest = config

      // if (status === 401) {
      breakme: if (response && response.status === 401) {
        if (originalRequest.url === '/api/admin-panel/auth/refresh-token') {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          router.push({ name: 'page-login' })
          break breakme
        }
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch('auth/fetchAccessToken')
            .then((response) => {
              isAlreadyFetchingAccessToken = false
              localStorage.setItem('accessToken', response.data.access_token)
              localStorage.setItem('refreshToken', response.data.refresh_token)
              onAccessTokenFetched(response.data.access_token)
            })
        }
        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(access_token => {
            originalRequest.headers.Authorization = `Bearer ${access_token}`
            setAccessToken()
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      }
      return Promise.reject(error)
    })
  },
  login (email, pwd) {
    return axios.post('/api/admin-panel/auth/login', {
      email,
      password: pwd
    })
  },
  refreshToken () {
    return axios.post('/api/admin-panel/auth/refresh-token', {refresh_token: localStorage.getItem('refreshToken') || 'refresh_token' })
  }
}
