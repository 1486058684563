<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full my-32 ">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-else>
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col w-full mb-4 flex">
          <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
          <label class="ml-4" :class="{ 'text-orange': isActive }">{{ $t('achievements.' + type + '.labels.is_active') }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active')) }}</span>
        </div>
      </div>
      <vx-card>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full" :label-placeholder="$t('achievements.' + type + '.labels.name')" v-model="name" />
            <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-container" style="width: 150px; height: 150px; margin: 0 auto">
              <picture-input
                ref="pictureInput"
                @prefill="onImgPrefill"
                @change="onImgChange"
                @error="onImgError"
                width="150"
                height="150"
                margin="16"
                :accept="this.$baseImageAcceptTypes"
                buttonClass="btn"
                size="10"
                :hideChangeButton="true"
                :crop="false"
                :prefill="imageUrl"
                :alertOnError="false"
                :customStrings="{
                  drag: $t('categories.edit.labels.image'),
                }"
              >
              </picture-input>
              <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
            </div>
          </div>
        </div>
        <div v-if="imgCrop" class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-crop-container">
              <cropper
                ref="cropper"
                classname="cropper"
                :src="imgCrop"
                :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }"
                @change="changeCropImg"
                :stencil-props="{}"
              ></cropper>
            </div>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-textarea class="w-full" :label="$t('achievements.' + type + '.labels.description')" v-model="description" />
            <span class="text-danger text-sm" v-show="formError.has('description')">{{ $t(formError.get('description')) }}</span>
          </div>
        </div>

        <template v-if="!achievements_to_user_count">
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <div class="mt-4 v-select-wrapper">
                <label class="vs-input--label">{{ this.$t('achievements.' + type + '.labels.type') }}</label>
                <v-select
                  @input="changeTypeSelect"
                  v-model="type_local"
                  :options="typeOptions"
                  label="label"
                  :reduce="(value) => value.value"
                  v-validate="'required'"
                  name="type"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
                <span class="text-danger text-sm" v-show="formError.has('type')">{{ $t(formError.get('type')) }}</span>
              </div>
            </div>
          </div>

          <div class="vx-row" v-if="type_local === 'event'">
            <div class="vx-col w-full mb-2">
              <div class="mt-4 v-select-wrapper">
                <label class="vs-input--label">{{ this.$t('achievements.' + type + '.labels.event') }}</label>
                <v-select
                  label="label"
                  :reduce="(value) => value.value"
                  v-model="event_local"
                  :clearable="false"
                  :options="eventsOptions"
                  v-validate="'required'"
                  name="challenge_id"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </div>

          <div class="vx-row" v-if="type_local === 'programm'">
            <div class="vx-col w-full mb-2">
              <div class="mt-4 v-select-wrapper">
                <label class="vs-input--label">{{ this.$t('achievements.' + type + '.labels.programm') }}</label>
                <v-select
                  label="label"
                  :reduce="(value) => value.value"
                  v-model="programm_local"
                  :clearable="false"
                  :options="programmOptions"
                  v-validate="'required'"
                  name="programm_id"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </div>

          <div class="vx-row" v-if="type_local === 'pedometr'">
            <div class="vx-col w-full mb-2">
              <div class="mt-4 v-select-wrapper">
                <label class="vs-input--label">{{ this.$t('achievements.' + type + '.labels.pedometr') }}</label>
                <v-select
                  label="label"
                  :reduce="(value) => value.value"
                  v-model="pedometr_local"
                  :clearable="false"
                  :options="pedometrOptions"
                  v-validate="'required'"
                  name="pedometr_id"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </div>

          <div class="vx-row" v-if="type_local === 'training'">
            <div class="vx-col w-full mb-2">
              <div class="mt-4 v-select-wrapper">
                <label class="vs-input--label">{{ this.$t('achievements.' + type + '.labels.programm') }}</label>
                <v-select
                  @input="filterTraining"
                  label="label"
                  :reduce="(value) => value.value"
                  v-model="training_programm_local"
                  :clearable="false"
                  :options="programmOptions"
                  v-validate="'required'"
                  name="training_programm_id"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </div>

          <div class="vx-row" v-if="show_training || training_programm_local">
            <div class="vx-col w-full mb-2">
              <div class="mt-4 v-select-wrapper">
                <label class="vs-input--label">{{ this.$t('achievements.' + type + '.labels.training') }}</label>
                <v-select
                  label="label"
                  :reduce="(value) => value.value"
                  v-model="training_local"
                  :clearable="false"
                  :options="trainingOptions"
                  v-validate="'required'"
                  name="training_id"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </div>


        </template>
        <template v-else>
          <h5 class="notice">
            {{ $t('achievements.edit.unable_edit_notice') }}
          </h5>
        </template>
      </vx-card>
      <div v-if="!isOnlyRead" class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button 
            @click="action(stayAfter = true)" 
            :disabled="actionButtonDisabled">
            {{$t('achievements.' + type + '.buttons.' + type)}}
          </vs-button>
          <vs-button 
            @click="action()" 
            :disabled="actionButtonDisabled">
            {{$t('achievements.' + type + '.buttons.' + type)}} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import editorJsConfig from '@/editorjs/config.js'

import vSelect from 'vue-select'
import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import helpers from '@/helpers/helpers.js'

import loading from '@/views/components/Loading.vue'

export default {
  components: {
    quillEditor,
    loading,
    'v-select': vSelect,
    PictureInput,
    Cropper
  },
  data () {
    return {
      id: 0,
      name: '',
      editorJsConfig: Object.assign({}, editorJsConfig),
      type: 'create',
      actionButtonDisabled: false,
      loaded: false,
      loading: true,
      type_local: null,
      image: null,
      imageUrl: '',
      imgCrop: null,
      imgWidth: null,
      imgHeight: null,
      isChangedImg: false,
      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error',
      typeOptions: [
        { label: this.$t('achievements.type.event'), value: 'event' },
        { label: this.$t('achievements.type.programm'), value: 'programm' },
        { label: this.$t('achievements.type.training'), value: 'training' },
        { label: this.$t('achievements.type.pedometr'), value: 'pedometr' }
      ],
      isActive: true,
      description: '',
      event_local: null,
      programm_local: null,
      pedometr_local: null,
      training_programm_local: null,
      training_local: null,
      achievements_to_user_count: 0,
      show_training: null,
      challengeOptions: [],
      eventsOptions: [],
      programmOptions: [],
      trainingOptions: [],
      trainingChallengeOptions: [],
      pedometrOptions: []
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-settings-achievements'] === 'read' ||  
               accessUser['admin-settings'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['achievement/formError']
    }
  },
  methods: {
    changeTypeSelect () {
      this.show_training = null
      this.training_programm_local = null
    },

    filterTraining (id) {
      this.$store.dispatch('achievement/trainings', { category_id: id }).then(() => {
        const trainings = this.$store.getters['achievement/trainings']

        this.trainingOptions = []

        trainings.forEach((grp, i) => {
          console.log('this.trainingOptions', this.trainingOptions)
          this.trainingOptions.push({ label: trainings[i].name, value: trainings[i].id })
        })

        this.show_training = true
      })
    },
    onImgPrefill () {
      this.imgCrop = this.$refs.pictureInput.image
      this.image = this.imgCrop
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    changeCropImg ({coordinates, canvas}) {
      if (!this.imgWidth && !this.imgHeight) {
        this.imgWidth = coordinates.width
        this.imgHeight = coordinates.height
      }
      if ((coordinates.width === this.imgWidth || coordinates.height === this.imgHeight) && this.type === 'edit' && this.imageUrl) {
        this.isChangedImg = false
      } else {
        this.isChangedImg = true
      }
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'achievement/create' : 'achievement/update'
      this.dispatch(patch, stayAfter)
    },
    async dispatch (patch, stayAfter) {
      try {
        const payload = {
          id: this.id,
          name: this.name,
          description: this.description,
          type: this.type_local,
          is_active: this.isActive,
          event: this.event_local,
          programm: this.programm_local,
          pedometr: this.pedometr_local,
          training: this.training_local,
          training_programm: this.training_programm_local,
          image:
              (this.isChangedImg && this.image !== null) ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : null
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name: 'achievements'
          },
          routerPushToEdit: {
            name: 'edit-achievement'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    }
  },
  created () {
    if (this.$router.currentRoute.name === 'edit-achievement') {
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('achievement/edit', { id: this.id }).then(() => {
        const edit = this.$store.getters['achievement/edit']
        this.name = edit.name
        this.description = edit.description
        this.isActive = edit.isActive
        this.type_local = edit.type
        this.programm_local = edit.categoryId
        this.event_local = edit.categoryId
        this.pedometr_local = edit.pedometerChallengeId
        this.achievements_to_user_count = edit.achievementsToUserCount

        if (edit.trainingId && edit.categoryId) {
          if (this.type_local === 'training') {
            this.filterTraining(edit.categoryId)
            this.show_training = true
            this.training_programm_local = edit.categoryId
            this.training_local = edit.trainingId
          } 
        }

        this.imageUrl = this.getUrl(edit.imagePath, `${this.$http.defaults.baseURL}/api/admin-panel`)
        
        this.loaded = true
        this.loading = false
      })
    } else {
      this.loaded = true
      this.loading = false
    }

    this.$store.dispatch('achievement/events').then(() => {
      const events = this.$store.getters['achievement/events']

      events.forEach((grp, i) => {
        this.eventsOptions.push({ label: events[i].name, value: events[i].id })
      })
    })

    this.$store.dispatch('achievement/programmsAndChallenges').then(() => {
      const programms = this.$store.getters['achievement/programmsAndChallenges']

      programms.forEach((grp, i) => {
        this.programmOptions.push({ label: programms[i].name, value: programms[i].id })
      })
    })

    this.$store.dispatch('pedometer_challenge/getSuite').then(() => {
      const pedometr = this.$store.getters['pedometer_challenge/suite']

      pedometr.forEach((grp, i) => {
        this.pedometrOptions.push({ label: `${pedometr[i].title}`, value: pedometr[i].id })
      })
    })

    this.$store.commit('achievement/clearFormError')
  }
}
</script>


<style>
.image-container .preview-container {
  margin: 0 !important;
}

.notice {
  margin-top: 20px;
  margin-bottom: 20px;
  background: #efefef;
  border-radius: 5px;
  padding: 15px;
}
</style>
