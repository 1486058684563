import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'
import qs from 'qs'

export default {
  getSuite ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/trainings/geocaching', {
        params: payload,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      })
        .then((response) => { 
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  rejectRecover (context, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/trainings/geocaching/moderation/${payload.id}`, payload)
        .then((response) => {
          context.commit(payload.moderation_status === 'rejected' ? 'retrieveReject' : 'retrieveRecover', payload.id)
          resolve(response)
        })
        .catch((error) => {
          console.log('error', error)
          reject(error)
        })
    })
  },
  getStatuses ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/trainings/geocaching/moderation/statuses')
        .then((response) => {
          commit('retrieveStatuses', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  delete ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/trainings/geocaching/${payload.id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
