<template>
  <div class="product h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-else>
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-2">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="mb-4 inline-block rounded-lg shadow-md font-bold bg-white py-2 px-4">
        {{ `${$t('shop_products.template.pattern')} ${$t('shop_products.template.' + activeTemplate.name + '.name')}` }}
      </div>
      <div class="vx-row mt-4">
        <div class="vx-col w-full mb-4 flex items-center">
          <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
          <label class="ml-4 text-lg" :class="{ 'text-orange': isActive }">{{ $t('shop_products.fields.is_active')
          }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active'))
          }}</span>
        </div>
      </div>
      <div class="vx-card tabs-container px-6 pt-6 pb-10">
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <label class="text-sm pb-2">{{ $t('shop_products.fields.name') }}*</label>
            <vs-input class="w-full mb-4 text-lg" :placeholder="$t('shop_products.fields.enter_name')" v-model="name" />
            <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
          </div>
        </div>

        <!-- в приложении нигде не выводится этот блок сейчас -->
        <!-- <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <label class="text-sm">{{ $t('shop_products.fields.block_description') }}</label>
            <vs-input class="w-full mb-4" :placeholder="$t('shop_products.fields.enter_block_description')"
              v-model="blockDescription" />
            <span class="text-danger text-sm" v-show="formError.has('content')">{{ $t(formError.get('block_description'))
            }}</span>
          </div>
        </div> -->
        
        <div class="vx-row mb-12">
          <div class="vx-col w-full">
            <label class="text-sm">{{ $t('shop_products.fields.description') }}</label>
            <vx-card no-shadow card-border class="card-input" tabindex="1">
              <editor  ref="editor" :config="editorJsConfig" class="editor"/>
              <span class="text-danger text-sm" v-show="formError.has('description')">{{ $t(formError.get('description'))
              }}</span>
            </vx-card>
          </div>
        </div>
        <div class="vx-row items-center">
          <div class="vx-col md:w-1/2 w-full mb-1 relative">
            <label class="label-for-select">{{ $t('shop_categories.suite.groups') }}</label>
            <v-select 
              v-model="groups" 
              multiple 
              taggable
              :placeholder="$t('shop_categories.suite.choose_groups')"
              :closeOnSelect="false" 
              :options="groupsOptions" 
              :reduce="option => typeof option === 'string' ? { label: option } : option"
              :selectable="option => groups.indexOf(option) < 0"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          </div>
          <div class="vx-col md:w-1/2 w-full mb-4">
            <label class="text-sm">{{ $t('shop_products.fields.min_user_level') }}</label>
            <vs-input class="w-full mb-4" :placeholder="$t('shop_products.fields.enter_min_level')"
              v-model="minUserLevel" />
            <span class="text-danger text-sm" v-show="formError.has('min_user_level')">{{
              $t(formError.get('min_user_level')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-8">
            <label class="text-sm">{{ $t('shop_products.fields.price') }}*</label>
            <div class="flex items-center gap-6">
              <vs-input class="w-full" type="number" :placeholder="$t('shop_products.fields.enter_price')" v-model="price" />
              <span class="text-danger text-sm" v-show="formError.has('price')">{{ $t(formError.get('price')) }}</span>
              <vs-switch  v-model="priceInPoint" class="prise-in-switch w-24">
              <span slot="on">{{ $t('shop_products.fields.price_in_points') }}</span>
              <span slot="off">{{ $t('shop_products.fields.price_in_coins') }}</span>
            </vs-switch>
            </div>
          </div>
        </div>
        <div v-if="activeTemplate.name === 'virtual_card'" class="vx-row items-center">
          <div class="vx-col md:w-1/2 w-full mb-2 relative">
            <label class="label-for-select">{{ $t('shop_products.fields.virtual_card') }}*</label>
            <v-select class="w-full" :options="virtualCardTypes"
              v-model="virtualCard" :placeholder="$t('shop_products.fields.enter_type')"/>
            <span class="text-danger text-sm" v-show="formError.has('virtual_card')">{{
              $t(formError.get('virtual_card')) }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-5">
            <label class="text-sm">{{ $t('shop_products.fields.real_price') }}*</label>
            <vs-input class="w-full mb-4" type="number" :placeholder="$t('shop_products.fields.enter_nominal')" v-model="realPrice" />
            <span class="text-danger text-sm" v-show="formError.has('real_price')">{{ $t(formError.get('real_price')) }}</span>
          </div>
        </div>
        <div class="vx-row items-center mt-4 mb-2">
          <div class="vx-col md:w-1/2 w-full mb-1 relative">
            <label class="label-for-select">{{ $t('segments.suite.title') }}</label>
            <v-select 
              v-model="segments"   
              multiple                 
              :placeholder="$t('segments.suite.title2')" 
              :closeOnSelect="false"
              :options="segmentsOptions" 
              :selectable="option => segments.indexOf(option) < 0"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('segments')">{{ $t(formError.get('segments'))
            }}</span>
          </div>
          <div v-if="typeProduct !== 'digital'" class="vx-col md:w-1/2 w-full mb-4">
            <label class="text-sm">{{ $t('shop_products.fields.stock') + (typeProduct !== 'virtual_card' ? '*' : '') }}</label>
            <vs-input  
              class="w-full mb-4" 
              :placeholder="$t('shop_products.fields.enter_stock')"
              v-model="stock" 
              type="number"
            />
            <span class="text-danger text-sm" v-show="formError.has('stock')">{{ $t(formError.get('stock')) }}</span>
          </div>
        </div>

        <div v-if="typeProduct === 'benefit'" class="vx-row mt-4">
          <div class="vx-col w-full mb-4 flex items-center">
            <vs-switch v-model="isNeedFiles" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
            <label class="ml-4 text-lg" :class="{ 'text-orange': isNeedFiles }">{{ $t('shop_products.fields.is_need_files')
            }}</label>
            <span class="text-danger text-sm" v-show="formError.has('isNeedFiles')">{{ $t(formError.get('is_need_files'))
            }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="text-sm">{{ $t('shop_products.fields.image') }}*</label>
            <div v-if="imgCrop" class="image-crop-container my-2">
              <cropper ref="cropper" classname="cropper" :src="imgCrop" :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }" @change="changeCropImg" :stencil-props="{
                  aspectRatio: 1 / 1,
                }"></cropper>
            </div>
          </div>
          <div class="vx-col w-full mb-2">
            <div class="image-container">
              <picture-input 
                v-if="!loading" 
                class="picture-input-btn picture-input-btn--no-preview" 
                margin="0" width="160"
                ref="pictureInput" 
                @prefill="onImgPrefill" 
                @change="onImgChange"
                @error="onImgError" 
                :accept="this.$baseImageAcceptTypes"
                buttonClass="btn"
                size="10" 
                :crop="false" 
                :prefill="imageUrl"
                :alertOnError="false" 
                :removable="false"
                removeButtonClass="btn-delete-icon"
                :customStrings="{
                  drag: `<span class='material-icons'>get_app</span> ${$t('shop_products.edit.labels.upload')}`,
                  change: $t('shop_products.edit.labels.change'),
                  remove: $t('shop_products.edit.labels.delete'),
                }">
              </picture-input>
              <span class="text-danger text-sm relative" v-show="formError.has('image')" style="top: 40px;">{{ $t(formError.get('image')) }}</span>
            </div>
          </div>
        </div>

        <div v-if="activeTemplate.name === 'digital'" class="vx-row">
          <div class="vx-col w-full mb-2 mt-6">
            <label class="text-sm">{{ $t('shop_products.fields.table_with_codes') }}*</label>
            <div class="upload-excel-users-container">
              <div class="excel-file-buttons-container">
                <vs-button type="flat" icon="get_app" class="flat-button" @click="$refs.file.click()">{{
                  $t('teams.suite.buttons.upload_file') }} </vs-button>
                <input type="file" ref="file" style="display: none" @change="previewFile">
              </div>
              <vs-alert class="alert shadow-md" active="true" color="#fff" icon="info" >
              <p>{{ $t('shop_products.fields.info_codes') }}</p>
            </vs-alert>
              <div></div>

              <span class="text-danger text-sm" v-show="formError.has('digital_codes')">{{
                $t(formError.get('digital_codes')) }}</span>

              <vs-alert :active.sync="showFileName" closable icon-pack="feather" close-icon="icon-x">
                {{ fileName }}
              </vs-alert>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row fixed-buttons">
      <div class="vx-col w-full flex gap-3">
        <vs-button 
          @click="action(stayAfter = true)" 
          :disabled="actionButtonDisabled || isOnlyRead || !isFormValid">
          {{ $t('pedometer.challenges.' + type + '.buttons.' + type) }}
        </vs-button>

        <vs-button 
          @click="action()" 
          :disabled="actionButtonDisabled || isOnlyRead || !isFormValid">
          {{ $t('pedometer.challenges.' + type + '.buttons.' + type) }} {{ $t('suite.and_exit') }}
        </vs-button>
      </div>
    </div>
    <vs-popup class="holamundo" title="" :active.sync="ErrorParsePopupActive">
      <p>{{ $t('shop_products.edit.error_parse.content') }}</p>
      <div class="error-parse-button-confirm">
        <vs-button color="warning" type="filled" @click="ErrorParsePopupActive = false">{{ $t(
          'shop_products.edit.error_parse.button_confirm') }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import helpers from '@/helpers/helpers.js'
import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import loading from '@/views/components/Loading.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import editorJsConfig from '@/editorjs/config.js'
import vSelect from 'vue-select'

export default {
  components: {
    flatPickr,
    PictureInput,
    Cropper,
    vSelect,
    loading
  },
  data () {
    return {
      ErrorParsePopupActive: false,
      id: 0,
      type: 'create',
      activeTemplate: {
        name: ''
      },
      typeProduct: '',
      actionButtonDisabled: false,
      loading: true,
      file: null,
      name: '',
      blockDescription: '',
      description: '',
      priceInPoint: true,
      price: '',
      stock: '',
      minUserLevel: '',
      isActive: true,
      isNeedFiles: false,
      isDigital: false,
      digitalContent: '',
      digitalCodes: '',
      image: null,
      imageUrl: '',
      showFileName: false,
      showUploadButtons: false,
      fileName: '',
      imgCrop: null,
      imgWidth: null,
      imgHeight: null,
      isChangedImg: false,
      imageErrorPopupActive: false,
      segments: [],
      segmentsOptions: [],
      groups: [],
      groupsOptions: [],
      virtualCardTypes: [],
      virtualCard: '',
      realPrice: '',
      imageErrorPopupMessage: 'Error',
      configdateTimePicker: {
        dateFormat: 'd.m.Y',
        locale: this.$i18n.locale
      },
      editorJsConfig: Object.assign({}, editorJsConfig),
      editorOption: {
        formats: [
          'bold',
          'header',
          'italic',
          'link',
          'list',
          'blockquote',
          'image',
          'indent'
        ],
        modules: {
          toolbar: '#toolbar'
        }
      }
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser !== null) {
        return this.$store.getters.accessUser['admin-shop'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['shop_product/formError']
    },

    isFormValid () {
      const {
        name,
        price,
        image,
        typeProduct,
        virtualCard,
        realPrice,
        file,
        type,
        stock
      } = this

      if (!(name && price && image)) {
        return false
      }
      if (typeProduct === 'physical') {
        return stock !== ''
      }
      if (typeProduct === 'virtual_card') {
        return virtualCard && realPrice
      }
      if (typeProduct === 'digital' && type === 'create') {
        return file
      }
      if (typeProduct === 'digital' && type === 'edit') {
        return true
      }
      return true

    }
  },
  
  methods: {
    action (stayAfter) {
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'shop_product/create' : 'shop_product/update'
      this.dispatch(patch, stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        const editorData = await this.$refs.editor.state.editor.save()
        const payload = {
          id: this.id,
          name: this.name,
          description: JSON.stringify(editorData),
          block_description: this.blockDescription,
          price: this.priceInPoint ? this.price : 0,
          price_in_coins: this.priceInPoint ? 0 : this.price,
          min_user_level: this.minUserLevel,
          segments: this.segments,
          groups: this.groups,
          is_active: +this.isActive,
          is_need_files: +this.isNeedFiles,
          is_digital: +this.isDigital,
          digital_content: this.digitalContent,
          type: this.typeProduct,
          additional_type: (this.virtualCard && this.virtualCard.type) ? this.virtualCard.type : null,
          real_price: this.realPrice,
          image:
            (this.isChangedImg && this.image !== null) ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : null
        }
        //Для виртуальных карт поле stock не обязательное, так что отправляем по дефолту 0
        if (!this.stock && this.typeProduct === 'virtual_card') {
          payload.stock = '0'
        }
        if (this.stock) {
          payload.stock = this.stock
        }
        if (this.isDigital) {
          payload.stock = '0'
        }
        if (this.file) {
          payload.digital_codes = this.file
        }

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name:'shop-products'
          },
          routerPushToEdit: {
            name: 'shop-products-edit'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    },
    previewFile (event) {
      this.file = event.target.files[0]
      this.fileName = this.file.name
      this.showFileName = true
      this.showUploadButtons = true
    },
    onImgPrefill () {
      this.imgCrop = this.$refs.pictureInput.image
      this.image = this.imgCrop
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    changeCropImg ({coordinates, canvas}) {
      if (!this.imgWidth && !this.imgHeight) {
        this.imgWidth = coordinates.width
        this.imgHeight = coordinates.height
      }
      if ((coordinates.width === this.imgWidth || coordinates.height === this.imgHeight) && this.type === 'edit' && this.imageUrl) {
        this.isChangedImg = false
      } else {
        this.isChangedImg = true
      }
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    }
  },
  created () {
    this.$store.dispatch('segment/getSegmentsOptions').then(() => {
      this.segmentsOptions = this.$store.getters['segment/segmentsOptions']
    })
    this.$store.dispatch('shop_categories/getSuite').then(() => {
      this.groupsOptions = JSON.parse(JSON.stringify(this.$store.getters['shop_categories/suite']))
    })
    this.$store.dispatch('shop_product/getVirtualCardTypes').then(() => {
      const data = this.$store.getters['shop_product/virtualCardTypes']
      this.virtualCardTypes = Object.keys(data).map(key => ({type: key, label: data[key]}))
    })
    this.activeTemplate = localStorage.activeTemplate ? JSON.parse(localStorage.activeTemplate) : null
    this.id = this.$route.params.id
    if (this.$router.currentRoute.name === 'shop-products-edit') {
      this.type = 'edit'
      localStorage.removeItem('activeTemplate')
      this.id = this.$route.params.id
      this.$store.dispatch('shop_product/edit', { id: this.id })
        .then(() => {
          const edit = this.$store.getters['shop_product/edit']
          this.name = edit.name
          this.description = edit.description
          console.log('edit', edit)
          if (this.description !== '') {
            try {
              this.editorJsConfig.data = JSON.parse(edit.description)
            } catch (e) {
              this.ErrorParsePopupActive = true
              this.editorJsConfig.data = ''
            }
          }

          this.segments = edit.segments
          this.groups = edit.groups
          this.blockDescription = edit.blockDescription
          this.price = edit.price || edit.priceInCoins
          this.priceInPoint = (edit.priceInCoins === 0)
          this.stock =  String(edit.stock)
          this.minUserLevel = edit.minUserLevel
          this.isDigital = edit.isDigital
          this.digitalContent = edit.digitalContent
          this.virtualCard = this.virtualCardTypes.find(item => item.type === edit.additionalType)
          this.realPrice = edit.realPrice
          this.isActive = edit.isActive
          this.id = edit.id
          this.typeProduct = edit.type
          this.isNeedFiles = edit.isNeedFiles

          this.imageUrl = this.getUrl(edit.imagePath, `${this.$http.defaults.baseURL}/api/admin-panel`)

          this.activeTemplate = {name: this.typeProduct}
          this.loading = false
        })
    } else {
      this.isDigital = this.activeTemplate.name === 'digital'
      this.loading = false
      this.typeProduct = this.activeTemplate.name
    }
    
    this.$store.commit('shop_product/clearFormError')
  }
}
</script>

<style lang="scss" scoped>

.error-parse-button-confirm {
  margin-top: 20px;
}

.flat-button {
  font-size: 16px;
  padding-left: 0px !important;
  &:hover {
    background: transparent !important;
  }
}

.alert {
  height: auto !important;
}

::v-deep {

  .card-input:focus {
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.15) !important;
  }
  .picture {
    z-index: 0;
    position: relative;
  }

  .vs-icon {
    font-size: 1.5em;
  }

  .vs-alert {
    color: #B9B9B9;
    padding-right: 80px;
    font-size: 12px;
    .vs-icon {
      color: #DA7E3C;
      height: 18px;
      top: 10px;
    }
  }
}
</style>

<style lang="scss">
  .prise-in-switch.vs-switch-primary {
    &.vs-switch-active {
      background: rgb(218,126,60);
    }
    background: rgb(46, 113, 238);
    .text-switch {
      color: #fff;
    }
  }

  .label-for-select {
    position: absolute;
    top: -25px;
    font-size: 12px;
  }

  label {
    line-height: 2;
  }

  .product .input-span-placeholder {
    font-size: 14px !important;
    
  }

</style>