<template>
  <div v-if="!isNoAccess" id="dashboard" ref="dashboard" :class="{print: onPrint}">
    <div >

      <section class="title-print-page flex flex-col justify-between bg-white html2pdf__page-break" style="height: 100vh; display: none !important;">
        <div class="title-print-page__header">
          
            <div class="flex justify-between">
              <div class="title-print-page__header-left flex items-center gap-8">
                <img :src="settingApp.icon" alt="Logo" width="32px" height="32px">
                <span class="title-print-page__title">{{ settingApp.name }}</span>
              </div>
              <div class="title-print-page__header-right flex items-center">
                <svg width="24" height="32" viewBox="0 0 70 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M43.4377 18.4456C48.4297 18.4456 52.4497 14.4253 52.4497 9.43351C52.4497 4.44175 48.4297 0.428711 43.4377 0.428711C38.4461 0.428711 34.433 4.44175 34.433 9.43351C34.433 14.4253 38.4461 18.4456 43.4377 18.4456ZM29.4002 53.8856L44.7886 62.5311L34.8934 71.0271C32.7206 72.8641 32.6812 75.3577 34.0682 76.9126C35.4954 78.5076 37.9743 78.8041 40.242 76.8797L54.27 64.9225C56.2523 63.2285 55.9421 59.6285 53.5578 58.0771L38.3898 48.1352L41.3703 37.7C41.7502 36.3073 43.3551 36.1136 44.1882 37.3342L48.666 43.89C49.7538 45.4486 51.7506 46.0585 53.5362 45.2333L66.4321 39.4959C68.7759 38.4409 69.6632 36.3636 68.6483 34.2205C67.6078 32.132 65.5777 31.4931 63.234 32.5338L53.1272 37.0009L46.1545 27.3765C42.4517 22.2714 37.8433 20.2078 30.3493 20.7703L16.0658 21.782C13.7362 21.95 12.1299 23.4687 11.794 25.8858L9.87667 40.363C9.50782 42.9625 10.8185 44.7841 13.1512 45.0399C15.4952 45.248 17.014 43.8972 17.3571 41.2976L19.0848 29.5766L24.7066 29.1748C25.9087 29.0688 27.053 29.9021 26.6399 31.3678L23.3164 42.9961C21.33 49.9891 25.8942 51.895 29.4002 53.8856ZM2.1664 88.9904C3.76149 90.3444 6.16737 90.44 8.25262 88.4278L21.4223 75.3787C22.5506 74.2575 22.7989 73.9877 23.4819 72.271L28.8956 58.2584L27.4034 57.4414C24.3064 55.7652 22.1191 54.0413 20.6293 52.2043L15.8769 69.0255L2.23229 82.6157C0.0522079 84.7483 0.403369 87.4572 2.1664 88.9904Z" :fill="settingApp.color"/>
                  </svg>
              </div>
            </div>
            <div class="title-print-page__line" :style="`background-color: ${settingApp.color}`"></div>
        </div> 
        <div class="title-print-main flex items-center">
          <div class="title-print-content flex ">
            <div class="left-border" :style="`background-color: ${settingApp.color}`"></div>
            <div>
              <div class="title-print-content__title">Отчет об активности пользователей</div>
              <div class="title-print-content__subtitle">Сентябрь 2022 г. — Сентябрь 2023 г.</div>
            </div>
          </div>
        </div>
        <div class="title-print-page__footer">
          <img src="@/assets/images/cross_life_logo.svg" width="250" alt="">
        </div>
      </section>
      <loading v-if="loading"></loading>
       <live-statistics v-if="loadedLiveStatistics" ref="LiveStatistics" class="html2pdf__page-break"></live-statistics>
    </div>


    <section class="dashboard-statistics pagebreak">
      <h2 class="mb-10">{{ $t('dashboard.titles.statistics') }}</h2>
      <div class="flex w-full justify-between items-center mr-0 mb-8">
        <div class="w-1/4 font-bold bg-white">
          <v-select
            v-model="statisticsPeriod"
            :clearable="false"
            :options="statisticsPeriodsOptions"
            :value="statisticsPeriod"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          />
        </div>
        <div class="w-auto">
          <!-- <vs-button @click="generatePDF" class="">Загрузить отчет в PDF</vs-button> -->
        </div>
      </div>
        <large-graphs-statistics v-if="loadedLargeGraphsStatistics" ref="LargeGraphsStatistics" :statisticsPeriod="statisticsPeriod"></large-graphs-statistics>
        <interests-statistics v-if="loadedInterestsStatistics" ref="InterestsStatistics" :statisticsPeriod="statisticsPeriod"></interests-statistics>
        <courses-and-programs v-if="loadedCoursesAndPrograms" ref="CoursesAndPrograms" :statisticsPeriod="statisticsPeriod"></courses-and-programs>
        <communication v-if="loadedCommunication" ref="Communication" :statisticsPeriod="statisticsPeriod"></communication>
        <events-statistics v-if="loadedEventsStatistics" ref="EventsStatistics" :statisticsPeriod="statisticsPeriod"></events-statistics>
        <shop-statistics v-if="loadedShopStatistics" ref="ShopStatistics" :statisticsPeriod="statisticsPeriod"></shop-statistics>
    </section>
  </div>
</template>

<script>
import _color from '@/assets/utils/color.js'
import loading from '@/views/components/Loading.vue'
// import LargeGraphsStatistics from '@/views/components/LargeGraphsStatistics.vue'
// import InfiniteScroll from 'v-infinite-scroll'
// import jsPDF from 'jspdf'
// import html2canvas from 'html2canvas'
// import html2pdf from 'html2pdf.js'
import vSelect from 'vue-select'

// import 'v-infinite-scroll/dist/v-infinite-scroll.css'

export default {
  components: {
    loading,
    'v-select': vSelect
    // InfiniteScroll
  },
  data () {
    return {
      onPrint: true,
      loading: false,
      settingApp: {
        name: '',
        icon: '',
        color: ''
      },
      statisticsPeriod: this.$t('dashboard.labels.month'),
      statisticsPeriodsOptions: [this.$t('dashboard.labels.month'), this.$t('dashboard.labels.year'), this.$t('dashboard.labels.all_time')],

      dataComponent: [
        {
          name: 'LiveStatistics',
          import: import('@/views/components/statistics/LiveStatistics')
        },
        {
          name: 'LargeGraphsStatistics',
          import: import('@/views/components/statistics/LargeGraphsStatistics')
        },
        {
          name: 'InterestsStatistics',
          import: import('@/views/components/statistics/InterestsStatistics')
        },
        {
          name: 'CoursesAndPrograms',
          import: import('@/views/components/statistics/CoursesAndPrograms')
        },
        {
          name: 'Communication',
          import: import('@/views/components/statistics/Communication')
        },
        {
          name: 'EventsStatistics',
          import: import('@/views/components/statistics/EventsStatistics')
        },
        {
          name: 'ShopStatistics',
          import: import('@/views/components/statistics/ShopStatistics')
        }
        
      ],

      loadedLiveStatistics: false,
      loadedLargeGraphsStatistics: false,
      loadedInterestsStatistics: false,
      loadedCoursesAndPrograms: false,
      loadedCommunication: false,
      loadedEventsStatistics: false,
      loadedShopStatistics: false,

      componentCounter: 1
    }
  },
  computed: {
    userInfo () {
      return this.$store.getters.userInfo
    },
    isNoAccess () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-dashboard'] === 'none'
      }
    }
  },

  async mounted () {
    this.loadComponent()
    window.addEventListener('scroll', this.handleScroll)
    // this.getSettingApp()
  },
  methods: {

    async handleScroll () {
      try {
        const component = this.$refs[this.dataComponent[this.componentCounter - 1].name]
        if (!component) {
          this.componentCounter++
          this.loadComponent(this.componentCounter)
          return
        }
        if (component && (this.componentCounter < this.dataComponent.length)) {
          const component1Bottom = await component.$el.getBoundingClientRect().bottom
          if (component1Bottom <= window.innerHeight) {
          // Load the second component
            this.componentCounter++
            this.loadComponent(this.componentCounter)
          }
        }
      } catch (error) {
        // console.error(`Failed to load component ${this.componentCounter}:`, error)
      }
    },
    async loadComponent (index = 1) {
      console.log('loadComponent', index)
      const componentName = this.dataComponent[index - 1].name
      try {
        const { default: Component } = await this.dataComponent[index - 1].import
        this.$options.components[componentName] = await Component

        this[`loaded${componentName}`] = true
        console.log('componentName', componentName)
      } catch (error) {
        throw new Error(`Failed to load component ${componentName}: ${error.message}`)
      }
      setTimeout(() => {
        const contentHeight = this.$refs.dashboard.offsetHeight + 200
        const viewportHeight = window.innerHeight
        
        if (contentHeight < viewportHeight) {
          this.handleScroll()
        }
      }, 500)
      
    },
    async getSettingApp () {
      const { data: { data: settingsData } } = await this.$store.dispatch('content_page/getSuite')
      const settingIds = { name: '', color: '', icon: '' }
      for (const setting of settingsData) {
        switch (setting.slug) {
        case 'name':
          settingIds.name = setting.id
          break
        case 'color':
          settingIds.color = setting.id
          break
        case 'icon':
          settingIds.icon = setting.id
          break
        default:
          break
        }
      }
      const [nameContent, colorContent, iconContent] = await Promise.all([
        this.$store.dispatch('content_page/edit', { id: settingIds.name }),
        this.$store.dispatch('content_page/edit', { id: settingIds.color }),
        this.$store.dispatch('content_page/edit', { id: settingIds.icon })
      ].map(p => p.then(res => res.data.data.content)))

      this.settingApp = { name: nameContent, color: colorContent, icon: iconContent }
    }
    // generatePDF () {
    //   // window.print()
    //   this.getSettingApp()
    //   const content = document.getElementById('dashboard')
    //   const opt = {
    //     margin: 16,
    //     filename: 'report.pdf',
    //     image: { type: 'jpeg', quality: 1 },
    //     pagebreak: { mode: 'legacy' },
    //     html2canvas: {
    //       scale: 4,
          
    //       useCORS: true,
    //       letterRendering: true,
    //       allowTaint: true,
    //       width: 1400,
    //       left: 20,
    //       right: 20
    //     },
    //     jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
    //   }
    //   html2pdf().set(opt).from(content).save() 
    // }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>

section {
  margin-bottom: 64px;
  // background-color: #f5f5f5;
}

h2 {
  font-size: 32px;
  font-weight: 650;
}

h3 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 32px;
}

.title-print-page {
  width: 100%;
  color: #000;
  font-family: 'Roboto', sans-serif;
  &__header {
    margin-top: 16px;
  }

  &__header-left {
    img {
      border-radius : 16%;
    }

    svg {
      size: 12px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
  }

  &__line {
    width: 100%;
    height: 2px;
    margin-top: 16px;
  }

  &__header-right {
  }

  &__footer {
  }
}
.title-print-main {
  .left-border {
    width: 8px;
    margin-right: 24px;
  }
}

.title-print-content {
  &__title {
    font-weight: 600;
    font-size: 32px; 
  }
  &__subtitle {
    color: #989898;
    font-size: 24px;
  }
}


</style>

<style lang="scss">

.vx-card {
  // box-shadow: none !important;
}
.statistics-h3 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 32px;
}

// #dashboard {
//     width: 1400px;
//   }


@media print {
 #dashboard {
    width: 1200px;
  }
  statistics-live__grid {
    width: 100% !important;
  }
}

 
</style>
