import axios from '@/axios.js'

export default {

  // statistics/live

  getCountCompletedTrainings ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/live/count-completed-trainings')
        .then((response) => {
          commit('retrieveCountCompletedTrainings', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getTopLikes ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/live/count-likes')
        .then((response) => {        
          commit('retrieveTopLikes', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCountMessages ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/live/count-messages')
        .then((response) => {
          commit('retrieveCountMessages', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCountPosts ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/live/count-posts-in-the-activity-feed')
        .then((response) => {
          commit('retrieveCountPosts', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCountReceivedPoints ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/live/count-received-points')
        .then((response) => {
          commit('retrieveCountReceivedPoints', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCountRegistrations ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/live/count-registrations')
        .then((response) => {
          commit('retrieveCountRegistrations', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCountFamilyUsers ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/live/count-family-users')
        .then((response) => {
          commit('retrieveCountFamilyUsers', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getUsersActivity ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/live/users-activity')
        .then((response) => {
          commit('retrieveUsersActivity', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // general

  getCountUsers ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/general/count-users')
        .then((response) => {
          commit('retrieveCountUsers', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCountUsersLogIn ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/general/count-users-log-in-in-app')
        .then((response) => {
          commit('retrieveCountUsersLogIn', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getUniqueCountUsersLogIn ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/general/count-unique-users-log-in-in-app')
        .then((response) => {
          commit('retrieveUniqueCountUsersLogIn', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getTimeSpentInApp ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/general/how-much-time-users-spent-in-app')
        .then((response) => {
          commit('retrieveTimeSpentInApp', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getInterests ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/general/interests')
        .then((response) => {
          commit('retrieveInterests', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // events

  getPastEvents ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/events/past-events')
        .then((response) => {
          commit('retrievePastEvents', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getUsersParticipatedInEvents ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/events/users-participated-in-events')
        .then((response) => {
          commit('retrieveUsersParticipatedInEvents', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getReports ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/events/reports')
        .then((response) => {
          commit('retrieveReports', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getTopEvents ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/events/top-events')
        .then((response) => {
          commit('retrieveTopEvents', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getTopLikesForReports ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/events/top-reports')
        .then((response) => {
          commit('retrieveTopLikesForReports', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // shop

  getShopOrders ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/shop/orders')
        .then((response) => {
          commit('retrieveShopOrders', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getShopOrdersFromWarehouse ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/shop/count-ordered-in-warehouse')
        .then((response) => {
          commit('retrieveShopOrdersFromWarehouse', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getShopOrderedDigital ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/shop/count-ordered-digital-products')
        .then((response) => {
          commit('retrieveShopOrderedDigital', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getTopProducts  ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/shop/top-popular-products')
        .then((response) => {
          commit('retrieveTopProducts', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getStockProducts ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/shop/stock-products')
        .then((response) => {
          commit('retrieveStockProducts', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // courses-and-programs

  getPrograms ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/courses-and-programs/added-programs')
        .then((response) => {
          commit('retrievePrograms', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getChallenges ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/courses-and-programs/added-challenges')
        .then((response) => {
          commit('retrieveChallenges', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCompletedTrainings ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/courses-and-programs/count-completed-trainings')
        .then((response) => {
          commit('retrieveCompletedTrainings', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getTopActiveUsers ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/courses-and-programs/top-active-users')
        .then((response) => {
          commit('retrieveTopActiveUsers', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getTopSociableUsers ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/courses-and-programs/top-sociable-users')
        .then((response) => {
          commit('retrieveTopSociableUsers', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getTopActiveTeams ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/courses-and-programs/top-active-teams')
        .then((response) => {
          commit('retrieveTopActiveTeams', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getTopCoursesAndChallenges ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/courses-and-programs/top-courses-and-challenges')
        .then((response) => {
          commit('retrieveTopCoursesAndChallenges', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getTopReports ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/courses-and-programs/top-reports')
        .then((response) => {
          commit('retrieveTopReports', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // communications

  getCommunicationsEmail ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/communications/count-email-messages')
        .then((response) => {
          commit('retrieveCommunicationsEmail', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommunicationsMessage ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/communications/count-message-users-send-each-other')
        .then((response) => {
          commit('retrieveCommunicationsMessage', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommunicationsPush ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/communications/count-push-messages')
        .then((response) => {
          commit('retrieveCommunicationsPush', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommunicationsPosts ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/communications/count-read-posts')
        .then((response) => {
          commit('retrieveCommunicationsPosts', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommunicationsLikes ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/statistics/communications/top-likes')
        .then((response) => {
          commit('retrieveCommunicationsLikes', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }


}
