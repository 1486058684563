<template>
  <div class="flex items-center justify-center">
    <vs-avatar :src="avatarUrl" class="flex-shrink-0 mr-2" size="30px" />
    <img 
      :src="avatarUrl" 
      @error="avatarUrl=require('@/assets/images/user/avatar/default.svg')" 
      style="display: none" 
      alt=""
      />
  </div>
</template>

<script>

export default {
  name: 'CellRendererLink',
  data () {
    return {
      'avatarUrl': null
    }
  },
  computed: {
    url () {
      return '/apps/user/user-view/268'

      // Below line will be for actual product
      // Currently it's commented due to demo purpose - Above url is for demo purpose
      // return "/apps/user/user-view/" + this.params.data.id
    }
  },
  mounted () {
    const url = this.params.value
    this.avatarUrl = (url === null) ? require('@/assets/images/user/avatar/default.svg') : this.getUrl(url)
  }
}
</script>
