import state from './moduleLabelState.js'
import mutations from './moduleLabelMutations.js'
import actions from './moduleLabelAction.js'
import getters from './moduleLabelGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
