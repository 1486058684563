<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-else class="vx-card tabs-container px-6 pt-6 pb-3">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <ul class="list">
            <li class="list__item">
              ID: {{ id }}
            </li>
            <li class="list__item">
              {{ $t('shop_orders.table.products') }}: <template v-for="(product, index) in this.products">
                <template v-if="index">, </template>{{ product['product']['name'] }}
              </template>
            </li>
            <li class="list__item">
              {{ $t('shop_orders.table.user') }}: #{{ user.id }} ({{ user.name }})
            </li>
<!--          Получатель  -->
            <li class="list__item">
              {{ $t('shop_orders.table.customer') }}: {{ order.customerName }} {{ order.customerLastname }} {{ order.customerPatronymic }}
            </li>
            <div class="vx-row" v-if="files.length">
              <div class="vx-col w-full mb-2">
                <h5 class="mt-2 mb-2">Прикрепленные файлы</h5>
                <ul class="flex gap-4 flex-wrap items-center">
                  <li v-for="(file, index) in files" :key="index" >
                    <vs-button color="primary" type="gradient" icon-pack="feather" icon="icon-download" @click="downloadFile(file.id)">{{ index+1 }}</vs-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <h5 class="mt-2 mb-2">{{ $t('shop_orders.table.customer_name') }}</h5>
                <vs-input class="w-full mb-4" v-model="customerName" />
                <span class="text-danger text-sm" v-show="formError.has('status')">{{ $t(formError.get('status')) }}</span>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <h5 class="mt-2 mb-2">{{ $t('shop_orders.table.customer_lastname') }}</h5>
                <vs-input class="w-full mb-4" v-model="customerLastName" />
                <span class="text-danger text-sm" v-show="formError.has('status')">{{ $t(formError.get('status')) }}</span>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <h5 class="mt-2 mb-2">{{ $t('shop_orders.table.customer_patronymic') }}</h5>
                <vs-input class="w-full mb-4" v-model="customerPatronymic" />
                <span class="text-danger text-sm" v-show="formError.has('status')">{{ $t(formError.get('status')) }}</span>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <h5 class="mt-2 mb-2">{{ $t('shop_orders.table.comment') }}</h5>
                <vs-textarea class="w-full mb-4 bgWhite" v-model="comment" />
                <span class="text-danger text-sm" v-show="formError.has('status')">{{ $t(formError.get('status')) }}</span>
              </div>
            </div>

            <li v-if="deliveryAddressCity || deliveryAddress || deliveryAddressApartment"
              class="list__item">
              {{ $t('shop_orders.table.customer_address') }}:
              {{ deliveryPostcode }},
              {{ deliveryAddressCity }},
              {{ deliveryAddress }},
              {{ deliveryAddressApartment }}
            </li>
            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <h5 class="mt-2 mb-2">{{ $t('shop_orders.table.delivery_postcode') }}</h5>
                <vs-input class="w-full mb-4" v-model="deliveryPostcode"/>
                <span class="text-danger text-sm" v-show="formError.has('status')">{{ $t(formError.get('status')) }}</span>
              </div>
            </div>

            <!--Доставка-->
            <vx-card class="mb-6" :title="$t('shop_orders.table.address')">
            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <h5 class="mt-2 mb-2">{{ $t('shop_orders.table.delivery_address_city') }}</h5>
                <vs-input class="w-full mb-4" v-model="deliveryAddressCity"/>
                <span class="text-danger text-sm" v-show="formError.has('status')">{{ $t(formError.get('status')) }}</span>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <h5 class="mt-2 mb-2">{{ $t('shop_orders.table.delivery_address_without_postcode') }}</h5>
                <vs-input class="w-full mb-4" v-model="deliveryAddress"/>
                <span class="text-danger text-sm" v-show="formError.has('status')">{{ $t(formError.get('status')) }}</span>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <h5 class="mt-2 mb-2">{{ $t('shop_orders.table.delivery_address_apartment') }}</h5>
                <vs-input class="w-full mb-4" v-model="deliveryAddressApartment"/>
                <span class="text-danger text-sm" v-show="formError.has('status')">{{ $t(formError.get('status')) }}</span>
              </div>
            </div>
              </vx-card>
            <!--!Доставка-->

            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <h5 class="mt-2 mb-2">{{ $t('shop_orders.table.customer_phone') }}</h5>
                <vs-input class="w-full mb-4" v-model="customerPhone"/>
                <span class="text-danger text-sm" v-show="formError.has('status')">{{ $t(formError.get('status')) }}</span>
              </div>
            </div>
            <div v-if="isDigitalProduct" class="vx-row">
              <div class="vx-col w-full mb-2">
                <h5 class="mt-2 mb-2">{{ $t('shop_orders.table.digital_id') }}</h5>
                {{digitalId}}
                <h5 class="mt-2 mb-2">{{ $t('shop_orders.table.digital_code') }}</h5>
                {{digitalCode}}
              </div>
            </div>
            <li class="list__item">
              {{ $t('shop_orders.table.sum') }}: {{ sum }}
            </li>
          </ul>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <h5 class="mt-2 mb-2">{{ $t('shop_orders.labels.change_status') }}</h5>
          <v-select v-model="status" :clearable="false" :options="statuses" v-validate="'required'" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm" v-show="formError.has('status')">{{ $t(formError.get('status')) }}</span>
        </div>
      </div>
    </div>
    <div class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button 
            @click="action(stayAfter = true)" 
            :disabled="actionButtonDisabled || isOnlyRead">
            {{ $t('pedometer.challenges.' + type + '.buttons.' + type) }}
          </vs-button>

          <vs-button 
            @click="action()" 
            :disabled="actionButtonDisabled || isOnlyRead">
            {{ $t('pedometer.challenges.' + type + '.buttons.' + type) }} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
    <vs-popup class="holamundo" :title="$t('shop_orders.popups.message.title')" :active.sync="messagePopupActive">
      <p>{{ messagePopupContent }}</p>
      <div class="message-popups-button">
        <vs-button color="primary" type="filled" @click="messagePopupActive=false">{{ $t('shop_orders.popups.message.button') }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import loading from '@/views/components/Loading.vue'
import vSelect from 'vue-select'
import helpers from '@/helpers/helpers.js'

export default{
  components: {
    loading,
    'v-select': vSelect
  },
  data () {
    return {
      id: 0,
      type: 'edit',
      isDigitalProduct: false,
      actionButtonDisabled: false,
      loading: true,
      status: 0,
      sum: 0,
      order: '',
      statuses: [],
      user: '',
      products: [],
      customerName: '',
      customerLastName: '',
      customerPatronymic: '',
      comment: '',
      deliveryPostcode: '',
      deliveryAddress: '',
      deliveryAddressCity: '',
      deliveryAddressApartment: '',
      customerPhone: '',
      digitalId: '',
      digitalCode: '',
      messagePopupActive: false,
      showMessagePopupWaitMessage: false,
      messagePopupContent: '',
      files: []
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-shop'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['announcement/formError']
    }
  },
  methods: {
    openMessagePopup (message) {
      this.messagePopupContent = this.$t(`shop_orders.popups.message.${message}`)
      this.messagePopupActive = true
    },
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      this.dispatch('shop_order/update', stayAfter)
    },
    async dispatch (patch, stayAfter) {
      try {
        const payload = {
          id: this.id,
          status: this.status.value,
          customer_name: this.customerName,
          customer_lastname: this.customerLastName,
          customer_patronymic: this.customerPatronymic,
          comment: this.comment,
          delivery_postcode: this.deliveryPostcode,
          delivery_address: this.deliveryAddress,
          delivery_address_city: this.deliveryAddressCity,
          delivery_address_apartment: this.deliveryAddressApartment,
          customer_phone: this.customerPhone
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name:'shop-orders'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    },
    update () {
      this.actionButtonDisabled = true
      const payload = {
        id: this.id,
        status: this.status.value,
        customer_name: this.customerName,
        customer_lastname: this.customerLastName,
        customer_patronymic: this.customerPatronymic,
        comment: this.comment,
        delivery_postcode: this.deliveryPostcode,
        delivery_address: this.deliveryAddress,
        delivery_address_city: this.deliveryAddressCity,
        delivery_address_apartment: this.deliveryAddressApartment,
        customer_phone: this.customerPhone
      }
      this.$store.dispatch('shop_order/update', payload)
        .then(() => {
          this.actionButtonDisabled = false
          this.$router.push({name: 'shop-orders'})
        })
        .catch((error) => {
          this.actionButtonDisabled = false
          if (error.response.data.message) {
            this.openMessagePopup(error.response.data.message)
          }
        })
    },
    downloadFile (id) {
      console.log('downloadFile', id)
      this.$store.dispatch('shop_order/downloadFile', id)
    }
  },
  created () {
    this.id = this.$route.params.id
    this.$store.dispatch('shop_order/edit', { id: this.id})
      .then(() => {
        const edit = this.$store.getters['shop_order/edit']
        console.log(edit)
        this.status = edit.status
        this.user = edit.user
        this.sum = edit.sum
        this.products = edit.products
        this.statuses = edit.statuses
        this.order = edit
        this.loading = false
        this.customerName = this.order.customerName
        this.comment = this.order.comment
        this.customerLastName = this.order.customerLastname
        this.customerPatronymic = this.order.customerPatronymic
        this.deliveryPostcode = this.order.deliveryPostcode
        this.deliveryAddress = this.order.deliveryAddress
        this.deliveryAddressCity = this.order.deliveryAddressCity
        this.deliveryAddressApartment = this.order.deliveryAddressApartment
        this.customerPhone = this.order.customerPhone
        this.files = edit.files
        if (edit.products[0].product.is_digital) {
          this.isDigitalProduct = true
          this.digitalId = edit.products[0].product.id
          this.digitalCode = edit.products[0].digital_code
        }
      })
    this.$store.commit('shop_order/clearFormError')
  }
}
</script>

<style>
.message-popups-button{
  margin-top: 30px;
}
.bgWhite {
  background: white
}
</style>
