<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-show="!loading">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col flex w-1/4 mb-6">
          <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"></vs-switch>
          <label class="ml-4 text-lg" :class="{ 'text-orange': isActive }">{{ $t('categories.edit.labels.is_active') }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active')) }}</span>
        </div>
      </div>
      <vx-card class="mb-6" :title="$t('categories.create.labels.accessibility_settings')">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('categories.edit.labels.publication_at') }}</label>
            <flat-pickr
              class="w-full"
              :config="configPublicationAtPicker"
              v-model="publicationAt"
              :placeholder="$t('categories.edit.labels.publication_at')"
              @on-change="onFromChangeRegistration"
            />
            <span class="text-danger text-sm pickatime" v-show="formError.has('publication_at')">{{
              $t(formError.get('publication_at'))
            }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t('categories.edit.labels.date_from') }}</label>
            <flat-pickr
              class="w-full"
              :config="configFromdateTimePicker"
              v-model="dateFrom"
              :placeholder="$t('categories.edit.labels.date_from')"
              @on-change="onFromChange"
            />
            <span class="text-danger text-sm" v-show="formError.has('date_from')">{{ $t(formError.get('date_from')) }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t('categories.edit.labels.date_to') }}</label>
            <flat-pickr
              class="w-full"
              :config="configTodateTimePicker"
              v-model="dateTo"
              :placeholder="$t('categories.edit.labels.date_to')"
              @on-change="onToChange"
            />
            <span class="text-danger text-sm" v-show="formError.has('date_to')">{{ $t(formError.get('date_to')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mt-6 flex">
            <vs-switch v-model="isRepeated" icon-pack="feather" vs-icon="icon-check"></vs-switch>
            <label class="ml-4 text-lg" :class="{ 'text-orange': isRepeated }" @click="isRepeated = !isRepeated">{{ $t('categories.suite.polling_health.repeated_passage') }}</label>
          </div>
        </div>
        <transition name="fade">
          <div v-show="isRepeated" class="vx-row mt-6">
            <div class="vx-col w-full md:w-1/2">
              <label :class="{ 'opacity-50': numberDaysBeforeRepeatedPoll > 0 }">{{$t('categories.suite.polling_health.on_specific_date')}}</label>
              <flat-pickr
                class="w-full"
                :config="configRepeatedPollDate"
                v-model="repeatedPollDate"
                :disabled="numberDaysBeforeRepeatedPoll > 0"
              />
            </div>
            <div class="vx-col w-full md:w-1/2">
              <label :class="{ 'opacity-50': repeatedPollDate }">{{$t('categories.suite.polling_health.certain_number_of_days')}}</label>
              <vs-input 
                class="w-full"
                type="number"
                min="0"
                :disabled="!!repeatedPollDate"
                v-model="numberDaysBeforeRepeatedPoll">
              </vs-input>
            </div>
          </div>
        </transition>
      </vx-card>

      <vx-card :title="$t('categories.create.labels.description')">
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t('categories.edit.labels.name') }}*</label>
            <vs-input class="w-full" v-model="name" />
            <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t(`categories.edit.labels.${type}.entrance_point`) }}</label>
            <vs-input class="w-full" type="number" v-model="entrancePoint" />
            <span class="text-danger text-sm" v-show="formError.has('entrance_point')">{{ $t(formError.get('entrance_point')) }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t(`categories.edit.labels.${type}.entrance_coin`) }}</label>
            <vs-input class="w-full" type="number" v-model="entranceCoin" />
            <span class="text-danger text-sm" v-show="formError.has('coins_in')">{{ $t(formError.get('coins_in')) }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t(`categories.edit.labels.${type}.point`) }}</label>
            <vs-input class="w-full" type="number" v-model="point" />
            <span class="text-danger text-sm" v-show="formError.has('point')">{{ $t(formError.get('point')) }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t(`categories.edit.labels.${type}.coin`) }}</label>
            <vs-input class="w-full" type="number" v-model="coin" />
            <span class="text-danger text-sm" v-show="formError.has('coins_finish')">{{ $t(formError.get('coins_finish')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-textarea class="w-full mb-2" :label="$t('categories.suite.polling_health.announcement')" v-model="announcement" />
            <span class="text-danger text-sm" v-show="formError.has('announcement')">{{ $t(formError.get('announcement')) }}</span>
          </div>
        </div>
      </vx-card>
      <vx-card class="mt-4" :title="$t(`categories.suite.polling_health.recommendations.title`)">
        <div class="vx-row" v-if="loaded" style="flex-direction: column-reverse;">
          <div class="vx-col w-full mb-2" v-for="item in recommendationsStack" :key="item.name">
            <vx-card no-shadow card-border :title="$t(`categories.suite.polling_health.recommendations.${item.name}`)">
              <editor :ref="item.name" :holder="item.name" :id="item.name" :config="item.config" class="pb-0" />
              <span class="text-danger text-sm" v-show="formError.has('block_description')">{{
                $t(formError.get('block_description'))
              }}</span>
            </vx-card>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-container" style="width: 150px; height: 150px; margin: 0 auto">
              <picture-input
                v-if="!loading"
                ref="pictureInput"
                @prefill="onImgPrefill"
                @change="onImgChange"
                @error="onImgError"
                width="150"
                height="150"
                margin="16"
                :accept="this.$baseImageAcceptTypes"
                buttonClass="btn"
                size="10"
                :hideChangeButton="true"
                :crop="false"
                :prefill="imageUrl"
                :alertOnError="false"
                :customStrings="{
                  drag: $t('categories.edit.labels.image'),
                }"
              >
              </picture-input>
              <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
            </div>
          </div>
        </div>
        <div v-if="imgCrop" class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-crop-container">
              <cropper
                ref="cropper"
                classname="cropper"
                :src="imgCrop"
                :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }"
                @change="changeCropImg"
                :stencil-props="{
                }"
              ></cropper>
            </div>
          </div>
        </div>
      </vx-card>
      <div class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <!--если вдруг очень нужно удалить (для тестирования)-->
          <!-- <vs-button @click="openDeletePopup()" :disabled="isOnlyRead">{{ $t('categories.edit.buttons.delete') }} </vs-button>  -->

          <vs-button  @click="action(stayAfter = true)" :disabled="buttonDisabled || isOnlyRead">
            {{ $t(`categories.edit.buttons.${editType}`) }}
          </vs-button>
          <vs-button  @click="action()" :disabled="buttonDisabled || isOnlyRead">
            {{ $t(`categories.edit.buttons.${editType}`) }} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
    </div>
    <vs-popup class="holamundo" title="Image error" :active.sync="imageErrorPopupActive">
      <p>{{ imageErrorPopupMessage }}</p>
    </vs-popup>
    <vs-popup class="holamundo" :title="$t('categories.suite.popups.delete.title')" :active.sync="deletePopupActive">
      <p>{{ deletePopupContent }}</p>
      <div class="delete-confirm-popup-buttons" v-if="!showDeletePopupWaitMessage">
        <vs-button color="primary" type="filled" @click="deleteCategory">
          {{ $t('categories.suite.popups.delete.buttons.confirm') }}
        </vs-button>
        <vs-button color="warning" type="filled" @click="deletePopupActive = false">
          {{ $t('categories.suite.popups.delete.buttons.cancel') }}
        </vs-button>
      </div>
      <p v-if="showDeletePopupWaitMessage" class="delete-popup-wait-message">
        {{ $t('categories.suite.popups.delete.wait_message') }}
        <font-awesome-icon icon="spinner" spin />
      </p>
    </vs-popup>

    <vs-popup v-if="this.categoryType" class="holamundo" title="" :active.sync="ErrorParsePopupActive">
      <p>{{ $t(`categories.edit.${this.categoryType.value}.error_parse.content`) }}</p>
      <div class="error-parse-button-confirm">
        <vs-button color="warning" type="filled" @click="ErrorParsePopupActive=false">{{ $t(`categories.edit.${this.categoryType.value}.error_parse.button_confirm`) }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import flatPickr from 'vue-flatpickr-component'
import locale from 'flatpickr/dist/l10n/ru'
import 'flatpickr/dist/flatpickr.css'

import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import editorJsConfig from '@/editorjs/config.js'
import loading from '@/views/components/Loading.vue'
import helpers from '@/helpers/helpers.js'

import vSelect from 'vue-select'
import moment from 'moment'

export default {
  components: {
    PictureInput,
    Cropper,
    loading,
    flatPickr,
    'v-select': vSelect
  },
  data () {
    return {
      editType: 'update',
      ErrorParsePopupActive: false,
      id: 0,
      name: '',
      announcement: '',
      description: '',
      blockDescription: null,
      image: null,
      imageUrl: '',
      imgCrop: null,
      imgWidth: null,
      imgHeight: null,
      isChangedImg: false,
      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error',
      recommendationsStack: [
        {
          name: 'risks__level_4',
          config: { ...editorJsConfig}
        },
        {
          name: 'risks__level_3',
          config: { ...editorJsConfig}
        },
        {
          name: 'risks__level_2',
          config: { ...editorJsConfig}
        },
        {
          name: 'risks__level_1',
          config: { ...editorJsConfig}
        },
        {
          name: 'attention',
          config: { ...editorJsConfig}
        },
        {
          name: 'can_be_better',
          config: {...editorJsConfig}
        },
        {
          name: 'almost_good',
          config: { ...editorJsConfig}
        },
        {
          name: 'all_right',
          config: { ...editorJsConfig}
        },
        {
          name: 'all_great',
          config: {...editorJsConfig}
        },
        {
          name: 'perfectly',
          config: {...editorJsConfig}
        }
      ],

      buttonDisabled: false,
      point: 0,
      coin: 0,
      entrancePoint: 0,
      entranceCoin: 0,
      isActive: true,
      isRepeated: false,
      numberDaysBeforeRepeatedPoll: '',
      categoryType: { label: this.$t('menu.polling_health'), value: 'polling_health' },
      loaded: false,
      loading: true,
      type: 'polling_health',
      typeProgramData: [],
      typeProgram: '',
      dateFrom: '',
      dateTo: '',
      publicationAt: moment().format('YYYY-MM-DD HH:mm'),
      datetimeRegistrationTo: null,
      repeatedPollDate: '',
      configFromdateTimePicker: {
        minDate: null,
        maxDate: null,
        locale: locale[this.$i18n.locale]
      },
      configTodateTimePicker: {
        minDate: null,
        locale: this.$i18n.locale === 'ru' ? locale.ru : locale.en
      },
      configPublicationAtPicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        minDate: null,
        maxDate: null,
        locale: locale[this.$i18n.locale]
      },
      configToDateDatetimeRegistrationPicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        minDate: null,
        locale: locale[this.$i18n.locale]
      },
      configRepeatedPollDate: {
        dateFormat: 'Y-m-d H:i',
        altFormat: 'Y-m-d',
        minDate: 'today',
        locale: locale[this.$i18n.locale],
        altInput: true
      },
      deletePopupActive: false,
      showDeletePopupWaitMessage: false,
      deletePopupContent: ''
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        if (this.type === 'polling_health') {
          return this.$store.getters.accessUser['admin-polling_health'] === 'read'
        }
      }
    },
    formError () {
      return this.$store.getters['category/formError']
    }
  },

  watch: {
    numberDaysBeforeRepeatedPoll (val, oldVal) {
      if (val && isNaN(val)) {
        this.numberDaysBeforeRepeatedPoll = oldVal
      }
    }
  },
  methods: {
    onFromChange (selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, 'minDate', dateStr)
    },
    onToChange (selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, 'maxDate', dateStr)
    },
    onFromChangeRegistration (selectedDates, dateStr, instance) {
      this.$set(this.configToDateDatetimeRegistrationPicker, 'minDate', dateStr)
    },
    onImgPrefill () {
      this.imgCrop = this.$refs.pictureInput.image
      this.image = this.imgCrop
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    changeCropImg ({coordinates, canvas}) {
      if (!this.imgWidth && !this.imgHeight) {
        this.imgWidth = coordinates.width
        this.imgHeight = coordinates.height
      }
      if ((coordinates.width === this.imgWidth || coordinates.height === this.imgHeight) && this.imageUrl) {
        this.isChangedImg = false
      } else {
        this.isChangedImg = true
      }
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    openDeletePopup (name) {
      this.deletePopupContent = this.$t('categories.suite.popups.delete.message').replace('[NAME]', this.name)
      this.deletePopupActive = true
    },
    deleteCategory () {
      this.showDeletePopupWaitMessage = true
      this.$store
        .dispatch('category/destroy', { id: this.id })
        .catch((error) => {
          this.$vs.notify({
            title: 'Server error',
            text: error,
            color: 'danger'
          })
        })
        .then(() => {
          this.deletePopupActive = false
          this.showDeletePopupWaitMessage = false
          this.$nextTick(() => {
            this.$router.push({ name: `${this.type}s` })
          })
        })
    },
    action (stayAfter) {
      if (this.dateTo !== '') {
        this.dateTo = moment(this.dateTo).format('YYYY-MM-DD')
      }
      if (this.dateTo !== '') {
        this.dateFrom = moment(this.dateFrom).format('YYYY-MM-DD')
      }

      this.loading = !stayAfter
      this.buttonDisabled = true
      const patch = `category/${this.editType}`
      this.dispatch(patch, stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        const blockDescription = {}
        for (const item of this.recommendationsStack) {
          const editorRef = this.$refs[item.name]
          if (editorRef?.[0]?.state.editor) {
            blockDescription[item.name] = await editorRef[0].state.editor.save()
          } else {
            console.error(`Editor for ${item.name} is not found or not initialized properly.`)
          }
        }
        console.log('blockDescription:', blockDescription)

        if (!this.isRepeated) {
          this.repeatedPollDate = ''
          this.numberDaysBeforeRepeatedPoll = ''
        }

        const payload = {
          id: this.id,
          name: this.name,
          announcement: this.announcement,
          description: this.description,
          block_description: JSON.stringify(blockDescription),
          image:
            (this.isChangedImg && this.image !== null) ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : null,
          is_active: +this.isActive,
          type: this.type,
          point: this.point || 0,
          entrance_point: this.entrancePoint || 0,
          coins_finish: this.coin || 0,
          coins_in: this.entranceCoin || 0,
          date_from: this.dateFrom ? moment(this.dateFrom).format('YYYY-MM-DD') : '',
          date_to: this.dateTo,
          publication_at: this.publicationAt ? this.publicationAt : '',
          repeated_poll_date: this.repeatedPollDate || '',
          number_days_before_repeated_poll: this.numberDaysBeforeRepeatedPoll
        }

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          type: this.editType,
          stayAfter: stayAfter,
          routerPush: {
            name: 'polling_healths'
          },
          routerPushToEdit: {
            name: 'edit-polling_health'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.buttonDisabled = false
      }
    },
     
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    }
  },
  created () {

    this.$store.commit('category/clearFormError')

    this.id = this.$route.params.id
    if (this.id) {
      this.editType = 'update'
      this.$store.dispatch('category/edit', { id: this.id }).then(() => {
        const edit = this.$store.getters['category/edit']
        this.name = edit.name
        this.announcement = edit.announcement
        this.description = edit.description
        this.blockDescription = edit.blockDescription
        if (this.blockDescription) {
          try {
            const data = JSON.parse(this.blockDescription)
            // this.recommendationsStack = []
            // for (const key in data) {
            //   this.recommendationsStack.push({
            //     name: key,
            //     config: { ...editorJsConfig}
            //   })
            //   const arrLength = this.recommendationsStack.length
            //   this.recommendationsStack[arrLength - 1].config.data = data[key]
            // }
            this.recommendationsStack.forEach((element, index) => {
              const recommendationData = data[element.name]
              if (recommendationData) {
                this.recommendationsStack[index].config.data = recommendationData
              }
            })
          } catch (e) {
            this.ErrorParsePopupActive = true
          }
        }

        this.imageUrl = this.getUrl(edit.imagePath, `${this.$http.defaults.baseURL}/api/admin-panel`)

        this.type = edit.type
        this.isActive = edit.isActive
        this.point = edit.point || 0
        this.entrancePoint = edit.entrancePoint || 0
        this.coin = edit.coinsFinish || 0
        this.entranceCoin = edit.coinsIn || 0
        this.dateFrom = edit.dateFrom
        this.dateTo = edit.dateTo
        this.publicationAt = edit.publicationAt ? moment(edit.publicationAt).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm')
        this.repeatedPollDate = edit.repeatedPollDate
        this.numberDaysBeforeRepeatedPoll = edit.numberDaysBeforeRepeatedPoll
        console.log('edit.numberDaysBeforeRepeatedPoll', edit)
        this.isRepeated = edit.repeatedPollDate || edit.numberDaysBeforeRepeatedPoll
      }).then(() => {
        this.loaded = true
        this.loading = false
      })

    } else {
      this.editType = 'create'
      this.loaded = true
      this.loading = false
    }
  }
}
</script>

<style>
.image-container .preview-container {
  margin: 0 !important;
}

.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.delete-popup-wait-message {
  margin-top: 30px;
}

.codex-editor__redactor {
  padding-bottom: 0 !important;
}

.error-parse-button-confirm {
  margin-top: 20px;
}
</style>
