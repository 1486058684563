<template>
  <section class="statistics" v-show="!loading">
    <h3 class="statistics-h3">{{ $t('dashboard.titles.communication') }}</h3>
    <div class="card-statistic-grid" v-show="!loading">
      <div v-for="(value, key) in statisticsData" :key="key" class="card-statistic vx-col flex-1 flex">
        <statistics-card-line class="statistics-card-line"
        :countData="value" 
        :icon="statisticsData[key].icon"
        :statisticTitle="statisticsData[key].title"
        :period="statisticsPeriod" 
        diffPercent
        hideChart
        column
        noPadding
        />
      </div>
    </div>

  </section>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import loading from '@/views/components/Loading.vue'

export default {
  name: 'Communication',
  props: ['statisticsPeriod'],
  components: {
    StatisticsCardLine,
    loading
  },
  data () {
    return {
      loading: true,
      statisticsData: {
        communicationsMessage: {
          icon: 'Messages',
          title: this.$t('dashboard.titles.messages')
        },
        communicationsLikes: {
          icon: 'ThumbsUpIcon',
          title: this.$t('dashboard.titles.likes')
        },
        communicationsEmail: {
          icon: 'MailIcon',
          title: 'Email'
        },
        communicationsPush: {
          icon: 'Push',
          title: 'Push'
        },
        communicationsPosts: {
          icon: 'EyeIcon',
          title: this.$t('dashboard.titles.blog')
        }
      }
    }
  },
  methods: {
    fetchData (dataName, dispatchName) {
      this.$store.dispatch(dispatchName).then(() => {
        this.statisticsData[dataName] = { ...this.statisticsData[dataName], ...this.$store.getters[`statistics/${dataName}`] }
      })
    }
  },
  created () {
    const fetchMessages = this.fetchData('communicationsMessage', 'statistics/getCommunicationsMessage')
    const fetchLikes = this.fetchData('communicationsLikes', 'statistics/getCommunicationsLikes')
    const fetchEmail = this.fetchData('communicationsEmail', 'statistics/getCommunicationsEmail')
    const fetchPush = this.fetchData('communicationsPush', 'statistics/getCommunicationsPush')
    const fetchBlog = this.fetchData('communicationsPosts', 'statistics/getCommunicationsPosts')

    Promise.all([fetchMessages, fetchLikes, fetchEmail, fetchPush, fetchBlog]).then(() => {
      this.loading = false
    })

  }
}

</script>

<style lang="scss" scoped>
.card-statistic-grid {
  display: flex;
  justify-content: center;
  gap: 20px 0;

  @media (max-width: 1400px) {
    flex-wrap: wrap;
    & > .card-statistic {
      max-width: 33.333%;
    }
    & > .card-statistic:nth-child(3) {
      border-right: none !important;
    }
  }  
    @media (max-width: 670px) {
    & > .card-statistic {
      max-width: 50%;
    }
    & > .card-statistic:nth-child(3) {
      border-right: 1px solid #C2C2C2 !important;
    }
    & > .card-statistic:nth-child(even) {
      border-right: none !important;
    }

  }  
  padding: 24px 0;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

}
.card-statistic {
    &:not(:last-child) {
      border-right: 1px solid #C2C2C2 !important;
    }
  }
.statistics-card-line {
  border-radius: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow:none !important;
  -webkit-box-shadow:none !important;
  -o-box-shadow:none !important;
  -ms-box-shadow:none !important;
  padding: 0 36px;
}


</style>
