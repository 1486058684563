<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <template v-if="this.params.data.deleted_at">
      <feather-icon  icon="FilePlusIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="confirmActivateRecord()" />
    </template>
    <template v-else>
      <feather-icon icon="FileMinusIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="confirmDeleteRecord()" />
    </template>
  </div>
</template>

<script>

export default {
  name: 'CellRendererActions',
  methods: {
    editRecord () {
      this.$store.commit('setListPage', this.params.api.paginationGetCurrentPage())
      this.$router.push(`${this.$route.path}/${this.params.value}`).catch(() => {})
    },
    confirmActivateRecord () {
      this.params.context.componentParent.activateItemPopupActive = true
      this.params.context.componentParent.activatePopupContent = this.$t('users.edit.steps.popups.confirm_activate.message').replace('[STEP]', this.params.data.count)
      this.params.context.componentParent.activateItemId = this.params.data.id
    },
    confirmDeleteRecord () {
      this.params.context.componentParent.deleteItemPopupActive = true
      this.params.context.componentParent.deleteItemPopupContent = this.$t('users.edit.steps.popups.confirm_delete.message').replace('[STEP]', this.params.data.count)
      this.params.context.componentParent.deleteItemId = this.params.data.id
    }
  }
}
</script>
