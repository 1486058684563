import state from './moduleRelevantlState.js'
import mutations from './moduleRelevantMutations.js'
import actions from './moduleRelevantActions.js'
import getters from './moduleRelevantGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
