import state from './moduleAnnouncementState.js'
import mutations from './moduleAnnouncementMutations.js'
import actions from './moduleAnnouncementActions.js'
import getters from './moduleAnnouncementGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
