<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full my-32">
        <loading v-if="loading"></loading>
      </div>
    </div>

    <div v-show="!loading">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">
        {{$t('users.edit.access_settings.massage_just_watch') }}
      </p>
      <div class="vx-row">
        <div class="vx-col flex w-1/4 mb-4 items-center">
          <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"></vs-switch>
          <label class="ml-4 text-lg" :class="{ 'text-orange': isActive }">
            {{ $t('Active')}}
          </label>
        </div>
      </div>

      <vx-card class="mb-6">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('Title') }}*</label>
            <vs-input class="w-full" v-model="title" :placeholder="$t('Title')" />
            <span class="text-danger text-sm" v-show="formError.has('title')">{{ $t(formError.get('title')) }}</span>
          </div>

          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('Subtitle') }}</label>
            <vs-input class="w-full mb-2" v-model="subtitle" :placeholder="$t('Subtitle')" />
            <span class="text-danger text-sm" v-show="formError.has('subtitle')">
              {{ $t(formError.get('subtitle'))}}
            </span>
          </div>

          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('Price') }}</label>
            <vs-input class="w-full" type="number" v-model="price" :placeholder="$t('Price')" />
            <span class="text-danger text-sm" v-show="formError.has('price')">{{ $t(formError.get('price')) }}</span>
          </div>

          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('Info link') }}</label>
            <vs-input class="w-full" v-model="infoLink" :placeholder="$t('Info link')" />
            <span class="text-danger text-sm" v-show="formError.has('info_link')">{{ $t(formError.get('info_link')) }}</span>
          </div>
        </div>
        <div class="vx-row" v-if="loaded">
          <div class="vx-col w-full mb-2">
            <vx-card no-shadow card-border :title="$t('Description')">
              <editor 
                ref="editor" 
                :key="$i18n.locale"
                :config="editorJsConfig"
              />
              <span class="text-danger text-sm" v-show="formError.has('description')">
                {{$t(formError.get('description'))}}
              </span>
            </vx-card>
          </div>
        </div>
      </vx-card>

      <div class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button v-if="type === 'edit'" class="bg-danger text-white border-solid border-2 border-grey"
            @click="deletePopupActive = true" :disabled="actionButtonDisabled || isOnlyRead">
            {{ $t('Delete') }}
          </vs-button>
          <vs-button @click="action(stayAfter = true)" :disabled="actionButtonDisabled || isOnlyRead">
            {{ $t('Save') }}
          </vs-button>
          <vs-button @click="action()" :disabled="actionButtonDisabled || isOnlyRead">
            {{ $t('Save and exit') }}
          </vs-button>
        </div>
      </div>

    <vs-popup class="holamundo" :title="`${$t('Delete a product')}: ${title} ?`" :active.sync="deletePopupActive">
      <div class="delete-confirm-popup-buttons flex justify-between">
        <vs-button color="primary" type="filled" @click="deleteProduct()">
          {{$t('Confirm')}}
        </vs-button>
        <vs-button color="warning" type="filled" @click="deletePopupActive = false">
          {{$t('Cancel')}}
        </vs-button>
      </div>
    </vs-popup>

    </div>
  </div>
</template>

<script>

import helpers from '@/helpers/helpers.js'
import loading from '@/views/components/Loading.vue'
import editorJsConfig from '@/editorjs/config.js'

export default {
  name: 'create-edit-product',

  components: {
    loading

  },

  data () {
    return {
      type: 'create',
      loading: true,
      loaded: false,
      isActive: true,
      id: null,
      categoryId: null,
      externalId: null,
      title: '',
      subtitle: '',
      description: '',
      price: '',
      infoLink: '',
      sorting: null,
      deletePopupActive: false,
      actionButtonDisabled: false,
      editorJsConfig: { ...editorJsConfig},
      editorOption: {
        formats: ['bold', 'header', 'italic', 'link', 'list', 'blockquote', 'image', 'indent'],
        modules: {
          toolbar: '#toolbar'
        }
      }
    }
  },

  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-cafeteria'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['cafeteria/formError']
    }
  },

  async created () {
    this.categoryId = this.$route.params.categoryId || null
    if (this.$router.currentRoute.name === 'cafeteria-product-edit') {
      await this.$store.dispatch('cafeteria/getProduct', this.$route.params.id).then(() => {
        this.type = 'edit'
        const product = this.$store.getters['cafeteria/getProduct']
        this.description = product.description
        if (this.description !== '') {
          try {
            this.editorJsConfig.data = JSON.parse(product.description)
            console.log('this.editorJsConfig.data', this.editorJsConfig.data)
          } catch (e) {
            this.editorJsConfig.data = ''
          }
        }
        this.id = product.id
        this.isActive = product.is_active
        this.categoryId = product.category_id
        this.externalId = product.external_id
        this.title = product.title
        this.subtitle = product.subtitle
        this.price = product.price
        this.infoLink = product.info_link
        this.sorting = product.sorting
      }).then(() => {
        this.loading = false
        this.loaded = true
      })
    }
    this.loading = false
    this.loaded = true
  },

  methods: {

    deleteProduct () {
      this.actionButtonDisabled = true
      this.$store
        .dispatch('cafeteria/deleteProduct', this.id)
        .catch((error) => {
          this.$vs.notify({
            title: 'Server error',
            text: error,
            color: 'danger'
          })
        })
        .then(() => {
          this.deletePopupActive = false
        })
        .then(() => {
          this.$vs.notify({
            title: this.$t('Success'),
            text: this.$t('Product deleted'),
            color: 'success'
          })
          this.actionButtonDisabled = false
          this.$router.push(`/cafeteria/catalog?${this.categoryId}`).catch(() => {})
        })
    },
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'cafeteria/createProduct' : 'cafeteria/updateProduct'

      this.dispatch(patch, stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        const blockDescription = await this.$refs.editor.state.editor.save()
        const payload = {
          id: this.id,
          title: this.title,
          subtitle: this.subtitle,
          description: JSON.stringify(blockDescription),
          price: this.price || 0,
          info_link: this.infoLink,
          category_id: this.categoryId,
          external_id: this.externalId,
          is_active: +this.isActive
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            path: `/cafeteria/catalog?categoryId=${this.categoryId}`,
            params: { categoryId: this.categoryId }
          },
          routerPushToEdit: {
            name: 'cafeteria-product-edit',
            params: { categoryId: this.categoryId }
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>