import helpers from '@/helpers/helpers.js'

export default {
  retrieveStatistics (state, data) {
    state.statistics = {}
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) continue
      state.statistics[helpers.kebabCaseToCamelCase(prop)] = data[prop]
    }
  },
  setLoading (state, isActive) {
    state.loading = isActive
  }
}
