<!-- =========================================================================================
    File Name: StatisticsCard.vue
    Description: Statistics card component
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="statistic-card vx-card">
      <div :class="{wrapper: !noPadding}">
        <div
          class="statistic-card__header mb-4"
          :class="{
            'flex flex-row-reverse items-center': iconRight,
            'flex items-center': iconLeft,
            'text-center': !iconRight && hideChart,
            'pb-0': !hideChart,
          }"
        >
          <feather-icon :icon="icon" class="main-icon inline-flex rounded-full" :class="[{ 'mr-2': !iconRight }]"></feather-icon>
          <div class="statistic-card__title">
            <h3 class="text-lg text-left">{{ statisticTitle }}</h3>
          </div>
        </div>

        <div class="statistic-card__body flex">
          <statistics-counter
            :countData="countData"
            :statisticTitle="statisticTitle"
            :period="period"
            :statisticDiff="statisticDiff"
            :statisticDiffPercent="statisticDiffPercent"
            :diffPercent="diffPercent"
            :column="column"
          />
        </div>
        <div v-if="subCount" class="sub-counter mt-4">
          <h4>+ {{ statisticSubTitle }}</h4>
          <statistics-counter 
            :countData="subCount" 
            :statisticTitle="statisticSubTitle"
            :diffPositionLeft="true"
            >
          </statistics-counter>
        </div>
      </div>

      <div class="line-area-chart" v-if="!hideChart">
        <vue-apex-charts ref="apexChart" :type="type" height="100" width="100%" :options="chartOptions" :series="chartData" />
      </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import chartConfigs from './chartConfigs.js'
import _color from '@/assets/utils/color.js'

import StatisticsCounter from '../../views/components/statistics/StatisticsCounter.vue'

export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    statisticTitle: {
      type: String
    },
    statisticSubTitle: {
      type: String
    },
    chartData: {
      type: Array
      // required: true
    },
    color: {
      type: String,
      default: 'success'
    },
    colorTo: {
      type: String
    },
    // chartType: {
    //     type: String,
    //     default: 'line-chart',
    // },
    type: {
      type: String,
      default: 'line'
    },
    column: {
      type: Boolean,
      default: false
    },
    iconRight: {
      type: Boolean,
      default: false
    },
    iconLeft: {
      type: Boolean,
      default: true
    },
    hideChart: {
      type: Boolean,
      default: false
    },
    period: {
      type: String
    },
    countData: {
      required: true
    },
    subCount: {
      type: Number,
      default: 0,
      required: false
    },


    diffPercent: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean
    }
  },
  components: {
    VueApexCharts,
    StatisticsCounter
  },
  data () {
    return {
      chartOptions: null,
      statisticDiff: 0,
      statisticDiffPercent: 0,
      oneMonthAgo: new Date().setMonth(new Date().getMonth() - 1),
      oneYearAgo: new Date().setFullYear(new Date().getFullYear() - 1)
    }
  },

  watch: {
    themePrimaryColor () {
      this.$refs.apexChart.updateOptions({ theme: { monochrome: { color: this.getHex(this.color) } } })
    },

      
    period (newValue) { 
      if (!this.hideChart && this.chartData) {

        const chartStartDate = new Date(this.$refs.apexChart.$options.propsData.series[0].data[0][0])
        const monthStartDate = chartStartDate < this.oneMonthAgo ? this.oneMonthAgo : chartStartDate
        const yearStartDate = chartStartDate < this.oneYearAgo ? this.oneYearAgo : chartStartDate
        
        switch (newValue) {
        case this.$t('dashboard.labels.month'):
          this.$refs.apexChart.zoomX(monthStartDate, new Date())
          break
        case this.$t('dashboard.labels.year'):
          this.$refs.apexChart.zoomX(yearStartDate, new Date())
          break
        case this.$t('dashboard.labels.all_time'):
          this.$refs.apexChart.zoomX(chartStartDate, new Date())
          break
        default:
          this.$refs.apexChart.zoomX(new Date().setMonth(new Date().getMonth() - 1), new Date())
          break
        }
      }
    }
  },

  computed: {
    themePrimaryColor () {
      return this.$store.state.themePrimaryColor
    }
  },

  methods: {
    getHex (color) {
      if (_color.isColor(color)) {
        let rgb = window.getComputedStyle(document.documentElement).getPropertyValue(`--vs-${color}`)
        rgb = rgb.split(',')
        return `#${((1 << 24) + (Number(rgb[0]) << 16) + (Number(rgb[1]) << 8) + Number(rgb[2])).toString(16).slice(1)}`
      }
      return color
    },
    gradientToColor (color) {
      const gradientToColors = {
        primary: '#A9A2F6',
        success: '#55DD92',
        warning: '#ffc085',
        danger: '#F97794'
      }

      return gradientToColors[color] ? gradientToColors[color] : gradientToColors['primary']
    }
  },
  created () {
    if (this.type === 'area') {
      // assign chart options
      this.chartOptions = Object.assign({}, chartConfigs.areaChartOptions)
      this.chartOptions['colors'] = [this.getHex(this.color)]
    } else if (this.type === 'line') {
      // Assign chart options
      this.chartOptions = JSON.parse(JSON.stringify(chartConfigs.lineChartOptions))

      this.chartOptions.fill.gradient.gradientToColors = [this.gradientToColor(this.colorTo || this.color)]
      this.chartOptions.colors = [this.getHex(this.color)]
    }
  },
  mounted () {
    // this.$refs.apexChart.zoomX(new Date().setMonth(new Date().getMonth() - 1), new Date())
  }
  
}
</script>

<style lang="scss" >


.statistic-card {
  font-size: 16px !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wrapper {
  padding: 21px 21px 7px;
}

.statistic-card__title {
  line-height: 19px;
}

.main-icon {
  background-color: rgba(115, 103, 240, 0.08);
  color: #7367f0;
  svg {
    font-family: feather !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    width: 40px;
    height: 40px;
    padding: 8px;
  }
}
.sub-counter {
  h4 {
    font-size: 12px;
    font-weight: 400;
  }
  .total-count__number {
    font-size: 18px;
    line-height: 1;
    font-weight: 600;
  }
  .text-lg {
    font-size: 12px !important;
  }

  .items-start i.vs-icon {
    font-size: 18px !important;
    height: 20px !important; 
    width: 20px !important;
  }
}
</style>
