<template>
  <div>
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-32 mt-32">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-show="!loading">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div v-if="isPrograms" class="vx-row  mb-6">
        <div class="vx-col flex items-center gap-4">
          <span class=" text-lg font-bold">{{ $t(`categories.template.${typeProgram}.name`) }}</span>
          <a  @click="$router.push({ name: 'choose-type-category' })"
            class="inline-block rounded-lg shadow-md font-bold text-xs bg-white py-2 px-4 cursor-pointer">
            {{ $t(`categories.template.buttons.to_change`) }}
          </a>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col flex w-1/4 mb-6">
          <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"></vs-switch>
          <label class="ml-4 text-lg">{{ $t('categories.edit.labels.is_active') }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active')) }}</span>
        </div>
      </div>
      <content-filter :targetId="id" :targetModel="'category'"></content-filter>
      <vx-card class="mb-6" :title="$t('categories.create.labels.accessibility_settings')">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('categories.edit.labels.publication_at') }}</label>
            <flat-pickr
              class="w-full"
              :config="configPublicationAtPicker"
              v-model="publicationAt"
              :placeholder="$t('categories.edit.labels.publication_at')"
              @on-change="onFromChangeRegistration"
            />
            <span class="text-danger text-sm pickatime" v-show="formError.has('publication_at')">{{
              $t(formError.get('publication_at'))
            }}</span>
          </div>
          <div v-if="type !== 'polling'" class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('categories.edit.labels.datetime_registration_to') }}</label>
            <flat-pickr
              class="w-full"
              :config="configToDateDatetimeRegistrationPicker"
              v-model="datetimeRegistrationTo"
              :placeholder="$t('categories.edit.labels.datetime_registration_to')"
              @on-change="onToChangeRegistration"
            />
            <span class="text-danger text-sm pickatime" v-show="formError.has('datetime_registration_to')">{{
              $t(formError.get('datetime_registration_to'))
            }}</span>
          </div>
        </div>
        <div class="vx-row" v-if="type === 'polling' || type === 'challenge'">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t('categories.edit.labels.date_from') }}</label>
            <flat-pickr
              class="w-full"
              :config="configFromdateTimePicker"
              v-model="dateFrom"
              :placeholder="$t('categories.edit.labels.date_from')"
              @on-change="onFromChange"
            />
            <span class="text-danger text-sm" v-show="formError.has('date_from')">{{ $t(formError.get('date_from')) }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2" v-if="type === 'polling'">
            <label>{{ $t('categories.edit.labels.date_to') }}</label>
            <flat-pickr
              class="w-full"
              :config="configTodateTimePicker"
              v-model="dateTo"
              :placeholder="$t('categories.edit.labels.date_to')"
              @on-change="onToChange"
            />
            <span class="text-danger text-sm" v-show="formError.has('date_to')">{{ $t(formError.get('date_to')) }}</span>
          </div>
        </div>
        <div class="vx-row" v-if="isPrograms">
          <div class="vx-col w-full md:w-1/2 mb-4">
            <label>{{ $t('personal_goals.suite.title.available_after') }}</label>
            <v-select v-model="availableAfter" label="name" :options="availableAfterOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('available_after')">{{ $t(formError.get('available_after')) }}</span>
          </div>
        </div>    
        <div v-if="type !== 'polling'">
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>{{ $t('categories.create.labels.show_in_module_event') }}</label>
              <vs-switch v-model="showInModuleEvent" icon-pack="feather" vs-icon="icon-check"></vs-switch>
              <span class="text-danger text-sm" v-show="formError.has('show_in_module_event')">{{
                $t(formError.get('show_in_module_event'))
              }}</span>
            </div>
          </div>

          <div class="vx-row" v-if="type === 'challenge' && showByDates">
            <div class="vx-col w-full mb-2">
              <label>{{ $t('categories.edit.labels.dont_add_points_if_missed') }}</label>
              <vs-switch v-model="dontAddPointsIfMissed" icon-pack="feather" vs-icon="icon-check"></vs-switch>
              <span class="text-danger text-sm" v-show="formError.has('dont_add_points_if_missed')">{{
                $t(formError.get('dont_add_points_if_missed'))
              }}</span>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>{{ $t('categories.edit.labels.is_show_chat') }}</label>
              <vs-switch v-model="isShowChat" icon-pack="feather" vs-icon="icon-check"></vs-switch>
              <span class="text-danger text-sm" v-show="formError.has('is_show_chat')">{{ $t(formError.get('is_show_chat')) }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>{{ $t('categories.create.labels.is_show_participants') }}</label>
              <vs-switch v-model="isShowParticipants" icon-pack="feather" vs-icon="icon-check"></vs-switch>
              <span class="text-danger text-sm" v-show="formError.has('is_show_participants')">{{
                $t(formError.get('is_show_participants'))
              }}</span>
            </div>
          </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <label>{{ $t('categories.create.labels.is_repeated_passage') }}</label>
            <vs-switch v-model="isRepeatedPassage" icon-pack="feather" vs-icon="icon-check"></vs-switch>
            <span class="text-danger text-sm" v-show="formError.has('is_repeated_passage')">{{
                $t(formError.get('is_repeated_passage'))
              }}</span>
          </div>
        </div>

          <div v-if="isWithoutMissed && 0" class="vx-row">
            <div class="vx-col w-full mb-2">
              <vs-input class="w-full" :label-placeholder="$t('categories.edit.labels.missed_count')" v-model="missedCount" />
              <span class="text-danger text-sm" v-show="formError.has('missed_count')">{{ $t(formError.get('missed_count')) }}</span>
            </div>
          </div>
        </div>
      </vx-card>

      <vx-card :title="$t('categories.create.labels.description')">
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t('categories.edit.labels.name') }}*</label>
            <vs-input class="w-full" v-model="name" />
            <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
          </div>
          <div v-if="type !== 'polling'" class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('events.create.labels.max_quantity_places') }}</label>
            <vs-input
              type="number"
              min="0"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              class="w-full"
              v-model="maxQuantityPlaces"
            />
            <span class="text-danger text-sm" v-show="formError.has('max_quantity_places')">{{
              $t(formError.get('max_quantity_places'))
            }}</span>
          </div>
          <div v-if="type !== 'polling'" class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t('categories.edit.labels.tags') }}</label>
            <v-select
              v-model="tags"
              multiple
              :placeholder="$t('categories.edit.labels.tags')"
              :closeOnSelect="false"
              :options="tagsOptions"
              :selectable="option => tags.indexOf(option) < 0"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
            <span class="text-danger text-sm" v-show="formError.has('tags')">{{ $t(formError.get('tags')) }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t('segments.suite.title') }}</label>
            <v-select
              v-model="segments"
              multiple
              :placeholder="$t('segments.suite.title')"
              :options="segmentsOptions"
              :closeOnSelect="false"
              :selectable="option => segments.indexOf(option) < 0"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
            <span class="text-danger text-sm" v-show="formError.has('segments')">{{ $t(formError.get('segments')) }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t(`categories.edit.labels.${type}.point`) }}*</label>
            <vs-input class="w-full" type="number" v-model="point" />
            <span class="text-danger text-sm" v-show="formError.has('point')">{{ $t(formError.get('point')) }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t(`categories.edit.labels.${type}.coin`) }}</label>
            <vs-input class="w-full" type="number" v-model="coin" />
            <span class="text-danger text-sm" v-show="formError.has('coins_finish')">{{ $t(formError.get('coins_finish')) }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2" style="z-index: 10002;">
            <label>{{ $t('categories.edit.labels.clubs') }}</label>
            <v-select v-model="club" :options="clubs" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('club_id')">{{ $t(formError.get('club_id')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-textarea class="w-full mb-2" :label="$t(`categories.edit.labels.${type}.announcement`)" v-model="announcement" />
            <span class="text-danger text-sm" v-show="formError.has('announcement')">{{ $t(formError.get('announcement')) }}</span>
          </div>
        </div>
        <div class="vx-row" v-if="loaded">
          <div class="vx-col w-full mb-2">
            <vx-card no-shadow card-border :title="$t(`categories.edit.labels.${type}.block_description`)">
              <editor ref="editor" :config="editorJsConfig" class="pb-0" />
              <span class="text-danger text-sm" v-show="formError.has('block_description')">{{
                $t(formError.get('block_description'))
              }}</span>
            </vx-card>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-container" style="width: 150px; height: 150px; margin: 0 auto">
              <picture-input
                v-if="!loading"
                ref="pictureInput"
                @prefill="onImgPrefill"
                @change="onImgChange"
                @error="onImgError"
                width="150"
                height="150"
                margin="16"
                :accept="this.$baseImageAcceptTypes"
                buttonClass="btn"
                size="10"
                :hideChangeButton="true"
                :crop="false"
                :prefill="imageUrl"
                :alertOnError="false"
                :customStrings="{
                  drag: $t('categories.edit.labels.image'),
                }"
              >
              </picture-input>
              <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
            </div>
          </div>
        </div>
        <div v-if="imgCrop" class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-crop-container">
              <cropper
                ref="cropper"
                classname="cropper"
                :src="imgCrop"
                :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }"
                @change="changeCropImg"
                :stencil-props="{
                  aspectRatio: 3 / 2
                }"
              ></cropper>
            </div>
          </div>
        </div>
      </vx-card>
      <div class="vx-row fixed-buttons">
        <div class="vx-col w-full">
          <div class="flex gap-3">
            <vs-button @click="openDeletePopup()" :disabled="isOnlyRead">{{ $t('categories.edit.buttons.delete') }} </vs-button>
            <vs-button @click="update(stayAfter = true)" :disabled="updateButtonDisabled || isOnlyRead">
              {{ $t('categories.edit.buttons.update') }}
            </vs-button>
            <vs-button @click="update()" :disabled="updateButtonDisabled || isOnlyRead">
              {{ $t('categories.edit.buttons.update') }} {{ $t('suite.and_exit') }}
            </vs-button>
          </div>
        </div>
      </div>
    </div>
    <vs-popup class="holamundo" title="Image error" :active.sync="imageErrorPopupActive">
      <p>{{ imageErrorPopupMessage }}</p>
    </vs-popup>
    <vs-popup class="holamundo" :title="$t('categories.suite.popups.delete.title')" :active.sync="deletePopupActive">
      <p>{{ deletePopupContent }}</p>
      <div class="delete-confirm-popup-buttons" v-if="!showDeletePopupWaitMessage">
        <vs-button color="primary" type="filled" @click="deleteCategory">
          {{ $t('categories.suite.popups.delete.buttons.confirm') }}
        </vs-button>
        <vs-button color="warning" type="filled" @click="deletePopupActive = false">
          {{ $t('categories.suite.popups.delete.buttons.cancel') }}
        </vs-button>
      </div>
      <p v-if="showDeletePopupWaitMessage" class="delete-popup-wait-message">
        {{ $t('categories.suite.popups.delete.wait_message') }}
        <font-awesome-icon icon="spinner" spin />
      </p>
    </vs-popup>

    <vs-popup v-if="this.categoryType" class="holamundo" title="" :active.sync="ErrorParsePopupActive">
      <p>{{ $t(`categories.edit.${this.categoryType.value}.error_parse.content`) }}</p>
      <div class="error-parse-button-confirm">
        <vs-button color="warning" type="filled" @click="ErrorParsePopupActive=false">{{ $t(`categories.edit.${this.categoryType.value}.error_parse.button_confirm`) }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import flatPickr from 'vue-flatpickr-component'
import locale from 'flatpickr/dist/l10n/ru'
import 'flatpickr/dist/flatpickr.css'

import { quillEditor } from 'vue-quill-editor'
import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import editorJsConfig from '@/editorjs/config.js'
import loading from '@/views/components/Loading.vue'
import helpers from '@/helpers/helpers.js'

import vSelect from 'vue-select'
import moment from 'moment'
import VxCard from '../../../components/vx-card/VxCard.vue'
import ContentFilter from '@/views/components/contentFilter.vue'

export default {
  components: {
    PictureInput,
    quillEditor,
    Cropper,
    loading,
    flatPickr,
    'v-select': vSelect,
    VxCard,
    ContentFilter
  },
  data () {
    return {
      ErrorParsePopupActive: false,
      id: 0,
      name: '',
      announcement: '',
      description: '',
      blockDescription: null,
      image: null,
      imageUrl: '',
      imgCrop: null,
      imgWidth: null,
      imgHeight: null,
      isChangedImg: false,
      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error',
      editorJsConfig: Object.assign({}, editorJsConfig),
      editorOption: {
        formats: ['bold', 'header', 'italic', 'link', 'list', 'blockquote', 'image', 'indent'],
        modules: {
          toolbar: '#toolbar'
        }
      },
      updateButtonDisabled: false,
      period: '',
      point: 0,
      coin: 0,
      isActive: true,
      isConsistent: false,
      isWithoutMissed: false,
      missedCount: null,
      maxQuantityPlaces: '',
      club: null,
      clubs: [],
      categoryType: null,
      categoryTypes: [
        { label: this.$t('menu.programms'), value: 'programm' },
        { label: this.$t('menu.challenges'), value: 'challenge' },
        { label: this.$t('menu.polling'), value: 'polling' }
      ],
      showByDates: false,
      showInModuleEvent: false,
      isShowChat: false,
      isShowParticipants: true,
      isRepeatedPassage: false,
      tags: [],
      tagsOptions: [],
      segments: [],
      segmentsOptions: [],
      availableAfter: null,
      availableAfterOptions: [],
      dontAddPointsIfMissed: false,
      loaded: false,
      loading: true,
      type: 'programm',
      typeProgramData: [],
      typeProgram: '',
      dateFrom: '',
      dateTo: '',
      publicationAt: moment().format('YYYY-MM-DD HH:mm'),
      datetimeRegistrationTo: null,
      configFromdateTimePicker: {
        minDate: null,
        maxDate: null,
        locale: locale[this.$i18n.locale]
      },
      configTodateTimePicker: {
        minDate: null,
        locale: this.$i18n.locale === 'ru' ? locale.ru : locale.en
      },
      configPublicationAtPicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        minDate: null,
        maxDate: null,
        locale: locale[this.$i18n.locale]
      },
      configToDateDatetimeRegistrationPicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        minDate: null,
        locale: locale[this.$i18n.locale]
      },
      deletePopupActive: false,
      showDeletePopupWaitMessage: false,
      deletePopupContent: ''
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        if (this.type === 'polling') {
          return accessUser['admin-pollings'] === 'read'
        } 
        if (this.isPrograms) {
          return accessUser['admin-clubs-with-program'] === 'read'
        } 
      }
    },
    formError () {
      return this.$store.getters['category/formError']
    },
    isPrograms () {
      const programs = ['programm', 'challenge', 'self_challenge']
      return programs.includes(this.type)
    }
  },
  methods: {
    onFromChange (selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, 'minDate', dateStr)
    },
    onToChange (selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, 'maxDate', dateStr)
    },
    onFromChangeRegistration (selectedDates, dateStr, instance) {
      this.$set(this.configToDateDatetimeRegistrationPicker, 'minDate', dateStr)
    },
    onToChangeRegistration (selectedDates, dateStr, instance) {
      this.$set(this.configPublicationAtPicker, 'maxDate', dateStr)
    },
    onImgPrefill () {
      this.imgCrop = this.$refs.pictureInput.image
      this.image = this.imgCrop
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    changeCropImg ({coordinates, canvas}) {
      if (!this.imgWidth && !this.imgHeight) {
        this.imgWidth = coordinates.width
        this.imgHeight = coordinates.height
      }
      if ((coordinates.width === this.imgWidth || coordinates.height === this.imgHeight) && this.imageUrl) {
        this.isChangedImg = false
      } else {
        this.isChangedImg = true
      }
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    openDeletePopup (name) {
      this.deletePopupContent = this.$t('categories.suite.popups.delete.message').replace('[NAME]', this.name)
      this.deletePopupActive = true
    },
    deleteCategory () {
      this.showDeletePopupWaitMessage = true
      this.$store
        .dispatch('category/destroy', { id: this.id })
        .catch((error) => {
          this.$vs.notify({
            title: 'Server error',
            text: error,
            color: 'danger'
          })
        })
        .then(() => {
          this.deletePopupActive = false
          this.showDeletePopupWaitMessage = false
          this.$nextTick(() => {
            if (this.isPrograms) {
              this.$router.push({ name: 'programms' })
            } else {
              this.$router.push({ name: `${this.type}s` })
            }
          })
        })
    },
    update (stayAfter) {
      this.loading = !stayAfter
      this.updateButtonDisabled = true

      if (this.dateTo !== null && this.dateTo !== '') {
        this.dateTo = moment(String(this.dateTo)).format('YYYY-MM-DD')
      }
      if (this.dateFrom !== null && this.dateTo !== '') {
        this.dateFrom = moment(String(this.dateFrom)).format('YYYY-MM-DD')
      }

      const patch = 'category/update'
      this.dispatch(patch, stayAfter)
    },
    
    async dispatch (patch, stayAfter) {
      try {
        const blockDescription = await this.$refs.editor.state.editor.save()

        const payload = {
          id: this.id,
          name: this.name,
          announcement: this.announcement,
          description: this.description,
          block_description: JSON.stringify(blockDescription),
          image:
            (this.isChangedImg && this.image !== null) ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : null,
          is_active: +this.isActive,
          is_consistent: +this.isConsistent,
          is_without_missed: +this.isWithoutMissed,
          missed_count: +this.missedCount,
          is_show_chat: +this.isShowChat,
          is_show_participants: +this.isShowParticipants,
          is_repeated_passage: +this.isRepeatedPassage,
          max_quantity_places: this.maxQuantityPlaces,
          show_by_dates: +this.showByDates,
          show_in_module_event: +this.showInModuleEvent,
          type: this.categoryType.value,
          tags: this.tags,
          segments: this.segments,
          dont_add_points_if_missed: +this.dontAddPointsIfMissed,
          point: this.point,
          coins_finish: this.coin,
          date_from: this.dateFrom ? moment(this.dateFrom).format('YYYY-MM-DD') : '',
          date_to: this.dateTo,
          publication_at: this.publicationAt ? this.publicationAt : '',
          datetime_registration_to: this.datetimeRegistrationTo ? this.datetimeRegistrationTo : '',
          available_after: this.availableAfter ? {id: this.availableAfter.id} : null
        }
        if (this.club?.id) {
          payload.club_id = this.club.id
        }

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          type: 'edit',
          stayAfter: stayAfter,
          routerPush: {
            name: this.isPrograms ? 'programms' : `${this.type}s`
          },
          routerPushToEdit: {
            name:`edit-${this.type}`
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
        
      } finally {
        this.loading = false
        this.updateButtonDisabled = false
      } 
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },


    getTypeProgram () {
    /**
     * Обновляет текущее значение typeProgram на основе текущего типа и данных программы.
     * Если значение не найдено, перенаправляет пользователя на страницу выбора типа категории.
    */
      let program = null

      program = this.typeProgramData.find((item) => item.name === this.type)

      if (program) {
        this.typeProgram = program.name
        return
      }

      program = this.typeProgramData.find((item) => (item.is_without_missed === this.isWithoutMissed && item.is_consistent === this.isConsistent && item.show_by_dates === this.showByDates))

      if (!program) {
        this.$router.push({ name: 'choose-type-category' })
      } else {
        this.typeProgram = program.name
      }
    },

    getAvailableAfterOptions () {
      this.$store.dispatch('category/getSuite').then(() => {
        const data = JSON.parse(JSON.stringify(this.$store.getters['category/suite']))
        const index = data.findIndex(item => +item.id === +this.id)
        if (index !== -1) {
          data.splice(index, 1)
        }
        this.availableAfterOptions = data
      })
    }
  },
  created () {

    if (this.$router.currentRoute.name === 'edit-polling') {
      this.type = 'polling'
    }

    this.typeProgramData = this.$store.getters['category/typeProgram']
    this.$store.commit('category/clearFormError')

    this.$store.dispatch('tag/getTagsOptions').then(() => {
      this.tagsOptions = this.$store.getters['tag/tagsOptions']
    })

    this.$store.dispatch('segment/getSegmentsOptions').then(() => {
      this.segmentsOptions = this.$store.getters['segment/segmentsOptions']
    })

    this.$store.dispatch('club/list').then(() => {
      this.clubs = this.$store.getters['club/list']
    })

    if (this.type !== 'polling') {
      this.getAvailableAfterOptions()
    }
    

    this.id = this.$route.params.id
    this.$store.dispatch('category/edit', { id: this.id }).then(() => {
      const edit = this.$store.getters['category/edit']
      this.name = edit.name
      this.announcement = edit.announcement
      this.description = edit.description
      this.blockDescription = edit.blockDescription
      if (this.blockDescription !== '') {
        try {
          this.editorJsConfig.data = JSON.parse(edit.blockDescription)
        } catch (e) {
          this.ErrorParsePopupActive = true
          this.editorJsConfig.data = ''
        }
      }

      this.imageUrl = this.getUrl(edit.imagePath, `${this.$http.defaults.baseURL}/api/admin-panel`)

      this.period = edit.period
      this.type = edit.type
      this.isActive = edit.isActive
      this.isConsistent = edit.isConsistent
      this.isWithoutMissed = edit.isWithoutMissed
      this.missedCount = edit.missedCount
      this.isShowChat = edit.isShowChat
      this.isShowParticipants = edit.isShowParticipants
      this.isRepeatedPassage = edit.isRepeatedPassage
      this.maxQuantityPlaces = edit.maxQuantityPlaces
      this.showByDates = edit.showByDates
      this.showInModuleEvent = edit.showInModuleEvent
      this.tags = edit.tags
      this.tags.map((tag) => {
        tag.id = tag.value
      })
      this.segments = edit.segments
      this.point = edit.point
      this.coin = edit.coinsFinish
      this.dontAddPointsIfMissed = edit.dontAddPointsIfMissed
      this.dateFrom = edit.dateFrom
      this.dateTo = edit.dateTo
      this.availableAfter = edit.availableAfter
      this.publicationAt = edit.publicationAt ? moment(edit.publicationAt).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm')
      this.datetimeRegistrationTo = edit.datetimeRegistrationTo
      this.club = {
        label: edit.club.name,
        value: edit.club.id
      }
    }).then(() => {
      if (this.$route.query.type) {
        let typeData = {}
        this.typeProgramData.map((item) => {
          if (item.name === this.$route.query.type) {
            typeData = item
          }
        })
        this.typeProgram = typeData.name
        this.type = typeData.type
        this.isWithoutMissed = typeData.is_without_missed
        this.isConsistent = typeData.is_consistent
        this.showByDates = typeData.show_by_dates
      } else {
        this.getTypeProgram()
      }
    }).then(() => {
      this.categoryType = {
        label: this.$t(`menu.${this.type}s`),
        value: this.type
      }
      this.loaded = true
      this.loading = false
    })
  }
}
</script>

<style>
.image-container .preview-container {
  margin: 0 !important;
}

.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.delete-popup-wait-message {
  margin-top: 30px;
}

.codex-editor__redactor {
  padding-bottom: 0 !important;
}

.error-parse-button-confirm {
  margin-top: 20px;
}
</style>
