<!-- =========================================================================================
    File Name: Maintenance.vue
    Description: Maintenance Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
      <img src="@/assets/images/pages/maintenance-2.png" alt="graphic-maintenance" class="mx-auto mb-4">
      <h1 class="sm:mx-0 mx-4 mb-6 text-5xl d-theme-heading-color">Under Maintenance!</h1>
      <p class="sm:mx-0 mx-4 mb-6 d-theme-text-inverse">paraphonic unassessable foramination Caulopteris worral Spirophyton encrimson esparcet aggerate chondrule restate whistler shallopy biosystematy area bertram plotting unstarting quarterstaff.</p>
      <vs-button size="large" to="/" @click="login">{{ $t('athlete_after_login.button') }}</vs-button>
    </div>
  </div>
</template>

<script>

export default{
  data () {
    return {}
  },
  methods: {
    login () {
      this.$store.commit('auth/SET_CHANGE_ROLE_TO_LOGIN_DATA')
      this.$store.dispatch('auth/loginServer', this.$store.getters['auth/loginData'])
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
