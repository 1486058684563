import FormError from  '../../modules/formError'

export default {
  suite: [],
  trackers: [],
  select: [],
  filtersOptions: [],
  edit: {
    name: ''
  },
  // targetTypes: [],
  // targetTypeItems: [],
  // users: [],
  formError: new FormError(),

  templates: [
    {
      name: 'distance',
      class_name: 'activities',
      metrics: ['meters', 'kilometers']
    },
    {
      name: 'time',
      class_name: 'activities',
      metrics: ['minutes', 'hours']
    },
    {
      name: 'steps_counter',
      class_name: 'steps_counter',
      metrics: ['amount']
    },
    {
      name: 'complete_few_events',
      class_name: 'complete_few_events',
      metrics: ['amount']
    },
    {
      name: 'complete_few_programs',
      class_name: 'complete_few_programs',
      metrics: ['amount']
    },
    {
      name: 'write_messages_in_chat',
      class_name: 'write_messages_in_chat',
      metrics: ['amount']
    },
    {
      name: 'comment_workout_result',
      class_name: 'comment_workout_result',
      metrics: ['amount']
    },
    {
      name: 'top_of_the_week',
      class_name: 'top_of_the_week',
      metrics: ['amount']
    },
    {
      name: 'complete_few_activities',
      class_name: 'complete_few_activities',
      metrics: ['amount']
    },
    {
      name: 'complete_challenge_or_programm',
      class_name: 'complete_challenge_or_programm',
      metrics: ['amount']
    }
    // {
    //   name: 'habits_tracker',
    //   class_name: 'habits_tracker',
    //   metrics: ['amount']
    // }
  ]
}
