<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <vs-button
          color="primary"
          type="gradient"
          icon-pack="feather"
          icon="icon-plus"
          class="add-button"
          :disabled="isOnlyRead"
          @click="$router.push({ name: 'create-achievement' })"
        ></vs-button>
      </div>
      <div class="vx-col w-full mb-base">
        <loading v-if="loading"></loading>
        <vs-table v-else :data="suite">
          <template slot="thead">
            <vs-th>{{ $t('achievements.suite.table.id') }}</vs-th>
            <vs-th>{{ $t('achievements.suite.table.name') }}</vs-th>
            <vs-th>{{ $t('achievements.suite.table.type') }}</vs-th>
            <vs-th>{{ $t('achievements.suite.table.edit') }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].id">
                {{ data[indextr].id }}
              </vs-td>

              <vs-td :data="data[indextr].name">
                {{ data[indextr].name }}
              </vs-td>

              <vs-td :data="data[indextr].type">
                {{ $t('achievements.type.' + data[indextr].type) }}
              </vs-td>

              <vs-td :data="data[indextr].date" class="text-center">
                <feather-icon
                  icon="Edit3Icon"
                  svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                  @click="$router.push({ name: 'edit-achievement', params: { id: data[indextr].id } })"
                />
                <feather-icon
                  v-if="!isOnlyRead"
                  icon="Trash2Icon"
                  svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                  @click="openDeletePopup(data[indextr].id, indextr, data[indextr].name)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <vs-popup class="holamundo" :title="$t('achievements.suite.popups.delete.title')" :active.sync="deletePopupActive">
      <p>{{ deletePopupContent }}</p>
      <div class="delete-confirm-popup-buttons" v-if="!showDeletePopupWaitMessage">
        <vs-button color="primary" type="filled" @click="deleteAchievement">{{
          $t('achievements.suite.popups.delete.buttons.confirm')
        }}</vs-button>
        <vs-button color="warning" type="filled" @click="deletePopupActive = false">{{
          $t('achievements.suite.popups.delete.buttons.cancel')
        }}</vs-button>
      </div>
      <p v-if="showDeletePopupWaitMessage" class="delete-popup-wait-message">
        {{ $t('achievements.suite.popups.delete.wait_message') }} <font-awesome-icon icon="spinner" spin />
      </p>
    </vs-popup>
  </div>
</template>

<script>
import loading from '@/views/components/Loading.vue'

export default {
  components: {
    loading
  },
  data () {
    return {
      loading: true,
      deletePopupActive: false,
      showDeletePopupWaitMessage: false,
      deletePopupContent: '',
      deleteAchievementId: 0,
      deleteAchievementIndex: 0
    }
  },
  methods: {
    openDeletePopup (id, index, name) {
      this.deletePopupContent = this.$t('achievements.suite.popups.delete.message').replace('[NAME]', name)
      this.deleteAchievementId = id
      this.deleteAchievementIndex = index
      this.deletePopupActive = true
    },
    deleteAchievement () {
      this.showDeletePopupWaitMessage = true
      this.$store
        .dispatch('achievement/delete', { id: this.deleteAchievementId, index: this.deleteAchievementIndex })
        .catch((error) => {
          this.$vs.notify({
            title: 'Server error',
            text: error,
            color: 'danger'
          })
        })
        .then(() => {
          this.deletePopupActive = false
          this.showDeletePopupWaitMessage = false
        })
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-settings-achievements'] === 'read' ||  
               accessUser['admin-settings'] === 'read'
      }
    },
    suite () {
      return this.$store.getters['achievement/suite']
    }
  },
  created () {
    this.$store.dispatch('achievement/getSuite').then(() => {
      this.loading = false
    })
  }
}
</script>

<style lang="scss" scoped>

.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.delete-popup-wait-message {
  margin-top: 30px;
}
.sort-table-wrapper {
  width: 100%;
}
.list-item {
  transition: all 1s;
}
.list-item:hover {
  cursor: all-scroll;
}
</style>
