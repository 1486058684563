import axios from '@/axios.js'

export default {
  methods: {
    paginationGoToPageCached (val) {
      let page = 0

      if (this.$store.state.listPage) {
        page = this.$store.state.listPage
        this.$store.commit('setListPage', 0)
      } else {
        page = val - 1
      }

      this.gridApi.paginationGoToPage(page)
    },
    getUrl (url, baseURL = axios.defaults.baseURL) {
      if (typeof url === 'string' && url.startsWith('http')) {
        return url
      }
      if (typeof url === 'string' && url !== '') {
        return `${baseURL}${url}`
      }
      return null
    }
  }
}