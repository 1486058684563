<!-- =========================================================================================
  File Name: PointsSettings.vue
  Description: Points settings List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <div v-if="!isOnlyRead" class="vx-col md:w-1/2 w-full">
        <vs-button
          color="primary"
          type="gradient"
          icon-pack="feather"
          icon="icon-plus"
          class="add-button"
          @click="$router.push({ name: 'points-settings-create' })"
        ></vs-button>
      </div>

      <loading v-if="loading"></loading>
      <div class="vx-card p-6" v-if="!loading">
        <!-- AgGrid Table -->
        <ag-grid-vue
          @gridReady="onGridReady"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4"
          domLayout="autoHeight"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="suite"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
        >
        </ag-grid-vue>

        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </div>

      <vs-popup class="holamundo" title="" :active.sync="deletePointSettingPopupActive">
        <p>{{ deletePointSettingPopupContent }}</p>
        <div v-if="deleteProcess">
          <font-awesome-icon icon="spinner" spin />
        </div>
        <div class="delete-confirm-popup-buttons" v-else>
          <vs-button color="primary" type="filled" @click="deletePointSetting()">{{
            $t('users.suite.popups.confirm_delete_user.buttons.confirm')
          }}</vs-button>
          <vs-button color="warning" type="filled" @click="deletePointSettingPopupActive = false">{{
            $t('users.suite.popups.confirm_delete_user.buttons.cancel')
          }}</vs-button>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererLink from '../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../components/cell-renderer/points_settings/CellRendererActions.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)

export default {
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,

    loading
  },
  data () {
    return {
      loading: true,
      // AgGrid
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1
      },
      columnDefs: [
        {
          headerName: this.$t('points_settings.table.id'),
          field: 'id',
          minWidth: 70,
          maxWidth: 100,
          sortable: true
        },
        {
          headerName: this.$t('points_settings.table.name'),
          field: 'name',
          minWidth: 250,
          maxWidth: 400,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'justify-content': 'flex-start', 'word-break': 'break-word'},
          filter: true
        },
        {
          headerName: this.$t('points_settings.table.point_type'),
          field: 'point_type',
          minWidth: 130,
          maxWidth: 400,
          flex: 2,
          cellStyle: {'line-height': '1.7', 'justify-content': 'flex-start', 'word-break': 'break-word'},
          filter: true
        },
        {
          headerName: this.$t('points_settings.table.point'),
          field: 'point',
          minWidth: 90,
          maxWidth: 110,
          sortable: true
        },
        /*
        {
          headerName: this.$t('points_settings.table.plus_point'),
          field: 'plus_point',
          width: 100,
          filter: true
        },
        */
        {
          headerName: this.$t('points_settings.table.max_appends'),
          field: 'max_appends',
          minWidth: 70,
          maxWidth: 160,
          flex: 2,
          sortable: true
        },
        {
          headerName: this.$t('points_settings.table.actions'),
          field: 'id',
          minWidth: 110,
          maxWidth: 130,
          cellRenderer: 'CellRendererActions'
        }
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deletePointSettingPopupActive: false,
      deletePointSettingPopupContent: '',
      deleteProcess: false,
      deletePointSettingId: 0
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-settings-points-settings'] === 'read' ||  
               accessUser['admin-settings'] === 'read'
      }
    },
    suite () {
      return this.$store.getters['point_setting/suite']
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },
  created () {
    this.$store.dispatch('point_setting/getSuite').then(() => {
      this.loading = false
    })
  },
  methods: {
    deletePointSetting () {
      this.deleteProcess = true
      this.$store
        .dispatch('point_setting/delete', { id: this.deletePointSettingId })
        .catch((error) => {
          this.$vs.notify({
            title: this.$t('users.suite.errors.title'),
            text: error,
            color: 'danger',
            time: 10000
          })
        })
        .then(() => {
          this.deleteProcess = false
          this.deletePointSettingPopupActive = false
        })
    },
    downloadPointSettingsFile () {
      this.$store.dispatch('point_setting/downloadExcelFilePointSettings')
    },
    onGridReady (params) {
      this.gridApi = params.api
    }
  }
}
</script>

<style lang="scss" scoped>
.add-button {
  margin-bottom: 30px;
}
.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.buttons-wrapper {
  margin-bottom: 30px;
}
.buttons-wrapper > button {
  display: inline-block;
  margin-right: 30px;
}
.sort-table-wrapper {
  width: 100%;
}
.list-item {
  transition: all 1s;
}
.list-item:hover {
  cursor: all-scroll;
}

::v-deep {
    .ag-cell {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
