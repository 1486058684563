import FormError from '../../modules/formError'

export default {
  suite: [],
  formError: new FormError(),
  edit: {
    slug: '',
    name: '',
    content: '',
    blockContent: null
  },
  appConfig: {}
}
