import state from './moduleMailingState.js'
import mutations from './moduleMailingMutations.js'
import actions from './moduleMailingActions.js'
import getters from './moduleMailingGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
