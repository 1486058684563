<template>

  <div id="page-code-list">
  
    <div class="vx-row">
      <loading v-if="loading"></loading>
  
      <div class="vx-col w-full mb-base">
        <div class="text-right">
          <vs-button class="add-button" @click="addCodePopupActive = true" type="line"><div class="flex items-center"><span class="material-icons mr-2">add</span> {{ $t('shop_products.edit.labels.add') }}</div></vs-button>
        </div>
        <div class="vx-card p-6" v-if="!loading">
          <!-- AgGrid Table -->
          <ag-grid-vue 
            @gridReady="onGridReady" 
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 text-center" 
            domLayout="autoHeight"
            :columnDefs="columnDefs" 
            :defaultColDef="defaultColDef"
            :rowData="codes" 
            rowSelection="multiple" 
            colResizeDefault="shift" 
            :animateRows="true" 
            :floatingFilter="true"
            :pagination="true" 
            :paginationPageSize="paginationPageSize" 
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl" />
  
          <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
        </div>
      </div>
      

      <!-- Редактирование кодов -->
      <vs-popup class="holamundo" :title="$t('shop_products.edit_codes.popup.title')" :active.sync="productCodePopupActive">
        <div class="flex items-center gap-4">
        <vs-input type="text" v-model="code" />
        <label >{{$t('shop_products.edit_codes.popup.title')}}</label>
      </div>
      <vs-alert v-if="deleted_at" class="alert mb-8 ml-0" active="true" color="#fff" icon="info" >
                <p>{{ $t('shop_products.fields.code_deactivated') }}</p>
              </vs-alert>
        
      <div class="delete-confirm-popup-buttons flex justify-between mt-8">
        <vs-button v-if="!deleted_at" color="danger" type="border" @click="deleteCode()">{{ $t('shop_products.edit_codes.popup.buttons.delete') }}</vs-button>
        <vs-button v-else color="success" type="border" @click="restoreCode()">{{ $t('shop_products.edit_codes.popup.buttons.restore') }}</vs-button>
        <vs-button type="filled" @click="changeCode()">{{ $t('shop_products.edit_codes.popup.buttons.save') }}</vs-button>
      </div>
      </vs-popup>

      <!-- Добавление кодов -->
      <vs-popup class="holamundo" :title="$t('shop_products.edit_codes.popup.add_codes')" :active.sync="addCodePopupActive">
        <div class="mb-10 ml-2 pr-4">
          <label class="add-table mb-8" :class="{ 'opacity-50': isRadioSelected !== 'file' }">
            <vs-radio
              v-model="isRadioSelected"
              vs-value="file"
              color="#7367F0"
              > 
              &nbsp;{{ $t('shop_products.edit_codes.popup.table_with_codes') }}
            </vs-radio>
            <div class="vx-col w-full mb-8">
              <div class="upload-excel-users-container">
                <div class="excel-file-buttons-container">
                  <vs-button 
                    type="flat" icon="get_app" 
                    class="flat-button" 
                    @click="$refs.file.click()"
                    :disabled="isRadioSelected !== 'file'"
                    >
                    {{ $t('teams.suite.buttons.upload_file') }} 
                  </vs-button>
                  <input type="file" ref="file" style="display: none" @change="previewFile">
                </div>
                <vs-alert class="alert" active="true" color="#fff" icon="info" >
                <p>{{ $t('shop_products.fields.info_codes') }}</p>
              </vs-alert>
                <div></div>

                <!-- <span class="text-danger text-sm" v-show="formError.has('digital_codes')">{{
                $t(formError.get('digital_codes')) }}</span> -->

                <vs-alert :active.sync="showFileName" closable icon-pack="feather" close-icon="icon-x">
                  {{ fileName }}
                </vs-alert>
              </div>
            </div>
          </label>
          <label class="add-code" :class="{ 'opacity-50': isRadioSelected !== 'string' }">
            <vs-radio
              v-model="isRadioSelected"
              vs-value="string"
              color="#7367F0"
              > 
              &nbsp;{{ $t('shop_products.edit_codes.popup.code') }}
            </vs-radio>
            <vs-input 
              type="text" 
              v-model="newCode"
              class="ml-8 mt-2"
              :disabled="isRadioSelected !== 'string'"
               />
          </label>
        </div>
        
        <div class="delete-confirm-popup-buttons flex justify-end">
          <vs-button 
            type="filled" 
            :disabled="!validAddCodes"
            @click="addCode()"
            >
            {{ $t('shop_products.edit_codes.popup.buttons.add') }}
          </vs-button>
        </div>

        </vs-popup>
  
  
    </div>
  </div>
</template>

<script>

import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

import CellRendererLink from '../../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../../components/cell-renderer/shop_product_code/CellRendererActions.vue'

import loading from '@/views/components/Loading.vue'
import XLSX from 'xlsx'

export default {

  components: {
    AgGridVue,
    loading,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions

  },

  data () {
    return {
      codes: [],
      loading: true,
      productCodePopupActive: false,
      addCodePopupActive: false,
      maxPageNumbers: 7,

      id: null,
      code: '',
      newCode: '',
      deleted_at: '',
      was_activated: null,
      productCodePopupId: 0,

      isRadioSelected: 'file',
      showFileName: false,
      fileName: '',
      file: null,

      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },

      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: params => {
          if (params.data.deleted_at) {
            return { backgroundColor: 'rgba(0, 0, 0, 0.10)', textAlign: 'center'}
          }
          return {textAlign: 'center'}
        },
        headerClass: 'text-center'
      },
      columnDefs: [
        {
          headerName: this.$t('shop_products.edit_codes.table.id'),
          field: 'id',
          minWidth: 80,
          maxWidth: 100
        },
        {
          headerName: this.$t('shop_products.edit_codes.table.code'),
          field: 'code',
          minWidth: 70
        },
        {
          headerName: this.$t('shop_products.edit_codes.table.purchased'),
          field: 'was_activated',
          width: 150
        },
        // {
        //   headerName: this.$t('shop_products.edit_codes.table.date_of_deletion'),
        //   field: 'deleted_at',
        //   filter: false,
        //   width: 225
        // },
        {
          headerName: this.$t('shop_products.edit_codes.table.date_of_activated'),
          field: 'activated_at',
          filter: false,
          width: 225,
          cellRenderer: (params) => {
            const deletedAt = params.data.deleted_at
            return deletedAt ? this.$t('shop_products.edit_codes.table.deactivated') : params.value
          }
        },
        {
          headerName: this.$t('shop_products.edit_codes.table.actions'),
          field: 'id',
          width: 150,
          cellRenderer: 'CellRendererActions'
        }
      ]
    }
  },
  created () {
    this.getCodes()
  },
  computed: {

    validAddCodes () {
      if (this.isRadioSelected === 'file') {
        return this.file
      }
      if (this.isRadioSelected === 'string') {
        return this.newCode
      }
    },

    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },
  methods: {
    onGridReady (params) {
      this.gridApi = params.api
    },
    getCodes () {
      this.loading = true
      this.productId = this.$route.params.id
      this.$store.dispatch('shop_product/getCodes', { id: this.productId })
        .then(() => {
          const edit = this.$store.getters['shop_product/codes']
          this.codes = JSON.parse(JSON.stringify(edit))     
        })
        .then(() => {
          for (const code of this.codes) {
            code.was_activated = code.was_activated ? this.$t('shop_products.edit_codes.table.yes') : this.$t('shop_products.edit_codes.table.no')
            code.deleted_at = this.dateFormat(code.deleted_at)
            code.activated = this.dateFormat(code.activated)
          }
        })
        .then(() => {
          this.loading = false
        })
    },

    dateFormat (date) {
      return date ? new Date(date).toLocaleString() : ''
    },
    deleteCode () {
      this.$store.dispatch('shop_product/deleteCode', { id: this.productCodePopupId, productId: this.productId})
        .then(() => {
          this.productCodePopupActive = false
        }).then(() => {
          this.getCodes()
        })
    },
    restoreCode () {
      this.$store.dispatch('shop_product/restoreCode', { id: this.productCodePopupId, productId: this.productId})
        .then(() => {
          this.productCodePopupActive = false
        }).then(() => {
          this.getCodes()
        })
    },
    changeCode () {
      this.$store.dispatch('shop_product/changeCode',
        {
          id: this.productCodePopupId,
          productId: this.productId,
          code: this.code
        })
        .then(() => {
          this.productCodePopupActive = false
        })
        .then(() => {
          this.getCodes()
        })
        .then(() => {
          this.$vs.notify({
            title: this.$t('shop_products.edit_codes.notify.title'),
            text: this.$t('shop_products.edit_codes.notify.text'),
            color: 'success',
            time: 10000
          })
        })
    },
    addCode () {
      if (this.isRadioSelected === 'string') {
        this.file = this.convertStringToXlsx(this.newCode)
      }
      this.$store.dispatch('shop_product/addCodes', { product_id: this.productId, digital_codes: this.file })
        .then(() => {
          this.addCodePopupActive = false
          this.$vs.notify({
            title: this.$t('shop_products.edit_codes.notify.title'),
            text: this.$t('shop_products.edit_codes.notify.add_code_text'),
            color: 'success'
          })
        })
        .then(() => {
          this.getCodes()
        })
        .catch(error => {
          this.$vs.notify({
            title: this.$t('shop_products.edit_codes.notify.error'),
            text: this.$t('shop_products.edit_codes.notify.error_text'),
            color: 'danger'
          })
          this.addCodePopupActive = false
          console.error(error)
        }) 
    },
    previewFile (event) {
      this.file = event.target.files[0]
      this.fileName = this.file.name
      this.showFileName = true
    },
    convertStringToXlsx (string) {
      const worksheet = XLSX.utils.aoa_to_sheet([[string]])
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      const xlsxBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xls' })
      const file = new File([new Uint8Array(xlsxBuffer)], 'code.xls', { type: 'application/vnd.ms-excel' })
      return file
    }
  }
}
</script>

<style lang="scss" scoped>
.add-button {
  padding: 0.3rem !important;
  border-width: 0 !important;
  font-weight: 600;
}

.alert {
  height: auto !important;
  margin-left: 18px;
}

::v-deep {

  .vs-button-line .vs-button-linex {
    height: 0 !important;
  }
  .ag-header-cell-text {
    margin: 0 auto;
  }

  .deleted {
    background-color: rgb(202, 85, 85) !important;
    color: rgb(255, 255, 255);
  }

  .vs-icon {
    font-size: 1.5em;
  }

  .vs-alert {
    color: #B9B9B9;
    padding-right: 80px;
    font-size: 12px;
    .vs-icon {
      color: #DA7E3C;
      height: 18px;
      top: 10px;
      font-size: 1.8em;
    }
  }
}
</style>
