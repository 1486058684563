<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-32 mt-32">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-show="!loading">
      <vx-card class="mb-6" :title="$t(`trainings.suite.labels.${type}.training_description`)" v-show="!loading">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <vs-input class="w-full mb-4" :label-placeholder="$t('trainings.create.labels.name')" v-model="name" />
            <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
          </div>
          <div v-show="!['polling', 'polling_health'].includes(type)" class="vx-col md:w-1/2 w-full">
            <datepicker
              class="w-full mt-5"
              :format="format"
              v-model="date"
              :placeholder="$t('trainings.create.labels.date')"
              :language="datepicker.locales[$i18n.locale]"
              :monday-first="$i18n.locale === 'ru' ? true : false"
            ></datepicker>
            <span class="text-danger text-sm" v-show="formError.has('date')">{{ $t(formError.get('date')) }}</span>
          </div>
        </div>
        <div v-if="!['polling', 'polling_health'].includes(type)" class="vx-row">
          <div class="vx-col w-full mb-2">
            <v-select
              v-model="tags"
              multiple
              :placeholder="$t('categories.edit.labels.tags')"
              :closeOnSelect="false"
              :options="tagsOptions"
              :selectable="option => tags.indexOf(option) < 0"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
            <span class="text-danger text-sm" v-show="formError.has('tags')">{{ $t(formError.get('tags')) }}</span>
          </div>
        </div>
        <div v-if="['polling', 'polling_health'].includes(type) === false" class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-textarea class="w-full mb-2" :label="$t('trainings.create.labels.announcement')" v-model="announcement" />
            <span class="text-danger text-sm" v-show="formError.has('announcement')">{{ $t(formError.get('announcement')) }}</span>
          </div>
        </div>
        <div v-show="['polling', 'polling_health'].includes(type) === false" class="vx-row">
          <div class="vx-col w-full mb-2">
            <vx-card no-shadow card-border :title="$t('trainings.create.labels.block_description')">
              <editor ref="editor" :config="editorJsConfig" />
              <span class="text-danger text-sm" v-show="formError.has('block_description')">{{
                $t(formError.get('block_description'))
              }}</span>
            </vx-card>
          </div>
        </div>
      </vx-card>
      <vx-card v-if="['polling', 'polling_health'].includes(type) === false" class="mb-6" :title="$t(`trainings.suite.labels.${type}.training_image`)">
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-container" style="width: 150px; height: 150px; margin: 0 auto">
              <picture-input
                ref="pictureInput"
                @change="onImgChange"
                @error="onImgError"
                width="150"
                height="150"
                margin="16"
                :accept="this.$baseImageAcceptTypes"
                buttonClass="btn"
                size="10"
                :hideChangeButton="true"
                :crop="false"
                :alertOnError="false"
                :customStrings="{
                  drag: $t('trainings.create.labels.image'),
                }"
              >
              </picture-input>
              <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
            </div>
          </div>
        </div>
        <div v-if="imgCrop" class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-crop-container">
              <cropper
                ref="cropper"
                classname="cropper"
                :src="imgCrop"
                :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }"
              ></cropper>
            </div>
          </div>
        </div>
      </vx-card>
      <vx-card :title="$t(`trainings.suite.labels.${type}.training_results`)">
        <div v-if="['polling', 'polling_health'].includes(type) === false">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mb-6">
              <vs-input class="w-full mb-4" :label="pointPlaceholder" v-model="point" type="number" />
              <span class="text-danger text-sm" v-show="formError.has('point')">{{ $t(formError.get('point')) }}</span>
            </div>
            <div class="vx-col md:w-1/2 w-full mb-6">
              <vs-input class="w-full mb-4" :label="$t('trainings.create.labels.coin')" v-model="coins" type="number" />
              <span class="text-danger text-sm" v-show="formError.has('coins')">{{ $t(formError.get('coins')) }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-6">
              <vs-input
                class="w-full mb-4"
                :label="$t('trainings.create.labels.required_viewing_time')"
                v-model="requiredViewingTime"
                type="time"
                step="2"
              />
              <span class="text-danger text-sm" v-show="formError.has('required_viewing_time')">{{
                $t(formError.get('required_viewing_time'))
              }}</span>
            </div>
          </div>

          <!-- Скрываем блок. значение 'cancelGender' по умолчанию ставим 'true' -->
          <!-- <div class="vx-row">
            <div class="vx-col w-full mb-6">
              <vs-checkbox v-model="cancelGender">{{ $t('trainings.create.labels.gender') }}</vs-checkbox>
              <span class="text-danger text-sm" v-show="formError.has('cancel_gender')">{{ $t(formError.get('cancel_gender')) }}</span>
            </div>
          </div> -->
          <div class="vx-row">
            <div class="vx-col w-full mb-6">
              <vs-checkbox v-model="notIncludedToStatistics">{{ $t('trainings.create.labels.not_included_to_statistics') }}</vs-checkbox>
              <span class="text-danger text-sm" v-show="formError.has('not_included_to_statistics')">{{
                $t(formError.get('not_included_to_statistics'))
              }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <vx-input-group prependClasses="border border-solid border-grey border-r-0">
                <template slot="prepend">
                  <div class="prepend-text">
                    <vs-checkbox icon-pack="feather" icon="icon-camera" class="input-checkbox" v-model="photoRequired">{{
                      $t('trainings.create.labels.photo_required')
                    }}</vs-checkbox>
                  </div>
                </template>

                <vs-input
                  :disabled="!photoRequired"
                  class="w-full"
                  :placeholder="$t('trainings.create.labels.photo_required_description')"
                  v-model="photoRequiredDescription"
                />
              </vx-input-group>
              <span class="text-danger text-sm" v-show="formError.has('photo_required')">{{ $t(formError.get('photo_required')) }}</span>
              <span class="text-danger text-sm" v-show="formError.has('photo_required_description')">{{
                $t(formError.get('photo_required_description'))
              }}</span>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <vx-input-group class="mb-base" prependClasses="border border-solid border-grey border-r-0">
                <template slot="prepend">
                  <div class="prepend-text">
                    <vs-checkbox icon-pack="feather" icon="icon-message-square" class="input-checkbox" v-model="commentRequired">{{
                      $t('trainings.create.labels.comment_required')
                    }}</vs-checkbox>
                  </div>
                </template>

                <vs-input
                  :disabled="!commentRequired"
                  class="w-full"
                  :placeholder="$t('trainings.create.labels.comment_required_description')"
                  v-model="commentRequiredDescription"
                />
              </vx-input-group>
              <span class="text-danger text-sm" v-show="formError.has('comment_required')">{{
                $t(formError.get('comment_required'))
              }}</span>
              <span class="text-danger text-sm" v-show="formError.has('comment_required_description')">{{
                $t(formError.get('comment_required_description'))
              }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

            <vs-table ref="table" :data="exercises">
              <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
                <div class="flex flex-wrap-reverse items-center data-list-btn-container">
                  <!-- ADD NEW -->
                  <div
                    class="
                      btn-add-new
                      p-3
                      mb-4
                      mr-4
                      rounded-lg
                      cursor-pointer
                      flex
                      items-center
                      justify-center
                      text-lg
                      font-medium
                      text-base text-primary
                      border border-solid border-primary
                    "
                    @click="addNewData"
                  >
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                    <span class="ml-2 text-base text-primary">{{ $t(`trainings.create.buttons.${type}.add_task`) }}</span>
                  </div>
                </div>
              </div>

              <template slot="thead">
                <vs-th sort-key="name">{{ $t('exercises.suite.table.name') }}</vs-th>
                <vs-th sort-key="category">{{ $t('exercises.suite.table.result_type') }}</vs-th>
                <vs-th sort-key="is_required">{{ $t('exercises.suite.table.is_required') }}</vs-th>
                <vs-th>{{ $t('exercises.suite.table.actions') }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                      <p class="product-name font-medium truncate">{{ tr.name }}</p>
                    </vs-td>

                    <vs-td>
                      <p class="product-category">{{ $t(`trainings.exercises.labels.${tr.accept_any_answers ? tr.result_type + '_poll' : tr.result_type }`) }}</p>
                    </vs-td>

                    <vs-td>
                      <p class="product-is-required">{{ tr.is_required ? '+' : '' }}</p>
                    </vs-td>

                    <vs-td class="whitespace-no-wrap">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="w-5 h-5 hover:text-primary stroke-current"
                        @click.stop="editData(tr, indextr)"
                      />
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="ml-2"
                        @click.stop="deleteData(indextr)"
                      />
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </div>
        </div>

        <div class="vx-row fixed-buttons">
          <div class="vx-col w-full">
            <div class="flex gap-3">
              <vs-button @click="create(stayAfter = true)" :disabled="createButtonDisabled">{{
                $t('trainings.create.buttons.create')
              }}</vs-button>
              <vs-button  @click="create()" :disabled="createButtonDisabled">
            {{ $t('trainings.create.buttons.create') }} {{ $t('suite.and_exit') }}
          </vs-button>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <vs-popup class="holamundo" title="Image error" :active.sync="imageErrorPopupActive">
      <p>{{ imageErrorPopupMessage }}</p>
    </vs-popup>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import PictureInput from 'vue-picture-input'
import Datepicker from 'vuejs-datepicker'
import { en, ru } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import DataViewSidebar from '../components/DataViewSidebar.vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import helpers from '@/helpers/helpers.js'
import editorJsConfig from '@/editorjs/config.js'
import loading from '@/views/components/Loading.vue'

import vSelect from 'vue-select'

export default {
  components: {
    PictureInput,
    quillEditor,
    Datepicker,
    DataViewSidebar,
    Cropper,
    loading,
    'v-select': vSelect
  },
  data () {
    return {
      categoryId: 0,
      name: '',
      announcement: '',
      description: '',
      image: null,
      imgCrop: null,
      date: helpers.today('-'),
      format: 'dd.MM.yyyy',
      cancelGender: true,
      point: 0,
      coins: 0,
      defaultPoint: 30,
      notIncludedToStatistics: false,
      photoRequired: false,
      photoRequiredDescription: '',
      commentRequired: false,
      commentRequiredDescription: '',
      requiredViewingTime: '00:00:00',
      isMounted: false,
      tags: [],
      tagsOptions: [],

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},

      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error',
      editorJsConfig: Object.assign({}, editorJsConfig),
      editorOption: {
        modules: {
          toolbar: '#toolbar'
        }
      },
      createButtonDisabled: false,
      datepicker: {
        locales: {
          en,
          ru
        }
      },
      loading: true,
      type: 'programm'
    }
  },
  computed: {
    exercises () {
      return this.$store.getters['training/exercises']
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.exercises.length
    },

    pointPlaceholder () {
      return this.$t('trainings.create.labels.point').replace('[POINT]', this.defaultPoint)
    },
    formError () {
      return this.$store.getters['training/formError']
    }
  },
  methods: {
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },
    create (stayAfter) {
      this.loading = !stayAfter
      this.createButtonDisabled = true
      this.dispatch('training/create', stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        const blockDescription = await this.$refs.editor.state.editor.save()

        const payload = {
          category_id: this.categoryId,
          name: this.name,
          announcement: this.announcement,
          description: this.description,
          block_description: JSON.stringify(blockDescription),
          image:
            typeof this.image === 'string' ? this.image : this.image !== null ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : '',
          date: moment(this.date).format('DD.MM.YYYY'),
          cancel_gender: +this.cancelGender,
          point: this.point,
          coins: this.coins || 0,
          tags: this.tags,
          not_included_to_statistics: +this.notIncludedToStatistics,
          photo_required: +this.photoRequired,
          photo_required_description: this.photoRequiredDescription,
          comment_required: +this.commentRequired,
          comment_required_description: this.commentRequiredDescription,
          required_viewing_time: this.requiredViewingTime || ''
        }

        const pathSegments = this.$route.path.split('/')
        pathSegments[pathSegments.length - 1] = 'edit-training'
        const pathToEdit = pathSegments.join('/')

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          type: 'create',
          stayAfter: stayAfter,
          routerPush: {
            name: `${this.type}-trainings`
          },
          routerPushToEdit: {
            path: pathToEdit
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
        
      } finally {
        this.loading = false
        this.createButtonDisabled = false
      }  
    },

    addNewData () {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    deleteData (index) {
      this.$store.dispatch('training/removeExercise', { index: index }).catch((err) => {})
    },
    editData (data, index) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      data.index = index
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    getOrderStatusColor (status) {
      if (status === 'on_hold') return 'warning'
      if (status === 'delivered') return 'success'
      if (status === 'canceled') return 'danger'
      return 'primary'
    },
    getPopularityColor (num) {
      if (num > 90) return 'success'
      if (num > 70) return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50) return 'danger'
      return 'primary'
    },
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    }
  },
  created () {
    this.$store.dispatch('tag/getTagsOptions').then((res) => {
      this.tagsOptions = this.$store.getters['tag/tagsOptions']
    })
    
    this.$store.dispatch('point_setting/getSuite').then(() => {
      this.defaultPoint = this.$store.getters['point_setting/suite'].find(obj => obj.point_type === 'training_result').point
    })

    switch (this.$router.currentRoute.name) {
    case 'programm-create-training':
      this.type = 'programm'
      break
    case 'challenge-create-training':
      this.type = 'challenge'
      break
    case 'polling-create-training':
      this.type = 'polling'
      break
    case 'polling_health-create-training':
      this.type = 'polling_health'
      break
    }

    this.$store.commit('training/clearFormError')
    this.categoryId = this.$route.params.categoryId
    this.$store.commit('training/resetExercises')
    this.loading = false
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
.image-container .preview-container {
  margin: 0 !important;
}
#data-list-list-view {
  .vs-con-table {
    /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
