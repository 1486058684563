<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
    <!-- <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="confirmDeleteRecord()" /> -->
  </div>
</template>

<script>

export default {
  name: 'CellRendererActions',
  methods: {
    editRecord () {
      this.$store.commit('setListPage', this.params.api.paginationGetCurrentPage())
      this.$router.push(`${this.$route.path}/edit/${this.params.value}`).catch(() => {})
    }
    // confirmDeleteRecord () {
    //   this.params.context.componentParent.deleteMailingPopupActive = true
    //   this.params.context.componentParent.deleteMailingPopupContent = this.$t('mailings.suite.popups.confirm_delete.message').replace('[NAME]', this.params.data.title)
    //   this.params.context.componentParent.deleteMailingId = this.params.value
    //   this.params.context.componentParent.deleteMailingPopupActive = true
    // }
  }
}
</script>
