<template>
  <div v-if="withoutReport" class="report-cell">{{ $t('reports.table.done') }}</div>
  <div v-else class="flex items-center report-cell" >
      <div v-if="getImages().length" class="report-cell__img-box">
        <img v-img="{src: getUrl(getImages('big')[0])}" :src="getUrl(getImages('thumb')[0])" alt="" @click.stop >
      </div>
      <div v-if="prevueReport" style="max-width: 100%;">
        <div v-if="Array.isArray(prevueReport.value)" class="report-cell__content">
          <ol>
            <li v-for="(item, index) in prevueReport.value" :key="index">{{ item.answer }}</li>
          </ol>
        </div>
        <div v-else-if="prevueReport.result_type === 'answer_by_scale'" class="custom-input">
            <label class="custom-slider">
              <span class="text-sm label">{{ prevueReport.name }}</span>
              <vs-slider color="#DA7E3C" ticks :value="prevueReport.value" disabled />
            </label>
        </div>
      <div v-else class="report-cell__content" v-html="prevueReport.value"></div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'CellRendererActions',
  data () {
    return {
      answers: []
    }
  },
  methods: {
    getContent (resultType) {
      const result = [] 
      if (this.params.data) {
        const exerciseResults = this.params.data.exercise_results
        exerciseResults.forEach(element => {
          if (element.result_type === resultType) {
            result.push(element.value)
          }
        })
      }
      return result
    },
    formatTime (milliseconds) {
      const time = moment.duration(milliseconds)
      const formattedTime = moment.utc(time.asMilliseconds()).format('HH:mm:ss')
      return formattedTime
    },
    getImages (size = 'big') {
      let images = []
      if (!this.params.data) {
        return images
      }
      const activityImg = this.params.data.images.map(el => {
        if (size === 'big') {
          return el.image_path || el.image_path_600 || el.image_path_300 || el.image_path_168
        } else if (size === 'thumb') {
          return el.image_path_168 || el.image_path_300 || el.image_path_600 || el.image_path
        }
      })
      if (activityImg.length) {
        images = activityImg
      }
      const exerciseResults = this.params.data.exercise_results

      if (exerciseResults && exerciseResults.length) {
        exerciseResults.forEach(element => {
          if (element.result_type === 'photo') {
            const resultsImg = element.images.map(el => {
              if (size === 'big') {
                return el.image_path || el.image_path_600 || el.image_path_300 || el.image_path_168
              } else if (size === 'thumb') {
                return el.image_path_168 || el.image_path_300 || el.image_path_600 || el.image_path
              }
            })
            if (resultsImg.length) {
              images = [...images, ...resultsImg]
            }
          }
        })
      }
      
      return images
    }

  },
  computed: {
    withoutReport () {
      if (this.prevueReport === null) {
        return true
      }
      return !this.prevueReport.value && this.getImages().length === 0
    },
    prevueReport () {
      let data = null
      if (this.params.data) {
        const cloneParams = JSON.parse(JSON.stringify(this.params.data))
        const filteredExerciseResults = cloneParams.exercise_results.filter(item => {
          return item.result_type !== 'photo'
        })
        const comment = cloneParams.comment
        data = cloneParams.activity_results.find(item => item.value) || filteredExerciseResults.find(item => item.value) || null   
        if (data) {
          if (data.result_type === 'time_less' || data.result_type === 'time_more' || data.result_type === 'time') {
            data.value = data.value ? this.formatTime(data.value) : ''
          }

          if (data.result_type === 'distance') {
            data.value += ` ${this.$t('reports.suite.km')}`
          }

          if (data.result_type === 'radio_answers') {
            data.value = data.value[0].answer
          }

          if (data.result_type === 'amount') {
            data.value += ` ${this.$t('reports.suite.amount')}`
          }
        } else {
          data = {value: comment}
        }
        
      }
      return data
    },

    content () {
      const comment = this.getContent('comment')
      const repetitions = this.getContent('repetitions')
      const time_less = this.getContent('time_less')
      const time_more = this.getContent('time_more')
      const enter_correct_answer = this.getContent('enter_correct_answer')
      const radio_answers = this.getContent('radio_answers')
      const flags_answers =  this.getContent('flags_answers')
      const answer_by_scale = this.getContent('answer_by_scale')
      return comment
    }

  }
}
</script>
<style lang="scss" scoped>
ol {
  list-style-type: decimal !important;
  padding-left: 16px !important;
}

.report-cell {
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  &__img-box {
    display: flex;
    flex: 0 0 52px;
    width: 52px;
    height: 47px;
    margin: 8px 8px 8px 0px;
    padding: 3px;
    background: #D9D9D9;
    border-radius: 2px;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      margin: auto;
    }
  }

  &__content {
    font-size: 12px;
    cursor: pointer;
    max-width: 100%;
    text-wrap: balance;
    white-space: normal;
    display: -webkit-box; /* Задаем контейнеру тип блока */
    -webkit-box-orient: vertical; /* Располагаем элементы в столбец */
    -webkit-line-clamp: 3; /* Ограничение на количество строк */
    line-clamp: 3;
    overflow: hidden; /* Скрытие текста, который не помещается в блоке */
    text-overflow: ellipsis; /* Добавление многоточия в конце текста */
  }
}

</style>

<style lang="scss">

.custom-slider {
  .label {
    max-width: 100%;
    font-size: 12px;
    text-overflow: ellipsis;
    text-wrap: balance;
  }
  .con-vs-slider {
 
  margin: 12px 0px;

  min-width: 220px;
  max-width: 220px;
  &.disabledx {
    opacity: 1;
  }
  .vs-slider--circle {
    background: #DA7E3C !important;
  }
}
}


</style>
