import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'

export default {
  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/content-pages')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  create ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/content-pages', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/content-pages/${payload.id}/edit`, { params: payload })
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/content-pages/${payload.id}`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  getAppConfig ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/content-pages/app-config')
        .then((response) => {
          commit('retrieveAppConfig', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
