import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'


export default {
  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/chats/comments/get_all_comments')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  
  delete (context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/chats/${payload.chat_id}/comments/${payload.id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  restore (context, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/chats/${payload.chat_id}/comments/${payload.id}/restore`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
