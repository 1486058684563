<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-else>
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
          <div class="vx-col w-full mb-4 flex">
            <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
            <label class="ml-4 text-lg" :class="{'text-orange': isActive}">{{ $t('announcements.fields.is_active') }}</label>
            <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active')) }}</span>
          </div>
        </div>
      <div class="vx-card tabs-container px-6 pt-6 pb-3">
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('announcements.fields.title')" v-model="title" />
            <span class="text-danger text-sm" v-show="formError.has('title')">{{ $t(formError.get('title')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('announcements.fields.content')" v-model="content" />
            <span class="text-danger text-sm" v-show="formError.has('content')">{{ $t(formError.get('content')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('announcements.fields.url')" v-model="url" />
            <span class="text-danger text-sm" v-show="formError.has('url')">{{ $t(formError.get('url')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-container" style="width: 150px; height:150px; margin: 0 auto;">
              <picture-input
                v-if="!loading"
                ref="pictureInput"
                @prefill="onImgPrefill"
                @change="onImgChange"
                @error="onImgError"
                width="150"
                height="150"
                margin="16"
                :accept="this.$baseImageAcceptTypes"
                buttonClass="btn"
                size="10"
                :hideChangeButton="true"
                :crop="false"
                :prefill="imageUrl"
                :alertOnError="false"
                :customStrings="{
                drag: $t('announcements.edit.labels.image'),
              }"
              >
              </picture-input>
              <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
            </div>
          </div>
        </div>
        <div v-if="imgCrop" class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-crop-container">
              <cropper
                ref="cropper"
                classname="cropper"
                :src="imgCrop"
                :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }"
                @change="changeCropImg"
                :stencil-props="{
                  aspectRatio: 3 / 2,
              }"
              ></cropper>
            </div>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <v-select v-model="segments" multiple :placeholder="$t('segments.suite.title')" :closeOnSelect="false" :options="segmentsOptions" :selectable="option => segments.indexOf(option) < 0" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('segments')">{{ $t(formError.get('segments')) }}</span>
          </div>
        </div>

        <div class="vx-row ">
          <div class="vx-col w-full mb-4 mt-4">
            <flat-pickr :config="configdateTimePicker" v-model="deactivateAt" :placeholder="$t('announcements.fields.deactivate_at')" :monday-first="($i18n.locale === 'ru') ? true : false" />
            <span class="text-danger text-sm" v-show="formError.has('deactivate_at')">{{ $t(formError.get('deactivate_at')) }}</span>
          </div>
        </div>
      </div>
      <div class="vx-row fixed-buttons">
          <div class="vx-col w-full flex gap-3">

            <vs-button 
              v-if="!isOnlyRead" 
              @click="action(stayAfter = true)" 
              :disabled="actionButtonDisabled">
              {{ $t('pedometer.challenges.' + type + '.buttons.' + type) }}
            </vs-button>

            <vs-button 
              v-if="!isOnlyRead" 
              @click="action()" 
              :disabled="actionButtonDisabled">
              {{ $t('pedometer.challenges.' + type + '.buttons.' + type) }} {{ $t('suite.and_exit') }}
            </vs-button>
            
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import helpers from '@/helpers/helpers.js'
import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import loading from '@/views/components/Loading.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import locale from 'flatpickr/dist/l10n/ru'
import moment from 'moment'
import vSelect from 'vue-select'

export default {
  components: {
    flatPickr,
    PictureInput,
    vSelect,
    Cropper,
    loading
  },
  data () {
    return {
      id: 0,
      type: 'create',
      actionButtonDisabled: false,
      loading: true,
      title: '',
      content: '',
      isActive: true,
      deactivateAt: null,
      segments: [],
      segmentsOptions: [],
      url: '',
      image: null,
      imageUrl: '',
      imgCrop: null,
      imgWidth: null,
      imgHeight: null,
      isChangedImg: false,
      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error',
      configdateTimePicker: {
        dateFormat: 'd.m.Y',
        locale: locale[this.$i18n.locale]
      }
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-announcements'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['announcement/formError']
    }
  },
  methods: {
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'announcement/create' : 'announcement/update'
      this.dispatch(patch, stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        const payload = {
          id: this.id,
          title: this.title,
          content: this.content,
          is_active: +this.isActive,
          segments: this.segments,
          deactivate_at: (!this.deactivateAt) ? '' : moment(this.deactivateAt, 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm:ss'),
          url: this.url,
          image:
            (this.isChangedImg && this.image !== null) ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : null
        }

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name: 'announcements'
          },
          routerPushToEdit: { 
            name: 'announcements-edit' 
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    },
    onImgPrefill () {
      this.imgCrop = this.$refs.pictureInput.image
      this.image = this.imgCrop
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    changeCropImg ({coordinates, canvas}) {
      if (!this.imgWidth && !this.imgHeight) {
        this.imgWidth = coordinates.width
        this.imgHeight = coordinates.height
      }
      if ((coordinates.width === this.imgWidth || coordinates.height === this.imgHeight) && this.type === 'edit' && this.imageUrl) {
        this.isChangedImg = false
      } else {
        this.isChangedImg = true
      }
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    }
  },
  created () {
    this.$store.dispatch('segment/getSegmentsOptions').then(() => {
      this.segmentsOptions = this.$store.getters['segment/segmentsOptions']
    })
    this.id = this.$route.params.id
    if (this.$router.currentRoute.name === 'announcements-edit') {
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('announcement/edit', { id: this.id })
        .then(() => {
          const edit = this.$store.getters['announcement/edit']
          this.title = edit.title
          this.content = edit.content
          this.isActive = edit.isActive
          this.segments = edit.segments
          this.url = edit.url
          this.deactivateAt = edit.deactivateAt ? moment(edit.deactivateAt).format('DD.MM.YYYY') : null
          
          this.imageUrl = this.getUrl(edit.imagePath, `${this.$http.defaults.baseURL}/api/admin-panel`)

          this.id = edit.id

          this.loading = false
        })
    } else {
      this.loading = false
    }
    this.$store.commit('announcement/clearFormError')
  }
}
</script>

<style>

</style>
