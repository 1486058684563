<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-else>
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <vx-card class="mb-6" :title="$t('notifications.fields.message')">
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('notifications.fields.title')" v-model="title"/>
            <span class="text-danger text-sm" v-show="formError.has('title')">{{ $t(formError.get('title')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('notifications.fields.content')" v-model="content"/>
            <span class="text-danger text-sm"
                  v-show="formError.has('content')">{{ $t(formError.get('content')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('notifications.fields.url')" v-model="url"/>
            <span class="text-danger text-sm" v-show="formError.has('url')">{{ $t(formError.get('url')) }}</span>
          </div>
        </div>

        <div class="vx-row flex items-center">
          <div class="vx-col md:w-1/2 w-full mb-4">
            <vs-input
              class="w-full"
              :label-placeholder="$t('notifications.fields.additional_url')"
              v-model="additional_url"
              @input="onInputAdditionalUrl"
            />
            <span class="text-danger text-sm absolute" v-show="formError.has('additional_url')">
              {{ $t(formError.get('additional_url')) }}
            </span>
          </div>
          <div class="vx-col md:w-1/2 w-full flex items-center gap-4">
            <vs-switch v-model="isOldRoutInApp" icon-pack="feather" vs-icon="icon-check" ></vs-switch>
            <span>{{ $t('link for the old interface') }}</span>
          </div>
        </div>
        <div class="vx-row
        ">
          <div class="vx-col md:w-1/2 w-full mb-4">
            <v-select
              v-model="additional_url_type"
              :options="additional_url_type_options"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
            <span class="text-danger text-sm absolute" v-show="formError.has('additional_url_type')">
              {{ $t(formError.get('additional_url_type')) }}
            </span>
          </div>

          <div class="vx-col md:w-1/2 w-full mb-4" v-if="additional_url_type.need_id">
            <v-select
              v-model="additional_url_object"
              :options="getDetailInfo"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
            <span class="text-danger text-sm absolute" v-show="formError.has('additional_url_object_id')">
              {{ $t(formError.get('additional_url_object_id')) }}
            </span>
          </div>
        </div>

      </vx-card>
      <vx-card class="mb-6" :title="$t('notifications.fields.sending_settings')">

        <div class="vx-row">
          <div class="vx-col w-64 mb-4">
            <label><b>{{ $t('notifications.fields.send_type.title') }}</b></label>
            <v-select
              v-model="send_type"
              :options="send_type_options"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
          </div>
        </div>

        <template v-if="send_type.id == 0">
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <flat-pickr
                :config="configdateTimePicker" v-model="start_after"
                :placeholder="$t('notifications.fields.start_after')"
                :monday-first="($i18n.locale === 'ru')"
              />
              <span class="text-danger text-sm" v-show="formError.has('start_after')">
                {{ $t(formError.get('start_after')) }}
              </span>
            </div>
          </div>

          <br/>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label><b>{{ $t('notifications.fields.address') }}</b></label>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>{{ $t('notifications.fields.send_all') }}</label>
              <label v-if="!isActiveShowNotSegmentedForFamilyUsers"> {{ $t('notifications.fields.except_for_family_users') }}</label>
              <vs-switch v-model="send_all" icon-pack="feather" vs-icon="icon-check"></vs-switch>
              <span class="text-danger text-sm" v-show="formError.has('send_all')">
                {{ $t(formError.get('send_all')) }}
              </span>
            </div>
          </div>

          <template v-if="!send_all">
            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <label>{{ $t('segments.suite.title') }}</label>
                <v-select
                  v-model="segments"
                  multiple
                  :placeholder="$t('segments.suite.title')"
                  :closeOnSelect="false"
                  :options="segmentsOptions"
                  :selectable="option => segments.indexOf(option) < 0"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
                <span class="text-danger text-sm" v-show="formError.has('segments')">
                  {{ $t(formError.get('segments')) }}
                </span>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <label>{{ $t('notifications.fields.select_clubs') }}</label>
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="clubs_selected"
                  :options="clubs_options"
                  :selectable="option => clubs_selected.indexOf(option) < 0"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
                <span class="text-danger text-sm" v-show="formError.has('is_active')">
                  {{ $t(formError.get('clubs_selected')) }}
                </span>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <label>{{ $t('notifications.fields.select_programms') }}</label>
                <template>
                  <v-select
                    multiple
                    :closeOnSelect="false"
                    v-model="programms_selected"
                    :options="programms_options"
                    :selectable="option => programms_selected.indexOf(option) < 0"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  />
                  <br>
                </template>
                <span class="text-danger text-sm" v-show="formError.has('is_active')">
                  {{ $t(formError.get('programms_selected')) }}
                </span>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <label>{{ $t('notifications.fields.select_challenges') }}</label>
                <template>
                  <v-select
                    multiple
                    :closeOnSelect="false"
                    v-model="challenges_selected"
                    :options="challenges_options"
                    :selectable="option => challenges_selected.indexOf(option) < 0"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
                  <br>
                </template>
                <span class="text-danger text-sm" v-show="formError.has('is_active')">
                  {{ $t(formError.get('challenges_selected')) }}
                </span>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <label>{{ $t('notifications.fields.select_events') }}</label>
                <template>
                  <v-select
                    multiple
                    :closeOnSelect="false"
                    v-model="events_selected"
                    :label="'alternative_label'"
                    :options="events_options"
                    :selectable="option => events_selected.indexOf(option) < 0"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  />
                  <br>
                </template>
                <span class="text-danger text-sm" v-show="formError.has('is_active')">
                  {{ $t(formError.get('events_selected')) }}
                </span>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <label>{{ $t('notifications.fields.select_trainings') }}</label>
                <template>
                  <v-select
                    multiple
                    :closeOnSelect="false"
                    :label="'alternative_label'"
                    v-model="trainings_selected"
                    :options="trainings_options"
                    :selectable="option => trainings_selected.indexOf(option) < 0"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  />
                  <br>
                </template>
                <span class="text-danger text-sm" v-show="formError.has('is_active')">
                  {{ $t(formError.get('trainings_selected')) }}
                </span>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full mb-2">
                <label>{{ $t('notifications.fields.select_users') }}</label>
                <template>
                  <v-select
                    multiple
                    :closeOnSelect="false"
                    v-model="users_selected"
                    :options="users_options"
                    :selectable="option => users_selected.indexOf(option) < 0"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  />
                  <br>
                </template>
                <span class="text-danger text-sm" v-show="formError.has('is_active')">
                  {{ $t(formError.get('users_selected')) }}
                </span>
              </div>
            </div>
          </template>
        </template>

        <template v-if="send_type.id == 1">
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>{{ $t('notifications.fields.select_pedometer') }}</label>
              <v-select
                v-model="goal_selected"
                :options="goal_options"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
              <span class="text-danger text-sm" v-show="formError.has('pedometers_selected')">
                {{ $t(formError.get('pedometers_selected')) }}
              </span>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <vs-input
                class="w-full mb-4"
                :label-placeholder="$t('notifications.fields.target_count')"
                v-model="target_count"
              />
              <span class="text-danger text-sm" v-show="formError.has('target_count')">
                {{ $t(formError.get('target_count')) }}
              </span>
            </div>
          </div>

        </template>
      </vx-card>
      <div v-if="!isOnlyRead" class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button @click="action(stayAfter = true)" :disabled="actionButtonDisabled">
            {{ $t('pedometer.challenges.' + type + '.buttons.' + type) }}
          </vs-button>
          <vs-button @click="action()" :disabled="actionButtonDisabled">
            {{ $t('pedometer.challenges.' + type + '.buttons.' + type) }} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Datepicker from 'vuejs-datepicker'
//import {en, ru} from 'vuejs-datepicker/dist/locale'
import helpers from '@/helpers/helpers.js'
import moment from 'moment'
import loading from '@/views/components/Loading.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import locale from 'flatpickr/dist/l10n/ru'


export default {
  components: {
    //Datepicker,
    flatPickr,
    'v-select': vSelect,
    loading
  },
  data () {
    return {
      copyId: 0,
      today: helpers.today('-'),
      dateFormat: 'dd.MM.yyyy H:i:s',
      datepicker: {},
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'd.m.Y H:i',
        locale: locale[this.$i18n.locale]
      },
      id: 0,
      start: null,
      end: null,
      countSteps: null,
      send_all: true,
      type: 'create',
      actionButtonDisabled: false,
      loading: true,
      title: '',
      content: '',
      url: '',
      additional_url: '',
      additional_url_type: '',
      additional_url_type_default: {
        label: '',
        value: null,
        need_id: false
      },
      additional_url_object: '',
      additional_url_object_id: '',
      start_after: null,
      send_type: {
        id: 0,
        label: this.$t('notifications.fields.send_type.labels.by_time')
      },
      target_count: '',
      users_selected: [],
      clubs_selected: [],
      programms_selected: [],
      challenges_selected: [],
      events_selected: [],
      trainings_selected: [],
      goal_selected: [],
      users_options: [],
      clubs_options: [],
      programms_options: [],
      challenges_options: [],
      events_options: [],
      trainings_options: [],
      goal_options: [],
      blog_options: [],
      segments: [],
      isActiveShowNotSegmentedForFamilyUsers: true,
      segmentsOptions: [],
      isOldRoutInApp: false,
      send_type_options: [
        {
          id: 0,
          label: this.$t('notifications.fields.send_type.labels.by_time')
        },
        {
          id: 1,
          label: this.$t('notifications.fields.send_type.labels.by_goal')
        }
      ],
      additional_url_type_options: [
        {
          label: this.$t('notifications.fields.additional_url_types.labels.blog_blogpage'),
          value: 'app/tabs/sport/blog/blogpage/',
          main_link: 'app/tabs/sport/blog',
          need_id: true,
          options: 'blog_options'
        },
        {
          label: this.$t('notifications.fields.additional_url_types.labels.newchat'),
          value: 'app/tabs/chat/chat/',
          main_link: 'app/tabs/chat',
          need_id: false
        },
        {
          label: this.$t('notifications.fields.additional_url_types.labels.step_tab'),
          value: 'app/tabs/sport/common_goals/',
          main_link: 'app/tabs/sport/common_goals',
          need_id: true,
          options: 'goal_options'
        },
        {
          label: this.$t('notifications.fields.additional_url_types.labels.sport'),
          value: 'app/tabs/sport',
          main_link: 'app/tabs/sport',
          need_id: false
        },
        {
          label: this.$t('notifications.fields.additional_url_types.labels.sport_event'),
          value: 'app/tabs/sport/event/',
          main_link: 'app/tabs/sport/events',
          need_id: true,
          options: 'events_options'
        },
        {
          label: this.$t('notifications.fields.additional_url_types.labels.sport_workout'),
          value: 'app/tabs/sport/workout/',
          main_link: 'app/tabs/sport/workout/',
          need_id: true,
          options: 'program_and_challenge_options'
        }
      ]
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-mailings'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['notification/formError']
    },
    getDetailInfo () {
      if (this.additional_url_type.options !== undefined) {
        switch (this.additional_url_type.options) {
        case 'program_and_challenge_options':
          return Object.assign(this.programms_options, this.challenges_options).map(item => {
            item.type = 'program_and_challenge_options'
            return item
          })
        case 'events_options':
          return this.events_options.map(item => {
            item.type = 'events_options'
            return item
          })
        case 'blog_options':
          return this.blog_options.map(item => {
            item.type = 'blog_options'
            return item
          })
        case 'goal_options':
          return this.goal_options.map(item => {
            item.type = 'goal_options'
            return item
          })
        }
      }
    }
  },
  methods: {
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'notification/create' : 'notification/update'

      this.dispatch(patch, stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        let payload = {
          id: this.id,
          start_after: this.start_after,
          title: this.title,
          content: this.content,
          url: this.url,
          users_selected: this.users_selected,
          programms_selected: !this.send_all ? this.programms_selected : [],
          challenges_selected: !this.send_all ? this.challenges_selected : [],
          events_selected: !this.send_all ? this.events_selected : [],
          trainings_selected: !this.send_all ? this.trainings_selected : [],
          clubs_selected: !this.send_all ? this.clubs_selected : [],
          segments: !this.send_all ? this.segments : [],
          send_all: +this.send_all
        }

        let payload2 = {
          id: this.id,
          title: this.title,
          content: this.content,
          url: this.url,
          pedometer_challenge_id: this.goal_selected.id,
          target_count: this.target_count
        }

        payload = Object.assign(payload, this.prepareAdditionalUrl())
        payload2 = Object.assign(payload2, this.prepareAdditionalUrl())

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: (this.send_type.id === 0) ? payload : payload2,
          stayAfter: stayAfter,
          routerPush: {
            name: 'notifications'
          },
          routerPushToEdit: {
            name: 'notifications-edit'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    },
    prepareAdditionalUrl () {
      let additionalUrlType = ''
      let additionalUrlObject = ''

      if (this.additional_url_object !== '' && this.additional_url_object !== null) {
        additionalUrlType = this.additional_url_type.value ? this.additional_url_type.value : ''
        additionalUrlObject = this.additional_url_object.id ? this.additional_url_object.id : ''
      } else {
        additionalUrlType = this.additional_url_type.main_link ? this.additional_url_type.main_link : ''
      }

      const object = {
        additional_url: this.additional_url,
        additional_url_type: additionalUrlType,
        additional_url_object_id: additionalUrlObject
      }

      return object
    },
    
    onInputAdditionalUrl () { // метод отчистки селектов
      this.additional_url_type = ''
    },
    prepareAdditionalUrlType (value) {
      // Флаг, который передается в том случае, когда ссылка вводится вручную (onInputAdditionalUrl)
      if (value !== '' && value !== undefined && value !== null) {
        // Проверка на то, что приходит вещественный объект
        if ((value !== null) && (value.value !== null)) {
          const slash = '/'
          if ( // проверка, что текущий тип соответствует себе и является объектом, если нет: скидываем объект, обновляем ссылку
            value.need_id &&
            this.additional_url_object &&
            this.additional_url_object.id &&
            this.additional_url_object.type === value.options
          ) {
            this.additional_url = slash + value.value + this.additional_url_object.id
          } else {
            this.additional_url_object = ''
            this.additional_url = slash + value.main_link
          }
        } else { // если приходит не объект, значит был нажат крест в селекте
          this.additional_url = ''
          this.additional_url_object = ''
          this.additional_url_type = this.additional_url_type_default
        }
      } else { // скидываем объект при ручном вводе
        this.additional_url_object = ''
      }
    },
    prepareAdditionalUrlLink (value) {
      const slash = '/'
      if (value !== '' && value !== null && value !== undefined) {
        if (value.id !== undefined && this.additional_url_type.value !== undefined) {
          this.additional_url = slash + this.additional_url_type.value + value.id
        } else if (this.additional_url_type !== null) {
          this.additional_url = slash + this.additional_url_type.main_link
        }
      }
    }
  },
  created () {
    this.$store.dispatch('user/getList')
      .then(() => {
        this.users_options = JSON.parse(JSON.stringify(this.$store.getters['user/list']))
      })
    this.$store.dispatch('club/list')
      .then(() => {
        this.clubs_options = JSON.parse(JSON.stringify(this.$store.getters['club/list']))
      })
    this.$store.dispatch('category/getList')
      .then(() => {
        this.programms_options = JSON.parse(JSON.stringify(this.$store.getters['category/list']))
      })
    this.$store.dispatch('category/getList', {type: 'event'})
      .then(() => {
        this.events_options = JSON.parse(JSON.stringify(this.$store.getters['category/list']))
      })
    this.$store.dispatch('training/getList')
      .then(() => {
        this.trainings_options = JSON.parse(JSON.stringify(this.$store.getters['training/list']))
      })
    this.$store.dispatch('pedometer_challenge/getList')
      .then(() => {
        this.goal_options = JSON.parse(JSON.stringify(this.$store.getters['pedometer_challenge/list']))
      })
    this.$store.dispatch('blog_post/getSuite')
      .then(() => {
        this.blog_options = JSON.parse(JSON.stringify(this.$store.getters['blog_post/suite'].map(blog => {
          return {
            id: blog.id,
            label: blog.title
          }
        })))
      })

    this.$store.dispatch('segment/getSegmentsOptions').then(() => {
      this.segmentsOptions = this.$store.getters['segment/segmentsOptions']
    })

    this.$store.dispatch('content_page/getAppConfig').then(() => {
      this.isActiveShowNotSegmentedForFamilyUsers = this.$store.getters['content_page/getAppConfig'].show_not_segmented_object_for_family_users
    })

    this.id = this.$route.params.id
    this.copyId = this.$route.params.copyId
    console.log('this.copyId', this.copyId)
    if (this.$router.currentRoute.name === 'notifications-edit' || this.copyId) {
      if (this.copyId) {
        this.id = this.copyId
      } else {
        this.type = 'edit'
        this.id = this.$route.params.id
      }

      this.$store.dispatch('notification/edit', {id: this.id})
        .then(() => {
          const edit = this.$store.getters['notification/edit']
          this.title = edit.title
          this.start_after = moment(edit.startAfter).format('DD.MM.YYYY HH:mm:ss')
          this.send_all = edit.sendAll
          this.content = edit.content
          this.url = edit.url
          this.additional_url = edit.additionalUrl
          this.additional_url_type = edit.additionalUrlType
          this.additional_url_object_id = edit.additionalUrlObjectId
          this.users_selected = edit.usersSelected
          this.programms_selected = edit.programmsSelected
          this.challenges_selected = edit.challengesSelected
          this.events_selected = edit.eventsSelected
          this.trainings_selected = edit.trainingsSelected
          this.clubs_selected = edit.clubsSelected
          this.segments = edit.segments
          this.id = edit.id

          this.target_count = edit.targetCount
          if (edit.pedometersSelected.length !== 0) {
            this.goal_selected = edit.pedometersSelected
            this.send_type = {
              id: 1,
              label: this.$t('notifications.fields.send_type.labels.by_goal')
            }
          }
          if (edit.additionalUrlType) {
            this.additional_url_type = this.additional_url_type_options.find(value => value.value === edit.additionalUrlType)
            if (this.additional_url_type === undefined) {
              this.additional_url_type = this.additional_url_type_options.find(value => value.main_link === edit.additionalUrlType)
            }
          }
        })
    }
    this.loading = false
    this.$store.commit('notification/clearFormError')
  },
  watch: {
    getDetailInfo (newValue) {
      if (this.additional_url_object_id) {
        this.additional_url_object = newValue.find(value => value.id === this.additional_url_object_id)
      }
    },

    additional_url_type (value) {
      this.prepareAdditionalUrlType(value)
    },
    additional_url_object (value) {
      this.prepareAdditionalUrlLink(value)
    },
    isOldRoutInApp (newValue) {
      console.log('newValue', newValue)
      this.additional_url_type_options.map((value, key) => {
        if (value.options === 'blog_options') {
          this.additional_url_type_options[key].value = newValue ? 'app/tabs/blog/blogpage/' : 'app/tabs/sport/blog/blogpage/'
          this.additional_url_type_options[key].main_link = newValue ? 'app/tabs/blog' : 'app/tabs/sport/blog'
        }
        if (value.options === 'goal_options') {
          this.additional_url_type_options[key].value = newValue ? 'app/tabs/own-goals/step/' : 'app/tabs/own-goals/sport/common_goals/'
          this.additional_url_type_options[key].main_link = newValue ? 'app/tabs/own-goals' : 'app/tabs/sport/common_goals'
        }
      })

    }
  }
}
</script>

<style>
</style>
