import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'
import FileSaver from 'file-saver'

export default {
  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/registration_codes')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateCode ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/registration_codes/${payload.id}`, payload)
        .then((response) => {
          commit('retrieveUpdateCode', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  deleteCode ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/registration_codes/${id}`)
        .then((response) => {
          commit('retrieveDeleteCode', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  createCode ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/registration_codes', payload)
        .then((response) => {
          commit('retrieveCreateCode', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/teams/${payload.id}/edit`, { params: payload })
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  create ({ commit, state }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/teams', formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  update ({ commit }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    formData.append('_method', 'PATCH')
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/teams/${payload.id}`, formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  delete (context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/teams/${payload.id}`)
        .then((response) => {
          context.commit('removeTeamById', payload.id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  downloadExcelFileTeams () {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/teams/excel/download', {
        responseType: 'blob'
      })
        .then((response) => {
          FileSaver.saveAs(response.data, 'teams.xlsx')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadAddExcelFile (context, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    formData.append('file', payload.file)
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/registration_codes/excel', formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
