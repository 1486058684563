import axios from '@/axios.js'
import FileSaver from 'file-saver'

export default {
  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/cafeteria/orders')
        .then((response) => { 
          commit('retrieveSuite', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  download ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/cafeteria/orders/download/list', {
        params: payload,
        responseType: 'blob'
      })
        .then((response) => {
          FileSaver.saveAs(response.data, 'cafeteria-orders.xlsx')
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeOrderStatus ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch('/api/admin-panel/cafeteria/orders/statuses', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  getCatalogTree ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/cafeteria/categories/tree')
        .then((response) => { 
          commit('retrieveCatalogTree', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getProduct ({ commit }, id) {
    console.log('getProduct', id)
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/cafeteria/products/${id}/edit`)
        .then((response) => { 
          commit('retrieveProduct', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteProduct ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/cafeteria/products/${id}`)
        .then((response) => { 
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateProduct ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/cafeteria/products/${payload.id}`, payload)
        .then((response) => { 
          commit('updateProduct', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  createProduct ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/cafeteria/products', payload)
        .then((response) => { 
          commit('createProduct', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
  
}
