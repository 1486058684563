<template>
  <section class="statistics" v-show="loaded">
    <h3 class="statistics-h3">{{ $t('dashboard.titles.interests') }}</h3>
    <vx-card>
      <div class="statistic-row">
        <div class="statistic-col">
          <ul class="">
            <li class="top-item">
              <span class="top-thead">{{$t('dashboard.labels.tag')}}</span>
              <span class="top-thead text-right">{{$t('dashboard.labels.time')}}</span>
              <span class="top-thead text-right">{{$t('dashboard.titles.reports')}}</span>
            </li>
            <li class="top-item" v-for="(item, index) of periodStatisticsData" :key="item.name">
              <div class="top-item__name">
                
                <div class="color-tag" :style="{backgroundColor: colors[index]}"></div>
                <span class="top-item__text">{{ item.name }}</span>
              </div>
              <span class="top-item__stat">{{` ${Math.round(+item.time).toLocaleString()} ${$t('dashboard.diff_time.h')}` }}.</span>
              <span class="top-item__stat">{{ (+item.count).toLocaleString() }}</span>
            </li>

          </ul>
        </div>
        <div class="statistic-col flex justify-center">
          <div v-if="loaded" class="charts-wrapper">
            <vue-apex-charts ref="chart" type="donut" :options="chartOptions" :series="series"></vue-apex-charts>
          </div>
        </div>
      </div>
    </vx-card>
  </section>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import loading from '@/views/components/Loading.vue'
import VueApexCharts from 'vue-apexcharts'
// import chartConfigs from './chartConfigs.js'


export default {
  name: 'interests-statistics',
  props: ['statisticsPeriod'],
  components: {
    StatisticsCardLine,
    loading,
    VueApexCharts
  },
  data () {
    return {
      loaded: false,
      interestsData: {},
      series: [],
      labels: [],
      colors: [
        '#FF5A58',
        '#299AFF',
        '#836AF9',
        '#2CB573',
        '#FFD029',
        '#2571A3',
        '#FF9F43'
      ],
      chartOptions: {
        chart: {
          type: 'donut',
          width: 300,
          height: 300
        },
        legend: {
          show: false
        },
        dataLabels: {
          style: {
            'fontSize': '16px',
            'color': '#fff',
            'font-weight': '400'
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  show: true,
                  fontSize: '16px',
                  formatter: function (val) {
                    return `${Math.round(Number(val)).toLocaleString()} ч.`
                  }
                },
                total: {
                  show: true,
                  label: 'Все время:',
                  color: '#000',
                  formatter: function (w) {
                    return `${Math.round(w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)).toLocaleString()} ч.`
                  }
                }
              }
            }
          }
        } 
      }
    }
  },
  methods: {
  },

  watch: {
    periodStatisticsData (newVal) {
      this.series = []
      for (const [key, value] of Object.entries(newVal)) {
        this.series.push(Number(value.time))
        this.labels.push(value.name)
      }    
    }

  },

  computed: {
    periodStatisticsData () {
      const dataByPeriod = {
        [this.$t('dashboard.labels.month')]: this.interestsData.for_month,
        [this.$t('dashboard.labels.year')]: this.interestsData.for_year,
        [this.$t('dashboard.labels.all_time')]: this.interestsData.for_all_time
      }
      return dataByPeriod[this.statisticsPeriod] || this.interestsData.for_month
    }

  },

  created () {
    this.loaded = false
    this.$store.dispatch('statistics/getInterests').then(() => {
      this.interestsData = this.$store.getters['statistics/interests']
    }).then(() => {
      this.loaded = true
    })
  },
  updated () {
    setTimeout(() => {
      this.$refs.chart.updateOptions({
        labels: this.labels,
        colors: this.colors
      })
    }, 0)
  }
}
</script>

<style lang="scss" scoped>
.statistic-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  & > div {
    flex: 0 0 50%;
    min-width: 300px;
  }
}
.top-item {
  padding: 16px 24px 16px 0;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  gap: 16px;

  &:not(:last-child) {
    border-bottom: 0.5px solid #C2C2C2;
  }
  &:first-child {
    padding-top: 0;
  }

  .top-thead {
    font-size: 12px;
  }

  &:first-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    display: flex;
    align-items: center;

    color: #2C2C2C;
  }


  &__stat {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
  }
}

.color-tag {
  width: 8px;
  height: 40px;
  border-radius: 6px;
  display: inline-block;
  margin-right: 8px;
}

.charts-wrapper {
  width: 540px;
  height: 100%;
}
</style>