export default {
  areaChartOptions: {
    chart: {
      sparkline: {
        enabled: true
      }
    },
    stroke: {
      curve: 'smooth',
      width: 5
    },
    xaxis: {
      type: 'datetime',
      min: new Date().setMonth(new Date().getMonth() - 1),
      lines: {
        show: false
      },
      axisBorder: {
        show: false
      },
      labels: { show: false }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy'
        // show: false
      },
      y: {
        formatter: function (value) {
          return Math.round(value)
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.6,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    }
  },
  lineChartOptions: {
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0
      }
    },
    chart: {
      type: 'line',
      dropShadow: {
        enabled: true,
        top: 5,
        left: 0,
        blur: 4,
        opacity: 0.10
      },
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    stroke: {
      width: 5,
      curve: 'smooth'
    },
    xaxis: {
      type: 'numeric'
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        // gradientToColors: ['#A9A2F6'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
    markers: {
      size: 0,
      hover: {
        size: 5
      }
    },
    tooltip: {
      x: { show: false },
      y: {
        formatter: function (value) {
          return Math.round(value)
        }
      }
    }
  },
  countUsersOptions: {
    colors: ['#28C76F'],
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'datetime',
      min: new Date().setMonth(new Date().getMonth() - 1)
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy'
      },
      y: {
        formatter: function (value) {
          return Math.round(value)
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.6,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    }
  },
  timeSpentInAppOptions: {
    colors: ['#EA5455'],
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'datetime',
      min: new Date().setMonth(new Date().getMonth() - 1)
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy'
      },
      y: {
        formatter: function (value) {
          return Math.round(value)
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.6,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    }
  },
  countUsersLogInOptions: {
    colors: ['#7367F0', '#28C76F'],
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'datetime',
      min: new Date().setMonth(new Date().getMonth() - 1)
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy'
      },
      y: {
        formatter: function (value) {
          return Math.round(value)
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.6,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    }
  }

}
