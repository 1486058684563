import state from './moduleGamificationState.js'
import mutations from './moduleGamificationMutations.js'
import actions from './moduleGamificationActions.js'
import getters from './moduleGamificationGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
