<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-else>
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col w-full mb-4 flex justify-between">
          <div class="flex">
            <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
            <label class="ml-4 text-lg" :class="{ 'text-orange': isActive }">{{ $t('activities.fields.is_active') }}</label>
            <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active'))
            }}</span>
          </div>
          <div class="flex" v-if="isActive">
            <vs-switch v-model="isRequiredPhoto" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
            <label class="ml-4 text-lg" :class="{ 'text-orange': isRequiredPhoto }">{{ $t('activities.fields.is_required_photo') }}</label>
            <span class="text-danger text-sm" v-show="formError.has('is_required_photo')">{{ $t(formError.get('is_required_photo'))
            }}</span>
          </div>
        </div>
      </div>
      <div class="vx-card tabs-container px-6 pt-6 pb-3">
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('activities.fields.name')" v-model="name" />
            <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <label>{{ $t('activities.fields.announcement') }}</label>
            <vs-textarea class="w-full mb-2" v-model="announcement" :placeholder="$t('activities.fields.enter_a_announcement')" />
            <span class="text-danger text-sm" v-show="formError.has('announcement')">{{ $t(formError.get('announcement')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <v-select v-model="tags" multiple :placeholder="$t('categories.edit.labels.tags')" :closeOnSelect="false"
              :options="tagsOptions" :selectable="option => tags.indexOf(option) < 0" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('tags')">{{ $t(formError.get('tags')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('activities.fields.google_fit')" v-model="googleFit" />
            <span class="text-danger text-sm" v-show="formError.has('google_fit')">{{ $t(formError.get('google_fit'))
            }}</span>
          </div>

          <div class="vx-col md:w-1/2 w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('activities.fields.health_kit')" v-model="healthKit" />
            <span class="text-danger text-sm" v-show="formError.has('health_kit')">{{ $t(formError.get('health_kit'))
            }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <vs-input class="w-full mb-4" type="number" :label-placeholder="$t('activities.fields.time_points')" v-model="timePoints" />
            <span class="text-danger text-sm" v-show="formError.has('time_points')">{{ $t(formError.get('time_points'))
            }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <vs-input class="w-full mb-4" type="number" :label-placeholder="$t('activities.fields.amount_points')" v-model="amountPoints" />
            <span class="text-danger text-sm" v-show="formError.has('amount_points')">{{ $t(formError.get('amount_points'))
            }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2 mt-1">
            <vs-input class="w-full mb-4" type="number" :label-placeholder="$t('activities.fields.distance_points')"
              v-model="distancePoints" />
            <span class="text-danger text-sm" v-show="formError.has('distance_points')">{{
              $t(formError.get('distance_points')) }}</span>
          </div>
          <div v-if="distancePoints" class="vx-col md:w-1/2 w-full">
            <label class="text-xs opacity-50 block mb-1">{{ $t('activities.fields.metrics') }}</label>
            <v-select 
              class="relative"
              style="z-index: 99990"
              v-model="unitI18n" 
              :options="[unitOptions.meters, unitOptions.kilometers]"
              :searchable="false"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" 
              />
            <span class="text-danger text-sm" v-show="formError.has('metrics')">{{ $t(formError.get('metrics')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-container" style="width: 150px; height:150px; margin: 0 auto;">
              <picture-input v-if="!loading" ref="pictureInput" @prefill="onImgPrefill" @change="onImgChange"
                @error="onImgError" width="150" height="150" margin="16" :accept="this.$baseImageAcceptTypes"
                buttonClass="btn" size="10" :hideChangeButton="true" :crop="false" :prefill="imageUrl"
                :alertOnError="false" :customStrings="{
                  drag: $t('activities.fields.image'),
                }">
              </picture-input>
              <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
            </div>
          </div>
        </div>
        <div v-if="imgCrop" class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-crop-container">
              <cropper ref="cropper" classname="cropper" :src="imgCrop" :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }" @change="changeCropImg" :stencil-props="{
                  aspectRatio: 1 / 1,
                }"></cropper>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isOnlyRead" class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button 
            @click="action(stayAfter = true)" 
            :disabled="actionButtonDisabled">
            {{ $t('activities.' + type + '.buttons.' + type) }}
          </vs-button>

          <vs-button 
            @click="action()" 
            :disabled="actionButtonDisabled">
            {{ $t('activities.' + type + '.buttons.' + type) }} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '@/helpers/helpers.js'
import loading from '@/views/components/Loading.vue'

import vSelect from 'vue-select'
import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  components: {
    PictureInput,
    Cropper,
    loading,
    'v-select': vSelect
  },
  data () {
    return {
      id: 0,
      type: 'create',
      actionButtonDisabled: false,
      loading: true,
      name: '',
      announcement: '',
      isActive: true,
      isRequiredPhoto: true,
      googleFit: '',
      healthKit: '',
      timePoints: '',
      amountPoints: '',
      distancePoints: '',
      unitI18n: this.$t('activities.metrics.kilometers'),
      unitOptions: {
        meters: this.$t('activities.metrics.meters'),
        kilometers: this.$t('activities.metrics.kilometers')
      },
      tags: [],
      tagsOptions: [],
      image: null,
      imageUrl: '',
      imgCrop: null,
      imgWidth: null,
      imgHeight: null,
      isChangedImg: false,
      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error'
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-settings-activities'] === 'read' ||  
               accessUser['admin-settings'] === 'read'
      }
    },
    unit () {
      if (this.unitI18n === this.unitOptions.meters) {
        return 'meters'
      }
      return 'kilometers'
    },
    formError () {
      return this.$store.getters['activity/formError']
    }
  },
  methods: {
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true

      const patch = (this.type === 'create') ? 'activity/create' : 'activity/update'
      this.dispatch(patch, stayAfter)
    },
    dispatch (patch, stayAfter) {
      try {
        const payload = {
          id: this.id || null,
          name: this.name,
          announcement: this.announcement,
          google_fit: this.googleFit,
          health_kit: this.healthKit,
          time_points: this.timePoints,
          distance_points: this.distancePoints,
          amount_points: this.amountPoints,
          is_active: +this.isActive,
          is_required_photo: +this.isRequiredPhoto,
          tags: this.tags,
          unit: this.distancePoints !== null ? this.unit : null,
          image:
            (this.isChangedImg && this.image !== null) ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : null
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name: 'activities'
          },
          routerPushToEdit: {
            name: 'activities-edit'
          }
        }
        helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    },
    
    onImgPrefill () {
      this.imgCrop = this.$refs.pictureInput.image
      this.image = this.imgCrop
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    changeCropImg ({coordinates, canvas}) {
      if (!this.imgWidth && !this.imgHeight) {
        this.imgWidth = coordinates.width
        this.imgHeight = coordinates.height
      }
      if ((coordinates.width === this.imgWidth || coordinates.height === this.imgHeight) && this.type === 'edit' && this.imageUrl) {
        this.isChangedImg = false
      } else {
        this.isChangedImg = true
      }
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    }
  },
  created () {
    this.$store.dispatch('tag/getTagsOptions').then(() => {
      this.tagsOptions = this.$store.getters['tag/tagsOptions']
    })

    this.id = this.$route.params.id
    if (this.$router.currentRoute.name === 'activities-edit') {
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('activity/edit', { id: this.id })
        .then(() => {
          const edit = this.$store.getters['activity/edit']
          this.name = edit.name
          this.announcement = edit.announcement
          this.googleFit = edit.googleFit
          this.healthKit = edit.healthKit
          this.timePoints = edit.timePoints
          this.distancePoints = edit.distancePoints
          this.amountPoints = edit.amountPoints
          this.isActive = edit.isActive
          this.isRequiredPhoto = edit.isRequiredPhoto
          this.unitI18n = edit.unit ? this.$t(`activities.metrics.${edit.unit}`) : this.unitOptions.kilometers
          this.tags = edit.tags
          this.tags.map((tag) => {
            tag.id = tag.value
          })
          this.id = edit.id

          this.imageUrl = this.getUrl(edit.imagePath, `${this.$http.defaults.baseURL}/api/admin-panel`)

          this.loading = false
        })
    } else {
      this.loading = false
    }
    this.$store.commit('activity/clearFormError')
  }
}
</script>

<style></style>
