export default {
  suite (state) {
    return state.suite
  },
  deactivateMailings (state) {
    return state.deactivateMailings
  },
  edit (state) {
    return state.edit
  },
  get_target_types (state) {
    return state.targetTypes
  },
  get_target_type_items (state) {
    return state.targetTypeItems
  },
  users (state) {
    return state.users
  },
  formError (state) {
    return state.formError
  }
}
