import helpers from '@/helpers/helpers.js'
import moment from 'moment'
export default {
  retrieveSuite (state, suite) {
    state.suite = suite
  },

  updateOrderStatus (state, { orderId, newStatus }) {
    const order = state.suite.data.find(order => order.id === orderId)
    if (order) {
      order.status = newStatus
    }
  },

  retrieveCatalogTree (state, tree) {
    state.catalogTree = tree
  },

  retrieveProduct (state, product) {
    state.product = product.data
  },

  updateProduct (state, product) {
    state.product = product
  },

  createProduct (state, product) {
    state.product = product
  },
  
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  }

}
