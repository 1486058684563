import axios from '@/axios.js'
import FileSaver from 'file-saver'

export default {
  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/common_goals')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/common_goals/${payload.id}/edit`)
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  copy ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/common_goals/${payload.id}/copy`)
        .then((response) => {
          commit('copyChallenge', [payload.index, response.data.data])
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  destroy ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/common_goals/${payload.id}`)
        .then((response) => {
          commit('removeChallenge', payload.index)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  create ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/common_goals/metrics')
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/common_goals', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/common_goals/${payload.id}`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  downloadLeaderboard ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/common_goals/${payload.id}/download/leaderboard`, {
        responseType: 'blob'
      })
        .then((response) => {
          FileSaver.saveAs(response.data, 'common_goals.xlsx')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  downloadTeamLeaderboard ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/common_goals/${payload.id}/download/team`, {
        responseType: 'blob'
      })
        .then((response) => {
          FileSaver.saveAs(response.data, 'common_goals_team.xlsx')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/common_goals/list')
        .then((response) => {
          commit('retrieveList', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
