import helpers from '@/helpers/helpers.js'

export default {
  retrieveSuite (state, suite) {
    state.suite = suite
  },
  retrieveList (state, list) {
    state.list = list
  },
  retrieveServiceList (state, listByTypes) {
    state.serviceList = listByTypes
  },
  retrieveEdit (state, data) {
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) continue
      state.edit[helpers.kebabCaseToCamelCase(prop)] = (data[prop] === null) ? '' : data[prop]
    }
  },
  removeEvent (state, index) {
    state.list.splice(index, 1)
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  retrieveMembers (state, members) {
    state.members = members
  },
  retrieveMembersTrainingsCount (state, count) {
    state.membersTrainingsCount = count
  },
  retrieveStatistics (state, data) {
    state.statistics = {}
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) continue
      state.statistics[helpers.kebabCaseToCamelCase(prop)] = data[prop]
    }
  },

  setAnswerCorrectness (state, { answer, isCorrect }) {
    answer.is_correct = isCorrect
  },

  clearFormError (state) {
    state.formError.clear()
  }
}
