export default {

  // live

  allStatisticsLive (state) {
    return state.live
  },

  countCompletedTrainings (state) {
    return state.live.countCompletedTrainings
  },
  topLikes (state) {
    return state.live.topLikes
  },
  countMessages (state) {
    return state.live.countMessages
  },
  countPosts (state) {
    return state.live.countPosts
  },
  countReceivedPoints (state) {
    return state.live.countReceivedPoints
  },
  countRegistrations (state) {
    return state.live.countRegistrations
  },
  countFamilyUsers (state) {
    return state.live.countFamilyUsers
  },
  usersActivity (state) {
    return state.usersActivity
  },

  // general

  allStatisticsGeneral (state) {
    return state.general
  },

  countUsers (state) {
    return state.general.countUsers
  },
  countUsersLogIn (state) {
    return state.general.countUsersLogIn
  },

  uniqueCountUsersLogIn (state) {
    return state.general.countUniqueUsersLogIn
  },
  timeSpentInApp (state) {
    return state.general.timeSpentInApp
  },
  interests (state) {
    return state.general.interests
  },

  // events
  
  pastEvents (state) {
    return state.events.pastEvents
  },
  usersParticipatedInEvents (state) {
    return state.events.usersParticipatedInEvents
  },
  reports (state) {
    return state.events.reports
  },
  topEvents (state) {
    return state.events.topEvents
  },
  topLikesForReports (state) {
    return state.events.topLikesForReports
  },

  // shop

  shopOrders (state) {
    return state.shop.shopOrders
  },
  shopOrdersFromWarehouse (state) {
    return state.shop.shopOrdersFromWarehouse
  },
  shopOrderedDigital (state) {
    return state.shop.shopOrderedDigital
  },
  topProducts (state) {
    return state.shop.topProducts
  },
  stockProducts (state) {
    return state.shop.stockProducts
  },

  // coursesAndPrograms

  programs (state) {
    return state.coursesAndPrograms.programs
  },
  challenges (state) {
    return state.coursesAndPrograms.challenges
  },
  completedTrainings (state) {
    return state.coursesAndPrograms.completedTrainings
  },
  topActiveUsers (state) {
    return state.coursesAndPrograms.topActiveUsers
  },
  topSociableUsers (state) {
    return state.coursesAndPrograms.topSociableUsers
  },
  topActiveTeams (state) {
    return state.coursesAndPrograms.topActiveTeams
  },
  topCoursesAndChallenges (state) {
    return state.coursesAndPrograms.topCoursesAndChallenges
  },
  topReports (state) {
    return state.coursesAndPrograms.topReports
  },

  // communications
  
  communicationsEmail (state) {
    return state.communications.email
  },
  communicationsMessage (state) {
    return state.communications.message
  },
  communicationsPush (state) {
    return state.communications.push
  },
  communicationsPosts (state) {
    return state.communications.posts
  },
  communicationsLikes (state) {
    return state.communications.likes
  }

}
