export default {
  suite (state) {
    return state.suite
  },
  edit (state) {
    return state.edit
  },
  list (state) {
    return state.list
  },
  formError (state) {
    return state.formError
  },
  getActiveClubId (state) {
    return state.isActiveClubId
  }
}
