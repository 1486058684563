<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full my-32">
        <loading v-if="loading"></loading>
      </div>
    </div>

    <div v-show="!loading">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{
      $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col flex w-1/4 mb-4 items-center">
          <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"></vs-switch>
          <label class="ml-4 text-lg" :class="{ 'text-orange': isActive }">{{ $t('categories.create.labels.is_active')
            }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active'))
            }}</span>
        </div>
        <div class="vx-col flex w-3/4 mb-6 justify-end">
          <label class="mx-4 text-grey font-bold" :class="[!isPickable ? ' text-orange' : '']">{{
      $t('habits_trackers.suite.title.is_pickable.false')
    }}</label>
          <vs-switch v-model="isPickable" class="is-pickable-switch" icon-pack="feather"
            vs-icon="icon-check"></vs-switch>
          <label class="ml-4 text-grey font-bold" :class="[isPickable ? ' text-orange' : '']">{{
      $t('habits_trackers.suite.title.is_pickable.true')
    }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active'))
            }}</span>
        </div>
      </div>

      <content-filter :targetId="id" :targetModel="'mission'"></content-filter>

      <vx-card v-if="isPickable" class="mb-6" :title="$t('habits_trackers.suite.title.accessibility_settings')">
        <div class="vx-row">
          <div class="vx-col w-full flex items-center gap-4">
            <label>{{ $t('habits_trackers.suite.title.days_to_complete') }}</label>
            <vs-input-number v-model="givenDays" color="#1f74ff" />
            <span class="text-danger text-sm pickatime" v-show="formError.has('given_days')">{{
      $t(formError.get('given_days')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2 mt-4">
            <label>{{ $t('habits_trackers.suite.title.available_after') }}</label>
            <v-select v-model="availableAfter" label="name" :options="availableAfterOptions"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('available_after')">{{
      $t(formError.get('available_after')) }}</span>
          </div>
        </div>

      </vx-card>

      <!-- Описание  -->

      <vx-card class="mb-6" :title="$t('habits_trackers.suite.title.description')">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('habits_trackers.suite.title.habits_tracker_name') }}*</label>
            <vs-input class="w-full" v-model="name" :placeholder="$t('habits_trackers.suite.title.enter_a_name')" />
            <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <label>{{ $t('habits_trackers.suite.title.habits_tracker_announcement') }}</label>
            <vs-textarea class="w-full mb-2" v-model="announcement"
              :placeholder="$t('habits_trackers.suite.title.enter_a_announcement')" />
            <span class="text-danger text-sm" v-show="formError.has('description')">{{ $t(formError.get('description'))
              }}</span>
          </div>
        </div>
        <div class="vx-row" v-if="loaded">
          <div class="vx-col w-full mb-2">
            <vx-card no-shadow card-border :title="$t('categories.create.labels.block_description')">
              <editor ref="editor" :config="editorJsConfig" />
              <span class="text-danger text-sm" v-show="formError.has('blockDescription')">{{
      $t(formError.get('blockDescription'))
    }}</span>
            </vx-card>
          </div>
        </div>
      </vx-card>

      <vx-card class="mb-6" :title="$t('habits_trackers.suite.title.settings')">
        <div class="vx-row">

          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('habits_trackers.suite.title.filters') }}</label>
            <v-select v-model="filters" multiple taggable :closeOnSelect="false" :options="filtersOptions"
              :selectable="option => filters.indexOf(option) < 0" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('filters')">{{ $t(formError.get('filters'))
              }}</span>
          </div>

          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('habits_trackers.suite.title.tags') }}</label>
            <v-select v-model="tags" multiple :closeOnSelect="false" :options="tagsOptions"
              :selectable="option => tags.indexOf(option) < 0" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('tags')">{{ $t(formError.get('tags')) }}</span>
          </div>

          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('segments.suite.title') }}</label>
            <v-select v-model="segments" multiple :placeholder="$t('segments.suite.title')" :closeOnSelect="false"
              :options="segmentsOptions" :selectable="option => segments.indexOf(option) < 0"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('segments')">{{ $t(formError.get('segments'))
              }}</span>
          </div>

          <div class="vx-col md:w-1/2 w-full mb-4">
            <label>{{ `${$t('habits_trackers.suite.title.quantity')} ${$t('habits_trackers.suite.title.to_complete')}*`
              }}</label>
            <vs-input class="w-full" v-model="completePoint" />
            <span class="text-danger text-sm" v-show="formError.has('points')">{{ $t(formError.get('points')) }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-4">
            <label>{{ $t('habits_trackers.suite.title.points_on_completion') }}*</label>
            <vs-input class="w-full" v-model="executionPoint" />
            <span class="text-danger text-sm" v-show="formError.has('points_for_completion')">{{
      $t(formError.get('points_for_completion'))
    }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-4">
            <label>{{ $t('habits_trackers.suite.title.coins_for_completion') }}</label>
            <vs-input class="w-full" v-model="executionCoin" />
            <span class="text-danger text-sm" v-show="formError.has('coins_for_completion')">{{
      $t(formError.get('coins_for_completion'))
    }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('habits_trackers.suite.title.click_points') }}*</label>
            <vs-input class="w-full" v-model="optionals.click_points" />
            <span class="text-danger text-sm" v-show="formError.has('optionals')">{{
      $t(formError.get('optionals'))
    }}</span>
          </div>
        </div>
      </vx-card>
      <vx-card :title="$t('habits_trackers.suite.title.image')">
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <p>{{ $t('habits_trackers.suite.title.achievement') }}*</p>
            <div class="image-container" style="width: 150px; height: 150px; margin: 0 auto">
              <picture-input v-if="!loading" ref="iconInput" @prefill="onIconPrefill" @change="onIconChange"
                @error="onIconError" width="150" height="150" margin="16" :accept="this.$baseImageAcceptTypes"
                buttonClass="btn" size="10" :hideChangeButton="true" :crop="false" :prefill="iconUrl"
                :alertOnError="false" :customStrings="{
      drag: $t('habits_trackers.suite.title.upload_an_icon'),
    }">
              </picture-input>
              <span class="text-danger text-sm" v-show="formError.has('icon')">{{ $t(formError.get('icon')) }}</span>
            </div>
          </div>

          <div v-if="iconCrop" class="vx-col w-full mb-2">
            <div class="image-crop-container ">
              <cropper ref="cropperIcon" classname="cropper" :src="iconCrop" :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }" @change="changeCropIcon" :stencil-props="{
      aspectRatio: 1 / 1
    }"></cropper>
            </div>
          </div>
          <div class="vx-col w-full mb-2">
            <p>{{ $t('habits_trackers.suite.title.habits_tracker_cover') }}*</p>
            <div class="image-container" style="width: 150px; height: 150px; margin: 0 auto">
              <picture-input v-if="!loading" ref="pictureInput" @prefill="onImgPrefill" @change="onImgChange"
                @error="onImgError" width="150" height="150" margin="16" :accept="this.$baseImageAcceptTypes"
                buttonClass="btn" size="10" :hideChangeButton="true" :crop="false" :prefill="imageUrl"
                :alertOnError="false" :customStrings="{
      drag: $t('habits_trackers.suite.title.upload_an_img'),
    }">
              </picture-input>
              <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
            </div>
          </div>
        </div>
        <div v-if="imgCrop" class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-crop-container">
              <cropper ref="cropper" classname="cropper" :src="imgCrop" :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }" @change="changeCropImg" :stencil-props="{
      aspectRatio: 3 / 2
    }"></cropper>
            </div>
          </div>
        </div>
      </vx-card>
      <div class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button v-if="type === 'edit'" class="bg-danger text-white border-solid border-2 border-grey"
            @click="deletePopupActive = true" :disabled="actionButtonDisabled || isOnlyRead">
            {{ $t('habits_trackers.suite.buttons.delete') }}
          </vs-button>
          <vs-button @click="action(stayAfter = true)" :disabled="actionButtonDisabled || isOnlyRead">
            {{ $t('habits_trackers.suite.buttons.save') }}
          </vs-button>
          <vs-button @click="action()" :disabled="actionButtonDisabled || isOnlyRead">
            {{ $t('habits_trackers.suite.buttons.save') }} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
    </div>

    <vs-popup class="holamundo" :title="`${$t('habits_trackers.suite.popup.delete_habits_tracker')}: ${name} ?`"
      :active.sync="deletePopupActive">
      <div class="delete-confirm-popup-buttons flex justify-between" v-if="!showDeletePopupWaitMessage">
        <vs-button color="primary" type="filled" @click="deleteMission()">{{
      $t('achievements.suite.popups.delete.buttons.confirm')
    }}</vs-button>
        <vs-button color="warning" type="filled" @click="deletePopupActive = false">{{
      $t('achievements.suite.popups.delete.buttons.cancel')
    }}</vs-button>
      </div>
      <p v-if="showDeletePopupWaitMessage" class="delete-popup-wait-message">
        {{ $t('achievements.suite.popups.delete.wait_message') }}
        <font-awesome-icon icon="spinner" spin />
      </p>
    </vs-popup>

    <vs-popup class="holamundo" title="" :active.sync="ErrorParsePopupActive">
      <p>{{ $t('habits_trackers.edit.error_parse.content') }}</p>
      <div class="error-parse-button-confirm">
        <vs-button color="warning" type="filled" @click="ErrorParsePopupActive=false">{{
          $t('habits_trackers.edit.error_parse.button_confirm') }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import loading from '@/views/components/Loading.vue'
import helpers from '@/helpers/helpers.js'

import editorJsConfig from '@/editorjs/config.js'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import locale from 'flatpickr/dist/l10n/ru'
import vSelect from 'vue-select'
import ContentFilter from '@/views/components/contentFilter.vue'
import moment from 'moment'

export default {
  components: {
    PictureInput,
    quillEditor,
    Cropper,
    loading,
    flatPickr,
    'v-select': vSelect,
    ContentFilter
  },

  data () {
    return {
      ErrorParsePopupActive: false,
      deletePopupActive: false,
      showDeletePopupWaitMessage: false,
      id: 0,
      type: 'create',
      name: '',
      announcement: '',
      activitiesOptions: [],
      targetsActivities: [],
      targetsChallengeAndProgramms: null,
      filtersOptions: [],
      tagsOptions: [],
      tags: [],
      filters: [],
      segmentsOptions: [],
      segments: [],
      achievementOptions: [],
      achievement: [],
      availableAfter: null,
      availableAfterOptions: [],
      completePoint: null,
      executionPoint: null,
      executionCoin: null,
      loading: true,
      isActive: true,
      isPickable: true,
      image: null,
      icon: null,
      iconUrl: '',
      imageUrl: '',
      imgCrop: null,
      iconCrop: null,
      imgWidth: null,
      imgHeight: null,
      iconWidth: null,
      iconHeight: null,
      isChangedIcon: false,
      isChangedImg: false,
      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error',
      description: '',
      blockDescription: null,
      editorJsConfig: Object.assign({}, editorJsConfig),
      editorOption: {
        formats: ['bold', 'header', 'italic', 'link', 'list', 'blockquote', 'image', 'indent'],
        modules: {
          toolbar: '#toolbar'
        }
      },
      loaded: false,
      actionButtonDisabled: false,

      givenDays: 30,
      publicationAt: moment().format('YYYY-MM-DD HH:mm'),
      startedAt: null,
      endedAt: null,
      configDateStarted: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        defaultHour: 0,
        defaultMinute: 0,
        time_24hr: true,
        minDate: null,
        maxDate: null,
        locale: locale[this.$i18n.locale]
      },
      configDateEnded: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        defaultHour: 23,
        defaultMinute: 59,
        time_24hr: true,
        minDate: null,
        maxDate: null,
        locale: locale[this.$i18n.locale]
      },
      optionals: {
        click_points: 0
      }
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-missions'] === 'read'
      }
    },

    formError () {
      return this.$store.getters['mission/formError']
    }
  },

  methods: {
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'mission/store' : 'mission/update'
      this.dispatch(patch, stayAfter)
    },
    async dispatch (patch, stayAfter) {
      try {
        const blockDescription = await this.$refs.editor.state.editor.save()
        const payload = {
          id: this.id,
          class_name: 'habits_tracker',
          metrics: 'amount',
          name: this.name,
          description: this.announcement,
          block_description: JSON.stringify(blockDescription),
          segments: this.segments,
          filters: this.filtersOptions.length ? this.filters : this.filters.map(filters => ({ label: filters })),
          tags: this.tags,
          points: this.completePoint || null,
          points_for_completion: this.executionPoint || 0,
          coins_for_completion: this.executionCoin || 0,
          is_active: +this.isActive,
          is_pickable: +this.isPickable,
          is_dateable_by_days: true,
          given_days: +this.givenDays,
          icon:
            (this.isChangedIcon && this.icon !== null) ? helpers.resizeCanvasImage(this.$refs.cropperIcon.getResult().canvas, 800).toDataURL(this.icon.type, 1.0) : null,

          available_after: this.availableAfter ? { id: this.availableAfter.id } : null,
          optionals: this.optionals,
          image:
            (this.isChangedImg && this.image !== null) ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : null
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name: 'habits-trackers'
          },
          routerPushToEdit: {
            name: 'habits-tracker-edit'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    },
    deleteMission () {
      this.showDeletePopupWaitMessage = true
      this.$store
        .dispatch('mission/delete', { id: this.id })
        .catch((error) => {
          this.$vs.notify({
            title: 'Server error',
            text: error,
            color: 'danger'
          })
        })
        .then(() => {
          this.deletePopupActive = false
          this.showDeletePopupWaitMessage = false
        })
        .then(() => {
          this.actionButtonDisabled = false
          this.$router.push({ name: 'habits-trackers' })
        })
    },

    onImgPrefill () {
      this.imgCrop = this.$refs.pictureInput.image
      this.image = this.imgCrop
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
      this.isChangedImg = true
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },

    onIconPrefill () {
      this.iconCrop = this.$refs.iconInput.image
      this.icon = this.iconCrop
    },

    onIconChange (img) {
      this.icon = this.$refs.iconInput.file
      this.iconCrop = img
      this.isChangedIcon = true
    },
    onIconError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },

    changeCropIcon ({ coordinates, canvas }) {
      if (!this.iconWidth && !this.iconHeight) {
        this.iconWidth = coordinates.width
        this.iconHeight = coordinates.height
      }
      if ((coordinates.width === this.iconWidth || coordinates.height === this.iconHeight) && this.type === 'edit' && this.iconUrl) {
        return this.isChangedIcon
      } else {
        this.isChangedIcon = true
      }
    },

    changeCropImg ({ coordinates, canvas }) {
      if (!this.imgWidth && !this.imgHeight) {
        this.imgWidth = coordinates.width
        this.imgHeight = coordinates.height
      }
      if ((coordinates.width === this.imgWidth || coordinates.height === this.imgHeight) && this.type === 'edit' && this.imageUrl) {
        return this.isChangedIcon
      } else {
        this.isChangedImg = true
      }
    },
    async loadTracker () {
      this.id = this.$route.params.id
      this.type = 'edit'
      await this.$store.dispatch('mission/edit', { id: this.id }).then(() => {
        const edit = JSON.parse(JSON.stringify(this.$store.getters['mission/edit']))
        this.blockDescription = edit.blockDescription
        if (this.blockDescription !== '') {
          try {
            this.editorJsConfig.data = JSON.parse(edit.blockDescription)
          } catch (e) {
            this.ErrorParsePopupActive = true
            this.editorJsConfig.data = ''
          }
        }
        this.name = edit.name
        this.announcement = edit.description
        this.segments = edit.segments
        this.tags = edit.tags
        this.tags.map((tag) => {
          tag.id = tag.value
        })
        this.filters = edit.filters
        this.filters.map((filter) => {
          filter.label = filter.title
        })
        this.availableAfter = edit.availableAfter

        this.completePoint = edit.points
        this.executionPoint = edit.pointsForCompletion
        this.executionCoin = edit.coinsForCompletion
        this.isActive = edit.isActive
        this.isPickable = edit.isPickable
        this.publicationAt = edit.publicationAt
        this.startedAt = edit.startedAt
        this.endedAt = edit.endedAt
        this.givenDays = edit.givenDays ? edit.givenDays : this.givenDays
        this.optionals = edit.optionals

        this.iconUrl = this.getUrl(edit.icon, `${this.$http.defaults.baseURL}/api/admin-panel`)
        this.imageUrl = this.getUrl(edit.imagePath, `${this.$http.defaults.baseURL}/api/admin-panel`)

        this.loading = false
        this.loaded = true
      })
    }

  },

  created () {
    if (this.$router.currentRoute.name === 'habits-tracker-create') {
      this.loading = false
      this.loaded = true
    }
    if (this.$router.currentRoute.name === 'habits-tracker-edit') {
      this.loadTracker()
    }

    this.$store.dispatch('mission/getSuite').then(() => {
      const data = JSON.parse(JSON.stringify(this.$store.getters['mission/suite']))
      const index = data.findIndex(item => +item.id === +this.id)
      if (index !== -1) {
        data.splice(index, 1)
      }
      this.availableAfterOptions = [...this.availableAfterOptions, ...data]
    })

    this.$store.dispatch('mission/getTrackers').then(() => {
      const data = JSON.parse(JSON.stringify(this.$store.getters['mission/trackers']))
      const index = data.findIndex(item => +item.id === +this.id)
      if (index !== -1) {
        data.splice(index, 1)
      }
      this.availableAfterOptions = [...this.availableAfterOptions, ...data]
    })

    this.$store.dispatch('mission/getFiltersOptions').then(() => {
      this.filtersOptions = JSON.parse(JSON.stringify(this.$store.getters['mission/filtersOptions']))
      this.filtersOptions.map((filter, index) => {
        if (filter.title === 'Я участвую') {
          this.filtersOptions.splice(index, 1)
        }
      })
    })

    this.$store.dispatch('tag/getTagsOptions').then(() => {
      this.tagsOptions = this.$store.getters['tag/tagsOptions']
    })

    this.$store.dispatch('segment/getSegmentsOptions').then(() => {
      this.segmentsOptions = this.$store.getters['segment/segmentsOptions']
    })


    this.$store.commit('mission/clearFormError')
  }
}

</script>

<style lang="scss" scoped>
.codex-editor__redactor {
  padding-bottom: 30px !important;
}

.is-pickable-switch {
  &.vs-switch-primary {
    background: rgba(var(--vs-primary), 1);
  }

  .vs-switch--text.text-off {
    color: #fff !important;
  }
}

.radio-btn:deep(.vs-radio) {
  &::before {
    content: '';
    position: absolute;
    width: 45%;
    height: 45%;
    background: #fff;
    z-index: 1;
    border-radius: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.error-parse-button-confirm {
  margin-top: 20px;
}

::deep .vs__dropdown-option--disabled {
  display: none !important;
}
</style>
