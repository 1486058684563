<template>
  <div class="h-full">
    <vs-navbar type="gradient" v-model="activeItem" class="nabarx category-tabs-wrapper">
      <vs-navbar-item index="edit">
        <a href="#">{{ $t('categories.edit.tabs.edit') }}</a>
      </vs-navbar-item>

      <vs-navbar-item index="members">
        <a href="#">{{ $t('categories.edit.tabs.members') }}</a>
      </vs-navbar-item>

      <vs-navbar-item index="statistics">
        <a href="#">{{ $t('categories.edit.tabs.statistics') }}</a>
      </vs-navbar-item>
    </vs-navbar>

    <edit-tab v-show="activeItem==='edit'"></edit-tab>
    <members-tab v-show="activeItem==='members'" :is-active="activeItem==='members'"></members-tab>
    <statistics-tab v-show="activeItem==='statistics'" :is-active="activeItem==='statistics'"></statistics-tab>

  </div>
</template>

<script>
import EditTab from '../components/category/EditTab.vue'
import MembersTab from '../components/category/MembersTab.vue'
import StatisticsTab from '../components/category/StatisticsTab.vue'

export default {
  components: {
    EditTab,
    MembersTab,
    StatisticsTab
  },
  data () {
    return {
      activeItem: 'edit'
    }
  }
}
</script>

<style lang="scss">
  .category-tabs-wrapper {
    margin-bottom: 30px;
  }
</style>

