<template>
  <div>
    <span
      v-for="(product, index) in this.params.data.products"
      :key="index"
    >
      <template v-if="index">, </template>{{ product['product']['name'] }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'Products'
}
</script>
