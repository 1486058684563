<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <div class="vx-col w-full search-reports mb-5">
        <div class="vx-row mb-5 justify-between">
          <div class="vx-col sm:w-1/2 md:w-1/3 w-full">
            <vs-input
              type="text"
              class="search-input w-full"
              v-model="search"
              :label-placeholder="$t('reports.suite.search')"
              icon="search"
              icon-no-border
              @keydown.enter="applyFilter()"
            />
            <span class="text-danger text-sm" v-show="formError.has('search')">{{ $t(formError.get('search')) }}</span>
          </div>
          <div class="filter-settings vx-col sm:w-1/2 md:w-2/3 w-full text-right self-end">
            <vs-button type="line" class="mr-4" text-color="#7367F0" @click="clearFilter()">{{
              $t('reports.suite.reset_filters')
            }}</vs-button>
            <vs-button type="line" text-color="#7367F0" @click="showSortOptions = !showSortOptions">{{
              showSortOptions ? $t('reports.suite.hide_filters') : $t('reports.suite.show_filters')
            }}</vs-button>
          </div>
        </div>
        <transition name="fade">
          <div v-show="showSortOptions" class="vx-row mb-4 justify-between gap-y-4">
            <div class="vx-col sm:w-1/2 md:w-1/3 w-full">
                <span class="block mb-2">{{ $t('reports.suite.status') }}</span>
                <v-select
                  class="search-input w-full"
                  :options="$store.state.reports.statuses"
                  :placeholder="$t('reports.suite.all')"
                  v-model="selectStatus"
                  />
            </div>
            <div class="vx-col sm:w-1/2 md:w-1/3 w-full">
              <span class="block mb-2">{{ $t('reports.table.user') }}</span>
              <v-select
                :options="$store.state.user.list"
                class=" w-full"
                :placeholder="$t('reports.suite.all')"
                multiple
                :closeOnSelect="false"
                v-model="selectUser"
                :selectable="option => selectUser.indexOf(option) < 0"
              />
            </div>
            <div class="vx-col sm:w-1/2 md:w-1/3 w-full">
              <span class="block mb-2">{{ $t('reports.table.name') }}</span>
              <v-select
                class="search-input w-full"
                :placeholder="$t('reports.suite.all')"
                label="name"
                multiple
                v-model="selectName"
                :options="$store.state.reports.titles"
                :closeOnSelect="false"
                :selectable="option => selectName.indexOf(option) < 0"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
            </div>
  
            <div class="vx-col sm:w-1/2 md:w-1/3 w-full">
              <p class="mb-1 ml-1 text-sm">{{ $t('reports.suite.dates') }}</p>
              <div class="flex gap-4">
                <flat-pickr class="w-full" :config="configDate" v-model="dateFrom"
                    :placeholder="$t('reports.suite.date_from')"/>
                <flat-pickr class="w-full" :config="configDate" v-model="dateTo"
                    :placeholder="$t('reports.suite.date_to')"/>
              </div>
            </div>
            <div class="vx-col md:w-1/2 w-full text-right self-end">
              <vs-button class="search-btn" @click="applyFilter()">{{
                $t('reports.suite.apply')
              }}</vs-button>
            </div>
          </div>
        </transition>
      </div>

      <loading v-if="loading"></loading>
      <div class="vx-col w-full mb-base" v-if="!showSortTable && !loading">
        <div class="vx-card p-6 ag-grid-wrapper">
          <!-- AgGrid Table -->
          <ag-grid-vue
            @gridReady="onGridReady"
            :gridOptions="gridOptions"
            @rowClicked="onRowClicked"
            class="ag-theme-material w-full h-full my-4"      
            style="height: 600px;"
            :rowHeight="64"
            :getRowStyle="getRowStyle"
            :columnDefs="columnDefs"
            :overlayNoRowsTemplate="overlayNoRowsTemplate"
            :defaultColDef="defaultColDef"
            colResizeDefault="shift"
            :animateRows="true"
            :enableRtl="$vs.rtl"
            :rowBuffer="rowBuffer"
            :rowSelection="rowSelection"
            :rowModelType="rowModelType"
            :cacheBlockSize="cacheBlockSize"
            :cacheOverflowSize="cacheOverflowSize"
            :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
            :infiniteInitialRowCount="infiniteInitialRowCount"
            :maxBlocksInCache="maxBlocksInCache"
            @sortChanged="onSortChanged"
          />
        </div>

        <vs-popup class="report-popup" :active.sync="showReportPopup" title="Отчет">
          <div class="popup-report" v-if="reportData.user">
            <div class="popup-report__user user-block flex items-center gap-4 mb-5">
              <div class="user-block__avatar">
                <img
                  class="rounded-full overflow-hidden"
                  :src="
                    reportData.user.avatar_url_168
                      ? getUrl(reportData.user.avatar_url_168)
                      : require('@/assets/images/user/avatar/default.svg')
                  "
                />
              </div>
              <div class="user-call flex justify-between w-full">
                <div class="flex-0">
                  <h4>{{ reportData.user.name }}</h4>
                  <p class="sub-text">ID: {{ reportData.user.id }} {{ reportData.user.email_validate }}</p> 
                </div>
                <div class="flex-0">
                  <vs-button
                  v-if="!reportData.moderation_like && reportData.moderation_status !== 'rejected'"
                  text-color="#DA7E3C"
                  class="btn self-center"
                  @click="toggleLike( reportData.id )"
                  >поставить лайк</vs-button
                >
                <vs-button
                  v-if="reportData.moderation_like"
                  text-color="#DA7E3C"
                  class="btn"
                  @click="toggleLike( reportData.id )"
                  >отменить лайк</vs-button
                >
                </div>
            </div>
            </div>
            <h4 class="mb-5">{{ reportData.unified_title }}</h4>
            <div class="popup-report__content content flex">
              <div class="content__text min-w-3/5 flex-1">
                <div v-if="reportInPopup">
                  <div class="report-item mb-2" v-for="report in reportInPopup" :key="report.id">
                    <p class="report-item__title">{{ report.name }}</p>

                    <div v-if="Array.isArray(report.value)">
                      <ol class="report-item__value">
                        <li v-for="(item, index) in report.value" :key="index">{{ item.answer }}</li>
                      </ol>
                    </div>

                    <div v-else-if="report && report.result_type === 'answer_by_scale'" class="custom-input">
                        <label class="custom-slider">
                          <vs-slider color="#DA7E3C" ticks :value="report.value" disabled />
                        </label>
                    </div>

                    <div v-else>
                      <div class="report-item__value">{{ report.value }}</div>
                    </div>
                  </div>
                </div>
                <div class="report-item__value">{{ reportData.comment }}</div>
              </div>
                 <div v-if="bigImages.length > 0" class="content__image w-2/5">
                    <swiper-container :pagination="{ clickable: true }" class="h-full">
                        <swiper-slide v-for="(img, index) in bigImages" :key="index">
                        <img
                          class=""
                          v-img="{src: getUrl(bigImages[index])}" 
                          :src="getUrl(thumbImages[index])"
                        />
                        </swiper-slide>
                    </swiper-container>
                 </div>
            </div>
            <div class="separator"></div>
            <div class="popup-report__info-item info-item mb-5">
              <p class="info-item__title font-bold">{{ $t('reports.popup.points') }}:</p>
                <p v-for="(point, index) in reportData.points" :key="index" class="info-item__value mt-0 mb-1 flex items-center gap-2">
                  <span class="text-sm">{{ point.type_title }}: </span>
                  <span class="font-bold flex items-center gap-1">
                    <svg  class="total-count__icon" width="9" height="11" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.80496 10.9156C1.25629 10.2897 0.707638 9.63878 0.358471 8.86266C0.00932714 8.03648 -0.0904382 7.08513 0.0841452 6.18384C0.258729 5.28255 0.657743 4.43133 1.23136 3.7053C1.18149 4.3312 1.40595 4.98213 1.82991 5.45781C1.58051 4.2561 1.97955 2.95423 2.7776 2.00288C3.57565 1.05153 4.673 0.4256 5.8202 0C5.2466 0.600858 5.17178 1.55221 5.39623 2.35337C5.62069 3.15451 6.09454 3.85552 6.51851 4.58154C6.94247 5.30757 7.34151 6.05865 7.36645 6.88483C7.59091 6.45923 7.84031 6.0336 7.98995 5.55794C8.13958 5.08225 8.2144 4.58155 8.08971 4.10586C8.53862 4.65665 8.78802 5.20742 8.91271 5.90843C9.0374 6.60944 9.01248 7.33547 8.93766 8.03648C8.83789 8.8126 8.63838 9.61374 8.21442 10.2647C7.71564 11.0408 6.91757 11.5915 6.0447 11.892C4.49842 12.1674 2.97711 11.9421 1.80496 10.9156Z" fill="#F74D30"/>
                      <path d="M3.70035 11.8423C5.74536 12.6435 7.76545 10.215 6.64318 8.3373C6.64318 8.31225 6.61823 8.31225 6.61823 8.28723C6.718 9.31369 6.46859 10.0648 5.99474 10.4904C6.24414 9.88953 6.06956 9.18852 5.77028 8.58766C5.47101 8.01185 5.04705 7.48609 4.72283 6.9353C4.39861 6.35949 4.14923 5.70854 4.29887 5.05762C3.70032 5.50826 3.27636 6.18423 3.10178 6.91026C2.92719 7.63629 3.02696 8.43745 3.35118 9.11341C2.97709 8.83803 2.70276 8.38738 2.67781 7.91169C2.27878 8.38738 2.0294 9.01326 2.05432 9.63916C2.05435 10.6156 2.82745 11.4918 3.70035 11.8423Z" fill="#FFD15C"/>
                    </svg>
                    <span style="line-height: 1;">{{ point.point || 0 }} </span>
                  </span>
                  <a v-if="reportData.points" @click="goToEditPoint(point)" class="info-item__edit flex items-center mb-1">
                    <feather-icon icon="EditIcon" svgClasses="w-4 h-4"></feather-icon>
                  </a>
                </p>
              </div>
            <div class="popup-report__info grid grid-cols-3 justify-center gap-4">
              <div class="popup-report__info-item info-item">
                <p class="info-item__title">{{ $t('reports.popup.likes') }}</p>
                <p class="info-item__value">{{ reportData.like_count || 0 }}</p>
              </div>
              <div class="popup-report__info-item info-item ml-8">
                <p class="info-item__title"></p>
                <p class="info-item__value"></p>
              </div>
              <div class="popup-report__info-item info-item ml-12">
                <p class="info-item__title"></p>
                <p class="info-item__value"></p>
              </div>
              <div class="popup-report__info-item info-item">
                <p class="info-item__title">{{ $t('reports.popup.created') }}</p>
                <p class="info-item__value" v-html="formatDate(reportData.created_at)"></p>
              </div>
              <div class="popup-report__info-item info-item ml-8" v-if="reportData.updated_at && reportData.updated_at !== reportData.created_at">
                <p class="info-item__title">{{ $t('reports.popup.updated') }}</p>
                <p class="info-item__value" v-html="formatDate(reportData.updated_at)"></p>
              </div>
              <div class="popup-report__info-item info-item ml-12" v-if="reportData.deleted_at">
                <p class="info-item__title">{{ $t('reports.popup.deleted') }}</p>
                <p class="info-item__value" v-html="formatDate(reportData.deleted_at)"></p>
              </div>
            </div>
            <section v-if="reportData.dislike_notices.length && showReportPopup">
              <div class="separator"></div>
              <div class="dislike-notices">
                <p class="dislike-notices__title">{{ $t('reports.popup.disputed')}} {{ slideIndex }} <span v-if="reportData.dislike_notices.length > 1"> / {{ reportData.dislike_notices.length }}</span></p>
                <swiper-container 
                  ref="dislikeNoticesSwiper"
                  class="disputed-swiper"
                  navigation="true" 
                  @progress="onSlideProgress"
                  >
                    <swiper-slide v-for="(item) in reportData.dislike_notices" :key="item.user.id">
                      <div class="flex gap-2 my-4">
                        <div class="dislike-notices__avatar">
                          <img :src="getUrl(item.user.avatar_url) || require('@/assets/images/user/avatar/default.svg')" alt="">
                        </div>
                        <div>
                          <p class="dislike-notices__name">{{ item.user.name }}</p>
                          <p class="dislike-notices__city">{{ item.user.city }}</p>
                        </div>
                      </div>
                      <p class="dislike-notices__notice">{{item.notice}}</p>
                    </swiper-slide>
                </swiper-container>
              </div>
            </section>
            <div class="popup-buttons flex justify-between mt-8">
              <vs-button
                v-if="reportData.moderation_status === 'rejected'"
                text-color="#DA7E3C"
                class="btn"
                @click="changeStatusReport( 'pending' )"
                >{{ $t('reports.table.recover_report') }}</vs-button
              >
              <vs-button
                v-if="reportData.moderation_status === 'pending'"
                text-color="#DA7E3C"
                class="btn"
                @click="changeStatusReport( 'approved' )"
                >{{ $t('reports.table.approved_report') }}</vs-button
              >
              <vs-button
                v-if="reportData.moderation_status === 'approved'"
                text-color="#DA7E3C"
                class="btn"
                @click="changeStatusReport( 'pending' )"
                >{{ $t('reports.table.consideration') }}</vs-button
              >
              <vs-button
                v-if="reportData.moderation_status === 'pending'"
                text-color="#DA7E3C"
                class="btn"
                @click="changeStatusReport( 'rejected' )"
                >{{ $t('reports.table.reject') }}</vs-button
              >
              <vs-button
                v-if="reportData.moderation_status === 'approved'"
                text-color="#DA7E3C"
                class="btn"
                @click="changeStatusReport( 'rejected' )"
                >{{ $t('reports.table.reject') }}</vs-button
              >
            </div>
          </div>
        </vs-popup>
      </div>
    </div>
  </div>
</template>

<script>

import draggable from 'vuedraggable'
import { AgGridVue } from 'ag-grid-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import locale from 'flatpickr/dist/l10n/ru'
import 'flatpickr/dist/flatpickr.css'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import 'swiper/swiper-bundle.min'

// Cell Renderer
import CellRendererActions from '@/views/components/cell-renderer/reports/CellRendererActions.vue'
import CellRendererReport from '@/views/components/cell-renderer/reports/CellRendererReport.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)

export default {
  components: {
    AgGridVue,
    CellRendererActions,
    CellRendererReport,
    flatPickr,
    draggable,
    'v-select': vSelect,
    loading
  },
  data () {
    return {
      showSortOptions: false,
      search: '',
      selectStatus: '',
      selectUser: '',
      selectName: '',
      dateFrom: null,
      dateTo: null,
      configDate: {
        enableTime: false,
        altFormat: 'd.m.Y',
        altInput: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        locale: locale[this.$i18n.locale]
      },
      showSortTable: false,
      loading: false,
      type: 'programm',

      // AgGrid
      gridApi: null,
      columnApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        filter: false,
        sortable: false,
        cellStyle: {'line-height': '1.7', 'display': 'flex', 'align-items': 'center', 'padding': '8px'}
      },
      columnDefs: [
        {
          headerName: this.$t('reports.table.id'),
          field: 'id',
          // valueGetter: 'node.id',
          minWidth: 60,
          maxWidth: 60,
          headerClass: 'text-center',
          cellStyle: {'justify-content': 'center'},
          cellRenderer: (params) => {
            if (params.value !== undefined) {
              return params.value
            } else {
              return '<img src="https://www.ag-grid.com/example-assets/loading.gif">'
            }
          }
        },
        {
          headerName: this.$t('reports.table.user'),
          field: 'user',
          sortable: true,
          cellRenderer: params => {
            if (params.value !== undefined) {
              const name = params.value.name
              const id = params.value.id
              const mail = params.value.email_validate ? `, ${params.value.email_validate}` : ''
              const html = `<div class="user-call">
                              <h4>${name}</h4>
                              <p class="sub-text">ID: ${id}${mail}</p> 
                            </div>`
              return html
            }
          },
          minWidth: 150,
          maxWidth: 350,
          wrapText: true
        },
        {
          headerName: this.$t('reports.table.name'),
          field: 'unified_title',
          wrapText: true,
          cellStyle: {
            'line-height': '1.3',
            // 'display': '-webkit-box;',
            // '-webkit-box-orient': 'vertical',
            // '-webkit-line-clamp': 3,
            'overflow': 'hidden',
            'text-overflow': 'ellipsis'
          },
          minWidth: 180,
          maxWidth: 500
        },
        {
          headerName: this.$t('reports.table.report'),
          field: 'exercise_results',
          cellStyle: {'line-height': '1.2'},
          minWidth: 300,
          maxWidth: 500,
          cellRenderer: 'CellRendererReport'
        },
        {
          headerName: '🔥',
          field: 'point',
          sortable: true,
          minWidth: 40,
          maxWidth: 60,
          headerClass: 'text-center text-bold text-xl',
          cellStyle: {'justify-content': 'center'}
        },
        {
          headerName: '❤',
          field: 'like_count',
          sortable: true,
          minWidth: 40,
          maxWidth: 60,
          headerClass: 'text-center text-bold text-xl',
          cellStyle: {'justify-content': 'center'}
        },
        {
          headerName: '!',
          field: 'dislike_count',
          sortable: true,
          minWidth: 40,
          maxWidth: 60,
          headerClass: 'text-center text-bold text-xl',
          cellStyle: {'justify-content': 'center'}
        },
        {
          headerName: this.$t('reports.table.created'),
          field: 'created_at',
          sortable: true,
          minWidth: 130,
          maxWidth: 130,
          headerClass: 'text-center',
          cellStyle: {'justify-content': 'center'},
          cellRenderer: (params) => {
            const date = params.value
            
            return this.formatDate(date)
          }
        }, 
        {
          headerName: '',
          field: 'moderation_status',
          cellRenderer: 'CellRendererActions',
          minWidth: 200,
          maxWidth: 200,
          cellStyle: {
            'justify-content': 'center'
          }
        },

        {
          headerName: 'Лайк',
          field: 'moderation_like',
          minWidth: 100,
          maxWidth: 100,
          headerClass: 'text-center',
          cellStyle: {
            'justify-content': 'center',
            'text-align': 'center'
          },
          cellRenderer: (params) => {
            return params.value ? '🧡' : ''
          }
        }

      ],
      startId: null,
      page: null,
      rowCount: null,
      rowBuffer: null,
      rowSelection: null,
      rowModelType: null,
      cacheBlockSize: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      maxBlocksInCache: null,

      overlayNoRowsTemplate: null,
      showReportPopup: false,
      reportData: {},
      slideIndex: 0
      // deleteProcess: false,
      // deleteItemId: 0
    }   
  },

  methods: {
    onSlideProgress (e) {
      const [swiper, progress] = e.detail
      const totalSlides = swiper.slides.length - 1
      this.slideIndex = Math.round(progress * totalSlides) + 1
    },

    onGridReady (params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi

      const updateData = () => {

        const dataSource = {

          rowCount: null,
          getRows: (params) => {
            const sort = params.sortModel[0]
            const colId = sort ? sort.colId : null

            const sortDislikes = (colId === 'dislike_count') ? sort.sort : null
            const sortLikes = (colId === 'like_count') ? sort.sort : null
            const sortPoints = (colId === 'points.point') ? sort.sort : null
            const sortCreation = (colId === 'created_at') ? sort.sort : null
            const sortUser = (colId === 'user') ? sort.sort : null

            this.$store
              .dispatch('reports/getSuite', {
                start_id: this.startId,
                page: this.page,
                per_page: this.cacheBlockSize || 30,
                search: this.search || null,
                titles: this.selectName ? this.selectName : [],
                status: this.selectStatus.id || [],
                user_ids: this.selectUser ? this.selectUser.map(item => item.id) : [],
                start_date: this.dateFrom || null,
                end_date: this.dateTo || null,
                by_dislikes: sortDislikes,
                by_likes: sortLikes,
                by_points: sortPoints,
                by_creation: sortCreation,
                by_user: sortUser
              })
              .then((data) => {
                const rowsThisPage = data.data
                rowsThisPage.data = rowsThisPage.data.filter(item => item.user)
                this.gridApi.hideOverlay() 
                if (rowsThisPage.data.length === 0) {
                  this.gridApi.showNoRowsOverlay()
                } 
                const lastItemId = rowsThisPage.data.length ? rowsThisPage.data[rowsThisPage.data.length - 1].id : null
                this.lastRow = this.lastRow || rowsThisPage.meta.total
                console.log('data', rowsThisPage)
                if (this.page === 1 && rowsThisPage.data.length) {
                  this.startId = rowsThisPage.data[0].id
                }
                this.page++

                params.successCallback(rowsThisPage.data, this.lastRow)
              })
              .catch(error => {
                console.log('error', error)
                params.failCallback()
                this.$vs.notify({
                  title: this.$t('users.suite.errors.title'),
                  text: error,
                  color: 'danger',
                  time: 10000
                })
              })      
          }
        }
        params.api.setDatasource(dataSource)
      }
      updateData()
    },

    onSortChanged (params) {
      this.startId = null
      this.page = 1
    },

    onRowClicked (params) {
      this.reportData = JSON.parse(JSON.stringify(params.data))
      this.showReportPopup = true
    },

    getRowStyle (params) {
      if (params.data && params.data.moderation_status === 'rejected') {
        return { background: '#ffe6e6' }
      } else if (params.data && params.data.moderation_status === 'approved') {
        return { background: 'lightgreen' }
      } else {
        return {background: '#fff' }
      }
    },

    updateCellData (rowId, columnName, newValue) {
      this.gridApi.forEachNode((node) => {
        if (node.data.id === rowId) {
          node.setDataValue(columnName, newValue)
        }
        // this.gridApi.refreshInfiniteCache()
      })
    },

    applyFilter () {
      this.startId = null
      this.page = 1
      this.lastRow = null
      this.gridApi.purgeInfiniteCache()
    },

    applySearchFilter () {
      if (this.search.length > 2) {
        this.applyFilter()
      }
    },

    clearFilter () {
      this.startId = null
      this.page = 1
      this.lastRow = null
      this.search = ''
      this.selectName = ''
      this.selectUser = ''
      this.selectStatus = ''
      this.dateFrom = null
      this.dateTo = null
      this.gridApi.purgeInfiniteCache()
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const dayMonthYear = moment(date).format('DD.MM.YYYY')
      const hoursMinutes = moment(date).format('HH:mm')
      const dayMonthYearSpan = `<span style="color: #000; font-weight: 600">${dayMonthYear}</span>`
      const hoursMinutesSpan = `<span style="color: #8E8E8E; font-weight: 600">${hoursMinutes}</span>`
      return `${dayMonthYearSpan} ${hoursMinutesSpan}`
    },

    changeStatusReport (status) {
      this.$store.dispatch('reports/rejectRecoverReport', { id: this.reportData.id, moderation_status: status }).then(() => {
        this.updateCellData(
          this.reportData.id,
          'moderation_status',
          status
        )
      }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.showReportPopup = false
      })
    },
    

    formatTime (milliseconds) {
      if (!milliseconds) {
        return ''
      }
      const time = moment.duration(milliseconds)
      const formattedTime = moment.utc(time.asMilliseconds()).format('HH:mm:ss')
      return formattedTime
    },
    goToEditPoint (point) {
      const pointId = point.id
      const userId = this.reportData.user.id
      this.$router.push(`/users/edit-user/${userId}/points/${pointId}`)
    },
    toggleLike (id) {
      this.$store.dispatch('reports/toggleLike', { id: id }).then(() => {
        const newValue = !this.reportData.moderation_like
        this.reportData.moderation_like = newValue
        this.updateCellData(
          this.reportData.id,
          'moderation_like',
          newValue
        )
      }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title')
        })
      })
    }
  },

  computed: {
    formError () {
      return this.$store.getters['reports/formError']
    },

    thumbImages () {
      let images = []
      const activityImg = this.reportData.images.map(el => {
        return el.image_path_168 || el.image_path_300 || el.image_path_600 || el.image_path
      })
      if (activityImg.length) {
        images = activityImg
      }
      const exerciseResults = this.reportData.exercise_results
      if (exerciseResults && exerciseResults.length) {
        exerciseResults.forEach(element => {
          if (element.result_type === 'photo') {
            const resultsImg = element.images.map(el => {
              return el.image_path_168 || el.image_path_300 || el.image_path_600 || el.image_path
            })
            if (resultsImg.length) {
              images = [...images, ...resultsImg]
            }
          }
        })
      }
      return images
    },
    bigImages () {
      let images = []
      const activityImg = this.reportData.images.map(el => {
        return el.image_path || el.image_path_600 || el.image_path_300 || el.image_path_168
      })
      if (activityImg.length) {
        images = activityImg
      }
      const exerciseResults = this.reportData.exercise_results
      if (exerciseResults && exerciseResults.length) {
        exerciseResults.forEach(element => {
          if (element.result_type === 'photo') {
            const resultsImg = element.images.map(el => {
              return el.image_path || el.image_path_600 || el.image_path_300 || el.image_path_168
            })
            if (resultsImg.length) {
              images = [...images, ...resultsImg]
            }
          }
        })
      }
      return images
    },
    reportInPopup () {
      if (this.reportData.id) {
        const exerciseResults = this.reportData.exercise_results.filter(item => {
          return item.result_type !== 'photo'
        })
        const activityResults = this.reportData.activity_results
        const data = activityResults.length ? activityResults : exerciseResults
        data.map(item => {
          if (item.result_type === 'time_less' || item.result_type === 'time_more' || item.result_type === 'time') {
            item.value = this.formatTime(item.value)
          }
          if (item.result_type === 'distance') {
            item.value += ` ${this.$t('reports.suite.km')}`
          }
          if (item.result_type === 'radio_answers') {
            item.value = item.value[0].answer
          }
          if (item.result_type === 'amount') {
            item.value += ` ${this.$t('reports.suite.amount')}`
          }
        })
        return data
      }
      
    }
  },

  created () {
    this.startId = null
    this.page = 1
    this.rowBuffer = 0
    this.rowSelection = 'multiple'
    this.rowModelType = 'infinite'
    this.cacheBlockSize = 30
    this.cacheOverflowSize = 1
    this.maxConcurrentDatasourceRequests = 1
    this.infiniteInitialRowCount = 1
    this.maxBlocksInCache = null

    this.overlayNoRowsTemplate = `<div class="flex items-center justify-center text-3xl font-bold">${this.$t('reports.table.no_data')}</div>`

    this.$store.dispatch('reports/getTitles')
    this.$store.dispatch('reports/getStatuses')
    this.$store.dispatch('user/getList')
    this.$store.commit('category/clearFormError')

  },
  mounted () {
  }

}
</script>


<style lang="scss" scoped>
.disputed-swiper::part(button-prev), .disputed-swiper::part(button-next) {
  width: 20px;
  height: 20px;
  background: #fff;
  color: #8E8E93;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 5px;
  font-weight: bold;
  border-radius: 50%;
  top: 2px;
}
.disputed-swiper::part(button-prev) {left: 105px;}
.disputed-swiper::part(button-next) {left: 137px;}

ol {
  list-style-type: decimal !important;
  padding-left: 18px !important;
}
.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.card ::v-deep > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > div:first-child {
    margin-bottom: auto;
  }
  .vx-card__body {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.buttons-wrapper {
  margin-bottom: 30px;
}
.buttons-wrapper > button {
  display: inline-block;
  margin-right: 30px;
}
.sort-table-wrapper {
  width: 100%;
}
.list-item {
  transition: all 1s;
}
.list-item:hover {
  cursor: all-scroll;
}

.popup-report .btn {
  background: #fff !important;
  border: 1px solid #DA7E3C;
  &:hover {
    background: #fcf3ec !important;
  }
}

.dislike-notices {
  font-size: 12px;
  &__title {
    font-weight: 600;
    color: #1E1E1E;
  }

  &__avatar {
    width: 32px;
    height: 32px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__name {
    color: #1a1a1a;
  }

  &__city {
    font-size: 10px;
    color: #9F9FA5;
  }

  &__notice {
    font-size: 16px;
    color: #121212;
  }
}

::v-deep {
  .vs__dropdown-toggle {
  background: #fff;
}
  .call-achievement {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    min-width: 52px;
    .row-number {
      line-height: 1;
    }
    .icon-achievement {
      width: 28px;
      height: 28px;
      object-fit: contain;
    }
  }

  .ag-cell-wrap-text {
    word-break: break-word;
  }

}
</style>

<style lang="scss">
.user-call {
  h4 {
    font-size: 12px;
  }
  .sub-text {
    font-size: 8px;
    color: #8e8e8e;
  }
}

.report-popup .vs-popup {
  width: 650px;
}

.popup-report {
  width: 100%;

  &__user {
  }
  &__content {
    .content__text {
      padding-right: 16px;
      margin-right: 6px;
      font-size: 16px;
      max-height: 210px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 2px;
      }
      /* Установите цвет фона вертикальной полосы прокрутки */
      &::-webkit-scrollbar-track {
        background: #dedede;
      }
      /* Установите цвет заполнения вертикальной полосы прокрутки */
      &::-webkit-scrollbar-thumb {
        background-color: #7367f0;
        border-radius: 5px;
      }
    }
    .content__image {
      width: 100%;
      height: 210px;
      background: #d9d9d9;
      border-radius: 8px;
      padding: 0;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        padding: 16px;
      }
    }
  }
  &__info {
    .info-item {
      &__title {
        font-size: 12px;
      }
      &__value {
        // display: flex;
        // align-items: center;
        font-size: 16px !important;
        line-height: 1.2;
        color: #000 !important;
      }
      &__edit {
        margin-left: 4px;
        cursor: pointer;
        feather-icon.feather-icon {
          width: 8px !important;
          height: 8px;
        }
      }
    }
  }
  .report-item {
    &__title {
      font-size: 12px;
      color: #8e8e8e;
    }
    &__value {
      font-size: 14px;
      color: #000;
    }
  }
  .btn {
    background: rgba(218, 126, 60, 0.1);
    border: 1px solid #da7e3c;
  }
}

.user-block {
  &__avatar {
    flex: 0 0 46px;
    width: 46px;
    height: 46px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
swiper-container {
  &::part(container) {
    overflow-y: visible;
  }
  &::part(pagination) {
    bottom: -26px;
  }
  &::part(bullet-active) {
    background: #DA7E3C !important;
  }
}

.ag-grid-wrapper {
  height: 600px;
  width: 100%;
  .ag-header-cell {
    padding-left: 8px;
    padding-right: 8px;
  }
}


.separator {
  border-bottom: 2px solid #e8e8e8;
  margin: 30px 0 20px;
}

.vs-input--icon {
    font-size: 20px;
    left: 8px;
  }

.filter-settings {
  .vs-button {
    padding-left: 6px !important;
    padding-right: 6px !important;
    padding-bottom: 6px !important;
  }

  .vs-button-primary.vs-button-line {
    border-color: #e0ddff !important;
  }
  .vs-button-primary.vs-button-line .vs-button-linex {
    background: #7367f0 !important;
  }
}
</style>
