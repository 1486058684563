<template>
  <div id="demo-basic-card">
    <div class="">
      <template>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-base flex gap-4">
            <vs-button
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-plus"
              class="add-button"
              @click="$router.push({ name: `personal-goal-template-create` })"
              :disabled="isOnlyRead"
              >{{ $t(`personal_goals.suite.buttons.create_personal_goal`) }}</vs-button
            >
            <vs-button
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-upload"
              :disabled="isOnlyRead"
              @click="$refs.file.click()"
            >{{ $t('personal_goals.suite.title.import_personal_goal') }}
            </vs-button
            >
          </div>
          <div class="vx-col md:w-1/2 w-full mb-base flex justify-end">
            <vs-button color="primary" type="border" icon-pack="feather" icon="icon-list" @click="showSortTable = !showSortTable" :disabled="isOnlyRead">{{$t('personal_goals.suite.buttons.sort')}}</vs-button>
          </div>
        </div>
        <input type="file" ref="file" style="display: none" @change="previewFile" />
      </template>

      <vs-alert :active.sync="showFileName" closable icon-pack="feather" close-icon="icon-x">
        {{ fileName }}
      </vs-alert>
      <div class="demo-alignment" v-if="showUploadButtons">
        <vs-button color="success" type="filled" icon-pack="feather" icon="icon-upload" @click="importUsersFile()"></vs-button>
      </div>
      <div v-if="uploading">
        <font-awesome-icon icon="spinner" spin />
      </div>

      <loading v-if="loading"></loading>
      <div class="vx-col w-full mb-base" v-if="!showSortTable && !loading">

        <div class="vx-card p-6">
          <!-- AgGrid Table -->
          <ag-grid-vue
            @gridReady="onGridReady"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4"
            domLayout="autoHeight"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="suite"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl"
          />

          <vs-pagination v-if="totalPages > 1"
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage" />

        </div>

        <vs-popup class="holamundo" title="" :active.sync="deleteItemPopupActive">
          <p>{{ deleteItemPopupContent }}</p>
          <div v-if="deleteProcess">
            <font-awesome-icon icon="spinner" spin />
          </div>
          <div class="delete-confirm-popup-buttons" v-else>
            <vs-button color="primary" type="filled" @click="deleteItem()">{{ $t('users.suite.popups.confirm_delete_user.buttons.confirm') }}</vs-button>
            <vs-button color="warning" type="filled" @click="deleteItemPopupActive=false">{{ $t('users.suite.popups.confirm_delete_user.buttons.cancel') }}</vs-button>
          </div>
        </vs-popup>

      </div>

      <div v-if="showSortTable && !loading" class="sort-table-wrapper">
        <vs-list>
          <draggable :list="suite" :options="{animation: 150}" @change="sort()">
            <transition-group tag="ul">
              <vs-list-item class="list-item" v-for="mission in suite" :key="mission.id" :title="mission.name">
                <vs-avatar
                  slot="avatar"
                  :src="getUrl(mission.icon)
                  "
                />
              </vs-list-item>
            </transition-group>
          </draggable>
        </vs-list>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererLink from '../../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../../components/cell-renderer/missions/CellRendererActions.vue'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)

export default {
  components: {
    AgGridVue,
    // Cell Renderer
    CellRendererLink,
    CellRendererActions,

    draggable,
    loading
  },
  data () {
    return {
      file: null,
      showFileName: false,
      fileName: '',
      showUploadButtons: false,
      uploading: false,
      showSortTable: false,
      loading: true,
      type: 'programm',

      // AgGrid
      gridApi: null,
      columnApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: {'display': 'flex', 'align-items': 'center'},
        headerClass: 'text-center'
      },
      columnDefs: [
        {
          headerName: this.$t('personal_goals.table.achievement'),
          cellRenderer: params => {
            const imageUrl = params.data.icon
            const id = params.data.id
            const index = this.suite.findIndex(item => item.id === id)
            return `<div class="call-achievement">
                      <span class="row-number">${id}</span><img class="icon-achievement" src='${this.getUrl(imageUrl)}'></img>
                    </div>`
          },
          cellStyle: {'justify-content': 'center'},
          minWidth: 100,
          maxWidth: 120
        },
        {
          headerName: this.$t('personal_goals.table.name'),
          field: 'name',
          minWidth: 250,
          maxWidth: 800,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7'},
          headerClass: 'text-left',
          filter: true
        },
        {
          headerName: this.$t('personal_goals.table.type'),
          field: 'class_label',
          filter: true,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.4'},
          headerClass: 'text-left',
          minWidth: 200,
          maxWidth: 330
          // flex: 1
        },
        {
          headerName: this.$t('personal_goals.table.tags'),
          autoHeight: true,
          minWidth: 180,
          maxWidth: 230,
          cellStyle: {'line-height': '1.7', 'justify-content': 'center', 'text-align': 'center'},
          cellRenderer: params => {
            const tags = params.data.tags
            let tagsHtml = ''
            for (const element of tags) {
              tagsHtml += `<span class="tag">${element.label}</span><br>`
            }
            return tagsHtml
          }
        },
        {
          headerName: this.$t('personal_goals.table.participants'),
          field: 'progress_count',
          minWidth: 125,
          maxWidth: 130,
          cellStyle: {'justify-content': 'center'},
          sortable: true
        },
        {
          headerName: this.$t('personal_goals.table.status'),
          field: 'status',
          sortable: true,
          minWidth: 125,
          maxWidth: 125,
          cellRenderer: ({data: {status, is_active}}) => {
            const bgColor = is_active ? '#DA7E3C' : '#808080'
            return `<div
                      style="
                        justify-content: center;
                        text-align: center;
                        border-radius: 4px;
                        color: white;
                        line-height: 2;
                        width: 100px;
                        background-color: ${bgColor};"
                      >
                      ${status}
                    </div>`
          }
        },
        {
          headerName: this.$t('personal_goals.table.actions'),
          field: 'id',
          minWidth: 150,
          maxWidth: 150,
          autoHeight: true,
          cellRenderer: 'CellRendererActions',
          cellStyle: {'justify-content': 'flex-end'},
          sortable: false
        }
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deleteItemPopupActive: false,
      deleteItemPopupContent: '',
      deleteProcess: false,
      deleteItemId: 0
    }
  },
  methods: {
    previewFile (event) {
      this.file = event.target.files[0]
      this.fileName = this.file.name
      this.showFileName = true
      this.showUploadButtons = true
    },
    importUsersFile () {
      this.uploading = true
      this.$store
        .dispatch('mission/importMission', {
          file: this.file
        })
        .then((response) => {
          this.$vs.notify({
            title: this.$t('personal_goals.suite.title.import.alert.title'),
            text: this.$t('personal_goals.suite.title.import.alert.text'),
            color: 'success',
            time: 10000
          })
          this.showFileName = this.showUploadButtons = false
        })
        .catch((error) => {
          const errorString = this.getErrorString(error.response.data)
          this.$vs.notify({
            title: this.$t('personal_goals.suite.title.import.alert.error'),
            text: errorString === '' ? error : errorString,
            color: 'danger',
            time: 10000
          })
        })
        .then(() => {
          this.$store.dispatch('mission/getSuite').then(() => {
            this.uploading = false
          })
        })
    },
    getTemplateName (data) {
      data.forEach(mission => {
        if (mission.class_name === 'activities') {
          switch (mission.metrics) {
          case 'meters':
          case 'kilometers':
            mission.class_label = this.$t('personal_goals.template.distance.name')
            break
          case 'minutes':
          case 'hours':
            mission.class_label = this.$t('personal_goals.template.time.name')
            break
          case 'amount':
            mission.class_label = this.$t('personal_goals.template.steps.name')
            break
          }
        } else {
          mission.class_label = this.$t(`personal_goals.template.${ mission.class_name}.name`)
        }
      })
    },
    getStatus (data) {
      data.forEach(mission => {
        if (mission.is_active === 1) {
          mission.status = this.$t('personal_goals.suite.title.active')
        } else {
          mission.status = this.$t('personal_goals.suite.title.not_active')
        }
      })
    },
    updateSuite () {
      this.suite = JSON.parse(JSON.stringify(this.$store.getters['mission/suite']))
      this.loading = false
      this.getTemplateName(this.suite)
      this.getStatus(this.suite)

    },
    deleteItem () {
      this.deleteProcess = true
      this.$store.dispatch('mission/delete', { id: this.deleteItemId }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.deleteProcess = false
        this.deleteItemPopupActive = false
      })
    },

    sort () {
      const payload = {
        suite: this.suite
      }
      this.$store.dispatch('mission/sort', payload)
    },

    //table
    onGridReady (params) {
      this.gridApi = params.api
    }
  },

  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-missions'] === 'read'
      }
    },
    suite () {
      const data = this.$store.getters['mission/suite']
      this.getTemplateName(data)
      this.getStatus(data)
      return data
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages: {
      get () {
        return this.gridApi ? this.gridApi.paginationGetTotalPages() : 0
      },
      set () {
      }
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },

  created () {
    this.$store.dispatch('mission/getSuite').then(() => {
      this.loading = false
    })
  },
  mounted () {
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  }

}
</script>

<style lang="scss" scoped>

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.card ::v-deep > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > div:first-child {
    margin-bottom: auto;
  }
  .vx-card__body {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.buttons-wrapper {
  margin-bottom: 30px;
}
.buttons-wrapper > button {
  display: inline-block;
  margin-right: 30px;
}
.sort-table-wrapper {
  width: 100%;
}
.list-item {
  transition: all 1s;
}
.list-item:hover {
  cursor: all-scroll;
}

::v-deep {
  .call-achievement {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    min-width: 52px;
    .row-number {
      line-height: 1;
    }
    .icon-achievement {
      width: 28px;
      height: 28px;
      object-fit: contain;
    }
  }

 .ag-cell-wrap-text {
   word-break: break-word;
 }
}

</style>
