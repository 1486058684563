import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'

export default {
  uploadFile ({commit}, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/editorjs/images', formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchUrl ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/editorjs/images/fetch-url/?url=${payload.url}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
