<template>
  <div id="demo-basic-card">


      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <vs-button color="primary" type="gradient" icon-pack="feather" icon="icon-plus" class="add-button"
                     @click="$router.push({ name: 'faq-create'})" :disabled="isOnlyRead"></vs-button>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-base flex justify-end">
          <vs-button color="primary" type="border" icon-pack="feather" icon="icon-list" @click="showSortTable = !showSortTable" :disabled="isOnlyRead">{{ $t('faq.buttons.priority') }}</vs-button>
        </div>
      </div>

    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <loading v-if="loading"></loading>
        <div class="vx-card p-6" v-if="!loading&&!showSortTable">
          <ag-grid-vue
            @gridReady="onGridReady"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4"
            domLayout="autoHeight"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="suite"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl">
          </ag-grid-vue>

          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage" />
        </div>

        <vs-popup class="holamundo" title="" :active.sync="deleteFaqPopupActive">
          <p>{{ deleteFaqPopupContent }}</p>
          <div v-if="deleteProcess">
            <font-awesome-icon icon="spinner" spin />
          </div>
          <div class="delete-confirm-popup-buttons" v-else>
            <vs-button color="primary" type="filled" @click="deleteFaq()">{{ $t('faq.suite.popups.confirm_delete.buttons.confirm') }}</vs-button>
            <vs-button color="warning" type="filled" @click="deleteFaqPopupActive=false">{{ $t('faq.suite.popups.confirm_delete.buttons.cancel') }}</vs-button>
          </div>
        </vs-popup>
      </div>

    </div>
    <div v-if="showSortTable&&!loading" class="sort-table-wrapper">
      <vs-list>
        <draggable :list="suite" @change="sort()">
          <transition-group>
            <vs-list-item class="list-item" v-for="faq in suite" :key="faq.id" :title="faq.title">
              <vs-avatar slot="avatar" :src="getUrl(faq.image_path)" />
            </vs-list-item>
          </transition-group>
        </draggable>
      </vs-list>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import loading from '../../components/Loading'
import CellRendererLink from '../../components/cell-renderer/CellRendererLink'
import CellRendererActions from '../../components/cell-renderer/FAQ/CellRenderActions'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons'
import draggable from 'vuedraggable'

library.add(faSpinner)
library.add(faCheck)

export default {
  components: {
    AgGridVue,
    loading,
    CellRendererActions,
    CellRendererLink,
    draggable
  },
  data () {
    return {
      loading: true,
      gridApi: null,
      icons: null,
      groupsOptions: [],
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: { 'text-align': 'center'},
        headerClass: 'text-center'
      },
      columnDefs: [
        {
          headerName: this.$t('faq.table.id'),
          field: 'id',
          minWidth: 50,
          maxWidth: 75,
          sortable: true
        },
        {
          headerName: this.$t('faq.table.title'),
          field: 'title',
          filter: true,
          minWidth: 250,
          maxWidth: 1000,
          flex: 1,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 24px'},
          headerClass: 'text-left'
        },
        {
          headerName: this.$t('faq.table.groups'),
          field: 'groups',
          minWidth: 140,
          maxWidth: 300,
          sortable: true,
          filter: true
        },
        {
          headerName: this.$t('faq.table.is_published'),
          minWidth: 160,
          maxWidth: 160,
          sortable: true,
          field: 'is_published',
          cellRenderer: (params) => {
            return params.value ? '&#10004;' : '&#10006;'
          }
        },
        {
          headerName: this.$t('faq.table.actions'),
          field: 'id',
          minWidth: 110,
          maxWidth: 130,
          cellRenderer: 'CellRendererActions'
        }
      ],
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deleteFaqPopupActive: false,
      deleteFaqPopupContent: '',
      deleteProcess: false,
      deleteFaqId: 0,
      showSortTable: false
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-settings'] === 'read'
      }
    },
    suite () { return this.$store.getters['faq/suite'] },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },
  created () {
    this.$store.dispatch('faq/getSuite').then(() => {
      this.loading = false
    })
    this.$store.dispatch('faq/getGroupsOptions').then(() => {
      this.groupsOptions = this.$store.getters['faq/groupsOptions']
    })
  },
  methods: {
    sort () {
      const payload = {
        suite: this.suite
      }
      this.$store.dispatch('faq/sort', payload)
    },
    deleteFaq () {
      this.deleteProcess = true
      this.$store.dispatch('faq/destroy', { id: this.deleteFaqId }).catch(error => {
        this.$vs.notify({
          title: this.$t('faq.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.deleteProcess = false
        this.deleteFaqPopupActive = false
      })
    },
    onGridReady (params) {
      this.gridApi = params.api
    }
  }
}
</script>

<style scoped>
.add-button {
  margin-bottom: 30px;
}
.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
</style>
