import state from './moduleSegmentState.js'
import mutations from './moduleSegmentMutations.js'
import actions from './moduleSegmentActions.js'
import getters from './moduleSegmentGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
