<template>
  <div class="total-count" :class="{'flex-col': column}">
    <div class="total-count__item">
      <p v-if="typeof processedCountData === 'object'" class="total-count__label">{{ $t('dashboard.labels.all_time') }}</p>
      <div class="mt-2" :class="{ 'statisticDiff-left': diffPositionLeft }">
        
        <div class="flex items-center">
          <svg v-if="statisticTitle === $t('dashboard.titles.earned_points')" class="total-count__icon" width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.80496 10.9156C1.25629 10.2897 0.707638 9.63878 0.358471 8.86266C0.00932714 8.03648 -0.0904382 7.08513 0.0841452 6.18384C0.258729 5.28255 0.657743 4.43133 1.23136 3.7053C1.18149 4.3312 1.40595 4.98213 1.82991 5.45781C1.58051 4.2561 1.97955 2.95423 2.7776 2.00288C3.57565 1.05153 4.673 0.4256 5.8202 0C5.2466 0.600858 5.17178 1.55221 5.39623 2.35337C5.62069 3.15451 6.09454 3.85552 6.51851 4.58154C6.94247 5.30757 7.34151 6.05865 7.36645 6.88483C7.59091 6.45923 7.84031 6.0336 7.98995 5.55794C8.13958 5.08225 8.2144 4.58155 8.08971 4.10586C8.53862 4.65665 8.78802 5.20742 8.91271 5.90843C9.0374 6.60944 9.01248 7.33547 8.93766 8.03648C8.83789 8.8126 8.63838 9.61374 8.21442 10.2647C7.71564 11.0408 6.91757 11.5915 6.0447 11.892C4.49842 12.1674 2.97711 11.9421 1.80496 10.9156Z" fill="#F74D30"/>
                    <path d="M3.70035 11.8423C5.74536 12.6435 7.76545 10.215 6.64318 8.3373C6.64318 8.31225 6.61823 8.31225 6.61823 8.28723C6.718 9.31369 6.46859 10.0648 5.99474 10.4904C6.24414 9.88953 6.06956 9.18852 5.77028 8.58766C5.47101 8.01185 5.04705 7.48609 4.72283 6.9353C4.39861 6.35949 4.14923 5.70854 4.29887 5.05762C3.70032 5.50826 3.27636 6.18423 3.10178 6.91026C2.92719 7.63629 3.02696 8.43745 3.35118 9.11341C2.97709 8.83803 2.70276 8.38738 2.67781 7.91169C2.27878 8.38738 2.0294 9.01326 2.05432 9.63916C2.05435 10.6156 2.82745 11.4918 3.70035 11.8423Z" fill="#FFD15C"/>
                  </svg>
          <p class="total-count__number" v-if="!countDataIsObject">{{ processedCountData }}
          </p>
        </div>
        <vx-tooltip v-if="!countDataIsObject && statisticDiff" :text="$t('dashboard.labels.last_changes')" position="right" color="primary">
          <p
            class="flex items-start text-lg"
            :class="{
              'text-green': statisticDiff > 0,
              'text-gray-500': statisticDiff == 0,
              'text-red': statisticDiff < 0,
            }"
          >
            <vs-icon v-if="statisticDiff != 0" :icon="statisticDiff > 0 ? 'expand_less' : 'expand_more'" size="25px"></vs-icon>
            {{ Math.abs(statisticDiff) }}
          </p>
        </vx-tooltip>
        <p class="total-count__number" v-if="countDataIsObject">{{ processedCountData.quantity_for_all_time }}</p>
      </div>
    </div>
    <div class="total-count__item" v-if="period && period !== $t('dashboard.labels.all_time')">
      <p class="total-count__label">{{ period }}</p>
      <div :class="{ 'statisticDiff-left': diffPositionLeft }" class="mt-2">
        <p class="total-count__number" :style="{ color: colorPeriod }">
          {{ formatNumber(getPeriodStatisticsData(processedCountData)) }}
        </p>
        <vx-tooltip v-if="(typeof this.countData == 'object') && diffObjects && showDiff && (getPeriodStatisticsData(diffObjects) !== 'NaN') " :text="$t('dashboard.labels.changes_compared_to_the_same_previous_period').replaceAll('[PERIOD]', this.periodInDays)" position="right" color="primary">
          <p
            class="flex items-start text-lg"
            :class="{
              'text-green': Number(getPeriodStatisticsData(diffObjects)) > 0,
              'text-gray-500': Number(getPeriodStatisticsData(diffObjects)) == 0,
              'text-red': Number(getPeriodStatisticsData(diffObjects)) < 0,
            }"
          >
            <vs-icon v-if="Number(getPeriodStatisticsData(diffObjects))" :icon="Number(this.getPeriodStatisticsData(diffObjects)) > 0 ? 'expand_less' : 'expand_more'" size="25px"></vs-icon>
            {{ Math.abs(+getPeriodStatisticsData(diffObjects)) }} {{diffPercent ? '%' : $t('dashboard.diff_time.h')+'.'}}
          </p>
        </vx-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'statistics-counter',
  props: {
    countData: {
      type: [Object, Number],
      required: true
    },
    period: {
      type: String
    },
    colorPeriod: {
      type: String,
      default: '#626262'
    },
    statisticTitle: {
      type: String
    },
    diffPositionLeft: {
      type: Boolean,
      default: false
    },
    diffPercent : {
      type: Boolean,
      default: false
    },
    column: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      statisticDiff: null,
      diffObjects: null,
      countDataIsObject: false
    }
  },
  computed: {
    processedCountData () {
      const data = JSON.parse(JSON.stringify(this.countData))
      this.getDiffObjects()
      if (data && typeof data === 'number') {
        return this.formatNumber(data)
      } else if 
      (data !== null && typeof data === 'object') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.countDataIsObject = true
        data.quantity_for_all_time = data.quantity_for_all_time ? this.formatNumber(data.quantity_for_all_time) : this.formatNumber(data.count_for_all_time)
        return data
      }
    },
    showDiff () {
      if ((this.period !== this.$t('dashboard.labels.all_time')) && this.countData.series && this.countData.series[0].data.length > 1) {
        const daysAgo60 = moment().subtract(60, 'days')
        const daysAgo730 = moment().subtract(730, 'days')
        const firstDateInData = this.countData.series[0].data[0][0]
        if (this.period === this.$t('dashboard.labels.month')) {
          if (firstDateInData && moment(firstDateInData).isBefore(daysAgo60)) {
            return true
          }
        }
        if (this.period === this.$t('dashboard.labels.year')) {
          if (firstDateInData && moment(firstDateInData).isBefore(daysAgo730)) {
            return true
          }
        }
      }
      return false
    },
    periodInDays () {
      return this.period === this.$t('dashboard.labels.month') ? 30 : 365
    }
    
  },

  methods: {
    formatNumber (number) {
      if (typeof (number) !== 'undefined') {
        return number.toLocaleString()
      } else {
        return '--'
      }
    },

    getDiffObjects () {
      const result = {}
      const monthStatistic = this.countData.quantity_for_last_thirty_days 
      const lastMonthStatistic = this.countData.quantity_for_time_interval_between_last_thirty_and_sixty_days
      const yearStatistic = this.countData.quantity_for_last_calendar_year
      const lastYearStatistic = this.countData.quantity_for_time_interval_between_last_calendar_and_year

      const diffMonth = monthStatistic - lastMonthStatistic
      const diffYear = yearStatistic - lastYearStatistic

      result.count_for_month = this.diffPercent ? (parseFloat(diffMonth / monthStatistic * 100)).toFixed(2) : diffMonth 
      result.count_for_year = this.diffPercent ? (parseFloat(diffYear / yearStatistic * 100)).toFixed(2) : diffYear
      this.diffObjects = result
      return result
    },

    getPeriodStatisticsData (countData) {
      const dataByPeriod = {
        [this.$t('dashboard.labels.month')]: countData.quantity_for_last_thirty_days || countData.count_for_month,
        [this.$t('dashboard.labels.year')]: countData.quantity_for_last_calendar_year || countData.count_for_year,
        [this.$t('dashboard.labels.all_time')]: countData.quantity_for_all_time || countData.count_for_all_time
      }
      // if (isNaN(parseFloat(dataByPeriod[this.period]))) {
      //   return 5
      // }
      return +dataByPeriod[this.period] || countData.quantity_for_last_thirty_days || countData.count_for_month

    },

    getDiffStatistic () {
      const statisticTitle = this.statisticTitle || this.countData.title || this.countData.series?.[0]?.name
      if (typeof this.countData === 'object') {
        this.getDiffObjects()
      } else {
        const statisticValue = Number(this.countData)
        const oldStatisticValue = Number(localStorage.getItem(`statistic_${statisticTitle}`)) || 0
        if (oldStatisticValue) {
          if (this.diffPercent) {
            this.statisticDiff = parseFloat((statisticValue - oldStatisticValue) / oldStatisticValue * 100).toFixed(2)
          } else {
            this.statisticDiff =  statisticValue - oldStatisticValue
          }
        } 
        localStorage.setItem(`statistic_${statisticTitle}`, JSON.stringify(this.countData))
      }
    }
     
  },


  mounted () {
    setTimeout(() => {
      this.getDiffStatistic()
    }, 1000)
    
 
  }
}
</script>

<style lang="scss" scoped>
.total-count {
  display: flex;
  align-items: flex-start;
  gap: 16px 32px;
  font-weight: 500;

  &__item {
  }

  &__label {
    font-size: 16px;
  }

  &__number {
    font-weight: 700;
    font-size: 32px;
    // margin-top: 10px;
  }

  &__icon {
    width: 20px;
    height: 24px;
    margin-right: 7px;
  }
}

.statisticDiff-left {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>