<template>

  <div id="page-category-members-list">
    <loading v-if="loading"></loading>
    <div class="vx-card p-6" v-if="!loading">
      <!-- AgGrid Table -->
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="suite"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage" />

    </div>
  </div>

</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererLink from '../cell-renderer/CellRendererLink.vue'
import CellRendererResultsCount from '../cell-renderer/CellRendererResultsCount.vue'

import loading from '@/views/components/Loading.vue'

export default {
  props: ['isActive'],
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererResultsCount,

    loading
  },
  data () {
    return {
      // AgGrid
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        headerClass: 'text-left',
        cellStyle: {
          'text-align': 'left'
        }
      },
      columnDefs: [
        {
          headerName: this.$t('mailings.edit.users.table.avatar'),
          field: 'avatar_url',
          minWidth: 70,
          maxWidth: 95,
          cellRenderer: 'CellRendererLink'
        },
        {
          headerName: this.$t('mailings.edit.users.table.name'),
          field: 'name',
          headerClass: 'text-left',
          cellStyle: {
            'text-align': 'left'
          },
          filter: true,
          minWidth: 180,
          maxWidth: 400
        },
        {
          headerName: this.$t('mailings.edit.users.table.email'),
          field: 'email',
          filter: true,
          minWidth: 180,
          maxWidth: 400
        },
        {
          headerName: this.$t('mailings.edit.users.table.sent_at'),
          field: 'sent_at',
          minWidth: 180,
          sortable: true
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink
      },
      loading: true
    }
  },
  watch: {
    isActive (val) {
      if (val === true) {
        this.$store.dispatch('mailing/getUsers', { id: this.$route.params.id }).then(() => {
          this.loading = false
        })
      }
    }
  },
  computed: {
    suite () { return this.$store.getters['mailing/users'] },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    onGridReady (params) {
      this.gridApi = params.api
    }
  }
}

</script>

<style lang="scss">
  #page-clubadmins-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
  
  
</style>

<style lang="scss" scoped>
</style>
