import FormError from './../../modules/formError'

export default {
  suite: [],
  lists: [],
  segmentsOptions: [],
  select: [],
  edit: {
    id: null,
    name: '',
    slug: '',
    user: [],
    department: [],
    city: [],
    team: [],
    gender: []
  },
  formError: new FormError()
}
