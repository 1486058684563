import state from './modulePointSettingState.js'
import mutations from './modulePointSettingMutations.js'
import actions from './modulePointSettingActions.js'
import getters from './modulePointSettingGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
