/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from '../../http/requests/auth/jwt/index.js'

import axios from '@/axios.js'

import firebase from 'firebase/app'
import 'firebase/auth'

import router from '@/router'

import helpers from '@/helpers/helpers.js'

export default {
  loginAttempt ({ dispatch }, payload) {

    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation
    }

    if (!(process.env.VUE_APP_FIREBASE_AUTH_ACTIVATED === 'true')) {
      dispatch('loginServer', newPayload)
      return
    }

    // If remember_me is enabled change firebase Persistence
    if (!payload.checkbox_remember_me) {

      // Change firebase Persistence
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

        // If success try to login
        .then(function () {
          dispatch('login', newPayload)
        })

        // If error notify
        .catch(function (err) {

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 2500,
            title: 'Error',
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    } else {
      // Try to login
      dispatch('login', newPayload)
    }
  },
  login ({ commit, state, dispatch }, payload) {

    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()

      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })

      return false
    }

    // Try to sigin
    firebase.auth().signInWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)

      .then((result) => {

        // Set FLAG username update required for updating username
        let isUsernameUpdateRequired = false

        // if username is provided and updateUsername FLAG is true
        // set local username update FLAG to true
        // try to update username
        if (payload.updateUsername && payload.userDetails.displayName) {

          isUsernameUpdateRequired = true

          dispatch('updateUsername', {
            user: result.user,
            username: payload.userDetails.displayName,
            notify: payload.notify,
            isReloadRequired: true
          })
        }

        dispatch('loginServer', {
          accessToken: result.user.xa,
          uid: result.user.uid,
          notify: payload.notify,
          closeAnimation: payload.closeAnimation
        })

        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()

        // if username update is not required
        // just reload the page to get fresh data
        // set new user data in localstorage
        if (!isUsernameUpdateRequired) {
          commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
        }
      }, (err) => {

        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()

        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },

  loginServer ({commit}, payload) {
    let url = '/api/admin-panel/auth/login'
    let localPayload = {
      change_role: payload.hasOwnProperty('change_role') ? payload.change_role : false
    }
    if ((process.env.VUE_APP_FIREBASE_AUTH_ACTIVATED === 'true')) {
      const firebaseAuthPayload = {
        access_token: payload.accessToken,
        uid: payload.uid,
        user_data: payload.user_data
      }
      localPayload = Object.assign(localPayload, firebaseAuthPayload)
    } else {
      url = '/api/admin-panel/auth/local/login'
      localPayload = Object.assign(localPayload, payload.userDetails)
    }

    return new Promise((resolve, reject) => {
      axios.post(url, localPayload)
        .then((response) => {
          if (response.data.code === 10001) {
            commit('SET_FIREBASE_LOGIN_DATA', localPayload)
            router.push({ name: 'athlete-after-login' })
            return
          }
          // Set accessToken
          localStorage.setItem('accessToken', response.data.access_token)

          // Set refreshToken
          localStorage.setItem('refreshToken', response.data.refresh_token)

          // Set bearer token in axios
          commit('SET_BEARER', response.data.access_token)

          commit('SET_USER_INFO', response.data, {root: true})

          if (payload.closeAnimation) payload.closeAnimation()

          // Navigate User to homepage
          router.push(router.currentRoute.query.to || '/')
        })
        .catch((error) => {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 2500,
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    })
  },

  // Google Login
  loginWithGoogle ({commit, state, dispatch}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.GoogleAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
        dispatch('loginServer', {
          accessToken: result.user.xa,
          uid: result.user.uid,
          notify: payload.notify,
          closeAnimation: payload.closeAnimation,
          user_data: {
            name: result.user.providerData[0].displayName,
            avatar_url: result.user.providerData[0].photoURL
          }
        })
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },

  // Facebook Login
  loginWithFacebook ({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.FacebookAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },
  registerUser ({dispatch}, payload) {

    // create user using firebase
    firebase.auth().createUserWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
      .then(() => {
        payload.notify({
          title: 'Account Created',
          text: 'You are successfully registered!',
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })

        const newPayload = {
          userDetails: payload.userDetails,
          notify: payload.notify,
          updateUsername: true
        }
        dispatch('login', newPayload)
      }, (error) => {
        payload.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },
  localRegisterUser ({commit}, payload) {
    return new Promise(() => {
      axios.post('/api/admin-panel/auth/local/register', payload.userDetails)
        .then((response) => {
          // Set accessToken
          localStorage.setItem('accessToken', response.data.access_token)
          // Set refreshToken
          localStorage.setItem('refreshToken', response.data.refresh_token)
          // Set bearer token in axios
          commit('SET_BEARER', response.data.access_token)
          commit('SET_USER_INFO', response.data, {root: true})

          // Navigate User to homepage
          router.push(router.currentRoute.query.to || '/')
        })
        .catch((error) => {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 2500,
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    })
  },
  updateUsername ({ commit }, payload) {
    payload.user.updateProfile({
      displayName: payload.displayName
    }).then(() => {

      // If username update is success
      // update in localstorage
      const newUserData = Object.assign({}, payload.user.providerData[0])
      newUserData.displayName = payload.displayName
      commit('UPDATE_USER_INFO', newUserData, {root: true})

      // If reload is required to get fresh data after update
      // Reload current page
      if (payload.isReloadRequired) {
        router.push(router.currentRoute.query.to || '/')
      }
    }).catch((err) => {
      payload.notify({
        time: 8800,
        title: 'Error',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  },
  // JWT
  fetchAccessToken () {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => { resolve(response) })
    })
  },
  logout ({commit, rootGetters, dispatch}) {

    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/auth/logout')
        .then(() => {
          if (rootGetters.loggedById) {
            localStorage.setItem('accessToken', localStorage.getItem('oldAccessToken'))
            localStorage.setItem('refreshToken', localStorage.getItem('oldRefreshToken'))
            localStorage.removeItem('oldAccessToken')
            localStorage.removeItem('oldRefreshToken')
            commit('SET_BEARER', localStorage.getItem('accessToken'))
            commit('SET_USER_INFO', JSON.parse(localStorage.getItem('oldUserInfo')), {root: true})
            localStorage.removeItem('oldUserInfo')
            dispatch('getUserInfo', {}, {root: true}).then(() => {
              dispatch('dashboard/getStatistics', {}, {root: true})
              commit('setLoggedById', false, {root: true})
              router.push(router.currentRoute.query.to || '/')
            })
          } else {
            router.push('/pages/login')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    })
  },
  updatePassword ({commit}, payload) {
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    formData.append('_method', 'PATCH')
    return new Promise((resolve, reject) => {
      axios.post('/api/auth/local/password', formData)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  checkPasswordResetToken ({commit}, payload) {
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.post('/api/auth/local/check-password-reset-token', formData)
        .then((response) => {
          commit('setPasswordResetTokenExists', response.data.data.exists)
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  }
}
