import state from './moduleTagState.js'
import mutations from './moduleTagMutations.js'
import actions from './moduleTagActions.js'
import getters from './moduleTagGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
