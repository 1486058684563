import axios from '@/axios.js'

export default {
  getManagerEmails ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/shop/settings')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/shop/settings', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  }
}
