<template>
  <div>
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-else>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <span>{{$t('users.edit.steps.labels.count')}}</span>
          <vs-input class="w-full mb-4" :label-placeholder="$t('users.edit.steps.labels.count')" v-model="count" />
          <span class="text-danger text-sm" v-show="formError.has('count')">{{ $t(formError.get('count')) }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <span>{{$t('users.edit.steps.labels.change_challenge')}}</span>
          <v-select
            selected
            :options="challenges"
            v-model="change_challenge"
            label="title"
          />
          <span class="text-danger text-sm" v-show="formError.has('notice')">{{ $t(formError.get('change_challenge')) }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mt-2" @click="action()" :disabled="actionButtonDisabled">{{ $t('users.edit.steps.create.buttons.create') }}</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from '@/views/components/Loading.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {Russian as ru} from 'flatpickr/dist/l10n/ru.js'
import moment from 'moment'
import vSelect from 'vue-select'

export default{
  components: {
    flatPickr,
    loading,
    'v-select': vSelect
  },
  data () {
    return {
      id: 0,
      actionButtonDisabled: false,
      loading: true,
      count: '',
      change_challenge: '',
      dateFormat: 'dd.MM.yyyy H:i:s',
      datepicker: {
      },
      configdateTimePicker: {
        enableTime: true,
        enableSeconds: true,
        dateFormat: 'd.m.Y H:i:s',
        locale: this.$i18n.locale,
        time_24hr: true
      }
    }
  },
  mounted () {
    this.getChallenges()
  },
  computed: {
    challenges () {
      return this.$store.getters['user/challenges']
    },
    formError () {
      return this.$store.getters['user/formError']
    }
  },
  methods: {
    action () {
      this.actionButtonDisabled = true
      this.create()
    },
    getChallenges () {
      this.$store.dispatch('user/getChallenges', {user_id: this.$route.params.user_id}).then(() => {
        this.change_challenge = this.challenges[0]
      })
    },
    create () {
      this.actionButtonDisabled = true
      const payload = {
        count: this.count,
        challenge_id: this.change_challenge.id,
        user_id: this.$route.params.user_id
      }
      this.$store.dispatch('user/createSteps', payload)
        .then(() => {
          this.actionButtonDisabled = false
          this.$router.push({ name: 'steps' })
        })
        .catch(() => {
          this.actionButtonDisabled = false
        })
    }
  },
  created () {
    this.loading = false
    this.$store.commit('user/clearFormError')
  }
}
</script>

<style>
</style>
