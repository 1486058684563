<template>

  <div id="page-category-members-list">
    <loading v-if="loading"></loading>
    <div class="vx-card p-6" v-if="!loading">
      <div class="clearfix">
        <div class="float-right">
          <vs-button color="primary" type="filled" @click="$store.dispatch('category/downloadMembers', { id: $route.params.id })">{{ $t('categories.suite.buttons.download_members_file') }}</vs-button>
        </div>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        @gridReady="onGridReady"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4"
        domLayout="autoHeight"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="suite"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage" />

    </div>
  </div>

</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererLink from '../cell-renderer/CellRendererLink.vue'
import CellRendererResultsCount from '../cell-renderer/CellRendererResultsCount.vue'

import loading from '@/views/components/Loading.vue'

export default {
  props: ['isActive'],
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererResultsCount,

    loading
  },
  data () {
    return {
      // AgGrid
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: { 
          'textAlign': 'left',
          'display': 'flex',
          'justify-content': 'flex-start'
        }
      },
      columnDefs: [
        {
          headerName: this.$t('categories.edit.members.table.avatar'),
          field: 'avatar_url',
          width: 95,
          maxWidth: 95,
          cellStyle: { 
            'textAlign': 'center',
            'justify-content': 'center'
          },
          headerClass: 'text-center',
          cellRenderer: 'CellRendererLink'
        },
        {
          headerName: this.$t('categories.edit.members.table.name'),
          field: 'name',
          filter: true,
          minWidth: 150
        },
        {
          headerName: this.$t('categories.edit.members.table.city'),
          field: 'city',
          filter: true,
          minWidth: 150
        },
        {
          headerName: this.$t('categories.edit.members.table.department'),
          field: 'department',
          filter: true,
          minWidth: 100
        },
        {
          headerName: this.$t('categories.edit.members.table.training.result.count'),
          field: 'training.result.count',
          minWidth: 180,
          maxWidth: 180,
          cellStyle: { 
            'textAlign': 'center',
            'justify-content': 'center'
          },
          headerClass: 'text-center',
          cellRenderer: 'CellRendererResultsCount',
          cellRendererParams: {
            trainingsCount: this.$store.getters['category/membersTrainingsCount']
          }
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink
      },
      loading: true
    }
  },
  watch: {
    isActive (val) {
      if (val === true) {
        this.$store.dispatch('category/getMembers', { id: this.$route.params.id }).then(() => {
          this.loading = false
          this.columnDefs[4].cellRendererParams.trainingsCount = this.$store.getters['category/membersTrainingsCount']
        })
      }
    }
  },
  computed: {
    suite () { return this.$store.getters['category/members'] },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    trainingsCount () {
      return this.$store.getters['category/membersTrainingsCount']
    }
  },
  methods: {
    onGridReady (params) {
      this.gridApi = params.api
    }
  }
}

</script>

<style lang="scss">
  #page-clubadmins-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
</style>
