import FormError from '../../../modules/formError'

export default {
  suite: [],
  formError: new FormError(),
  edit: {
    title: '',
    image_path: '',
    preview: '',
    content: '',
    tags: [],
    is_active: false
  }
}
