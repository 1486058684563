<template>
  <div id="demo-basic-card">
    <div v-if="templates" class="flex flex-wrap gap-6">
        <article v-for="template in templates" :key="template.name" class="flex flex-col bg-white shadow rounded-lg p-6 sm:w-1/2 lg:w-1/3 min-w-sm max-w-sm basis-72 flex-1">
          <h1 class="font-bold text-base pb-2">{{ $t(`shop_products.template.${template.name}.name`) }}</h1>
          <p class="text-gray-700 text-xs text-[#626262] mb-8">{{$t(`shop_products.template.${template.name}.description`)}}</p>
          <button @click="goToTemplate(template)" class="mt-auto ml-auto rounded-lg py-3 px-6 text-center text-white bg-white text-orange cursor-pointer border-orange text-base self-start">{{$t('shop_products.template.select_template')}}</button>
        </article>

    </div>
  </div>
</template>

<script>

export default{

  data () {
    return {
    }
  },
  computed: {
    templates () {
      const data = this.$store.getters['shop_product/templates']
      console.log('templates', data)
      return data || []
    }
  },
  methods: {
    goToTemplate (template) {

      localStorage.setItem('activeTemplate', JSON.stringify(template))

      this.$router.push({name: 'shop-products-create'})
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
