<template>
  <div id="demo-basic-card">
    <div class="">
      <template>
        <div class="vx-row mb-4">
          <div class="vx-col md:w-1/2 w-full">
            <vs-button
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-plus"
              class="add-button"
              @click="$router.push({ name: `services-create` })"
              >{{ $t(`services.create`) }}</vs-button
            >
          </div>
          <div class="vx-col md:w-1/2 w-full mb-base flex justify-end">
            <vs-button color="primary" type="border" icon-pack="feather" icon="icon-list" @click="showSortTable = !showSortTable">{{$t('services.buttons.sort')}}</vs-button>
          </div>
        </div>
      </template>

      <loading v-if="loading"></loading>
      <div class="vx-col w-full mb-base" v-if="!showSortTable && !loading">

        <div class="vx-card p-6">
          <!-- AgGrid Table -->
          <ag-grid-vue
            @gridReady="onGridReady"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4"
            domLayout="autoHeight"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="suite"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl"
          />

          <vs-pagination v-if="totalPages > 1"
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage" />

        </div>

        <vs-popup class="holamundo" title="" :active.sync="deleteItemPopupActive">
          <p>{{ deleteItemPopupContent }}</p>
          <div v-if="deleteProcess">
            <font-awesome-icon icon="spinner" spin />
          </div>
          <div class="delete-confirm-popup-buttons" v-else>
            <vs-button color="primary" type="filled" @click="deleteItem()">{{ $t('users.suite.popups.confirm_delete_user.buttons.confirm') }}</vs-button>
            <vs-button color="warning" type="filled" @click="deleteItemPopupActive=false">{{ $t('users.suite.popups.confirm_delete_user.buttons.cancel') }}</vs-button>
          </div>
        </vs-popup>
      </div>
      <div v-if="showSortTable && !loading" class="sort-table-wrapper">
        <vs-list>
          <draggable :list="suite" :options="{animation: 150}" @change="sort()">
            <transition-group tag="ul">
              <vs-list-item class="list-item" v-for="service in suite" :key="service.id" :title="service.name">
                <vs-avatar
                  slot="avatar"
                  :src="getUrl(service.icon)
                  "
                />
              </vs-list-item>
            </transition-group>
          </draggable>
        </vs-list>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererLink from '../../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../../components/cell-renderer/services/CellRendererActions.vue'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)

export default {
  components: {
    AgGridVue,
    // Cell Renderer
    CellRendererLink,
    CellRendererActions,

    draggable,
    loading
  },
  data () {
    return {
      loading: true,
      showSortTable: false,
      // AgGrid
      gridApi: null,
      columnApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: {'display': 'flex', 'align-items': 'center'},
        headerClass: 'text-center'
      },
      columnDefs: [
        {
          headerName: '',
          cellRenderer: params => {
            const imageUrl = params.data.image_path_168
            return `<div class="call-achievement items-center">
                     <img class="icon-achievement rounded-full" src='${this.getUrl(imageUrl)}'></img>
                    </div>`
          },
          cellStyle: {'justify-content': 'center'},
          minWidth: 40,
          maxWidth: 40
        },
        {
          headerName: this.$t('services.table.name'),
          field: 'name',
          minWidth: 250,
          maxWidth: 600,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7'},
          headerClass: 'text-left',
          filter: true
        },
        {
          headerName: this.$t('services.table.tags'),
          autoHeight: true,
          minWidth: 180,
          maxWidth: 230,
          cellStyle: {'line-height': '1.7', 'justify-content': 'center'},
          cellRenderer: params => {
            const tags = params.data.tags || []
            let tagsHtml = ''
            for (const element of tags) {
              tagsHtml += `<span class="tag">${element.label}</span><br>`
            }
            return tagsHtml
          }
        },
        {
          headerName: this.$t('services.table.segments'),
          autoHeight: true,
          minWidth: 180,
          maxWidth: 230,
          cellStyle: {'line-height': '1.7', 'justify-content': 'center'},
          cellRenderer: params => {
            const segments = params.data.segments || []
            let segmentsHtml = ''
            for (const element of segments) {
              segmentsHtml += `<span class="tag">${element.label}</span><br>`
            }
            return segmentsHtml
          }
        },
        {
          headerName: this.$t('services.table.status'),
          field: 'status',
          sortable: true,
          minWidth: 140,
          maxWidth: 140,
          cellRenderer: ({data: {status, is_published}}) => {
            const bgColor = is_published ? '#DA7E3C' : '#808080'
            return `<div 
                      style="
                        justify-content: center;
                        text-align: center;
                        border-radius: 4px;
                        color: white;
                        line-height: 2;
                        width: 100px;
                        background-color: ${bgColor};"
                      >
                      ${status}
                    </div>`
          }
        },
        {
          headerName: this.$t('personal_goals.table.actions'),
          field: 'id',
          minWidth: 120,
          maxWidth: 120,
          autoHeight: true,
          cellRenderer: 'CellRendererActions',
          cellStyle: {'justify-content': 'center'},
          sortable: false
        }
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deleteItemPopupActive: false,
      deleteItemPopupContent: '',
      deleteProcess: false,
      deleteItemId: 0
    }     
  },
  methods: {
    getStatus (data) {
      data.forEach(item => {
        if (item.is_published === 1) {
          item.status = this.$t('personal_goals.suite.title.active')
        } else {
          item.status = this.$t('personal_goals.suite.title.not_active')
        }
      })
    },
    updateSuite () {
      this.suite = JSON.parse(JSON.stringify(this.$store.getters['services/suite']))
      this.loading = false
      this.getStatus(this.suite)
      
    },
    deleteItem () {
      this.deleteProcess = true
      this.$store.dispatch('services/delete', { id: this.deleteItemId }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.deleteProcess = false
        this.deleteItemPopupActive = false
      })
    },

    sort () {
      const payload = {
        suite: this.suite
      }
      this.$store.dispatch('services/sort', payload)
    },

    //table
    onGridReady (params) {
      this.gridApi = params.api
    }
  },

  computed: {
    suite () {
      const data = this.$store.getters['services/suite']
      this.getStatus(data)
      return data
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages: {
      get () {
        return this.gridApi ? this.gridApi.paginationGetTotalPages() : 0
      },
      set () { 
      }
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },

  created () {
    this.$store.dispatch('services/getSuite').then(() => {
      this.loading = false
    })
  }

}
</script>

<style lang="scss" scoped>

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.card ::v-deep > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > div:first-child {
    margin-bottom: auto;
  }
  .vx-card__body {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.buttons-wrapper {
  margin-bottom: 30px;
}
.buttons-wrapper > button {
  display: inline-block;
  margin-right: 30px;
}
.sort-table-wrapper {
  width: 100%;
}
.list-item {
  transition: all 1s;
}
.list-item:hover {
  cursor: all-scroll;
}

::v-deep {
  .call-achievement {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-width: 52px;
    .row-number {
      line-height: 1;
    }
    .icon-achievement {
      width: 28px;
      height: 28px;
      object-fit: contain;
    }
  }
  
 .ag-cell-wrap-text {
   word-break: break-word;
 }
}

</style>
