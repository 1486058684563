/*=========================================================================================
  File Name: moduleAuthGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  loginData (state) {
    return state.loginData
  },
  passwordResetTokenExists (state) {
    return state.passwordResetTokenExists
  },
  formError (state) {
    return state.formError
  }
}
