import state from './moduleShopSettingsState.js'
import mutations from './moduleShopSettingsMutations.js'
import actions from './moduleShopSettingsActions.js'
import getters from './moduleShopSettingsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
