<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <template v-else>
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-card tabs-container px-6 pt-6 pb-3">
        <div class="vx-row">
          <div class="vx-col w-full mb-4">
            <vs-input class="w-full" :label-placeholder="$t('content_pages.' + type + '.labels.slug')" v-model="slug" />
            <span class="text-danger text-sm" v-show="formError.has('slug')">{{ $t(formError.get('slug')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-4">
            <vs-input class="w-full" :label-placeholder="$t('content_pages.' + type + '.labels.name')" v-model="name" />
            <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <v-select
                v-if="slug==='statistic-time'"
                v-model="content"
                :options="typeOptions"
                label="label"  :reduce="value => value.value"
                v-validate="'required'"
                :dir="$vs.rtl ? 'rtl' : 'ltr'" />
  
            <vs-textarea class="w-full mb-2"
                :label="$t('content_pages.' + type + '.labels.content')"
                v-if="(id !== 25) && (slug !== 'statistic-time')"
                v-model="content" />

            <vs-input 
                class="w-full mb-2" 
                v-if="id===25" 
                type="date"
                :label="$t('content_pages.' + type + '.labels.content')" 
                v-model="content" />

            <span class="text-danger text-sm" v-show="formError.has('content')">{{ $t(formError.get('content')) }}</span>
          </div>
        </div>
        <div class="vx-row" v-if="loaded">
          <div class="vx-col w-full mb-2">
            <vx-card no-shadow card-border :title="$t('content_pages.' + type + '.labels.block_content')">
              <editor ref="editor" :config="editorJsConfig" />
              <span class="text-danger text-sm" v-show="formError.has('block_content')">{{ $t(formError.get('block_content')) }}</span>
            </vx-card>
          </div>
        </div>
        <vs-popup class="holamundo" title="" :active.sync="ErrorParsePopupActive">
          <p>{{ $t('content_pages.edit.error_parse.content') }}</p>
          <div class="error-parse-button-confirm">
            <vs-button color="warning" type="filled" @click="ErrorParsePopupActive=false">{{ $t('content_pages.edit.error_parse.button_confirm') }}</vs-button>
          </div>
        </vs-popup>
      </div>
    </template>
    <div v-if="!isOnlyRead" class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button 
            @click="action(stayAfter = true)" 
            :disabled="actionButtonDisabled">
            {{ $t('content_pages.' + type + '.buttons.' + type) }}
          </vs-button>
          <vs-button 
            @click="action()" 
            :disabled="actionButtonDisabled">
            {{ $t('content_pages.' + type + '.buttons.' + type) }} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import editorJsConfig from '@/editorjs/config.js'

import loading from '@/views/components/Loading.vue'
import vSelect from 'vue-select'
import helpers from '@/helpers/helpers.js'

export default{
  components: {
    quillEditor,
    loading,
    'v-select': vSelect
  },
  data () {
    return {
      ErrorParsePopupActive: false,
      id: 0,
      slug: '',
      name: '',
      content: '',
      blockContent: null,
      editorJsConfig: Object.assign({}, editorJsConfig),
      type: 'create',
      actionButtonDisabled: false,
      loaded: false,
      loading: true,
      typeOptions: [
        { label: this.$t('content_pages.type.one_in_week'),  value: '1 in week' },
        { label: this.$t('content_pages.type.two_in_week'),  value: '2 in week' },
        { label: this.$t('content_pages.type.one_in_month'),  value: '1 in month' }
      ]
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-settings-content-pages'] === 'read' ||  
               accessUser['admin-settings'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['content_page/formError']
    }
  },
  methods: {
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true

      const patch = (this.type === 'create') ? 'content_page/create' : 'content_page/update'
      this.dispatch(patch, stayAfter)
    },
    async dispatch (patch, stayAfter) {
      try {
        const blockDescription = await this.$refs.editor.state.editor.save()
        const payload = {
          id: this.id,
          name: this.name,
          slug: this.slug,
          content: this.content,
          block_content: JSON.stringify(blockDescription)
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name: 'content-pages'
          },
          routerPushToEdit: {
            name: 'edit-content-page'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    }
  },
  created () {
    if (this.$router.currentRoute.name === 'edit-content-page') {
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('content_page/edit', { id: this.id })
        .then(() => {
          const edit = this.$store.getters['content_page/edit']
          this.slug = edit.slug
          this.name = edit.name
          this.content = edit.content
          this.blockContent = edit.blockContent
          if (this.blockContent !== '') {
            try {
              this.editorJsConfig.data = JSON.parse(this.blockContent)
            } catch (e) {
              this.ErrorParsePopupActive = true
              this.editorJsConfig.data = ''
            }
          }
          this.loaded = true
          this.loading = false
        })
    } else {
      this.loaded = true
      this.loading = false
    }
    this.$store.commit('content_page/clearFormError')
  }
}
</script>

<style>
  .image-container .preview-container {
    margin: 0!important;
  }

  .error-parse-button-confirm {
    margin-top: 20px;
  }
</style>
