<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <div v-if="!loading" class="vx-col md:w-1/2 w-full">
        <vs-button color="primary" type="gradient" icon-pack="feather" icon="icon-plus" class="add-button" @click="$router.push({ name: 'blog-create-post'})" :disabled="isOnlyRead"></vs-button>
      </div>
      <div v-if="!isOnlyRead" class="vx-col md:w-1/2 w-full mb-base flex justify-end">
        <vs-button color="primary" type="border" icon-pack="feather" icon="icon-list" @click="showSortTable = !showSortTable">{{$t('blog.posts.suite.sort')}}</vs-button>
      </div>


      <div class="vx-col w-full mb-base">
        <loading v-if="loading"></loading>
        <vs-table v-if="!showSortTable&&!loading" :data="suite">

          <template slot="thead">
            <vs-th>{{ $t('blog.posts.suite.table.title') }}</vs-th>
            <vs-th><nobr>{{ $t('blog.posts.suite.table.publication_at') }}</nobr></vs-th>
            <vs-th class="center" colspan="2">{{ $t('blog.posts.suite.table.actions') }}</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td :data="data[indextr].title">
                {{ data[indextr].title }}
              </vs-td>

              <vs-td :data="data[indextr].publication_at">
                {{ formattedDate(data[indextr].publication_at) }}
              </vs-td>

              <vs-td :data="data[indextr].date" width="15" class="px-3">
                <div type="line"  @click="$router.push({ name: 'blog-edit-post', params: { id: data[indextr].id }})" class="text-center"><feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" /></div>
              </vs-td>

              <vs-td :data="data[indextr].date" width="15" class="px-3">
                <div v-if="!isOnlyRead" type="line" @click="openDeletePopup(data[indextr].id, indextr, data[indextr].title)" class="text-center"><feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" /></div>
              </vs-td>

            </vs-tr>
          </template>


        </vs-table>

        <div v-if="showSortTable&&!loading" class="sort-table-wrapper">
          <vs-list>
            <draggable :list="suite" @change="sort()">
              <transition-group>
                <vs-list-item class="list-item" v-for="blog_post in suite" :key="blog_post.id" :title="blog_post.title">
                </vs-list-item>
              </transition-group>
            </draggable>
          </vs-list>
        </div>
      </div>
    </div>

    <vs-popup class="holamundo" :title="$t('blog.posts.suite.popups.delete.title')" :active.sync="deletePopupActive">
      <p>{{ deletePopupContent }}</p>
      <div class="delete-confirm-popup-buttons" v-if="!showDeletePopupWaitMessage">
        <vs-button color="primary" type="filled" @click="deletePost" :disabled="isOnlyRead">{{ $t('blog.posts.suite.popups.delete.buttons.confirm') }}</vs-button>
        <vs-button color="warning" type="filled" @click="deletePopupActive=false">{{ $t('blog.posts.suite.popups.delete.buttons.cancel') }}</vs-button>
      </div>
      <p v-if="showDeletePopupWaitMessage" class="delete-popup-wait-message">{{ $t('blog.posts.suite.popups.delete.wait_message') }} <font-awesome-icon icon="spinner" spin /></p>
    </vs-popup>

  </div>
</template>

<script>
import loading from '@/views/components/Loading.vue'
import draggable from 'vuedraggable'
import moment from 'moment'

export default{
  components: {
    draggable,
    loading
  },
  data () {
    return {
      showSortTable: false,
      loading: true,
      deletePopupActive: false,
      showDeletePopupWaitMessage: false,
      deletePopupContent: '',
      deleteAchievementId: 0,
      deleteAchievementIndex: 0
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-blog'] === 'read'
      }
    },
    suite () {
      return this.$store.getters['blog_post/suite']
    }
  },
  methods: {
    openDeletePopup (id, index, name) {
      this.deletePopupContent = this.$t('blog.posts.suite.popups.delete.message').replace('[NAME]', name)
      this.deleteAchievementId = id
      this.deleteAchievementIndex = index
      this.deletePopupActive = true
    },
    sort () {
      const payload = {
        suite: this.suite
      }
      this.$store.dispatch('blog_post/sort', payload)
    },
    formattedDate (date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    },
    deletePost () {
      this.showDeletePopupWaitMessage = true
      this.$store.dispatch('blog_post/destroy', { id: this.deleteAchievementId, index: this.deleteAchievementIndex }).catch(error => {
        this.$vs.notify({
          title: 'Server error',
          text: error,
          color: 'danger'
        })
      }).then(() => {
        this.deletePopupActive = false
        this.showDeletePopupWaitMessage = false
      })
    }
  },
  created () {
    this.$store.dispatch('blog_post/getSuite').then(() => {
      this.loading = false
    })
  }
}
</script>

<style lang="scss">
  .add-button {
    margin-bottom: 30px;
  }
  .delete-confirm-popup-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  .delete-popup-wait-message {
    margin-top: 30px;
  }
  .sort-table-wrapper {
    width: 100%;
  }
  .list-item {
    transition: all 1s;
  }
  .list-item:hover {
    cursor: all-scroll;
  }

  .center .vs-table-text{
    justify-content: center;
  }

</style>
