import state from './moduleContentPageState.js'
import mutations from './moduleContentPageMutations.js'
import actions from './moduleContentPageActions.js'
import getters from './moduleContentPageGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
