<!-- =========================================================================================
  File Name: Announcements.vue
  Description: Announcements List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="demo-basic-card">
    <div class="vx-row">

      <div v-if="!isOnlyRead" class="vx-col md:w-1/2 w-full">
        <vs-button color="primary" type="gradient" icon-pack="feather" icon="icon-plus" class="add-button" @click="$router.push({ name: 'announcements-create'})"></vs-button>
      </div>
      <div v-if="!isOnlyRead" class="vx-col md:w-1/2 w-full mb-base flex justify-end flex-1">
        <vs-button color="primary" type="border" icon-pack="feather" icon="icon-list" @click="showSortTable = !showSortTable">{{$t('announcements.sort')}}</vs-button>
      </div>

      <loading v-if="loading"></loading>

      <div class="vx-col w-full mb-base" v-if="!showSortTable&&!loading">

        <div class="vx-card p-6" v-if="!loading">
          <!-- AgGrid Table -->
          <ag-grid-vue
            @gridReady="onGridReady"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4"
            domLayout="autoHeight"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="suite"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl"
          />

          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage" />

        </div>

        <vs-popup class="holamundo" title="" :active.sync="deleteAnnouncementPopupActive">
          <p>{{ deleteAnnouncementPopupContent }}</p>
          <div v-if="deleteProcess">
            <font-awesome-icon icon="spinner" spin />
          </div>
          <div class="delete-confirm-popup-buttons" v-else>
            <vs-button color="primary" type="filled" @click="deleteAnnouncement()">{{ $t('users.suite.popups.confirm_delete_user.buttons.confirm') }}</vs-button>
            <vs-button color="warning" type="filled" @click="deleteAnnouncementPopupActive=false">{{ $t('users.suite.popups.confirm_delete_user.buttons.cancel') }}</vs-button>
          </div>
        </vs-popup>

      </div>

      <div v-if="showSortTable&&!loading" class="sort-table-wrapper">
        <vs-list>
          <draggable :list="suite" @change="sort()">
            <transition-group>
              <vs-list-item class="list-item" v-for="announcement in suite" :key="announcement.id" :title="announcement.title">
                <vs-avatar slot="avatar" :src="(announcement.image_path)" />
              </vs-list-item>
            </transition-group>
          </draggable>
        </vs-list>
      </div>

    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import moment from 'moment'

// Cell Renderer
import CellRendererLink from '../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../components/cell-renderer/announcements/CellRendererActions.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)

export default{
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,

    loading,
    draggable
  },
  data () {
    return {
      showSortTable: false,
      loading: true,
      // AgGrid
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: { 
          'text-align': 'center'
        }
      },
      columnDefs: [
        {
          headerName: this.$t('announcements.table.id'),
          field: 'id',
          minWidth: 50,
          maxWidth: 70,
          sortable: true
        },
        {
          headerName: this.$t('announcements.table.title'),
          field: 'title',
          minWidth: 200,
          maxWidth: 400,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'text-align': 'left', 'word-break': 'break-word'},
          filter: true
        },
        {
          headerName: this.$t('announcements.table.content'),
          field: 'content',
          minWidth: 200,
          maxWidth: 800,
          flex: 3,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'text-align': 'left', 'word-break': 'break-word'},
          filter: true
        },
        {
          headerName: this.$t('announcements.table.date'),
          field: 'created_at',
          valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD.MM.YYYY') : ''
          },
          minWidth: 150,
          maxWidth: 170,
          sortable: true,
          headerClass: 'text-center'
        },
        {
          headerName: this.$t('announcements.table.deactivate_at'),
          field: 'deactivate_at',
          valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD.MM.YYYY') : ''
          },
          minWidth: 170,
          maxWidth: 190,
          sortable: true
        },
        {
          headerName: this.$t('announcements.table.actions'),
          field: 'id',
          minWidth: 108,
          maxWidth: 108,
          cellRenderer: 'CellRendererActions'
        }
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deleteAnnouncementPopupActive: false,
      deleteAnnouncementPopupContent: '',
      deleteProcess: false,
      deleteAnnouncementId: 0
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters['accessUser']) {
        return this.$store.getters.accessUser['admin-announcements'] === 'read'
      }
    },
    suite () { return this.$store.getters['announcement/suite'] },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },
  created () {
    this.$store.dispatch('announcement/getSuite').then(() => {
      this.loading = false
    })
  },
  methods:{
    deleteAnnouncement () {
      this.deleteProcess = true
      this.$store.dispatch('announcement/delete', { id: this.deleteAnnouncementId }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.deleteProcess = false
        this.deleteAnnouncementPopupActive = false
      })
    },
    sort () {
      const payload = {
        suite: this.suite
      }
      this.$store.dispatch('announcement/sort', payload)
    },
    onGridReady (params) {
      this.gridApi = params.api
    }
  }
}
</script>

<style lang="scss" scoped>
  .add-button {
    margin-bottom: 30px;
  }
  .delete-confirm-popup-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  .buttons-wrapper {
    margin-bottom: 30px;
  }
  .buttons-wrapper > button{
    display: inline-block;
    margin-right: 30px;
  }
  .sort-table-wrapper {
    width: 100%;
  }
  .list-item {
    transition: all 1s;
  }
  .list-item:hover {
    cursor: all-scroll;
  }
</style>
