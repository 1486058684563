<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>

    <div v-show="!loading" class="vx-card tabs-container px-6 pt-6 pb-3">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-input class="w-full" :label-placeholder="$t('faq.' + type + '.labels.title')" v-model="title" />
          <span class="text-danger text-sm" v-show="formError.has('title')">{{ $t(formError.get('title')) }}</span>
        </div>
      </div>


    <div class="vx-row" v-if="loaded">
      <div class="vx-col w-full mb-2">
        <vx-card no-shadow card-border :title="$t('faq.' + type + '.labels.content')">
          <editor ref="editor" :config="editorJsConfig" />
          <span class="text-danger text-sm" v-show="formError.has('content')">{{ $t(formError.get('content')) }}</span>
        </vx-card>
      </div>
    </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <div class="image-container" style="width: 150px; height:150px; margin: 0 auto;">
            <picture-input
              ref="pictureInput"
              @prefill="onImgPrefill"
              @change="onImgChange"
              @error="onImgError"
              width="150"
              height="150"
              margin="16"
              :accept="this.$baseImageAcceptTypes"
              buttonClass="btn"
              size="10"
              :hideChangeButton="true"
              :crop="false"
              :prefill="imageUrl"
              :alertOnError="false"
              :customStrings="{
              drag: $t('faq.' + type + '.labels.image'),
            }"
            >
            </picture-input>
            <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
          </div>
        </div>
      </div>
      <div v-if="imgCrop" class="vx-row">
        <div class="vx-col w-full mb-2">
          <div class="image-crop-container">
            <cropper
              ref="cropper"
              classname="cropper"
              :src="imgCrop"
              :default-size="cropperDefaultSize"
              :resizeImage="{ wheel: false }"
              @change="changeCropImg"
              :stencil-props="{
                aspectRatio: 1 / 1,
                }"
            ></cropper>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
        <v-select
          v-model="groups"
          :options="groupsOptions"
          :selectable="option => groups.indexOf(option) < 0"
          :reduce="option => typeof option === 'string' ? { label: option } : option"
          multiple
          taggable
          push-tags
          placeholder="Группы"
        />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('faq.' + type + '.labels.is_published') }}</label>
          <vs-switch v-model="isPublished" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
          <span class="text-danger text-sm" v-show="formError.has('is_published')">{{ $t(formError.get('is_published')) }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('faq.' + type + '.labels.is_display_preview') }}</label>
          <vs-switch v-model="isDisplayPreview" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
          <span class="text-danger text-sm" v-show="formError.has('is_display_preview')">{{ $t(formError.get('is_display_preview')) }}</span>
        </div>
      </div>

    </div>
    <div class="vx-row fixed-buttons">
      <div class="vx-col w-full flex gap-3">
        <vs-button 
          @click="action(stayAfter = true)" 
          :disabled="actionButtonDisabled || isOnlyRead">
          {{ $t('faq.' + type + '.buttons.' + type) }}
        </vs-button>

        <vs-button 
          @click="action()" 
          :disabled="actionButtonDisabled || isOnlyRead">
          {{ $t('faq.' + type + '.buttons.' + type) }} {{ $t('suite.and_exit') }}
        </vs-button>
      </div>
    </div>

    <vs-popup class="holamundo" title="" :active.sync="ErrorParsePopupActive">
      <p>{{ $t('faq.edit.error_parse.content') }}</p>
      <div class="error-parse-button-confirm">
        <vs-button color="warning" type="filled" @click="ErrorParsePopupActive=false">{{ $t('faq.edit.error_parse.button_confirm') }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import loading from '@/views/components/Loading.vue'
import editorJsConfig from '@/editorjs/config.js'
import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import helpers from '@/helpers/helpers.js'
import vSelect from 'vue-select'

export default {
  components: {
    PictureInput,
    Cropper,
    loading,
    vSelect
  },
  data () {
    return {
      ErrorParsePopupActive: false,
      loading: true,
      type: 'create',
      title: '',
      loaded: false,
      editorJsConfig: Object.assign({}, editorJsConfig),
      isPublished: false,
      content: '',
      id: 0,
      groupsOptions: [],
      groups: [],
      imageUrl: '',
      imgCrop: null,
      image: null,
      imgWidth: null,
      imgHeight: null,
      isChangedImg: false,
      actionButtonDisabled: false,
      isDisplayPreview: false
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-settings'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['faq/formError']
    }
  },
  methods: {
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'faq/store' : 'faq/update'
      this.dispatch(patch, stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        const blockDescription = await this.$refs.editor.state.editor.save()
        const payload = {
          id: this.id,
          title: this.title,
          content: JSON.stringify(blockDescription),
          groups: this.groups,
          is_published: +this.isPublished,
          is_display_preview: +this.isDisplayPreview,
          image:
            (this.isChangedImg && this.image !== null) ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : null
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name: 'faq'
          },
          routerPushToEdit: {
            name: 'faq-edit'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    },
    onImgPrefill () {
      this.imgCrop = this.$refs.pictureInput.image
      this.image = this.imgCrop
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    changeCropImg ({coordinates, canvas}) {
      if (!this.imgWidth && !this.imgHeight) {
        this.imgWidth = coordinates.width
        this.imgHeight = coordinates.height
      }
      if ((coordinates.width === this.imgWidth || coordinates.height === this.imgHeight) && this.type === 'edit' && this.imageUrl) {
        this.isChangedImg = false
      } else {
        this.isChangedImg = true
      }
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    }
  },
  created () {
    this.$store.dispatch('faq/getGroupsOptions').then(() => {
      this.groupsOptions = this.$store.getters['faq/groupsOptions']
    })
    this.id = this.$route.params.id
    if (this.$router.currentRoute.name === 'faq-edit') {
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('faq/edit', {id: this.id})
        .then(() => {
          const edit = this.$store.getters['faq/edit']
          this.id = edit.id
          this.title = edit.title
          this.groups = edit.groups
          this.isPublished = edit.isPublished
          this.isDisplayPreview = edit.isDisplayPreview
          this.loading = false
          this.loaded = true
          if (edit.content !== '') {
            try {
              this.editorJsConfig.data = JSON.parse(edit.content)
            } catch (e) {
              this.ErrorParsePopupActive = true
              this.editorJsConfig.data = ''
            }
          }

          this.imageUrl = this.getUrl(edit.imagePath, `${this.$http.defaults.baseURL}/api/admin-panel`)

        })
    } else {
      this.loading = false
      this.loaded = true
    }
    this.$store.commit('faq/clearFormError')
  }
}
</script>

<style scoped>
.error-parse-button-confirm {
  margin-top: 20px;
}
</style>
