/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '../../http/axios/index.js'

export default {
  SET_BEARER (state, accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  },
  SET_FIREBASE_LOGIN_DATA (state, data) {
    if ((process.env.VUE_APP_FIREBASE_AUTH_ACTIVATED === 'true')) {
      data.accessToken = data.access_token
      delete data.access_token
    }
    state.loginData = data
  },
  SET_CHANGE_ROLE_TO_LOGIN_DATA (state) {
    state.loginData.change_role =  true
  },
  setPasswordResetTokenExists (state, exists) {
    state.passwordResetTokenExists =  exists
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  }
}
