<template>
  <div>
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-32 mt-32">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-show="!loading">
      <div v-if="isPrograms" class="vx-row  mb-6">
        <div class="vx-col flex items-center gap-4">
          <span class=" text-lg font-bold">{{ $t(`categories.template.${typeProgram}.name`) }}</span>
          <a type="button" @click="$router.push({ name: 'choose-type-category' })"
            class="inline-block rounded-lg shadow-md cursor-pointer font-bold text-xs bg-white py-2 px-4">
            {{ $t(`categories.template.buttons.to_change`) }}
          </a>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col flex w-1/4 mb-4">
          <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"></vs-switch>
          <label class="ml-4 text-lg" :class="{ 'text-orange': isActive }">{{ $t('categories.edit.labels.is_active')
          }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_active')">{{
            $t(formError.get('is_active'))
          }}</span>
        </div>
      </div>
      <content-filter></content-filter>
      <vx-card :title="$t('categories.create.labels.accessibility_settings')" class="mb-6">
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t('categories.create.labels.publication_at') }}</label>
            <flat-pickr class="w-full" :config="configPublicationAtPicker" v-model="publicationAt"
              :placeholder="$t('categories.create.labels.publication_at')" @on-change="onFromChangeRegistration" />
            <span class="text-danger text-sm pickatime" v-show="formError.has('publication_at')">{{
              $t(formError.get('publication_at'))
            }}</span>
          </div>
          <div v-if="type !== 'polling'" class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t('categories.create.labels.datetime_registration_to') }}</label>
            <flat-pickr class="w-full" :config="configToDateDatetimeRegistrationPicker" v-model="datetimeRegistrationTo"
              :placeholder="$t('categories.create.labels.datetime_registration_to')"
              @on-change="onToChangeRegistration" />
            <span class="text-danger text-sm pickatime" v-show="formError.has('datetime_registration_to')">{{
              $t(formError.get('datetime_registration_to'))
            }}</span>
          </div>
        </div>
        <div class="vx-row" v-if=" type === 'polling' || type === 'challenge'">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t('categories.create.labels.date_from') }}</label>
            <flat-pickr class="w-full" :config="configFromdateTimePicker" v-model="dateFrom"
              :placeholder="$t('categories.create.labels.date_from')" @on-change="onFromChange" />
            <span class="text-danger text-sm" v-show="formError.has('date_from')">{{ $t(formError.get('date_from'))
            }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2" v-if="type === 'polling'">
            <label>{{ $t('categories.create.labels.date_to') }}</label>
            <flat-pickr class="w-full" :config="configTodateTimePicker" v-model="dateTo"
              :placeholder="$t('categories.create.labels.date_to')" @on-change="onToChange" />
            <span class="text-danger text-sm" v-show="formError.has('date_to')">{{ $t(formError.get('date_to')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-4">
            <label>{{ $t('personal_goals.suite.title.available_after') }}</label>
            <v-select v-model="availableAfter" label="name" :options="availableAfterOptions"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('available_after')">{{
              $t(formError.get('available_after')) }}</span>
          </div>
        </div>

        <div v-if="type !== 'polling'">
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>{{ $t('categories.create.labels.show_in_module_event') }}</label>
              <vs-switch v-model="showInModuleEvent" icon-pack="feather" vs-icon="icon-check"></vs-switch>
              <span class="text-danger text-sm" v-show="formError.has('show_in_module_event')">{{
                $t(formError.get('show_in_module_event'))
              }}</span>
            </div>
          </div>

          <div class="vx-row" v-if="type === 'challenge' && showByDates">
            <div class="vx-col w-full mb-2">
              <label>{{ $t('categories.create.labels.dont_add_points_if_missed') }}</label>
              <vs-switch v-model="dontAddPointsIfMissed" icon-pack="feather" vs-icon="icon-check"></vs-switch>
              <span class="text-danger text-sm" v-show="formError.has('dont_add_points_if_missed')">{{
                $t(formError.get('dont_add_points_if_missed'))
              }}</span>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>{{ $t('categories.edit.labels.is_show_chat') }}</label>
              <vs-switch v-model="isShowChat" icon-pack="feather" vs-icon="icon-check"></vs-switch>
              <span class="text-danger text-sm" v-show="formError.has('is_show_chat')">{{
                $t(formError.get('is_show_chat')) }}</span>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>{{ $t('categories.create.labels.is_show_participants') }}</label>
              <vs-switch v-model="isShowParticipants" icon-pack="feather" vs-icon="icon-check"></vs-switch>
              <span class="text-danger text-sm" v-show="formError.has('is_show_participants')">{{
                $t(formError.get('is_show_participants'))
              }}</span>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>{{ $t('categories.create.labels.is_repeated_passage') }}</label>
              <vs-switch v-model="isRepeatedPassage" icon-pack="feather" vs-icon="icon-check"></vs-switch>
              <span class="text-danger text-sm" v-show="formError.has('is_repeated_passage')">{{
                $t(formError.get('is_repeated_passage'))
              }}</span>
            </div>
          </div>

          <div v-if="isWithoutMissed && 0" class="vx-row">
            <div class="vx-col w-full mb-2">
              <vs-input class="w-full" :label-placeholder="$t('categories.edit.labels.missed_count')"
                v-model="missedCount" />
              <span class="text-danger text-sm" v-show="formError.has('missed_count')">{{
                $t(formError.get('missed_count')) }}</span>
            </div>
          </div>

        </div>
      </vx-card>

      <vx-card class="mb-2" :title="$t('categories.create.labels.description')">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('categories.create.labels.name') }}*</label>
            <vs-input class="w-full" v-model="name" />
            <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
          </div>
          <div v-if="type !== 'polling'" class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('events.create.labels.max_quantity_places') }}</label>
            <vs-input type="number" min="0" oninput="this.value=this.value.replace(/[^0-9]/g,'');" class="w-full"
              v-model="maxQuantityPlaces" />
            <span class="text-danger text-sm" v-show="formError.has('max_quantity_places')">{{
              $t(formError.get('max_quantity_places'))
            }}</span>
          </div>
          <div v-if="type !== 'polling'" class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('categories.edit.labels.tags') }}</label>
            <v-select v-model="tags" multiple :closeOnSelect="false" :options="tagsOptions"
              :selectable="option => tags.indexOf(option) < 0" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('tags')">{{ $t(formError.get('tags')) }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('segments.suite.title') }}</label>
            <v-select v-model="segments" multiple :placeholder="$t('segments.suite.title')" :closeOnSelect="false"
              :options="segmentsOptions" :selectable="option => segments.indexOf(option) < 0"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('segments')">{{ $t(formError.get('segments'))
            }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t(`categories.edit.labels.${type}.point`) }}*</label>
            <vs-input class="w-full" v-model="point" type="number" />
            <span class="text-danger text-sm" v-show="formError.has('point')">{{ $t(formError.get('point')) }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t(`categories.edit.labels.${type}.coin`) }}</label>
            <vs-input class="w-full" v-model="coin" type="number" />
            <span class="text-danger text-sm" v-show="formError.has('coins_finish')">{{ $t(formError.get('coins_finish')) }}</span>
          </div>
          <div v-if="type === 'polling'" class="vx-col w-full md:w-1/2 mb-4">
            <label>{{ $t('categories.edit.labels.clubs') }}</label>
            <v-select v-model="club" :options="clubs" :dir="$vs.rtl ? 'rtl' : 'ltr'" :clearable="false" />
            <span class="text-danger text-sm" v-show="formError.has('club_id')">{{ $t(formError.get('club_id')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-textarea class="w-full mb-2" :label="$t(`categories.create.labels.${type}.announcement`)"
              v-model="announcement" />
            <span class="text-danger text-sm" v-show="formError.has('announcement')">{{ $t(formError.get('announcement'))
            }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vx-card no-shadow card-border :title="$t(`categories.create.labels.${type}.block_description`)">
              <editor ref="editor" :config="editorJsConfig" />
              <span class="text-danger text-sm" v-show="formError.has('block_description')">{{
                $t(formError.get('block_description'))
              }}</span>
            </vx-card>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-container" style="width: 150px; height: 150px; margin: 0 auto">
              <picture-input ref="pictureInput" @change="onImgChange" @error="onImgError" width="150" height="150"
                margin="16" :accept="this.$baseImageAcceptTypes" buttonClass="btn" size="10" :hideChangeButton="true"
                :crop="false" :alertOnError="false" :customStrings="{
                  drag: $t('categories.create.labels.image'),
                }">
              </picture-input>
              <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
            </div>
          </div>
        </div>
        <div v-if="imgCrop" class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-crop-container">
              <cropper ref="cropper" classname="cropper" :src="imgCrop" :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }" :stencil-props="{
                  aspectRatio: 3 / 2
                }"></cropper>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <vs-popup class="holamundo" title="Image error" :active.sync="imageErrorPopupActive">
      <p>{{ imageErrorPopupMessage }}</p>
    </vs-popup>
    <div class="vx-row fixed-buttons">
      <div class="vx-col w-full">
        <div class="flex gap-3">
          <vs-button  @click="create(stayAfter = true)" :disabled="createButtonDisabled">
            {{ $t('categories.create.buttons.create') }}
          </vs-button>
          <vs-button  @click="create()" :disabled="createButtonDisabled">
            {{ $t('categories.create.buttons.create') }} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import editorJsConfig from '@/editorjs/config.js'
import loading from '@/views/components/Loading.vue'
import helpers from '@/helpers/helpers.js'
import ContentFilter from '@/views/components/contentFilter.vue'

import flatPickr from 'vue-flatpickr-component'
import locale from 'flatpickr/dist/l10n/ru'
import 'flatpickr/dist/flatpickr.css'

import vSelect from 'vue-select'
import moment from 'moment'

export default {
  components: {
    PictureInput,
    quillEditor,
    Cropper,
    loading,
    ContentFilter,
    flatPickr,
    'v-select': vSelect
  },
  data () {
    return {
      name: '',
      announcement: '',
      description: '',
      image: null,
      imgCrop: null,
      imageErrorPopupActive: false,
      maxQuantityPlaces: '',
      imageErrorPopupMessage: 'Error',
      editorJsConfig: Object.assign({}, editorJsConfig),
      editorOption: {
        formats: ['bold', 'header', 'italic', 'link', 'list', 'blockquote', 'image', 'indent'],
        modules: {
          toolbar: '#toolbar'
        }
      },
      createButtonDisabled: false,
      clubs: [],
      club: null,
      period: '',
      isActive: true,
      isConsistent: false,
      isWithoutMissed: false,
      missedCount: null,
      isShowChat: false,
      isShowParticipants: true,
      isRepeatedPassage: false,
      tags: [],
      tagsOptions: [],
      segments: [],
      segmentsOptions: [],
      availableAfter: null,
      availableAfterOptions: [],
      showByDates: false,
      showInModuleEvent: false,
      dontAddPointsIfMissed: false,
      point: 0,
      coin: 0,
      loading: true,
      type: 'programm',
      typeProgramData: [],
      typeProgram: '',
      dateFrom: '',
      dateTo: '',
      configFromdateTimePicker: {
        minDate: null,
        maxDate: null,
        locale: locale[this.$i18n.locale]
      },
      configTodateTimePicker: {
        minDate: null,
        locale: locale[this.$i18n.locale]
      },
      publicationAt: moment().format('YYYY-MM-DD HH:mm'),
      datetimeRegistrationTo: '',
      configPublicationAtPicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        minDate: null,
        maxDate: null,
        locale: locale[this.$i18n.locale]
      },
      configToDateDatetimeRegistrationPicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        minDate: null,
        locale: locale[this.$i18n.locale]
      }
    }
  },
  computed: {
    formError () {
      return this.$store.getters['category/formError']
    },
    isPrograms () {
      const programs = ['programm', 'challenge', 'self_challenge']
      return programs.includes(this.type)
    }
  },
  methods: {
    onFromChange (selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, 'minDate', dateStr)
    },
    onToChange (selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, 'maxDate', dateStr)
    },
    onFromChangeRegistration (selectedDates, dateStr, instance) {
      this.$set(this.configToDateDatetimeRegistrationPicker, 'minDate', dateStr)
    },
    onToChangeRegistration (selectedDates, dateStr, instance) {
      this.$set(this.configPublicationAtPicker, 'maxDate', dateStr)
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },
    create (stayAfter) {
      this.loading = !stayAfter
      this.createButtonDisabled = true
      this.dispatch('category/create', stayAfter)
    },
    async dispatch (patch, stayAfter) {
      try {
        const blockDescription = await this.$refs.editor.state.editor.save()

        const payload = {
          name: this.name,
          description: this.description,
          block_description: JSON.stringify(blockDescription),
          announcement: this.announcement,
          image: typeof this.image === 'string' ? this.image : this.image !== null ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : '',
          type: this.type,
          period: this.period,
          is_active: +this.isActive,
          is_consistent: +this.isConsistent,
          is_without_missed: +this.isWithoutMissed,
          missed_count: +this.missedCount,
          is_show_chat: +this.isShowChat,
          show_by_dates: +this.showByDates,
          is_show_participants: +this.isShowParticipants,
          is_repeated_passage: +this.isRepeatedPassage,
          max_quantity_places: this.maxQuantityPlaces,
          tags: this.tags,
          segments: this.segments,
          available_after: this.availableAfter ? {id: this.availableAfter.id} : null,
          dont_add_points_if_missed: +this.dontAddPointsIfMissed,
          point: this.point,
          coins_finish: this.coin,
          date_from: this.dateFrom,
          date_to: this.dateTo,
          publication_at: this.publicationAt,
          datetime_registration_to: this.datetimeRegistrationTo
          // club_id: this.$store.getters['club/getActiveClubId'] ? this.$store.getters['club/getActiveClubId'] : window.localStorage.getItem('clubId')
        }
        if (this.club?.id) {
          payload.club_id = this.club.id
        }
        // if (this.type !== 'polling') {
        //   payload.club_id = this.$store.getters['club/getActiveClubId'] ? this.$store.getters['club/getActiveClubId'] : window.localStorage.getItem('clubId')
        // } else {
        //   payload.club_id = this.club?.id || null
        // }

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          type: 'create',
          stayAfter: stayAfter,
          routerPush: {
            name: this.isPrograms ? 'programms' : `${this.type}s`
          },
          routerPushToEdit: {
            name: `edit-${this.type}`
          }
        }
        await helpers.createOrEditPage(dispatchParams)

      } catch (e) {
        console.log('error', e)
        
      } finally {
        this.loading = false
        this.createButtonDisabled = false
      }      
    },

    getAvailableAfterOptions () {
      this.$store.dispatch('category/getSuite').then(() => {
        const data = this.$store.getters['category/suite']
        const index = data.findIndex(item => +item.id === +this.id)
        if (index !== -1) {
          data.splice(index, 1)
        }
        this.availableAfterOptions = data
      })
    }
  },

  created () {

    this.$store.dispatch('tag/getTagsOptions').then(() => {
      this.tagsOptions = this.$store.getters['tag/tagsOptions']
    })

    this.$store.dispatch('segment/getSegmentsOptions').then(() => {
      this.segmentsOptions = this.$store.getters['segment/segmentsOptions']
    })

    this.typeProgramData = this.$store.getters['category/typeProgram']

    this.getAvailableAfterOptions()

    if (this.$router.currentRoute.name === 'create-polling')  {
      this.type = 'polling'
      this.clubId = ''

      this.$store.dispatch('club/list').then(() => {
        this.clubs = this.$store.getters['club/list']
        this.loading = false
      })
    }

    if (this.$route.query.type) {

      let typeData = {}
      this.typeProgramData.map((item) => {
        if (item.name === this.$route.query.type) {
          typeData = item
        }
      })
      this.typeProgram = typeData.name
      this.type = typeData.type
      this.isWithoutMissed = typeData.is_without_missed
      this.isConsistent = typeData.is_consistent
      this.showByDates = typeData.show_by_dates
    }

    this.$store.commit('category/clearFormError')
  }
}
</script>

<style>
.image-container .preview-container {
  margin: 0 !important;
}
</style>
