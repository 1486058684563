<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
    <feather-icon icon="LogInIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="getCategories()" />
  </div>
</template>

<script>

export default {
  name: 'CellRendererActions',
  methods: {
    editRecord () {
      this.$store.commit('setListPage', this.params.api.paginationGetCurrentPage())
      this.$router.push(`${this.$route.path}/edit-club/${this.params.data.id}`).catch(() => {})
    },
    getCategories () {
      this.$store.commit('club/setIsActiveClubId', this.params.data.id)
      window.localStorage.setItem('clubId', this.params.data.id)
      this.$router.push({
        name: 'programms'
      }).catch(() => {})
    }
  }
}
</script>
