export default {
  suite (state) {
    return state.suite
  },
  edit (state) {
    return state.edit
  },
  codes (state) {
    return state.codes
  },
  virtualCardTypes (state) {
    return state.virtualCardTypes
  },
  templates (state) {
    return state.templates
  },
  formError (state) {
    return state.formError
  }
}
