import FormError from './../../modules/formError'

export default {
  suite: [],
  list: [],
  serviceList: [],
  edit: {
    name: '',
    announcement: '',
    description: '',
    blockDescription: null,
    imageUrl: '',
    type: '',
    point: null,
    period: ''
  },
  typeProgram: [
    {
      name: 'normal_course',
      description: 'normal_course.description',
      type: 'programm',
      is_without_missed: 0,
      is_consistent: 0,
      show_by_dates: 0
    },
    {
      name: 'sequential_course',
      description: 'sequential_course.description',
      type: 'programm',
      is_without_missed: 0,
      is_consistent: 1,
      show_by_dates: 0
    },
    {
      name: 'challenge_by_dates',
      description: 'challenge_by_dates.description',
      type: 'challenge',
      is_without_missed: 0,
      is_consistent: 0,
      show_by_dates: 1
    },
    {
      name: 'challenge_for_departure',
      description: 'challenge_for_departure.description',
      type: 'challenge',
      is_without_missed: 1,
      is_consistent: 0,
      show_by_dates: 0
    },
    {
      name: 'self_challenge',
      description: 'self_challenge.description',
      type: 'self_challenge',
      is_without_missed: 0,
      is_consistent: 0,
      show_by_dates: 0
    }
  ],
  members: [],
  membersTrainingsCount: 0,
  statistics: {},
  formError: new FormError()
}
