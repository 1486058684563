import state from './moduleChatMessagesState.js'
import mutations from './moduleChatMessagesMutations.js'
import actions from './moduleChatMessagesActions.js'
import getters from './moduleChatMessagesGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
