import state from './moduleMissionState.js'
import mutations from './moduleMissionMutations.js'
import actions from './moduleMissionActions.js'
import getters from './moduleMissionGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
