<!-- =========================================================================================
  File Name: ShopProducts.vue
  Description: Shop Products List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="demo-basic-card">
    <div class="vx-row">

      <div class="vx-col md:w-1/2 w-full">
        <vs-button color="primary" type="gradient" icon-pack="feather" icon="icon-plus" class="add-button" :disabled="isOnlyRead" @click="$router.push({ name: 'templates-product'})">{{ $t('shop_products.createtitle') }}</vs-button>
      </div>
      <div class="vx-col md:w-1/2 w-full mb-base flex justify-end">
        <vs-button color="primary" type="border" icon-pack="feather" icon="icon-list" :disabled="isOnlyRead" @click="showSortTable = !showSortTable">{{$t('shop_products.sort')}}</vs-button>
      </div>

      <loading v-if="loading"></loading>

      <div class="vx-col w-full mb-base" v-if="!showSortTable&&!loading">

        <div class="vx-card p-6" v-if="!loading">
          <!-- AgGrid Table -->
          <ag-grid-vue
            @gridReady="onGridReady"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4"
            domLayout="autoHeight"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="suite"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl"
          />

          <vs-pagination v-if="totalPages > 1"
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage" />

        </div>

        <vs-popup class="holamundo" title="" :active.sync="deleteShopProductPopupActive">
          <p>{{ deleteShopProductPopupContent }}</p>
          <div v-if="deleteProcess">
            <font-awesome-icon icon="spinner" spin />
          </div>
          <div class="delete-confirm-popup-buttons" v-else>
            <vs-button color="primary" type="filled" @click="deleteShopProduct()">{{ $t('users.suite.popups.confirm_delete_user.buttons.confirm') }}</vs-button>
            <vs-button color="warning" type="filled" @click="deleteShopProductPopupActive=false">{{ $t('users.suite.popups.confirm_delete_user.buttons.cancel') }}</vs-button>
          </div>
        </vs-popup>

      </div>

      <div v-if="showSortTable&&!loading" class="sort-table-wrapper">
        <vs-list>
          <draggable :list="suite" @change="sort()">
            <transition-group>
              <vs-list-item class="list-item" v-for="product in suite" :key="product.id" :title="product.name">
                <vs-avatar slot="avatar" :src="getUrl(product.image_path)" />
              </vs-list-item>
            </transition-group>
          </draggable>
        </vs-list>
      </div>

    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererLink from '../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../components/cell-renderer/shop/products/CellRendererActions.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)

export default{
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,

    loading,
    draggable
  },
  data () {
    return {
      showSortTable: false,
      loading: true,
      // AgGrid
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        headerClass: 'text-center'
      },
      columnDefs: [
        {
          headerName: this.$t('shop_products.table.id'),
          field: 'id',
          minWidth: 70,
          maxWidth: 80,
          sortable: true
        },
        {
          headerName: this.$t('shop_products.table.name'),
          field: 'name',
          minWidth: 250,
          maxWidth: 600,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'justify-content': 'flex-start', 'word-break': 'break-word'},
          headerClass: 'text-left',
          filter: true
        },
        {
          headerName: this.$t('shop_products.table.stock'),
          field: 'stock',
          minWidth: 100,
          maxWidth: 150,
          sortable: true
        },
        {
          headerName: this.$t('shop_products.table.price'),
          field: 'price',
          minWidth: 100,
          maxWidth: 150,
          sortable: true
        },
        {
          headerName: this.$t('shop_products.table.price_in_coins'),
          field: 'price_in_coins',
          minWidth: 150,
          maxWidth: 150,
          sortable: true
        },
        {
          headerName: this.$t('shop_products.table.is_active'),
          minWidth: 140,
          maxWidth: 140,
          cellRenderer: ({data: {is_active}}) => {
            return is_active ? '&#10004;' : '&#10006;'

          }
        },
        {
          headerName: this.$t('shop_products.table.actions'),
          field: 'id',
          minWidth: 80,
          maxWidth: 120,
          cellRenderer: 'CellRendererActions'
        }
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deleteShopProductPopupActive: false,
      deleteShopProductPopupContent: '',
      deleteProcess: false,
      deleteShopProductId: 0
    }
  },
  computed: {
    
    suite () { return this.$store.getters['shop_product/suite'] },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    },
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-shop'] === 'read'
      }
    }
  },
  created () {
    this.$store.dispatch('shop_product/getSuite').then(() => {
      this.loading = false
    })
  },
  methods:{
    deleteShopProduct () {
      this.deleteProcess = true
      this.$store.dispatch('shop_product/delete', { id: this.deleteShopProductId }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.deleteProcess = false
        this.deleteShopProductPopupActive = false
      })
    },
    onGridReady (params) {
      this.gridApi = params.api
    },
    sort () {
      const payload = {
        suite: this.suite
      }
      this.$store.dispatch('shop_product/sort', payload)
    }
  }
}
</script>

<style lang="scss" scoped>
  .add-button {
    margin-bottom: 30px;
  }
  .delete-confirm-popup-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  .buttons-wrapper {
    margin-bottom: 30px;
  }
  .buttons-wrapper > button{
    display: inline-block;
    margin-right: 30px;
  }
  .sort-table-wrapper {
    width: 100%;
  }
  .list-item {
    transition: all 1s;
  }
  .list-item:hover {
    cursor: all-scroll;
  }

  ::v-deep {
    .ag-cell {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
