<template>
  <section class="statistics">
    <h3 class="statistics-h3">{{ $t('dashboard.titles.shop') }}</h3>
    <div class="card-statistic-grid" v-show="!loading">
      <div v-for="(value, key) in statisticsData" :key="key" class="card-statistic vx-col w-full flex">
        <statistics-card-line :chartData="value.series" :countData="value" type="area" :icon="statisticsData[key].icon"
          icon-left :statisticTitle="statisticsData[key].title"
          :color="statisticsData[key].color" :period="statisticsPeriod" diffPercent />
      </div>
    </div>
    <div class="top-section" v-show="!loading">
      <vx-card class="">
        <div>
          <h4 class="top-title">{{ $t('dashboard.titles.top_positions') }}</h4>
          <ol class="">
            <li class="top-item">
              <span class="top-thead">{{ $t('dashboard.titles.product') }}</span>
              <span class="top-thead text-right">{{ $t('dashboard.titles.number_of_purchases') }}</span>
            </li>
            <li class="top-item" v-for="(item, index) of topProducts" :key="item.id">
              <div class="top-item__name">
                <span class="place">{{ index + 1 }}</span>
                <img v-img="{src: item.image_path
                    ? getUrl(item.image_path)
                    : ''}" class="top-item__img rounded-lg bg-grey" :src="
                  item.image_path_thumbnail
                    ? getUrl(item.image_path_thumbnail)
                    : require('@/assets/images/pages/not-img.png')
                " />
                <div class="flex-column">
                  <span class="top-item__text">{{ item.name }}</span>
                  <span class="top-item__type">{{ item.is_digital ? $t('dashboard.titles.digital_goods') : $t('dashboard.titles.warehouse_goods')}}</span>
                </div>
              </div>
              <span class="top-item__stat">{{ item.count }}</span>
            </li>

          </ol>
        </div>
      </vx-card>
      <vx-card class="">
        <div>
          <h4 class="top-title">{{ $t('dashboard.titles.remains') }}</h4>
          <ol class="">
            <li class="top-item">
              <span class="top-thead">{{ $t('dashboard.titles.product') }}</span>
              <span class="top-thead text-right">{{ $t('dashboard.titles.quantity') }}</span>
            </li>
            <li class="top-item" v-for="(item) of stockProducts" :key="item.id">
              <div class="top-item__name">
                <img v-img="{src: item.image_path
                    ? getUrl(item.image_path)
                    : ''}" class="top-item__img rounded-lg bg-grey" :src="
                  item.image_path_thumbnail
                    ? getUrl(item.image_path_thumbnail)
                    : require('@/assets/images/pages/not-img.png')
                " />
                <div class="flex-column">
                  <span class="top-item__text">{{ item.name }}</span>
                  <span class="top-item__type">{{ item.is_digital ? $t('dashboard.titles.digital_goods') : $t('dashboard.titles.warehouse_goods')}}</span>
                </div>
              </div>
              <span class="top-item__stat">{{ item.stock }}</span>
            </li>

          </ol>
        </div>
      </vx-card>
    </div>

  </section>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import loading from '@/views/components/Loading.vue'

export default {
  name: 'shop-statistics',
  props: ['statisticsPeriod'],
  components: {
    StatisticsCardLine,
    loading
  },
  data () {
    return {
      loading: true,
      chartData: [],
      statisticsData: {
        shopOrders: {
          icon: 'ShoppingCard',
          title: this.$t('dashboard.titles.orders'),
          color: '#299AFF'
        },
        shopOrdersFromWarehouse: {
          icon: 'Package',
          title: this.$t('dashboard.titles.ordered_from_warehouse'),
          color: '#2CB573'
        },
        shopOrderedDigital: {
          icon: 'Ticket',
          title: this.$t('dashboard.titles.ordered_digital'),
          color: '#7367F0'
        }
      }
    }
  },
  methods: {

    getPeriodStatisticsData (statistics) {
      const dataByPeriod = {
        [this.$t('dashboard.labels.month')]: statistics.for_month,
        [this.$t('dashboard.labels.year')]: statistics.for_year,
        [this.$t('dashboard.labels.all_time')]: statistics.for_all_time
      }
      return dataByPeriod[this.statisticsPeriod] ||  statistics.for_month
    },

    fetchData (dataName, dispatchName, seriesName) {
      this.$store.dispatch(dispatchName).then(() => {
        this.statisticsData[dataName] = { ...this.statisticsData[dataName], ...this.$store.getters[`statistics/${dataName}`] }
        this.statisticsData[dataName].series[0].name = seriesName
      })
    }
  },

  computed: {
    topProducts () {
      const topProducts = this.$store.getters['statistics/topProducts']
      return this.getPeriodStatisticsData(topProducts)
    },
    stockProducts () {
      return JSON.parse(JSON.stringify(this.$store.getters['statistics/stockProducts']))
      
    }
  },

  created () {
    const fetchShopOrders = this.fetchData('shopOrders', 'statistics/getShopOrders', this.statisticsData.shopOrders.title)

    const fetchShopOrdersFromWarehouse = this.fetchData('shopOrdersFromWarehouse', 'statistics/getShopOrdersFromWarehouse', this.statisticsData.shopOrdersFromWarehouse.title)

    const fetchShopOrderedDigital = this.fetchData('shopOrderedDigital', 'statistics/getShopOrderedDigital', this.statisticsData.shopOrderedDigital.title)

    const dispatches = [
      this.$store.dispatch('statistics/getTopProducts'),
      this.$store.dispatch('statistics/getStockProducts')
    ]

    Promise.all([fetchShopOrders, fetchShopOrdersFromWarehouse, fetchShopOrderedDigital, ...dispatches]).then(() => {
      this.loading = false
    })
  }
}
</script>

<style lang="scss" scoped>
.card-statistic-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
}

.top-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 24px;

  &>div {
    max-height: 463px;
    overflow-y: auto;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    scroll-padding-top: 10px;

    &::-webkit-scrollbar {
      width: 7px;
      background-color: #f9f9fd;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #7367f0;
      border-radius: 5px;
    }
  }
}

.top-title {
  position: sticky;
  top: 0px;
  margin-top: -21px;
  padding-top: 21px;
  padding-bottom: 16px;
  background: #fff;
  z-index: 1;
  font-size: 18px;
  font-weight: 400;

}

.top-item {
  padding: 16px 24px 16px 0;
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;

  &:not(:last-child) {
    border-bottom: 0.5px solid #C2C2C2;
  }

  &:first-child {
    padding-top: 0;
  }

  .top-thead {
    font-size: 12px;
    color: #626262;
  }

  &:first-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    display: flex;
    align-items: center;

    color: #2C2C2C;
  }

  &__img {
    width: 40px;
    height: 40px;
    margin: 0 8px 0 13px;
  }

  &__type {
    font-weight: 400;
    font-size: 12px;
  }

  &__stat {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
  }
}


.list {

  &__item:not(:last-child) {
    border-bottom: 0.5px solid #C2C2C2;
  }
}

.item-list {

  padding: 16px 0;

  &__user {
    h5 {
      font-size: 12px;
    }
  }

  &__post {}
}

.user-block {

  &__img {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
  }

  &__body {}

  &__city {}
}

.post-block {
  display: flex;
  align-items: stretch;
  font-size: 12px;

  &__img {
    width: 62px;
    height: 62px;
  }

  &__tag {
    display: inline-block;
    width: fit-content;
    line-height: 14px;
    margin: 0px 0px 5px 0px;
    padding: 2px 8px;
    font-size: 12px;
  }

  &__text {}

  &__comment {
    font-weight: 600;
  }
}

.count-likes {
  align-self: self-end;
  margin-left: auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.like-icon {
  color: red;
  fill: red;
  width: 14px;
  height: 14px;
}
</style>