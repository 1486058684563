<!-- =========================================================================================
  File Name: relevant.vue
  Description: relevant List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="demo-basic-card">
    <div class="vx-row">

      <div class="vx-col md:w-1/2 w-full" v-if="!loading">
        <vs-button  color="primary" type="gradient" class="add-button" @click="$router.push({ name: 'relevant-create'})" :disabled="isOnlyRead">{{ $t('relevant.create_button')}}</vs-button>
      </div>
      <!-- <div class="vx-col md:w-1/2 w-full mb-base flex justify-end">
        <vs-button color="primary" type="border" icon-pack="feather" icon="icon-list" @click="showSortTable = !showSortTable">{{$t('relevant.sort')}}</vs-button>
      </div> -->

      <loading v-if="loading"></loading>

      <div class="vx-col w-full mb-base" v-if="!showSortTable&&!loading">

        <div class="vx-card p-6" v-if="!loading">
          <!-- AgGrid Table -->
          <ag-grid-vue
            @gridReady="onGridReady"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4"
            domLayout="autoHeight"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="suite"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl"
          />

          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage" />

        </div>

        <vs-popup class="holamundo" title="" :active.sync="deleteRelevantPopupActive">
          <p>{{ deleteRelevantPopupContent }}</p>
          <div v-if="deleteProcess">
            <font-awesome-icon icon="spinner" spin />
          </div>
          <div class="delete-confirm-popup-buttons" v-else>
            <vs-button color="primary" type="filled" @click="deleteRelevant()">{{ $t('users.suite.popups.confirm_delete_user.buttons.confirm') }}</vs-button>
            <vs-button color="warning" type="filled" @click="deleteRelevantPopupActive=false">{{ $t('users.suite.popups.confirm_delete_user.buttons.cancel') }}</vs-button>
          </div>
        </vs-popup>

      </div>

      <div v-if="showSortTable && !loading" class="sort-table-wrapper">
        <vs-list>
          <draggable :list="suite" @change="sort()">
            <transition-group>
              <vs-list-item class="list-item" v-for="relevant in suite" :key="relevant.id" :title="relevant.target_model.title">
                <vs-avatar slot="avatar" :src="getUrl(relevant.target_model.image_path)" />
              </vs-list-item>
            </transition-group>
          </draggable>
        </vs-list>
      </div>

    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import moment from 'moment'

// Cell Renderer
import CellRendererLink from '../../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../../components/cell-renderer/relevant/CellRendererActions.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)

export default{
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,

    loading,
    draggable
  },
  data () {
    return {
      showSortTable: false,
      loading: true,
      // AgGrid
      gridApi: null,
      columnApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        sortable: true,
        flex: 1,
        headerClass: 'text-center',
        cellStyle: {'display': 'flex', 'justify-content': 'flex-start', 'align-items': 'center'}
      },
      columnDefs: [
        {
          headerName: this.$t('relevant.table.id'),
          field: 'id',
          minWidth: 80,
          maxWidth: 100,
          cellStyle: {'justify-content': 'center'},
          sortable: true
        },
        {
          headerName: this.$t('relevant.table.title'),
          field: 'target_model.title',
          minWidth: 220,
          maxWidth: 800,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'justify-content': 'flex-start', 'align-items': 'center'},
          headerClass: 'text-left',
          filter: true
        },
        {
          headerName: this.$t('relevant.table.publication_at'),
          field: 'publication_at',
          valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD.MM.YYYY HH:mm') : ''
          },
          minWidth: 140,
          cellStyle: {'overflow': 'visible'}
        },
        {
          headerName: this.$t('relevant.table.deactivate_at'),
          field: 'publication_until',
          valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD.MM.YYYY HH:mm') : ''
          },
          minWidth: 140,
          cellStyle: {'overflow': 'visible'}
        },
        {
          headerName: this.$t('relevant.table.type'),
          field: 'target_model.type',
          minWidth: 150,
          headerClass: 'text-left',
          filter: true
        },
        {
          headerName: this.$t('relevant.table.status'),
          field: 'status',
          minWidth: 130,
          filter: true,
          sort: 'desc',
          // сортировка в такой последовательности:  soon, active, completed, not_active
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const orderChart = {
              [this.$t('relevant.table.relevant_status.soon')]: 3,
              [this.$t('relevant.table.relevant_status.active')]: 2,
              [this.$t('relevant.table.relevant_status.completed')]: 1,
              [this.$t('relevant.table.relevant_status.not_active')]: 0
            }
            
            return orderChart[valueA] - orderChart[valueB] || 
                  Date.parse(nodeA.data.publication_at) - Date.parse(nodeB.data.publication_at)
          },
          cellRenderer: ({data: {status}}) => {
            let bgColor = ''
            let color = ''
            switch (status) {
            case this.$t('relevant.table.relevant_status.soon'):
              bgColor = '#E0DEF4'
              color = '#7367F0'
              break
            case this.$t('relevant.table.relevant_status.active'):
              bgColor = '#D4EEE0'
              color = '#28C76F'
              break
            case this.$t('relevant.table.relevant_status.completed'):
              bgColor = '#E9E9EA'
              color = '#A8AAAE'
              break
            case this.$t('relevant.table.relevant_status.not_active'):
              bgColor = '#7c7c81'
              color = '#dee3d5'
              break
            }
            return `<div 
                      style="
                        justify-content: center;
                        text-align: center;
                        border-radius: 4px;
                        color: ${color};
                        line-height: 2;
                        font-weight: 600;
                        width: 100px;
                        background-color: ${bgColor};"
                      >
                      ${status}
                    </div>`
          }
        },
        {
          headerName: this.$t('relevant.table.actions'),
          field: 'id',
          minWidth: 100,
          cellRenderer: 'CellRendererActions',
          cellStyle: {'justify-content': 'center'},
          sortable: false
        }
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deleteRelevantPopupActive: false,
      deleteRelevantPopupContent: '',
      deleteProcess: false,
      deleteRelevantId: 0
    }
  },   
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-relevant'] === 'read'
      }
    },
    suite () {
      return JSON.parse(JSON.stringify(this.$store.getters['relevant/suite']))
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },

  created () {
    this.$store.dispatch('relevant/getSuite').then(() => {
      this.loading = false
      
    })
  },

  watch: {
    suite () {
      this.updateSuite()
    }
  },

  methods:{
    statusComparator (valueA, valueB) {
      const orderChart = {
        [this.$t('relevant.table.relevant_status.soon')]: 1,
        [this.$t('relevant.table.relevant_status.active')]: 0,
        [this.$t('relevant.table.relevant_status.completed')]: -1,
        [this.$t('relevant.table.relevant_status.not_active')]: -1
      }
      return orderChart[valueA] - orderChart[valueB]
    },
    updateSuite () {
      this.suite = JSON.parse(JSON.stringify(this.$store.getters['relevant/suite']))
      this.loading = false
      for (const item of this.suite) {
        item.status = this.$t(`relevant.table.relevant_status.${item.status}`)
        item.target_model.type = this.$t(`relevant.table.relevant_type.${item.target_model.type}`)
      } 
    },
    deleteRelevant () {
      this.deleteProcess = true
      this.$store.dispatch('relevant/delete', { id: this.deleteRelevantId }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.deleteProcess = false
        this.deleteRelevantPopupActive = false
      })
    },
    sort () {
      const payload = {
        suite: this.suite
      }
      this.$store.dispatch('relevant/sort', payload)
    },
    onGridReady (params) {
      this.gridApi = params.api
    }
  }
}
</script>

<style lang="scss" scoped>
.add-button {
    margin-bottom: 30px;
  }
  .delete-confirm-popup-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  .buttons-wrapper {
    margin-bottom: 30px;
  }
  .buttons-wrapper > button{
    display: inline-block;
    margin-right: 30px;
  }
  .sort-table-wrapper {
    width: 100%;
  }
  .list-item {
    transition: all 1s;
  }
  .list-item:hover {
    cursor: all-scroll;
  }

  ::v-deep .ag-grid-table {
    .ag-cell-wrap-text {
    word-break: break-word;
  }
  }
</style>
