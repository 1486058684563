import { render, staticRenderFns } from "./createEditProduct.vue?vue&type=template&id=d3e3e922&scoped=true"
import script from "./createEditProduct.vue?vue&type=script&lang=js"
export * from "./createEditProduct.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3e3e922",
  null
  
)

export default component.exports