import Header from '@editorjs/header'
import List from '@editorjs/list'
import Embed from '@editorjs/embed'
import Paragraph from '@editorjs/paragraph'
import LinkTool from '@editorjs/link'
import ImageTool from '@editorjs/image'
import ImageGallery from 'editorjs-gallery'
import Delimiter from '@editorjs/delimiter'
import Quote from '@editorjs/quote'
import Alert from 'editorjs-alert'
import store from '../store/store'
import axios from '@/axios.js'

function compressImage (file) {

  const image = new Image()
  
  const reader = new FileReader()
  
  return new Promise((resolve, reject) => {
    image.onload = () => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      
      const MAX_WIDTH = 1000
      let newWidth = image.width
      let newHeight = image.height

      if (file.type === 'image/gif') {
        resolve(file)
        return
      }
      
      if (newWidth > MAX_WIDTH) {
        const ratio = MAX_WIDTH / newWidth
        newWidth = MAX_WIDTH
        newHeight = newHeight * ratio
      }
      
      canvas.width = newWidth
      canvas.height = newHeight
      
      context.drawImage(image, 0, 0, newWidth, newHeight)
      
      canvas.toBlob((blob) => {
        const compressedFile = new File([blob], file.name, { type: file.type })
        resolve(compressedFile)
      }, file.type)
    }
    
    // Read the file data as a data URL
    reader.onloadend = () => {
      image.src = reader.result
    }
    
    // Read the file using the FileReader
    reader.readAsDataURL(file)
  })
}

export default {
  tools:{
    header: {
      class: Header,
      config: {
        placeholder: 'Введите заголовок',
        levels: [1, 2, 3],
        defaultLevel: 2
      }
    },
    list: {
      class: List,
      inlineToolbar: true
    },
    embed: {
      class: Embed
    },
    paragraph: {
      class: Paragraph
    },
    linkTool: {
      class: LinkTool,
      config: {
        endpoint: `${axios.defaults.baseURL}/api/admin-panel/editorjs/links/get`
      }
    },
    image: {
      class: ImageTool,
      config: {
        types: 'image/*, video/mp4, video/ogg, video/webm',
        uploader: {
          uploadByFile (file) {
            return compressImage(file).then((compressedFile) => {
              return store.dispatch('editorjs/uploadFile', { image: file })
                .then((response) => {
                  return response.data
                })
            })
          },
          uploadByUrl (url) {
            return store.dispatch('editorjs/fetchUrl', { url }).then((response) => {
              return response.data
            })
          }
        }
      }
    },
    gallery: {
      class: ImageGallery,
      config: {
        uploader: {
          uploadByFile (file) {
            return compressImage(file).then((compressedFile) => {
              return store.dispatch('editorjs/uploadFile', { image: compressedFile })
                .then((response) => {
                  return response.data
                })
            })
          },
          uploadByUrl (url) {
            return store.dispatch('editorjs/fetchUrl', { url }).then((response) => {
              return response.data
            })
          }
        }
      }
    },
    delimiter: {
      class: Delimiter
    },
    quote: {
      class: Quote,
      inlineToolbar: true,
      shortcut: 'CMD+SHIFT+O',
      config: {
        quotePlaceholder: 'Enter a quote',
        captionPlaceholder: 'Quote\'s author'
      }
    },
    alert: {
      class: Alert,
      inlineToolbar: true,
      config: {
        defaultType: 'primary',
        defaultAlign: 'center',
        messagePlaceholder: 'Введите сообщение'
      }
    }
  }
}
