import helpers from '@/helpers/helpers.js'
import moment from 'moment'
export default {
  retrieveSuite (state, suite) {
    suite.map((item) => {
      item.created_at = item.created_at ? moment(item.created_at).format('DD.MM.YYYY') : ''
    })
    state.suite = suite
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  },
  retrieveEdit (state, data) {
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) continue
      state.edit[helpers.kebabCaseToCamelCase(prop)] = (data[prop] === null) ? '' : data[prop]
    }
  },
  retrieveAppConfig (state, suite) {
    state.appConfig = suite
  }
}
