import helpers from '@/helpers/helpers.js'
import moment from 'moment'
export default {
  retrieveSuite (state, suite) {
    state.suite = suite
    const now = Date.now()

    for (const prop of state.suite) {

      // получаем недостающие типы
      if (prop.target_type.includes('Post')) {
        prop.target_model.type = 'post'
      }
      if (prop.target_type.includes('Challenge')) {
        prop.target_model.type = 'goal'
      }

      // обрезаем секунды у даты
      if (prop.publication_at && prop.publication_at.length > 16) {
        prop.publication_at = prop.publication_at.slice(0, 16)       
      }
      if (prop.publication_until && prop.publication_until.length > 16) {
        prop.publication_until = prop.publication_until.slice(0, 16)      
      }

      // получаем статус
      const at = Date.parse(prop.publication_at)
      const until = Date.parse(prop.publication_until) || Infinity
  

      if (prop.is_active !== 0) {
        if (now > until) {
          prop.status = 'completed'
        }
        if (now < at) {
          prop.status = 'soon'
        }
        if (at < now && now < until) {
          prop.status = 'active'
        }
      } else {
        prop.status = 'not_active'
      }
    }
  },
  retrieveEdit (state, data) {
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) continue
      state.edit[helpers.kebabCaseToCamelCase(prop)] = (data[prop] === null) ? '' : data[prop]
    }
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  },
  removeRelevantById (state, id) {
    const index = helpers.searchInArray('id', id, state.suite, true)
    state.suite.splice(index, 1)
  }
}
