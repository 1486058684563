import state from './moduleStatisticsState.js'
import mutations from './moduleStatisticsMutations.js'
import actions from './moduleStatisticsActions.js'
import getters from './moduleStatisticsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
