
<template>
  <div id="demo-basic-card">
    <div class="vx-row">

      <loading v-if="loading"></loading>

      <div class="vx-col w-full mb-base" v-if="!loading">

        <div class="vx-card p-6">
           <vs-button
            color="#975bff"
            class=" py-2 px-4 rounded mb-4"
            :disabled="selectedRows.length === 0 || isOnlyRead"
            @click="openStatusModal"
          >
            {{ $t('Change Status') }}
          </vs-button>
          <!-- AgGrid Table -->
          <ag-grid-vue
            @gridReady="onGridReady"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4"
            domLayout="autoHeight"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="suite.data"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            @rowClicked="onRowClicked"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl"
            @selectionChanged="onSelectionChanged"
          />

          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage" />

        </div>
        <div class="mt-8" v-if="!loading">
          <h2>{{ $t('Upload orders') }}</h2>
          <div class="vx-col w-full flex flex-wrap gap-12 items-end mt-8">
            <div>
              <div v-for="(statusName, statusValue) in suite.statuses" :key="statusValue" class="flex flex-column">
                <label>
                  <input type="checkbox" :value="statusValue" v-model="statusArrForDownload">
                  {{ statusName }}
                </label>
              </div>
            </div>
            <div>
              <div class="vx-col w-48 mb-2">
                <label>{{ $t('Date from') }}</label>
                <flat-pickr
                  class="w-full"
                  :config="configTimePicker"
                  v-model="dateFromDownload"
                  :placeholder="$t('Date from')"
                />
              </div>
              <div class="vx-col w-48">
                <label>{{ $t('Date to') }}</label>
                <flat-pickr
                  class="w-full"
                  :config="configTimePicker"
                  v-model="dateUntilDownload"
                  :placeholder="$t('Date to')"
                />
              </div>
            </div>
            <vs-button  color="primary" type="gradient" class="add-button h-12" @click="download()">{{ $t('Download')}}</vs-button>
          </div>
        </div>
        <vs-popup
          class="status-popup p-6 bg-white rounded-lg shadow-lg"
          :title="$t('Change Status')"
          :active.sync="showStatusModal"
        >
          <div class="mb-4">
            <label for="status-select" class="block text-grey-700">{{ $t('Select Status') }}</label>
            <select
              id="status-select"
              v-model="newStatus"
              class="mt-2 p-2 border rounded w-full"
            >
              <option v-for="(statusName, statusValue) in suite.statuses" :key="statusValue" :value="statusValue">
                {{ statusName }}
              </option>
            </select>
          </div>
          <vs-button
            color="#975bff"
            class=" py-2 px-4 rounded mb-4"
            :disabled="!newStatus"
            @click="confirmChangeStatus(single = false)"
          >
            {{ $t('Change Status') }} ({{ selectedRows.length }})
          </vs-button>
        </vs-popup>

         <vs-popup
          class="confirm-popup p-6 bg-white rounded-lg shadow-lg"
          :title="$t('Confirm Action')"
          :active.sync="showConfirmModal"
        >
          <p v-if="!isSingleChangeStatus">{{ $t('Are you sure you want to change the status of the selected orders to') + ' ' + suite.statuses[newStatus] + '?' }}</p>
          <p v-else>{{ $t('Are you sure you want to change the status of the selected order to') + ' ' + suite.statuses[selectedStatus] + '?' }}</p>
          <div class="flex justify-end mt-4">
            <vs-button
              color="warning"
              class="bg-gray-500 text-white py-2 px-4 rounded mr-2"
              @click="showConfirmModal = false"
            >
              {{ $t('Cancel') }}
            </vs-button>
            <vs-button
              color="primary"
              class="bg-blue-500 text-white py-2 px-4 rounded"
              @click="changeStatus()"
            >
              {{ $t('Confirm') }}
            </vs-button>
          </div>
        </vs-popup>

        <vs-popup class="order-popup p-6 rounded-lg shadow-lg" :title="`${$t('Order')} № ${rowData.id}`" :active.sync="showOrderPopup">
          <ul class="space-y-4">
            <li class="flex justify-between">
              <span class="font-semibold">{{ $t('Order') }}:</span>
              <span>{{ rowData.id }}</span>
            </li>
            <li class="flex justify-between">
              <span class="font-semibold">{{ $t('Order date') }}:</span>
              <span>{{ formatDate(rowData.created_at) }}</span>
            </li>
            <li class="flex justify-between">
              <span class="font-semibold">{{ $t('E-mail') }}:</span>
              <span>{{ rowData.customer_email }}</span>
            </li>
            <li class="flex justify-between">
              <span class="font-semibold">{{ $t('Name') }}:</span>
              <span>{{ rowData.customer_name }}</span>
            </li>
            <li class="flex justify-between">
              <span class="font-semibold">{{ $t('Phone') }}:</span>
              <span>{{ rowData.customer_phone }}</span>
            </li>
            <li class="flex justify-between items-center">
              <span class="font-semibold">{{ $t('Status') }}:</span>
              <select v-model="selectedStatus" @change="confirmChangeStatus(single = true)" :disabled="isOnlyRead" class="ml-2 p-2 border rounded">
                <option v-for="(statusName, statusValue) in suite.statuses" :key="statusValue" :value="statusValue">
                  {{ statusName }}
                </option>
              </select>
            </li>
            <li>
              <span class="font-semibold">{{ $t('Composition') }}:</span>
              <ul class="list-disc list-inside mt-2">
                <li v-for="(product, index) in rowData.products" :key="product.product_id">{{ index + 1 }}. {{ product.title }}</li>
              </ul>
            </li>
            <li class="flex justify-between">
              <span class="font-semibold">{{ $t('Price') }}:</span>
              <span>{{ rowData.sum }}</span>
            </li>
            <li class="flex justify-between">
              <span class="font-semibold">{{ $t('Additional payment is required') }}:</span>
              <span>{{ rowData.surcharge_cash ? rowData.surcharge_cash : $t('No') }}</span>
            </li>
          </ul>
        </vs-popup>
      </div>

    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import locale from 'flatpickr/dist/l10n/ru'
import 'flatpickr/dist/flatpickr.css'

// Cell Renderer
import CellRendererLink from '../../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../../components/cell-renderer/relevant/CellRendererActions.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)


export default{
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,
    flatPickr,

    loading,
    draggable
  },
  data () {
    return {
      loading: true,
      rowData: {},
      selectedStatus: null,
      showOrderPopup: false,
      showStatusModal: false,
      showConfirmModal: false,
      selectedRows: [],
      newStatus: null,
      isSingleChangeStatus: false,
      dateFromDownload: null,
      dateUntilDownload: null,
      statusArrForDownload: [],
      configTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d',
        locale: locale[this.$i18n.locale],
        time_24hr: true
      },

      // AgGrid
      gridApi: null,
      columnApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        sortable: true,
        flex: 1,
        headerClass: 'text-center',
        cellStyle: {'display': 'flex', 'justify-content': 'flex-start', 'align-items': 'center'}
      },
      columnDefs: [
        {
          headerName: this.$t('ID'),
          field: 'id',
          minWidth: 130,
          maxWidth: 130,
          cellStyle: {'justify-content': 'center', 'align-items': 'center'},
          sortable: true,
          filter: 'agNumberColumnFilter',
          headerCheckboxSelection: true,
          checkboxSelection: true
        },
        {
          headerName: this.$t('Order date'),
          field: 'created_at',
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              // Преобразуем значение ячейки в дату с помощью moment
              const cellDate = moment(cellValue, moment.ISO_8601, true).toDate()
              // Преобразуем дату фильтра в полночь
              const filterDate = new Date(filterLocalDateAtMidnight)
              filterDate.setHours(0, 0, 0, 0)

              // Сравниваем только даты без учета времени
              const cellDateOnly = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate())
              const filterDateOnly = new Date(filterDate.getFullYear(), filterDate.getMonth(), filterDate.getDate())

              if (cellDateOnly < filterDateOnly) {
                return -1
              } else if (cellDateOnly > filterDateOnly) {
                return 1
              } else {
                return 0
              }
            },
            browserDatePicker: true,
            filterOptions: ['inRange', 'equals', 'lessThan', 'greaterThan']
          },
          minWidth: 175,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD.MM.YYYY HH:mm') : ''
          },
          cellStyle: {'line-height': '1.7', 'justify-content': 'flex-start', 'align-items': 'center'},
          headerClass: 'text-left'
        },
        {
          headerName: this.$t('E-mail'),
          field: 'customer_email',
          minWidth: 180,
          flex: 2,
          sortable: false,
          filter: 'agTextColumnFilter',
          cellStyle: {'overflow': 'visible'}
        },
        {
          headerName: this.$t('Status'),
          field: 'status',
          sortable: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: () => Object.values(this.suite.statuses)
          },
          valueGetter: (params) => {
            return this.suite.statuses[params.data.status] || params.data.status
          },
          valueFormatter: (params) => {
            return this.suite.statuses[params.value] || params.value
          },
          cellRenderer: (params) => {
            const status = params.data.status
            const statusMap = this.suite.statuses
            let bgColor = ''
            let color = ''
            switch (status) {
            case 0:
              bgColor = '#e0f7fa' // Светло-голубой фон
              color = '#004d40' // Темно-зеленый текст
              break
            case 10:
              bgColor = '#fff3e0' // Светло-оранжевый фон
              color = '#bf360c' // Темно-коричневый текст
              break
            case 20:
              bgColor = '#ffebee' // Светло-красный фон
              color = '#b71c1c' // Темно-красный текст
              break
            case 30:
              bgColor = '#e8f5e9' // Светло-зеленый фон
              color = '#1b5e20' // Темно-зеленый текст
              break
            case 40:
              bgColor = '#e3f2fd' // Светло-синий фон
              color = '#0d47a1' // Темно-синий текст
              break
            case 50:
              bgColor = '#f3e5f5' // Светло-фиолетовый фон
              color = '#4a148c' // Темно-фиолетовый текст
              break
            }
            return `<div 
                      style="
                        justify-content: center;
                        text-align: center;
                        border-radius: 4px;
                        color: ${color};
                        line-height: 2;
                        font-weight: 600;
                        padding: 0 10px;
                        background-color: ${bgColor};"
                      >
                      ${statusMap[status]}
                    </div>`
          },
          minWidth: 200,
          cellStyle: { 'overflow': 'visible', 'justify-content': 'center' }
        },
        {
          headerName: this.$t('Composition'),
          field: 'products',
          minWidth: 250,
          headerClass: 'text-left',
          autoHeight: true,
          sortable: false,
          filter: false,
          cellRenderer: ({ data: { products } }) => {
            return `<div>${products.map((product, index) => `${index + 1}. ${product.title}`).join('<br><br>')}</div>`
          },
          cellStyle: {
            'font-size': '11px',
            'line-height': '1.2',
            'word-break': 'break-word'
          },
          wrapText: true,
          flex: 3
        },
        {
          headerName: this.$t('Additional payment'),
          field: 'surcharge_cash',
          minWidth: 140,
          cellStyle: {'justify-content': 'center'}
        },
        {
          headerName: this.$t('Name'),
          field: 'customer_name',
          minWidth: 140,
          sortable: false,
          autoHeight: true,
          filter: true,
          cellStyle: {
            'font-size': '11px',
            'line-height': '1.2',
            'word-break': 'break-word',
            'white-space': 'normal'
          }
        }
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deleteRelevantPopupActive: false,
      deleteRelevantPopupContent: '',
      deleteProcess: false,
      deleteRelevantId: 0
    }
  },   
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-cafeteria'] === 'read'
      }
    },
    suite () {
      return this.$store.getters['cafeteria/suite']
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },

  created () {
    this.$store.dispatch('cafeteria/getSuite').then(() => {
      this.loading = false
      
    })
  },


  methods:{
    
    onGridReady (params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },

    download () {
      const payload = {
        status: this.statusArrForDownload.join(','),
        date_from: this.dateFromDownload,
        date_until: this.dateUntilDownload
      }
      this.$store.dispatch('cafeteria/download', payload)
    },

    onRowClicked (params) {
      this.rowData = params.data
      this.showOrderPopup = true
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      return moment(date).format('DD.MM.YYYY HH:mm')
    },
    updateStatus () {
      this.$store.commit('cafeteria/updateOrderStatus', { orderId: this.rowData.id, newStatus: this.selectedStatus })
      this.gridApi.refreshCells({ force: true })
      console.log(`Статус заказа ${this.rowData.id} изменен на ${this.suite.statuses[this.selectedStatus]}`)
    },
    onSelectionChanged () {
      this.selectedRows = this.gridApi.getSelectedRows()
    },
    openStatusModal () {
      this.showStatusModal = true
    },
    changeStatus () {
      try {
        const payload = {
          orders: []
        }

        if (this.isSingleChangeStatus) {
          payload.orders.push({ id: this.rowData.id, status: this.selectedStatus })
          this.$store.commit('cafeteria/updateOrderStatus', { orderId: this.rowData.id, newStatus: this.selectedStatus })
        } else {
          this.selectedRows.forEach(row => {
            payload.orders.push({ id: row.id, status: this.newStatus })
            this.$store.commit('cafeteria/updateOrderStatus', { orderId: row.id, newStatus: this.newStatus })
          })
        }

        this.$store.dispatch('cafeteria/changeOrderStatus', payload)

        this.gridApi.refreshCells({ force: true })
        this.showConfirmModal = false
        this.isSingleChangeStatus = false
      } catch (error) {
        console.error('Error changing status:', error)
        this.gridApi.refreshCells({ force: true })
        this.showConfirmModal = false
        this.$vs.notify({
          title: 'Server error',
          text: error,
          color: 'danger'
        })
      }
    },
    confirmChangeStatus (single = false) {
      this.showStatusModal = false
      this.isSingleChangeStatus = single
      this.showConfirmModal = true
    }
  },

  watch: {
    rowData: {
      handler (newValue) {
        // Устанавливаем начальное значение selectedStatus при открытии попапа
        this.selectedStatus = newValue.status
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>

  .delete-confirm-popup-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  .buttons-wrapper {
    margin-bottom: 30px;
  }
  .buttons-wrapper > button{
    display: inline-block;
    margin-right: 30px;
  }
  .sort-table-wrapper {
    width: 100%;
  }
  .list-item {
    transition: all 1s;
  }
  .list-item:hover {
    cursor: all-scroll;
  }

  ::v-deep .ag-grid-table {
    .ag-cell-wrap-text {
    word-break: break-word;
  }
  }
</style>
