import helpers from '@/helpers/helpers.js'

export default {
  retrieveCountCompletedTrainings (state, data) {
    state.live.countCompletedTrainings = data
  },

  retrieveTopLikes (state, data) {
    state.live.topLikes = data
  },

  retrieveCountMessages (state, data) {
    state.live.countMessages = data
  },

  retrieveCountPosts (state, data) {
    state.live.countPosts = data
  },

  retrieveCountReceivedPoints (state, data) {
    state.live.countReceivedPoints = data
  },

  retrieveCountRegistrations (state, data) {
    state.live.countRegistrations = data
  },
  retrieveCountFamilyUsers (state, data) {
    state.live.countFamilyUsers = data
  },
  retrieveUsersActivity (state, data) {
    state.usersActivity = data
  },
  retrieveCountUsers (state, data) {
    state.general.countUsers = data
    state.general.countUsers.series[0].data = data.series[0].data.map(row => [row.date_created, row.count])
  },
  retrieveCountUsersLogIn (state, data) {
    state.general.countUsersLogIn = data
    state.general.countUsersLogIn.series[0].data = data.series[0].data.map(row => [row.date_created, row.count])
  },

  retrieveUniqueCountUsersLogIn (state, data) {
    state.general.countUniqueUsersLogIn = data
    state.general.countUniqueUsersLogIn.series[0].data = data.series[0].data.map(row => [row.date_created, row.count])
  },

  retrieveTimeSpentInApp (state, data) {
    state.general.timeSpentInApp = data
    state.general.timeSpentInApp.series[0].data = data.series[0].data.map(row => [row.date_created, Math.floor(row.count / 3600)])
    for (const prop in state.general.timeSpentInApp) {
      if (typeof state.general.timeSpentInApp[prop] === 'number') {
        state.general.timeSpentInApp[prop] = Math.floor(state.general.timeSpentInApp[prop] / 3600)
      }
    }
  },
  retrieveInterests (state, data) {
    state.general.interests = data
    const mapTimeToHours = (row) => ({
      ...row,
      time: Math.floor(row.time / 3600)
    })
    data.for_all_time = data.for_all_time.map(mapTimeToHours)
    data.for_month = data.for_month.map(mapTimeToHours)
    data.for_year = data.for_year.map(mapTimeToHours)
  },
  retrievePastEvents (state, data) {
    state.events.pastEvents = data
    state.events.pastEvents.series[0].data = data.series[0].data.map(row => [row.date_created, row.count])
  },
  retrieveUsersParticipatedInEvents (state, data) {
    state.events.usersParticipatedInEvents = data
    state.events.usersParticipatedInEvents.series[0].data = data.series[0].data.map(row => [row.date_created, row.count])
  },
  retrieveReports (state, data) {
    state.events.reports = data
    state.events.reports.series[0].data = data.series[0].data.map(row => [row.date_created, row.count])
  },
  retrieveTopEvents (state, data) {
    state.events.topEvents = data
  },
  retrieveTopLikesForReports (state, data) {
    state.events.topLikesForReports = data
  },
  retrieveShopOrders (state, data) {
    state.shop.shopOrders = data
    state.shop.shopOrders.series[0].data = data.series[0].data.map(row => [row.date_created, row.count])
  },
  retrieveShopOrdersFromWarehouse (state, data) {
    state.shop.shopOrdersFromWarehouse = data
    state.shop.shopOrdersFromWarehouse.series[0].data = data.series[0].data.map(row => [row.date_created, row.count])
  },
  retrieveShopOrderedDigital (state, data) {
    state.shop.shopOrderedDigital = data
    state.shop.shopOrderedDigital.series[0].data = data.series[0].data.map(row => [row.date_created, row.count])
  },
  retrieveTopProducts (state, data) {
    state.shop.topProducts = data
  },
  retrieveStockProducts (state, data) {
    state.shop.stockProducts = data
  },
  retrievePrograms (state, data) {
    state.coursesAndPrograms.programs = data
    state.coursesAndPrograms.programs.series[0].data = data.series[0].data.map(row => [row.date_created, row.count])
  },
  retrieveChallenges (state, data) {
    state.coursesAndPrograms.challenges = data
    state.coursesAndPrograms.challenges.series[0].data = data.series[0].data.map(row => [row.date_created, row.count])
  },
  retrieveCompletedTrainings (state, data) {
    state.coursesAndPrograms.completedTrainings = data
    state.coursesAndPrograms.completedTrainings.series[0].data = data.series[0].data.map(row => [row.date_created, row.count])
  },
  retrieveTopActiveUsers (state, data) {
    state.coursesAndPrograms.topActiveUsers = data
  },
  retrieveTopSociableUsers (state, data) {
    state.coursesAndPrograms.topSociableUsers = data
  },
  retrieveTopActiveTeams (state, data) {
    state.coursesAndPrograms.topActiveTeams = data
  },
  retrieveTopCoursesAndChallenges (state, data) {
    state.coursesAndPrograms.topCoursesAndChallenges = data
  },
  retrieveTopReports (state, data) {
    state.coursesAndPrograms.topReports = data
  },
  retrieveCommunicationsEmail (state, data) {
    state.communications.email = data
  },
  retrieveCommunicationsMessage (state, data) {
    state.communications.message = data
  },
  retrieveCommunicationsPush (state, data) {
    state.communications.push = data
  },
  retrieveCommunicationsPosts (state, data) {
    state.communications.posts = data
  },
  retrieveCommunicationsLikes (state, data) {
    state.communications.likes = data
  }
}
