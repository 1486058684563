<!-- =========================================================================================
  File Name: Mailings.vue
  Description: Mailings List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="demo-basic-card">
    <div v-if="!isOnlyRead" class="upload-excel-users-container">
      <div class="excel-file-buttons-container">
        <vs-button color="primary" type="gradient" icon-pack="feather" icon="icon-plus" class="add-button" @click="$router.push({ name: 'mailings-create'})"></vs-button>
      </div>
    </div>
    <div>
      <loading v-if="loading"></loading>
      <div class="vx-card p-6" v-if="!loading">
        <!-- AgGrid Table -->
        <ag-grid-vue
          @gridReady="onGridReady"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4"
          domLayout="autoHeight"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="suite"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl">
        </ag-grid-vue>

        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage" />

      </div>

      <vs-popup class="holamundo" :title="$t('mailings.suite.popups.confirm_delete.title')" :active.sync="deleteMailingPopupActive">
        <p>{{ deleteMailingPopupContent }}</p>
        <div v-if="deleteProcess">
          <font-awesome-icon icon="spinner" spin />
        </div>
        <div class="delete-confirm-popup-buttons" v-else>
          <vs-button color="primary" type="filled" @click="deleteMailing()">{{ $t('mailings.suite.popups.confirm_delete.buttons.confirm') }}</vs-button>
          <vs-button color="warning" type="filled" @click="deleteMailingPopupActive=false">{{ $t('mailings.suite.popups.confirm_delete.buttons.cancel') }}</vs-button>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererLink from '../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../components/cell-renderer/mailings/CellRendererActions.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)

export default{
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,

    loading
  },
  data () {
    return {
      loading: true,
      showFileName: false,
      fileName: '',
      file: null,
      showUploadButtons: false,
      uploading: false,
      // AgGrid
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: { 'textAlign': 'center'},
        headerClass: 'text-center'
      },
      columnDefs: [
        {
          headerName: this.$t('mailings.table.id'),
          field: 'id',
          minWidth: 50,
          maxWidth: 75,
          sortable: true
        },
        {
          headerName: this.$t('mailings.table.title'),
          field: 'title',
          filter: true,
          minWidth: 250,
          maxWidth: 1000,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 24px'},
          headerClass: 'text-left'
        },
        {
          headerName: this.$t('mailings.table.sending_at'),
          field: 'sending_at',
          filter: true,
          minWidth: 250,
          maxWidth: 1000,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 24px'},
          headerClass: 'text-left'
        },
        {
          headerName: this.$t('mailings.table.status'),
          field: 'status',
          minWidth: 140,
          maxWidth: 170,
          sortable: true
        },
        {
          headerName: this.$t('mailings.table.actions'),
          field: 'id',
          minWidth: 110,
          maxWidth: 130,
          cellRenderer: 'CellRendererActions'
        }
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deleteMailingPopupActive: false,
      deleteMailingPopupContent: '',
      deleteProcess: false,
      deleteMailingId: 0
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-mailings'] === 'read'
      }
    },
    suite () { return this.$store.getters['mailing/suite'] },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.paginationGoToPageCached(val)
      }
    }
  },
  created () {
    this.$store.dispatch('mailing/getSuite').then(() => {
      this.loading = false
    })
  },
  methods:{
    deleteMailing () {
      this.deleteProcess = true
      this.$store.dispatch('mailing/delete', { id: this.deleteMailingId }).catch(error => {
        this.$vs.notify({
          title: this.$t('mailings.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.deleteProcess = false
        this.deleteMailingPopupActive = false
      })
    },
    onGridReady (params) {
      this.gridApi = params.api
    }
  }
}
</script>

<style lang="scss" scoped>
.add-button {
  margin-bottom: 30px;
}
.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.buttons-wrapper {
  margin-bottom: 30px;
}
.buttons-wrapper > button{
  display: inline-block;
  margin-right: 30px;
}
.sort-table-wrapper {
  width: 100%;
}
.list-item {
  transition: all 1s;
}
.list-item:hover {
  cursor: all-scroll;
}
.excel-file-buttons-container {
  display: flex;
  //justify-content: space-between;
  align-items: flex-end;
  justify-content: flex-end;

  & > button {
    margin-left: 15px;
    margin-bottom: 15px;
  }

  & > button:first-child {
    margin-left: 0;
    margin-right: auto;
  }
}
</style>
