<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
    <feather-icon v-if="!isOnlyRead" icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="confirmDeleteRecord()" />
  </div>
</template>

<script>
export default {
  name: 'CellRenderActions',
  methods: {
    editRecord () {
      this.$store.commit('setListPage', this.params.api.paginationGetCurrentPage())
      this.$router.push(`${this.$route.path}/edit/${this.params.value}`).catch(() => {})
    },
    confirmDeleteRecord () {
      this.params.context.componentParent.deleteShopCategoriesPopupActive = true
      this.params.context.componentParent.deleteShopCategoriesPopupContent = this.$t('shop_categories.confirm_delete.message').replace('[NAME]', this.params.data.label)
      this.params.context.componentParent.deleteShopCategoriesId = this.params.value
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-shop'] === 'read'
      }
    }
  }
}
</script>
