import FormError from './../../modules/formError'

export default {
  suite: [],
  select: [],
  edit: {
    title: '',
    content: '',
    url: '',
    startAfter: null,
    sendAll: false,
    targetCount: false,
    usersSelected: [],
    clubsSelected: [],
    programmsSelected: [],
    challengesSelected: [],
    eventsSelected: [],
    trainingsSelected: [],
    pedometersSelected: []
  },
  formError: new FormError()
}
