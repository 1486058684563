import FormError from './../../modules/formError'

export default {
  suite: [],
  select: [],
  edit: {
    name: '',
    is_active: '',
    description: '',
    image: ''
  },
  formError: new FormError()
}
