import axios from '@/axios.js'

export default {
  getTagsOptions ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/tags/digest_list')
        .then((response) => {
          commit('retrieveTagsOptions', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
