/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const data = [
  {
    url: '/',
    name: 'Home',
    access_name: '',
    slug: 'home',
    icon: 'HomeIcon',
    i18n: 'menu.home'
  },
  {
    name: 'Content',
    access_name: 'content',
    slug: 'content',
    icon: 'BookIcon',
    i18n: 'menu.content',
    submenu: [
      {
        url: '/relevant',
        name: 'relevant',
        access_name: 'admin-relevant',
        slug: 'relevant',
        icon: 'StarIcon',
        i18n: 'menu.relevant'
      },
      {
        url: '/clubs',
        name: 'Clubs',
        access_name: 'admin-clubs-with-program',
        slug: 'clubs',
        icon: 'GridIcon',
        i18n: 'menu.clubs'
      },
      {
        url: '/events',
        name: 'Events',
        access_name: 'admin-events',
        slug: 'events',
        icon: 'CalendarIcon',
        i18n: 'menu.calendar_of_events'
      },
      {
        url: '/personal-goals',
        name: 'personal-goals',
        access_name: 'admin-missions',
        slug: 'personal-goals',
        icon: 'TriangleIcon',
        i18n: 'menu.personal_goals'
      },
      {
        url: '/habits_trackers',
        name: 'habits-trackers',
        access_name: 'admin-missions',
        slug: 'habits-trackers',
        icon: 'CheckSquareIcon',
        i18n: 'menu.habits_trackers'
      },
      {
        url: '/pedometer/challenges',
        name: 'Pedometer challenges',
        access_name: 'admin-pedometer',
        slug: 'pedometer-challenges',
        icon: 'CrosshairIcon',
        i18n: 'menu.pedometer_challenges'
      },
      {
        url: '/common-goals',
        name: 'Common goals',
        access_name: 'admin-pedometer',
        slug: 'common-goals',
        icon: 'CrosshairIcon',
        i18n: 'menu.common_goals'
      },
      {
        url: '/blog/posts',
        name: 'Blog posts',
        access_name: 'admin-blog',
        slug: 'blog-posts',
        icon: 'FileTextIcon',
        i18n: 'menu.blog_posts'
      },
      {
        url: '/faq',
        name: 'FAQ',
        access_name: 'admin-faq',
        slug: 'faq',
        icon: 'AwardIcon',
        i18n: 'menu.faq'
      }
    ]
  },
  {
    name: 'Communication',
    access_name: 'admin-mailings',
    slug: 'communication',
    icon: 'MailIcon',
    i18n: 'menu.communication',
    submenu: [
      {
        url: '/notifications',
        name: 'Notifications',
        access_name: 'admin-mailings',
        slug: 'notifications',
        icon: 'MessageCircleIcon',
        i18n: 'menu.notifications'
      },
      {
        url: '/mailings',
        name: 'Mailings',
        access_name: 'admin-mailings',
        slug: 'mailings',
        icon: 'MailIcon',
        i18n: 'menu.mailings'
      },
      {
        url: '/announcements',
        name: 'Announcements',
        access_name: 'admin-announcements',
        slug: 'announcement',
        icon: 'AlertCircleIcon',
        i18n: 'menu.announcements'
      },
      {
        url: '/deactivate-users-mailings',
        name: 'deactivate-users',
        access_name: 'admin-messages',
        slug: 'messages',
        i18n: 'menu.deactivating_users'
      }
    ]
  },
  {
    name: 'services',
    access_name: 'admin-services',
    slug: 'services',
    icon: 'LayersIcon',
    i18n: 'menu.services',
    submenu: [
      {
        url: '/services',
        name: 'services-catalog',
        access_name: 'admin-services',
        slug: 'services-catalog',
        icon: 'LayersIcon',
        i18n: 'menu.services_catalog'
      },
      {
        url: '/geocaching',
        name: 'Geocaching',
        access_name: 'admin-geocaching',
        slug: 'geocaching',
        icon: 'MapIcon',
        i18n: 'menu.geocaching'
      },
      {
        url: '/Raffle',
        name: 'Raffle',
        access_name: 'admin-raffle',
        slug: 'raffle',
        icon: 'GiftIcon',
        i18n: 'menu.raffle'
      },
      {
        url: '/shop/products',
        name: 'Shop',
        access_name: 'admin-shop',
        slug: 'shop-products',
        icon: 'ShoppingCartIcon',
        i18n: 'menu.shop',
        submenu: [
          {
            url: '/shop/products',
            name: 'Products',
            access_name: 'admin-shop-products',
            slug: 'shop-products',
            icon: 'ShoppingCartIcon',
            i18n: 'menu.shop_products'
          },
          {
            url: '/shop/categories',
            name: 'Categories',
            access_name: 'admin-shop-categories',
            slug: 'shop-categories',
            icon: 'ShoppingCartIcon',
            i18n: 'menu.shop_categories'
          },
          {
            url: '/shop/orders',
            name: 'Orders',
            access_name: 'admin-shop-orders',
            slug: 'shop-orders',
            icon: 'ShoppingCartIcon',
            i18n: 'menu.shop_orders'
          },
          {
            url: '/shop/settings',
            name: 'Settings',
            access_name: 'admin-shop-settings',
            slug: 'shop-settings',
            i18n: 'menu.shop_settings'
          }
        ]
      },
      {
        name: 'DMS module',
        access_name: 'admin-cafeteria',
        slug: 'cafeteria',
        icon: 'ShieldIcon',
        i18n: 'menu.cafeteria',
        submenu: [
          {
            url: '/cafeteria/catalog',
            name: 'cafeteria-catalog',
            access_name: 'admin-cafeteria',
            slug: 'cafeteria',
            i18n: 'menu.cafeteria_catalog'
          },
          {
            url: '/cafeteria/orders',
            name: 'cafeteria-orders',
            access_name: 'admin-cafeteria',
            slug: 'cafeteria-orders',
            i18n: 'menu.cafeteria_orders'
          }
        ]

      }
    ]
  },
  {
    url: '/users',
    name: 'Users',
    access_name: 'admin-users',
    slug: 'users',
    icon: 'UserIcon',
    i18n: 'menu.users',
    submenu: [
      {
        url: '/users',
        name: 'Users list',
        access_name: 'admin-users',
        slug: 'users-list',
        icon: 'UserIcon',
        i18n: 'menu.users_list'
      },
      {
        url: '/reports',
        name: 'Reports',
        access_name: 'admin-reports',
        slug: 'reports',
        icon: 'FileIcon',
        i18n: 'menu.reports'
      },
      {
        url: '/chat-messages',
        name: 'chat-messages',
        access_name: 'admin-chat-messages',
        slug: 'chat-messages',
        icon: 'MailIcon',
        i18n: 'menu.message'
      }
    ]
  },
  {
    url: '/pollings',
    name: 'polling',
    access_name: 'admin-pollings',
    slug: 'polling',
    icon: 'CheckSquareIcon',
    i18n: 'menu.pollings',
    submenu: [
      {
        url: '/pollings',
        name: 'polling',
        access_name: 'admin-pollings',
        slug: 'polling',
        icon: 'CheckSquareIcon',
        i18n: 'menu.pollings'
      },
      {
        url: '/polling_healths',
        name: 'polling_health',
        access_name: 'admin-polling_healths',
        slug: 'polling_health',
        icon: 'ActivityIcon',
        i18n: 'menu.polling_health'
      }
    ]
  },
  {
    url: '/settings',
    name: 'All settings',
    access_name: 'admin-settings',
    slug: 'all-settings',
    icon: 'SettingsIcon',
    i18n: 'menu.all_settings',
    submenu: [
      {
        url: '/segments',
        name: 'Segments',
        access_name: 'admin-settings-segments',
        slug: 'segments',
        icon: 'PieChartIcon',
        i18n: 'menu.segments'
      },
      {
        url: '/activities',
        name: 'Activities',
        access_name: 'admin-settings-activities',
        slug: 'activity',
        icon: 'SmileIcon',
        i18n: 'menu.activities'
      },
      {
        url: '/teams',
        name: 'Teams',
        access_name: 'admin-settings-teams',
        slug: 'team',
        icon: 'UsersIcon',
        i18n: 'menu.teams'
      },
      {
        url: '/points-settings',
        name: 'Points settings',
        access_name: 'admin-settings-points-settings',
        slug: 'points-settings',
        icon: 'SettingsIcon',
        i18n: 'menu.points_settings'
      },
      {
        url: '/content-pages',
        name: 'Content pages',
        access_name: 'admin-settings-content-pages',
        slug: 'content-pages',
        icon: 'SmartphoneIcon',
        i18n: 'menu.content_pages'
      },
      {
        url: '/achievements',
        name: 'Achievements',
        access_name: 'admin-settings-achievements',
        slug: 'achievements',
        icon: 'AwardIcon',
        i18n: 'menu.achievements'
      },
      {
        url: '/registration-codes',
        name: 'Registration_codes',
        access_name: 'admin-settings-registration-codes',
        slug: 'registration_codes',
        icon: 'AwardIcon',
        i18n: 'menu.registration_codes'
      }
    ]
  }
]


export default data
