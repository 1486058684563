import state from './moduleTrainingState.js'
import mutations from './moduleTrainingMutations.js'
import actions from './moduleTrainingActions.js'
import getters from './moduleTrainingGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
