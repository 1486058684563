<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-else class="vx-card tabs-container px-6 pt-6 pb-3">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-input class="w-full mb-4" :label-placeholder="$t('points_settings.fields.name')" v-model="name" />
          <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
        </div>
      </div>

      <!-- <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-input class="w-full" :label-placeholder="$t('content_pages.' + type + '.labels.slug')" v-model="slug" />
          <span class="text-danger text-sm" v-show="formError.has('slug')">{{ $t(formError.get('slug')) }}</span>
        </div>
      </div> -->

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-input class="w-full mb-4" :label-placeholder="$t('points_settings.fields.point_type')" v-model="pointType" />
          <span class="text-danger text-sm" v-show="formError.has('point_type')">{{ $t(formError.get('point_type')) }}</span>
        </div>
      </div>


      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mb-2">
          <vs-input class="w-full mb-4" :label-placeholder="$t('points_settings.fields.coins')" v-model="coins" />
          <span class="text-danger text-sm" v-show="formError.has('coins')">{{ $t(formError.get('coins')) }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2">
          <vs-input class="w-full mb-4" :label-placeholder="$t('points_settings.fields.point')" v-model="point" />
          <span class="text-danger text-sm" v-show="formError.has('point')">{{ $t(formError.get('point')) }}</span>
        </div>
      </div>

      <!-- <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-input class="w-full mb-4" :label-placeholder="$t('points_settings.fields.plus_point')" v-model="plusPoint" />
          <span class="text-danger text-sm" v-show="formError.has('plus_point')">{{ $t(formError.get('plus_point')) }}</span>
        </div>
      </div> -->

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-input class="w-full mb-4" :label-placeholder="$t('points_settings.fields.max_appends')" v-model="maxAppends" />
          <span class="text-danger text-sm" v-show="formError.has('max_appends')">{{ $t(formError.get('max_appends')) }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('points_settings.fields.is_public') }}</label>
          <vs-switch v-model="isPublic" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
          <span class="text-danger text-sm" v-show="formError.has('is_public')">{{ $t(formError.get('is_public')) }}</span>
        </div>
      </div>
    </div>
    <div v-if="!isOnlyRead" class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button 
            @click="action(stayAfter = true)" 
            :disabled="actionButtonDisabled">
            {{ $t('points_settings.' + type + '.buttons.' + type) }}
          </vs-button>

          <vs-button 
            @click="action()" 
            :disabled="actionButtonDisabled">
            {{ $t('points_settings.' + type + '.buttons.' + type) }} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
  </div>
</template>

<script>
import helpers from '@/helpers/helpers.js'
import loading from '@/views/components/Loading.vue'

export default{
  name: 'CreateEditPointSetting',
  components: {
    loading
  },
  data () {
    return {
      id: 0,
      type: 'create',
      actionButtonDisabled: false,
      loading: true,
      name: '',
      pointType: '',
      point: 0,
      coins: '',
      plusPoint: 0,
      isPublic: '',
      maxAppends: ''
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-settings-points-settings'] === 'read' ||  
               accessUser['admin-settings'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['point_setting/formError']
    }
  },
  methods: {
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'point_setting/create' : 'point_setting/update'
      this.dispatch(patch, stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        const payload = {
          id: this.id,
          name: this.name,
          point_type: this.pointType,
          point: this.point || 0,
          coins: this.coins || 0,
          plus_point: this.plusPoint || 0,
          is_public: +this.isPublic,
          max_appends: this.maxAppends
        }

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name: 'points-settings'
          },
          routerPushToEdit: {
            name: 'points-settings-edit'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    }
  },
  created () {
    this.id = this.$route.params.id
    if (this.$router.currentRoute.name === 'points-settings-edit') {
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('point_setting/edit', { id: this.id})
        .then(() => {
          const edit = this.$store.getters['point_setting/edit']
          this.name = edit.name
          this.pointType = edit.pointType
          this.point = edit.point || 0
          this.coins = edit.coins || 0
          this.plusPoint = edit.plusPoint || 0
          this.isPublic = edit.isPublic
          this.maxAppends = edit.maxAppends
          this.id = edit.id

          this.loading = false
        })
    } else {
      this.loading = false
    }
    this.$store.commit('point_setting/clearFormError')
  }
}
</script>

<style>
</style>
