import axios from '@/axios.js'

export default {
  getLabelsOptions ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/categories/labels?is_unique_names=1')
        .then((response) => {
          commit('retrieveLabelsOptions', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
