<template>

  <div id="page-clubadmins-list">
    <div class="vx-row">

      <div class="vx-col md:w-1/2 w-full">
        <vs-button color="primary" type="gradient" icon-pack="feather" icon="icon-plus" class="add-button" :disabled="isOnlyRead" @click="$router.push({ name: 'club-create'})"></vs-button>
      </div>
      <div class="vx-col md:w-1/2 w-full mb-base flex justify-end">
        <vs-button color="primary" type="border" icon-pack="feather" icon="icon-list" @click="showSortTable = !showSortTable" :disabled="isOnlyRead">{{ $t('clubs.buttons.priority') }}</vs-button>
      </div>
    </div>

    <div class="vx-row">
      <loading v-if="loading"></loading>

      <div class="vx-col w-full mb-base" v-if="!showSortTable&&!loading">
        <div class="vx-card p-6" v-if="!loading">
          <!-- AgGrid Table -->
          <ag-grid-vue
            @gridReady="onGridReady"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4"
            domLayout="autoHeight"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="suite"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl"
          />

          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage" />
        </div>
      </div>

      <div v-if="showSortTable&&!loading" class="sort-table-wrapper">
        <vs-list>
          <draggable :list="suite" @change="sort(suite)">
            <transition-group>
              <vs-list-item 
                class="list-item" 
                v-for="club in suite" 
                :key="club.id" 
                :title="club.name"
                >
                <vs-avatar 
                  slot="logo_path" 
                  :src="club.logo_path" 
                />
              </vs-list-item>
            </transition-group>
          </draggable>
        </vs-list>
      </div>


    </div>
  </div>

</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import draggable from 'vuedraggable'

// Cell Renderer
import CellRendererLink from '../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../components/cell-renderer/clubs/CellRendererActions.vue'

import loading from '@/views/components/Loading.vue'

export default {
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,

    loading,
    draggable
  },
  data () {
    return {
      showSortTable: false,
      // AgGrid
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: { 'textAlign': 'center'}
      },
      columnDefs: [
        {
          field: 'logo_path',
          headerName: this.$t('clubs.suite.table.avatar'),
          minWidth: 50,
          maxWidth: 95,
          sortable: true,
          cellStyle: { 'margin': 'auto'},
          cellRenderer: 'CellRendererLink'
        },
        {
          headerName: this.$t('clubs.suite.table.name'),
          field: 'name',
          filter: true,
          minWidth: 100,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 24px'}
        },
        {
          field: 'id',
          headerName: this.$t('users.suite.table.actions'),
          minWidth: 120,
          maxWidth: 120,
          cellRenderer: 'CellRendererActions'
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      },
      loading: true
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-clubs-with-program'] === 'read'
      }
    },
    suite () { return this.$store.getters['club/suite'] },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },
  methods:{
    sort (suite) {
      const payload = {
        suite: this.suite
      }
      this.$store.dispatch('club/sort', payload)
    },
    onGridReady (params) {
      this.gridApi = params.api
    }
  },
  created () {
    this.$store.dispatch('club/getSuite').then(() => {
      this.loading = false
    }).catch(err => { console.error(err) })
  }
}

</script>

<style lang="scss" scoped>
  #page-clubadmins-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
  .add-button {
    margin-bottom: 30px;
  }

  ::v-deep .ag-theme-material {
    .ag-header-cell-label {
      justify-content: start;
    }
  }

</style>
