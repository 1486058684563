export default {
  suite (state) {
    return state.suite
  },
  edit (state) {
    return state.edit
  },
  activitiesOptions (state) {
    return state.activitiesOptions
  },
  formError (state) {
    return state.formError
  }
}
