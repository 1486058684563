<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-button color="primary" type="gradient" icon-pack="feather" icon="icon-plus" class="add-button" :disabled="isOnlyRead" @click="$router.push({ path: `${$route.path}/create-training`, params: { categoryId: categoryId }})"></vs-button>
      </div>
      <div class="vx-col md:w-1/2 w-full mb-base flex justify-end">
        <vs-button color="primary" type="border" icon-pack="feather" icon="icon-list" :disabled="isOnlyRead" @click="showSortTable = !showSortTable">{{$t('trainings.suite.buttons.sort')}}</vs-button>
      </div>
      <div class="vx-col w-full mb-base" v-if="!showSortTable">
        <loading v-if="loading"></loading>
        <div class="vx-card p-6"  v-if="!loading">

          <!-- AgGrid Table -->
          <ag-grid-vue
            @gridReady="onGridReady"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4"
            domLayout="autoHeight"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="suite"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl"
          />

          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage"
          />

        </div>
      </div>
      <div v-if="showSortTable && !loading" class="sort-table-wrapper">
        <vs-list>
          <draggable :list="suite" @change="sort()">
            <transition-group>
              <vs-list-item class="list-item" v-for="training in suite" :key="training.id" :title="training.name + ' ' + training.date">
              </vs-list-item>
            </transition-group>
          </draggable>
        </vs-list>
      </div>
    </div>
    <vs-popup class="holamundo" :title="$t('trainings.suite.popups.delete.title')" :active.sync="deletePopupActive">
      <p>{{ deletePopupContent }}</p>
      <div class="delete-confirm-popup-buttons" v-if="!showDeletePopupWaitMessage">
        <vs-button color="primary" type="filled" @click="deleteTraining">{{ $t('trainings.suite.popups.delete.buttons.confirm') }}</vs-button>
        <vs-button color="warning" type="filled" @click="deletePopupActive=false">{{ $t('trainings.suite.popups.delete.buttons.cancel') }}</vs-button>
      </div>
      <p v-if="showDeletePopupWaitMessage" class="delete-popup-wait-message">{{ $t('trainings.suite.popups.delete.wait_message') }} <font-awesome-icon icon="spinner" spin /></p>
    </vs-popup>

    <div v-if="showCopyPopupWaitMessage">
      <font-awesome-icon icon="spinner" spin />
    </div>

  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import draggable from 'vuedraggable'
import moment from 'moment'

import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererLink from '../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../components/cell-renderer/trainings/CellRendererActions.vue'

import loading from '@/views/components/Loading.vue'

library.add(faSpinner)

export default{
  components: {
    loading,
    draggable,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,
    AgGridVue
  },
  data () {
    return {
      categoryId: 0,
      deletePopupActive: false,
      deletePopupContent: '',
      deleteTrainingId: 0,
      deleteTrainingIndex: 0,
      showDeletePopupWaitMessage: false,
      showCopyPopupWaitMessage: false,
      loading: true,
      showSortTable: false,

      // AgGrid
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: { 'textAlign': 'center'},
        headerClass: 'text-center'
      },
      columnDefs: [
        {
          headerName: this.$t('trainings.suite.table.name'),
          field: 'name',
          filter: true,
          minWidth: 250,
          maxWidth: 1000,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 24px'},
          headerClass: 'text-left'
        },
        {
          headerName: this.$t('trainings.suite.table.date'),
          field: 'date',
          valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD.MM.YYYY') : ''
          },
          minWidth: 160,
          maxWidth: 170,
          sortable: true
        },
        {
          headerName: this.$t('teams.table.actions'),
          field: 'id',
          minWidth: 110,
          maxWidth: 130,
          cellRenderer: 'CellRendererActions'
        }
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      }
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {     
        return this.$store.getters.accessUser['admin-clubs-with-program'] === 'read'
      }
    },

    suite () {
      return JSON.parse(JSON.stringify(this.$store.getters['training/suite'])) || []
    },
    
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()

      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },
  created () {
    this.categoryId = this.$route.params.categoryId
    this.$store.dispatch('training/getSuite', { category_id: this.categoryId}).then(() => {
      this.loading = false
    })
  },
  methods: {
    deleteTraining () {
      this.showDeletePopupWaitMessage = true
      this.$store.dispatch('training/delete', { id: this.deleteTrainingId, index: this.deleteTrainingIndex}).catch(error => {
        this.$vs.notify({
          title: 'Server error',
          text: error,
          color: 'danger'
        })
      }).then(() => {
        this.deletePopupActive = false
        this.showDeletePopupWaitMessage = false
      })
    },
    sort () {
      const payload = {
        category_id: this.categoryId,
        suite: this.suite
      }
      this.$store.dispatch('training/sort', payload)
    },
    onGridReady (params) {
      this.gridApi = params.api
    }
  }
}
</script>

<style lang="scss" scoped>
  .add-button {
    margin-bottom: 30px;
  }
  .delete-confirm-popup-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  .delete-popup-wait-message {
    margin-top: 30px;
  }
  .sort-table-wrapper {
    width: 100%;
  }
  .list-item {
    transition: all 1s;
  }
  .list-item:hover {
    cursor: all-scroll;
  }

</style>
