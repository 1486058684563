<template>
  <div id="page-category-members-list">

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
      </div>
      <user-nav-bar :user_id="$route.params.user_id"></user-nav-bar>
    </div>

    <loading v-if="loading"></loading>

    <div v-if="!loading" class="vx-col w-full vx-card p-4 mb-4">
      <strong>Пользователь #{{ userData.id }}:</strong> {{ userData.email }}
    </div>

    <div class="vx-card p-6" v-if="!loading">
      <!-- AgGrid Table -->
      <ag-grid-vue
        @gridReady="onGridReady"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4"
        domLayout="autoHeight"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="suite"
        rowSelection="multiple"
        colResizeDefault="shift"
        width="100%"
        flex="1"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage" />

    </div>

    <vs-popup class="holamundo" :title="$t('users.edit.steps.popups.confirm_delete.title')" :active.sync="deleteItemPopupActive">
      <p>{{ deleteItemPopupContent }}</p>
      <div v-if="deleteProcess">
        <font-awesome-icon icon="spinner" spin />
      </div>
      <div class="delete-confirm-popup-buttons" v-else>
        <vs-button color="primary" type="filled" @click="deleteSteps()">{{ $t('users.edit.steps.popups.confirm_delete.buttons.confirm') }}</vs-button>
        <vs-button color="warning" type="filled" @click="deleteItemPopupActive=false">{{ $t('users.edit.steps.popups.confirm_delete.buttons.cancel') }}</vs-button>
      </div>
    </vs-popup>

    <vs-popup class="holamundo" :title="$t('users.edit.steps.popups.confirm_activate.title')" :active.sync="activateItemPopupActive">
      <p>{{ activatePopupContent }}</p>
      <div v-if="activateProcess">
        <font-awesome-icon icon="spinner" spin />
      </div>
      <div class="delete-confirm-popup-buttons" v-else>
        <vs-button color="primary" type="filled" @click="activateSteps()">{{ $t('users.edit.steps.popups.confirm_activate.buttons.confirm') }}</vs-button>
        <vs-button color="warning" type="filled" @click="activateItemPopupActive=false">{{ $t('users.edit.steps.popups.confirm_activate.buttons.cancel') }}</vs-button>
      </div>
    </vs-popup>

    <vs-popup class="holamundo" :title="$t('users.edit.trainings.popups.training.title')" :active.sync="valueItemPopupActive">
      <p v-html="valuePopupContent"></p>
      <div v-if="valueProcess">
        <font-awesome-icon icon="spinner" spin />
      </div>
      <div class="delete-confirm-popup-buttons" v-else>
        <vs-button color="warning" type="filled" @click="valueItemPopupActive=false">{{ $t('users.edit.trainings.popups.value.buttons.cancel') }}</vs-button>
      </div>
    </vs-popup>

  </div>

</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererLink from '../../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../../components/cell-renderer/users/steps/CellRendererActions.vue'
import DeletedAt from '../../components/cell-renderer/users/steps/DeletedAt.vue'
import loading from '@/views/components/Loading.vue'
import UserNavBar from '../../components/user/Nav.vue'

export default {
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,
    DeletedAt,
    UserNavBar,
    loading
  },
  data () {
    return {
      // AgGrid
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: { 'textAlign': 'center', 'padding': '0px'},
        headerClass: 'text-center'
      },
      columnDefs: [
        {
          headerName: this.$t('users.edit.trainings.table.id'),
          field: 'id',
          minWidth: 70,
          maxWidth: 70,
          sortable: true
        },
        {
          headerName: this.$t('users.edit.trainings.table.title'),
          field: 'unified_title',
          filter: true,
          minWidth: 200,
          maxWidth: 400,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.5', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 6px'},
          headerClass: 'text-left'
        },
        {
          headerName: this.$t('users.edit.trainings.table.results_text'),
          field: 'results_text',
          filter: true,
          minWidth: 250,
          maxWidth: 1000,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 6px'},
          headerClass: 'text-left',
          onCellClicked: this.showCellValuePopup,
          cellRenderer: params => {
            return params.value
          }
        },
        {
          headerName: this.$t('users.edit.trainings.table.points'),
          field: 'point',
          minWidth: 90,
          maxWidth: 90,
          sortable: true
        },
        {
          headerName: this.$t('users.edit.trainings.table.like_count'),
          field: 'like_count',
          minWidth: 60,
          maxWidth: 70,
          sortable: true
        },
        {
          headerName: this.$t('users.edit.trainings.table.dislike_count'),
          field: 'dislike_count',
          minWidth: 70,
          maxWidth: 70,
          sortable: true
        },
        {
          headerName: this.$t('users.edit.trainings.table.created_at'),
          field: 'created_at',
          minWidth: 140,
          maxWidth: 150,
          sortable: true
        },
        {
          headerName: this.$t('users.edit.trainings.table.updated_at'),
          field: 'updated_at',
          minWidth: 140,
          maxWidth: 150,
          sortable: true
        },
        {
          headerName: this.$t('users.edit.trainings.table.deleted_at'),
          field: 'deleted_at',
          minWidth: 140,
          maxWidth: 150,
          sortable: true
        }/*,
        {
          headerName: this.$t('users.suite.table.actions'),
          field: 'id',
          width: 130,
          cellRenderer: 'CellRendererActions'
        }*/
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions,
        DeletedAt
      },
      loading: true,
      deleteItemPopupActive: false,
      deleteItemPopupContent: '',
      deleteProcess: false,
      deleteItemId: 0,
      activateItemPopupActive: false,
      activatePopupContent: '',
      activateProcess: false,
      activateItemId: 0,
      valueItemPopupActive: false,
      valuePopupContent: '',
      valueProcess: false
    }
  },
  created () {
    this.$store.dispatch('user/getTrainings', { user_id: this.$route.params.user_id }).then(() => {
      this.loading = false
    })
  },
  methods: {
    deleteSteps () {
      this.deleteProcess = true
      this.$store.dispatch('user/deleteSteps', { user_id: this.$route.params.user_id, steps_id:this.deleteItemId }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.deleteProcess = false
        this.deleteItemPopupActive = false
      })
    },
    activateSteps () {
      this.activateProcess = true
      this.$store.dispatch('user/activateSteps', { user_id: this.$route.params.user_id, steps_id: this.activateItemId }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.activateProcess = false
        this.activateItemPopupActive = false
      })
    },
    showCellValuePopup (event) {
      console.log(event.value)
      this.valueItemPopupActive = true
      this.valuePopupContent = event.value
    },
    onGridReady (params) {
      this.gridApi = params.api
    }
  },
  computed: {
    suite () {
      return this.$store.getters['user/trainings']
    },
    userData () {
      return this.$store.getters['user/userData']
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 20
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  }
}

</script>

<style lang="scss">
#page-clubadmins-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.vs-button-filled {
  color: #fff !important;
  margin-left: 15px;
}

</style>
