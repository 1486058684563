<!-- =========================================================================================
  File Name: SegmentList.vue
  Description: Segment List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <div v-show="!isOnlyRead" class="vx-col w-full mb-base">
        <vs-button color="primary" type="gradient" icon-pack="feather" icon="icon-plus" class="add-button" @click="$router.push({ name: 'segments-create'})"></vs-button>
      </div>
      <div class="vx-col w-full mb-base">
        <loading v-if="loading"></loading>
        <div class="vx-card p-6" v-if="!loading">
          <!-- AgGrid Table -->
          <ag-grid-vue
            @gridReady="onGridReady"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4"
            domLayout="autoHeight"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="suite"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl">
          </ag-grid-vue>

          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage" />

        </div>
        <vs-popup class="holamundo" title="" :active.sync="deleteSegmentPopupActive">
          <p>{{ deleteSegmentPopupContent }}</p>
          <div v-if="deleteProcess">
            <font-awesome-icon icon="spinner" spin />
          </div>
          <div class="delete-confirm-popup-buttons" v-else>
            <vs-button color="primary" type="filled" @click="deleteSegment()">{{ $t('segments.suite.popups.confirm_delete.buttons.confirm') }}</vs-button>
            <vs-button color="warning" type="filled" @click="deleteSegmentPopupActive=false">{{ $t('segments.suite.popups.confirm_delete.buttons.cancel') }}</vs-button>
          </div>
        </vs-popup>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererLink from '../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../components/cell-renderer/segments/CellRendererActions.vue'

import moment from 'moment'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)

export default{
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,

    loading
  },
  data () {
    return {
      loading: true,
      // AgGrid
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1
      },
      columnDefs: [
        {
          headerName: this.$t('segments.table.id'),
          field: 'id',
          minWidth: 60,
          maxWidth: 70,
          sortable: true,
          headerClass: 'text-center'
        },
        {
          headerName: this.$t('segments.table.name'),
          field: 'name',
          minWidth: 180,
          maxWidth: 400,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'justify-content': 'flex-start', 'word-break': 'break-word'},
          filter: true
        },
        {
          headerName: this.$t('segments.table.slug'),
          field: 'slug',
          minWidth: 180,
          maxWidth: 400,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'justify-content': 'flex-start', 'word-break': 'break-word'},
          filter: true
        },
        {
          headerName: this.$t('segments.table.created_at'),
          field: 'created_at',
          valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD.MM.YYYY') : ''
          },
          minWidth: 150,
          maxWidth: 170,
          sortable: true,
          headerClass: 'text-center'
        },
        {
          headerName: this.$t('segments.table.actions'),
          field: 'id',
          minWidth: 110,
          maxWidth: 130,
          cellRenderer: 'CellRendererActions'
        }
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deleteSegmentPopupActive: false,
      deleteSegmentPopupContent: '',
      deleteProcess: false,
      deleteSegmentId: 0
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-settings-segments'] === 'read' ||  
               accessUser['admin-settings'] === 'read'
      }
    },
    suite () { return this.$store.getters['segment/suite'] },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },
  created () {
    this.$store.dispatch('segment/getSuite').then(() => {
      this.loading = false
    })
  },
  methods:{
    deleteSegment () {
      this.deleteProcess = true
      this.$store.dispatch('segment/delete', { id: this.deleteSegmentId }).catch(error => {
        this.$vs.notify({
          title: this.$t('segments.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.deleteProcess = false
        this.deleteSegmentPopupActive = false
      })
    },
    onGridReady (params) {
      this.gridApi = params.api
    }
  }
}
</script>

<style lang="scss" scoped>
.add-button {
  margin-bottom: 30px;
}
.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

::v-deep {
    .ag-cell {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
