<template>
  <div class="management">

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">

      </div>
      <user-nav-bar :user_id="$route.params.user_id"></user-nav-bar>
    </div>

    <div id="page-user-management">
      <loading v-if="loading"></loading>
      <div v-else>
        <vx-card class="mb-6">
          <h4>{{$t('users.edit.buttons.link_to_password_recovery')}}</h4>
          <vs-button class="ml-0 mt-4" color="danger" type="filled" @click="getResetPasswordUrl()">{{ $t('users.edit.buttons.reset_password') }}</vs-button>
          <div class="mt-4 mb-4">{{ reset_password_url }}</div>
        </vx-card>
        <vx-card>
          <h4 class="mb-6">{{ $t('users.edit.access_settings.title') }}</h4>

          <!-- доступы админа в админке -->
          <div v-if="userData?.role === 'admin'">
            <p v-if="showMassageAccessRestriction" class="text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_access_restriction') }}</p>
            <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
            <div class="vx-row grid" v-if="adminAccessData.length">
              <div class="vx-col" v-for="(item) in adminAccessData" :key="item.module">
                <h5 class="w-full text-xl font-bold mb-3">{{ $t(`users.edit.access_settings.modules.${item.module}`) }}</h5>
                <ul class="radio-group" v-if="accessModules?.access_values">
                  <li v-for="(value, index) in accessModules.access_values" :key="index">
                    <vs-radio 
                      v-if="!(item.module === 'admin-create-admin' && value === 'read') && !(item.module === 'admin-users' && value === 'none')"
                      v-model="item.access_level" 
                      :vs-value="value"
                      :vs-name="item.module"
                      :class="{ 'font-bold': item.access_level === value }"
                      :disabled="!checkAccess(item)"
                      >
                      {{$t(`users.edit.access_settings.${value}`)}}
                    </vs-radio>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- доступы атлета в приложении -->
          <div v-if="userData && ['athlete', 'coach', 'family'].includes(userData.role)">
            <div class="vx-row grid" v-if="adminAccessData.length">
              <div class="vx-col" v-for="(item) in adminAccessData" :key="item.module">
                <h5 class="w-full text-xl font-bold mb-3">{{ $t(`users.edit.access_settings.modules.${item.module}`) }}</h5>
                <ul class="radio-group">
                  <li v-for="(value, index) in accessModules.access_values" :key="index">
                    <vs-radio 
                      v-if="showOption(item, value)"
                      v-model="item.access_level" 
                      :vs-value="value"
                      :vs-name="item.module"
                      :class="{ 'font-bold': item.access_level === value }"
                      >
                      {{$t(`users.edit.access_settings.athlete.${value}`)}}
                    </vs-radio>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </vx-card>
        <div class="vx-row">
          <div class="vx-col ml-auto">
            <vs-button class="mt-6" @click="updateAccesses" :disabled="showMassageAccessRestriction || loading || isOnlyRead">{{ this.$t('users.edit.buttons.save') }}</vs-button>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import loading from '@/views/components/Loading.vue'
import UserNavBar from '../../components/user/Nav.vue'
import VxCard from '../../../components/vx-card/VxCard.vue'

export default {
  components: {
    UserNavBar,
    loading,
    VxCard
  },
  data () {
    return {
      id: '',
      loading: false,
      reset_password_url: '',
      showMassageAccessRestriction: false
    }
  },
  methods: {
    updateAccesses () {
      this.loading = true
      const userAccesses = {}

      this.adminAccessData.forEach(item => {
        const { module, access_level } = item
        if (!userAccesses[access_level]) {
          userAccesses[access_level] = []
        }
        userAccesses[access_level].push(module)
      })

      this.$store.dispatch('user/updateAccessesModules', { 
        user_id: this.id,
        user_accesses: userAccesses
      }).then(() => {
        this.$vs.notify({
          title: this.$t('users.suite.success.title'),
          text: this.$t('users.suite.success.save'),
          color: 'success',
          time: 3000
        })
        this.loading = false
      }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
        this.loading = false
      })
    },
    getResetPasswordUrl () {
      this.$store.dispatch('user/getResetPasswordUrl', { user_id: this.$route.params.user_id }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(res => {
        this.reset_password_url = res.data.data.url
      })
    },
    checkAccess (module) {
      if (!this.userInfo) {
        return false
      }
      if (+module.user_id === +this.userInfo.id) {
        this.showMassageAccessRestriction = true
        return false
      } else  
      if (this.userInfo.accesses[module.module] !== 'write') {
        return false
      } else {
        return !this.isOnlyRead
      }
    },
    showOption (item, value) {
      if (value === 'read') {
        return item.module === 'comments'
      }
      return true
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-users'] === 'read'
      }
    },
    userInfo () {
      return this.$store.getters.AppActiveUser
    },
    userData () {
      return this.$store.getters['user/editData']
    },
    accessModules () {
      return this.$store.getters['user/adminAccessesModules']
    },
    adminAccessData () {
      const data = JSON.parse(JSON.stringify(this.$store.getters['user/adminAccesses']))
      const role = data.role
      if (data) {
        let accesses = data.accesses?.sort((a, b) => a.module.localeCompare(b.module))
        if (role === 'family') {
          accesses = accesses.filter(item => !['families'].includes(item.module))
        }
        return accesses
      }
    }
  },
  created () {
    const id = +this.$route.params.user_id
    this.id = id
    this.$store.dispatch('user/edit', { id })
    this.$store.dispatch('user/getAdminAccesses', { user_id: this.id })
    this.$store.dispatch('user/getAdminAccessesModules')
  }
}

</script>

<style lang="scss">
  .tabs-container {
    padding-bottom: 23px;
  }
  .vs-button-filled {
    color: #fff !important;
    margin-left: 15px;
  }
</style>

<style lang="scss" scoped>
  .access-settings-list {
    .radio-group {
      display: flex;
      flex-direction: column;
      gap: 4px;
      min-width: fit-content;
    }
  }

</style>
