import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'

export default {
  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/shop/products')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/shop/products/${payload.id}/edit`, { params: payload })
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getVirtualCardTypes ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/shop/products/virtual-card/get-types')
        .then((response) => {
          commit('retrieveVirtualCardTypes', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCodes ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/shop/products/${payload.id}/code/get-codes`, { params: payload })
        .then((response) => {
          commit('setCodes', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  addCodes (commit, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    const formData = new FormData()
    if (payload.digital_codes) {
      formData.append('digital_codes', payload.digital_codes)
    }
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/shop/products/${payload.product_id}/code/addCodes`, formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },

  deleteCode (context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/shop/products/${payload.productId}/code/${payload.id}`)
        .then((response) => {
          context.commit('addPropertyDeletedAtForCodeById', payload.id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  restoreCode (context, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/shop/products/${payload.productId}/code/${payload.id}/restore`)
        .then((response) => {
          context.commit('restoreCodeById', payload.id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeCode (context, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    formData.append('code', payload.code)
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/shop/products/${payload.productId}/code/${payload.id}/changeCode`,
        formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create ({ commit, state }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    if (payload.digital_codes) {
      formData.append('digital_codes', payload.digital_codes)
    }
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/shop/products', formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  update ({ commit }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    if (payload.digital_codes) {
      formData.append('digital_codes', payload.digital_codes)
    }
    helpers.getFormData(formData, payload)
    formData.append('_method', 'PATCH')
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/shop/products/${payload.id}`, formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  delete (context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/shop/products/${payload.id}`)
        .then((response) => {
          context.commit('removeShopProductById', payload.id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sort ({ commit }, payload) {
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    formData.append('_method', 'PATCH')
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/shop/products/sort', formData)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
