import state from './moduleChallengeState.js'
import mutations from './moduleChallengeMutations.js'
import actions from './moduleChallengeActions.js'
import getters from './moduleChallengeGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
