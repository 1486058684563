import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'
import FileSaver from 'file-saver'

export default {
  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/missions')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getTrackers ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/missions/trackers')
        .then((response) => {
          commit('retrieveTrackers', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getFiltersOptions ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/missions/filters')
        .then((response) => {
          commit('retrieveFiltersOptions', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/missions', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },

  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/missions/${payload.id}/edit`, { params: payload })
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/missions/${payload.id}`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  delete (context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/missions/${payload.id}`)
        .then((response) => {
          context.commit('removeMissionById', payload.id)
          context.commit('removeTrackersId', payload.id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sort ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch('/api/admin-panel/missions/sort', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  exportMission ({ commit }, payload) {
    console.log(payload)
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/missions/export/${payload.id}`, {
        responseType: 'blob',
        params: {
          is_export_by_sequential: payload.isExportBySequential
        }
      })
        .then((response) => {
          const fileName = payload.isExportBySequential ? `${payload.id}_chain` : payload.id
          FileSaver.saveAs(response.data, `${fileName}.json`)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  importMission ({ commit }, payload) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    const formData = new FormData()
    formData.append('file', payload.file)
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/missions/import', formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
