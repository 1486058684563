import axios from '@/axios.js'

export default {
  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/services')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getGroupsOptions ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/services/groups')
        .then((response) => {
          commit('retrieveGroupsOptions', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/services', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },

  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/services/${payload.id}/edit`, { params: payload })
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/services/${payload.id}`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  delete (context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/services/${payload.id}`)
        .then((response) => {
          context.commit('removeServiceById', payload.id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sort ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch('/api/admin-panel/services/sort', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
