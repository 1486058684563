<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <loading v-if="loading"></loading>
      <div class="vx-col w-full mb-base" v-if="!showSortTable && !loading">
        <div class="vx-card p-6 ag-grid-wrapper">
          <!-- AgGrid Table -->
          <ag-grid-vue
            @gridReady="onGridReady"
            :gridOptions="gridOptions"
            @rowClicked="onRowClicked"
            class="ag-theme-material w-full h-full my-4"      
            style="height: 600px;"
            :rowHeight="64"
            :getRowStyle="getRowStyle"
            :columnDefs="columnDefs"
            :overlayNoRowsTemplate="overlayNoRowsTemplate"
            :defaultColDef="defaultColDef"
            colResizeDefault="shift"
            :animateRows="true"
            :enableRtl="$vs.rtl"
            :rowBuffer="rowBuffer"
            :rowSelection="rowSelection"
            :rowModelType="rowModelType"
            :cacheBlockSize="cacheBlockSize"
            :cacheOverflowSize="cacheOverflowSize"
            :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
            :infiniteInitialRowCount="infiniteInitialRowCount"
            :maxBlocksInCache="maxBlocksInCache"
            @sortChanged="onSortAndFilterChanged"
            @filterChanged="onSortAndFilterChanged"
          />
        </div>

        <vs-popup class="report-popup" :active.sync="showReportPopup" title="Отчет">
          <div class="popup-report" v-if="reportData.user">
            <div class="popup-report__user user-block flex items-center gap-4 mb-5">
              <div class="user-block__avatar">
                <img
                  class="rounded-full overflow-hidden"
                  :src="
                    reportData.user.avatar_url
                      ? getUrl(reportData.user.avatar_url)
                      : require('@/assets/images/user/avatar/default.svg')
                  "
                  alt=""
                />
              </div>
              <div class="user-call">
                <h4>{{ reportData.user.name }}</h4>
                <p class="sub-text">ID: {{ reportData.user.id }}</p> 
              </div>
            </div>
            <div class="popup-report__content content flex">
              <div class="content__text min-w-3/5 flex-1">
                <h4 class="ml-auto">{{ reportData.name }}</h4>
                <div class="report-item__value">{{ reportData.announcement }}</div>
              </div>
              <div v-if="bigImages.length > 0" class="content__image w-2/5">
                <swiper-container :pagination="{ clickable: true }" class="h-full">
                    <swiper-slide v-for="(img, index) in bigImages" :key="index">
                    <img
                      class=""
                      v-img="{src: getUrl(getImages[index])}" 
                      :src="getUrl(thumbImages[index])"
                      alt=""
                    />
                    </swiper-slide>
                </swiper-container>
              </div>
            </div>
            <div class="separator"></div>
            <div class="popup-report__info grid grid-cols-3 justify-center gap-4">
              <div class="popup-report__info-item info-item">
                <p class="info-item__title">{{ $t('geocaching.popup.count_of_found') }}</p>
                <p class="info-item__value">
                  {{ reportData.count_of_found || 0 }} 
                </p>
              </div>
              <div class="popup-report__info-item info-item ml-8">
                <p class="info-item__title">{{ $t('geocaching.table.region') }}</p>
                <p class="info-item__value">{{ reportData.training_data.region.name }}</p>
              </div>
              <div class="popup-report__info-item info-item ml-12">
                <p class="info-item__title"></p>
                <p class="info-item__value"></p>
              </div>
              <div class="popup-report__info-item info-item">
                <p class="info-item__title">{{ $t('geocaching.popup.created') }}</p>
                <p class="info-item__value" v-html="formatDate(reportData.created_at)"></p>
              </div>
              <div class="popup-report__info-item info-item ml-8">
                <p class="info-item__title">{{ $t('geocaching.popup.updated') }}</p>
                <p class="info-item__value" v-html="formatDate(reportData.updated_at)"></p>
              </div>
              <div class="popup-report__info-item info-item ml-12">
                <p class="info-item__title">{{ $t('geocaching.popup.deleted') }}</p>
                <p class="info-item__value" v-html="formatDate(reportData.deleted_at)"></p>
              </div>
            </div>
            <section v-if="reportData.dislikes && reportData.dislikes.length && showReportPopup">
              <div class="separator"></div>
              <div class="dislike-notices">
                <p class="dislike-notices__title">{{ $t('geocaching.popup.disputed')}} {{ slideIndex }} <span v-if="reportData.dislikes.length > 1"> / {{ reportData.dislikes.length }}</span></p>
                <swiper-container 
                  ref="dislikeNoticesSwiper"
                  class="disputed-swiper"
                  navigation="true" 
                  @progress="onSlideProgress"
                  >
                    <swiper-slide v-for="(item) in reportData.dislikes" :key="item.id">
                      <div class="flex gap-2 my-4" v-if="item.user">
                        <div class="dislike-notices__avatar">
                          <img :src="getUrl(item.user.avatar_path) || require('@/assets/images/user/avatar/default.svg')" alt="">
                        </div>
                        <div>
                          <p class="dislike-notices__name">{{ item.user.name }}</p>
                          <p class="dislike-notices__city">{{ item.user.role }}</p>
                        </div>
                      </div>
                      <p class="dislike-notices__notice">{{item.points.notice}}</p>
                      <div v-if="item.images.length > 0" class="w-full">
                          <div class="img-gallery">
                              <div v-for="(img) in item.images" :key="img.id" class="img-gallery__item">
                                <img
                                  class=""
                                  v-img="{src: getUrl(img.image_path)}" 
                                  :src="getUrl(img.image_path_300 || img.image_path)"
                                  alt=""
                                />
                              </div>
                          </div>
                      </div>
                    </swiper-slide>
                </swiper-container>
              </div>
            </section>
            <div class="popup-buttons flex mt-8">
              <vs-button
                v-if="reportData.moderation_status === 'rejected'"
                text-color="#DA7E3C"
                class="btn mr-auto"
                @click="changeStatus( 'pending' )"
                >{{ $t('geocaching.table.recover') }}</vs-button
              >
              <vs-button
                v-if="reportData.moderation_status === 'pending'"
                text-color="#DA7E3C"
                class="btn mr-auto"
                @click="changeStatus( 'approved' )"
                >{{ $t('geocaching.table.approved') }}</vs-button
              >
              <vs-button
                v-if="reportData.moderation_status === 'pending'"
                text-color="#DA7E3C"
                class="btn"
                @click="changeStatus( 'rejected' )"
                >{{ $t('geocaching.table.reject') }}</vs-button
              >
              <vs-button
                v-if="reportData.moderation_status === 'approved'"
                text-color="#DA7E3C"
                class="btn mr-auto"
                @click="changeStatus( 'pending' )"
                >{{ $t('geocaching.table.consideration') }}</vs-button
              >
              <vs-button
                v-if="reportData.moderation_status === 'approved'"
                text-color="#DA7E3C"
                class="btn "
                @click="changeStatus( 'rejected' )"
                >{{ $t('geocaching.table.reject') }}</vs-button
              >
              <vs-button
                color="danger"
                class="ml-auto"
                @click="onDelete(reportData.id)"
                >{{ $t('geocaching.table.delete') }}</vs-button
              >
            </div>
          </div>
        </vs-popup>
      </div>
    </div>
  </div>
</template>

<script>

import draggable from 'vuedraggable'
import { AgGridVue } from 'ag-grid-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import locale from 'flatpickr/dist/l10n/ru'
import 'flatpickr/dist/flatpickr.css'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import 'swiper/swiper-bundle.min'

// Cell Renderer
import CellRendererActions from '@/views/components/cell-renderer/geocaching/CellRendererActions.vue'
import CellRendererReport from '@/views/components/cell-renderer/geocaching/CellRendererReport.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)

export default {
  components: {
    AgGridVue,
    CellRendererActions,
    CellRendererReport,
    flatPickr,
    draggable,
    'v-select': vSelect,
    loading
  },
  data () {
    return {
      showSortOptions: false,
      search: '',
      selectStatus: '',
      selectUser: '',
      selectName: '',
      dateFrom: null,
      dateTo: null,
      configDate: {
        enableTime: false,
        altFormat: 'd.m.Y',
        altInput: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        locale: locale[this.$i18n.locale]
      },
      showSortTable: false,
      loading: false,

      // AgGrid
      gridApi: null,
      columnApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        filter: false,
        sortable: false,
        cellStyle: {'line-height': '1.7', 'display': 'flex', 'align-items': 'center', 'padding': '8px'}
      },
      columnDefs: [
        {
          headerName: this.$t('geocaching.table.user'),
          field: 'user',
          sortable: true,
          cellRenderer: params => {
            if (params.value !== undefined) {
              const name = params.value.name
              const id = params.value.id
              const avatar = params.value.avatar_url || require('@/assets/images/user/avatar/default.svg')
              const html = `
                          <div class="flex items-center gap-2">
                            <div class="avatar" style="flex-shrink: 0">
                              <img src="${avatar}" alt="Avatar">
                            </div>
                            <div class="user-call">
                              <h4>${name}</h4>
                              <p class="sub-text">ID: ${id}</p> 
                            </div>
                          <div>`
              return html
            } else {
              return '<img src="https://www.ag-grid.com/example-assets/loading.gif">'
            }
          },
          minWidth: 180,
          maxWidth: 350,
          wrapText: true
        },
        {
          headerName: this.$t('geocaching.table.region'),
          field: 'training_data.region.name_with_type',
          filter: true,
          wrapText: true,
          cellStyle: {
            'line-height': '1.3',
            'overflow': 'hidden',
            'text-overflow': 'ellipsis'
          },
          minWidth: 180,
          maxWidth: 500
        },
        {
          headerName: this.$t('geocaching.table.name'),
          field: 'name',
          flex: 1,
          sortable: true,
          wrapText: true,
          minWidth: 180,
          maxWidth: 500
        },
        {
          headerName: this.$t('geocaching.table.announcement'),
          field: 'announcement',
          cellStyle: {'line-height': '1.2'},
          minWidth: 300,
          maxWidth: 500
        },
        {
          headerName: '!',
          field: 'dislike_count',
          sortable: true,
          minWidth: 40,
          maxWidth: 60,
          headerClass: 'text-center text-bold text-xl',
          cellStyle: {'justify-content': 'center'}
        },
        {
          headerName: this.$t('reports.table.created'),
          field: 'created_at',
          sortable: true,
          minWidth: 130,
          maxWidth: 130,
          headerClass: 'text-center',
          cellStyle: {'justify-content': 'center'},
          cellRenderer: (params) => {
            const date = params.value
            
            return this.formatDate(date)
          }
        }, 
        {
          headerName: '',
          field: 'training_data.moderation_status',
          cellRenderer: 'CellRendererActions',
          minWidth: 200,
          maxWidth: 200,
          cellStyle: {
            'justify-content': 'center'
          }
        }
      ],
      startId: null,
      page: null,
      rowCount: null,
      rowBuffer: null,
      rowSelection: null,
      rowModelType: null,
      cacheBlockSize: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      maxBlocksInCache: null,
      filterData: '',

      overlayNoRowsTemplate: null,
      showReportPopup: false,
      reportData: {},
      slideIndex: 0
      // deleteProcess: false,
      // deleteItemId: 0
    }   
  },

  methods: {
    onSlideProgress (e) {
      const [swiper, progress] = e.detail
      const totalSlides = swiper.slides.length - 1
      this.slideIndex = Math.round(progress * totalSlides) + 1
    },

    onGridReady (params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi

      const updateData = () => {

        const dataSource = {

          rowCount: null,
          getRows: (params) => {
            const sort = params.sortModel[0]
            const filter = params.filterModel
            const colId = sort ? sort.colId : null

            const sortUser = (colId === 'user') ? sort.sort : null
            const sortName = (colId === 'name') ? sort.sort : null
            const sortDislikes = (colId === 'dislike_count') ? sort.sort : null
            const sortCreatedAt = (colId === 'created_at') ? sort.sort : null

            const filterRegion = (filter && filter['training_data.region.name_with_type']) ? filter['training_data.region.name_with_type'].filter : null
  
            this.$store
              .dispatch('geocaching/getSuite', {
                start_id: this.startId,
                page: this.page,
                per_page: this.cacheBlockSize || 30,
                status: this.selectStatus.id || [],
                by_dislikes: sortDislikes,
                by_creation: sortCreatedAt,
                by_user: sortUser,
                bu_name: sortName,
                region: filterRegion
              })
              .then((data) => {
                const rowsThisPage = data.data
                this.gridApi.hideOverlay() 
                if (rowsThisPage.data.length === 0) {
                  this.gridApi.showNoRowsOverlay()
                } 
                const lastItemId = rowsThisPage.data.length ? rowsThisPage.data[rowsThisPage.data.length - 1].id : null
                this.lastRow = this.lastRow || rowsThisPage.meta.total
                if (this.page === 1 && rowsThisPage.data.length) {
                  this.startId = rowsThisPage.data[0].id 
                }
                this.page++

                params.successCallback(rowsThisPage.data, this.lastRow)
              })
              .catch(error => {
                params.failCallback()
                this.$vs.notify({
                  title: this.$t('users.suite.errors.title'),
                  text: error,
                  color: 'danger',
                  time: 10000
                })
              })      
          }
        }
        params.api.setDatasource(dataSource)
      }
      updateData()
    },

    onSortAndFilterChanged (params) {
      this.startId = null
      this.page = 1
    },

    onRowClicked (params) {
      if (!params.data) {
        return
      }
      this.reportData = params.data
      this.reportData.moderation_status = params.data.training_data.moderation_status
      this.showReportPopup = true
    },

    getRowStyle (params) {
      const status = params.data ? params.data.training_data.moderation_status : 'pending'
      if (status === 'rejected') {
        return { background: '#ffe6e6' }
      } else if (status === 'approved') {
        return { background: 'lightgreen' }
      } else {
        return {background: '#fff' }
      }
    },

    updateCellData (rowId, columnName, newValue) {
      this.gridApi.forEachNode((node) => {
        if (node.data && (node.data.id === rowId)) {
          node.setDataValue(columnName, newValue)
        }
        // this.gridApi.refreshInfiniteCache()
      })
    },

    applyFilter () {
      this.startId = null
      this.page = 1
      this.lastRow = null
      this.gridApi.purgeInfiniteCache()
    },

    applySearchFilter () {
      if (this.search.length > 2) {
        this.applyFilter()
      }
    },

    clearFilter () {
      this.startId = null
      this.page = 1
      this.lastRow = null
      this.search = ''
      this.selectName = ''
      this.selectUser = ''
      this.selectStatus = ''
      this.dateFrom = null
      this.dateTo = null
      this.gridApi.purgeInfiniteCache()
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const dayMonthYear = moment(date).format('DD.MM.YYYY')
      const hoursMinutes = moment(date).format('HH:mm')
      const dayMonthYearSpan = `<span style="color: #000; font-weight: 600">${dayMonthYear}</span>`
      const hoursMinutesSpan = `<span style="color: #8E8E8E; font-weight: 600">${hoursMinutes}</span>`
      return `${dayMonthYearSpan} ${hoursMinutesSpan}`
    },

    changeStatus (status) {
      this.$store.dispatch('geocaching/rejectRecover', { id: this.reportData.id, moderation_status: status }).then(() => {
        this.updateCellData(
          this.reportData.id,
          'training_data.moderation_status',
          status
        )
      }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.showReportPopup = false
      })
    },

    onDelete () {
      if (confirm(this.$t('geocaching.confirmDelete').replace('[NAME]', this.reportData.name))) {
        this.$store.dispatch('geocaching/delete', { id: this.reportData.id }).then(() => {
          this.onSortAndFilterChanged()
          this.gridApi.purgeInfiniteCache()
        }).catch(error => {
          this.$vs.notify({
            title: this.$t('users.suite.errors.title'),
            text: error,
            color: 'danger',
            time: 10000
          })
        }).then(() => {
          this.showReportPopup = false
        })
      }
    },
    

    formatTime (milliseconds) {
      if (!milliseconds) {
        return ''
      }
      const time = moment.duration(milliseconds)
      const formattedTime = moment.utc(time.asMilliseconds()).format('HH:mm:ss')
      return formattedTime
    },
    goToEditPoint (reportData) {
      const pointId = reportData.points.id
      const userId = reportData.user.id
      this.$router.push(`/users/edit-user/${userId}/points/${pointId}`)
    },

    getImages (images, size = 'big') {
      if (size === 'thumb') {
        return images.map(el => {
          return el.image_path_168 || el.image_path_300 || el.image_path_600 || el.image_path
        })
      } else {
        return images.map(el => {
          return el.image_path || el.image_path_600 || el.image_path_300 || el.image_path_168
        })
      }
    }
    
  },

  computed: {
    formError () {
      return this.$store.getters['geocaching/formError']
    },

    thumbImages () {
      return this.getImages(this.reportData.images, 'thumb')
    },
    bigImages () {
      return this.getImages(this.reportData.images)
    }
  },

  created () {
    this.startId = null
    this.page = 1
    this.rowBuffer = 0
    this.rowSelection = 'multiple'
    this.rowModelType = 'infinite'
    this.cacheBlockSize = 15
    this.cacheOverflowSize = 1
    this.maxConcurrentDatasourceRequests = 1
    this.infiniteInitialRowCount = 1
    this.maxBlocksInCache = null

    this.overlayNoRowsTemplate = `<div class="flex items-center justify-center text-3xl font-bold">${this.$t('reports.table.no_data')}</div>`

    this.$store.dispatch('geocaching/getStatuses')
    this.$store.dispatch('user/getList')
    this.$store.commit('category/clearFormError')

  },
  mounted () {
  }

}
</script>


<style lang="scss" scoped>

.disputed-swiper::part(button-prev), .disputed-swiper::part(button-next) {
  width: 20px;
  height: 20px;
  background: #fff;
  color: #8E8E93;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 5px;
  font-weight: bold;
  border-radius: 50%;
  top: 2px;
}
.disputed-swiper::part(button-prev) {left: 105px;}
.disputed-swiper::part(button-next) {left: 137px;}

ol {
  list-style-type: decimal !important;
  padding-left: 18px !important;
}
.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.card ::v-deep > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > div:first-child {
    margin-bottom: auto;
  }
  .vx-card__body {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.buttons-wrapper {
  margin-bottom: 30px;
}
.buttons-wrapper > button {
  display: inline-block;
  margin-right: 30px;
}
.sort-table-wrapper {
  width: 100%;
}
.list-item {
  transition: all 1s;
}
.list-item:hover {
  cursor: all-scroll;
}

.popup-report .btn {
  background: #fff !important;
  border: 1px solid #DA7E3C;
  &:hover {
    background: #fcf3ec !important;
  }
}

.dislike-notices {
  font-size: 12px;
  &__title {
    font-weight: 600;
    color: #1E1E1E;
  }

  &__avatar {
    width: 32px;
    height: 32px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__name {
    color: #1a1a1a;
  }

  &__city {
    font-size: 10px;
    color: #9F9FA5;
  }

  &__notice {
    font-size: 16px;
    color: #121212;
  }
}

::v-deep {
  .vs__dropdown-toggle {
  background: #fff;
}
  .call-achievement {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    min-width: 52px;
    .row-number {
      line-height: 1;
    }
    .icon-achievement {
      width: 28px;
      height: 28px;
      object-fit: contain;
    }
  }

  .ag-cell-wrap-text {
    word-break: break-word;
  }

}
</style>

<style lang="scss">

.user-call {
  h4 {
    font-size: 12px;
  }
  .sub-text {
    font-size: 8px;
    color: #8e8e8e;
  }
}

.report-popup .vs-popup {
  width: 800px;
}

.popup-report {
  width: 100%;

  &__content {
    .content__text {
      padding-right: 16px;
      margin-right: 6px;
      font-size: 16px;
      max-height: 210px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 2px;
      }
      /* Установите цвет фона вертикальной полосы прокрутки */
      &::-webkit-scrollbar-track {
        background: #dedede;
      }
      /* Установите цвет заполнения вертикальной полосы прокрутки */
      &::-webkit-scrollbar-thumb {
        background-color: #7367f0;
        border-radius: 5px;
      }
    }
  }
  &__info {
    .info-item {
      &__title {
        font-size: 12px;
      }
      &__value {
        // display: flex;
        // align-items: center;
        font-size: 16px !important;
        line-height: 1.2;
        color: #000 !important;
      }
      &__edit {
        margin-left: 4px;
        cursor: pointer;
        svg {
           width: 14px;
          height: 14px;
        }
      }
    }
  }
  .report-item {
    &__title {
      font-size: 12px;
      color: #8e8e8e;
    }
    &__value {
      font-size: 14px;
      color: #000;
    }
  }
  .btn {
    background: rgba(218, 126, 60, 0.1);
    border: 1px solid #da7e3c;
  }
}

.content__image {
  width: 100%;
  height: 210px;
  background: #d9d9d9;
  border-radius: 8px;
  padding: 0;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    padding: 16px;
  }
}

.img-gallery {
  display: flex;
  width: 100%;
  height: 210px;
  background: #d9d9d9;
  gap: 16px;
  padding: 12px;
  &__item {
  }
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.user-block {
  &__avatar {
    width: 46px;
    height: 46px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
swiper-container {
  &::part(container) {
    overflow-y: visible;
  }
  &::part(pagination) {
    bottom: -26px;
  }
  &::part(bullet-active) {
    background: #DA7E3C !important;
  }
}

.ag-grid-wrapper {
  height: 600px;
  width: 100%;
  .ag-header-cell {
    padding-left: 8px;
    padding-right: 8px;
  }
}


.separator {
  border-bottom: 2px solid #e8e8e8;
  margin: 30px 0 20px;
}

.vs-input--icon {
    font-size: 20px;
    left: 8px;
  }

.filter-settings {
  .vs-button {
    padding-left: 6px !important;
    padding-right: 6px !important;
    padding-bottom: 6px !important;
  }

  .vs-button-primary.vs-button-line {
    border-color: #e0ddff !important;
  }
  .vs-button-primary.vs-button-line .vs-button-linex {
    background: #7367f0 !important;
  }
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
