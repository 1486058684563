<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-else>
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col w-full flex mb-4">
          <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
          <label class="ml-4" :class="{ 'text-orange': isActive }">{{ $t('mailings.fields.is_active') }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active')) }}</span>
        </div>
      </div>
      <div class="vx-card tabs-container px-6 pt-6 pb-3">
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('mailings.fields.title')" v-model="title" />
            <span class="text-danger text-sm" v-show="formError.has('title')">{{ $t(formError.get('title')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            {{ $t('mailings.suite.notice') }}
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('mailings.fields.subject')" v-model="subject" />
            <span class="text-danger text-sm" v-show="formError.has('subject')">{{ $t(formError.get('subject')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vx-card no-shadow card-border :title="$t('mailings.fields.content')">
              <editor ref="editor" :config="editorJsConfig" />
              <span class="text-danger text-sm" v-show="formError.has('content')">{{ $t(formError.get('content')) }}</span>
            </vx-card>
          </div>
        </div>

        <template v-if="isScheduled">
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <flat-pickr
                :config="configdateTimePicker"
                v-model="sendingAt"
                :placeholder="$t('mailings.fields.sending_at')"
                :monday-first="$i18n.locale === 'ru' ? true : false"
              />
              <span class="text-danger text-sm" v-show="formError.has('sending_at')">{{ $t(formError.get('sending_at')) }}</span>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>{{ $t('mailings.fields.target_type') }}</label>
              <v-select @input="getTargetTypeItemsOnChange" v-model="targetType" :options="targetTypes" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
              <span class="text-danger text-sm" v-show="formError.has('target_type')">{{ $t(formError.get('target_type')) }}</span>
            </div>
          </div>

          <div v-if="targetItemsShow" class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>{{ $t('mailings.fields.target_item') }}</label>
              <v-select
                v-model="targetItems"
                multiple
                :closeOnSelect="false"
                :options="targetItemsOptions"
                :selectable="option => targetItems.indexOf(option) < 0"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
              <span class="text-danger text-sm" v-show="formError.has('target_items')">{{ $t(formError.get('target_items')) }}</span>
            </div>
          </div>
        </template>
      </div>
      <div v-if="!isOnlyRead" class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button @click="action(stayAfter = true)" :disabled="actionButtonDisabled">{{
            $t('mailings.' + type + '.buttons.' + type)
          }}</vs-button>
          <vs-button @click="action()" :disabled="actionButtonDisabled">
          {{$t('mailings.' + type + '.buttons.' + type)}} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
    </div>
    <vs-popup class="holamundo" title="" :active.sync="ErrorParsePopupActive">
      <p>{{ $t('mailings.edit.error_parse.content') }}</p>
      <div class="error-parse-button-confirm">
        <vs-button color="warning" type="filled" @click="ErrorParsePopupActive=false">{{ $t('mailings.edit.error_parse.button_confirm') }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import helpers from '@/helpers/helpers.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import locale from 'flatpickr/dist/l10n/ru'
import 'flatpickr/dist/flatpickr.css'
import { Russian as ru } from 'flatpickr/dist/l10n/ru.js'
import editorJsConfig from '@/editorjs/config.js'
import loading from '@/views/components/Loading.vue'

export default {
  components: {
    flatPickr,
    'v-select': vSelect,
    loading
  },
  data () {
    return {
      ErrorParsePopupActive: false,
      id: 0,
      type: 'create',
      actionButtonDisabled: false,
      loading: true,
      title: '',
      slug: '',
      subject: '',
      sendingAt: '',
      targetType: '',
      targetId: null,
      isActive: true,
      sendingsСount: 0,
      isScheduled: true,
      content: '',
      targetTypes: [],
      targetItems: [],
      targetItemsShow: false,
      targetItemsOptions: [],
      today: helpers.today('-'),
      dateFormat: 'dd.MM.yyyy H:i:s',
      datepicker: {},
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'd.m.Y H:i',
        locale: locale[this.$i18n.locale],
        time_24hr: true
      },
      editorJsConfig: Object.assign({}, editorJsConfig),
      editorOption: {
        formats: ['bold', 'header', 'italic', 'link', 'list', 'blockquote', 'image', 'indent'],
        modules: {
          toolbar: '#toolbar'
        }
      }
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-mailings'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['mailing/formError']
    }
  },
  methods: {
    targetTypeHasItems (target_type) {
      return target_type !== undefined && target_type !== 'all' && target_type !== 'inactive'
    },
    getTargetTypeItemsOnChange (target_type) {
      if (this.targetTypeHasItems(target_type.value)) {
        this.getTargetTypeItems(target_type)
      } else {
        this.targetItemsShow = false
      }

      this.targetItems = []
    },
    getTargetTypeItems (target_type) {
      const t_type = target_type.value !== undefined ? target_type.value : target_type
      this.$store.dispatch('mailing/get_target_type_items', { target_type: t_type }).then(() => {
        const data = this.$store.getters['mailing/get_target_type_items']
        this.targetItemsOptions = data.targetTypeItems
        this.targetItemsShow = true
      })
    },
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'mailing/create' : 'mailing/update'

      this.dispatch(patch, stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {

        const blockDescription = await this.$refs.editor.state.editor.save()
        const payload = {
          id: this.id,
          title: this.title,
          subject: this.subject,
          content: JSON.stringify(blockDescription),
          sending_at: this.sendingAt,
          slug: this.slug ? this.slug : new Date().getTime(),
          target_type: this.targetType ? this.targetType.value : '',
          target_ids: this.targetItems,
          is_active: +this.isActive
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name: 'mailings'
          },
          routerPushToEdit: {
            name: 'mailings-edit'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    }
  },
  created () {
    this.id = this.$route.params.id
    if (this.$router.currentRoute.name === 'mailings-edit') {
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('mailing/edit', { id: this.id }).then(() => {
        const edit = this.$store.getters['mailing/edit']
        this.title = edit.title
        this.slug = edit.slug
        this.subject = edit.subject
        this.content = edit.content
        if (this.content !== '') {
          try {
            this.editorJsConfig.data = JSON.parse(edit.content)
          } catch (e) {
            this.ErrorParsePopupActive = true
            this.editorJsConfig.data = ''
          }
        }
        this.sendingAt = edit.sendingAt
        this.targetType = {
          label: this.$t(`mailings.target_types.${edit.targetType}`),
          value: edit.targetType
        }
        this.targetItems = edit.targetItems
        this.targetId = edit.targetId
        this.isActive = edit.isActive
        this.isScheduled = edit.isScheduled
        this.sendingsСount = edit.sendingsСount
        this.id = edit.id

        if (this.targetTypeHasItems(edit.targetType)) {
          this.getTargetTypeItems(edit.targetType)
        }

        this.loading = false
      })
    } else {
      this.targetType = {
        label: this.$t('mailings.target_types.all'),
        value: 'all'
      }
      this.loading = false
    }

    this.$store.dispatch('mailing/get_target_types').then(() => {
      const data = this.$store.getters['mailing/get_target_types']
      this.targetTypes = []

      data.targetTypes.forEach((grp, i) => {
        const targetType = data.targetTypes[i]
        this.targetTypes.push({ label: this.$t(`mailings.target_types.${targetType}`), value: targetType })
      })
    })

    this.$store.commit('mailing/clearFormError')
  }
}
</script>

<style>
.h-textarea .vs-textarea {
  min-height: 300px;
}

.error-parse-button-confirm {
  margin-top: 20px;
}
</style>
