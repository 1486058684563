import helpers from '@/helpers/helpers.js'

export default {
  retrieveSuite (state, suite) {
    state.suite = suite
  },

  retrieveTrackers (state, trackers) {
    state.trackers = trackers
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  },
  retrieveEdit (state, data) {
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) continue
      state.edit[helpers.kebabCaseToCamelCase(prop)] = (data[prop] === null) ? '' : data[prop]
    }
  },
  removeMissionById (state, id) {
    const index = helpers.searchInArray('id', id, state.suite, true)
    state.suite.splice(index, 1)
  },

  removeTrackersId (state, id) {
    const index = helpers.searchInArray('id', id, state.trackers, true)
    state.trackers.splice(index, 1)
  },
  retrieveFiltersOptions (state, filters_options) {
    for (const filter of filters_options)  {
      filter.label = filter.title
    }
    state.filtersOptions = filters_options
  }
}

