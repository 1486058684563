<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full my-32">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-if="!loading" class="vx-row">
      <div class="vx-col w-full mb-2 flex gap-4">
        <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"></vs-switch>
        <label>{{ $t('categories.create.labels.is_active') }}</label>
        <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active')) }}</span>
      </div>
    </div>

    <div v-if="!loading">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <vx-card class="mb-6 mt-4" :title="$t('relevant.edit_create.labels.accessibility_settings')">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <label>{{ $t('relevant.edit_create.labels.publication_at') }}*</label>
            <flat-pickr class="w-full p-4" :config="configdateTimePicker" v-model="publicationAt" :placeholder="$t('relevant.edit_create.labels.enter_the_date')" :monday-first="($i18n.locale === 'ru') ? true : false" />
            <span class="text-danger text-sm" v-show="formError.has('publication_at')">{{ $t('form.errors.required_field') }}</span>
          </div>

          <div class="vx-col md:w-1/2 w-full">
            <label>{{ $t('relevant.edit_create.labels.deactivate_at') }}</label>
            <flat-pickr class="w-full p-4" :config="configdateTimePicker" v-model="publicationUntil"
              :placeholder="$t('relevant.edit_create.labels.enter_the_date')" :monday-first="($i18n.locale === 'ru') ? true : false" />
            <span class="text-danger text-sm" v-show="formError.has('publication_until')">{{ $t(formError.get('publication_until'))
            }}</span>
          </div>
        </div>
      </vx-card>

      <vx-card class="mb-6 mt-4" :title="$t('relevant.edit_create.labels.accessibility_publication')">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('relevant.edit_create.labels.record_type') }}*</label>
            <v-select v-model="relevantType" 
              :options="relevantTypeOptions" :placeholder="$t('relevant.edit_create.labels.not_selected')" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('target_model')">{{ $t(formError.get('target_model')) }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('relevant.edit_create.labels.name') }}*</label>
            <v-select :disabled="!relevantType" v-model="program" :placeholder="$t('relevant.edit_create.labels.not_selected')"
              :options="programsOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #option="{ label, isActive }">
                <span :class="{ 'not-active-option': isActive === 0 }">
                    {{ label }}
                </span>
            </template>
            </v-select>
            <span class="text-danger text-sm" v-show="formError.has('target_id')">{{ $t(formError.get('target_id')) }}</span>
          </div>
        </div>
      </vx-card>
    </div> 
    <div v-if="!isOnlyRead" class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button 
            v-if="type === 'edit'" 
            color="danger" 
            @click="deleteRelevantPopupActive = true"
            :disabled="actionButtonDisabled">
            {{ $t('relevant.delete_button') }}
          </vs-button>

          <vs-button 
            @click="action(stayAfter = true)" 
            :disabled="actionButtonDisabled">
            {{ $t('pedometer.challenges.' + type + '.buttons.' + type) }}
          </vs-button>

          <vs-button 
            @click="action()" 
            :disabled="actionButtonDisabled">
            {{ $t('pedometer.challenges.' + type + '.buttons.' + type) }} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
    </div>

    <vs-popup class="holamundo" title="" :active.sync="deleteRelevantPopupActive">
      <p>{{ $t('relevant.popup.delete_title').replace('[item]', programNameForDelete) }}</p>
      <div v-if="deleteProcess">
        <font-awesome-icon icon="spinner" spin />
      </div>
      <div class="delete-confirm-popup-buttons" v-else>
        <vs-button color="primary" type="filled" @click="deleteRelevant()">{{
          $t('users.suite.popups.confirm_delete_user.buttons.confirm')
        }}</vs-button>
        <vs-button color="warning" type="filled" @click="deleteRelevantPopupActive = false">{{
          $t('users.suite.popups.confirm_delete_user.buttons.cancel')
        }}</vs-button>
      </div>
    </vs-popup>
    <vs-popup class="holamundo" style="z-index: 9999999" :title="$t('relevant.popup.attention')" :active.sync="errorValidPublicationAt">
      <p>{{ `${$t('relevant.popup.error_valid_publication_at')} (${program ? program.publicationAtProgram : ''})` }}</p>
      <div v-if="deleteProcess">
        <font-awesome-icon icon="spinner" spin />
      </div>
      <div class="delete-confirm-popup-buttons" v-else>
        <vs-button
          color="warning"
          type="filled"
          class="ml-auto"
          @click="
            {
              (errorValidPublicationAt = false), (actionButtonDisabled = false);
            }
          "
          >{{ $t('relevant.popup.button.close') }}</vs-button
        >
      </div>
    </vs-popup>
    <vs-popup class="holamundo" style="z-index: 9999999" :title="$t('relevant.popup.attention')" :active.sync="errorNotActive">
      <p>{{ $t('relevant.popup.error_program_not_active') }}</p>
      <div v-if="deleteProcess">
        <font-awesome-icon icon="spinner" spin />
      </div>
      <div class="delete-confirm-popup-buttons" v-else>
        <vs-button
          color="warning"
          type="filled"
          class="ml-auto"
          @click="
            {
              (errorNotActive = false), (actionButtonDisabled = false);
            }
          "
          >{{ $t('relevant.popup.button.close') }}</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import loading from '@/views/components/Loading.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import locale from 'flatpickr/dist/l10n/ru'
import moment from 'moment'
import vSelect from 'vue-select'
import helpers from '@/helpers/helpers.js'

export default{
  components: {
    flatPickr,
    PictureInput,
    vSelect,
    Cropper,
    loading
  },
  data () {
    return {

      type: 'create',
      actionButtonDisabled: false,
      loading: true,
      isActive: 1,
      relevantType: null,
      modal: null,
      targetId: null,
      programData: [],
      program: null,
      programsOptions: [],
      relevantTypeOptions: [
        {
          label: this.$t('relevant.table.relevant_type.post'),
          model: 'App\\Models\\Blog\\Post'  
        },
        {
          label: this.$t('relevant.table.relevant_type.goal'),
          model: 'App\\Models\\Pedometer\\Challenge'  
        },
        {
          label: this.$t('relevant.table.relevant_type.programm'),
          model: 'App\\Models\\Category',
          type: ['programm', 'challenge', 'self_challenge']
        },
        {
          label: this.$t('relevant.table.relevant_type.polling'),
          model: 'App\\Models\\Category',
          type: ['polling']
        }

      ],

      publicationUntil: '',
      publicationAt: moment().format('YYYY-MM-DD HH:mm'),
      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error',
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        minDate: null,
        maxDate: null,
        locale: locale[this.$i18n.locale]
      },
      deleteRelevantPopupActive: false,
      errorValidPublicationAt: false,
      errorNotActive: false,
      deleteProcess: false,
      programNameForDelete: ''
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-relevant'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['relevant/formError']
    },
    checkValidPublicationAt () {
      if (this.program && this.program.publicationAtProgram) {
        const publicationAt = moment(this.publicationAt)
        const publicationAtProgram =  moment(this.program.publicationAtProgram)
        return publicationAt >= publicationAtProgram
      } 
      return true
    }
  },
  methods: {
    action (stayAfter) {
      if (!this.checkValidPublicationAt) {
        this.errorValidPublicationAt = true
        return
      }
      if (this.program && !this.program.isActive) {
        this.errorNotActive = true
        return
      }
      this.loading = !stayAfter
      this.actionButtonDisabled = true

      const patch = (this.type === 'create') ? 'relevant/create' : 'relevant/update'
      this.dispatch(patch, stayAfter)
    },
    async dispatch (patch, stayAfter) {
      try {
        const payload = {
          id: this.id,
          publication_until: this.publicationUntil,
          publication_at: this.publicationAt,
          is_active: +this.isActive,
          target_model: this.relevantType ? this.relevantType.model : null,
          target_id: this.program ? this.program.id : null
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name:'relevant'
          },
          routerPushToEdit: {
            name: 'relevant-edit'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    },
    deleteRelevant () {
      this.deleteProcess = true
      this.$store.dispatch('relevant/delete', { id: this.id })
        .catch(error => {
          this.$vs.notify({
            title: this.$t('users.suite.errors.title'),
            text: error,
            color: 'danger',
            time: 10000
          })
        })
        .then(() => {
          this.deleteProcess = false
          this.deleteRelevantPopupActive = false
          this.actionButtonDisabled = false
        })
        .finally(() => {
          this.$router.push({ name: 'relevant' })
        })
    },
    getRelevantType (suite) {
      const targetModel = suite.targetModel
      const targetType = suite.targetType
      console.log('targetModel', targetModel)
      const relevantType = this.relevantTypeOptions.find(option => {
        if (option.type) {
          return option.type.includes(targetModel.type)
        }
        return option.model === targetType && !targetModel.type
      })
      this.relevantType = relevantType
    },
    getProgram (suite) {
      this.program = this.programsOptions.find(program => +suite.targetId === +program.id)
    },
    fetchProgramData (type) {
      switch (type.label) {    
      case this.$t('relevant.table.relevant_type.goal'):
        return this.$store.dispatch('pedometer_challenge/getSuite').then(() => {
          this.programData = JSON.parse(JSON.stringify(this.$store.getters['pedometer_challenge/suite']))
        }) 
      case this.$t('relevant.table.relevant_type.post'):
        return this.$store.dispatch('blog_post/getSuite').then(() => {
          this.programData = JSON.parse(JSON.stringify(this.$store.getters['blog_post/suite']))
        })
      case this.$t('relevant.table.relevant_type.programm'):
        return this.$store.dispatch('category/getList').then(() => {
          this.programData = JSON.parse(JSON.stringify(this.$store.getters['category/list']))
        })
      case this.$t('relevant.table.relevant_type.polling'):
        return this.$store.dispatch('category/getList', { type: 'polling' }).then(() => {
          this.programData = JSON.parse(JSON.stringify(this.$store.getters['category/list']))
        })
      default:
        break
      }
    },
    getProgramsOptions () {
      return this.fetchProgramData(this.relevantType).then(() => {
        this.programsOptions = this.programData.map(program => ({
          label: program.title || program.label,
          id: program.id,
          publicationAtProgram: program.publication_at ? moment(program.publication_at).format('YYYY-MM-DD HH:mm') : '',
          isActive: program.is_active
        }))
      })
    }
  },
  created () {
   
    this.id = this.$route.params.id
    if (this.$router.currentRoute.name === 'relevant-edit') {
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('relevant/edit', { id: this.id})
        .then(() => {
          const edit = this.$store.getters['relevant/edit']
          this.publicationUntil = edit.publicationUntil ? moment(edit.publicationUntil).format('YYYY-MM-DD HH:mm') : ''
          this.isActive = edit.isActive
          this.programNameForDelete = edit.targetModel.title
          this.getRelevantType(edit)
          this.getProgramsOptions()
            .then(() => this.getProgram(edit))
            .then(() => {
              this.publicationAt = moment(edit.publicationAt).format('YYYY-MM-DD HH:mm')
            })

          this.loading = false
        })
    } else {
      this.loading = false
    }
    this.$store.commit('relevant/clearFormError')

  },
  watch: {
    relevantType (newType) {
      this.programsOptions = []
      this.program = null
      this.programData = []
      this.fetchProgramData(newType).then(() => {
        this.programsOptions = this.programData.map(program => ({
          label: program.title || program.label,
          id: program.id,
          publicationAtProgram: program.publication_at ? moment(program.publication_at).format('YYYY-MM-DD HH:mm') : '',
          isActive: program.is_active
        }))
      })
    },
    program (newValue) {
      if (this.program && !newValue.isActive) {
        this.errorNotActive = true
      }
      if (this.program && this.program.publicationAtProgram && this.program.publicationAt && !this.checkValidPublicationAt) {
        this.errorValidPublicationAt = true
      }
    },
    publicationAt () {
      if (!this.checkValidPublicationAt) {
        this.errorValidPublicationAt = true
      }
    }
  }
}
</script>

<style scoped>
.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.not-active-option {
  opacity: 0.5;
}
</style>
