import helpers from '@/helpers/helpers.js'

export default {
  retrieveSuite (state, suite) {
    state.suite = suite
  },
  retrieveLists (state, lists) {
    state.lists = lists
  },
  retrieveSegmentsOptions (state, segmentsOptions) {
    state.segmentsOptions = []
    for (const segment in segmentsOptions) {
      state.segmentsOptions.push({id: segmentsOptions[segment].id, label:segmentsOptions[segment].name})
    }
  },
  retrieveEdit (state, data) {
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) continue
      if (prop === 'team') {
        //get label from lists
        const datacopy = data[prop]
        data[prop] = []
        datacopy.map(function (value, key) {
          const team = state.lists['teams_options'].filter(function (elem) {
            if (elem.value.toString() === value.id.toString()) return elem
          })
          if (team.length > 0) {
            data[prop].push(team[0])
          } else {
            data[prop].push({label:'deleted', value:value.value})
          }
        })
      }

      if (prop === 'user') {
        //get label from lists
        const datacopy = data[prop]
        data[prop] = []
        datacopy.map(function (value, key) {
          const user = state.lists['users_options'].filter(function (elem) {
            if (elem.id.toString() === value.id.toString()) return elem
          })
          if (user.length > 0) {
            data[prop].push(user[0])
          } else {
            data[prop].push({label:'deleted', id:value.id})
          }
        })
      }
      state.edit[helpers.kebabCaseToCamelCase(prop)] = (data[prop] === null) ? '' : data[prop]

    }
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  },
  removeSegmentById (state, id) {
    const index = helpers.searchInArray('id', id, state.suite, true)
    state.suite.splice(index, 1)
  }
}
