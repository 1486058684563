<template>
  <section class="statistics-live">
    <h2 class="mb-8">Live</h2>
    <loading v-if="loading"></loading>
    <div class="vx-row justify-between content-between gap-y-4 pr-4" v-show="!loading">
      <div class="statistics-live__grid vx-col lg:w-6/12 w-full gap-4">
        <div v-for="(item) in liveStatistics" :key="item.name" class="card-statistic-grid vx-col w-full flex">
          <statistics-card-line
            :chartData="chartData"
            hideChart
            type="area"
            :icon="item.icon"
            icon-left
            :countData="Number(item.count)"
            :subCount="Number(item.subCount)"
            :statisticTitle="item.title"
            :statisticSubTitle="item.subTitle"
            :color="item.color"
          />
        </div>
      </div>
      <vx-card class="vx-col statistic-card statistic-card--activity lg:w-6/12 w-full pl-5 relative" data-html2canvas-ignore>
        <div slot="no-body" class="py-6 px-2">
          <div class="statistic-card__header mb-4 pb-2 flex items-center">         
            <feather-icon icon="DeviceMobile" class="main-icon rounded-full inline-flex mr-3"></feather-icon>
            <div class="statistic-card__title">
              <h3 class="text-lg">{{ $t('dashboard.titles.user_activity') }}</h3>
            </div>
          </div>
          <div class="activity-list">
            <div class="activity-list__item item-activity relative flex pb-12" v-for="(item) of usersActivity" :key="item.id">
              <div class="item-activity__dot"
               :class="{
                'item-activity__dot--complete': item.type_activity === 'Событие завершено',
                'item-activity__dot--active': item.type_activity === 'Новая запись в ленте активности',
               }
               ">
              </div>
              <img
                class="item-activity__img rounded-full bg-grey"
                :src="
                  item.avatar_path_thumbnail ? getUrl(item.avatar_path_thumbnail) : require('@/assets/images/user/avatar/default.svg')
                "
              />
              <div class="item-activity__body">
                <h4 class="font-bold mb-1">{{ item.user_name }}</h4>
                <div class="item-activity__city mb-2">{{ item.user_city }}</div>
                <p class="activity-type font-bold mb-1">{{ item.type_activity }}</p>
                <div class="activity-message inline-flex items-center mr-2">
                  <div v-if="item.type_activity === 'Новая запись в ленте активности'" class="inline-flex items-center">
                    <img v-img="{src:item.training_results_photo_path ? getUrl(item.training_results_photo_path) : ''}"
                      class="activity-message__img rounded-lg bg-grey"
                      :src="
                        item.training_results_photo_path_thumbnail
                          ? getUrl(item.training_results_photo_path_thumbnail)
                          : require('@/assets/images/pages/not-img.png')
                      "
                    />
                    <div class="bg-violet-100 text-violet leading-loose px-8px ml-4px rounded-2xl">{{ item.training_activity_name }}</div>
                  </div>
                  <p v-else class="">
                    {{ item.category_name }}
                    <span class="ml-2 mr-1">
                      <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.50414 9.09658C1.04691 8.57499 0.589698 8.03256 0.298726 7.3858C0.00777261 6.69731 -0.0753652 5.90452 0.070121 5.15345C0.215607 4.40237 0.548119 3.69302 1.02614 3.088C0.984578 3.60958 1.17162 4.15202 1.52493 4.54842C1.31709 3.54699 1.64962 2.4621 2.31467 1.66931C2.97971 0.876516 3.89417 0.354911 4.85016 0.000244141C4.37217 0.500959 4.30982 1.29375 4.49686 1.96139C4.68391 2.629 5.07879 3.21317 5.43209 3.8182C5.78539 4.42322 6.11792 5.04912 6.13871 5.7376C6.32576 5.38293 6.53359 5.02825 6.65829 4.63186C6.78299 4.23546 6.84533 3.8182 6.74143 3.42179C7.11552 3.88079 7.32335 4.33976 7.42726 4.92394C7.53117 5.50811 7.5104 6.11314 7.44805 6.69731C7.36491 7.34408 7.19865 8.01169 6.84535 8.55412C6.4297 9.20089 5.76464 9.65986 5.03725 9.91022C3.74868 10.1397 2.48092 9.95196 1.50414 9.09658Z"
                          fill="#F74D30"
                        />
                        <path
                          d="M3.08362 9.86874C4.7878 10.5364 6.47121 8.51264 5.53598 6.94791C5.53598 6.92704 5.51519 6.92704 5.51519 6.90619C5.59833 7.76157 5.3905 8.38747 4.99562 8.74215C5.20345 8.24144 5.05796 7.65726 4.80857 7.15655C4.55918 6.6767 4.20587 6.23857 3.93569 5.77958C3.66551 5.29973 3.45769 4.75728 3.58239 4.21484C3.0836 4.59038 2.7303 5.15368 2.58481 5.75871C2.43933 6.36373 2.52247 7.03137 2.79265 7.59467C2.48091 7.36518 2.2523 6.98965 2.23151 6.59324C1.89898 6.98965 1.69117 7.51121 1.71194 8.0328C1.71196 8.84646 2.35621 9.57666 3.08362 9.86874Z"
                          fill="#FFD15C"
                        />
                      </svg>
                    </span>
                    <span class="font-bold">{{ item.number_points }}</span>
                  </p>
                </div>
              </div>
              <div class="absolute bottom-0 mb-12 pb-2 right-0">{{getTimePassedSince(item.date_creation)}}</div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </section>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import _color from '@/assets/utils/color.js'
import loading from '@/views/components/Loading.vue'

import moment from 'moment'

export default {
  name: 'live-statistics',
  components: {
    StatisticsCardLine,
    loading
  },
  data () {
    return {
      loading: true,
      chartData: [],
      statisticsData: [
        {
          name: 'countRegistrations',
          icon: 'UserIcon',
          title: this.$t('dashboard.titles.all_users'),
          color: 'success'
        },
        {
          name: 'countReceivedPoints',
          icon: 'Coin',
          title: this.$t('dashboard.titles.earned_points'),
          color: 'primary'
        },
        {
          name: 'countCompletedTrainings',
          icon: 'Checkbox',
          title: this.$t('dashboard.titles.completed_tasks'),
          color: 'warning'
        },
        {
          name: 'countPosts',
          icon: 'LayoutBoardSplit',
          title: this.$t('dashboard.titles.posts_in_feed'),
          color: 'danger'
        },
        {
          name: 'countMessages',
          icon: 'Messages',
          title: this.$t('dashboard.titles.written_messages'),
          color: 'warning'
        },
        {
          name: 'topLikes',
          icon: 'ThumbsUpIcon',
          title: this.$t('dashboard.titles.put_likes'),
          color: 'danger'
        }
      ]
    }
  },

  computed: {
    liveStatistics () {
      this.getCount('countRegistrations')
      this.getCount('countReceivedPoints')
      this.getCount('countCompletedTrainings')
      this.getCount('countPosts')
      this.getCount('countMessages')
      this.getCount('topLikes')
      return this.statisticsData
    },

    usersActivity () {
      return JSON.parse(JSON.stringify(this.$store.getters['statistics/usersActivity']))
    }
  },
  watch: {

  },

  methods: {
    getTimePassedSince (date) {
      const diffSecs = (new moment() - moment(date)) / 1000
      if (diffSecs < 60) {
        return this.$t('dashboard.diff_time.seconds')
      } else if (diffSecs < 3600) {
        return `${Math.floor(diffSecs / 60)} ${this.$t('dashboard.diff_time.minutes')}`
      } else if (diffSecs < 86400) {
        return `${Math.floor(diffSecs / 3600)} ${this.$t('dashboard.diff_time.hours')}`
      } else if (diffSecs < 604800) {
        return `${Math.floor(diffSecs / 86400)} ${this.$t('dashboard.diff_time.days')}`
      } else if (diffSecs < 2419200) {
        return `${Math.floor(diffSecs / 604800)} ${this.$t('dashboard.diff_time.weeks')}`
      } else if (diffSecs < 29030400) {
        return `${Math.floor(diffSecs / 2419200)} ${this.$t('dashboard.diff_time.months')}`
      } else {
        return `${Math.floor(diffSecs / 29030400)} ${this.$t('dashboard.diff_time.years')}`
      }
    },
    getCount (name) {
      this.statisticsData.forEach(item => {
        if (item.name === name) {
          item.count = +this.$store.getters[`statistics/${name}`].count
        }
        if (item.name === 'countRegistrations') {
          item.subCount = +this.$store.getters['statistics/countFamilyUsers'].count
          item.subTitle = this.$t('dashboard.titles.family_users')
        }
      })
    }

  },

  created () {
    const dispatches = [
      this.$store.dispatch('statistics/getCountCompletedTrainings'),
      this.$store.dispatch('statistics/getTopLikes'),
      this.$store.dispatch('statistics/getCountMessages'),
      this.$store.dispatch('statistics/getCountPosts'),
      this.$store.dispatch('statistics/getCountReceivedPoints'),
      this.$store.dispatch('statistics/getCountRegistrations'),
      this.$store.dispatch('statistics/getCountFamilyUsers'),
      this.$store.dispatch('statistics/getUsersActivity')
    ]
    Promise.all(dispatches).then(() => {
      this.loading = false
    })

    this.intervalFetch = setInterval(() => {
      console.log('intervalFetch')
      this.$store.dispatch('statistics/getCountRegistrations')
      this.$store.dispatch('statistics/getCountFamilyUsers')
      this.$store.dispatch('statistics/getCountReceivedPoints')
      this.$store.dispatch('statistics/getCountCompletedTrainings')
      this.$store.dispatch('statistics/getCountPosts')
      this.$store.dispatch('statistics/getCountMessages')
      this.$store.dispatch('statistics/getTopLikes')
      
      this.$store.dispatch('statistics/getUsersActivity')
    }, 60000)
  },
  beforeDestroy () {
    clearInterval(this.intervalFetch)
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 32px;
  font-weight: 650;

}

h3 {
  font-weight: 600;
  font-size: 24px;
}

.statistics-live {
  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
}

.card-statistic-grid {
  min-width: min-content;
}

.statistic-card {
  &--activity {
    height: 600px;
    overflow-y: auto;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    scroll-padding-top: 10px;

    &::-webkit-scrollbar {
      width: 7px;
      background-color: #f9f9fd;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #7367f0;
      border-radius: 5px;
    }
  }

  &__header {
    position: sticky;
    top: -1px;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 999;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -22px;
    padding: 20px 20px 20px 14px;
  }
}
.item-activity {
  &__dot {
    position: absolute;
    left: -30px;
    top: 9px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #da7e3c;
    border: 2px solid #f8e5d8;

    &--complete {
      background-color: #2cb573;
      border-color: #d5f0e3;
    }

    &--active {
      background-color: #7367f0;
      border-color: #f4f3fe;
    }
  }

  &__img {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
  }
}

.activity-list {
  font-size: 12px;
  border-left: 0.5px solid #c2c2c2;
  padding-left: 22px;

  &__item {
    gap: 8px;
  }

  h4 {
    font-size: 12px;
  }
}

.activity-message {
  &__img {
    width: 22px;
    height: 22px;
  }

  &:last-of-type {
    margin-right: 100px;
  }
}
</style>