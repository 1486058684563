<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
                          <template v-if="loading">
                            <loading></loading>
                          </template>
                          <template v-else>
                            <template v-if="tokenExists">
                              <div class="p-8" v-if="passwordNotUpdated">
                                <div class="vx-card__title mb-8">
                                  <h4 class="mb-4">{{ this.$t('update_password.title') }}</h4>
                                  <p>{{ this.$t('update_password.message') }}</p>
                                </div>
                                <vs-input type="password" :label-placeholder="$t('update_password.placeholder')" v-model="password" class="w-full mb-3" />
                                <div class="text-danger text-sm mb-6" v-show="formError.has('password')">{{ $t(formError.get('password')) }}</div>

                                <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
                                  <vs-button class="w-full sm:w-auto" @click="updatePassword">{{ this.$t('update_password.button') }}</vs-button>
                                </div>

                              </div>
                              <div class="p-8" v-else>
                                <div class="vx-card__title mb-8">
                                  <h4 class="mb-4">{{ this.$t('update_password.success_message') }}</h4>
                                </div>
                              </div>
                            </template>
                            <template v-else>
                              <div class="p-8">
                                <div class="vx-card__title mb-8">
                                  <h4 class="mb-4">{{ this.$t('update_password.invalid_token_message') }}</h4>
                                </div>
                              </div>
                            </template>
                          </template>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import loading from '@/views/components/Loading.vue'

export default{
  components: {
    loading
  },
  data () {
    return {
      password: '',
      passwordNotUpdated: true,
      token: null,
      loading: true
    }
  },
  computed: {
    tokenExists () {
      return this.$store.getters['auth/passwordResetTokenExists']
    },
    formError () {
      return this.$store.getters['auth/formError']
    }
  },
  methods: {
    updatePassword () {
      const payload = {
        token: this.token,
        password: this.password,
        password_confirmation: this.password
      }
      this.$store.dispatch('auth/updatePassword', payload).then(() => {
        this.passwordNotUpdated = false
      })
    }
  },
  created () {
    this.token = this.$route.query.token
    const payload = {
      token: this.token
    }
    this.$store.dispatch('auth/checkPasswordResetToken', payload).then(() => {
      this.loading = false
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
