import axios from '@/axios.js'
import FileSaver from 'file-saver'
import helpers from '@/helpers/helpers.js'

export default {
  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/users')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/users/list')
        .then((response) => {
          commit('retrieveList', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/users/${payload.id}/edit`)
        .then((response) => {
          commit('retrieveEditData', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/users/${payload.id}`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  uploadAddExcelFile (context, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    formData.append('file', payload.file)
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/users/excel', formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadCheckDeleteExcelFile (context, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    formData.append('file', payload.file)
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/users/excel/get-delete-count', formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadDeleteExcelFile (context, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    formData.append('file', payload.file)
    formData.append('_method', 'DELETE')
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/users/excel', formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete (context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/users/${payload.id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deactivate (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/users/${payload.id}/deactivate`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  downloadExcelFileTrainingResults (context, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/users/excel/download/training_results', {
        responseType: 'blob',
        params: payload
      })
        .then((response) => {
          FileSaver.saveAs(response.data, 'users_training_results.xlsx')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  downloadExcelFileLevels (context, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/users/excel/download/levels', {
        responseType: 'blob',
        params: payload
      })
        .then((response) => {
          FileSaver.saveAs(response.data, 'users_levels.xlsx')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSteps ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/users/${payload.user_id}/steps`)
        .then((response) => {
          commit('retrieveSteps', response.data.data)
          commit('retrieveUserData', response.data.user)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteSteps (context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/users/${payload.user_id}/steps/${payload.steps_id}`)
        .then((response) => {
          context.commit('setStepsDeletedById', payload.steps_id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getChallenges (context, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/users/${payload.user_id}/steps/getChallengesNames`)
        .then((response) => {
          context.commit('retrieveChallenges', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activateSteps (context, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/users/${payload.user_id}/steps/${payload.steps_id}/restore`)
        .then((response) => {
          context.commit('setStepsActivatedById', payload.steps_id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createSteps ({ commit, state }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/users/${payload.user_id}/steps`, formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  downloadExcelFilePoints (context, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/users/${payload.user_id}/points/download`, {
        responseType: 'blob',
        params: payload
      })
        .then((response) => {
          FileSaver.saveAs(response.data, 'user_points.xlsx')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPoints ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/users/${payload.user_id}/points`)
        .then((response) => {
          commit('retrievePoints', response.data.data)
          commit('retrieveUserData', response.data.user)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCoins ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/users/${payload.user_id}/coin`)
        .then((response) => {
          commit('retrieveCoins', response.data.data)
          commit('retrieveUserData', response.data.user)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deletePoint (context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/users/${payload.user_id}/points/${payload.point_id}`)
        .then((response) => {
          context.commit('setPointDeletedById', payload.point_id)
          context.commit('retrieveUserData', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activatePoint (context, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/users/${payload.user_id}/points/${payload.point_id}/restore`)
        .then((response) => {
          context.commit('setPointActivatedById', payload.point_id)
          context.commit('retrieveUserData', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createPoint ({ commit, state }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/users/${payload.user_id}/points`, formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  createCoin ({ commit, state }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/users/${payload.user_id}/coin`, formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  editPoint ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/users/${payload.user_id}/points/${payload.point_id}`)
        .then((response) => {
          commit('retrieveEditPointData', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updatePoint ({ commit }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    formData.append('_method', 'PATCH')
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/users/${payload.user_id}/points/${payload.id}`, formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  getResetPasswordUrl ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/users/${payload.user_id}/get_reset_password_url`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getTrainings ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/users/${payload.user_id}/trainings`)
        .then((response) => {
          commit('retrieveTrainings', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getAdminAccesses ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/access_management', {
        params: payload
      })
        .then((response) => {
          commit('retrieveAdminAccesses', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    }) 
  },
  getAdminAccessesModules ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/access_management/modules')
        .then((response) => {
          commit('retrieveAdminAccessesModules', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    }) 
  },
  updateAccessesModules ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch('/api/admin-panel/access_management', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  createUser ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('api/admin-panel/users/create_admin', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          console.log('error', error)
          reject(error)
        })
    })
  }
}
