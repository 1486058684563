<template>
  <section v-if="loaded" class="pagebreak">
    <div v-for="(chart, name, index) in statisticsChartData" :key="index" class="chart-block">
      <h3 class="statistics-h3">{{ chart.series[0].name }}</h3>
      <vx-card>
          <statistics-counter
          :countData="chart"
          :period="statisticsPeriod"
          :colorPeriod="chart.options.colors[0]"
          diffPositionLeft
          :diffPercent="chart.diffPercent"
        />
        <vue-apex-charts type="area" height="383" width="100%" :options="chart.options" :series="chart.series" :ref="name" @legendClick="handleLegendClick" />
      </vx-card>
    </div>
  </section>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import chartConfigs from '@/components/statistics-cards/chartConfigs'
import loading from '@/views/components/Loading.vue'

import StatisticsCounter from './StatisticsCounter.vue'

export default {
  name: 'large-graphs-statistics',
  props: ['statisticsPeriod'],
  components: {
    VueApexCharts,
    loading,
    StatisticsCounter
  },

  data () {
    return {
      loading: false,
      loaded: false,
      oneMonthAgo: new Date().setMonth(new Date().getMonth() - 1),
      oneYearAgo: new Date().setFullYear(new Date().getFullYear() - 1),

      statisticsChartData: {},

      countUsersLogIn: {},
      countUniqueUsersLogIn: {}  
    }
  },
  methods: {
    async sequentialPromises (promises) {
      for (const promise of promises) {
        await promise
      }
    },
    handleLegendClick (chartContext, seriesIndex) {
      setTimeout(() => {
        const activeSeriesCount = chartContext.w.globals.series.filter(series => series.length > 0).length
        if (!chartContext.w.globals.series[0].length) {
          for (const key in this.statisticsChartData.countUsersLogIn) {
            if (key !== 'series' && this.countUsersLogIn.hasOwnProperty(key)) {
              this.statisticsChartData.countUsersLogIn[key] = this.$store.getters['statistics/countUsersLogIn'][key]
            }
          }
        } else {
          for (const key in this.statisticsChartData.countUsersLogIn) {
            if (key !== 'series' && this.countUsersLogIn.hasOwnProperty(key)) {
              this.statisticsChartData.countUsersLogIn[key] = this.$store.getters['statistics/uniqueCountUsersLogIn'][key]
            }
          }
        }
      })
    }
  },

  watch: {
    statisticsPeriod (newValue) {
      const chartRefs = [this.$refs.countUsers, this.$refs.countUsersLogIn, this.$refs.timeSpentInApp]
      chartRefs.forEach((chart) => {
        
        const chartStartDate = new Date(chart[0].$options.propsData.series[0].data[0][0])
        const monthStartDate = chartStartDate < this.oneMonthAgo ? this.oneMonthAgo : chartStartDate
        const yearStartDate = chartStartDate < this.oneYearAgo ? this.oneYearAgo : chartStartDate

        switch (newValue) {
        case this.$t('dashboard.labels.month'):
          chart[0].zoomX(monthStartDate, new Date())
          break
        case this.$t('dashboard.labels.year'):
          chart[0].zoomX(yearStartDate, new Date())
          break
        case this.$t('dashboard.labels.all_time'):
          chart[0].zoomX(chartStartDate, new Date())
          break
        default:
          chart[0].zoomX(new Date().setMonth(new Date().getMonth() - 1), new Date())
          break
        }
      })
    }
  },

  async created () {
    this.loaded = false

    const getCountUsers = await this.$store.dispatch('statistics/getCountUsers').then(() => {
      this.statisticsChartData.countUsers = this.$store.getters['statistics/countUsers']
      this.statisticsChartData.countUsers.series[0].name = this.$t('dashboard.titles.users')
      this.statisticsChartData.countUsers.options = chartConfigs.countUsersOptions
      this.statisticsChartData.countUsers.diffPercent = true
    }).catch((err) => {
      console.error(err)
    })

    const getTimeSpentInApp = await this.$store.dispatch('statistics/getTimeSpentInApp').then(() => {
      this.statisticsChartData.timeSpentInApp = this.$store.getters['statistics/timeSpentInApp']
      this.statisticsChartData.timeSpentInApp.series[0].name = this.$t('dashboard.titles.time_spent_in_the_app')
      this.statisticsChartData.timeSpentInApp.options = chartConfigs.timeSpentInAppOptions
      this.statisticsChartData.timeSpentInApp.diffPercent = false
    }).catch((err) => {
      console.error(err)
    })  

    const getUniqueCountUsersLogIn = this.$store.dispatch('statistics/getUniqueCountUsersLogIn').then(() => {
      const data = JSON.parse(JSON.stringify(this.$store.getters['statistics/uniqueCountUsersLogIn']))
      this.countUniqueUsersLogIn = data
      if (this.countUsersLogIn.hasOwnProperty('series')) {
        this.statisticsChartData.countUsersLogIn.series.push({
          name: this.$t('dashboard.titles.unique_visits_to_the_app'),
          data: data.series[0].data
        })
      } else {
        this.statisticsChartData.countUsersLogIn = data
        this.statisticsChartData.countUsersLogIn.options = chartConfigs.countUsersLogInOptions
        this.statisticsChartData.countUsersLogIn.series[0].name = this.$t('dashboard.titles.unique_visits_to_the_app')
        this.statisticsChartData.countUsersLogIn.diffPercent = true
      }
    }).catch((err) => {
      console.error(err)
    })

    const getCountUsersLogIn = this.$store.dispatch('statistics/getCountUsersLogIn').then(() => {
      const data = JSON.parse(JSON.stringify(this.$store.getters['statistics/countUsersLogIn']))
      this.countUsersLogIn = data
      if (this.countUniqueUsersLogIn.hasOwnProperty('series')) {
        this.statisticsChartData.countUsersLogIn.series.push({
          name: this.$t('dashboard.titles.visits_to_the_app'),
          data: data.series[0].data
        })
      } else {
        this.statisticsChartData.countUsersLogIn = data
        this.statisticsChartData.countUsersLogIn.options = chartConfigs.countUsersLogInOptions
        this.statisticsChartData.countUsersLogIn.series[0].name = this.$t('dashboard.titles.visits_to_the_app')
        this.statisticsChartData.countUsersLogIn.diffPercent = true
      }
    }).catch((err) => {
      console.error(err)
    })

    this.sequentialPromises([getCountUsers, getTimeSpentInApp, getUniqueCountUsersLogIn, getCountUsersLogIn]).then(() => {
      this.loaded = true
    }).catch((err) => {
      console.error(err)
    })
  }
}
</script>

<style lang="scss" scoped>
.chart-block {
  margin-bottom: 64px;
  // background-color: #f5f5f5;
}

.chart-block :deep(.apexcharts-legend-text) {
  font-size: 18px !important;
  margin-right: 10px;
}

.total-count {
  display: flex;
  gap: 32px;
  font-weight: 500;

  &__item {
  }

  &__label {
    font-size: 16px;
  }

  &__number {
    font-weight: 700;
    font-size: 32px;
    margin-top: 10px;

    &--countUsers {
      color: #28c76f;
    }

    &--countUsersLogIn {
      color: #7367f0;
    }

    &--timeSpentInApp {
      color: #ea5455;
    }
  }
}

.chart-block :deep(.apexcharts-legend-text) {
  font-size: 22px !important;
  margin-right: 10px;
}

.chart-block :deep(.apexcharts-legend) {
  bottom: -7px !important;
}

.chart-block :deep(.apexcharts-toolbar) {
 & > div {
   margin-right: 5px;
 }
}

.total-count {
  display: flex;
  gap: 32px;
  font-weight: 500;

  &__item {
  }

  &__label {
    font-size: 16px;
  }

  &__number {
    font-weight: 700;
    font-size: 32px;
    margin-top: 10px;

    &--countUsers {
      color: #28c76f;
    }

    &--countUsersLogIn {
      color: #7367f0;
    }

    &--timeSpentInApp {
      color: #ea5455;
    }
  }
}
</style>