import FormError from './../../modules/formError'

export default {
  suite: [],
  list: [],
  edit: {
    name: '',
    announcement: '',
    description: '',
    blockDescription: null,
    imageUrl: ''
  },
  formError: new FormError(),
  isActiveClubId: null
}
