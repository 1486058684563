import state from './moduleGeocachingState.js'
import mutations from './moduleGeocachingMutations.js'
import actions from './moduleGeocachingActions.js'
import getters from './moduleGeocachingGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
