// axios
import i18n from './i18n/i18n'
import axios from 'axios'


const isValidUrl = urlString => {
  const urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i') // validate fragment locator
  return !!urlPattern.test(urlString)
}

const instance = axios.create()
let resolveBaseURLPromise = () => {}
const baseURLPromise = new Promise((resolve) => {
  resolveBaseURLPromise = resolve
})

instance.interceptors.request.use(async (config) => {
  await baseURLPromise // будет продолжаться до тех пор, пока не получим baseUrl.
  config.headers['Accept-Language'] = i18n.locale
  return config
})

const configureBaseURL = async () => {
  try {
    const response = await axios.get('/base-url.json')
    const url = response.data.BASE_URL
    if (!isValidUrl(url)) {
      throw new Error('BaseUrl is not a url!')
    }
    console.log('baseURL из json ', url)
    return url
  } catch (error) {
    const url = process.env.VUE_APP_API_URL || 'https://api.crosslife.me'
    console.log('baseURL из .env ', url)
    return url
  }
}

const setAxiosDefaults = async () => {
  const baseURL = await configureBaseURL()
  instance.defaults.baseURL = baseURL
  resolveBaseURLPromise()
}

setAxiosDefaults()

export default instance

