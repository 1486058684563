import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'

export default {
  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/segments-without-sql')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getLists ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/segments/lists')
        .then((response) => {
          commit('retrieveLists', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getSegmentsOptions ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/segments')
        .then((response) => {
          commit('retrieveSegmentsOptions', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/segments/${payload.id}/edit`, { params: payload })
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  create ({ commit, state }, payload) {
    const config = {
      headers: { 'content-type': 'application/json' }
    }
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/segments', payload, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  update ({ commit }, payload) {
    const config = {
      headers: { 'content-type': 'application/json' }
    }
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/segments/${payload.id}`, payload, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  delete (context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/segments/${payload.id}`)
        .then((response) => {
          context.commit('removeSegmentById', payload.id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
