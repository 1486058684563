import state from './moduleCafeteriaState.js'
import mutations from './moduleCafeteriaMutations.js'
import actions from './moduleCafeteriaActions.js'
import getters from './moduleCafeteriaGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
