<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-show="!loading">
      <div class="vx-row">
        <div class="vx-col w-full mb-4 flex">
          <vs-switch v-model="isPublished" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
          <label class="ml-4 text-lg" :class="{'text-orange': isPublished}">{{ $t('services.labels.published') }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_published')">{{ $t(formError.get('is_published')) }}</span>
        </div>
      </div>

      <content-filter :targetId="id" :targetModel="'service'" style="margin-top: -3em;"></content-filter>

      <div class="vx-card tabs-container px-6 pt-6 pb-3">
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full" :label-placeholder="$t('services.labels.name')" v-model="name" />
            <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-textarea class="w-full" :label="$t('services.labels.announcement')" v-model="announcement" />
          </div>
        </div>
        <div class="vx-row" v-if="loaded">
          <div class="vx-col w-full mb-2">
            <vx-card no-shadow card-border :title="$t('services.labels.description')">
              <editor
                ref="editor"
                :key="$i18n.locale"
                :config="editorJsConfig"
              />
              <span class="text-danger text-sm" v-show="formError.has('block_description')">{{ $t(formError.get('block_description')) }}</span>
            </vx-card>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mt-2 mb-4">
            <label>Ссылка</label>
            <vs-input class="w-full" v-model="link" type="url"></vs-input>
            <span class="text-danger text-sm" v-show="formError.has('url')">{{ $t(formError.get('url')) }}</span>
          </div>
          <div class="vx-col w-full mb-2">
            <div class="image-container" style="width: 150px; height:150px; margin: 0 auto;">
              <picture-input
                v-if="!loading"
                ref="pictureInput"
                @prefill="onImgPrefill"
                @change="onImgChange"
                @error="onImgError"
                width="150"
                height="150"
                margin="16"
                :accept="this.$baseImageAcceptTypes"
                buttonClass="btn"
                size="10"
                :hideChangeButton="true"
                :crop="false"
                :prefill="imageUrl"
                :alertOnError="false"
                :customStrings="{
                drag: $t('categories.edit.labels.image'),
              }"
              >
              </picture-input>
              <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
            </div>
          </div>
        </div>
        <div v-if="imgCrop" class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-crop-container">
              <cropper
                ref="cropper"
                classname="cropper"
                :src="imgCrop"
                :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }"
                @change="changeCropImg"
                :stencil-props="{
                  aspectRatio: 1 / 1
                }"
              ></cropper>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('personal_goals.suite.title.tags') }}</label>
            <v-select 
              v-model="tags" 
              multiple 
              :placeholder="$t('services.labels.add_tags')"
              :closeOnSelect="false" 
              :options="this.$store.getters['tag/tagsOptions']" 
              :selectable="option => tags.indexOf(option) < 0" 
              :dir="$vs.rtl ? 'rtl' : 'ltr'" 
              />
            <span class="text-danger text-sm" v-show="formError.has('tags')">{{ $t(formError.get('tags')) }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('services.labels.segments') }}</label>
            <v-select 
              v-model="segments" 
              multiple 
              :placeholder="$t('services.labels.select_segment')" 
              :closeOnSelect="false" 
              :options="$store.getters['segment/segmentsOptions']" 
              :selectable="option => segments.indexOf(option) < 0" 
              :dir="$vs.rtl ? 'rtl' : 'ltr'" 
              />
            <span class="text-danger text-sm" v-show="formError.has('segments')">{{ $t(formError.get('segments')) }}</span>
          </div>
          <div class="vx-col w-full mb-2 mt-4">
            <label>{{ $t('services.labels.groups') }}</label>
            <v-select
              v-model="groups"
              :options="groupsOptions"
              :reduce="option => typeof option === 'string' ? { label: option } : option"
              :selectable="option => groups.indexOf(option) < 0"
              multiple
              taggable
              push-tags
              :placeholder="$t('services.labels.add_groups')"
            />
            <span class="text-danger text-sm" v-show="formError.has('groups')">{{ $t(formError.get('groups')) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button 
            @click="action(stayAfter = true)" 
            :disabled="actionButtonDisabled">
            {{ $t('services.buttons.' + type) }}
          </vs-button>

          <vs-button 
           @click="action()"
           :disabled="actionButtonDisabled">
           {{ $t('services.buttons.' + type) }} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
    </div>
    <vs-popup class="holamundo" title="" :active.sync="ErrorParsePopupActive">
      <p>{{ $t('blog.posts.edit.error_parse.content') }}</p>
      <div class="error-parse-button-confirm">
        <vs-button color="warning" type="filled" @click="ErrorParsePopupActive=false">{{ $t('blog.posts.edit.error_parse.button_confirm') }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import editorJsConfig from '@/editorjs/config.js'
import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import locale from 'flatpickr/dist/l10n/ru'

import loading from '@/views/components/Loading.vue'
import ContentFilter from '@/views/components/contentFilter.vue'
import helpers from '@/helpers/helpers.js'
import vSelect from 'vue-select'

export default{
  components: {
    flatPickr,
    PictureInput,
    Cropper,
    loading,
    vSelect,
    ContentFilter
  },
  data () {
    return {
      ErrorParsePopupActive: false,
      id: 0,
      name: '',
      image: null,
      imageUrl: '',
      imgCrop: null,
      imgWidth: null,
      imgHeight: null,
      isChangedImg: false,
      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error',
      link: '',
      announcement: '',
      tags: [],
      tagsOptions: [],
      segments: [],
      groups: [],
      groupsOptions: [],
      blockDescription: '',
      editorJsConfig: Object.assign({}, editorJsConfig),
      isPublished: false,
      type: 'create',
      actionButtonDisabled: false,
      loaded: false,
      loading: true,
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'd.m.Y H:i',
        locale: locale[this.$i18n.locale],
        time_24hr: true
      }
    }
  },
  computed: {

    formError () {
      return this.$store.getters['services/formError']
    }
  },
  methods: {
    onImgPrefill () {
      this.imgCrop = this.$refs.pictureInput.image
      this.image = this.imgCrop
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    changeCropImg ({coordinates, canvas}) {
      if (!this.imgWidth && !this.imgHeight) {
        this.imgWidth = coordinates.width
        this.imgHeight = coordinates.height
      }
      if ((coordinates.width === this.imgWidth || coordinates.height === this.imgHeight) && this.type === 'edit' && this.imageUrl) {
        this.isChangedImg = false
      } else {
        this.isChangedImg = true
      }
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },

    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'services/store' : 'services/update'
      this.dispatch(patch, stayAfter)
    },
    async dispatch (patch, stayAfter) {
      try {
        const blockDescription = await this.$refs.editor.state.editor.save()
        const payload = {
          id: this.id,
          name: this.name,
          image:
            (this.isChangedImg && this.image !== null) ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : null,
          announcement: this.announcement,
          tags: this.tags,
          url: this.link,
          segments: this.segments,
          groups: this.groups,
          block_description: JSON.stringify(blockDescription),
          is_published: +this.isPublished
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name: 'services'
          },
          routerPushToEdit: {
            name: 'services-edit'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    }
  },
  created () {
    this.$store.dispatch('segment/getSegmentsOptions')
    this.$store.dispatch('tag/getTagsOptions')
    this.$store.dispatch('services/getGroupsOptions').then(() => {
      this.groupsOptions = JSON.parse(JSON.stringify(this.$store.getters['services/groupsOptions']))
    })

    if (this.$router.currentRoute.name === 'services-edit') {
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('services/edit', { id: this.id })
        .then(() => {
          const edit = this.$store.getters['services/edit']
          this.name = edit.name
          console.log('edit', edit)

          this.imageUrl = this.getUrl(edit.imagePath, `${this.$http.defaults.baseURL}/api/admin-panel`)
          
          this.announcement = edit.announcement
          this.link = edit.url
          this.tags = edit.tags
          this.groups = edit.groups
          this.tags.map((tag) => {
            tag.id = tag.value
          })
          this.segments = edit.segments
          this.blockDescription = edit.blockDescription
          if (this.blockDescription !== '') {
            try {
              this.editorJsConfig.data = JSON.parse(edit.blockDescription)
            } catch (e) {
              this.ErrorParsePopupActive = true
              this.editorJsConfig.data = ''
            }
          }
          this.isPublished = edit.isPublished
          this.loaded = true
          this.loading = false
        })
    } else {
      this.loaded = true
      this.loading = false
    }
    this.$store.commit('services/clearFormError')
  }
}
</script>

<style>
  .image-container .preview-container {
    margin: 0!important;
  }
  .error-parse-button-confirm {
    margin-top: 20px;
  }
</style>
