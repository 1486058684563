<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-else>
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col w-full flex mb-4">
          <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
          <label class="ml-4" :class="{ 'text-orange': isActive }">{{ $t('mailings.fields.is_active') }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active')) }}</span>
        </div>
      </div>
      <div class="vx-card tabs-container px-6 pt-6 pb-3">
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('mailings.fields.title')" v-model="title" />
            <span class="text-danger text-sm" v-show="formError.has('title')">{{ $t(formError.get('title')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            {{ $t('Automatically substituted variables: name, company.') }}
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full mb-4" :label-placeholder="$t('mailings.fields.subject')" v-model="subject" />
            <span class="text-danger text-sm" v-show="formError.has('subject')">{{ $t(formError.get('subject')) }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vx-card no-shadow card-border :title="$t('mailings.fields.content')">
              <editor ref="editor" :config="editorJsConfig" />
              <span class="text-danger text-sm" v-show="formError.has('content')">{{ $t(formError.get('content')) }}</span>
            </vx-card>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2 mt-2">
            <vs-input class="w-1/2 mb-4" :label-placeholder="$t('Inactive days')" type="number" v-model="daysBefore"></vs-input>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-checkbox v-model="afterDeactivating">
              {{ $t('Deactivate the user after receiving the email') }}
            </vs-checkbox>
          </div>
        </div>

      </div>
      <div v-if="!isOnlyRead" class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button @click="action(stayAfter = true)" :disabled="actionButtonDisabled">{{
            $t('mailings.' + type + '.buttons.' + type)
          }}</vs-button>
          <vs-button @click="action()" :disabled="actionButtonDisabled">
          {{$t('mailings.' + type + '.buttons.' + type)}} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
    </div>
    <vs-popup class="holamundo" title="" :active.sync="ErrorParsePopupActive">
      <p>{{ $t('mailings.edit.error_parse.content') }}</p>
      <div class="error-parse-button-confirm">
        <vs-button color="warning" type="filled" @click="ErrorParsePopupActive=false">{{ $t('mailings.edit.error_parse.button_confirm') }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import helpers from '@/helpers/helpers.js'
import 'flatpickr/dist/flatpickr.css'
import editorJsConfig from '@/editorjs/config.js'
import loading from '@/views/components/Loading.vue'

export default {
  components: {
    loading
  },
  data () {
    return {
      ErrorParsePopupActive: false,
      id: 0,
      type: 'create',
      actionButtonDisabled: false,
      loading: true,
      title: '',
      slug: '',
      subject: '',
      daysBefore: 30,
      afterDeactivating: false,
      isActive: true,
      content: '',
      editorJsConfig: Object.assign({}, editorJsConfig),
      editorOption: {
        formats: ['bold', 'header', 'italic', 'link', 'list', 'blockquote', 'image', 'indent'],
        modules: {
          toolbar: '#toolbar'
        }
      }
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-mailings'] === 'read'
      }
    },

    targetType () {
      return this.afterDeactivating ? 'inactive_with_deactivation' : 'inactive'
    },
    formError () {
      return this.$store.getters['mailing/formError']
    }
  },

  methods: {
    
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'mailing/create' : 'mailing/update'

      this.dispatch(patch, stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {

        const blockDescription = await this.$refs.editor.state.editor.save()
        const payload = {
          id: this.id,
          title: this.title,
          subject: this.subject,
          content: JSON.stringify(blockDescription),
          slug: this.slug ? this.slug : new Date().getTime(),
          target_type: this.targetType,
          days_before: this.daysBefore,
          is_active: +this.isActive
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name: 'deactivate-users-mailings'
          },
          routerPushToEdit: {
            name: 'deactivate-users-mailings-edit'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    }
  },
  created () {
    this.id = this.$route.params.id
    if (this.$router.currentRoute.name === 'deactivate-users-mailings-edit') {
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('mailing/edit', { id: this.id }).then(() => {
        const edit = this.$store.getters['mailing/edit']
        this.title = edit.title
        this.slug = edit.slug
        this.subject = edit.subject
        this.daysBefore = edit.daysBefore
        this.content = edit.content
        if (this.content !== '') {
          try {
            this.editorJsConfig.data = JSON.parse(edit.content)
          } catch (e) {
            this.ErrorParsePopupActive = true
            this.editorJsConfig.data = ''
          }
        }
        this.sendingAt = edit.sendingAt
        this.afterDeactivating = edit.targetType === 'inactive_with_deactivation'
        this.isActive = edit.isActive
        this.id = edit.id
      })
      this.loading = false
    }
    this.loading = false

    this.$store.commit('mailing/clearFormError')
  }
}
</script>

<style>
.h-textarea .vs-textarea {
  min-height: 300px;
}

.error-parse-button-confirm {
  margin-top: 20px;
}
</style>
