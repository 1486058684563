import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'
import FileSaver from 'file-saver'
import router from '@/router'

export default {
  getSuite ({ commit }, payload = {club_id: localStorage.getItem('clubId')}) {
    return new Promise((resolve, reject) => {
      let url = '/api/admin-panel/categories'
      if (payload.hasOwnProperty('type')) { // если параметр не передаем, то получаем  programm + challenge
        url += `?type=${payload.type}`
      }
      axios.get(url, {
        headers: {
          'Club-id': payload.club_id
        }
      })
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getList ({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      let url = '/api/admin-panel/categories/list'
      if (payload.hasOwnProperty('type')) { // если параметр не передаем, то получаем  programm + challenge
        url += `?type=${payload.type}`
      }
      axios.get(url)
        .then((response) => {
          commit('retrieveList', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getServiceList ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/categories/service_list', { params: payload })
        .then((response) => {
          commit('retrieveServiceList', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  create ({ commit }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/categories', formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  createEvent ({ commit }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/categories/event', formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/categories/${payload.id}/edit`, { params: payload })
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  editEvent ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/categories/event/${payload.id}/edit`, { params: payload })
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          commit('edit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  destroy ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/categories/${payload.id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  destroyEvent ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/categories/event/${payload.id}`)
        .then((response) => {
          commit('removeEvent', payload.index)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  update ({ commit }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    formData.append('_method', 'PATCH')
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/categories/${payload.id}`, formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },

  updateEvent ({ commit }, payload) {
    const config = {
      headers: { 'content-type': 'application/json' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/categories/event/${payload.id}`, payload, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },

  sort ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch('/api/admin-panel/categories/sort', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getMembers ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/categories/${payload.id}/members`)
        .then((response) => {
          commit('retrieveMembers', response.data.data)
          commit('retrieveMembersTrainingsCount', response.data.training.count)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getStatistics ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/categories/${payload.id}/statistics`)
        .then((response) => {
          commit('retrieveStatistics', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  downloadMembers ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/categories/${payload.id}/members/download`, {
        responseType: 'blob'
      })
        .then((response) => {
          FileSaver.saveAs(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  downloadStatistics ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/categories/${payload.id}/statistics/download`, {
        responseType: 'blob'
      })
        .then((response) => {
          FileSaver.saveAs(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  downloadCategoryJson ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/categories/${payload.id}/download-json`, {
        responseType: 'blob'
      })
        .then((response) => {
          FileSaver.saveAs(response.data, `${payload.name}.json`)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  uploadAddJsonFile ({ commit }, payload) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Club-id': payload.club_id
      }
    }
    const formData = new FormData()
    formData.append('file', payload.file)
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/categories/upload-json', formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
