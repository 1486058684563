/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('./views/pages/Home.vue'),
          meta: {
            pageTitle: 'menu.home',
            rule: 'editor',
            breadCrumb: true
          }
        },
        {
          path: '/page2',
          name: 'page-2',
          component: () => import('./views/Page2.vue')
        },
        {
          path: '/programms',
          name: 'empty-programms',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'categories.suite.programm.title',
            rule: 'editor',
            breadCrumb: true
          },
          children:[
            {
              path: '',
              name: 'programms',
              component: () => import('./views/pages/Categories.vue'),
              meta: {
                pageTitle: 'categories.suite.programm.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'choose-type-category',
              name: 'choose-type-category',
              component: () => import('./views/pages/ChooseTypeCategory.vue'),
              meta: {
                pageTitle: 'categories.template.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'create-programm',
              name: 'create-programm',
              component: () => import('./views/pages/CreateCategory.vue'),
              meta: {
                pageTitle: 'categories.suite.programm.create_button',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-programm/:id',
              name: 'edit-programm',
              component: () => import('./views/pages/EditCategory.vue'),
              meta: {
                pageTitle: 'categories.edit.programm.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'trainings/:categoryId',
              name: 'empty-programm-trainings',
              component: () => import('./views/EmptyRouterView.vue'),
              meta: {
                pageTitle: 'trainings.suite.title',
                rule: 'editor',
                breadCrumb: true
              },
              children:[
                {
                  path: '',
                  name: 'programm-trainings',
                  component: () => import('./views/pages/Trainings.vue'),
                  meta: {
                    pageTitle: 'trainings.suite.title',
                    rule: 'editor',
                    breadCrumb: false
                  }
                },
                {
                  path: 'create-training/',
                  name: 'programm-create-training',
                  component: () => import('./views/pages/CreateTraining.vue'),
                  meta: {
                    pageTitle: 'trainings.create.title',
                    rule: 'editor',
                    breadCrumb: true
                  }
                },
                {
                  path: 'edit-training/:id',
                  name: 'programm-edit-training',
                  component: () => import('./views/pages/EditTraining.vue'),
                  meta: {
                    pageTitle: 'trainings.edit.title',
                    rule: 'editor',
                    breadCrumb: true
                  }
                }
              ]
            }
          ]
        },

        {
          path: '/events',
          name: 'empty-events',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'categories.suite.event.title',
            rule: 'editor',
            breadCrumb: true
          },
          children:[
            {
              path: '',
              name: 'events',
              component: () => import('./views/pages/Event/Events.vue'),
              meta: {
                pageTitle: 'categories.suite.event.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create-event',
              name: 'create-event',
              component: () => import('./views/pages/Event/CreateEditCategoryEvent.vue'),
              meta: {
                pageTitle: 'events.createtitle',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'edit-event',
              component: () => import('./views/pages/Event/CreateEditTabs.vue'),
              meta: {
                pageTitle: 'categories.edit.programm.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'trainings/:categoryId',
              name: 'empty-programm-trainings',
              component: () => import('./views/EmptyRouterView.vue'),
              meta: {
                pageTitle: 'trainings.suite.title',
                rule: 'editor',
                breadCrumb: true
              },
              children:[
                {
                  path: '',
                  name: 'programm-trainings',
                  component: () => import('./views/pages/Trainings.vue'),
                  meta: {
                    pageTitle: 'trainings.suite.title',
                    rule: 'editor',
                    breadCrumb: false
                  }
                },
                {
                  path: 'create-training/',
                  name: 'programm-create-training',
                  component: () => import('./views/pages/CreateTraining.vue'),
                  meta: {
                    pageTitle: 'trainings.create.title',
                    rule: 'editor',
                    breadCrumb: true
                  }
                },
                {
                  path: 'edit-training/:id',
                  name: 'programm-edit-training',
                  component: () => import('./views/pages/EditTraining.vue'),
                  meta: {
                    pageTitle: 'Edit training',
                    rule: 'editor',
                    breadCrumb: true
                  }
                }
              ]
            }
          ]
        },

        {
          path: '/chat-messages',
          name: 'empty-chat-messages',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'menu.message',
            rule: 'editor',
            breadCrumb: true
          },
          children:[
            {
              path: '',
              name: 'chat-messages',
              component: () => import('./views/pages/ChatMessages/ChatMessages.vue'),
              meta: {
                pageTitle: 'menu.message',
                rule: 'editor',
                breadCrumb: false
              }
            }
          ]
        },

        {
          path: '/personal-goals',
          name: 'empty-personal-goals',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'menu.personal_goals',
            rule: 'editor',
            breadCrumb: true
          },
          children:[
            {
              path: '',
              name: 'personal-goals',
              component: () => import('./views/pages/Missions/Missions.vue'),
              meta: {
                pageTitle: 'menu.personal_goals',
                rule: 'editor',
                breadCrumb: false
              }

            },
            {
              path: 'edit/:id',
              name: 'personal-goal-edit',
              component: () => import('./views/pages/Missions/CreateEditMission.vue'),
              meta: {
                pageTitle: 'personal_goals.title.edit',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: '/create-template',
              name: 'empty-personal-goal-template-create',
              component: () => import('./views/EmptyRouterView.vue'),
              meta: {
                pageTitle: 'personal_goals.title.template',
                rule: 'editor',
                breadCrumb: false
              },
              children: [
                {
                  path: '',
                  name: 'personal-goal-template-create',
                  component: () => import('./views/pages/Missions/createTemplateMission.vue'),
                  meta: {
                    pageTitle: 'personal_goals.title.template',
                    rule: 'editor',
                    breadCrumb: true
                  }
                },
                {
                  path: 'create',
                  name: 'personal-goal-create',
                  component: () => import('./views/pages/Missions/CreateEditMission.vue'),
                  meta: {
                    pageTitle: 'personal_goals.title.create',
                    rule: 'editor',
                    breadCrumb: true
                  }
                }
              ]
            }
          ]
        },

        {
          path: '/habits_trackers',
          name: 'empty-habits_trackers',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'menu.habits_trackers',
            rule: 'editor',
            breadCrumb: true
          },
          children:[
            {
              path: '',
              name: 'habits-trackers',
              component: () => import('./views/pages/HabitsTracker/HabitsTrackers.vue'),
              meta: {
                pageTitle: 'menu.habits_trackers',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'edit/:id',
              name: 'habits-tracker-edit',
              component: () => import('./views/pages/HabitsTracker/CreateEditHabitsTracker.vue'),
              meta: {
                pageTitle: 'habits_trackers.title.edit',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'create',
              name: 'habits-tracker-create',
              component: () => import('./views/pages/HabitsTracker/CreateEditHabitsTracker.vue'),
              meta: {
                pageTitle: 'habits_trackers.title.create',
                rule: 'editor',
                breadCrumb: true
              }
            }
            
          ]
        },

        {
          path: '/achievements',
          name: 'empty-achievements',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'achievements.suite.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'achievements',
              component: () => import('./views/pages/Achievements.vue'),
              meta: {
                pageTitle: 'achievements.suite.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create-achievement',
              name: 'create-achievement',
              component: () => import('./views/pages/CreateEditAchievement.vue'),
              meta: {
                pageTitle: 'achievements.create.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-achievement/:id',
              name: 'edit-achievement',
              component: () => import('./views/pages/CreateEditAchievement.vue'),
              meta: {
                pageTitle: 'achievements.edit.title',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/raffle',
          name: 'empty-raffle',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'gamification.raffle.suite.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'raffle',
              component: () => import('./views/pages/Gamification/Raffle.vue'),
              meta: {
                pageTitle: 'gamification.raffle.title',
                rule: 'editor',
                breadCrumb: false
              }
            }
          ]
        },

        {
          path: '/pollings',
          name: 'empty-polling',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'categories.suite.polling.title',
            rule: 'editor',
            breadCrumb: true
          },
          children:[
            {
              path: '',
              name: 'pollings',
              component: () => import('./views/pages/Categories.vue'),
              meta: {
                pageTitle: 'categories.suite.polling.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create-polling',
              name: 'create-polling',
              component: () => import('./views/pages/CreateCategory.vue'),
              meta: {
                pageTitle: 'categories.create.polling.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-polling/:id',
              name: 'edit-polling',
              component: () => import('./views/pages/EditCategory.vue'),
              meta: {
                pageTitle: 'categories.edit.polling.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'trainings/:categoryId',
              name: 'empty-polling-trainings',
              component: () => import('./views/EmptyRouterView.vue'),
              meta: {
                pageTitle: 'trainings.suite.title',
                rule: 'editor',
                breadCrumb: true
              },
              children:[
                {
                  path: '',
                  name: 'polling-trainings',
                  component: () => import('./views/pages/Trainings.vue'),
                  meta: {
                    pageTitle: 'trainings.suite.title',
                    rule: 'editor',
                    breadCrumb: false
                  }
                },
                {
                  path: 'create-training/',
                  name: 'polling-create-training',
                  component: () => import('./views/pages/CreateTraining.vue'),
                  meta: {
                    pageTitle: 'trainings.create.title',
                    rule: 'editor',
                    breadCrumb: true
                  }
                },
                {
                  path: 'edit-training/:id',
                  name: 'polling-edit-training',
                  component: () => import('./views/pages/EditTraining.vue'),
                  meta: {
                    pageTitle: 'trainings.edit.title',
                    rule: 'editor',
                    breadCrumb: true
                  }
                }
              ]
            }
          ]
        },

        {
          path: '/polling_healths',
          name: 'empty-polling_health',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'categories.suite.polling_health.title',
            rule: 'editor',
            breadCrumb: true
          },
          children:[
            {
              path: '',
              name: 'polling_healths',
              component: () => import('./views/pages/Categories.vue'),
              meta: {
                pageTitle: 'categories.suite.polling_health.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create-polling_health',
              name: 'create-polling_health',
              component: () => import('./views/pages/PollingHealth/CreateEditPollingHealth.vue'), 
              meta: {
                pageTitle: 'categories.create.polling_health.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-polling_health/:id',
              name: 'edit-polling_health',
              component: () => import('./views/pages/PollingHealth/CreateEditPollingHealth.vue'),
              meta: {
                pageTitle: 'categories.edit.polling_health.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'trainings/:categoryId',
              name: 'empty-polling_health-trainings',
              component: () => import('./views/EmptyRouterView.vue'),
              meta: {
                pageTitle: 'trainings.suite.title',
                rule: 'editor',
                breadCrumb: true
              },
              children:[
                {
                  path: '',
                  name: 'polling_health-trainings',
                  component: () => import('./views/pages/Trainings.vue'),
                  meta: {
                    pageTitle: 'trainings.suite.title',
                    rule: 'editor',
                    breadCrumb: false
                  }
                },
                {
                  path: 'create-training/',
                  name: 'polling_health-create-training',
                  component: () => import('./views/pages/CreateTraining.vue'),
                  meta: {
                    pageTitle: 'trainings.create.title',
                    rule: 'editor',
                    breadCrumb: true
                  }
                },
                {
                  path: 'edit-training/:id',
                  name: 'polling_health-edit-training',
                  component: () => import('./views/pages/EditTraining.vue'),
                  meta: {
                    pageTitle: 'trainings.edit.title',
                    rule: 'editor',
                    breadCrumb: true
                  }
                }
              ]
            }
          ]
        },

        {
          path: '/users',
          name: 'empty-users',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'users.suite.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'users',
              component: () => import('./views/pages/Users.vue'),
              meta: {
                pageTitle: 'users.suite.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create-user',
              name: 'create-user',
              component: () => import('./views/pages/CreateUser.vue'),
              meta: {
                pageTitle: 'users.create.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-user/:id',
              name: 'edit-user',
              component: () => import('./views/pages/EditUser.vue'),
              meta: {
                pageTitle: 'users.edit.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-user/:user_id/points',
              name: 'points',
              component: () => import('./views/pages/User/Points.vue'),
              meta: {
                pageTitle: 'users.edit.tabs.points',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-user/:user_id/coins',
              name: 'coins',
              component: () => import('./views/pages/User/Coins.vue'),
              meta: {
                pageTitle: 'users.edit.tabs.coins',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-user/:user_id/management',
              name: 'points',
              component: () => import('./views/pages/User/Management.vue'),
              meta: {
                pageTitle: 'users.edit.tabs.management',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-user/:user_id/steps/create',
              name: 'steps-create',
              component: () => import('./views/pages/User/CreateEditSteps.vue'),
              meta: {
                pageTitle: 'users.edit.tabs.create',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-user/:user_id/points/create',
              name: 'point-create',
              component: () => import('./views/pages/User/CreateEditPoint.vue'),
              meta: {
                pageTitle: 'users.edit.tabs.create',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-user/:user_id/coins/create',
              name: 'coin-create',
              component: () => import('./views/pages/User/CreateCoin.vue'),
              meta: {
                pageTitle: 'users.edit.tabs.create',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-user/:user_id/points/:point_id',
              name: 'point-edit',
              component: () => import('./views/pages/User/CreateEditPoint.vue'),
              meta: {
                pageTitle: 'users.edit.tabs.points',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-user/:user_id/steps',
              name: 'steps',
              component: () => import('./views/pages/User/Steps.vue'),
              meta: {
                pageTitle: 'users.edit.tabs.steps',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-user/:user_id/trainings',
              name: 'trainings',
              component: () => import('./views/pages/User/Trainings.vue'),
              meta: {
                pageTitle: 'users.edit.tabs.trainings',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },

        {
          path: '/segments',
          name: 'empty-segment',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'segments.suite.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'segments',
              component: () => import('./views/pages/Segments.vue'),
              meta: {
                pageTitle: 'segments.suite.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'segments-create',
              component: () => import('./views/pages/Segment/CreateSegment.vue'),
              meta: {
                pageTitle: 'segments.create.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'segments-edit',
              component: () => import('./views/pages/Segment/CreateSegment.vue'),
              meta: {
                pageTitle: 'segments.create.title_edit',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/registration-codes',
          name: 'registration-codes',
          component: () => import('./views/pages/Settings/RegistrationCodes.vue'),
          meta: {
            pageTitle: 'registration_codes.suite.title',
            rule: 'editor',
            breadCrumb: true
          }
        },
        {
          path: '/faq',
          name: 'empty-faq',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'faq.suite.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'faq',
              component: () => import('./views/pages/FAQ/Faq.vue'),
              meta: {
                pageTitle: 'faq.suite.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'faq-create',
              component: () => import('./views/pages/FAQ/CreateFaq.vue'),
              meta: {
                pageTitle: 'faq.create.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'faq-edit',
              component: () => import('./views/pages/FAQ/CreateFaq.vue'),
              meta: {
                pageTitle: 'faq.edit.title',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },

        {
          path: '/content-pages',
          name: 'empty-content-pages',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'content_pages.suite.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'content-pages',
              component: () => import('./views/pages/ContentPages.vue'),
              meta: {
                pageTitle: 'content_pages.suite.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create-content-page',
              name: 'create-content-page',
              component: () => import('./views/pages/CreateEditContentPage.vue'),
              meta: {
                pageTitle: 'content_pages.create.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-content-page/:id',
              name: 'edit-content-page',
              component: () => import('./views/pages/CreateEditContentPage.vue'),
              meta: {
                pageTitle: 'content_pages.edit.title',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },

        {
          path: '/edit-club/:id',
          name: 'edit-club',
          component: () => import('./views/pages/EditClub.vue'),
          meta: {
            pageTitle: 'clubs.edit.title',
            rule: 'editor',
            breadCrumb: true
          }
        },

        {
          path: '/pedometer/challenges',
          name: 'empty-pedometer-challenges',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'pedometer.challenges.suite.old_title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'pedometer-challenges',
              component: () => import('./views/pages/Pedometer/Challenges.vue'),
              meta: {
                pageTitle: 'pedometer.challenges.suite.old_title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create-challenge',
              name: 'pedometer-create-challenge',
              component: () => import('./views/pages/Pedometer/CreateEditChallenge.vue'),
              meta: {
                pageTitle: 'pedometer.challenges.create.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-challenge/:id',
              name: 'pedometer-edit-challenge',
              component: () => import('./views/pages/Pedometer/CreateEditChallenge.vue'),
              meta: {
                pageTitle: 'pedometer.challenges.edit.title',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },

        {
          path: '/common-goals',
          name: 'empty-common-goals',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'pedometer.challenges.suite.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'common-goals',
              component: () => import('./views/pages/CommonGoals/CommonGoals.vue'),
              meta: {
                pageTitle: 'pedometer.challenges.suite.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'common-goals-create',
              component: () => import('./views/pages/CommonGoals/CreateEditCommonGoals.vue'),
              meta: {
                pageTitle: 'pedometer.challenges.create.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'common-goals-edit',
              component: () => import('./views/pages/CommonGoals/CreateEditCommonGoals.vue'),
              meta: {
                pageTitle: 'pedometer.challenges.edit.title',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },

        {
          path: '/blog/posts',
          name: 'empty-blog-posts',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'blog.posts.suite.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'blog-posts',
              component: () => import('./views/pages/Blog/Posts.vue'),
              meta: {
                pageTitle: 'blog.posts.suite.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create-post',
              name: 'blog-create-post',
              component: () => import('./views/pages/Blog/CreateEditPost.vue'),
              meta: {
                pageTitle: 'blog.posts.create.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-post/:id',
              name: 'blog-edit-post',
              component: () => import('./views/pages/Blog/CreateEditPost.vue'),
              meta: {
                pageTitle: 'blog.posts.edit.title',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },

        {
          path: '/clubs',
          name: 'empty-clubs',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'clubs.suite.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'clubs',
              component: () => import('./views/pages/Club.vue'),
              meta: {
                pageTitle: 'clubs.suite.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'club-create',
              component: () => import('./views/pages/EditClub.vue'),
              meta: {
                pageTitle: 'clubs.create.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit-club/:id',
              name: 'edit-club',
              component: () => import('./views/pages/EditClub.vue'),
              meta: {
                pageTitle: 'clubs.edit.title',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/notifications',
          name: 'empty-notifications',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'notifications.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'notifications',
              component: () => import('./views/pages/Notifications.vue'),
              meta: {
                pageTitle: 'notifications.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'notifications-create',
              component: () => import('./views/pages/Notification/CreateNotification.vue'),
              meta: {
                pageTitle: 'notifications.createtitle',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'notifications-edit',
              component: () => import('./views/pages/Notification/CreateNotification.vue'),
              meta: {
                pageTitle: 'notifications.edittitle',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/announcements',
          name: 'empty-announcements',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'announcements.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'announcements',
              component: () => import('./views/pages/Announcements.vue'),
              meta: {
                pageTitle: 'announcements.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'announcements-create',
              component: () => import('./views/pages/Announcement/CreateEditAnnouncement.vue'),
              meta: {
                pageTitle: 'announcements.createtitle',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'announcements-edit',
              component: () => import('./views/pages/Announcement/CreateEditAnnouncement.vue'),
              meta: {
                pageTitle: 'announcements.edittitle',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/services',
          name: 'empty-services',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'services.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'services',
              component: () => import('./views/pages/Services/Services.vue'),
              meta: {
                pageTitle: 'services.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'services-create',
              component: () => import('./views/pages/Services/CreateEditServices.vue'),
              meta: {
                pageTitle: 'services.create',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'services-edit',
              component: () => import('./views/pages/Services/CreateEditServices.vue'),
              meta: {
                pageTitle: 'services.edit',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/shop/orders',
          name: 'empty-shop-orders',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'shop_orders.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'shop-orders',
              component: () => import('./views/pages/ShopOrders.vue'),
              meta: {
                pageTitle: 'shop_orders.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'edit/:id',
              name: 'shop-orders-edit',
              component: () => import('./views/pages/ShopOrder/CreateEditShopOrder.vue'),
              meta: {
                pageTitle: 'shop_orders.edittitle',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/shop/products',
          name: 'empty-shop-products',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'shop_products.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'shop-products',
              component: () => import('./views/pages/ShopProducts.vue'),
              meta: {
                pageTitle: 'shop_products.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'templates',
              name: 'templates-product',
              component: () => import('./views/pages/ShopProduct/createTemplateProduct.vue'),
              meta: {
                pageTitle: 'shop_products.template.title',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'create',
              name: 'shop-products-create',
              component: () => import('./views/pages/ShopProduct/CreateEditShopProduct.vue'),
              meta: {
                pageTitle: 'shop_products.createtitle',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'shop-products-edit',
              component: () => import('./views/pages/ShopProduct/CreateEditShopProductTabs.vue'),
              meta: {
                pageTitle: 'shop_products.edittitle',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/shop/categories',
          name: 'empty-shop-categories',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'shop_categories.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'shop-categories',
              component: () => import('./views/pages/ShopCategories/ShopCategories.vue'),
              meta: {
                pageTitle: 'shop_categories.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'shop-category-create',
              component: () => import('./views/pages/ShopCategories/CreateEditShopCategory.vue'),
              meta: {
                pageTitle: 'shop_categories.createtitle',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'shop-category-edit',
              component: () => import('./views/pages/ShopCategories/CreateEditShopCategory.vue'),
              meta: {
                pageTitle: 'shop_categories.edittitle',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/shop/settings',
          name: 'empty-shop-settings',
          component: () => import('./views/pages/ShopSettings/ShopSettings.vue'),
          meta: {
            pageTitle: 'shop_settings.title',
            rule: 'editor',
            breadCrumb: true
          }
        },
        {
          path: '/activities',
          name: 'empty-activities',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'activities.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'activities',
              component: () => import('./views/pages/Activities.vue'),
              meta: {
                pageTitle: 'activities.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'activities-create',
              component: () => import('./views/pages/Activity/CreateEditActivity.vue'),
              meta: {
                pageTitle: 'activities.createtitle',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'activities-edit',
              component: () => import('./views/pages/Activity/CreateEditActivity.vue'),
              meta: {
                pageTitle: 'activities.edittitle',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/teams',
          name: 'empty-teams',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'teams.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'teams',
              component: () => import('./views/pages/Teams.vue'),
              meta: {
                pageTitle: 'teams.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'teams-create',
              component: () => import('./views/pages/Team/CreateEditTeam.vue'),
              meta: {
                pageTitle: 'teams.createtitle',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'teams-edit',
              component: () => import('./views/pages/Team/CreateEditTeam.vue'),
              meta: {
                pageTitle: 'teams.edittitle',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/reports',
          name: 'empty-reports',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'reports.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'reports',
              component: () => import('./views/pages/Reports.vue'),
              meta: {
                pageTitle: 'reports.title',
                rule: 'editor',
                breadCrumb: false
              }
            }
          ]
        },
        {
          path: '/geocaching',
          name: 'empty-geocaching',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'geocaching.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'geocaching',
              component: () => import('./views/pages/Geocaching/Geocaching.vue'),
              meta: {
                pageTitle: 'geocaching.title',
                rule: 'editor',
                breadCrumb: false
              }
            }
          ]
        },
        {
          path: '/mailings',
          name: 'empty-mailings',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'mailings.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'mailings',
              component: () => import('./views/pages/Mailings.vue'),
              meta: {
                pageTitle: 'mailings.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'mailings-create',
              component: () => import('./views/pages/Mailing/CreateEditMailing.vue'),
              meta: {
                pageTitle: 'mailings.createtitle',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'mailings-edit',
              component: () => import('./views/pages/Mailing/CreateEditMailing.vue'),
              meta: {
                pageTitle: 'mailings.edittitle',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/deactivate-users-mailings',
          name: 'empty-deactivate-users-mailings',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'menu.deactivating_users',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'deactivate-users-mailings',
              component: () => import('./views/pages/DeactivateMailings/DeactivateMailing.vue'),
              meta: {
                pageTitle: 'menu.deactivating_users',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'deactivate-users-mailings-create',
              component: () => import('./views/pages/DeactivateMailings/CreateEditDeactivateMailing.vue'),
              meta: {
                pageTitle: 'mailings.createtitle',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'deactivate-users-mailings-edit',
              component: () => import('./views/pages/DeactivateMailings/CreateEditDeactivateMailing.vue'),
              meta: {
                pageTitle: 'mailings.edittitle',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/points-settings',
          name: 'empty-points-settings',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'points_settings.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'points-settings',
              component: () => import('./views/pages/PointsSettings.vue'),
              meta: {
                pageTitle: 'points_settings.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'points-settings-create',
              component: () => import('./views/pages/PointSetting/CreateEditPointSetting.vue'),
              meta: {
                pageTitle: 'points_settings.createtitle',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'points-settings-edit',
              component: () => import('./views/pages/PointSetting/CreateEditPointSetting.vue'),
              meta: {
                pageTitle: 'points_settings.edittitle',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },
        {
          path: '/cafeteria/catalog',
          name: 'empty-cafeteria-catalog',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'VMI. Catalog',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'cafeteria-catalog',
              component: () => import('./views/pages/Cafeteria/catalog.vue'),
              meta: {
                pageTitle: 'VMI. Catalog',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'product-edit/:id',
              name: 'cafeteria-product-edit',
              component: () => import('./views/pages/Cafeteria/createEditProduct.vue'),
              meta: {
                pageTitle: 'Редактирование продукта',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'product/create',
              name: 'cafeteria-product-create',
              component: () => import('./views/pages/Cafeteria/createEditProduct.vue'),
              meta: {
                pageTitle: 'Создание продукта',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        },

        {
          path: '/cafeteria/orders',
          name: 'empty-cafeteria-orders',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'cafeteria.orders',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'cafeteria-orders',
              component: () => import('./views/pages/Cafeteria/orders.vue'),
              meta: {
                pageTitle: 'VMI. Orders',
                rule: 'editor',
                breadCrumb: false
              }
            }

          ]
        },
        {
          path: '/relevant',
          name: 'empty-relevant',
          component: () => import('./views/EmptyRouterView.vue'),
          meta: {
            pageTitle: 'relevant.title',
            rule: 'editor',
            breadCrumb: true
          },
          children: [
            {
              path: '',
              name: 'relevant',
              component: () => import('./views/pages/Relevant/Relevant.vue'),
              meta: {
                pageTitle: 'relevant.title',
                rule: 'editor',
                breadCrumb: false
              }
            },
            {
              path: 'create',
              name: 'relevant-create',
              component: () => import('./views/pages/Relevant/CreateEditRelevant.vue'),
              meta: {
                pageTitle: 'relevant.createtitle',
                rule: 'editor',
                breadCrumb: true
              }
            },
            {
              path: 'edit/:id',
              name: 'relevant-edit',
              component: () => import('./views/pages/Relevant/CreateEditRelevant.vue'),
              meta: {
                pageTitle: 'relevant.edittitle',
                rule: 'editor',
                breadCrumb: true
              }
            }
          ]
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/Login.vue')
        },
        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('@/views/pages/Register.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '/athlete-after-login',
          name: 'athlete-after-login',
          component: () => import('./views/pages/AthleteAfterLogin')
        },
        {
          path: '/pages/update-password',
          name: 'update-password',
          component: () => import('@/views/pages/UpdatePassword.vue')
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  //убираем заврешающие слеши если есть
  const newPath = to.fullPath.replace(/\/+$/, '')
  if (to.fullPath !== '/' && to.fullPath !== newPath) {
    next({path: newPath})
  } else {
    next()
  }
})


export default router
