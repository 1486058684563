import FormError from  '../../modules/formError'

export default {
  suite: [],
  edit: {
    name: ''
  },
  groupsOptions: [],
  formError: new FormError()

}
