<template>
  <div>
    <div class="vx-row">
      <loading v-if="loading"></loading>
    </div>
    <div class="vx-row">
      <div v-for="(value, key) in statistics" :key="key" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line
          hideChart
          class="mb-base"
          :icon="statisticsData[key].icon"
          icon-right
          :statistic="value"
          :statisticTitle="statisticsData[key].title"
          :color="statisticsData[key].color"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col">
        <vs-button type="gradient" color="#7367F0" gradient-color-secondary="#CE9FFC"  @click="$store.dispatch('category/downloadStatistics', { id: $route.params.id })">{{$t('categories.edit.statistics.titles.download_statistics')}}</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import loading from '@/views/components/Loading.vue'

export default {
  props: ['isActive'],
  components: {
    StatisticsCardLine,
    loading
  },
  watch: {
    isActive (val) {
      if (val === true) {
        this.$store.dispatch('category/getStatistics', { id: this.$route.params.id }).then(() => {
          this.loading = false
        })
      }
    }
  },
  data () {
    return {
      statisticsData: {
        countUsers: {
          icon: 'SmileIcon',
          title: this.$t('categories.edit.statistics.titles.count_users'),
          color: 'primary'
        },
        countAddedResults: {
          icon: 'ClipboardIcon',
          title: this.$t('categories.edit.statistics.titles.count_added_results'),
          color: 'success'
        },
        countComments: {
          icon: 'MessageSquareIcon',
          title: this.$t('categories.edit.statistics.titles.count_comments'),
          color: 'warning'
        }
      },
      loading: true
    }
  },
  computed: {
    statistics () {
      return this.$store.getters['category/statistics']
    }
  }
}
</script>
