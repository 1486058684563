import state from './moduleShopProductState.js'
import mutations from './moduleShopProductMutations.js'
import actions from './moduleShopProductActions.js'
import getters from './moduleShopProductGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
