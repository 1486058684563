<template>
  <div class="vx-col w-full mb-base flex justify-end">
    <router-link :to="`/users/edit-user/${this.user_id}/management`" class="vs-button vs-button-primary vs-button-filled">{{ $t('users.edit.tabs.management') }}</router-link>
    <router-link :to="`/users/edit-user/${this.user_id}`" class="vs-button vs-button-primary vs-button-filled">{{ $t('users.edit.tabs.edit') }}</router-link>
    <router-link :to="`/users/edit-user/${this.user_id}/points`" class="vs-button vs-button-primary vs-button-filled">{{ $t('users.edit.tabs.points') }}</router-link>
    <router-link :to="`/users/edit-user/${this.user_id}/coins`" class="vs-button vs-button-primary vs-button-filled">{{ $t('users.edit.tabs.coins') }}</router-link>
    <router-link :to="`/users/edit-user/${this.user_id}/steps`" class="vs-button vs-button-primary vs-button-filled">{{ $t('users.edit.tabs.steps') }}</router-link>
    <router-link :to="`/users/edit-user/${this.user_id}/trainings`" class="vs-button vs-button-primary vs-button-filled">{{ $t('users.edit.tabs.trainings') }}</router-link>
  </div>
</template>
<script>

export default {
  name: 'UserNavBar',
  props: ['user_id']
}
</script>
