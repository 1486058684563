import helpers from '@/helpers/helpers.js'
import moment from 'moment'

export default {
  retrieveRoll (state, roll) {
    roll.tickets.forEach((value, index) => {
      console.log(value)
      console.log(index)
      console.log(roll.tickets[index])
      roll.tickets[index].type = '-'
      if (value.training) {
        roll.tickets[index].type = `${roll.tickets[index].training.category.name} | ${roll.tickets[index].training.name}`
      } else if (roll.tickets[index].activity) {
        roll.tickets[index].type = roll.tickets[index].activity.name
      }

      roll.tickets[index].created_at = moment(String(value.created_at)).format('YYYY-DD-MM HH:mm:ss')
    })
    state.roll = roll
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  }
}
