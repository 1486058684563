<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-else class="vx-card tabs-container px-6 pt-6 pb-3">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-input class="w-full mb-4" :label-placeholder="$t('segments.create.fields.name')" v-model="name" />
          <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-input class="w-full mb-4" :label-placeholder="$t('segments.create.fields.slug')" v-model="slug" />
          <span class="text-danger text-sm" v-show="formError.has('slug')">{{ $t(formError.get('slug')) }}</span>
        </div>
      </div>


      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('segments.create.fields.users') }}</label>
          <template>
            <v-select multiple :closeOnSelect="false" v-model="users_selected" :options="users_options" :selectable="option => users_selected.indexOf(option) < 0" :dir="$vs.rtl ? 'rtl' : 'ltr'" /><br>
          </template>
          <span class="text-danger text-sm" v-show="formError.has('users_selected')">{{ $t(formError.get('users_selected')) }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('segments.create.fields.departments') }}</label>
          <template>
            <v-select multiple :closeOnSelect="false" v-model="departments_selected" :options="departments_options" :selectable="option => departments_selected.indexOf(option) < 0" :dir="$vs.rtl ? 'rtl' : 'ltr'" /><br>
          </template>
          <span class="text-danger text-sm" v-show="formError.has('departments_selected')">{{ $t(formError.get('departments_selected')) }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('segments.create.fields.teams') }}</label>
          <template>
            <v-select multiple :closeOnSelect="false" v-model="teams_selected" :options="teams_options" :selectable="option => teams_selected.indexOf(option) < 0" :dir="$vs.rtl ? 'rtl' : 'ltr'" /><br>
          </template>
          <span class="text-danger text-sm" v-show="formError.has('teams_selected')">{{ $t(formError.get('teams_selected')) }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('segments.create.fields.city') }}</label>
          <template>
            <v-select multiple :closeOnSelect="false" v-model="cities_selected" :options="cities_options" :selectable="option => cities_selected.indexOf(option) < 0" :dir="$vs.rtl ? 'rtl' : 'ltr'" /><br>
          </template>
          <span class="text-danger text-sm" v-show="formError.has('cities_selected')">{{ $t(formError.get('cities_selected')) }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('segments.create.fields.gender') }}</label>
          <template>
            <v-select multiple :closeOnSelect="false" v-model="genders_selected" :options="genders_options" :selectable="option => genders_selected.indexOf(option) < 0" :dir="$vs.rtl ? 'rtl' : 'ltr'" /><br>
          </template>
          <span class="text-danger text-sm" v-show="formError.has('genders_selected')">{{ $t(formError.get('genders_selected')) }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('segments.create.fields.created_at_greater_now_minus_value') }}</label>
          <template>
            <vs-input class="w-full" v-model="created_at_greater_now_minus_value"/>
          </template>
          <span class="text-danger text-sm" v-show="formError.has('created_at_greater_now_minus_value')">{{ $t(formError.get('created_at_greater_now_minus_value')) }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('segments.create.fields.created_at_lower_now_minus_value') }}</label>
          <template>
            <vs-input class="w-full" v-model="created_at_lower_now_minus_value"/>
          </template>
          <span class="text-danger text-sm" v-show="formError.has('created_at_lower_now_minus_value')">{{ $t(formError.get('created_at_lower_now_minus_value')) }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('segments.create.fields.created_at_greater_datetime') }}</label>
          <div class="w-full">
            <flat-pickr :config="configdateTimePicker" v-model="created_at_greater_datetime" :placeholder="$t('segments.create.fields.created_at_greater_datetime_button')" :monday-first="($i18n.locale === 'ru') ? true : false" />
          </div>
          <span class="text-danger text-sm" v-show="formError.has('created_at_greater_datetime')">{{ $t(formError.get('created_at_greater_datetime')) }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('segments.create.fields.created_at_lower_datetime') }}</label>
          <div class="w-full">
            <flat-pickr :config="configdateTimePicker" v-model="created_at_lower_datetime" :placeholder="$t('segments.create.fields.created_at_lower_datetime_button')" :monday-first="($i18n.locale === 'ru') ? true : false" />
          </div>
          <span class="text-danger text-sm" v-show="formError.has('created_at_lower_datetime')">{{ $t(formError.get('created_at_lower_datetime')) }}</span>
        </div>
      </div>
    </div>
    <div class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button  @click="action(stayAfter = true)" :disabled="actionButtonDisabled || isOnlyRead">{{ $t('pedometer.challenges.' + type + '.buttons.' + type) }}</vs-button>
          <vs-button  @click="action()" :disabled="actionButtonDisabled || isOnlyRead">
            {{ $t('pedometer.challenges.' + type + '.buttons.' + type) }} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
  </div>
</template>

<script>

import helpers from '@/helpers/helpers.js'
import loading from '@/views/components/Loading.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import locale from 'flatpickr/dist/l10n/ru'


export default{
  components: {
    flatPickr,
    'v-select': vSelect,
    loading
  },
  data () {
    return {
      today: helpers.today('-'),
      dateFormat: 'dd.MM.yyyy H:i:s',
      datepicker: {
      },
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: locale[this.$i18n.locale]
      },
      type: 'create',
      actionButtonDisabled: false,
      loading: true,
      id: null,
      name: '',
      slug: '',
      users_selected: [],
      departments_selected: [],
      teams_selected: [],
      cities_selected: [],
      genders_selected: [],
      users_options: [],
      departments_options: [],
      teams_options: [],
      cities_options: [],
      genders_options: [],
      created_at_greater_now_minus_value: null,
      created_at_lower_now_minus_value: null,
      created_at_greater_datetime: null,
      created_at_lower_datetime: null
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-settings-segments'] === 'read' ||  
               accessUser['admin-settings'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['segment/formError']
    }
  },
  methods: {
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'segment/create' : 'segment/update'
      this.dispatch(patch, stayAfter)
    },
    async dispatch (patch, stayAfter) {
      try {
        const payload = {
          id: this.id,
          name: this.name,
          slug: this.slug,
          user: this.users_selected,
          department: this.departments_selected,
          team: this.teams_selected,
          city: this.cities_selected,
          gender: this.genders_selected,
          createdAtGreaterNowMinus: this.created_at_greater_now_minus_value ? this.created_at_greater_now_minus_value : null,
          createdAtLowerNowMinus: this.created_at_lower_now_minus_value ? this.created_at_lower_now_minus_value : null,
          createdAtGreaterDatetime: this.created_at_greater_datetime ? this.created_at_greater_datetime : null,
          createdAtLowerDatetime: this.created_at_lower_datetime ? this.created_at_lower_datetime : null
        }

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name:'segments'
          },
          routerPushToEdit: {name: 'segments-edit'}
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    }
  },
  created () {

    this.$store.dispatch('segment/getLists')
      .then(() => {
        this.users_options = this.$store.getters['segment/lists']['users_options']
        this.genders_options = this.$store.getters['segment/lists']['genders_options']
        this.teams_options = this.$store.getters['segment/lists']['teams_options']
        this.cities_options = this.$store.getters['segment/lists']['cities_options']
        this.departments_options = this.$store.getters['segment/lists']['departments_options']

        this.id = this.$route.params.id
        if (this.$router.currentRoute.name === 'segments-edit') {
          this.type = 'edit'
          this.id = this.$route.params.id
          this.$store.dispatch('segment/edit', { id: this.id})
            .then(() => {
              const edit = this.$store.getters['segment/edit']
              console.log(edit)
              this.name = edit.name
              this.slug = edit.slug
              this.users_selected = edit.user
              this.teams_selected = edit.team
              this.departments_selected = edit.department
              this.genders_selected = edit.gender
              this.cities_selected = edit.city
              this.id = edit.id

              if (edit.createdAtGreaterNowMinus) {
                this.created_at_greater_now_minus_value = edit.createdAtGreaterNowMinus
              }
              if (edit.createdAtLowerNowMinus) {
                this.created_at_lower_now_minus_value = edit.createdAtLowerNowMinus
              }
              if (edit.createdAtGreaterDatetime) {
                this.created_at_greater_datetime = edit.createdAtGreaterDatetime
              }
              if (edit.createdAtLowerDatetime) {
                this.created_at_lower_datetime = edit.createdAtLowerDatetime
              }


              this.loading = false
            })
        } else {
          this.loading = false
        }
      })
    this.$store.commit('segment/clearFormError')
  }
}
</script>

<style>
</style>
