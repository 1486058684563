export default {
  suite (state) {
    return state.suite
  },
  list (state) {
    return state.list
  },
  edit (state) {
    return state.edit
  },
  exercises (state) {
    return state.exercises
  },
  exercises1 (state) {
    return state.exercises1
  },
  exercises2 (state) {
    return state.exercises2
  },
  formError (state) {
    return state.formError
  },
  exercisesCreate (state) {
    return state.exercisesCreate
  },
  exercisesCreate1 (state) {
    return state.exercisesCreate1
  },
  exercisesCreate2 (state) {
    return state.exercisesCreate2
  },

  exercisesUpdate (state) {
    return state.exercisesUpdate
  },
  exercisesUpdate1 (state) {
    return state.exercisesUpdate1
  },
  exercisesUpdate2 (state) {
    return state.exercisesUpdate2
  },


  exercisesDelete (state) {
    return state.exercisesDelete
  },
  exercisesDelete1 (state) {
    return state.exercisesDelete1
  },
  exercisesDelete2 (state) {
    return state.exercisesDelete2
  }
}
