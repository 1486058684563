export default {
  getFormData (formData, data, previousKey) {
    if (data instanceof Object) {
      Object.keys(data).forEach(key => {
        const value = data[key]
        if (value instanceof Object && !Array.isArray(value) && (key !== 'image')) {
          return this.getFormData(formData, value, key)
        }
        if (previousKey) {
          key = `${previousKey}[${key}]`
        }
        if (Array.isArray(value)) {
          value.forEach((val, index) => {
            if (val instanceof Object && !Array.isArray(val)) {
              return this.getFormData(formData, val, `${key}[${index}]`)
            }
            formData.append(`${key}[${index}]`, val)
          })
        } else {
          formData.append(key, value)
        }
      })
    }
  },
  kebabCaseToCamelCase (string) {
    return string.replace(/_./g, x => x.toUpperCase()[1])
  },
  debugSleepFor (sleepDuration) {
    const now = new Date().getTime()
    while (new Date().getTime() < now + sleepDuration) { /* do nothing */ }
  },
  today (sp) {
    const today = new Date()
    let dd = today.getDate()
    let mm = today.getMonth() + 1
    const yyyy = today.getFullYear()

    if (dd < 10) dd = `0${  dd}`
    if (mm < 10) mm = `0${  mm}`
    return (yyyy + sp + mm + sp + dd)
  },
  searchInArray (key, value, array, returnIndex = false) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return returnIndex ? i : array[i]
      }
    }
  },
  resizeCanvasImage (oldCanvas, width = 0, height = 0) {
    const resizedCanvas = document.createElement('canvas')
    const resizedContext = resizedCanvas.getContext('2d')
    if (width && height) {
      resizedCanvas.width = width
      resizedCanvas.height = height
    } else if (width) {
      const scale = width / oldCanvas.width
      resizedCanvas.width = oldCanvas.width * scale
      resizedCanvas.height = oldCanvas.height * scale
    } else if (height) {
      const scale = height / oldCanvas.height
      resizedCanvas.width = oldCanvas.width * scale
      resizedCanvas.height = oldCanvas.height * scale
    } else {
      resizedCanvas.width = oldCanvas.width
      resizedCanvas.height = oldCanvas.height
    }
    resizedContext.drawImage(oldCanvas, 0, 0, resizedCanvas.width, resizedCanvas.height)
    return resizedCanvas
  },
  isJsonString (str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }

    return true
  },
  slugify (str) {
    str = str.trim().toLowerCase()
    const charMapping = {
      '-' : ' ',
      'а' : 'a', // Cyrillic to English
      'б' : 'b',
      'в' : 'v',
      'г' : 'g',
      'д' : 'd',
      'е' : 'e',
      'ё' : 'yo',
      'ж' : 'zh',
      'з' : 'z',
      'и' : 'i',
      'й' : 'y',
      'к' : 'k',
      'л' : 'l',
      'м' : 'm',
      'н' : 'n',
      'о' : 'o',
      'п' : 'p',
      'р' : 'r',
      'с' : 's',
      'т' : 't',
      'у' : 'u',
      'ф' : 'f',
      'х' : 'kh',
      'ц' : 'ts',
      'ч' : 'ch',
      'ш' : 'sh',
      'щ' : 'shch',
      'ъ' : '',
      'ы' : 'y',
      'ь' : '',
      'э' : 'e',
      'ю' : 'yu',
      'я' : 'ya',
      ' ' : '-' // hyphenate spaces
    }
    // replace characters
    str = str.split('').map(char => charMapping[char] || char).join('')

    // remove special characters
    str = str.replace(/[^\w\d-]/g, '')

    // remove extra hyphens
    str = str.replace(/--+/g, '-')

    // remove leading/trailing hyphens
    str = str.replace(/^-|-$/g, '')

    return str
  },
  createOrEditPage ({
    vueInstance,
    patch,
    payload,
    type = null,
    stayAfter = false,
    routerPush = {name:'home'},
    routerPushToEdit = {name:'home'}

  }) {
    const typeRequest = type || vueInstance.type
    return new Promise((resolve, reject) => {
      vueInstance.$store.dispatch(patch, payload).then((res) => {
        vueInstance.$vs.notify({
          title: vueInstance.$t('notify.success.title'),
          text:  vueInstance.$t('notify.success.success_save'),
          color: 'success',
          time: 3000
        })
        const createId = res.data.data ? res.data.data.id : null
        if (!stayAfter) {
          vueInstance.$router.push(routerPush)
        }
        if (typeRequest === 'create' && stayAfter && createId) {
          const { name, path, params } = routerPushToEdit
          if (name) {
            vueInstance.$router.push({ name: name, params: { id: createId, ...params } })
          } else if (path) {
            vueInstance.$router.push({ path: `${path}/${createId}`, params: { id: createId, ...params } })
          }
        }
        resolve(res)
      })
        .catch((error) => {
          const errorData = error.response.data
          console.log('errorData.errors.id', errorData.errors.id)
          if (errorData.errors.id && errorData.errors.id.find(e => e === '823')) {
            vueInstance.$vs.notify({
              title: vueInstance.$t('notify.errors.title'),
              text:  vueInstance.$t('form.errors.mailing_sending_exist'),
              color: 'danger',
              time: 5000
            })
          } else {
            vueInstance.$vs.notify({
              title: vueInstance.$t('notify.errors.title'),
              text:  vueInstance.$t(`notify.errors["${errorData.message}"]`),
              color: 'danger',
              time: 5000
            })
          }
          reject(error)
        })
    })
    
  }
}

