<template>
  <div class="">
      <vs-navbar 
        type="flat" 
        v-model="activeItem" 
        class="category-tabs-wrapper" 
        :class="{
          'category-tabs-wrapper--codes': activeItem === 'shop-product-codes',
          }"
        >

        <vs-navbar-item index="edit" v-if="existCodes && activeItem !== 'edit'">
          <a><span class="material-icons">chevron_left</span>{{ $t('shop_products.edit.tabs.product_settings') }}</a>
        </vs-navbar-item>

        <vs-navbar-item v-if="existCodes && activeItem !== 'shop-product-codes'" index="shop-product-codes">
          <a>{{ $t('shop_products.edit.tabs.codes') }}<span class="material-icons">chevron_right</span></a>
        </vs-navbar-item>

      </vs-navbar>

      <create-edit-shop-product v-show="activeItem === 'edit'"></create-edit-shop-product>
      <shop-product-codes
        v-show="activeItem === 'shop-product-codes'"
        :is-active="activeItem === 'shop-product-codes'">
      </shop-product-codes>

    </div>
</template>

<script>
import CreateEditShopProduct from './CreateEditShopProduct'
import ShopProductCodes from './ShopProductCodes'

export default {
  components: {
    ShopProductCodes,
    CreateEditShopProduct
  },
  data () {
    return {
      activeItem: 'edit'
    }
  },
  computed: {
    existCodes () {
      return this.$store.getters['shop_product/edit'].isDigital
    }
  }
}
</script>

<style lang="scss" scoped>
  .category-tabs-wrapper {
    top: 75px;
    height: 0px;

    a {
      display: flex;
      align-items: center;
      padding-right: 0px;
      color: #DA7E3C;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        color: #DA7E3C !important;
      }
    }
    &--codes {
      margin-bottom: 0px;
      top: 38px;
      height: auto;
      width: 50%;
      justify-content: flex-start;
      a {
        padding-right: 10px;
        padding-left: 0px;
      }
    }
  }
</style>

