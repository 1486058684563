import helpers from '@/helpers/helpers.js'

export default {
  retrieveSuite (state, suite) {
    state.suite = suite
  },
  setCodes (state, codes) {
    state.codes = codes
  },
  retrieveVirtualCardTypes (state, virtualCardTypes) {
    state.virtualCardTypes = virtualCardTypes
  },
  retrieveEdit (state, data) {
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) continue
      state.edit[helpers.kebabCaseToCamelCase(prop)] = (data[prop] === null) ? '' : data[prop]
    }
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  },
  removeShopProductById (state, id) {
    const index = helpers.searchInArray('id', id, state.suite, true)
    state.suite.splice(index, 1)
  },
  addPropertyDeletedAtForCodeById (state, id) {
    const index = helpers.searchInArray('id', id, state.codes, true)
    state.codes[index].deleted_at = Date.now()
  },
  restoreCodeById (state, id) {
    const index = helpers.searchInArray('id', id, state.codes, true)
    state.codes[index].deleted_at = null
    // state.codes.splice(index, 1)
  }
}
