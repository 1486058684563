<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
    <feather-icon v-if="!isOnlyRead" icon="CopyIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="copyTraining()" />
    <feather-icon v-if="!isOnlyRead" icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="confirmDeleteRecord()" />
  </div>
</template>

<script>

export default {
  name: 'CellRendererActions',
  methods: {
    editRecord () {
      this.$store.commit('setListPage', this.params.api.paginationGetCurrentPage())
      this.$router.push({ path: `${this.$route.path}/edit-training/${this.params.value}`, params: { id: this.params.value, categoryId: this.categoryId }})
    },
    confirmDeleteRecord () {
      this.params.context.componentParent.deletePopupContent = this.$t('trainings.suite.popups.delete.message').replace('[NAME]', this.params.data.name)
      this.params.context.componentParent.deleteTrainingId = this.params.value
      this.params.context.componentParent.deleteTrainingIndex = this.params.rowIndex
      this.params.context.componentParent.deletePopupActive = true
    },
    copyTraining (id, index) {
      this.params.context.componentParent.showCopyPopupWaitMessage = true
      this.$store.dispatch('training/copy', { id: this.params.value, index: this.params.rowIndex }).catch(error => {
        this.$vs.notify({
          title: 'Server error',
          text: error,
          color: 'danger'
        })
      }).then(() => {
        // this.suite = JSON.parse(JSON.stringify(this.$store.getters['training/suite']))
        this.params.context.componentParent.showCopyPopupWaitMessage = false
      })
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-events'] === 'read'
      }
    }
  }
}
</script>
