import FormError from '../../modules/formError'

export default {
  suite: [],
  list: [],
  edit: {
    name: '',
    announcement: '',
    description: '',
    blockDescription: null,
    date: '',
    imageUrl: '',
    cancelGender: '',
    videoLink: '',
    photoRequired: false,
    photoRequiredDescription: '',
    commentRequired: false,
    commentRequiredDescription: '',
    hasResults: 0,
    point: null
  },
  exercises: [],
  exercises1: [],
  exercises2: [],

  exercisesCreate: [],
  exercisesCreate1: [],
  exercisesCreate2: [],

  exercisesUpdate: [],
  exercisesUpdate1: [],
  exercisesUpdate2: [],

  exercisesDelete: [],
  exercisesDelete1: [],
  exercisesDelete2: [],
  formError: new FormError()
}
