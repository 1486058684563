
export default {
  retrieveSuite (state, suite) {
    state.suite = suite
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  }
}
