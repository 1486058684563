import axios from '@/axios.js'

export default {

  getAllFilters ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/content-filters')
        .then((response) => {
          commit('retrieveAllFilters', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getFiltersById ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/content-filters/by-target', {params: payload})
        .then((response) => {
          commit('retrieveFiltersById', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('api/admin-panel/content-filters', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  }
}
