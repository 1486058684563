// NOTE
// Please use your own firebase details below. For more details visit: https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/development/firebaseIntegration.html


import firebase from 'firebase/app'

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyAzSnYhPLV5WFMrU62owDRCbQylOwyFww8',
  authDomain: 'project--3984167549830791713.firebaseapp.com',
  databaseURL: 'https://project--3984167549830791713.firebaseio.com',
  projectId: 'project--3984167549830791713',
  storageBucket: 'project--3984167549830791713.appspot.com',
  messagingSenderId: '527710225421'
}

firebase.initializeApp(config)
