import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'

export default {
  getRoll ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/gamification/raffle',  { params: payload })
        .then((response) => {
          commit('retrieveRoll', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
