import state from './moduleContentFilterState.js'
import mutations from './moduleContentFilterMutations.js'
import actions from './moduleContentFilterActions.js'
import getters from './moduleContentFilterGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
