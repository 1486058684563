import FormError from '../../../modules/formError'

export default {
  suite: [],
  formError: new FormError(),
  edit: {
    id: null,
    start: null,
    end: null,
    isTeam: null,
    challengeTypeAdmin: null,
    countSteps: null
  },
  list: []
}
