// en, ru
export default {
  en: {
    menu: {
      home: 'Dashboard',
      programms: 'Programs',
      challenges: 'Challenges',
      users: 'Users',
      users_list: 'Users list',
      segments: 'Segments',
      content_pages: 'Content pages',
      edit_club: 'Club',
      pedometer_challenges: 'Old Common goals',
      common_goals: 'Common goals',
      blog_posts: 'Blog',
      clubs: 'Clubs',
      registration_codes: 'Registration codes',
      faq: 'FAQ',
      notifications: 'Push-mailing',
      communication: 'Communication',
      mailings: 'Mailings',
      all_notifications: 'Notifications',
      announcements: 'Announcements',
      shop_orders: 'Orders',
      shop_products: 'Products',
      shop_categories: 'Categories',
      shop_settings: 'Settings',
      shop: 'Shop',
      events: 'Events',
      calendar_of_events: 'Calendar of events',
      activities: 'Activities',
      achievements: 'Awards (achievement)',
      raffle: 'Raffle',
      teams: 'Teams',
      points_settings: 'Points settings',
      all_settings: 'Settings',
      message: 'Message',
      personal_goals: 'Personal-goals',
      habits_trackers: 'Habits tracker',
      polling: 'Polling',
      pollings: 'Polling`s',
      relevant: 'Relevant',
      reports: 'Reports',
      polling_health: 'Well-Being Index',
      services: 'Services',
      services_catalog: 'Services catalog',
      geocaching: 'Geocaching',
      content: 'Content',
      deactivating_users: 'Deactivating users',
      cafeteria: 'VMI policy',
      cafeteria_catalog: 'Catalog',
      cafeteria_orders: 'Orders'
    },
    navbar: {
      alerts: {
        login_by_id: 'You are logged in club',
        logout_button: 'Logout'
      }
    },
    achievements: {
      suite: {
        title: 'Awards (achievement)',
        table: {
          id: 'ID',
          name: 'Title',
          type: 'Type',
          edit: 'Edit',
          copy: 'Copy',
          delete: 'Delete'
        },
        popups: {
          delete: {
            title: 'Delete Gamification',
            message: 'Are you sure you want to delete [NAME]?',
            wait_message: 'Removal...',
            buttons: {
              confirm: 'Delete',
              cancel: 'Cancel'
            }
          }
        }
      },
      create: {
        title: 'Add a reward',
        labels: {
          name: 'Title',
          type: 'Type',
          is_active: 'Active',
          description: 'Description',
          challenge: 'Challenge',
          programm: 'Program',
          training: 'Training',
          training_challenge: 'Task',
          pedometr: 'Task',
          event: 'Event'
        },
        buttons: {
          create: 'Add a reward'
        }
      },
      edit: {
        title: 'Edit the reward',
        unable_edit_notice: 'You cannot change the type and parameters of the reward, because some users have already received it. Create a new reward',
        labels: {
          name: 'Title',
          type: 'Type',
          is_active: 'Active',
          description: 'Description',
          challenge: 'Challenge',
          programm: 'Program',
          training: 'Training',
          training_challenge: 'Task',
          pedometr: 'Task',
          event: 'Event'
        },
        buttons: {
          edit: 'Change the reward'
        }
      },
      type: {
        event: 'Completed Event',
        challenge: 'Completed challenge',
        programm: 'Completed program',
        training: 'The task in the program',
        training_in_challenge: 'Completed task in the challenge',
        pedometr: 'Completed pedometer task',
        mission: 'Completed personal goals'
      }
    },
    gamification: {
      raffle: {
        title: 'Raffle',
        labels: {
          seed: 'Random number',
          reroll: 'Reroll',
          start: 'Start',
          end: 'End',
          moreResultsMoreChances: 'More results - more chances'
        },
        table: {
          user_id: 'User ID',
          id: 'Result ID',
          name: 'Name',
          email: 'Email',
          type: 'For what',
          source_type: 'Upload type',
          date: 'Created',
          role: 'Role',
          avatar_url: 'Avatar_url',
          district: 'District',
          region: 'Region',
          city: 'City',
          department: 'Department',
          optional: 'Optional',
          gender: 'Gender',
          dob: 'Dob',
          phone: 'Phone',
          registration: 'Registration'
        }
      }
    },
    categories: {
      suite: {
        programm: {
          title: 'Programs',
          create_button: 'Add programm',
          upload_button: 'Upload programm'
        },
        event: {
          title: 'Events',
          create_button: 'Add event',
          upload_button: 'Upload event'
        },
        challenge: {
          title: 'Challenges',
          create_button: 'Add challenge',
          upload_button: 'Upload challenge'
        },
        polling: {
          title: 'Polling',
          create_button: 'Add polling',
          upload_button: 'Upload polling'
        },
        polling_health: {
          title: 'Polling health',
          create_button: 'Add polling health',
          upload_button: 'Upload polling health',
          announcement: 'Polling description',
          recommendations: {
            title: 'Recommendations',
            risks: 'There are risks',
            risks__level_4: 'There are risks (1-9）',
            risks__level_3: 'There are risks (10-19)',
            risks__level_2: 'There are risks (20-29)',
            risks__level_1: 'There are risks (30-39)',
            attention: 'Pay attention (40-49)',
            can_be_better: 'Сan be better (50-59)',
            almost_good: 'Almost good (60-69)',
            all_right: 'All right (70-79)',
            all_great: 'All great (80-89)',
            perfectly: 'Perfectly good (90-100)',
            warning_block: 'Warning',
            attention_block: 'Attention',
            good_block: 'Good'
          },
          repeated_passage: 'Repeated passage',
          on_specific_date: 'On a certain date',
          certain_number_of_days: 'Certain number of days'
        },
        buttons: {
          edit: 'Edit',
          download_members_file: 'Download members',
          questions: 'Questions',
          tasks: 'Tasks',
          sort: 'Sort'
        },
        popups: {
          delete: {
            title: 'Delete category',
            message: 'Are you sure you want to delete [NAME]',
            wait_message: 'Deleting...',
            buttons: {
              confirm: 'Delete',
              cancel: 'Cancel'
            }
          },
          not_valid: {
            title: 'You need to add tasks!',
            message: 'Click on the "Add Task" button and fill out the necessary fields'
          }
        },
        statistics: {
          results: 'Reports',
          participants: 'Participants'
        }
      },
      template: {
        title: 'Select the type of program',
        normal_course: {
          name: 'Normal course',
          description: 'All tasks are available immediately, execution at any time, in any order. '
        },
        sequential_course: {
          name: 'Consistent course',
          description: 'Tasks must be performed sequentially (the next lesson opens after passing the previous one)'
        },
        challenge_by_dates: {
          name: 'Challenge by dates',
          description: 'Tasks open on the specified date.'
        },
        challenge_for_departure: {
          name: 'Challenge to fly',
          description: 'Tasks are opened once a day from the date of joining Challenge. If the user misses the report day, he flies out of the challenger.'
        },
        self_challenge: {
          name: 'Personal challenge',
          description: 'Tasks are opened daily.',
          create_title: 'Create a personal challenge',
          edit_title: 'Edit a personal challenge'
        },
        buttons: {
          choose: 'Choose',
          to_change: 'Change the type'
        }
      },
      edit: {
        programm: {
          title: 'Edit program',
          error_parse: {
            content: 'Program description is corrupted, please refill',
            button_confirm: 'Ok'
          }
        },
        challenge: {
          title: 'Edit challenge',
          error_parse: {
            content: 'Challenge description is corrupted, please refill',
            button_confirm: 'Ok'
          }
        },
        self_challenge: {
          title: 'Edit program',
          error_parse: {
            content: 'Program description is corrupted, please refill',
            button_confirm: 'Ok'
          }
        },
        polling: {
          title: 'Edit polling',
          error_parse: {
            content: 'Polling description is corrupted, please refill',
            button_confirm: 'Ok'
          }
        },
        event: {
          error_parse: {
            content: 'Event description is corrupted, please refill',
            button_confirm: 'Ok'
          }
        },
        polling_health: {
          title: 'Edit polling health',
          error_parse: {
            content: 'Polling description is corrupted, please refill',
            button_confirm: 'Ok'
          }
        },
        tabs: {
          edit: 'Edit',
          members: 'Members',
          statistics: 'Statistics'
        },
        labels: {
          programm: {
            point: 'Malles for the program',
            entrance_point: 'Points for joining the program',
            coin: 'Coins for the program',
            entrance_coin: 'Coins for joining the program',
            announcement: 'Announcement',
            block_description: 'Description'
          },
          challenge: {
            point: 'Scores for performing a challenge',
            entrance_point: 'Points for joining Challenge',
            coin: 'Coins for Challenge',
            entrance_coin: 'Coins for joining Challenge',
            announcement: 'Announcement',
            block_description: 'Description'
          },
          self_challenge: {
            point: 'Scores for performing a challenge',
            entrance_point: 'Points for joining Challenge',
            coin: 'Coins for Challenge',
            entrance_coin: 'Coins for joining Challenge',
            announcement: 'Announcement',
            block_description: 'Description'
          },
          polling: {
            point: 'Survey points',
            entrance_point: 'Points for entry',
            coin: 'Survey coins',
            entrance_coin: 'Coins for entry',
            announcement: 'Polling description',
            block_description: 'Recommendations'
          },
          polling_health: {
            point: 'Survey points',
            entrance_point: 'Points for entry',
            coin: 'Survey coins',
            entrance_coin: 'Coins for entry',
            announcement: 'Polling description',
            block_description: 'Recommendations'
          },
          name: 'Name',
          announcement: 'Announcement',
          availability: 'Availability',
          clubs: 'Club',
          description: 'Description',
          block_description: 'Block description',
          accessibility_settings: 'Accessibility settings',
          image: 'Drag a image',
          type: 'Сhallenge',
          period: 'Start date and end date',
          is_active: 'Active',
          is_consistent: 'Open the next lesson only after completing the previous one',
          is_without_missed: 'Challenge without a missed',
          missed_count: 'Missed count',
          is_show_chat: 'Show in app',
          tags: 'Tags',
          activities: 'Activities',
          point: 'Point',
          show_by_dates: 'Show by dates',
          dont_add_points_if_missed: 'Do not add points if user missed the day of the report',
          datetime_registration_to: 'Registration before',
          entrance_point: 'Points for joining the category',
          category_type: 'Category type',
          date_from: 'Start date',
          date_to: 'End date',
          publication_at: 'Date of publication*'
        },
        buttons: {
          delete: 'Delete',
          update: 'Update',
          create: 'Create'
        },
        members: {
          table: {
            avatar: 'Avatar',
            name: 'Name',
            city: 'City',
            department: 'Department',
            training: {
              result: {
                count: 'Reports count'
              }
            }
          }
        },
        statistics: {
          titles: {
            count_users: 'Count users',
            count_added_results: 'Count added reports',
            count_comments: 'Count comments',
            download_statistics: 'Upload statistics to Excel'
          }
        }
      },
      create: {
        programm: {
          title: 'Create program'
        },
        challenge: {
          title: 'Create challenge'
        },
        polling: {
          title: 'Create polling'
        },
        polling_health: {
          title: 'Create polling health'
        },
        labels: {
          programm: {
            point: 'Malles for the program',
            entrance_point: 'Points for joining the program',
            coin: 'Coins for the program',
            entrance_coin: 'Coins for joining the program'
          },
          challenge: {
            point: 'Scores for performing a challenge',
            entrance_point: 'Points for joining Challenge',
            coin: 'Coins for Challenge',
            entrance_coin: 'Coins for joining Challenge'
          },
          self_challenge: {
            point: 'Scores for performing a challenge',
            entrance_point: 'Points for joining Challenge',
            coin: 'Coins for Challenge',
            entrance_coin: 'Coins for joining Challenge'
          },
          polling: {
            point: 'Survey points',
            entrance_point: 'Points for entry',
            coin: 'Survey coins',
            entrance_coin: 'Coins for entry'
          },
          polling_health: {
            point: 'Survey points',
            entrance_point: 'Points for entry',
            coin: 'Survey coins',
            entrance_coin: 'Coins for entry'
          },
          name: 'Name',
          name_required: 'Title',
          announcement: 'Announcement',
          availability: 'Availability',
          description: 'Description',
          block_description: 'Block description',
          event_description: 'Event description',
          location: 'Location',
          accessibility_settings: 'Accessibility settings',
          image: 'Drag a image',
          type: 'Сhallenge',
          period: 'Start date and end date',
          is_active: 'Active',
          is_consistent: 'Open the next lesson only after completing the previous one',
          is_without_missed: 'Challenge without a missed',
          missed_count: 'Missed count',
          is_show_chat: 'Show in app',
          tags: 'Tags',
          activities: 'Activities',
          event_is_hide_time_and_prohibit_adding_in_calendar: 'Do not show the time and prohibit adding to the calendar in the application',
          publication_at: 'Date of publication*',
          datetime_registration_to: 'Registration before',
          date_from: 'Start date',
          date_to: 'End date',
          show_in_module_event: 'Output Events in the module',
          is_show_participants: 'Display participants in the app',
          is_repeated_passage: 'Repeated passage',
          date_to_event_required: 'Event end date*',
          show_by_dates: 'Show by dates',
          dont_add_points_if_missed: 'Do not add points if user missed the day of the report'
        },
        buttons: {
          create: 'Create',
          save: 'Save'
        }
      }
    },
    trainings: {
      suite: {
        title: 'Trainings',
        labels: {
          programm: {
            training_description: 'Description of training',
            training_results: 'Training results',
            training_image: 'Image of training'
          },
          challenge: {
            training_description: 'Description of training',
            training_results: 'Training results',
            training_image: 'Image of training'
          },
          polling: {
            training_description: 'Description of the question',
            training_results: 'Results',
            training_image: 'Image'
          },
          polling_health: {
            training_description: 'Description of the question',
            training_results: 'Results',
            training_image: 'Image'
          },
          training_description: 'Training Description',
          training_results: 'Training Results',
          training_image: 'Training Image'
        },
        table: {
          name: 'Name',
          date: 'Date',
          edit: 'Edit',
          delete: 'Delete'
        },
        popups: {
          delete: {
            title: 'Delete training',
            message: 'Are you sure you want to delete [NAME]',
            wait_message: 'Deleting...',
            buttons: {
              confirm: 'Delete',
              cancel: 'Cancel'
            }
          }
        },
        buttons: {
          sort: 'Sort'
        }
      },
      edit: {
        title: 'Edit training',
        labels: {
          name: 'Name',
          announcement: 'Announcement',
          description: 'Description',
          block_description: 'Block description',
          image: 'Drag a image',
          remove_image: 'Remove',
          gender: 'Cancel gender',
          point: 'Points for completing the task (if not filled, then [POINT])',
          coin: 'Koins for completing the task',
          required_viewing_time: 'Required viewing time',
          not_included_to_statistics: 'Not included to statistics',
          photo_required: 'Photo required',
          photo_required_description: 'Photo required description',
          comment_required: 'Comment required',
          comment_required_description: 'Comment required description',
          is_required: 'Required',
          date: 'Date'
        },
        error_parse: {
          content: 'Training description is corrupted, please refill',
          button_confirm: 'Ok'
        },
        buttons: {
          programm: {
            add_task: 'Add a task'
          },
          challenge: {
            add_task: 'Add a task'
          },
          polling: {
            add_task: 'Add a question'
          },
          polling_health: {
            add_task: 'Add a task (question)'
          },
          add_exercise: 'Add exercise',
          add_task: 'Add task',
          update: 'Update'
        },
        popup: {
          title: 'Warning',
          message: 'Nothing will come of it'
        }
      },
      create: {
        title: 'Create training',
        labels: {
          name: 'Name',
          announcement: 'Announcement',
          description: 'Description',
          block_description: 'Block description',
          image: 'Drag a image',
          gender: 'Cancel gender',
          point: 'Points for completing the task (if not filled, then [POINT])',
          coin: 'Koins for completing the task',
          point_event: 'Event',
          required_viewing_time: 'Required viewing time',
          not_included_to_statistics: 'Not included to statistics',
          photo_required: 'Photo required',
          photo_required_description: 'Photo required description',
          comment_required: 'Comment required',
          comment_required_description: 'Comment required description',
          is_required: 'Required',
          hide_results: 'Hide the result from other participants',
          any_answer_is_correct: 'Any answer will be correct',
          all_answers_must_be_selected: 'All answers must be selected',
          date: 'Date'
        },
        buttons: {
          programm: {
            add_task: 'Add a task'
          },
          challenge: {
            add_task: 'Add a task'
          },
          polling: {
            add_task: 'Add a question'
          },
          polling_health: {
            add_task: 'Add a task (question)'
          },
          add_exercise: 'Add exercise',
          add_task: 'Add task',
          create: 'Create'
        }
      },
      exercises: {
        labels: {
          repetitions: 'Quantity (repetitions, kg, meters)',
          time_less: 'Time: The faster the better',
          time_more: 'Time: The longer the better',
          hide_results: 'Hide the result',
          comment: 'Comment',
          photo: 'Photo',
          enter_correct_answer: 'Specify the correct answer',
          radio_answers: 'Test (one answer)',
          flags_answers: 'Test (several answers)',
          radio_answers_poll: 'Poll (one answer)',
          flags_answers_poll: 'Poll (several answers)',
          answer_by_scale: 'Rating on the scale'
        }
      },
      buttons: {
        sort: 'Sort'
      }
    },
    exercises: {
      suite: {
        table: {
          name: 'Name',
          result_type: 'Result type',
          is_required: 'Required',
          name_task_event: 'Title',
          actions: 'Actions',
          comment: 'Comment',
          photo: 'Photo'
        }
      },
      create_edit: {
        titles: {
          create: 'Report builder',
          edit: 'Report builder',
          create_task: 'Report builder',
          edit_task: 'Report builder'
        },
        labels: {
          name: 'Name',
          enter_a_name: 'Enter the title',
          current_answer: 'Enter the right answer',
          enter_a_current_answer: 'Enter the correct answer',
          enter_a_answer: 'Введите ответ',
          point: 'Points for completing the task',
          result_type: 'Result type',
          task_type: 'Type of task',
          answer: 'Answer',
          answers: 'Answers',
          error_valid: 'specify the answer option, or add an image',
          error_valid_2:'Indicate the answer option',
          min_value: ' Minimum value',
          max_value: 'Maximum value',
          icon: 'Icon',
          add: 'Add',
          delete: 'Delete'
        },
        buttons: {
          submit: 'Submit',
          cancel: 'Cancel',
          add_photo: 'Add image',
          create: 'Create',
          update: 'Update'
        },
        description: {
          specify_the_correct_answer: 'If there are several correct answers, enter them separated by a semicolon ";". Spaces before and after the semicolon are removed. Case-insensitive.',
          example_correct_answer: 'For example, you entered: "Answer 1; Answer 2; AnSwEr 3". The correct answers are: "Answer 1", "Answer 2", "AnSwEr 3" and like.'
        }
      }
    },
    users: {
      suite: {
        title: 'Users',
        table: {
          id: 'ID',
          avatar: 'Avatar',
          email: 'Email',
          phone: 'Phone',
          name: 'Name',
          role: 'Role',
          city: 'City',
          department: 'Department',
          gender: 'Gender',
          dob: 'Date of b.',
          trainings_results_count: 'Results count',
          favorite_categories_count: 'Category count',
          all_point: 'Points',
          level: 'Level',
          actions: 'Actions'
        },
        buttons: {
          upload_file: 'Upload file',
          download_date_since: 'Date since',
          download_date_until: 'Date until',
          download_file_training_results: 'Unload training results',
          download_file_levels: 'Unload levels',
          download_file_orders: 'Unload orders',
          add_admin: 'Add the admin'
        },
        notifications: {
          succsess_created_title: 'Success',
          succsess_created_message: 'Successfuly created [COUNT] users.',
          succsess_deleted_title: 'Success',
          succsess_deleted_message: 'Successfuly deleted [COUNT] users.'
        },
        popups: {
          confirm_delete: {
            message: 'Are you sure you want to delete [COUNT] users?',
            buttons: {
              confirm: 'Confirm',
              cancel: 'Cancel'
            }
          },
          confirm_delete_user: {
            message: 'Are you sure you want to delete [EMAIL]?',
            buttons: {
              confirm: 'Delete',
              cancel: 'Cancel'
            }
          },
          confirm_deactivate_user: {
            message: 'Are you sure you want to deactivate [EMAIL]?',
            buttons: {
              confirm: 'Deactivate',
              cancel: 'Cancel'
            }
          }
        },
        errors: {
          'emails': 'The document could not be processed. Check the line ',
          'title': 'Server error'
        }
      },
      create: {
        title: 'Registration Administrator',
        labels: {
          email: 'Email',
          password: 'Password',
          password_confirmation: 'Password confirmation',
          name: 'Name'
        },
        errors: {
          valid_password: 'Enter from 5 to 15 characters',
          valid_password_confirmation: 'Password mismatch'
        },
        buttons: {
          create: 'Create Admin'
        }

      },
      edit: {
        title: 'Edit user',
        labels: {
          role: 'Role',
          name: 'Name',
          email: 'Email',
          city: 'City',
          department: 'Department',
          team: 'Team',
          phone: 'Phone',
          social_networks: 'Socials',
          gender: 'Gender',
          dob: 'Birthday'
        },
        genders: {
          male: 'Man',
          female: 'Woman'
        },
        points: {
          table: {
            point: 'Point',
            coin: 'Coin',
            type: 'Type',
            target_title: 'Target',
            notice: 'Notice',
            created_at: 'Created date',
            deleted_at: 'Delete date'
          },
          labels: {
            user: 'User',
            point: 'Points',
            shop_point: 'Point in the store',
            coin: 'Coins',
            notice: 'Notice',
            created_at: 'Date'
          },
          edit: {
            buttons: {
              edit: 'Save'
            }
          },
          create: {
            buttons: {
              create: 'Add'
            }
          },
          popups: {
            confirm_delete: {
              title: 'Delete point?',
              message: 'Are you sure you want to delete [POINT] points?',
              buttons: {
                confirm: 'Подтвердить',
                cancel: 'Отмена'
              }
            },
            confirm_activate: {
              title: 'Add point?',
              message: 'Are you sure you want to add [POINT] points?',
              buttons: {
                confirm: 'Confirm',
                cancel: 'Cancel'
              }
            }
          }
        },
        steps: {
          table: {
            id: 'Steps',
            count_steps: 'Type',
            target_title: 'Target',
            created_at: 'Created date',
            deleted_at: 'Delete date'
          },
          labels: {
            steps: 'Steps',
            notice: 'Notice',
            created_at: 'Date'
          },
          edit: {
            buttons: {
              edit: 'Save'
            }
          },
          create: {
            buttons: {
              create: 'Add'
            }
          },
          popups: {
            confirm_delete: {
              title: 'Delete step?',
              message: 'Are you sure you want to delete [STEP] steps?',
              buttons: {
                confirm: 'Confirm',
                cancel: 'Cancel'
              }
            },
            confirm_activate: {
              title: 'Add step?',
              message: 'Are you sure you want to add [STEP] steps?',
              buttons: {
                confirm: 'Confirm',
                cancel: 'Cancel'
              }
            }
          }
        },
        trainings: {
          table: {
            id: 'ID',
            title: 'Title',
            results_text: 'result',
            points: 'Points',
            like_count: '❤️',
            dislike_count: '❗️',
            created_at: 'Created date',
            updated_at: 'Updated date',
            deleted_at: 'Delete date'
          },
          popups: {
            training: {
              title: 'Result'
            },
            value: {
              buttons: {
                cancel: 'Close'
              }
            }
          }
        },
        tabs: {
          edit: 'User',
          create: 'Add',
          management: 'Management',
          points: 'Points',
          coins: 'Coins',
          steps: 'Steps',
          trainings: 'Results'
        },
        buttons: {
          save: 'Save changes',
          reset_password: 'Get reset url',
          link_to_password_recovery: 'Password recovery link',
          delete: 'Delete user',
          download_file_points: 'Download table excel',
          deactivate_the_user: 'Deactivate the user'
        },
        access_settings: {
          title: 'Access settings',
          admin: {
            none: 'No access',
            write: 'Editing is allowed',
            read: 'Only viewing'
          },
          athlete: {
            none: 'No access',
            write: 'Is access',
            read: 'Only viewing'
          },
          massage_access_restriction: 'You cannot change your account access parameters, contact the administrator.',
          massage_just_watch: 'Editing is not available',
          modules: {
            'admin-dashboard': 'Dashboard',
            'admin-users': 'Users',
            'admin-clubs-with-program': 'Clubs/Programs',
            'admin-mailings': 'Notifications',
            'admin-announcements': 'Announcements',
            'admin-events': 'Events',
            'admin-settings': 'Settings',
            'admin-settings-segments': 'Settings. Segments',
            'admin-settings-activities': 'Settings. Types of activity',
            'admin-settings-teams': 'Settings. Teams',
            'admin-settings-points-settings': 'Settings. Points',
            'admin-settings-achievements': 'Settings. Awards (Achivka)',
            'admin-settings-registration-codes': 'Settings. Registration codes',
            'admin-settings-content-pages': 'Settings. Content pages',
            'admin-content-filters': 'Content filters',
            'admin-raffle': 'Raffle',
            'admin-chat-messages': 'Messages',
            'admin-missions': 'Personal goals',
            'admin-relevant': 'Relevant',
            'admin-pollings': 'Polling',
            'admin-blog': 'Blog',
            'admin-pedometer': 'Pedometer',
            'admin-shop': 'Store',
            'admin-create-admin': 'Create admin',
            'admin-cafeteria': 'cafeteria benefits',
            'comments': 'Chats, comments on reports, blog comments',
            'events': 'participation in events',
            'news-feeds':'Activity Tape (can see other people\'s reports)',
            'Families': 'Adding a family',
            'pedometer': 'General goals',
            'points': 'Earnings of points',
            'shop': 'store',
            'training-activities':' Visibility of user reports (training and activity)'
          }
        }
      }
    },
    segments: {
      suite: {
        title: 'Segments',
        title2: 'Select the segment',
        errors: {
          title: 'Error trying delete segment'
        },
        popups: {
          confirm_delete: {
            message: 'Are you sure to delete segment «[NAME]»',
            buttons: {
              confirm: 'Delete',
              cancel: 'Cancel'
            }
          }
        }
      },
      table: {
        id: 'id',
        name: 'Name',
        slug: 'Slug',
        created_at: 'Created date',
        actions: 'Actions'
      },
      create: {
        title: 'Create segment',
        title_edit: 'Edit segment',
        fields: {
          name: 'Name',
          slug: 'Slug',
          users: 'Users',
          departments: 'Departments',
          teams: 'Teams',
          city: 'Cities',
          gender: 'Genders',
          created_at_greater_now_minus_value: 'Register date fresh than [Х seconds ago]',
          created_at_lower_now_minus_value: 'Register date older than [Х seconds ago]',
          created_at_greater_datetime: 'Registration date after the selected date',
          created_at_lower_datetime: 'Registration date before the selected date',
          created_at_greater_datetime_button: 'Registration date after',
          created_at_lower_datetime_button: 'Registration date before'
        }
      }
    },
    registration_codes: {
      suite: {
        title: 'Registration codes',
        description: 'Excel file in xlsx, xls format.',
        description_codes: 'When uploading codes: First column - registration codes (required), second - emails (optional). No headers.',
        description_emails: 'When uploading mails: in the first cell of the file, the header: allowed_emails_list_for_registration, after the header, the first column is allowed for mail registration.',
        buttons: {
          download: 'Unload codes',
          upload_file: 'Download the file'
        },
        notifications: {
          success_create: 'Success',
          success_created_title: 'Success',
          success_created_message: 'You have successfully added [Count] codes.',
          success_deleted_title: 'Success',
          success_deleted_message: 'You have successfully deleted [Count] codes.'
        },
        errors: {
          teams: 'It is impossible to process the document. Check the line',
          title: 'server error'

        },
        popups: {
          confirm_delete: {
            title: 'Disconnect the code',
            message: 'You are sure you want to delete [NAME]?',
            wait_message: 'Removal...',
            buttons: {
              confirm: 'Delete',
              cancel: 'Cancel'
            }
          }
        }
      },
      table:{
        id: 'ID',
        code: 'code',
        email: 'email',
        description: 'Description',
        image: 'Avatar',
        is_active: 'Activated',
        created_at: 'date of creation',
        actions: 'Actions',
        coins: 'Coins',
        product_id: 'ID Product',
        comment: 'Comment'
      },
      create:{
        title: 'Create a segment',
        title_edit: 'Edit the segment',
        fields: {
          name: 'Name',
          slug: 'Slug',
          users: 'Users',
          departments: 'Departments',
          teams: 'Teams',
          city: 'Cities',
          gender: 'Genders',
          created_at_greater_now_minus_value: 'The date of registration is fresh than [x seconds ago]',
          created_at_lower_now_minus_value: 'Registration date earlier than [x seconds ago]',
          created_at_greater_datetime: 'Registration date later than [date] ',
          created_at_lower_datetime: 'Registration date earlier than [date] '
        }
      }
    },
    faq: {
      suite: {
        title: 'FAQ',
        errors:{
          title: 'Error trying delete FAQ'
        },
        popups: {
          confirm_delete: {
            message: 'You are sure you want to delete [NAME]?',
            buttons:{
              confirm: 'Delete',
              cancel: 'Cancel'
            }
          }
        }
      },
      table: {
        id: 'ID',
        title: 'Title',
        actions: 'Actions',
        is_published: 'Published',
        groups: 'Groups'
      },
      create: {
        title: 'Create article',
        labels: {
          is_published: 'Published',
          title: 'Title',
          content: 'Content',
          image: 'Image',
          is_display_preview: 'Display in preview'
        },
        buttons: {
          create: 'Create'
        }
      },
      edit: {
        title: 'Edit article',
        labels: {
          is_published: 'Published',
          title: 'Title',
          content: 'Content',
          image: 'Image',
          is_display_preview: 'Display in preview'
        },
        buttons: {
          edit: 'Update'
        },
        error_parse: {
          content: 'Article description is corrupted, please refill',
          button_confirm: 'Ok'
        }
      },
      buttons: {
        priority: 'Sort'
      }
    },
    form: {
      errors: {
        required_field: 'Required Field',
        required_recipients: 'You Need to Select Recipents',
        doesnt_exist: 'Doesn\'t exist',
        unique_field: 'Unique Field',
        inccorect_email: 'Inccorect email',
        invalid_data: 'Invalid data',
        max_size: 'Maximum size exceeded',
        mailing_sending_exist: 'You cannot change the mailing list that has already been sent',
        password_min_size: 'Insufficient minimum size',
        letters_and_digits: 'Password must contain letters and numbers',
        time_format: 'Invalid time format',
        only_future_date: 'Date cannot be earlier than the current time',
        only_past_date: 'Date cannot be later than the current time'
      }
    },
    content_pages: {
      suite: {
        title: 'Content pages',
        table: {
          id: 'ID',
          slug: 'Slug',
          name: 'Name',
          date: 'Date',
          edit: 'Edit'
        }
      },
      create: {
        title: 'Create content page',
        labels: {
          slug: 'Slug',
          name: 'Name',
          content: 'Content',
          block_content: 'Block content'
        },
        buttons: {
          create: 'Create'
        }
      },
      edit: {
        title: 'Edit content page',
        labels: {
          slug: 'Slug',
          name: 'Name',
          content: 'Content',
          block_content: 'Block content'
        },
        buttons: {
          edit: 'Save changes'
        },
        error_parse: {
          content: 'Content page Block content is corrupted, please refill',
          button_confirm: 'Ok'
        }
      }
    },
    clubs: {
      create: {
        title: 'Create club',
        button: 'Create'
      },
      suite: {
        title: 'Clubs',
        table: {
          avatar: 'Avatar',
          name: 'Name',
          action: 'Actions'
        }
      },
      buttons: {
        priority: 'Priority'
      },
      edit: {
        title: 'Edit club',
        labels: {
          name: 'Name',
          image: 'Image',
          address: 'Address',
          description: 'Description',
          block_description: 'Block description',
          opened: 'Opened',
          expand_on_home: 'Expand on Home'
        },
        error_parse: {
          content: 'Club description is corrupted, please refill',
          button_confirm: 'Ok'
        },
        button: 'Update'
      }
    },
    dashboard: {
      title: 'Dashboard',
      titles: {
        statistics: 'Statistics',
        count_activated_users: 'Active Users',
        user_activity: 'User activity',
        all_users: 'All users',
        users: 'Users',
        count_added_programs: 'Added programs',
        count_comments: 'Comments',
        count_liked_category_comments: 'Likes',
        challenge: 'Challenge',
        challenges: 'Challenges',
        programm: 'Course',
        programs: 'Courses',
        earned_points: 'Earned points',
        completed_tasks: 'Completed tasks',
        posts_in_feed: 'Posts in the Activity tape',
        written_messages: 'Written messages',
        put_likes: 'Likes put',
        visits_to_the_app: 'Visits to the app',
        time_spent_in_the_app: 'Time spent in the app',
        interests: 'Interests',
        courses_and_programs: 'Courses and programs',
        courses_and_challenges: 'Courses/Challenges',
        top_active_users: 'TOP-5 active users',
        top_sociable_users: 'TOP-5 of the most sociable user',
        teams: 'Teams',
        top_active_teams: 'TOP-5 active commands',
        top_courses_and_challenges: 'TOP-5 courses and challenge',
        top_reports: 'TOP-5 reports',
        top_reports_on_likes: 'TOP-5 reports on the number of likes in events',
        points: 'Points',
        messages: 'Messages',
        event: 'Event',
        events: 'Events',
        top_events: 'Top 5 events',
        past_events: 'Past events',
        participants: 'Participants',
        total_tasks: 'Total tasks',
        communication: 'Communications',
        blog: 'Blog',
        likes: 'Likes',
        reports: 'Reports',
        shop: 'Store',
        family_users: 'Family accounts',
        top_positions: 'ТOP-5 positions',
        product: 'Product',
        number_of_purchases: 'The number of purchases',
        remains: 'Remains',
        quantity: 'Quantity',
        orders: 'Orders',
        ordered_from_warehouse: 'Ordered from the warehouse',
        ordered_digital: 'Order digital',
        digital_goods: 'Digital goods',
        warehouse_goods: 'Warehouse'
      },
      labels: {
        last_changes: 'Change since the last visit to this page',
        changes_compared_to_the_same_previous_period: 'The number of days with the quantity for the previous [PERIOD] days is compared for the last [PERIOD]',
        all_time: 'All time',
        year: 'Year',
        month: '30 days',
        tag: 'Tag',
        time: 'Time'
      },
      diff_time: {
        seconds: 'just now',
        minutes: 'minutes ago',
        hours: 'hours ago',
        days: 'days ago',
        weeks: 'weeks ago',
        months: 'months ago',
        years: 'years ago',
        h: 'h'
      }
    },
    pedometer: {
      challenges: {
        suite: {
          title: 'Challenges',
          table: {
            is_active: 'Active',
            is_team: 'Team challenge',
            count_steps: 'Count steps',
            start: 'Start',
            end: 'End',
            completed: 'Completed',
            passed: 'Passed',
            actions: 'Actions',
            leaderboard: 'Leaderboard',
            team_leaderboard: 'Team leaderboard',
            title: 'Title',
            content: 'Content',
            challenge_type: 'Type',
            image: 'Image'
          },
          popups: {
            delete: {
              title: 'Delete challenge',
              message: 'Are you sure you want to delete [NAME]',
              wait_message: 'Deleting...',
              buttons: {
                confirm: 'Delete',
                cancel: 'Cancel'
              }
            }
          }
        },
        create: {
          title: 'Create challenge',
          labels: {
            start: 'Start',
            end: 'End',
            count_steps: 'Count steps',
            coins: 'Coins for execution',
            is_active: 'Active',
            is_team: 'Team challenge',
            title: 'Title',
            content: 'Content',
            announcement: 'Announcement',
            challenge_type: 'Type',
            image: 'Image'
          },
          buttons: {
            create: 'Create'
          }
        },
        edit: {
          title: 'Edit challenge',
          labels: {
            start: 'Start',
            end: 'End',
            count_steps: 'Count steps',
            coins: 'Coins for execution',
            is_active: 'Active',
            is_team: 'Team challenge',
            title: 'Title',
            content: 'Content',
            announcement: 'Announcement',
            challenge_type: 'Type',
            image: 'Image'
          },
          buttons: {
            edit: 'Update'
          },
          error_parse: {
            content: 'Challenge description is corrupted, please refill',
            button_confirm: 'Ok'
          }
        }
      }
    },
    blog: {
      posts: {
        suite: {
          title: 'Posts',
          sort: 'Sort',
          table: {
            title: 'Title',
            publication_at: 'Publication at',
            destroy: 'Delete',
            edit: 'Edit',
            actions: 'Actions'
          },
          popups: {
            delete: {
              title: 'Delete post',
              message: 'Are you sure you want to delete [NAME]',
              wait_message: 'Deleting...',
              buttons: {
                confirm: 'Delete',
                cancel: 'Cancel'
              }
            }
          }
        },
        create: {
          title: 'Create post',
          labels: {
            title: 'Title',
            image: 'Drag a image',
            preview: 'Preview',
            content: 'Content',
            tags: 'Tags',
            add_tags: 'Add tags',
            publication_at: 'Publication at',
            is_active: 'Published'
          },
          buttons: {
            create: 'Create'
          }
        },
        edit: {
          title: 'Edit post',
          labels: {
            title: 'Title',
            image: 'Drag a image',
            preview: 'Preview',
            content: 'Content',
            tags: 'Tags',
            add_tags: 'Add tags',
            publication_at: 'Publication at',
            is_active: 'Published'
          },
          buttons: {
            edit: 'Save changes'
          },
          error_parse: {
            content: 'Post description is corrupted, please refill',
            button_confirm: 'Ok'
          }
        }
      }
    },
    athlete_after_login: {
      button: 'Become a teacher'
    },
    update_password: {
      title: 'Update Password',
      message: 'Please enter your new password. At least 6 characters',
      placeholder: 'Password',
      button: 'Update',
      success_message: 'Password updated',
      invalid_token_message: 'Password recovery link is out of date'
    },
    notifications: {
      title: 'Push-mailing',
      table: {
        id: 'ID',
        title: 'Title',
        content: 'Content',
        start_after: 'When send',
        date: 'Created at',
        actions: 'Actions',
        status: 'Status'
      },
      edittitle: 'Edit push-mail',
      createtitle: 'Create push-mail',
      fields: {
        title: 'Title (recommended up to 20 chars)',
        message: 'Message',
        sending_settings: 'Sending Settings',
        content: 'Content (recommended up to 60 chars)',
        url: 'Url link',
        additional_url: 'Additional url link',
        start_after: 'When send',
        address: 'Who:',
        send_all: 'Send to all',
        except_for_family_users: '(Except for family users)',
        select_clubs: 'Clubs',
        select_challenges: 'Challenges',
        select_events: 'Events',
        select_programms: 'Programms',
        select_trainings: 'Trainings',
        select_users: 'Users',
        send_type: {
          title: 'Send Type',
          labels: {
            by_time: 'By time',
            by_goal: 'By goal'
          }
        },
        select_pedometer: 'Challenge',
        target_count: 'Target count'
      },
      additional_url_types: {
        title: 'Event type',
        labels: {
          blog_blogpage: 'Blog',
          newchat: 'Chat',
          step_tab: 'Common goal',
          sport: 'Home',
          sport_event: 'Event',
          sport_workout: 'Course/Challenge'
        }
      }
    },
    announcements: {
      title: 'Announcements',
      table: {
        id: 'ID',
        title: 'Title',
        content: 'Content',
        date: 'Created at',
        deactivate_at: 'Deactivate date',
        actions: 'Actions'
      },
      edittitle: 'Edit announcement',
      createtitle: 'Create announcement',
      sort: 'Sort',
      fields: {
        title: 'Title',
        content: 'Content',
        is_active: 'Published',
        deactivate_at: 'Deactivate date',
        url: 'Url link',
        image: 'Image'
      },
      edit: {
        labels: {
          image: 'Drag a image'
        }
      }
    },
    shop_orders: {
      title: 'Shop. Orders',
      table: {
        id: 'ID',
        products: 'Products',
        user: 'User',
        customer: 'Customer full name',
        customer_name: 'Customer name',
        customer_lastname: 'Customer lastname',
        customer_patronymic: 'Customer patronymic',
        customer_phone: 'Customer phone',
        digital_id: 'Id digital product',
        digital_code: 'Code digital product',
        address: 'Address:',
        customer_address: 'Customer address',
        delivery_postcode: 'postcode',
        delivery_address_without_postcode: 'delivery address without postcode',
        delivery_address_apartment: 'Apartment/office',
        delivery_address_city: 'City',
        comment: 'Commentary on order',
        sum: 'Sum',
        status: 'Status',
        created_at: 'Date',
        actions: 'Actions'
      },
      edittitle: 'Edit order',
      fields: {
        sum: 'Sum',
        status: 'Status',
        created_at: 'Date'
      },
      labels: {
        change_status: 'Change status'
      },
      popups: {
        message: {
          title: 'Error message',
          shop_point_error: 'The user does not have enough points to renew the order',
          product_stock_error: 'Not enough products in stock to renew the order',
          button: 'Ok'
        }
      },
      confirm_delete: {
        message: 'Are you sure you want to delete order #[ID]?',
        buttons: {
          confirm: 'Delete',
          cancel: 'Cancel'
        }
      }
    },
    shop_products: {
      title: 'Shop. Products',
      template: {
        title: 'The choice of the template',
        pattern: 'Template',
        select_template: 'Choose',
        physical: {
          name: 'Physical goods',
          description: 'A real product that requires delivery. For example: Merch of the company, sports props'
        },
        digital: {
          name: 'Digital goods',
          description: 'A product that can be obtained in electronic format. For example: gift card, e -book, service subscription'
        },
        virtual_card: {
          name: 'Virtual bank card',
          description: 'Electronic tool, which is a virtual form of a bank card'
        },
        benefit: {
          name: 'Benefit',
          description: ''
        }
      },
      table: {
        id: 'ID',
        name: 'Title',
        price: 'Price',
        price_in_coins: 'Price in Coins',
        is_active: 'Published',
        stock: 'Stock',
        actions: 'Actions'
      },
      edittitle: 'Edit product',
      createtitle: 'Create product',
      sort: 'Sort',
      fields: {
        name: 'Title',
        enter_name: 'Enter title',
        price: 'Price',
        enter_price: 'Enter the price',
        price_in_points: 'Points',
        price_in_coins: 'Coins',
        image: 'Image',
        block_description: 'Brief description',
        enter_block_description: 'Enter a brief description',
        description: 'Description',
        min_user_level: 'Min user level',
        enter_min_level: 'Enter the level',
        is_active: 'Published',
        is_digital: 'Digital',
        digital_content: 'Digital content',
        stock: '',
        enter_stock: 'Введите количество товара',
        virtual_card: 'Type of virtual card',
        enter_type: 'Select the type',
        real_price: 'Nominal value of the virtual card in rubles',
        enter_nominal: 'Enter the nominal',
        table_with_codes: 'Table with codes',
        info_codes: 'Excel - File Format XLSX, XLS. One (first) column - codes. When updating the goods, codes will be added to existing',
        code_deactivated: 'code deactivated',
        is_need_files: 'The user must add files'
      },
      edit: {
        labels: {
          image: 'Select the image',
          upload: 'Download',
          change: 'Replace',
          delete: 'Delete',
          add: 'Add'
        },
        tabs: {
          edit: 'Edit',
          codes: 'Codes',
          product_settings: 'Product settings'
        },
        error_parse: {
          content: 'Product description is corrupted, please refill',
          button_confirm: 'Ok'
        }
      },
      edit_codes: {
        table: {
          id: 'id',
          code: 'Code',
          activated: 'Activated',
          purchased: 'Purchased',
          deactivated: 'Disabled',
          actions: 'Actions',
          date_of_deletion: 'Date of deletion',
          date_of_activated: 'The date of activation',
          yes: 'Yes',
          no: 'Not'
        },
        popup: {
          title: 'Edit the code',
          add_codes: 'Adding codes',
          table_with_codes: 'Table with codes',
          code: 'Code',
          buttons: {
            delete: 'Disconnect the code',
            restore: 'Restore the code',
            save: 'Save',
            add: 'Add'
          }
        },
        notify: {
          title: 'Successfully!',
          text: 'The code is changed',
          add_code_text: 'The code has been added',
          error: 'Error!',
          error_text: 'The code has not loaded, try it again!'
        }
      },
      confirm_delete: {
        message: 'Are you sure you want to delete product #[ID]?',
        buttons: {
          confirm: 'Delete',
          cancel: 'Cancel'
        }
      }
    },
    shop_categories: {
      title: 'Shop. Categories of goods',
      suite: {
        groups: 'Product categories',
        choose_groups: 'Select a category'
      },
      table: {
        id: 'ID',
        title: 'Category',
        actions: 'Actions'
      },
      edittitle: 'Edit the category',
      createtitle: 'Create a category',
      sort: 'Sort',
      edit: {
        labels: {
          title: 'name of category*',
          slug: 'The name in the URL-Front format (SLUG)'
        },
        buttons: {
          edit: 'Update'
        }
      },
      create: {
        labels: {
          title: 'name of category*',
          slug: 'The name in the URL-Front format (SLUG)'
        },
        buttons: {
          create: 'Create'
        }
      },
      confirm_delete: {
        message: 'You are sure you want to delete the category "[NAME]"?',
        buttons: {
          confirm: 'Delete',
          cancel: 'Cancellation'
        }
      }
    },
    shop_settings: {
      title: 'Shop. Settings',
      notify_manager_new_order: {
        label: 'Notify about new order',
        description: 'Manager\'s email (enter multiple emails separated by commas)'
      },
      limit_count_purchases_per_month: 'limit on the number of purchases per month (leave the field blank if there are no limits)',
      limit_count_purchases_identical_product_per_month: 'limit on the number of purchases of the same products per month (leave the field blank if there are no restrictions)',
      buttons: {
        save: 'Save'
      },
      popups: {
        message: {
          title: 'Message',
          button: 'Ok',
          success: 'Settings have been saved',
          error: 'Incorrect data entered'
        }
      }
    },
    activities: {
      title: 'Activities',
      table: {
        id: 'ID',
        name: 'Title',
        google_fit: 'GoogleFit',
        health_kit: 'HealthKit',
        time_points: 'Points for 1 min',
        distance_points: 'Points for 1 km',
        amount_points: 'Points for 1 unit',
        is_active: 'Published',
        date: 'Created at',
        actions: 'Actions'
      },
      edittitle: 'Edit activity',
      createtitle: 'Create activity',
      sort: 'Sort',
      fields: {
        name: 'Title',
        announcement: 'Description',
        enter_a_announcement: 'Enter the description of activity',
        google_fit: 'GoogleFit',
        health_kit: 'HealthKit',
        time_points: 'Points for 1 min',
        distance_points: 'Points for 1 km',
        amount_points: 'Points for 1 unit',
        image: 'Image',
        is_active: 'Puba is available manually',
        is_required_photo: 'Photo is required',
        metrics: 'Measurement measure'
      },
      metrics: {
        meters: 'metres',
        kilometers: 'kilometers'
      },
      create: {
        buttons: {
          create: 'Create'
        }
      },
      edit: {
        buttons: {
          edit: 'Save changes'
        }
      }
    },
    teams: {
      title: 'Teams',
      table: {
        id: 'ID',
        name: 'Title',
        description: 'Description',
        image: 'Image',
        is_active: 'Published',
        created_at: 'Created at',
        actions: 'Actions'
      },
      edittitle: 'Edit team',
      createtitle: 'Create team',
      fields: {
        name: 'Title',
        description: 'Description',
        image: 'Image',
        is_active: 'Published',
        is_enable_chat: 'Enable chat',
        created_at: 'Created at',
        actions: 'Actions'
      },
      suite: {
        buttons: {
          download: 'Download',
          upload_file: 'Upload file'
        },
        notifications: {
          succsess_created_title: 'Success',
          succsess_created_message: 'Successfuly created [COUNT] teams.',
          succsess_deleted_title: 'Success',
          succsess_deleted_message: 'Successfuly deleted [COUNT] teams.'
        },
        errors: {
          teams: 'The document could not be processed. Check the line ',
          title: 'Server error'
        },
        popups: {
          confirm_delete: {
            title: 'Delete team',
            message: 'Are you sure you want to delete [NAME]?',
            wait_message: 'Deleting...',
            buttons: {
              confirm: 'Confirm',
              cancel: 'Cancel'
            }
          }
        }
      },
      create: {
        buttons: {
          create: 'Create'
        }
      },
      edit: {
        buttons: {
          edit: 'Save changes'
        }
      }
    },
    mailings: {
      title: 'Mailings',
      table: {
        id: 'ID',
        title: 'Title',
        subject: 'Letter subject',
        content: 'Letter content',
        sending_at: 'Date of sending',
        status: 'Status',
        is_active: 'Active',
        actions: 'Actions'
      },
      edittitle: 'Edit team',
      createtitle: 'Create team',
      fields: {
        slug: 'Unique code',
        title: 'Title',
        subject: 'Letter subject',
        content: 'Letter content',
        sending_at: 'Sending date',
        target_type: 'Type of target',
        target_item: 'ID of target',
        is_active: 'Active',
        actions: 'Actions'
      },
      target_types: {
        all: 'All',
        inactive: 'Inactive',
        club: 'Club`s members',
        program: 'Program`s members',
        user: 'Athlete'
      },
      suite: {
        notice: 'Automatically substituted variables: name, company. For the registration: email, password.',
        popups: {
          confirm_delete: {
            title: 'Delete mailing',
            message: 'Are you sure you want to delete [NAME]?',
            wait_message: 'Deleting...',
            buttons: {
              confirm: 'Confirm',
              cancel: 'Cancel'
            }
          }
        }
      },
      create: {
        buttons: {
          create: 'Create'
        }
      },
      edit: {
        buttons: {
          edit: 'Save changes'
        },
        tabs: {
          edit: 'Edit',
          users: 'Recipients'
        },
        error_parse: {
          content: 'Mailing description is corrupted, please refill',
          button_confirm: 'Ok'
        },
        users: {
          table: {
            avatar: 'Avatar',
            name: 'Name',
            email: 'Email',
            sent_at: 'Sent at'
          }
        }
      }
    },
    events: {
      title: 'Events',
      table:{
        id: 'ID',
        label: 'Title',
        publication_at: 'Date of publication',
        datetime_registration_to: 'registration before',
        date_from: 'Event start date',
        date_to: 'Event end date',
        actions: 'Actions'
      },
      edittitle: 'Edit the newsletter',
      createtitle: 'Create an event',
      fields: {
        slug: 'Unique code',
        title: 'Title',
        subject: 'Subject of the letter',
        content: 'Content',
        target_type: 'Recipient type',
        target_item: 'Recipient ID (optional)',
        sending_at: 'Date of dispatch',
        is_active: 'Active',
        actions: 'Actions'
      },
      target_types: {
        all: 'All',
        inactive: 'Inactive (more than 30 days)',
        club: 'Club members',
        program: 'Program participants',
        user: 'Athlete'
      },
      suite: {
        notice: 'Automatically substituted variables: name, company. To register: email, password..',
        popups: {
          confirm_delete: {
            title: 'Delete an event',
            message: 'Are you sure you want to delete [NAME]?',
            wait_message: 'Removal..',
            buttons: {
              confirm: 'Remove',
              cancel: 'Cancel'
            }
          }
        }
      },
      create: {
        buttons: {
          create: 'Create'
        },
        labels: {
          accessibility_settings: 'Accessibility Settings',
          input_task: 'Input task',
          output_task: 'Output task',
          event_description: 'Event Description',
          max_quantity_places: 'Number of seats (leave empty if not limited)',
          datetime_registration_to: 'Event date',
          datetime_registration_to_required: 'Event start date*',
          date_from_required: 'Event start date*',
          labels: 'Labels',
          newLabel: 'add new labels (separator ",")',
          point: 'Scores',
          coin: 'Coins',
          point_required: 'Points for completing the event*',
          coin_required: 'Coins for completing the event*',
          entrance_point: 'Points for joining the event',
          entrance_coin: 'Coins for joining the event'
        }
      },
      edit: {
        buttons: {
          edit: 'Save Changes'
        },
        tabs: {
          edit: 'Edit',
          users: 'Recipients'
        },
        users : {
          table: {
            avatar: 'Avatar',
            name: 'Name',
            email: 'Email',
            sent_at: 'Shipped'
          }
        }
      }
    },
    points_settings: {
      title: 'Points settings',
      table: {
        id: 'ID',
        name: 'Title',
        point_type: 'Type',
        point: 'Point',
        plus_point: 'Plus point',
        max_appends: 'Max appends',
        actions: 'Actions'
      },
      edittitle: 'Edit setting',
      createtitle: 'Create setting',
      fields: {
        name: 'Title',
        point_type: 'Type',
        point: 'Point',
        coins: 'Coins',
        plus_point: 'Plus point',
        max_appends: 'Max appends',
        is_public: 'Show in app',
        actions: 'Actions'
      },
      create: {
        buttons: {
          create: 'Create'
        }
      },
      edit: {
        buttons: {
          edit: 'Save changes'
        }
      }
    },
    chat_messages: {
      suite: {
        popups: {
          buttons: {
            delete: 'Delete',
            recover: 'Recover',
            cancel: 'Cancel'
          }
        },
        table: {
          id: 'ID',
          chat_id: 'Chat ID',
          chat_name: 'Chat name',
          user: 'User',
          action: 'Actions',
          message: 'Message',
          deleted_at: 'Date of deletion',
          created_at: 'Date of creation'
        }
      }
    },
    personal_goals: {
      title: {
        template: 'Template selection',
        create: 'Create personal goal',
        edit: 'Edit personal goal'
      },
      suite: {
        buttons: {
          create_personal_goal: 'Create personal goal',
          sort: 'Sort',
          select_template: 'Select',
          delete: 'Delete',
          save: 'Save'
        },
        title: {
          pattern: 'Pattern',
          personal_goal_name: 'Personal goal name',
          personal_goal_announcement: 'Personal goal announcement',
          enter_a_name: 'Enter the title',
          started_at: 'the date of the beginning',
          ended_at: 'expiration date',
          select_date: 'Select the date',
          enter_a_announcement: 'Enter the announcement',
          settings: 'Settings',
          available_after: 'Available after passing',
          type_activity: 'Type of activity',
          challenge_or_programm: 'Program',
          quantity: 'Quantity',
          to_complete: 'to completion',
          points_on_completion: 'Number of points for execution',
          coins_for_completion: 'Number of coins for execution',
          click_points: 'Points for the mark (execution per day)',
          days_to_complete: 'The number of days for execution',
          metrics: 'Measurement measure',
          image: 'Image',
          upload_an_icon: 'Download icon',
          upload_an_img: 'Download image',
          achievement: 'Achievement',
          personal_goal_cover: 'The cover of the personal goal',
          active: 'Active',
          not_active: 'Not active',
          accessibility_settings: 'Accessibility configuration',
          common_deadline: 'General deadline',
          personal_deadline: 'Personal deadline',
          no_deadline: 'Without a deadline',
          tags: 'Tags',
          filters: 'Filters',
          is_pickable: {
            false: 'Everyone is involved',
            true: 'Need to confirm participation'
          },
          import_personal_goal: 'Import personal goal',
          import: {
            alert: {
              title: 'Success',
              text: 'Personal goal successfully added',
              error: 'Error importing personal goal'
            }
          }
        },
        popup: {
          delete_personal_goal: 'Delete personal goal',
          attention: 'Attention!',
          delete_title: 'Personal goal "[Item]" will be deleted?',
          button: {
            close: 'Close'
          }
        }
      },
      table: {
        achievement: 'Achivka',
        name: 'NAME',
        type: 'TYPE',
        tags: 'TAGS',
        participants: 'PARTICIPANTS',
        status: 'STATUS',
        actions: 'ACTIONS'
      },
      edit: {
        error_parse: {
          content: 'Personal goal description is corrupted, please refill',
          button_confirm: 'Ok'
        }
      },
      template: {
        distance: {
          name: 'Distance',
          description: 'A personal goal to complete a certain distance'
        },
        time: {
          name: 'Time',
          description: 'Personal goal to pass activities for a certain time'
        },
        steps_counter: {
          name: 'Steps',
          description: 'Personal goal to pass a certain number of steps'
        },
        complete_few_events: {
          name: 'Developments',
          description: 'Personal goal to participate in a certain number of events'
        },
        complete_few_programs: {
          name: 'Courses',
          description: 'Personal goal to pass a certain number of courses'
        },
        write_messages_in_chat: {
          name: 'Messages',
          description: 'Write a certain number of messages'
        },
        comment_workout_result: {
          name: 'Comments',
          description: 'Write a certain number of comments on the reports'
        },
        top_of_the_week: {
          name: 'Leader',
          description: 'Personal goal to become a leader or take first place in the ranking'
        },
        complete_few_activities: {
          name: 'Quantity',
          description: 'Personal goal to fulfill a certain number of activities'
        },
        complete_challenge_or_programm: {
          name: 'Go through the course/challenge',
          description: 'Personal goal for the passage of a particular course/challenge'
        },
        habits_tracker: {
          name: 'Tracker of habits',
          description: 'Personal goal for daily practice of a new habit'
        }
      },
      metrics: {
        meters: 'Meters',
        kilometers: 'Kilometers',
        minutes: 'Minutes',
        hours: 'Hours',
        amount: ''
      },
      metrics_declination: {
        meters: 'meters',
        kilometers: 'kilometers',
        minutes: 'minutes',
        hours: 'hours',
        amount: ''
      }
    },
    habits_trackers: {
      title: {
        create: 'Create',
        edit: 'Edit'
      },
      suite: {
        buttons: {
          create_habits_tracker: 'Create a tracker of habits',
          sort: 'Sort',
          edit: 'Edit the tracker of habits',
          delete: 'Delete',
          save: 'Save',
          import_habits_tracker: 'Import habit tracker'
        },
        title: {
          habits_tracker_name: 'The name of the tracker of habits',
          habits_tracker_announcement: 'The announcement of the tracker of habits',
          enter_a_name: 'Enter the title',
          enter_a_announcement: 'Enter the announcement',
          settings: 'Settings',
          description: 'Description',
          available_after: 'Available after passing',
          quantity: 'Quantity',
          to_complete: 'to complete',
          points_on_completion: 'Number of points for execution',
          coins_for_completion: 'The number of coins for execution',
          click_points: 'Points for the mark (execution per day)',
          days_to_complete: 'The number of days for execution',
          image: 'Image',
          upload_an_icon: 'Download the icon',
          upload_an_img: 'Download the image',
          achievement: 'Achievement',
          habits_tracker_cover: 'The cover of the tracker of habits',
          active: 'Active',
          not_active: 'Not active',
          accessibility_settings: 'Accessibility configuration',
          tags: 'Tag',
          filters: 'Filters',
          is_pickable: {
            false: 'Everyone participates',
            true: 'You need to confirm participation'
          },
          import: {
            alert: {
              title: 'Success',
              text: 'Habit tracker successfully added',
              error: 'Error importing habit tracker'
            }
          }
        },
        popup: {
          delete_habits_tracker: 'Delete the tracker of habits',
          attention: 'Внимание!',
          delete_title: 'Tracker of habits "[item]" will be removed?',
          button: {
            close: 'Close'
          }
        }
      },
      table: {
        achievement: 'ACHIEVEMENT',
        name: 'NAME',
        tags: 'TAGS',
        participants: 'PARTICIPANTS',
        status: 'STATUS',
        actions: 'ACTIONS'
      },
      edit: {
        error_parse: {
          content: 'Description of the tracker of habits is damaged, please fill in',
          button_confirm: 'Ок'
        }
      },
      popup: {
        attention: 'Attention!',
        delete_title: 'Tracker of habits "[item]" will be removed?',
        button: {
          close: 'Close'
        }
      }
    },
    relevant: {
      title: 'Actual',
      table:{
        id: 'ID',
        title: 'Name',
        type: 'Type of',
        status: 'Status',
        publication_at: 'Start',
        deactivate_at: 'The ending',
        actions: 'Actions',
        relevant_status: {
          soon: 'Soon',
          active: 'Active',
          completed: 'Completed',
          not_active: 'Not active',
          undefined: ''
        },
        relevant_type: {
          post: 'Blog',
          goal: 'Common goal',
          programm: 'Program',
          challenge: 'Challenge',
          event: 'Events',
          polling: 'Polling',
          self_challenge: 'Self challenge'
        }
      },
      edittitle: 'Record editing',
      createtitle: 'Creation of a record',
      create_button: 'Create a record',
      delete_button: 'Delete',
      sort: 'Sort',
      popup: {
        attention: 'Attention!',
        delete_title: 'Record "[item]" will be deleted?',
        error_valid_publication_at: 'The date of publication in the actual should be later the date of publication of the record.',
        error_program_not_active: 'The selected entry is not published. Before choosing an entry, publish it.',
        button: {
          close: 'Close'
        }
      },
      edit_create: {
        labels: {
          accessibility_settings: 'Accessibility configuration',
          accessibility_publication: 'Setting up publication',
          publication_at: 'The date of publication',
          deactivate_at: 'The end of the publication',
          enter_the_date: 'Enter the date',
          record_type: 'Type of record',
          programm: 'Program',
          name: 'Name',
          not_selected: 'Not chosen'
        }
      }
    },
    reports: {
      title: 'Reports',
      table: {
        id: 'ID',
        user: 'User',
        name: 'Name/tag',
        report: 'Report',
        created: 'Created',
        reject: 'Reject',
        report_rejected: 'Report Rejected',
        recover_report: 'Return',
        approved_report: 'Approve',
        consideration: 'For consideration',
        no_data: 'No reports found',
        done: 'Done'
      },
      suite: {
        search: 'Search',
        hide_filters: 'Hide filters',
        show_filters: 'Filters',
        reset_filters: 'Subtract filters',
        status: 'Status',
        all: 'All',
        dates: 'Dates',
        date_from: 'Date of',
        date_to: 'Date',
        apply: 'Apply',
        km: 'кm.',
        amount: 'count'
      },
      popup: {
        points: 'Баллы',
        likes: 'Лаики',
        disputed: 'Оспорено',
        created: 'Создано',
        updated: 'Обновлено',
        deleted: 'Удалено'
      },
      errors: {
        enter_at_least_3_characters: 'Enter at least 3 characters to search'
      }
    },
    geocaching: {
      title: 'Geocaching',
      confirmDelete: 'You are sure you want to delete[NAME]?',
      table: {
        id: 'ID',
        user: 'User',
        name: 'Name',
        city: 'City',
        region: 'Region',
        announcement: 'Announcement',
        created: 'Created',
        reject: 'Reject',
        rejected: 'Rejected',
        recover: 'Return',
        approved: 'Approve',
        delete: 'Delete',
        consideration: 'For consideration',
        no_data: 'No reports found',
        done: 'Done'
      },
      suite: {
        search: 'Search',
        hide_filters: 'Hide filters',
        show_filters: 'Filters',
        reset_filters: 'Subtract filters',
        status: 'Status',
        all: 'All',
        dates: 'Dates',
        date_from: 'Date of',
        date_to: 'Date',
        apply: 'Apply',
        km: 'кm.'
      },
      popup: {
        points: 'Points',
        likes: 'likes',
        disputed: 'Challenged',
        created: 'Created',
        updated: 'Updated',
        deleted: 'Deleted',
        count_of_found: 'Count of found',
        complaints: 'Complaints'
      },
      errors: {
        enter_at_least_3_characters: 'Enter at least 3 characters to search'
      }
    },
    services: {
      title: 'Services',
      create: 'Create a service',
      edit: 'Edit the service',
      labels: {
        name: 'Name',
        published: 'Published',
        announcement: 'Announcement',
        description: 'Description',
        tags: 'Tag',
        add_tags: 'Add tags',
        segments: 'Segments',
        select_segment: 'Select the segment',
        groups: 'Categories (filter)',
        add_groups: 'Add categories (filter)'

      },
      buttons: {
        create: 'Create',
        edit: 'Save',
        sort: 'Sort'
      },
      table: {
        id: 'ID',
        name: 'Name',
        status: 'Status',
        announcement: 'Announcement',
        description: 'Description',
        tags: 'Tag',
        segments: 'Segments',
        actions: 'Actions'
      },
      popup: {
        delete_title: 'Service "[Item]" will be deleted?'
      }
    },
    contentFilters: {
      suite: {
        filters: 'Filters',
        hide_filters: 'Hide filters',
        save_filters: 'Save filters'
      },
      tooltip: {
        disabled: 'Filter editing will be possible after creating content'
      },
      notify: {
        saved:'Saved',
        error: 'Error',
        success_text: 'Content filters are changed successfully'
      }
    },
    notify: {
      errors: {
        'title': 'Error',
        'error_save': 'Failed to keep changes',
        'no_valid_data': 'Check the validity of the filled field',
        'The given data was invalid.': 'It was not possible to maintain changes, since the compulsory fields were not filled or filled incorrectly',
        'error_delete': 'Failed to delete the recording, try again'
      },
      success: {
        'title': 'Success',
        'success_save': 'The changes were saved',
        'success_delete': 'The recording was deleted'
      }
    },

    suite: {
      and_exit: 'And go out'
    },

    statuses: {
      0: 'New',
      10: 'In work',
      20: 'Information is required',
      30: 'Coordination',
      40: 'Completed',
      50: 'Canceled'
    }

  },

  ru: {
    'menu': {
      home: 'Дашборд',
      programms: 'Программы',
      challenges: 'Челенджи',
      users: 'Пользователи',
      users_list: 'Список пользователей',
      segments: 'Сегменты',
      content_pages: 'Настройка приложения',
      edit_club: 'Настройки клуба',
      pedometer_challenges: 'Старые Общие цели',
      common_goals: 'Общие цели',
      blog_posts: 'Блог',
      clubs: 'Клубы',
      achievements: 'Награды (ачивки)',
      raffle: 'Розыгрыши',
      registration_codes: 'Коды регистрации',
      faq: 'FAQ',
      notifications: 'Push-рассылка',
      communication: 'Коммуникации',
      mailings: 'Email рассылки',
      all_notifications: 'Рассылки',
      announcements: 'Объявления',
      shop_orders: 'Заказы',
      shop_products: 'Товары',
      shop_categories: 'Категории',
      shop_settings: 'Настройки',
      shop: 'Магазин',
      activities: 'Типы активностей',
      teams: 'Команды',
      events: 'События',
      calendar_of_events: 'Календарь событий',
      points_settings: 'Настройки баллов',
      all_settings: 'Настройки',
      message: 'Сообщения',
      personal_goals: 'Личные цели',
      habits_trackers: 'Трекер привычек',
      polling: 'Опрос',
      pollings: 'Опросы',
      relevant: 'Актуальное',
      reports: 'Отчеты',
      polling_health: 'Индекс Благополучия',
      services: 'Сервисы',
      services_catalog: 'Каталог сервисов',
      geocaching: 'Геокешинг',
      content: 'Контент',
      deactivating_users: 'Деактивация пользователей',
      cafeteria: 'Модуль ДМС',
      cafeteria_catalog: 'Каталог',
      cafeteria_orders: 'Заказы'
      
    },
    'navbar': {
      alerts: {
        login_by_id: 'Вы находитесь в клубе',
        logout_button: 'Выйти'
      }
    },
    'achievements': {
      suite: {
        title: 'Награды (ачивки)',
        table: {
          id: 'ID',
          name: 'Название',
          type: 'Тип',
          edit: 'Редактировать',
          copy: 'Копировать',
          delete: 'Удалить'
        },
        popups: {
          delete: {
            title: 'Удалить геймификацию',
            message: 'Вы уверены, что хотите удалить [NAME]?',
            wait_message: 'Удаление...',
            buttons: {
              confirm: 'Удалить',
              cancel: 'Отмена'
            }
          }
        }
      },
      create: {
        title: 'Добавить награду',
        labels: {
          name: 'Название',
          type: 'Тип',
          is_active: 'Активна',
          description: 'Описание',
          challenge: 'Челлендж',
          programm: 'Программа',
          training: 'Тренировка',
          training_challenge: 'Задание',
          pedometr: 'Задание',
          event: 'Событие'
        },
        buttons: {
          create: 'Добавить награду'
        }
      },
      edit: {
        title: 'Редактировать награду',
        unable_edit_notice: 'Вы не можете изменить тип и параметры награды, т.к. некоторые пользователи ее уже получили. Создайте новую награду',
        labels: {
          name: 'Название',
          type: 'Тип',
          is_active: 'Активна',
          description: 'Описание',
          challenge: 'Челлендж',
          programm: 'Программа',
          training: 'Тренировка',
          training_challenge: 'Задание',
          pedometr: 'Задание',
          event: 'Событие'
        },
        buttons: {
          edit: 'Изменить награду'
        }
      },
      type: {
        event: 'Завершённое событие',
        challenge: 'Пройденный челлендж',
        programm: 'Пройденная программа',
        training: 'Пройденное задание в программе',
        training_in_challenge: 'Пройденное задание в челлендже',
        pedometr: 'Пройденное задание шагомера',
        mission: 'Выполненная личная цель'
      }
    },
    'gamification': {
      raffle: {
        title: 'Розыгрыши',
        labels: {
          seed: 'Случайное число',
          reroll: 'Перевыбрать',
          start: 'Начало выборки',
          end: 'Конец выборки',
          moreResultsMoreChances: 'Больше отчетов - больше шансов'
        },
        table: {
          user_id: 'User ID',
          id: 'ID результата',
          name: 'Имя',
          email: 'email',
          type: 'За что',
          source_type: 'Тип загрузки',
          date: 'Дата регистрации',
          role: 'Роль',
          avatar_url: 'Аватар',
          district: 'Округ',
          region: 'Регион',
          city: 'Город',
          department: 'Отдел',
          optional: 'optional',
          gender: 'Пол',
          dob: 'dob',
          phone: 'Телефон'
        }
      }
    },
    'categories': {
      suite: {
        programm: {
          title: 'Программы',
          create_button: 'Добавить программу',
          upload_button: 'Загрузить программу'
        },
        event: {
          title: 'События',
          create_button: 'Добавить событие',
          upload_button: 'Загрузить событие'
        },
        challenge: {
          title: 'Челенджи',
          create_button: 'Добавить челендж',
          upload_button: 'Загрузить челендж'
        },
        polling: {
          title: 'Опрос',
          create_button: 'Добавить опрос',
          upload_button: 'Загрузить опрос'
        },
        polling_health: {
          title: 'Опрос здоровья',
          create_button: 'Добавить опрос здоровья',
          upload_button: 'Загрузить опрос здоровья',
          announcement: 'Описание опроса',
          recommendations: {
            title: 'Рекомендации',
            risks: 'Есть риски',
            risks__level_4: 'Есть риски (1-9)',
            risks__level_3: 'Есть риски (10-19)',
            risks__level_2: 'Есть риски (20-29)',
            risks__level_1: 'Есть риски (30-39)',
            attention: 'Обратите внимание (40-49)',
            can_be_better: 'Можно лучше (50-59)',
            almost_good: 'Почти хорошо (60-69)',
            all_right: 'Все хорошо (70-79)',
            all_great: 'Все отлично (80-89)',
            perfectly: 'Прекрасно (90-100)',
            warning_block: 'Есть риски',
            attention_block: 'Обратите внимание',
            good_block: 'Все хорошо'
          },
          repeated_passage: 'Повторное прохождение',
          on_specific_date: 'В определенную дату',
          certain_number_of_days: 'Через определенное количество дней'
        },
        buttons: {
          edit: 'Редактировать',
          download_members_file: 'Выгрузить участников',
          tasks: 'Задания',
          questions: 'Вопросы',
          sort: 'Сортировать'
        },
        popups: {
          delete: {
            title: 'Удалить категорию',
            message: 'Вы уверены, что хотите удалить [NAME]',
            wait_message: 'Удаление...',
            buttons: {
              confirm: 'Удалить',
              cancel: 'Отмена'
            }
          },
          not_valid: {
            title: 'Необходимо добавить задания!',
            message: 'Нажмите на кнопку "Добавить задание" и заполните необходимые поля'
          }
        },
        statistics: {
          results: 'Отчётов',
          participants: 'Участников'
        }
      },
      template: {
        title: 'Выберите тип программы',
        normal_course: {
          name: 'Обычный курс',
          description: 'Все задания доступны сразу, выполнение в любое время, в любом порядке.',
          create_title: 'Создать обычный курс',
          edit_title: 'Редактировать обычный курс'
        },
        sequential_course: {
          name: 'Последовательный курс',
          description: 'Задания нужно выполнять последовательно (следующий урок открывается после прохождения предыдущего)',
          create_title: 'Создать последовательный курс',
          edit_title: 'Редактировать последовательный курс'
        },
        challenge_by_dates: {
          name: 'Челлендж по датам',
          description: 'Задания открываются в указанную дату.',
          create_title: 'Создать челлендж по датам',
          edit_title: 'Редактировать челлендж по датам'
        },
        challenge_for_departure: {
          name: 'Челлендж на вылет',
          description: 'Задания открываются раз в день с даты вступления в челлендж. Если пользователь пропускает день отчета - он вылетает из челленджа.',
          create_title: 'Создать челлендж на вылет',
          edit_title: 'Редактировать челлендж на вылет'
        },
        self_challenge: {
          name: 'Личный челлендж',
          description: 'Задания открываются ежедневно.',
          create_title: 'Создать личный челлендж',
          edit_title: 'Редактировать личный челлендж'
        },
        buttons: {
          choose: 'Выбрать',
          to_change: 'Изменить тип'
        }

      },
      edit: {
        programm: {
          title: 'Редактировать',
          error_parse: {
            content: 'Описание программы повреждено, пожалуйста, заполните заново',
            button_confirm: 'Ок'
          }
        },
        challenge: {
          title: 'Редактировать',
          error_parse: {
            content: 'Описание челенджа повреждено, пожалуйста, заполните заново',
            button_confirm: 'Ок'
          }
        },
        self_challenge: {
          title: 'Редактировать',
          error_parse: {
            content: 'Описание челенджа повреждено, пожалуйста, заполните заново',
            button_confirm: 'Ок'
          }
        },
        polling: {
          title: 'Редактировать опрос',
          error_parse: {
            content: 'Описание опроса повреждено, пожалуйста, заполните заново',
            button_confirm: 'Ок'
          }
        },
        event: {
          error_parse: {
            content: 'Описание опроса повреждено, пожалуйста, заполните заново',
            button_confirm: 'Ок'
          }
        },
        polling_health: {
          title: 'Редактировать опрос здоровья',
          error_parse: {
            content: 'Описание опроса здоровья повреждено, пожалуйста, заполните заново',
            button_confirm: 'Ок'
          }
        },
        tabs: {
          edit: 'Редактировать',
          members: 'Участники',
          statistics: 'Статистика'
        },
        labels: {
          programm: {
            point: 'Баллы за программу',
            entrance_point: 'Баллы за вступление в программу',
            coin: 'Коины за программу',
            entrance_coin: 'Коины за вступление в программу',
            announcement: 'Анонс',
            block_description: 'Описание'
          },
          challenge: {
            point: 'Баллы за выполнение челленджа',
            entrance_point: 'Баллы за вступление в челлендж',
            coin: 'Коины за выполнение челленджа',
            entrance_coin: 'Коины за вступление в челлендж',
            announcement: 'Анонс',
            block_description: 'Описание'
          },
          self_challenge: {
            point: 'Баллы за выполнение челленджа',
            entrance_point: 'Баллы за вступление в челлендж',
            coin: 'Коины за выполнение челленджа',
            entrance_coin: 'Коины за вступление в челлендж',
            announcement: 'Анонс',
            block_description: 'Описание'
          },
          polling: {
            point: 'Баллы за выполнение опроса',
            entrance_point: 'Баллы за вступление',
            coin: 'Коины за выполнение опроса',
            entrance_coin: 'Коины за вступление',
            announcement: 'Описание опроса',
            block_description: 'Рекомендации'
          },
          polling_health: {
            point: 'Баллы за выполнение опроса здоровья',
            entrance_point: 'Баллы за вступление',
            coin: 'Коины за выполнение опроса здоровья',
            entrance_coin: 'Коины за вступление',
            announcement: 'Описание опроса',
            block_description: 'Рекомендации'
          },
          name: 'Имя',
          announcement: 'Анонс',
          description: 'Описание',
          availability: 'Доступность',
          block_description: 'Описание',
          event_description: 'Описание',
          accessibility_settings: 'Настройки доступности',
          image: 'Выберите изображение',
          type: 'Челлендж',
          is_active: 'Активна',
          is_consistent: 'Открывать следующий урок только после прохождения предыдущего',
          is_without_missed: 'Челлендж на вылет',
          missed_count: 'Количество возможных пропусков',
          is_show_chat: 'Отображать чат в приложении',
          tags: 'Тэги',
          activities: 'Типы активностей',
          point: 'Баллы за программу',
          show_by_dates: 'Вывод заданий по датам',
          dont_add_points_if_missed: 'Не начислять баллы, если пропустил день отчета',
          publication_at: 'Дата публикации*',
          clubs: 'Клуб',
          category_type: 'Тип категории',
          entrance_point: 'Баллы за вступление в категорию',
          datetime_registration_to: 'Регистрация до',
          date_from: 'Дата начала',
          date_to: 'Дата конца'
        },
        buttons: {
          delete: 'Удалить',
          update: 'Обновить',
          create: 'Создать'
        },
        members: {
          table: {
            avatar: 'Аватар',
            name: 'Имя',
            city: 'Город',
            department: 'Отдел',
            training: {
              result: {
                count: 'Кол-во отчетов'
              }
            }
          }
        },
        statistics: {
          titles: {
            count_users: 'Кол-во участников',
            count_added_results: 'Кол-во добавленных отчетов',
            count_comments: 'Кол-во комментариев',
            download_statistics: 'Выгрузить статистику в Excel'
          }
        }
      },
      create: {
        programm: {
          title: 'Добавить программу',
          titleType:'Добавить [TYPE]'
        },
        challenge: {
          title: 'Добавить челендж'
        },
        polling: {
          title: 'Добавить опрос'
        },
        polling_health: {
          title: 'Добавить опрос здоровья'
        },
        labels: {
          programm: {
            point: 'Баллы за программу',
            entrance_point: 'Баллы за вступление в программу',
            coin: 'Коины за программу',
            entrance_coin: 'Коины за вступление в программу',
            announcement: 'Анонс',
            block_description: 'Описание'
          },
          challenge: {
            point: 'Баллы за выполнение челленджа',
            entrance_point: 'Баллы за вступление в челлендж',
            coin: 'Коины за выполнение челленджа',
            entrance_coin: 'Коины за вступление в челлендж',
            announcement: 'Анонс',
            block_description: 'Описание'
          },
          self_challenge: {
            point: 'Баллы за выполнение челленджа',
            entrance_point: 'Баллы за вступление в челлендж',
            coin: 'Коины за выполнение челленджа',
            entrance_coin: 'Коины за вступление в челлендж',
            announcement: 'Анонс',
            block_description: 'Описание'
          },
          polling: {
            point: 'Баллы за выполнение опроса',
            entrance_point: 'Баллы за вступление',
            coin: 'Коины за выполнение опроса',
            entrance_coin: 'Коины за вступление',
            announcement: 'Описание опроса',
            block_description: 'Рекомендации'
          },
          polling_health: {
            point: 'Баллы за выполнение опроса здоровья',
            entrance_point: 'Баллы за вступление',
            coin: 'Коины за выполнение опроса здоровья',
            entrance_coin: 'Коины за вступление',
            announcement: 'Описание опроса',
            block_description: 'Рекомендации'
          },
          name: 'Имя',
          name_required: 'Название*',
          availability: 'Доступность',
          publication_at: 'Дата публикации*',
          max_quantity_places: 'Максимальное количество мест (оставить пустым если не ограничено)',
          announcement: 'Анонс',
          location: 'Место проведения',
          description: 'Описание',
          block_description: 'Описание',
          event_description: 'Описание',
          accessibility_settings: 'Настройки доступности',
          image: 'Выберите изображение',
          type: 'Челлендж',
          period: 'Дата начала и дата конца',
          date_to_event_required: 'Дата окончания мероприятия*',
          date_from: 'Дата начала',
          date_to: 'Дата конца',
          is_active: 'Активна',
          event_is_hide_time_and_prohibit_adding_in_calendar: 'Не показывать время и запретить добавление в календарь в приложении',
          is_consistent: 'Открывать следующий урок только после прохождения предыдущего',
          is_without_missed: 'Челлендж на вылет',
          missed_count: 'Количество возможных пропусков',
          is_show_chat: 'Отображать чат в приложении',
          is_show_participants: 'Отображать участников в приложении',
          is_repeated_passage: 'Повторное прохождение',
          tags: 'Тэги',
          activities: 'Типы активностей',
          show_by_dates: 'Вывод заданий по датам',
          show_in_module_event: 'Выводить в модуле События',
          dont_add_points_if_missed: 'Не начислять баллы, если пропустил день отчета',
          datetime_registration_to: 'Регистрация до'
        },
        buttons: {
          create: 'Добавить',
          save: 'Сохранить'
        }
      }
    },
    'trainings': {
      suite: {
        title: 'Задания',
        labels: {
          programm: {
            training_description: 'Описание задания',
            training_results: 'Результаты задания',
            training_image: 'Изображение задания'
          },
          challenge: {
            training_description: 'Описание задания',
            training_results: 'Результаты задания',
            training_image: 'Изображение задания'
          },
          polling: {
            training_description: 'Описание вопроса',
            training_results: 'Результаты',
            training_image: 'Изображение'
          },
          polling_health: {
            training_description: 'Описание вопроса',
            training_results: 'Результаты',
            training_image: 'Изображение'
          },
          training_description: 'Описание задания',
          training_results: 'Результаты задания',
          training_image: 'Изображение задания'
        },
        table: {
          name: 'Имя',
          date: 'Дата',
          edit: 'Редактировать',
          copy: 'Копировать',
          delete: 'Удалить'
        },
        popups: {
          delete: {
            title: 'Удалить задание',
            message: 'Вы уверены, что хотите удалить [NAME]?',
            wait_message: 'Удаление...',
            buttons: {
              confirm: 'Удалить',
              cancel: 'Отмена'
            }
          }
        },
        buttons: {
          sort: 'Сортировать'
        }
      },
      edit: {
        title: 'Редактирование задания',
        labels: {
          name: 'Название задания',
          announcement: 'Анонс',
          description: 'Описание',
          block_description: 'Описание',
          image: 'Выберите изображение',
          remove_image: 'Удалить',
          gender: 'Общий лидерборд для муж. и жен.',
          point: 'Баллы за выполнение задания (если не заполнено, то [POINT])',
          coin: 'Коины за выполнение задания',
          required_viewing_time: 'Минимальное время просмотра',
          not_included_to_statistics: 'Не учитывать в статистике',
          photo_required: 'Старая версия, больше не поддерживается. Фотография',
          photo_required_description: 'Поле не поддерживается. Сюда ничего не заполняем.',
          comment_required: 'Старая версия, больше не поддерживается. Комментарий',
          comment_required_description: 'Поле не поддерживается. Сюда ничего не заполняем.',
          is_required: 'Обязательное поле',
          date: 'Дата'
        },
        error_parse: {
          content: 'Описание задания повреждено, пожалуйста, заполните заново',
          button_confirm: 'Ок'
        },
        buttons: {
          programm: {
            add_task: 'Добавить отчет'
          },
          challenge: {
            add_task: 'Добавить отчет'
          },
          polling: {
            add_task: 'Добавить вопрос'
          },
          polling_health: {
            add_task: 'Добавить задание (вопрос)'
          },
          add_exercise: 'Добавить упражнение',
          add_task: 'Добавить отчет',
          update: 'Обновить'
        },
        popup: {
          title: 'Предупреждение',
          message:
            'Вы не можете удалить, или добавить, т.к. в задании уже добавлены отчеты пользователей. Создайте новое задание.'
        },
        popup_event: {
          title: 'Предупреждение',
          message:
            'Вы не можете удалить, или добавить, т.к. некоторые пользователи уже опубликовали свои отчеты.'
        }
      },
      create: {
        title: 'Создать задание',
        labels: {
          name: 'Название задания',
          announcement: 'Анонс',
          description: 'Описание',
          block_description: 'Описание',
          image: 'Выберите изображение',
          gender: 'Общий лидерборд для муж. и жен.',
          point: 'Баллы за выполнение задания (если не заполнено, то [POINT])',
          coin: 'Коины за выполнение задания',
          point_event: 'Баллы',
          required_viewing_time: 'Минимальное время просмотра',
          not_included_to_statistics: 'Не учитывать в статистике',
          photo_required: 'Старая версия, больше не поддерживается.Фотография.',
          photo_required_description: 'Поле не поддерживается. Сюда ничего не заполняем.',
          comment_required: 'Старая версия, больше не поддерживается. Комментарий',
          comment_required_description: 'Поле не поддерживается. Сюда ничего не заполняем.',
          is_required: 'Обязательное поле',
          hide_results: 'Скрыть результат от других участников',
          any_answer_is_correct: 'Любой ответ будет правильным',
          all_answers_must_be_selected: 'Все ответы должны быть выбраны',
          date: 'Дата'
        },
        buttons: {
          programm: {
            add_task: 'Добавить отчет'
          },
          challenge: {
            add_task: 'Добавить отчет'
          },
          polling: {
            add_task: 'Добавить вопрос'
          },
          polling_health: {
            add_task: 'Добавить задание (вопрос)'
          },
          add_exercise: 'Добавить упражнение',
          add_task: 'Добавить отчет',
          add_photo: 'Добавить изображение',
          create: 'Добавить'
        }
      },
      exercises: {
        labels: {
          repetitions: 'Количество (повторы, кг, метры)',
          time_less: 'Время: Чем быстрее тем лучше',
          time_more: 'Время: Чем дольше тем лучше',
          comment: 'Комментарий',
          photo: 'Фотография',
          hide_results: 'Скрыть результат от других участников',
          enter_correct_answer: 'Укажите верный ответ',
          radio_answers: 'Тест (один вариант ответа)',
          flags_answers: 'Тест (несколько вариантов ответов)',
          radio_answers_poll: 'Опрос (один вариант ответа)',
          flags_answers_poll: 'Опрос (несколько вариантов ответов)',
          answer_by_scale: 'Оценка по шкале'
        }
      },
      buttons: {
        sort: 'Сортировать'
      }
    },
    'exercises': {
      suite: {
        table: {
          name: 'Название',
          name_task_event: 'Название',
          result_type: 'Тип результата',
          is_required: 'Обязательное поле',
          actions: 'Действия'
        }
      },
      create_edit: {
        titles: {
          create: 'Конструктор отчетов',
          create_task: 'Конструктор отчетов',
          edit: 'Конструктор отчетов',
          edit_task: 'Конструктор отчетов'
        },
        labels: {
          name: 'Название',
          enter_a_name: 'Введите название',
          enter_a_answer: 'Введите ответ',
          enter_a_current_answer: 'Введите правильный ответ',
          current_answer: 'Правильный ответ',
          result_type: 'Тип результата',
          point: 'Баллы за выполнение упражнения',
          task_type: 'Тип задания',
          answer: 'Ответ',
          answers: 'Ответы',
          error_valid: 'укажите вариант ответа, либо добавьте изображение',
          error_valid_2:'укажите вариант ответа',
          min_value: ' Минимальное значение',
          max_value: 'Максимальное значение',
          icon: 'Иконка',
          add: 'Добавить',
          delete: 'Удалить'
        },
        buttons: {
          submit: 'Подтвердить',
          cancel: 'Отмена',
          add_photo: 'Добавить изображение',
          create: 'Создать',
          update: 'Обновить'
        },
        description: {
          specify_the_correct_answer: 'Если верных ответов несколько, введите их через знак точки с запятой ";". Пробелы до-после точки с запятой убираются. Регистр не учитывается.',
          example_correct_answer: 'Например, вы ввели: "Ответ 1; Ответ2; оТвЕт 3". Верными ответами будут: "Ответ1", "Ответ 2", "оТвЕт 3" и подобные.'
        }
      }
    },
    'users': {
      suite: {
        title: 'Пользователи',
        table: {
          id: 'ID',
          avatar: 'Аватар',
          email: 'Email',
          phone: 'Телефон',
          name: 'Имя',
          role: 'Роль',
          city: 'Город',
          department: 'Отдел',
          gender: 'Пол',
          dob: 'Дата рож.',
          trainings_results_count: 'Результатов',
          favorite_categories_count: 'Программ',
          all_point: 'Баллов',
          level: 'Уровень',
          actions: 'Действия'
        },
        buttons: {
          upload_file: 'Загрузить файл',
          download_file_training_results: 'Результаты тренировок ',
          download_date_since: 'Дата от',
          download_date_until: 'Дата до',
          download_file_levels: 'Баллы и уровни',
          download_file_orders: 'Скачать таблицу Excel',
          add_admin: 'Добавить админа'
        },
        notifications: {
          succsess_created_title: 'Успех',
          succsess_created_message: 'Вы успешно добавили [COUNT] пользователей.',
          succsess_deleted_title: 'Успех',
          succsess_deleted_message: 'Вы успешно удалили [COUNT] пользователей.'
        },
        popups: {
          confirm_delete: {
            message: 'Вы уверены, что хотите удалить [COUNT] пользователей?',
            buttons: {
              confirm: 'Подтвердить',
              cancel: 'Отмена'
            }
          },
          confirm_delete_user: {
            message: 'Вы уверены, что хотите удалить [EMAIL]?',
            buttons: {
              confirm: 'Удалить',
              cancel: 'Отмена'
            }
          },
          confirm_deactivate_user: {
            message: 'Вы уверены, что хотите деактивировать [EMAIL]?',
            buttons: {
              confirm: 'Деактивировать',
              cancel: 'Отмена'
            }
          }
        },
        errors: {
          'emails': 'Невозможно обработать документ. Проверьте строку ',
          'title': 'Ошибка сервера'
        },
        success: {
          title: 'Успех',
          save: 'Вы успешно сохранили изменения.'
        }
      },
      create: {
        title: 'Регистрация администратора',
        labels: {
          email: 'Email',
          password: 'Пароль',
          password_confirmation: 'Подтверждение пароля',
          name: 'Имя'
        },
        errors: {
          valid_password: 'Введите от 5 до 15 символов',
          valid_password_confirmation: 'Пароли не совпадают'
        },
        buttons: {
          create: 'Создать Админа'
        }
      },
      edit: {
        title: 'Редактировать пользователя',
        labels: {
          role: 'Роль',
          name: 'Имя',
          email: 'Email',
          city: 'Город',
          department: 'Отдел',
          team: 'Команда',
          phone: 'Телефон',
          social_networks: 'Соц. сети',
          gender: 'Пол',
          dob: 'Дата рождения'
        },
        genders: {
          male: 'Мужчина',
          female: 'Женщина'
        },
        points: {
          table: {
            point: 'Баллы',
            coin: 'Коины',
            type: 'Тип',
            target_title: 'Объект',
            notice: 'Примечание',
            created_at: 'Дата начисления',
            deleted_at: 'Дата отмены'
          },
          labels: {
            user: 'Пользователь',
            point: 'Баллы',
            shop_point: 'Баллы в магазине',
            coin: 'Коины',
            notice: 'Примечание',
            created_at: 'Дата начисления'
          },
          edit: {
            buttons: {
              edit: 'Обновить'
            }
          },
          create: {
            buttons: {
              create: 'Добавить'
            }
          },
          popups: {
            confirm_delete: {
              title: 'Удалить начисление?',
              message: 'Вы уверены, что хотите удалить [POINT] начисленных баллов?',
              buttons: {
                confirm: 'Подтвердить',
                cancel: 'Отмена'
              }
            },
            confirm_activate: {
              title: 'Добавить начисление?',
              message: 'Вы уверены, что хотите добавить [POINT] баллов?',
              buttons: {
                confirm: 'Подтвердить',
                cancel: 'Отмена'
              }
            }
          }
        },
        steps: {
          table: {
            id: 'id записи',
            count_steps: 'Количество добавленных шагов',
            target_title: 'Наименование челледжа',
            created_at: 'Дата добавления',
            deleted_at: 'Дата деактивации'
          },
          labels: {
            count: 'Количество шагов',
            change_challenge: 'Челленж',
            challenge_id: 'id челленжа',
            created_at: 'Дата начисления'
          },
          edit: {
            buttons: {
              edit: 'Обновить'
            }
          },
          create: {
            buttons: {
              create: 'Начислить'
            }
          },
          popups: {
            confirm_delete: {
              title: 'Удалить начисление?',
              message: 'Вы уверены, что хотите удалить [STEP] начисленных шагов?',
              buttons: {
                confirm: 'Подтвердить',
                cancel: 'Отмена'
              }
            },
            confirm_activate: {
              title: 'Добавить начисление?',
              message: 'Вы уверены, что хотите добавить [STEP] шагов?',
              buttons: {
                confirm: 'Подтвердить',
                cancel: 'Отмена'
              }
            }
          }
        },
        trainings: {
          table: {
            id: 'ID',
            title: 'Название',
            results_text: 'Отчет',
            points: 'Баллы',
            like_count: '❤️',
            dislike_count: '❗️',
            created_at: 'Создано',
            updated_at: 'Обновлено',
            deleted_at: 'Удалено'
          },
          popups: {
            training: {
              title: 'Отчет'
            },
            value: {
              buttons: {
                cancel: 'Закрыть'
              }
            }
          }
        },
        tabs: {
          edit: 'Пользователь',
          create: 'Начислить',
          management: 'Управление',
          points: 'Баллы',
          coins: 'Коины',
          steps: 'Шаги',
          trainings: 'Отчеты'
        },
        buttons: {
          save: 'Сохранить изменения',
          reset_password: 'Получить ссылку',
          link_to_password_recovery: 'Ссылка на восстановление пароля',
          delete: 'Удалить пользователя',
          download_file_points: 'Скачать таблицу excel',
          deactivate_the_user: 'Деактивировать пользователя'
        },
        access_settings: {
          title: 'Настройки доступа',
          admin: {
            none: 'Нет доступа',
            write: 'Редактирование разрешено',
            read: 'Только просмотр'
          },
          athlete: {
            none: 'Нет доступа',
            write: 'Доступно',
            read: 'Только просмотр'
          },
          none: 'Нет доступа',
          write: 'Редактирование разрешено',
          read: 'Только просмотр',
          massage_access_restriction: 'Вы не можете изменять параметры доступа своей учетной записи, обратитесь к администратору.',
          massage_just_watch: 'Редактирование недоступно',
          modules: {
            'admin-dashboard': 'Дашборд',
            'admin-users': 'Пользователи',
            'admin-clubs-with-program': 'Клубы/Программы',
            'admin-mailings': 'Рассылки',
            'admin-announcements': 'Объявления',
            'admin-events': 'События',
            'admin-settings': 'Доступ к настройкам',
            'admin-settings-segments': 'Настройки. Сегменты',
            'admin-settings-activities': 'Настройки. Типы активностей',
            'admin-settings-teams': 'Настройки. Команды',
            'admin-settings-points-settings': 'Настройки. Баллы',
            'admin-settings-achievements': 'Настройки. Награды (ачивки)',
            'admin-settings-registration-codes': 'Настройки. Коды регистрации',
            'admin-settings-content-pages': 'Настройки. Приложение',
            'admin-content-filters': 'Фильтры контента',
            'admin-raffle': 'Розыгрыши',
            'admin-chat-messages': 'Сообщения чатов',
            'admin-missions': 'Личные цели',
            'admin-relevant': 'Актуальное',
            'admin-pollings': 'Опрос',
            'admin-blog': 'Блог',
            'admin-pedometer': 'Общие цели',
            'admin-shop': 'Магазин',
            'admin-create-admin': 'Создание администратора',
            'admin-cafeteria': 'Кафетерия льгот',
            'comments': 'Чаты, комментарии к отчетам, комментарии в блоге',
            'events': 'Участие в событиях',
            'news-feeds': 'Лента активности (может видеть чужие отчеты)',
            'families': 'Добавление семьи',
            'pedometer': 'Общие цели',
            'points': 'Заработок баллов',
            'shop': 'Магазин',
            'training-activities': 'Видимость отчетов пользователя другими пользователями (тренировки, активности)'
          }
        }
      }
    },
    'segments': {
      suite: {
        title: 'Сегменты',
        title2: 'Выберите сегмент',
        errors: {
          title: 'Ошибка удаления сегмента'
        },
        popups: {
          confirm_delete: {
            message: 'Вы уверены что хотите удалить сегмент «[NAME]»',
            buttons: {
              confirm: 'Удалить',
              cancel: 'Отмена'
            }
          }
        }
      },
      table: {
        id: 'id',
        name: 'Наименование',
        slug: 'Slug',
        created_at: 'Дата создания',
        actions: 'Действия'
      },
      create: {
        title: 'Создать сегмент',
        title_edit: 'Редактировать сегмент',
        fields: {
          name: 'Наименование',
          slug: 'Slug',
          users: 'Пользователи',
          departments: 'Департаменты',
          teams: 'Команды',
          city: 'Города',
          gender: 'Гендеры',
          created_at_greater_now_minus_value: 'Дата регистрации свежее чем [Х секунд назад]',
          created_at_lower_now_minus_value: 'Дата регистрации ранее чем [Х секунд назад]',
          created_at_greater_datetime: 'Дата регистрации после выбранной даты',
          created_at_lower_datetime: 'Дата регистрации до выбранной даты',
          created_at_greater_datetime_button: 'Дата регистрации после',
          created_at_lower_datetime_button: 'Дата регистрации до'
        }
      }
    },
    'registration_codes': {
      suite: {
        title: 'Коды регистрации',
        description: 'Excel - файл формата xlsx, xls.',
        description_codes: 'При загрузке кодов: Первый столбец - коды регистрации (обязательно), второй - email-ы (необязательно). Без заголовков.',
        description_emails: 'При загрузке почт: в первой ячейке файла заголовок: allowed_emails_list_for_registration, после заголовка первый столбец допустимые для регистрации почты.',
        buttons: {
          download: 'Выгрузить коды',
          upload_file: 'Загрузить файл'
        },
        notifications: {
          success_create: 'Успех',
          success_created_title: 'Успех',
          success_created_message: 'Вы успешно добавили [COUNT] кодов.',
          success_deleted_title: 'Успех',
          success_deleted_message: 'Вы успешно удалили [COUNT] кодов.'
        },
        errors: {
          teams: 'Невозможно обработать документ. Проверьте строку',
          title: 'Ошибка сервера'
        },
        popups: {
          confirm_delete: {
            title: 'Отключить код',
            message: 'Вы уверены, что хотите удалить [NAME]?',
            wait_message: 'Удаление...',
            buttons: {
              confirm: 'Удалить',
              cancel: 'Отмена'
            }
          }
        }
      },
      table: {
        id: 'ID',
        code: 'Код',
        email: 'email',
        description: 'Описание',
        image: 'Аватар',
        is_active: 'Активирован',
        created_at: 'Дата создания',
        actions: 'Действия',
        coins: 'Коины',
        product_id: 'ID Продукта',
        comment: 'Комментарий'
      },
      create: {
        title: 'Создать сегмент',
        title_edit: 'Редактировать сегмент',
        fields: {
          name: 'Наименование',
          slug: 'Slug',
          users: 'Пользователи',
          departments: 'Департаменты',
          teams: 'Команды',
          city: 'Города',
          gender: 'Гендеры',
          created_at_greater_now_minus_value: 'Дата регистрации свежее чем [Х секунд назад]',
          created_at_lower_now_minus_value: 'Дата регистрации ранее чем [Х секунд назад]',
          created_at_greater_datetime: 'Дата регистрации позднее чем [дата]',
          created_at_lower_datetime: 'Дата регистрации ранее чем [дата]'
        }
      }
    },
    'faq': {
      suite: {
        title: 'FAQ',
        errors:{
          title: 'Ошибка удаления FAQ'
        },
        popups: {
          confirm_delete: {
            message: 'Вы уверены, что хотите удалить [NAME]?',
            buttons:{
              confirm: 'Удалить',
              cancel: 'Отмена'
            }
          }
        }
      },
      table: {
        id: 'ID',
        title: 'Название',
        actions: 'Действия',
        is_published: 'Опубликовано',
        groups: 'Раздел'
      },
      create: {
        title: 'Создать запись',
        labels: {
          is_published: 'Опубликовано',
          title: 'Название',
          content: 'Описание',
          image: 'Изображение',
          is_display_preview: 'Показывать в превью'
        },
        buttons: {
          create: 'Создать'
        }
      },
      edit: {
        title: 'Редактировать запись',
        labels: {
          is_published: 'Опубликовано',
          title: 'Название',
          content: 'Описание',
          image: 'Изображение',
          is_display_preview: 'Показывать в превью'
        },
        buttons: {
          edit: 'Обновить'
        },
        error_parse: {
          content: 'Описание статьи повреждено, пожалуйста, заполните заново',
          button_confirm: 'Ок'
        }
      },
      buttons: {
        priority: 'Сортировать'
      }
    },
    'form': {
      errors: {
        required_field: 'Обязательное поле',
        required_recipients: 'Нужно выбрать получателей',
        doesnt_exist: 'Не существует',
        unique_field: 'Уникальное поле',
        inccorect_email: 'Некорректный email',
        invalid_data: 'Неверные данные',
        max_size: 'Превышен максимальный размер',
        mailing_sending_exist: 'Нельзя изменить рассылку, которую уже отправляли',
        password_min_size: 'Пароль должен содержать не меньше 6 символов',
        letters_and_digits: 'Пароль должен содержать латинские буквы и цифры',
        time_format: 'Неверный формат времени',
        only_future_date: 'Дата не может быть раньше текущего времени',
        only_past_date: 'Дата не может быть позже текущего времени'
      }
    },
    'content_pages': {
      suite: {
        title: 'Настройка приложения (менять с осторожностью!)',
        table: {
          id: 'ID',
          slug: 'SLUG',
          name: 'Название',
          date: 'Дата',
          edit: 'Редактировать'
        }
      },
      type: {
        one_in_week: '1 раз в неделю',
        two_in_week: '2 раза в неделю',
        one_in_month: '1 раз в месяц'
      },
      create: {
        title: 'Создать настройку',
        labels: {
          slug: 'SLUG',
          name: 'Название',
          content: 'Данные',
          block_content: 'Блочные данные'
        },
        buttons: {
          create: 'Создать'
        }
      },
      edit: {
        title: 'Редактировать',
        labels: {
          slug: 'SLUG',
          name: 'Название',
          content: 'Данные',
          block_content: 'Блочные данные'
        },
        buttons: {
          edit: 'Сохранить изменения'
        },
        error_parse: {
          content: 'Блочные данные настройки приложения повреждены, пожалуйста, заполните заново',
          button_confirm: 'Ок'
        }
      }
    },
    'clubs': {
      create: {
        title: 'Создать клуб',
        button: 'Создать'
      },
      suite: {
        title: 'Клубы',
        table: {
          avatar: 'Лого',
          name: 'Имя',
          action: 'Действия'
        }
      },
      buttons: {
        priority: 'Сортировка'
      },
      edit: {
        title: 'Настройки клуба',
        labels: {
          name: 'Название клуба',
          image: 'Выберите изображение клуба',
          address: 'Физический адрес клуба (если есть)',
          description: 'Краткое описание клуба',
          block_description: 'Подробное описание клуба',
          opened: 'Открытый клуб?',
          expand_on_home: 'Раскрывать на главной'
        },
        error_parse: {
          content: 'Описание клуба повреждено, пожалуйста, заполните заново',
          button_confirm: 'Ок'
        },
        button: 'Обновить'
      }
    },
    'dashboard': {
      title: 'Дашборд',
      titles: {
        statistics: 'Статистика',
        count_activated_users: 'Активные Пользователи',
        user_activity: 'Активность пользователей',
        all_users: 'Все пользователи',
        users: 'Пользователи',
        count_added_programs: 'Добавленные программы',
        count_comments: 'Комментариев',
        count_liked_category_comments: 'Лайков',
        challenge: 'Челлендж',
        challenges: 'Челленджи',
        programm: 'Курс',
        programs: 'Курсы',
        earned_points: 'Заработанные баллы',
        completed_tasks: 'Выполненные задания',
        posts_in_feed: 'Посты в ленте активности',
        written_messages: 'Написано сообщений',
        put_likes: 'Поставлено лайков',
        visits_to_the_app: 'Визиты',
        unique_visits_to_the_app: 'Уникальные посещения',
        time_spent_in_the_app: 'Время проведенное в приложении',
        interests: 'Интересы',
        courses_and_programs: 'Курсы и программы',
        courses_and_challenges: 'Курсы/Челленджи',
        teams: 'Команды',
        top_active_users: 'Топ-5 активных пользователей',
        top_sociable_users: 'Топ-5 самых общительных пользователя',
        top_active_teams: 'Топ-5 активных команд',
        top_courses_and_challenges: 'Топ-5 курсов и челленджей',
        top_reports: 'Топ-5 отчетов',
        top_reports_on_likes: 'Топ-5 отчетов по количеству лайков в событиях',
        points: 'Баллы',
        messages: 'Сообщения',
        event: 'Событие',

        events: 'События',
        top_events: 'Топ-5 событий',
        past_events: 'Прошедшие события',
        participants: 'Участники',
        total_tasks: 'Всего заданий',
        communication: 'Коммуникация',
        blog: 'Блог',
        likes: 'Лайки',
        reports: 'Отчеты',
        shop: 'Магазин ',
        family_users: 'Семейные аккаунты',
        top_positions: 'Топ-5 позиций',
        product: 'Товар',
        number_of_purchases: 'Количество покупок',
        remains: 'Остатки',
        quantity: 'Количество',
        orders: 'Заказы',
        ordered_from_warehouse: 'Заказано со склада',
        ordered_digital: 'Заказано цифровых',
        digital_goods: 'Цифровой товар',
        warehouse_goods: 'Складской товар'
      },
      labels: {
        last_changes: 'Изменение с последнего посещения данной страницы',
        changes_compared_to_the_same_previous_period: 'Сравнивается количество за последние [PERIOD] дней с количеством за предыдущие [PERIOD] дней',
        all_time: 'Все время',
        year: 'Год',
        month: '30 дней',
        tag: 'Тег',
        time: 'Время'

      },
      diff_time: {
        seconds: 'только что',
        minutes: 'мин. назад',
        hours: 'ч. назад',
        days: 'дн. назад',
        weeks: 'нед. назад',
        months: 'месяц назад',
        years: 'год назад',
        h: 'ч'
      }
    },
    'pedometer': {
      challenges: {
        suite: {
          old_title: 'Старые Общие цели',
          title: 'Общие цели',
          table: {
            is_active: 'Активен',
            is_team: 'Командный',
            count_steps: 'Кол-во шагов',
            start: 'Начало',
            end: 'Конец',
            completed: 'Завершен',
            passed: 'Пройден',
            actions: 'Действия',
            leaderboard: 'Лидерборд',
            team_leaderboard: 'Командный',
            title: 'Название',
            content: 'Описание',
            challenge_type: 'Тип',
            image: 'Изображение'
          },
          popups: {
            delete: {
              title: 'Удалить общую цель',
              message: 'Вы уверены, что хотите удалить [NAME]?',
              wait_message: 'Удаление...',
              buttons: {
                confirm: 'Удалить',
                cancel: 'Отмена'
              }
            }
          }
        },
        create: {
          title: 'Создать общую цель',
          labels: {
            start: 'Начало',
            end: 'Конец',
            count_steps: 'Количество',
            coins: 'Коины за выполнение',
            is_active: 'Активен',
            is_team: 'Командный',
            title: 'Название',
            content: 'Описание',
            announcement: 'Анонс',
            challenge_type: 'Тип',
            image: 'Изображение'
          },
          buttons: {
            create: 'Создать'
          }
        },
        edit: {
          title: 'Редактировать общую цель',
          labels: {
            start: 'Начало',
            end: 'Конец',
            count_steps: 'Количество',
            coins: 'Коины за выполнение',
            is_active: 'Активен',
            is_team: 'Командный',
            title: 'Название',
            content: 'Описание',
            announcement: 'Анонс',
            challenge_type: 'Тип',
            image: 'Изображение'
          },
          buttons: {
            edit: 'Обновить'
          },
          error_parse: {
            content: 'Описание общей цели повреждено, пожалуйста, заполните заново',
            button_confirm: 'Ок'
          }
        }
      }
    },
    'blog': {
      posts: {
        suite: {
          title: 'Записи',
          sort: 'Сортировать',
          table: {
            title: 'Заголовок',
            destroy: 'Удалить',
            publication_at: 'Дата публикации',
            edit: 'Редактировать',
            actions: 'Действия'
          },
          popups: {
            delete: {
              title: 'Удалить запись',
              message: 'Вы уверены, что хотите удалить [NAME]?',
              wait_message: 'Удаление...',
              buttons: {
                confirm: 'Удалить',
                cancel: 'Отмена'
              }
            }
          }
        },
        create: {
          title: 'Создать запись',
          labels: {
            title: 'Заголовок',
            image: 'Выберите изображение',
            preview: 'Превью',
            content: 'Содержание',
            tags: 'Теги',
            add_tags: 'Введите теги',
            publication_at: 'Дата публикации',
            is_active: 'Опубликовано'
          },
          buttons: {
            create: 'Создать'
          }
        },
        edit: {
          title: 'Редактировать запись',
          labels: {
            title: 'Заголовок',
            image: 'Выберите изображение',
            preview: 'Превью',
            content: 'Содержание',
            tags: 'Теги',
            add_tags: 'Введите теги',
            publication_at: 'Дата публикации',
            is_active: 'Опубликовано'
          },
          buttons: {
            edit: 'Сохранить изменения'
          },
          error_parse: {
            content: 'Описание поста повреждено, пожалуйста, заполните заново',
            button_confirm: 'Ок'
          }
        }
      }
    },
    'athlete_after_login': {
      button: 'Станьте преподавателем'
    },
    'update_password': {
      title: 'Сброс пароля',
      message: 'Пожалуйста, введите новый пароль, не менее 6 символов',
      placeholder: 'Пароль',
      button: 'Сохранить',
      success_message: 'Пароль изменен',
      invalid_token_message: 'Ссылка на восстановление пароля устарела'
    },
    'notifications': {
      title: 'Push-рассылка',
      table: {
        id: 'ID',
        title: 'Заголовок',
        content: 'Содержимое',
        start_after: 'Когда отправить',
        date: 'Дата создания',
        actions: 'Действия',
        status: 'Статус'
      },
      edittitle: 'Редактировать рассылку',
      createtitle: 'Создать рассылку',
      fields: {
        message: 'Сообщение',
        sending_settings: 'Настройка отправки',
        title: 'Заголовок (рекомендуется до 20 символов)',
        content: 'Контент (рекомендуется до 60 символов)',
        url: 'Внешняя URL ссылка на сайт (откроется во всплывающем окне)',
        additional_url: 'Внутреняя URL ссылка (ссылка на страницу приложения)',
        start_after: 'Когда отправить',
        address: 'Кому:',
        send_all: 'Отправить всем',
        except_for_family_users: '(Кроме семейных пользователей)',
        select_clubs: 'Список клубов',
        select_challenges: 'Список челленджей',
        select_events: 'Список событий',
        select_programms: 'Список программ',
        select_trainings: 'Список тренировок',
        select_users: 'Список пользователей',
        send_type: {
          title: 'Тип отправки',
          labels: {
            by_time: 'По времени',
            by_goal: 'По цели'
          }
        },
        additional_url_types: {
          title: 'Тип события',
          labels: {
            blog_blogpage: 'Блог',
            newchat: 'Чат',
            step_tab: 'Общая цель',
            sport: 'Главная',
            sport_event: 'Событие',
            sport_workout: 'Курс/Челлендж'
          }
        },
        select_pedometer: 'Общая цель',
        target_count: 'Количество баллов/шагов необходимое для отправки'
      }
    },
    'announcements': {
      title: 'Объявления',
      table: {
        id: 'ID',
        title: 'Заголовок',
        content: 'Содержимое',
        date: 'Создано',
        deactivate_at: 'Снятие публикации',
        actions: 'Действия'
      },
      edittitle: 'Редактировать объявление',
      createtitle: 'Создать объявление',
      sort: 'Сортировать',
      fields: {
        title: 'Заголовок',
        content: 'Контент',
        is_active: 'Опубликовано',
        deactivate_at: 'Дата снятия с публикации',
        url: 'Url ссылка',
        image: 'Изображение'
      },
      edit: {
        labels: {
          image: 'Выберите изображение'
        }
      }
    },
    'shop_orders': {
      title: 'Магазин. Заказы',
      table: {
        id: 'ID',
        products: 'Состав',
        user: 'Пользователь',
        customer: 'Получатель',
        customer_name: 'Имя',
        customer_lastname: 'Фамилия',
        customer_patronymic: 'Отчество',
        comment: 'Комментарий к заказу',
        customer_phone: 'Телефон',
        address: 'Адрес:',
        digital_id: 'id цифрового товара',
        digital_code: 'Код цифрового товара',
        customer_address: 'Адрес доставки',
        delivery_postcode: 'Индекс',
        delivery_address_without_postcode: 'Адрес',
        delivery_address_city: 'Город',
        delivery_address_apartment: 'Квартира/офис',
        sum: 'Сумма',
        status: 'Статус',
        created_at: 'Создан',
        actions: 'Действия'
      },
      edittitle: 'Редактировать заказ',
      fields: {
        sum: 'Сумма',
        status: 'Статус',
        created_at: 'Создан'
      },
      labels: {
        change_status: 'Изменить статус'
      },
      popups: {
        message: {
          title: 'Сообщение о ошибке',
          shop_point_error: 'У пользователя не хватает баллов для возобновления заказа',
          product_stock_error: 'На складе не хватает товаров для возобновления заказа',
          button: 'Ок'
        }
      },
      confirm_delete: {
        message: 'Вы уверены, что хотите удалить заказ #[ID]?',
        buttons: {
          confirm: 'Удалить',
          cancel: 'Отмена'
        }
      }
    },
    'shop_products': {
      title: 'Магазин. Товары',
      template: {
        title: 'Выбор шаблона',
        pattern: 'Шаблон',
        select_template: 'Выбрать',
        physical: {
          name: 'Физический товар',
          description: 'Реальный продукт, требующий доставки. Например: мерч компании, спортивный реквизит и тд.'
        },
        digital: {
          name: 'Цифровой товар',
          description: 'Продукт,который можно получить в электронном формате. Например: подарочная карта, электронная книга, подписка на сервис'
        },
        virtual_card: {
          name: 'Виртуальная банковская карта',
          description: 'Электронный инструмент, представляющий собой виртуальную форму банковской карты'
        },
        benefit: {
          name: 'Льгота',
          description: ''
        }
      },
      table: {
        id: 'ID',
        name: 'Название',
        price: 'Цена',
        price_in_coins: 'Цена в коинах',
        is_active: 'Опубликовано',
        stock: 'На складе',
        actions: 'Действия'
      },
      edittitle: 'Редактировать товар',
      createtitle: 'Создать товар',
      sort: 'Сортировать',
      fields: {
        name: 'Название',
        enter_name: 'Введите название',
        price: 'Цена',
        enter_price: 'Введите цену',
        price_in_points: 'БАЛЛЫ',
        price_in_coins: 'КОИНЫ',
        image: 'Изображение',
        block_description: 'Краткое описание',
        enter_block_description: 'Введите краткое описание',
        description: 'Описание',
        min_user_level: 'Минимальный уровень пользователя',
        enter_min_level: 'Введите уровень',
        is_active: 'Опубликовано',
        is_digital: 'Цифровой товар',
        digital_content: 'Цифровой контент',
        stock: 'Наличие',
        enter_stock: 'Введите количество товара',
        virtual_card: 'Тип виртуальной карты',
        enter_type: 'Выберите тип',
        real_price: 'Номинал виртуальной карты в рублях',
        enter_nominal: 'Введите номинал',
        table_with_codes: 'Таблица с кодами',
        info_codes: 'Excel - файл формата xlsx, xls. Один (первый) столбец - коды. При обновлении товара коды добавятся к уже существующим',
        code_deactivated: 'Код отключен',
        is_need_files: 'Пользователь должен добавить файлы'
      },
      edit: {
        labels: {
          image: 'Изображение',
          upload: 'Загрузить',
          change: 'Заменить',
          delete: 'Удалить',
          add: 'Добавить'
        },
        tabs: {
          edit: 'Редактировать',
          codes: 'Коды',
          product_settings: 'Настройки товара'
        },
        error_parse: {
          content: 'Описание товара повреждено, пожалуйста, заполните заново',
          button_confirm: 'Ок'
        }
      },
      edit_codes: {
        table: {
          id: 'id',
          code: 'Код',
          activated: 'Активирован',
          purchased: 'Куплен',
          deactivated: 'Отключен',
          actions: 'Действия',
          date_of_deletion: 'Дата удаления',
          date_of_activated: 'Дата активации',
          yes: 'Да',
          no: 'Нет'
        },
        popup: {
          title: 'Редактировать код',
          add_codes: 'Добавление кодов',
          table_with_codes: 'Таблица с кодами',
          code: 'Код',
          buttons: {
            delete: 'Отключить код',
            restore: 'Восстановить код',
            save: 'Сохранить',
            add: 'Добавить'
          }
        },
        notify: {
          title: 'Успешно!',
          text: 'Код изменен',
          add_code_text: 'Код добавлен',
          error: 'Ошибка!',
          error_text: 'Код не загрузился, попробуйте еще раз!'
        }
      },
      confirm_delete: {
        message: 'Вы уверены, что хотите удалить товар #[ID]?',
        buttons: {
          confirm: 'Удалить',
          cancel: 'Отмена'
        }
      }
    },
    'shop_categories': {
      title: 'Магазин. Категории товаров',
      suite: {
        groups: 'Категории товаров',
        choose_groups: 'Выберите категорию'
      },
      table: {
        id: 'ID',
        title: 'Категория товара',
        actions: 'Действия'
      },
      edittitle: 'Редактировать категорию',
      createtitle: 'Создать категорию',
      sort: 'Сортировать',
      edit: {
        labels: {
          title: 'Название категории*',
          slug: 'Название в URL-дружелюбном формате (slug)'
        },
        buttons: {
          edit: 'Обновить'
        }
      },
      create: {
        labels: {
          title: 'Название категории*',
          slug: 'Название в URL-дружелюбном формате (slug)'
        },
        buttons: {
          create: 'Создать'
        }
      },
      confirm_delete: {
        message: 'Вы уверены, что хотите удалить категорию "[NAME]"?',
        buttons: {
          confirm: 'Удалить',
          cancel: 'Отмена'
        }
      }
    },
    'shop_settings': {
      title: 'Магазин. Настройки',
      notify_manager_new_order: {
        label: 'Уведомлять о новом заказе',
        description: 'E-mail менеджера (введите несколько email через запятую)'
      },
      limit_count_purchases_per_month: 'Ограничение на количество покупок в месяц (оставьте поле пустым, если ограничений нет)',
      limit_count_purchases_identical_product_per_month: 'Ограничение на количество покупок одинаковых товаров в месяц  (оставьте поле пустым, если ограничений нет)',
      buttons: {
        save: 'Сохранить'
      },
      popups: {
        message: {
          title: 'Сообщение',
          button: 'Ок',
          success: 'Настройки сохранены',
          error: 'Введены некорректные данные'
        }
      }
    },
    'activities': {
      title: 'Типы активностей',
      table: {
        id: 'ID',
        name: 'Заголовок',
        google_fit: 'GoogleFit',
        health_kit: 'HealthKit',
        time_points: 'За 1 мин',
        distance_points: 'За 1 км',
        amount_points: 'За 1 ед.',
        is_active: 'Доступно добавление вручную',
        date: 'Дата создания',
        actions: 'Действия'
      },
      edittitle: 'Редактировать тип активности',
      createtitle: 'Создать тип активности',
      sort: 'Сортировать',
      fields: {
        name: 'Заголовок',
        announcement: 'Описание',
        enter_a_announcement: 'Введите описание активности',
        google_fit: 'GoogleFit',
        health_kit: 'HealthKit',
        is_active: 'Доступно добавление вручную',
        is_required_photo: 'Фотография обязательна',
        time_points: 'Баллы за 1 минуту активности',
        distance_points: 'Баллы за 1 км активности',
        amount_points: 'Баллы за единицу активности (отжиманий/шагов/подходов и т.д)',
        image: 'Иконка',
        metrics: 'Мера измерения'
      },
      metrics: {
        meters: 'Метры',
        kilometers: 'Километры',
        null: ''
      },
      create: {
        buttons: {
          create: 'Создать'
        }
      },
      edit: {
        buttons: {
          edit: 'Сохранить изменения'
        }
      }
    },
    'teams': {
      title: 'Команды',
      table: {
        id: 'ID',
        name: 'Название',
        description: 'Описание',
        image: 'Аватар',
        is_active: 'Опубликовано',
        created_at: 'Дата создания',
        actions: 'Действия'
      },
      edittitle: 'Редактировать команду',
      createtitle: 'Создать команду',
      fields: {
        name: 'Название',
        description: 'Описание',
        image: 'Изображение',
        is_active: 'Опубликовано',
        is_enable_chat: 'Включить чат',
        created_at: 'Дата создания',
        actions: 'Действия'
      },
      suite: {
        buttons: {
          download: 'Выгрузить команды',
          upload_file: 'Загрузить файл'
        },
        notifications: {
          succsess_created_title: 'Успех',
          succsess_created_message: 'Вы успешно добавили [COUNT] команд.',
          succsess_deleted_title: 'Успех',
          succsess_deleted_message: 'Вы успешно удалили [COUNT] команд.'
        },
        errors: {
          teams: 'Невозможно обработать документ. Проверьте строку',
          title: 'Ошибка сервера'
        },
        popups: {
          confirm_delete: {
            title: 'Удалить команду',
            message: 'Вы уверены, что хотите удалить [NAME]?',
            wait_message: 'Удаление...',
            buttons: {
              confirm: 'Удалить',
              cancel: 'Отмена'
            }
          }
        }
      },
      create: {
        buttons: {
          create: 'Создать'
        }
      },
      edit: {
        buttons: {
          edit: 'Сохранить изменения'
        }
      }
    },
    'mailings': {
      title: 'Email рассылки',
      table: {
        id: 'ID',
        title: 'Название',
        subject: 'Тема письма',
        content: 'Текст письма',
        sending_at: 'Дата отправки',
        status: 'Статус',
        is_active: 'Активно',
        actions: 'Действия'
      },
      edittitle: 'Редактировать рассылку',
      createtitle: 'Создать рассылку',
      fields: {
        slug: 'Уникальный код',
        title: 'Название',
        subject: 'Тема письма',
        content: 'Текст письма',
        target_type: 'Тип получателя',
        target_item: 'ID получателя (не обязательно)',
        sending_at: 'Дата отправки',
        is_active: 'Активно',
        actions: 'Действия'
      },
      target_types: {
        all: 'Все',
        inactive: 'Не активные (более 30 дней)',
        club: 'Члены клуба',
        program: 'Участники программы',
        user: 'Атлет'
      },
      suite: {
        notice: 'Автоматически подставляемые переменные: name, company. Для регистрации: email, password.',
        popups: {
          confirm_delete: {
            title: 'Удалить рассылку',
            message: 'Вы уверены, что хотите удалить [NAME]?',
            wait_message: 'Удаление...',
            buttons: {
              confirm: 'Удалить',
              cancel: 'Отмена'
            }
          }
        }
      },
      create: {
        buttons: {
          create: 'Создать'
        }
      },
      edit: {
        buttons: {
          edit: 'Сохранить изменения'
        },
        tabs: {
          edit: 'Редактировать',
          users: 'Получатели'
        },
        error_parse: {
          content: 'Описание рассылки повреждено, пожалуйста, заполните заново',
          button_confirm: 'Ок'
        },
        users: {
          table: {
            avatar: 'Аватар',
            name: 'Имя',
            email: 'Email',
            sent_at: 'Отправлено'
          }
        }
      }
    },
    'events': {
      title: 'События',
      table: {
        id: 'ID',
        label: 'Название',
        publication_at: 'Дата публикации',
        datetime_registration_to: 'Регистрация до',
        date_from: 'Дата начала события',
        date_to: 'Окончание события',
        actions: 'Действия'
      },
      edittitle: 'Редактировать рассылку',
      createtitle: 'Создать событие',
      fields: {
        slug: 'Уникальный код',
        title: 'Название',
        subject: 'Тема письма',
        content: 'Текст письма',
        target_type: 'Тип получателя',
        target_item: 'ID получателя (не обязательно)',
        sending_at: 'Дата отправки',
        is_active: 'Активно',
        actions: 'Действия'
      },
      target_types: {
        all: 'Все',
        inactive: 'Не активные (более 30 дней)',
        club: 'Члены клуба',
        program: 'Участники программы',
        user: 'Атлет'
      },
      suite: {
        notice: 'Автоматически подставляемые переменные: name, company. Для регистрации: email, password.',
        popups: {
          confirm_delete: {
            title: 'Удалить событие',
            message: 'Вы уверены, что хотите удалить [NAME]?',
            wait_message: 'Удаление...',
            buttons: {
              confirm: 'Удалить',
              cancel: 'Отмена'
            }
          }
        }
      },
      create: {
        buttons: {
          create: 'Создать'
        },
        labels: {
          accessibility_settings: 'Настройки доступности',
          event_description: 'Описание события',
          input_task: 'Входное задание',
          output_task: 'Выходное задание',
          max_quantity_places: 'Количество мест (оставить пустым если не ограничено)',
          datetime_registration_to: 'Дата события',
          datetime_registration_to_required: 'Дата начала события*',
          date_from_required: 'Дата начала события*',
          labels: 'Ярлыки',
          newLabel: 'Добавить новые ярлыки (разделитель ",")',
          point: 'Баллы',
          coin: 'Коины',
          point_required: 'Баллы за завершение события',
          coin_required: 'Коины за завершение события',
          entrance_point: 'Баллы за вступление в событие',
          entrance_coin: 'Коины за вступление в событие'
        }
      },
      edit: {
        buttons: {
          edit: 'Сохранить изменения'
        },
        tabs: {
          edit: 'Редактировать',
          users: 'Получатели'
        },
        users: {
          table: {
            avatar: 'Аватар',
            name: 'Имя',
            email: 'Email',
            sent_at: 'Отправлено'
          }
        }
      }
    },
    'points_settings': {
      title: 'Настройки баллов',
      table: {
        id: 'ID',
        name: 'Название',
        point_type: 'Тип',
        point: 'Баллы',
        plus_point: 'Plus баллы',
        max_appends: 'Макс начислений',
        actions: 'Действия'
      },
      edittitle: 'Редактировать настройку',
      createtitle: 'Создать настройку',
      fields: {
        name: 'Название',
        point_type: 'Тип',
        point: 'Баллы',
        coins: 'Коины',
        plus_point: 'Plus баллы',
        max_appends: 'Макс начислений',
        is_public: 'Выводить в приложении',
        actions: 'Действия'
      },
      create: {
        buttons: {
          create: 'Создать'
        }
      },
      edit: {
        buttons: {
          edit: 'Сохранить изменения'
        }
      }
    },
    'chat_messages': {
      suite: {
        popups: {
          buttons: {
            delete: 'Удалить',
            recover: 'Восстановить',
            cancel: 'Закрыть'
          }
        },
        table: {
          id: 'ID',
          chat_id: 'Чат ID',
          chat_name: 'Название чата',
          user: 'Пользователь',
          action: 'Действия',
          message: 'Сообщение',
          deleted_at: 'Дата удаления',
          created_at: 'Дата создания'
        }
      }
    },
    'personal_goals': {
      title: {
        template: 'Выбор шаблона',
        create: 'Создать личную цель',
        edit: 'Редактировать личную цель'
      },
      suite: {
        buttons: {
          create_personal_goal: 'Создать личную цель',
          sort: 'Сортировать',
          edit: 'Редактировать личную цель',
          select_template : 'Выбрать',
          delete: 'Удалить',
          save: 'Сохранить'
        },
        title: {
          pattern: 'Шаблон',
          personal_goal_name: 'Название личной цели',
          personal_goal_announcement: 'Анонс личной цели',
          enter_a_name: 'Введите название',
          started_at: 'Дата начала',
          ended_at: 'Дата окончания',
          select_date: 'Выберите дату',
          enter_a_announcement: 'Введите анонс',
          settings: 'Настройки',
          available_after: 'Доступно после прохождения',
          type_activity: 'Тип активности',
          challenge_or_programm: 'Программа',
          quantity: 'Количество',
          to_complete: 'для завершения',
          points_on_completion: 'Количество баллов за выполнение',
          coins_for_completion: 'Количество коинов за выполнение',
          click_points: 'Баллов за отметку (выполнение в день)',
          days_to_complete: 'Количество дней на выполнение',
          metrics: 'Мера измерения',
          image: 'Изображение',
          upload_an_icon: 'Загрузить иконку',
          upload_an_img: 'Загрузить изображение',
          achievement: 'Ачивка',
          personal_goal_cover: 'Обложка личной цели',
          active: 'Активная',
          not_active: 'Не активная',
          accessibility_settings: 'Настройка доступности',
          common_deadline: 'Общий дедлайн',
          personal_deadline: 'Личный дедлайн',
          no_deadline: 'Без дедлайна',
          tags: 'Теги',
          filters: 'Фильтры',
          is_pickable: {
            false: 'Все участвуют',
            true: 'Нужно подтвердить участие'
          },
          import_personal_goal: 'Загрузить личную цель',
          import: {
            alert: {
              title: 'Успех',
              text: 'Личная цель успешно добавлена',
              error: 'Ошибка при импорте личной цели'
            }
          }
        },
        popup: {
          delete_personal_goal: 'Удалить личную цель',
          attention: 'Внимание!',
          delete_title: 'Личная цель "[item]" будет удалена?',
          button: {
            close: 'Закрыть'
          }
        }
      },
      table: {
        achievement: 'АЧИВКА',
        name: 'НАЗВАНИЕ',
        type: 'ТИП',
        tags: 'ТЕГИ',
        participants: 'УЧАСТНИКИ',
        status: 'СТАТУС',
        actions: 'ДЕЙСТВИЯ'
      },
      edit: {
        error_parse: {
          content: 'Описание личной цели повреждено, пожалуйста, заполните заново',
          button_confirm: 'Ок'
        }
      },
      template: {
        distance: {
          name: 'Дистанция',
          description: 'Личная цель на прохождение определенного расстояния'
        },
        time: {
          name: 'Время',
          description: 'Личная цель на прохождение активностей за определенное время'
        },
        steps_counter: {
          name: 'Шаги',
          description: 'Личная цель на прохождение определенного количества шагов'
        },
        complete_few_events: {
          name: 'События',
          description: 'Личная цель на участие в определенном количестве событий'
        },
        complete_few_programs: {
          name: 'Курсы',
          description: 'Личная цель на прохождение определенного количества курсов'
        },
        write_messages_in_chat: {
          name: 'Сообщения',
          description: 'Написать определенное количество сообщений'
        },
        comment_workout_result: {
          name: 'Комментарии',
          description: 'Написать определенное количество комментариев к отчетам'
        },
        top_of_the_week: {
          name: 'Лидер',
          description: 'Личная цель стать лидером или занять первое место в рейтинге'
        },
        complete_few_activities: {
          name: 'Количество',
          description: 'Личная цель на выполнение определенного кол-ва активностей'
        },
        complete_challenge_or_programm: {
          name: 'Пройти курс/челлендж',
          description: 'Личная цель на прохождение конкретного курса/челленджа'
        },
        habits_tracker: {
          name: 'Трекер привычек',
          description: 'Личная цель для ежедневной практики новой привычки'
        }
      },
      metrics: {
        meters: 'Метры',
        kilometers: 'Километры',
        minutes: 'Минуты',
        hours: 'Часы',
        amount: ''
      },
      metrics_declination: {
        'meters': 'метров',
        'kilometers': 'километров',
        'minutes': 'минут',
        'hours': 'часов',
        'amount': '',
        'null': ''
      },
      popup: {
        attention: 'Внимание!',
        delete_title: 'Личная цель "[item]" будет удалена?',
        button: {
          close: 'Закрыть'
        }
      }
    },
    'habits_trackers': {
      title: {
        create: 'Создать',
        edit: 'Редактировать'
      },
      suite: {
        buttons: {
          create_habits_tracker: 'Создать трекер привычек',
          sort: 'Сортировать',
          edit: 'Редактировать трекер привычек',
          delete: 'Удалить',
          save: 'Сохранить',
          import_habits_tracker: 'Загрузить трекер привычек'
        },
        title: {
          habits_tracker_name: 'Название трекера привычек',
          habits_tracker_announcement: 'Анонс трекера привычек',
          enter_a_name: 'Введите название',
          enter_a_announcement: 'Введите анонс',
          settings: 'Настройки',
          description: 'Описание',
          available_after: 'Доступно после прохождения',
          quantity: 'Количество',
          to_complete: 'для завершения',
          points_on_completion: 'Количество баллов за выполнение',
          coins_for_completion: 'Количество коинов за выполнение',
          click_points: 'Баллов за отметку (выполнение в день)',
          days_to_complete: 'Количество дней на выполнение',
          image: 'Изображение',
          upload_an_icon: 'Загрузить иконку',
          upload_an_img: 'Загрузить изображение',
          achievement: 'Ачивка',
          habits_tracker_cover: 'Обложка трекера привычек',
          active: 'Активная',
          not_active: 'Не активная',
          accessibility_settings: 'Настройка доступности',
          tags: 'Теги',
          filters: 'Фильтры',
          is_pickable: {
            false: 'Все участвуют',
            true: 'Нужно подтвердить участие'
          },
          import: {
            alert: {
              title: 'Успех',
              text: 'Трекер привычек успешно добавлен',
              error: 'Ошибка при импорте трекера привычек'
            }
          }
        },
        popup: {
          delete_habits_tracker: 'Удалить трекер привычек',
          attention: 'Внимание!',
          delete_title: 'Трекер привычек "[item]" будет удален?',
          button: {
            close: 'Закрыть'
          }
        }
      },
      table: {
        achievement: 'АЧИВКА',
        name: 'НАЗВАНИЕ',
        tags: 'ТЕГИ',
        participants: 'УЧАСТНИКИ',
        status: 'СТАТУС',
        actions: 'ДЕЙСТВИЯ'
      },
      edit: {
        error_parse: {
          content: 'Описание трекера привычек повреждено, пожалуйста, заполните заново',
          button_confirm: 'Ок'
        }
      },
      popup: {
        attention: 'Внимание!',
        delete_title: 'Трекер привычек "[item]" будет удален?',
        button: {
          close: 'Закрыть'
        }
      }
    },
    'relevant': {
      title: 'Актуальное',
      table:{
        id: 'ID',
        title: 'Название',
        type: 'Тип',
        status: 'Статус',
        publication_at: 'Начало',
        deactivate_at: 'Окончание',
        actions: 'Действия',
        relevant_status: {
          soon: 'Скоро',
          active: 'В ленте',
          completed: 'Завершено',
          not_active: 'Не активно',
          undefined: ''
        },
        relevant_type: {
          post: 'Блог',
          goal: 'Общая цель',
          programm: 'Программа',
          challenge: 'Челлендж',
          event: 'События',
          polling: 'Опрос',
          self_challenge: 'Личный челлендж'
        }
      },
      edittitle: 'Редактирование записи',
      createtitle: 'Создание записи',
      create_button: 'Создать запись',
      delete_button: 'Удалить',
      sort: 'Сортировать',
      popup: {
        attention: 'Внимание!',
        delete_title: 'Запись "[item]" будет удалена?',
        error_valid_publication_at: 'Дата публикации в актуальном должна быть позже даты публикации записи.',
        error_program_not_active: 'Выбранная запись не опубликована. Прежде чем выбрать запись, опубликуйте ее.',
        button: {
          close: 'Закрыть'
        }
      },
      edit_create: {
        labels: {
          accessibility_settings: 'Настройка доступности',
          accessibility_publication: 'Настройка публикации',
          image: 'Выберите изображение',
          publication_at: 'Дата публикации',
          deactivate_at: 'Дата окончания публикации',
          enter_the_date: 'Введите дату',
          record_type: 'Тип записи',
          programm: 'Программа',
          name: 'Название',
          not_selected: 'Не выбрано'
        }
      }
    },
    'reports': {
      title: 'Отчеты',
      table: {
        id: 'ID',
        user: 'Пользователь',
        name: 'Название/тег',
        report: 'Отчет',
        created: 'Создано',
        reject: 'Отклонить',
        report_rejected: 'Отчет отклонен',
        recover_report: 'Вернуть',
        approved_report: 'Одобрить',
        consideration: 'На рассмотрение',
        no_data: 'Отчеты не найдены',
        done: 'Выполнено'
      },
      suite: {
        search: 'Поиск',
        hide_filters: 'Скрыть фильтры',
        show_filters: 'Фильтры',
        reset_filters: 'Сбросить фильтры',
        status: 'Статус',
        all: 'Все',
        dates: 'Даты',
        date_from: 'Дата от',
        date_to: 'Дата до',
        apply: 'Применить',
        km: 'км.',
        amount: 'раз (шт.)'
      },
      popup: {
        points: 'Баллы',
        likes: 'Лаики',
        disputed: 'Оспорено',
        created: 'Создано',
        updated: 'Обновлено',
        deleted: 'Удалено'
      },
      errors: {
        enter_at_least_3_characters: 'Введите не менее 3 символов для поиска'
      }
    },
    'geocaching': {
      title: 'Геокешинг',
      confirmDelete: 'Вы уверены, что хотите удалить [NAME]?',
      table: {
        id: 'ID',
        user: 'Пользователь',
        name: 'Название',
        region: 'Регион',
        city: 'Город',
        announcement: 'Описание',
        created: 'Создано',
        reject: 'Отклонить',
        rejected: 'Отклонен',
        recover: 'Вернуть',
        approved: 'Одобрить',
        delete: 'Удалить',
        consideration: 'На рассмотрение',
        no_data: 'Отчеты не найдены',
        done: 'Выполнено'
      },
      suite: {
        search: 'Поиск',
        hide_filters: 'Скрыть фильтры',
        show_filters: 'Фильтры',
        reset_filters: 'Сбросить фильтры',
        status: 'Статус',
        all: 'Все',
        dates: 'Даты',
        date_from: 'Дата от',
        date_to: 'Дата до',
        apply: 'Применить',
        km: 'км.'
      },
      popup: {
        points: 'Баллы',
        likes: 'Лаики',
        disputed: 'Оспорено',
        created: 'Создано',
        updated: 'Обновлено',
        deleted: 'Удалено',
        count_of_found: 'Найдено',
        complaints: 'Жалобы'
      },
      errors: {
        enter_at_least_3_characters: 'Введите не менее 3 символов для поиска'
      }
    },
    'services': {
      title: 'Сервисы',
      create: 'Создать сервис',
      edit: 'Редактировать сервис',
      labels: {
        name: 'Название',
        published: 'Опубликовано',
        announcement: 'Анонс',
        description: 'Описание',
        tags: 'Теги',
        add_tags: 'Добавить теги',
        segments: 'Сегменты',
        select_segment: 'Выберите сегмент',
        groups: 'Категории (фильтр)',
        add_groups: 'Добавить категории (фильтр)'

      },
      buttons: {
        create: 'Создать',
        edit: 'Сохранить',
        sort: 'Сортировать'
      },
      table: {
        id: 'ID',
        name: 'Название',
        status: 'Статус',
        announcement: 'Анонс',
        description: 'Описание',
        tags: 'Теги',
        segments: 'Сегменты',
        actions: 'Действия'
      },
      popup: {
        delete_title: 'Сервис "[item]" будет удален?'
      }
    },

    'contentFilters': {
      suite: {
        filters: 'Фильтры',
        hide_filters: 'Скрыть фильтры',
        save_filters: 'Сохранить фильтры'
      },
      tooltip: {
        disabled: 'Редактирование фильтров будет возможно после создания контента'
      },
      notify: {
        saved: 'Сохранено',
        error: 'Ошибка',
        success_text: 'Фильтры контента изменены успешно'
      }
    },

    'notify': {
      errors: {
        'title': 'Ошибкa',
        'error_save': 'Не удалось сохранить изменения, попробуйте ещё раз',
        'no_valid_data': 'Проверье валидность заполненого поля',
        'The given data was invalid.': 'Не удалось сохранить изменения, так как обязательные поля не заполнены или заполнены неверно',
        'error_delete': 'Не удалось удалить запись, попробуйте ещё раз'
      },
      success: {
        'title': 'Успех',
        'success_save': 'Изменения были сохранены',
        'success_delete': 'Запись была удалена'
      }
    },

    'suite': {
      and_exit: 'и выйти'
    },

    'statuses': {
      0: 'Новый',
      10: 'В работе',
      20: 'Требуется информация',
      30: 'Согласование',
      40: 'Завершен',
      50: 'Отменен'
    },


    // Здесь переводы без дублирования в 'en' ///////////////////

    'VMI. Orders': 'ДМС. Заказы',
    'VMI. Catalog': 'ДМС. Каталог',
    'ID': 'ID',
    'E-mail': 'E-mail',
    'Phone': 'Телефон',
    'Name': 'ФИО',
    'Status': 'Статус',
    'Order date': 'Дата оформления',
    'Composition': 'Состав',
    'Additional payment': 'Доп. оплата',
    'Download': 'Скачать',
    'Order': 'Заказ',
    'Policies': 'Полисы',
    'Additional payment is required': 'Требуется доплата',
    'Yes': 'Да',
    'No': 'Нет',
    'Change Status': 'Изменить статус',
    'Select Status': 'Выберите статус',
    'Confirm Action': 'Подтвердите действие',
    'Are you sure you want to change the status of the selected orders to': 'Вы уверены, что хотите изменить статус выбранных заказов на',
    'Are you sure you want to change the status of the selected order to': 'Вы уверены, что хотите изменить статус выбранного заказа на',
    'Are you sure you want to delete the registration code?': 'Вы уверены, что хотите удалить код регистрации?',
    'Confirm': 'Подтвердить',
    'Cancel': 'Отменить',
    'Price': 'Цена',
    'Date from': 'Дата с',
    'Date to': 'Дата по',
    'Upload orders': 'Выгрузить заказы',
    'Registration code': 'Код регистрации',
    'Edit': 'Редактировать',
    'Edit field:': 'Редактировать поле:',
    'Delete': 'Удалить',
    'Create': 'Создать',
    'code': 'Код',
    'email': 'E-mail',
    'coins': 'Коины',
    'is_active': 'Активирован',
    'Save changes': 'Сохранить изменения',
    'Active': 'Активен',
    'Title': 'Название',
    'Subtitle': 'Подзаголовок',
    'Description': 'Описание',
    'Info link': 'Ссылка на информацию',
    'Save': 'Сохранить',
    'Save and exit': 'Сохранить и выйти',
    'Delete a product': 'Удалить продукт',
    'Product deleted': 'Продукт удален',
    'Success': 'Успех',
    'link for the old interface': 'Cсылка для старого интерфейса',
    'Random teams': 'Случайные команды',
    'Number of random team members': 'Кол-во членов рандомной команды',
    'Team Chat': 'Чат команды',
    'Deactivate the user after receiving the email': 'Деактивировать юзера после получения письма',
    'Deactivate after receiving': 'Деактивировать после получения',
    'Automatically substituted variables: name, company.': 'Автоматически подставляемые переменные: name, company.',
    'Inactive days': 'Неактивные дни'
    
  }
}

