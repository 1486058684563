import helpers from '@/helpers/helpers.js'
import moment from 'moment'

export default {
  retrieveSuite (state, suite) {
    state.suite = suite
  },
  retrieveList (state, list) {
    state.list = list
  },
  retrieveEditData (state, editData) {
    state.editData = editData
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  },
  setPointDeletedById (state, id) {
    const index = helpers.searchInArray('id', id, state.points, true)
    state.points[index].deleted_at = moment().format('DD.MM.YYYY HH:mm:ss')
  },
  setStepsDeletedById (state, id) {
    const index = helpers.searchInArray('id', id, state.steps, true)
    state.steps[index].deleted_at = moment().format('DD.MM.YYYY HH:mm:ss')
  },
  setStepsActivatedById (state, id) {
    const index = helpers.searchInArray('id', id, state.steps, true)
    state.steps[index].deleted_at = ''
  },
  retrieveChallenges (state, steps) {
    state.challenges = steps
  },
  setPointActivatedById (state, id) {
    const index = helpers.searchInArray('id', id, state.points, true)
    state.points[index].deleted_at = ''
  },
  retrievePoints (state, points) {

    points.forEach((item) => {
      item.created_at = moment(item.created_at).format('DD.MM.YYYY HH:mm:ss')
      item.deleted_at = item.deleted_at ? moment(item.deleted_at).format('DD.MM.YYYY HH:mm:ss') : ''
    })

    state.points = points
  },
  retrieveCoins (state, coins) {

    coins.forEach((item) => {
      item.created_at = moment(item.created_at).format('DD.MM.YYYY HH:mm:ss')
      item.deleted_at = item.deleted_at ? moment(item.deleted_at).format('DD.MM.YYYY HH:mm:ss') : ''
    })

    state.coins = coins
  },
  retrieveSteps (state, steps) {
    steps.forEach((item) => {
      item.created_at = moment(item.created_at).format('DD.MM.YYYY HH:mm:ss')
      item.deleted_at = item.deleted_at ? moment(item.deleted_at).format('DD.MM.YYYY HH:mm:ss') : ''
    })

    state.steps = steps
  },
  retrieveUserData (state, userData) {
    state.userData = userData
  },
  retrieveEditPointData (state, editData) {
    state.editPointData = editData
  },
  retrieveTrainings (state, trainings) {
    trainings.forEach((item) => {
      item.created_at = moment(item.created_at).format('DD.MM.YYYY HH:mm')
      item.deleted_at = item.deleted_at ? moment(item.deleted_at).format('DD.MM.YYYY HH:mm') : ''
      item.updated_at = item.updated_at ? moment(item.updated_at).format('DD.MM.YYYY HH:mm') : ''
    })
    state.trainings = trainings
  },
  retrieveAdminAccesses (state, adminAccesses) {
    state.adminAccesses = adminAccesses[0]
  },
  retrieveAdminAccessesModules (state, adminAccessesModules) {
    state.adminAccessesModules = adminAccessesModules
  }
}
