import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'
import qs from 'qs'

export default {
  getSuite ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/trainings/moderation', {
        params: payload,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      })
        .then((response) => { 
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  rejectRecoverReport (context, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/trainings/moderation/${payload.id}`, payload)
        .then((response) => {
          context.commit(payload.moderation_status === 'rejected' ? 'retrieveReject' : 'retrieveRecover', payload.id)
          resolve(response)
        })
        .catch((error) => {
          console.log('error', error)
          reject(error)
        })
    })
  },

  getTitles ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/trainings/moderation/titles')
        .then((response) => {
          commit('retrieveTitles', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getStatuses ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/trainings/moderation/statuses')
        .then((response) => {
          commit('retrieveStatuses', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  toggleLike ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/trainings/moderation/${payload.id}/toggle`)
        .then((response) => {
          commit('toggleLike', payload.id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
