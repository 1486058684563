import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'

export default {
  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/activities')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/activities/${payload.id}/edit`, { params: payload })
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  create ({ commit, state }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/activities', formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  update ({ commit }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    formData.append('_method', 'PATCH')
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/activities/${payload.id}`, formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  delete (context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/activities/${payload.id}`)
        .then((response) => {
          context.commit('removeActivityById', payload.id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getActivitiesOptions ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/activities/digest_list')
        .then((response) => {
          commit('retrieveActivitiesOptions', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  sort ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch('/api/admin-panel/activities/sort', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
