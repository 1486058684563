<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <loading v-if="loading"></loading>
      <div class="vx-col w-1/2 mb-base" v-if="!loading">
        <div class="vx-card p-6">
          <vue-tree-list
            v-if="treeData.children && treeData.children?.length"
            :model="treeData"
            default-tree-node-name="Новая категория"
            default-leaf-node-name="Новый продукт"
            v-bind:default-expanded="false"
            @click="onClick"
            @change-name="onChangeName"
            @delete-node="onDel"
            @add-node="onAddNode"
          >
            <template v-slot:leafNameDisplay="slotProps">
              <span>{{ slotProps.model.name }}</span>
            </template>
          </vue-tree-list>
          <div v-else>Нет данных для отображения</div>
        </div>
      </div>
      <div class="vx-col w-1/2 mb-base" v-if="selectedCategory && products?.length">
        <div class="vx-card p-6">
          <div class="flex justify-between">
            <h3>Товары "{{ selectedCategory.name }}"</h3>
            <button @click="addProduct" class="add-product-btn">Добавить продукт</button>
          </div>
          <draggable v-model="products" @end="onDragEnd" class="product-list" draggable="false">
            <transition-group>
              <div v-for="product in products" :key="product.id" @click="onProductClick(product)" class="product-item">
                {{ product.title }}
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
    <!-- Модальное окно подтверждения удаления -->
    <vs-popup :active.sync="showDeleteModal" class="rounded-lg shadow-lg" :title="`Удаление ${deleteItem.isProduct ? 'продукта' : 'категории'}`">
      <div>
        <h2 class="mb-4">Подтверждение удаления</h2>
        <p>Вы уверены, что хотите удалить {{ deleteItem.isProduct ? 'продукт' : 'категорию' }} "{{ deleteItem.name }}"?</p>
        <div class="vx-row mt-6">
          <div class="vx-col">
            <vs-button color="danger" type="filled" class="ml-auto mt-4" @click="confirmDelete">Да, удалить</vs-button>
          </div>
          <div class="vx-col ml-auto">
            <vs-button class="ml-auto mt-4" @click="cancelDelete">Отмена</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { VueTreeList, Tree, TreeNode } from 'vue-tree-list'
import draggable from 'vuedraggable'
import loading from '@/views/components/Loading.vue'

export default {
  name: 'cafeteria-catalog',
  components: {
    VueTreeList,
    loading,
    draggable
  },
  data () {
    return {
      loading: true,
      treeData: new Tree([]),
      selectedCategoryId: null,
      selectedCategory: null,
      products: [],
      product: {},
      showDeleteModal: false,
      deleteItem: {}
    }
  },
  created () {
    this.selectedCategoryId = this.$router.currentRoute.query.categoryId
    this.$store.dispatch('cafeteria/getCatalogTree')
      .then(() => {
        const data = this.$store.getters['cafeteria/catalogTree']
        if (data && (data.data || data)) {
          this.treeData = this.convertToTreeData(data.data || data)
        } else {
          console.warn('No data received from store')
        }
      })
      .catch(error => {
        console.error('Error fetching catalog data:', error)
      })
      .finally(() => {
        this.loading = false
        this.openCategoryById(this.selectedCategoryId)
      })
  },
  mounted () {
    
  },
  methods: {
    convertToTreeData (data) {
      if (!data || typeof data !== 'object') {
        console.warn('Invalid data structure')
        return new Tree([])
      }
      const processedData = Array.isArray(data) ? data : [data]
      return new Tree(processedData.map(item => this.createTreeNode(item)).filter(Boolean))
    },
    createTreeNode (item) {
      if (!item || typeof item !== 'object') {
        console.warn('Invalid item:', item)
        return null
      }

      const node = new TreeNode({
        name: item.title || item.name || 'Untitled',
        id: item.id || Date.now(),
        children: item.children || [],
        products: item.products || [],
        addLeafNodeDisabled: true,
        addTreeNodeDisabled: true,
        delNodeDisabled: true,
        editNodeDisabled: true,
        dragDisabled: true
      })

      if (Array.isArray(item.children)) {
        node.children = item.children.map(child => this.createTreeNode(child)).filter(Boolean)
      }

      return node
    },
    onClick (model) {
      console.log('Node clicked:', model)
      this.selectedCategory = model
      this.products = model.products || []
    },
    onProductClick (product) {
      this.$router.push(`/cafeteria/catalog/product-edit/${product.id}`).catch(() => {})
    },
    onChangeName (model, newName) {
      model.name = newName
      console.log('Node name changed:', model)
    },
    onAddNode (model, name) {
      const newNode = new TreeNode({
        name: name || 'new node',
        id: Date.now(),
        children: [],
        products: []
      })
      model.children.push(newNode)
      console.log('Node added:', newNode)
    },
    onDel (model) {
      this.showDeleteModal = true
      this.deleteItem = {
        name: model.name,
        isProduct: model.isLeaf,
        model: model
      }
    },
    confirmDelete () {
      const parent = this.deleteItem.model.parent
      const index = parent.children.indexOf(this.deleteItem.model)
      if (index > -1) {
        parent.children.splice(index, 1)
        this.showDeleteModal = false
        this.deleteItem = {}
        console.log('Node deleted')
      }
    },
    cancelDelete () {
      this.showDeleteModal = false
      this.deleteItem = {}
    },
    onDragEnd (evt) {
      console.log('Drag ended:', evt)
      console.log('')
      // Здесь можно добавить логику для обновления порядка продуктов на сервере
    },
    addProduct () {
      const data = {
        categoryId: this.selectedCategory.id
      }
      this.$router.push({ name: 'cafeteria-product-create', params: data }).catch(() => {})
    },
    findCategoryById (node, categoryId) {
      if (+node.id === +categoryId) {
        this.products = node.products
        return node
      }

      if (node.children && node.children.length > 0) {
        for (const child of node.children) {
          const found = this.findCategoryById(child, categoryId)
          if (found) {
            return found
          }
        }
      }
      return null
    },
    expandToCategory (node, categoryId) {
      if (+node.id === +categoryId) {
        node.expanded = true
        return true
      }
      if (node.children) {
        for (const child of node.children) {
          if (this.expandToCategory(child, categoryId)) {
            node.expanded = true
            return true
          }
        }
      }
      return false
    },
    openCategoryById (categoryId = null) {
      console.log('categoryId', categoryId)
      const category = this.findCategoryById(this.treeData, categoryId)
      console.log('category', category)
      if (category) {
        this.expandToCategory(this.treeData, categoryId)
        this.selectedCategory = category
      }
    }
  }
}
</script>

<style scoped>
  .vx-card {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .product-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .product-item {
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
  }
  .product-item:hover {
    background-color: #f5f5f5;
    border-color: #bbb;
  }
  .add-product-btn {
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .add-product-btn:hover {
    background-color: #45a049;
  }
</style>

