<!-- =========================================================================================
  File Name: UserEdit.vue
  Description: User Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="tab-edit h-full">

    <div class="vx-row">
      <user-nav-bar :user_id="$route.params.id"></user-nav-bar>
    </div>

    <div id="page-user-edit">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
        <span>User record with id: {{ $route.params.id }} not found. </span>
        <span>
        <span>Check </span><router-link :to="{name:'page-user-list'}" class="text-inherit underline">All Users</router-link>
      </span>
      </vs-alert>

      <loading v-if="loading"></loading>
      <vx-card v-else>
        <div slot="no-body" class="tabs-container px-6 pt-6">

          <div id="user-edit-tab-info">

            <!-- Avatar Row -->
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="flex items-start flex-col sm:flex-row">
                  <img :src="data.avatar_url" class="mr-8 rounded h-24 w-24" />
                  <div>
                    <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">{{ data.email  }}</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Content Row -->
            <div class="vx-row">

              <div class="vx-col md:w-1/2 w-full">

                <div class="mt-8">
                  <label class="vs-input--label">{{ this.$t('users.edit.labels.role') }}</label>
                  <v-select v-model="role_local" :clearable="false" :options="roleOptions" v-validate="'required'" name="role" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
                  <span class="text-danger text-sm"  v-show="errors.has('role')">{{ errors.first('role') }}</span>
                </div>

                <div class="mt-8">
                  <vs-input class="w-full mb-4" :label-placeholder="$t('users.edit.labels.name')" v-model="name" />
                  <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
                </div>

                <div class="mt-8">
                  <vs-input class="w-full mb-4" :label-placeholder="$t('users.edit.labels.email')" v-model="email" />
                  <span class="text-danger text-sm" v-show="formError.has('email')">{{ $t(formError.get('email')) }}</span>
                </div>

                <div class="mt-8">
                  <vs-input class="w-full mb-4" :label-placeholder="$t('users.edit.labels.city')" v-model="city" />
                  <span class="text-danger text-sm" v-show="formError.has('city')">{{ $t(formError.get('city')) }}</span>
                </div>

                <div class="mt-8">
                  <vs-input class="w-full mb-4" :label-placeholder="$t('users.edit.labels.department')" v-model="department" />
                  <span class="text-danger text-sm" v-show="formError.has('department')">{{ $t(formError.get('department')) }}</span>
                </div>

                <div class="mt-8">
                  <vs-input class="w-full mb-4" :label-placeholder="$t('users.edit.labels.phone')" v-model="phone" />
                  <span class="text-danger text-sm" v-show="formError.has('phone')">{{ $t(formError.get('phone')) }}</span>
                </div>

                <div class="mt-8">
                  <vs-input class="w-full mb-4" :label-placeholder="$t('users.edit.labels.social_networks')" v-model="social_networks" />
                  <span class="text-danger text-sm" v-show="formError.has('social_networks')">{{ $t(formError.get('social_networks')) }}</span>
                </div>

                <div class="mt-4">
                  <label class="vs-input--label">{{ this.$t('users.edit.labels.gender') }}</label>
                  <v-select v-model="gender" :reduce="value => value.value" :clearable="false" :options="genderOptions" name="team" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
                  <span class="text-danger text-sm"  v-show="errors.has('gender')">{{ errors.first('gender') }}</span>
                </div>

                <div class="mt-4">
                  <label class="vs-input--label">{{ this.$t('users.edit.labels.team') }}</label>
                  <v-select v-model="team" :reduce="value => value.value" :clearable="false" :options="teamOptions" name="team" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
                  <span class="text-danger text-sm"  v-show="errors.has('team')">{{ errors.first('team') }}</span>
                </div>

                <div class="mt-4">
                  <flat-pickr :config="configdateTimePicker" v-model="dob" :placeholder="$t('users.edit.labels.dob')" :monday-first="($i18n.locale === 'ru') ? true : false" />
                  <span class="text-danger text-sm" v-show="errors.has('dob')">{{ errors.first('dob') }}</span>
                </div>

              </div>
            </div>

            <!-- Save & Reset Button -->
            <div class="vx-row fixed-buttons">
              <div class="vx-col w-full flex gap-3">
                <vs-button v-if="showDeactivateButton" color="danger" type="filled" @click="confirmDeactivateUser()" :disabled="isOnlyRead">{{ this.$t('users.edit.buttons.deactivate_the_user') }}</vs-button>
                <vs-button color="danger" type="filled" @click="confirmDeleteRecord()" :disabled="isOnlyRead">{{ this.$t('users.edit.buttons.delete') }}</vs-button>
                <vs-button @click="update(stayAfter = true)" :disabled="!validateForm || isOnlyRead">{{ this.$t('users.edit.buttons.save') }}</vs-button>
                <vs-button @click="update()" :disabled="!validateForm || isOnlyRead">{{ this.$t('users.edit.buttons.save') }}  {{ $t('suite.and_exit') }}</vs-button>
              </div>
            </div>
          </div>

        </div>

      </vx-card>
    </div>

    <vs-popup class="holamundo" title="" :active.sync="deleteUserPopupActive">
      <p>{{ deleteUserPopupContent }}</p>
      <div v-if="deleteProcess">
        <font-awesome-icon icon="spinner" spin />
      </div>
      <div class="delete-confirm-popup-buttons" v-else>
        <vs-button color="danger" type="filled" @click="deleteUser()">{{ $t('users.suite.popups.confirm_delete_user.buttons.confirm') }}</vs-button>
        <vs-button color="warning" type="filled" @click="deleteUserPopupActive=false">{{ $t('users.suite.popups.confirm_delete_user.buttons.cancel') }}</vs-button>
      </div>
    </vs-popup>
    <vs-popup class="holamundo" title="" :active.sync="deactivateUserPopupActive">
        <p>{{ deactivateUserPopupContent }}</p>
        <div v-if="deactivateProcess">
          <font-awesome-icon icon="spinner" spin />
        </div>
        <div class="delete-confirm-popup-buttons" v-else>
          <vs-button color="danger" type="filled" @click="deactivateUser()">{{ $t('users.suite.popups.confirm_deactivate_user.buttons.confirm') }}</vs-button>
          <vs-button color="warning" type="filled" @click="deactivateUserPopupActive = false">{{ $t('users.suite.popups.confirm_deactivate_user.buttons.cancel') }}</vs-button>
        </div>
      </vs-popup>

  </div>
</template>

<script>
import axios from '@/axios.js'
import vSelect from 'vue-select'
import loading from '@/views/components/Loading.vue'
import UserNavBar from '../components/user/Nav.vue'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import locale from 'flatpickr/dist/l10n/ru'
import helpers from '@/helpers/helpers.js'

import moment from 'moment'

export default {
  components: {
    vSelect,
    flatPickr,
    UserNavBar,
    loading
  },
  data () {
    return {
      id: 0,
      user_not_found: false,
      data: {
        avatar_url: null
      },
      data_local: null,
      name: '',
      email: '',
      city: '',
      department: '',
      team: '',
      teamOptions: [],
      gender: '',
      genderOptions: [
        { label: this.$t('users.edit.genders.male'),  value: 'male' },
        { label: this.$t('users.edit.genders.female'),  value: 'female' }
      ],
      dob: null,
      social_networks: '',
      phone: '',
      loading: true,
      deleteUserPopupActive: false,
      deactivateUserPopupActive: false,
      deleteUserPopupContent: '',
      deactivateUserPopupContent: '',
      deleteProcess: false,
      deactivateProcess: false,
      datepicker: {
      },
      configdateTimePicker: {
        dateFormat: 'Y-m-d',
        locale: locale[this.$i18n.locale]
      }
    }
  },
  computed: {
    roleOptions () {
      const role = this.role_local
      switch (role.value) {
      case 'admin':
        return [{ label: 'Admin', value: 'admin' }]
      case 'athlete':
        return [
          { label: 'Athlete', value: 'athlete' },
          { label: 'Coach', value: 'coach' }
        ]
      case 'coach':
        return [
          { label: 'Coach', value: 'coach' },
          { label: 'Athlete', value: 'athlete' }
        ]
      default:
        return [
          { label: 'Admin', value: 'admin' },
          { label: 'Athlete', value: 'athlete' },
          { label: 'Coach', value: 'coach' }
        ]
      }
    },

    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-users'] === 'read'
      }
    },
    role_local: {
      get () {
        return { label: this.capitalize(this.data_local.role),  value: this.data_local.role  }
      },
      set (obj) {
        this.data_local.role = obj.value
      }
    },
    showDeactivateButton () {
      const protectedRoles = this.data_local.role === 'admin' || this.data_local.role === 'clubadmin'
      const isActiveUser = +this.data_local.id === +this.$store.getters.AppActiveUser.id

      if (this.$store.getters.AppActiveUser.role === 'admin') {
        return !(protectedRoles && !isActiveUser)
      } else return false
    },

    validateForm () {
      return !this.errors.any()
    },
    formError () {
      return this.$store.getters['user/formError']
    }
  },
  methods: {
    fetch_user_data (id) {
      this.$store.dispatch('user/edit', { id })
        .then(res => {
          this.data = JSON.parse(JSON.stringify(res.data.data))
          this.data_local = JSON.parse(JSON.stringify(this.data))
          this.name = this.data.name
          this.email = this.data.email
          this.city = this.data.city
          this.department = this.data.department
          this.team = this.data.team ? this.data.team.id : null
          this.social_networks = this.data.social_networks
          this.phone = this.data.phone
          this.gender = this.data.gender
          this.dob = this.data.dob
          this.loading = false

          if (!this.data.avatar_url || (this.data.avatar_url === axios.defaults.baseURL)) {
            this.data.avatar_url = require('@/assets/images/user/avatar/default.svg')
          }

          const teams = res.data.teams
          teams.forEach((team, i) => {
            this.teamOptions.push({label: teams[i].name, value: teams[i].id})
          })

        })
        .catch(err => {
          if (err.response.status === 404) {
            this.user_not_found = true
            return
          }
          console.error(err)
        })
    },
    capitalize (str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1, str.length)
    },
    update (stayAfter) {
      if (!this.validateForm) return
      this.dispatch('user/update', stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        const payload = {
          id: this.id,
          name: this.name,
          email: this.email,
          city: this.city,
          department: this.department,
          team_id: this.team,
          gender: this.gender,
          phone: this.phone,
          social_networks: this.social_networks,
          dob: moment(this.dob).format('YYYY-MM-DD'),
          role: this.role_local.value
        }

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          type: 'edit',
          stayAfter: stayAfter,
          routerPush: {
            name: 'users'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      }
    },
    confirmDeleteRecord () {
      this.deleteUserPopupActive = true
      this.deleteUserPopupContent = this.$t('users.suite.popups.confirm_delete_user.message').replace('[EMAIL]', this.email)
    },
    confirmDeactivateUser () {
      this.deactivateUserPopupActive = true
      this.deactivateUserPopupContent = this.$t('users.suite.popups.confirm_deactivate_user.message').replace('[EMAIL]', this.email)
    },
    deleteUser () {
      this.deleteProcess = true
      this.$store.dispatch('user/delete', { id: this.id }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.deleteProcess = false
        this.deleteUserPopupActive = false

        this.$nextTick(() => {
          this.$router.push({ name: 'users' })
        })
      })
    },
    deactivateUser () {
      this.deactivateProcess = true
      this.$store.dispatch('user/deactivate', { id: this.id }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.deactivateProcess = false
        this.deactivateUserPopupActive = false

        this.$nextTick(() => {
          this.$router.push({ name: 'users' })
        })
      })
    }
  },

  created () {
    this.id = this.$route.params.id
    this.fetch_user_data(this.id)
    this.$store.commit('user/clearFormError')
  }
}

</script>

<style>
  .tabs-container {
    padding-bottom: 23px;
  }
  .vs-button-filled {
    color: #fff !important;
    margin-left: 15px;
  }
  .delete-confirm-popup-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
</style>
