<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-show="!loading">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col w-full mb-4 flex">
          <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"></vs-switch>
          <label class="ml-4 text-lg" :class="{ 'text-orange': isActive }">{{ $t('categories.create.labels.is_active') }}</label>
          <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active')) }}</span>
        </div>
      </div>

      <content-filter :targetId="id" :targetModel="'category'" style="margin-top: -3em;"></content-filter>

      <vx-card class="mb-6" :title="$t('events.create.labels.accessibility_settings')">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <label>{{ $t('categories.create.labels.publication_at') }}</label>
            <flat-pickr
              class="w-full"
              :config="configPublicationAt"
              v-model="publicationAt"
              :placeholder="$t('categories.create.labels.publication_at')"
            />
            <span class="text-danger text-sm pickatime" v-show="formError.has('publication_at')">{{
              $t(formError.get('publication_at'))
            }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <label>{{ $t('events.create.labels.date_from_required') }}</label>
            <flat-pickr
              class="w-full"
              :config="configToDateDatetimeRegistrationPicker"
              v-model="dateFrom"
              :placeholder="$t('categories.create.labels.date_from')"
              @on-change="onFromChange"
            />
            <span class="text-danger text-sm pickatime" v-show="formError.has('date_from')">{{ $t(formError.get('date_from')) }}</span>
          </div>
        </div>
        <div class="vx-row mt-4">
          <div class="vx-col md:w-1/2 w-full">
            <label>{{ $t('categories.create.labels.date_to_event_required') }}</label>
            <flat-pickr
              class="w-full"
              :config="configTodateTimePicker"
              v-model="dateTo"
              :placeholder="$t('categories.create.labels.date_to_event_required')"
              @on-change="onToChange"
            />
            <span class="text-danger text-sm" v-show="formError.has('date_to')">{{ $t(formError.get('date_to')) }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <label>{{ $t('categories.create.labels.datetime_registration_to') }}</label>
            <flat-pickr
              class="w-full"
              :config="configToDateDatetimeRegistrationPicker"
              v-model="datetimeRegistrationTo"
              :placeholder="$t('categories.create.labels.datetime_registration_to')"

            />
            <span class="text-danger text-sm" v-show="formError.has('datetime_registration_to')">{{ $t(formError.get('datetime_registration_to')) }}</span>
          </div>
        </div>
        <div class="vx-row mt-6">
          <div class="vx-col w-full mb-2">
            <label :class="{'text-orange': isHideTimeAndProhibitAddingInCalendar}">{{ $t('categories.create.labels.event_is_hide_time_and_prohibit_adding_in_calendar') }}</label>
            <vs-switch v-model="isHideTimeAndProhibitAddingInCalendar"
            icon-pack="feather"
            vs-icon="icon-check"></vs-switch>
            <span class="text-danger text-sm" v-show="formError.has('event_is_hide_time_and_prohibit_adding_in_calendar')">{{
              $t(formError.get('event_is_hide_time_and_prohibit_adding_in_calendar'))
            }}</span>
          </div>
        </div>
      </vx-card>
      <vx-card class="mb-6" :title="$t('events.create.labels.event_description')">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('categories.create.labels.name_required') }}</label>
            <vs-input class="w-full" v-model="name" />
            <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('categories.edit.labels.tags') }}</label>
            <v-select v-model="tags" multiple :closeOnSelect="false" :options="tagsOptions" :selectable="option => tags.indexOf(option) < 0" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm" v-show="formError.has('tags')">{{ $t(formError.get('tags')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('events.create.labels.max_quantity_places') }}</label>
            <vs-input
              type="number"
              min="0"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              class="w-full"
              v-model="maxQuantityPlaces"
            />
            <span class="text-danger text-sm" v-show="formError.has('max_quantity_places')">{{
              $t(formError.get('max_quantity_places'))
            }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label>{{ $t('segments.suite.title') }}</label>
            <v-select
              v-model="segments"
              multiple
              :placeholder="$t('segments.suite.title')"
              :closeOnSelect="false"
              :options="segmentsOptions"
              :selectable="option => segments.indexOf(option) < 0"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
            <span class="text-danger text-sm" v-show="formError.has('segments')">{{ $t(formError.get('segments')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t(`events.create.labels.entrance_point`) }}</label>
            <vs-input class="w-full" type="number" v-model="entrancePoint" />
            <span class="text-danger text-sm" v-show="formError.has('entrance_point')">{{ $t(formError.get('entrance_point')) }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t(`events.create.labels.entrance_coin`) }}</label>
            <vs-input class="w-full" type="number" v-model="entranceCoin" />
            <span class="text-danger text-sm" v-show="formError.has('coins_in')">{{ $t(formError.get('coins_in')) }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-4">
            <label>{{ $t('events.create.labels.point_required') }}</label>
            <vs-input class="w-full" type="number" v-model="point" />
            <span class="text-danger text-sm" v-show="formError.has('point')">{{ $t(formError.get('point')) }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>{{ $t(`events.create.labels.coin_required`) }}</label>
            <vs-input class="w-full" type="number" v-model="coin" />
            <span class="text-danger text-sm" v-show="formError.has('coins_finish')">{{ $t(formError.get('coins_finish')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-4">
            <label>{{ $t('categories.edit.labels.clubs') }}</label>
            <v-select v-model="club" :options="clubs" :dir="$vs.rtl ? 'rtl' : 'ltr'" :clearable="false" />
            <span class="text-danger text-sm" v-show="formError.has('club_id')">{{ $t(formError.get('club_id')) }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-textarea class="w-full mb-2" :label="$t('categories.create.labels.announcement')" v-model="announcement" />
            <span class="text-danger text-sm" v-show="formError.has('announcement')">{{ $t(formError.get('announcement')) }}</span>
          </div>
        </div>
        <div class="vx-row" v-if="loadedEdit">
          <div class="vx-col w-full mb-2">
            <vx-card no-shadow card-border :title="$t('categories.edit.labels.block_description')">
              <editor ref="editor" :config="editorJsConfig" />
              <span class="text-danger text-sm" v-show="formError.has('block_description')">{{
                $t(formError.get('block_description'))
              }}</span>
            </vx-card>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-textarea class="w-full mb-2" :label="$t('categories.create.labels.location')" v-model="location" />
            <span class="text-danger text-sm" v-show="formError.has('location')">{{ $t(formError.get('location')) }}</span>
          </div>
        </div>
      </vx-card>

      <div v-if="showTrainings">
        <create-training
          v-for="training in trainings"
          v-bind:key="training.index"
          v-bind:type_exercise="training.type_exercise"
          v-bind:title="training.title"
          v-bind:ref="training.ref"
          v-bind:index="training.index"
          v-bind:dataEdit="training.dataEdit"
          v-bind:noValidPopupActive="noValidPopupActive"
        >
        </create-training>
      </div>

      <div class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button @click="action(stayAfter = true)" :disabled="createButtonDisabled || isOnlyRead">
            {{ $t('categories.create.buttons.save') }}
          </vs-button>
          <vs-button @click="action()" :disabled="createButtonDisabled || isOnlyRead">
            {{ $t('categories.create.buttons.save') }} {{ $t('suite.and_exit') }}
          </vs-button>
        </div>
      </div>
    </div>
    <vs-popup :title="$t('categories.suite.popups.not_valid.title')" :active.sync="noValidPopupActive">
      <p>
        {{ $t('categories.suite.popups.not_valid.message') }}
      </p>
    </vs-popup>

    <vs-popup class="holamundo" title="" :active.sync="ErrorParsePopupActive">
      <p>{{ $t('categories.edit.event.error_parse.content') }}</p>
      <div class="error-parse-button-confirm">
        <vs-button color="warning" type="filled" @click="ErrorParsePopupActive=false">{{ $t('categories.edit.event.error_parse.button_confirm') }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import loading from '@/views/components/Loading.vue'
import helpers from '@/helpers/helpers.js'

import editorJsConfig from '@/editorjs/config.js'
import flatPickr from 'vue-flatpickr-component'
import locale from 'flatpickr/dist/l10n/ru'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import ContentFilter from '@/views/components/contentFilter.vue'
import moment from 'moment'

export default {
  components: {
    PictureInput,
    quillEditor,
    Cropper,
    loading,
    flatPickr,
    ContentFilter,
    'v-select': vSelect,
    'create-training': () => import('./CreateEditTrainingByEvent')
  },
  data () {
    return {
      ErrorParsePopupActive: false,
      noValidPopupActive: false,
      showTrainings: false,
      trainings: [
        {
          ref: 'inputTraining',
          title: this.$t('events.create.labels.input_task'),
          type_exercise: '1',
          dataEdit: []
        },
        {
          ref: 'outputTraining',
          title: this.$t('events.create.labels.output_task'),
          type_exercise: '2',
          dataEdit: []
        }
      ],
      name: '',
      announcement: '',
      location: '',
      description: '',
      maxQuantityPlaces: '',
      loadedEdit: false,
      editorJsConfig: Object.assign({}, editorJsConfig),
      editorOption: {
        formats: ['bold', 'header', 'italic', 'link', 'list', 'blockquote', 'image', 'indent'],
        modules: {
          toolbar: '#toolbar'
        }
      },
      notIncludedToStatistics: false,
      imgCrop: null,
      club: '',
      clubs: [],
      segments: [],
      segmentsOptions: [],
      addNewDataSidebar: false,
      sidebarData: {},
      createButtonDisabled: false,
      period: '',
      isActive: true,
      isHideTimeAndProhibitAddingInCalendar: false,
      isConsistent: false,
      isWithoutMissed: false,
      missedCount: null,
      isShowParticipants: true,
      tags: [],
      tagsOptions: [],
      showByDates: false,
      dontAddPointsIfMissed: false,
      point: 0,
      coin: 0,
      entrancePoint: 0,
      entranceCoin: 0,
      loading: true,
      type: 'create',
      dateFrom: '',
      dateTo: '',
      datetimeRegistrationTo: '',
      configFromdateTimePicker: {
        minDate: null,
        maxDate: null,
        locale: locale[this.$i18n.locale]
      },
      configTodateTimePicker: {
        minDate: null,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        locale: locale[this.$i18n.locale]
      },
      publicationAt: moment().format('YYYY-MM-DD HH:mm'),
      configPublicationAt: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        minDate: null,
        maxDate: null,
        locale: locale[this.$i18n.locale]
      },
      configToDateDatetimeRegistrationPicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        minDate: null,
        locale: locale[this.$i18n.locale]
      },
      // Всё по заданиям
      isActiveInputTask: true,
      isActiveOutputTask: true,
      editDataInputTraining: [],
      editDataOutputTraining: []
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-events'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['category/formError']
    },
    isValidTraining () {
      const inputTraining = this.getPayloadInputTraining()
      const outputTraining = this.getPayloadOutputTraining()

      const isInputActiveEmpty = inputTraining.is_active && inputTraining.exercises.length === 0
      const isOutputActiveEmpty = outputTraining.is_active && outputTraining.exercises.length === 0

      if (isInputActiveEmpty || isOutputActiveEmpty) {
        return false
      }

      if (this.type === 'edit') {
        const isEditInputActiveEmpty = inputTraining.is_active && this.editDataInputTraining.exercises.length === 0
        const isEditOutputActiveEmpty = outputTraining.is_active && this.editDataOutputTraining.exercises.length === 0

        if (isEditInputActiveEmpty || isEditOutputActiveEmpty) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    action (stayAfter) {
      if (!this.isValidTraining) {
        this.noValidPopupActive = true
        return
      }

      this.loading = !stayAfter
      this.createButtonDisabled = true

      const patch = (this.type === 'create') ? 'category/createEvent' : 'category/updateEvent'
      this.dispatch(patch, stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        const blockDescription = await this.$refs.editor.state.editor.save()

        const payload = {
          id: this.id,
          is_active: +this.isActive,
          publication_at: this.publicationAt,
          is_hide_time_and_prohibit_adding_in_calendar: +this.isHideTimeAndProhibitAddingInCalendar,
          date_from: this.dateFrom,
          datetime_registration_to: this.datetimeRegistrationTo,
          name: this.name,
          tags: this.tags,
          segments: this.segments,
          point: this.point,
          coins_finish: this.coin,
          entrance_point: this.entrancePoint,
          coins_in: this.entranceCoin,
          club_id: this.club.id,
          date_to: this.dateTo,
          announcement: this.announcement,
          max_quantity_places: this.maxQuantityPlaces,
          is_show_participants: +this.isShowParticipants,
          location: this.location,
          description: this.description,
          block_description: JSON.stringify(blockDescription),
          type: 'event',
          trainings: [this.getPayloadInputTraining(), this.getPayloadOutputTraining()]
        }

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name:'events'
          },
          routerPushToEdit: {name: 'edit-event'}
        }
        console.log('payload', payload)
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.createButtonDisabled = false
        this.loading = false
      }
    },
    onFromChange (selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, 'minDate', dateStr)
    },
    onToChange (selectedDates, dateStr, instance) {
      this.$set(this.configToDateDatetimeRegistrationPicker, 'maxDate', dateStr)
    },
    getPayloadInputTraining () {
      const training = this.$refs.inputTraining[0].getPayload()
      training.priority = 0
      training.name = 'Входное задание'
      training.description = ''
      training.cancel_gender = 1
      return training
    },
    getPayloadOutputTraining () {
      const training = this.$refs.outputTraining[0].getPayload()
      training.priority = 1
      training.description = ''
      training.name = 'Выходное задание'
      training.cancel_gender = 1
      return training
    },
    addNewData () {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    deleteData (index) {
      this.$store.dispatch('training/removeExercise', { index: index }).catch((err) => {})
    },
    editData (data, index) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      data.index = index
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    addClubs (setClubField = true) {
      this.$store.dispatch('club/list').then(() => {
        this.$store.dispatch('club/addDefaultClub').then(() => {
          this.clubs = this.$store.getters['club/list']
          if (setClubField) {
            this.club = this.clubs[0]
          }
        })
        console.log(this.$store.getters['club/list'])
      })
    }
  },
  beforeMount () {
    this.id = this.$route.params.id
    if (this.$router.currentRoute.name === 'edit-event') {
      //Сбрасываем все поля упражнений, т.к мы их перезапишем ниже
      this.$store.commit('training/resetExercises')
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('category/editEvent', { id: this.id }).then(() => {
        const edit = this.$store.getters['category/edit']
        this.description = edit.description
        this.blockDescription = edit.blockDescription
        if (this.blockDescription !== '') {
          try {
            this.editorJsConfig.data = JSON.parse(edit.blockDescription)
          } catch (e) {
            this.ErrorParsePopupActive = true
            this.editorJsConfig.data = ''
          }
        }
        this.isActive = edit.isActive
        this.isHideTimeAndProhibitAddingInCalendar = edit.isHideTimeAndProhibitAddingInCalendar
        this.publicationAt = edit.publicationAt
        this.dateFrom = edit.dateFrom
        this.datetimeRegistrationTo = edit.datetimeRegistrationTo
        this.name = edit.name
        this.point = edit.point
        this.coin = edit.coinsFinish
        this.entrancePoint = edit.entrancePoint
        this.entranceCoin = edit.coinsIn
        this.tags = edit.tags
        this.tags.map((tag) => {
          tag.id = tag.value
        })
        this.segments = edit.segments
        this.dateTo = edit.dateTo
        this.announcement = edit.announcement
        this.location = edit.location
        this.isShowParticipants = edit.isShowParticipants
        this.description = edit.description
        this.maxQuantityPlaces = edit.maxQuantityPlaces
        this.showTrainings = true
        this.editDataInputTraining = edit.trainings[0]
        this.editDataOutputTraining = edit.trainings[1]
        this.trainings[0].dataEdit = this.editDataInputTraining
        this.trainings[1].dataEdit = this.editDataOutputTraining
        this.loadedEdit = true
        this.club = {
          id: edit.club.id,
          label: edit.club.name
        }
        this.addClubs(false)
        if (edit.club === '') {
          this.addClubs()
        } else {
          this.addClubs(false)
        }
      })
    } else {
      this.showTrainings = true
      this.loadedEdit = true
      this.addClubs()
    }
  },
  created () {
    this.$store.dispatch('tag/getTagsOptions').then(() => {
      this.tagsOptions = this.$store.getters['tag/tagsOptions']
    })

    this.$store.dispatch('segment/getSegmentsOptions').then(() => {
      this.segmentsOptions = this.$store.getters['segment/segmentsOptions']
    })

    this.$store.commit('category/clearFormError')
    this.loading = false
  }
}
</script>

