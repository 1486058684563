import state from './moduleShopOrderState.js'
import mutations from './moduleShopOrderMutations.js'
import actions from './moduleShopOrderActions.js'
import getters from './moduleShopOrderGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
