<template>
  <div>
    {{ this.params.data.deleted_at }}
  </div>
</template>

<script>

export default {
  name: 'DeletedAt'
}
</script>
