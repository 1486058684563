import FormError from '../../modules/formError'

export default {
  suite: [],
  trainings: [],
  formError: new FormError(),
  edit: {
    name: '',
    description: ''
  }
}
