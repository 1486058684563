export default {
  suite (state) {
    return state.suite
  },
  statuses (state) {
    return state.statuses
  },
  formError (state) {
    return state.formError
  }
}
