<template>
    <div id="demo-basic-card" class="vx-card tabs-container px-6 pt-6">
        <div class="vx-row">
              <div class="vx-col w-4/5 mb-2">
                <label>{{ $t('gamification.raffle.labels.seed') }}</label>
                <vs-input class="w-full" :label-placeholder="$t('gamification.raffle.labels.seed')" @change="onFromChange(0)"
                          v-model="seed"/>
                <span class="text-danger text-sm" v-show="formError.has('seed')">{{
                    $t(formError.get('seed'))
                  }}</span>
              </div>
              <div class="vx-col w-1/5 mb-2">
                <label>{{ $t('gamification.raffle.labels.reroll') }}</label>
                <vs-button color="primary" type="border" icon-pack="feather" icon="icon-refresh-ccw" style="margin-top: 17px;" @click="regenerateSeed" :disabled="isOnlyRead"></vs-button>
              </div>
            <div class="vx-col w-full mb-2">
              <label>{{ $t('gamification.raffle.labels.start') }}</label>
              <flat-pickr class="w-full" :config="configStartPicker" v-model="start"
                          :placeholder="$t('gamification.raffle.labels.start')" @on-close="onFromChange(0)"/>
              <span class="text-danger text-sm pickatime"
                    v-show="formError.has('start')">{{ $t(formError.get('start')) }}</span>
            </div>
            <div class="vx-col w-full mb-2">
              <label>{{ $t('gamification.raffle.labels.end') }}</label>
              <flat-pickr class="w-full" :config="configEndPicker" v-model="end"
                          :placeholder="$t('gamification.raffle.labels.end')" :monday-first="($i18n.locale === 'ru') ? true : false"  @on-change="onFromChange(0)"/>
              <span class="text-danger text-sm pickatime"
                    v-show="formError.has('end')">{{ $t(formError.get('end')) }}</span>
            </div>
            <div class="vx-col w-full mb-2">
              <label>{{ $t('gamification.raffle.labels.moreResultsMoreChances') }}</label>
              <vs-switch v-model="moreResultsMoreChances" icon-pack="feather" vs-icon="icon-check" @change="onFromChange($event)"> </vs-switch>
              <span class="text-danger text-sm pickatime"
                    v-show="formError.has('moreResultsMoreChances')">{{ $t(formError.get('moreResultsMoreChances')) }}</span>
            </div>
            <div class="vx-col w-full mb-base">
                <loading v-if="loading"></loading>
                <vs-table max-items="20" pagination :data="participaints" v-else>

                    <template slot="thead">
                        <vs-th>{{ $t('gamification.raffle.table.user_id') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.name') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.email') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.id') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.type') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.source_type') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.date') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.role') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.avatar_url') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.district') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.region') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.city') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.department') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.optional') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.gender') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.dob') }}</vs-th>
                        <vs-th>{{ $t('gamification.raffle.table.phone') }}</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data" :style="{ background: tr.id === winner.id ? '#90EE90' : '#fff' }">
                          <vs-td :data="data[indextr].user.id">
                              {{ data[indextr].user.id }}
                          </vs-td>
                          <vs-td :data="data[indextr].user.name">
                              {{ data[indextr].user.name }}
                          </vs-td>
                          <vs-td :data="data[indextr].user.email">
                              {{ data[indextr].user.email }}
                          </vs-td>
                          <vs-td :data="data[indextr].id">
                            {{ data[indextr].id }}
                          </vs-td>
                          <vs-td :data="data[indextr].type">
                            {{ data[indextr].type }}
                          </vs-td>
                          <vs-td :data="data[indextr].source_type">
                            {{ data[indextr].source_type }}
                          </vs-td>
                          <vs-td :data="data[indextr].created_at">
                            {{ data[indextr].created_at }}
                          </vs-td>
                          <vs-td :data="data[indextr].user.role">
                            {{ data[indextr].user.role }}
                          </vs-td>
                          <vs-td :data="data[indextr].user.avatar_path">
                            {{ data[indextr].user.avatar_path }}
                          </vs-td>
                          <vs-td :data="data[indextr].user.athlete.region ? data[indextr].user.athlete.region.federal_district : null">
                            {{ data[indextr].user.athlete.region ? data[indextr].user.athlete.region.federal_district : null }}
                          </vs-td>
                          <vs-td :data="data[indextr].user.athlete.region ? data[indextr].user.athlete.region.name_with_type : null">
                            {{ data[indextr].user.athlete.region ? data[indextr].user.athlete.region.name_with_type : null }}
                          </vs-td>
                          <vs-td :data="data[indextr].user.athlete.city">
                            {{ data[indextr].user.athlete.city }}
                          </vs-td>
                          <vs-td :data="data[indextr].user.athlete.department">
                            {{ data[indextr].user.athlete.department }}
                          </vs-td>
                          <vs-td :data="data[indextr].user.athlete.optional">
                            {{ data[indextr].user.athlete.optional }}
                          </vs-td>
                          <vs-td :data="data[indextr].user.athlete.gender">
                            {{ data[indextr].user.athlete.gender }}
                          </vs-td>
                          <vs-td :data="data[indextr].user.athlete.dob">
                            {{ data[indextr].user.athlete.dob }}
                          </vs-td>
                          <vs-td :data="data[indextr].user.athlete.phone">
                            {{ data[indextr].user.athlete.phone }}
                          </vs-td>
                        </vs-tr>
                    </template>

                </vs-table>
            </div>
        </div>

    </div>
</template>

<script>
import loading from '@/views/components/Loading.vue'
import flatPickr from 'vue-flatpickr-component'
import locale from 'flatpickr/dist/l10n/ru'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'

export default{
  components: {
    loading,
    flatPickr
  },
  data () {
    return {
      loading: true,
      seed: Math.random(),
      start: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm'),
      end: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm'),
      moreResultsMoreChances: true,
      participaints: [],
      winner: [],
      configStartPicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        minDate: null,
        maxDate: null,
        locale: locale[this.$i18n.locale]
      },
      configEndPicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        minDate: null,
        locale: locale[this.$i18n.locale]
      }
    }
  },
  methods: {
    regenerateSeed () {
      this.seed = Math.random()
      this.onFromChange(0)
    },
    onFromChange (event) {
      if (event) {
        if (event.type === 'input') {
          return
        }
      }
      this.loading = true
      const payload = {
        seed: this.seed,
        start: this.start,
        end: this.end,
        moreResultsMoreChances: this.moreResultsMoreChances ? 1 : 0
      }
      this.$store.dispatch('gamification/getRoll', payload).then(() => {
        const roll = this.$store.getters['gamification/roll']
        this.winner = roll.winner
        this.participaints = roll.tickets
        this.loading = false
      })
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-raffle'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['gamification/formError']
    }
  },
  created () {
    this.onFromChange(0)
  }
}
</script>

<style lang="scss" scoped>
    .delete-confirm-popup-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }
    .delete-popup-wait-message {
        margin-top: 30px;
    }
    .sort-table-wrapper {
        width: 100%;
    }
    .list-item {
        transition: all 1s;
    }
    .list-item:hover {
        cursor: all-scroll;
    }
</style>
