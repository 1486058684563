<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-show="!loading">
      <vx-card class="mb-6" :title="title" v-show="!loading">
        <div class="vx-row">
          <div class="vx-col w-full mb-4 flex">
            <vs-switch v-model="isActive" icon-pack="feather" vs-icon="icon-check"></vs-switch>
            <label class="ml-4 text-lg" :class="{ 'text-orange': isActive }">{{ $t('categories.create.labels.is_active') }}</label>
            <span class="text-danger text-sm" v-show="formError.has('is_active')">{{ $t(formError.get('is_active')) }}</span>
          </div>
        </div>
        <div class="vx-row" v-show="isActive">
          <div class="vx-col w-full">
            <vs-textarea class="w-full mb-2" :label="$t('categories.create.labels.event_description')" v-model="eventDescription" />
            <span class="text-danger text-sm" v-show="formError.has('event_description')">{{
              $t(formError.get('event_description'))
            }}</span>
          </div>
        </div>

        <div class="vx-row" v-show="isActive">
          <div class="vx-col w-full mb-2">
            <data-view-sidebar
              :type_exercise="this.type_exercise"
              :type="'task'"
              :isSidebarActive="addNewDataSidebar"
              @closeSidebar="toggleDataSidebar"
              :data="sidebarData"
            />

            <vs-table ref="table" :data="exercises">
              <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
                <div class="flex flex-wrap-reverse items-center data-list-btn-container">
                  <!-- ADD NEW -->
                  <div
                    class="
                      btn-add-new
                      p-3
                      mb-4
                      mr-4
                      rounded-lg
                      cursor-pointer
                      flex
                      items-center
                      justify-center
                      font-medium
                      text-lg text-primary
                      border-solid border-primary
                    "
                    v-bind:class="classNoValid"
                    @click="addNewData"
                  >
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                    <span class="ml-2 text-base text-primary">{{ $t('trainings.create.buttons.add_task') }}</span>
                  </div>
                </div>
              </div>

              <template slot="thead">
                <vs-th sort-key="name">{{ $t('exercises.suite.table.name_task_event') }}</vs-th>
                <vs-th sort-key="category">{{ $t('exercises.suite.table.result_type') }}</vs-th>
                <vs-th sort-key="is_required">{{ $t('exercises.suite.table.is_required') }}</vs-th>
                <vs-th>{{ $t('exercises.suite.table.actions') }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                      <p class="product-name font-medium truncate">{{ tr.name }}</p>
                    </vs-td>

                    <vs-td>
                      <p class="product-category">{{ $t(`trainings.exercises.labels.${tr.result_type}`) }}</p>
                    </vs-td>

                    <vs-td>
                      <p class="product-is-required">{{ tr.is_required ? '+' : '' }}</p>
                    </vs-td>

                    <vs-td class="whitespace-no-wrap">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="w-5 h-5 hover:text-primary stroke-current"
                        @click.stop="editData(tr, indextr)"
                      />
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="ml-2"
                        @click.stop="deleteData(indextr)"
                      />
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
    <vs-popup class="holamundo" :title="$t('trainings.edit.popup.title')" :active.sync="popupActive">
      <p>{{ $t('trainings.edit.popup_event.message') }}</p>
    </vs-popup>
    <vs-popup class="holamundo" title="Image error" :active.sync="imageErrorPopupActive">
      <p>{{ imageErrorPopupMessage }}</p>
    </vs-popup>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import PictureInput from 'vue-picture-input'
import Datepicker from 'vuejs-datepicker'
import { en, ru } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import DataViewSidebar from '../../components/DataViewSidebar.vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import helpers from '@/helpers/helpers.js'
import editorJsConfig from '@/editorjs/config.js'
import loading from '@/views/components/Loading.vue'
import { mask } from 'vue-the-mask'

import vSelect from 'vue-select'

export default {
  props: ['title', 'dataEdit', 'type_exercise', 'noValidPopupActive'],
  components: {
    PictureInput,
    quillEditor,
    Datepicker,
    DataViewSidebar,
    Cropper,
    loading,
    'v-select': vSelect
  },
  directives: {
    mask
  },
  data () {
    return {
      isActive: false,
      // categoryId: 0,
      name: '',
      announcement: '',
      // exercises: [],
      description: '',
      eventDescription: '',
      image: null,
      imgCrop: null,
      date: helpers.today('-'),
      format: 'dd.MM.yyyy',
      cancelGender: true,
      point: 0,
      youtubeUrl: 'https://www.youtube.com/',
      videoLink: '',
      photoRequired: false,
      photoRequiredDescription: '',
      commentRequired: false,
      commentRequiredDescription: '',
      requiredViewingTime: null,
      isMounted: false,
      tags: [],
      tagsOptions: [],

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},

      popupActive: false,
      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error',
      editorJsConfig1: Object.assign({}, editorJsConfig),
      editorOption: {
        modules: {
          toolbar: '#toolbar'
        }
      },
      createButtonDisabled: false,
      datepicker: {
        locales: {
          en,
          ru
        }
      },
      loading: true,
      type: 'programm',
      editExercises: false
    }
  },
  computed: {
    classNoValid: function () {
      return {
        'border-4': !this.exercises.length && this.noValidPopupActive,
        'border-danger': !this.exercises.length && this.noValidPopupActive
      }
    },
    exercises () {
      return this.$store.getters['training/exercises'.concat(this.type_exercise)]
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.exercises.length
    },
    formError () {
      return this.$store.getters['training/formError']
    }
  },
  methods: {
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },
    create () {
      this.createButtonDisabled = true
      const payload = {
        is_active: this.isActive,
        event_description: this.eventDescription,
        point: this.point,

        category_id: this.categoryId,
        name: this.name,
        announcement: this.announcement,
        description: this.description,
        image:
          typeof this.image === 'string' ? this.image : this.image !== null ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : '',
        date: moment(this.date).format('DD.MM.YYYY'),
        cancel_gender: +this.cancelGender,
        tags: this.tags,
        video_link: this.youtubeUrl + this.videoLink,
        photo_required: +this.photoRequired,
        photo_required_description: this.photoRequiredDescription,
        comment_required: +this.commentRequired,
        comment_required_description: this.commentRequiredDescription,
        required_viewing_time: this.requiredViewingTime !== null ? this.requiredViewingTime : ''
      }
      this.createButtonDisabled = false
      this.$store
        .dispatch('training/create', payload)
        .then(() => {
          this.$router.push({
            name: `${this.type}-trainings`,
            params: { categoryId: this.categoryId }
          })
        })
        .catch(() => {
          this.createButtonDisabled = false
        })
    },
    getPayload () {
      let exercises = this.exercises

      if (this.$router.currentRoute.name === 'edit-event') {
        exercises = {
          create: this.$store.getters['training/exercisesCreate'.concat(this.type_exercise)],
          update: this.$store.getters['training/exercisesUpdate'.concat(this.type_exercise)],
          delete: this.$store.getters['training/exercisesDelete'.concat(this.type_exercise)]
        }
      }

      return {
        is_active: this.isActive ? 1 : 0,
        event_description: this.eventDescription,
        point: this.point,
        exercises: exercises
      }
    },
    addNewData () {
      if (this.hasResults) {
        this.popupActive = true
        return
      }
      this.sidebarData = {}
      // this.sidebarData.index = this.index
      this.toggleDataSidebar(true)
    },
    deleteData (index) {
      if (this.hasResults) {
        this.popupActive = true
        return
      }
      this.$store
        .dispatch('training/removeExercise', { index: index, type_exercise: this.type_exercise }, this.type_exercise)
        .catch((err) => {})
    },
    editData (data, index) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      data.index = index
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    getOrderStatusColor (status) {
      if (status === 'on_hold') return 'warning'
      if (status === 'delivered') return 'success'
      if (status === 'canceled') return 'danger'
      return 'primary'
    },
    getPopularityColor (num) {
      if (num > 90) return 'success'
      if (num > 70) return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50) return 'danger'
      return 'primary'
    },
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    }
  },
  created () {
    this.id = this.$route.params.id
    if (this.$router.currentRoute.name === 'edit-event') {
      const edit = this.dataEdit
      console.log(edit)
      this.isActive = edit.is_active
      this.eventDescription = edit.event_description
      this.point = edit.point

      edit.exercises.forEach(function callback (currentValue, index, exercise) {
        exercise.forEach(function callback (currentValue, index, exercise) {
          if (currentValue.training_exercises_results.length > 0) {
            console.log('results exists')
            this.hasResults = true
          }
        }, this)
      }, this)

      this.$store.commit('training/setExercises'.concat(this.type_exercise), edit.exercises)
    } else {
      this.type = 'event'
      this.$store.commit('training/clearFormError')
      this.categoryId = this.$route.params.categoryId
      this.$store.commit('training/resetExercises')
    }

    this.loading = false

    this.$store.dispatch('tag/getTagsOptions').then(() => {
      this.tagsOptions = this.$store.getters['tag/tagsOptions']
    })
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
.image-container .preview-container {
  margin: 0 !important;
}

.btn-add-new {
  border-width: 1px;
}

#data-list-list-view {
  .vs-con-table {
    /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
