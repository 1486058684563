/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import moduleAuth from './auth/moduleAuth.js'
import moduleCategory from './category/moduleCategory.js'
import moduleTraining from './training/moduleTraining.js'
import moduleUser from './user/moduleUser.js'
import moduleSegment from './segment/moduleSegment.js'
import moduleContentPage from './content-page/moduleContentPage'
import moduleClub from './club/moduleClub'
import moduleEditorjs from './editorjs/moduleEditorjs'
import moduleDashboard from './dashboard/moduleDashboard'
import modulePedometerChallenge from './pedometer/challenge/moduleChallenge'
import moduleCommonGoals from './common-goals/moduleCommonGoals'
import moduleBlogPost from './blog/post/modulePost'
import moduleAchievement from './achievement/moduleAchievement'
import moduleGamification from './gamification/moduleGamification'
import moduleNotification from './notification/moduleNotification'
import moduleMailing from './mailing/moduleMailing'
import moduleAnnouncement from './announcement/moduleAnnouncement'
import moduleActivity from './activity/moduleActivity'
import moduleTeam from './team/moduleTeam'
import modulePointSetting from './point-setting/modulePointSetting'
import moduleTag from './tag/moduleTag'
import moduleShopOrder from './shop-order/moduleShopOrder'
import moduleShopProduct from './shop-product/moduleShopProduct'
import moduleShopCategories from './shop-categories/moduleShopCategories'
import moduleShopSettings from './shop-settings/moduleShopSettings'
import moduleLabel from './label/moduleLabel'
import moduleRegistration from './registration/moduleRegistration'
import moduleChatMessages from './chat-messages/moduleChatMessages'
import moduleFaq from './FAQ/moduleFaq'
import moduleMission from './mission/moduleMission'
import moduleRelevant from './relevant/moduleRelevant'
import moduleStatistics from './statistics/moduleStatistics'
import moduleReports from './reports/moduleReports'
import moduleServices from './services/moduleServices'
import moduleGeocaching from './geocaching/moduleGeocaching'
import moduleContentFilter from './content-filter/moduleContentFilter'
import moduleCafeteria from './cafeteria/moduleCafeteria.js'

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    category: moduleCategory,
    training: moduleTraining,
    user: moduleUser,
    segment: moduleSegment,
    content_page: moduleContentPage,
    club: moduleClub,
    editorjs: moduleEditorjs,
    dashboard: moduleDashboard,
    pedometer_challenge: modulePedometerChallenge,
    common_goals: moduleCommonGoals,
    blog_post: moduleBlogPost,
    achievement: moduleAchievement,
    gamification: moduleGamification,
    notification: moduleNotification,
    mailing: moduleMailing,
    announcement: moduleAnnouncement,
    activity: moduleActivity,
    team: moduleTeam,
    point_setting: modulePointSetting,
    tag: moduleTag,
    label: moduleLabel,
    shop_order: moduleShopOrder,
    shop_product: moduleShopProduct,
    shop_categories: moduleShopCategories,
    shop_settings: moduleShopSettings,
    registration: moduleRegistration,
    chat_messages: moduleChatMessages,
    faq: moduleFaq,
    mission: moduleMission,
    relevant: moduleRelevant,
    statistics: moduleStatistics,
    reports: moduleReports,
    services: moduleServices,
    geocaching: moduleGeocaching,
    contentFilter: moduleContentFilter,
    cafeteria: moduleCafeteria

  },
  strict: (process.env.NODE_ENV !== 'production')
})
