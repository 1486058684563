<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

  <div id="page-user-list">
    <div class="upload-excel-users-container">
      <div class="excel-file-buttons-container">
         <vs-button v-if="!isOnlyRead" color="primary" type="gradient" icon-pack="feather" icon="icon-plus" class="add-button" @click="$router.push({ name: 'create-user'})">{{ $t('users.suite.buttons.add_admin')}}</vs-button>
        <!--
        <vs-button color="primary" type="filled" @click="$refs.file.click()">{{ $t('users.suite.buttons.upload_file') }} <font-awesome-icon :icon="['fas', 'file-excel']" /></vs-button>
        -->

        <flat-pickr :config="configdateTimePicker" v-model="date_since" :placeholder="$t('users.suite.buttons.download_date_since')" :monday-first="($i18n.locale === 'ru') ? true : false" />
        <flat-pickr :config="configdateTimePicker" v-model="date_until" :placeholder="$t('users.suite.buttons.download_date_until')" :monday-first="($i18n.locale === 'ru') ? true : false" />

        <vs-button color="primary" type="filled" @click="downloadUsersFileTrainingResults">{{ $t('users.suite.buttons.download_file_training_results') }}  <font-awesome-icon class="mr-50" :icon="['fas', 'download']" /></vs-button>
        <vs-button color="primary" type="filled" @click="downloadUsersFileLevels">{{ $t('users.suite.buttons.download_file_levels') }}  <font-awesome-icon class="mr-50" :icon="['fas', 'download']" /></vs-button>
        <input type="file" ref="file" style="display: none" @change="previewFile">
      </div>
      <vs-alert :active.sync="showFileName" closable icon-pack="feather" close-icon="icon-x">
        {{ fileName }}
      </vs-alert>
      <div class="demo-alignment" v-if="showUploadButtons">
        <vs-button color="success" type="filled" icon-pack="feather" icon="icon-upload" @click="uploadUsersFile('add')"></vs-button>
        <vs-button color="danger" type="filled" icon-pack="feather" icon="icon-upload" @click="uploadUsersFile('confirm-delete')"></vs-button>
      </div>
      <div v-if="uploading">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>
    <loading v-if="loading"></loading>
    <div class="vx-col w-full mb-base" v-if="!loading">
      <div class="vx-card p-6">
        <!-- AgGrid Table -->
        <ag-grid-vue
          @gridReady="onGridReady"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4"
          domLayout="autoHeight"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="suite"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
        />

        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage" />

      </div>
    </div>
    <vs-popup class="holamundo" :title="deletePopupTitle" :active.sync="deletePopupActive">
      <p>{{ deletePopupContent }}</p>
      <div class="delete-confirm-popup-buttons">
        <vs-button color="primary" type="filled" @click="uploadUsersFile('delete')">{{ $t('users.suite.popups.confirm_delete.buttons.confirm') }}</vs-button>
        <vs-button color="warning" type="filled" @click="deletePopupActive=false">{{ $t('users.suite.popups.confirm_delete.buttons.cancel') }}</vs-button>
      </div>
    </vs-popup>
  </div>

</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import locale from 'flatpickr/dist/l10n/ru'

// Cell Renderer
import CellRendererLink from '../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../components/cell-renderer/users/CellRendererActions.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'


library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)

export default {
  components: {
    AgGridVue,
    flatPickr,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,

    loading
  },
  data () {
    return {
      // AgGrid
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: { 'textAlign': 'center'},
        headerClass: 'text-center'
      },
      columnDefs: [
        {
          headerName: this.$t('users.suite.table.avatar'),
          field: 'avatar_url',
          minWidth: 70,
          maxWidth: 100,
          cellRenderer: 'CellRendererLink'
        },
        {
          headerName: this.$t('users.suite.table.email'),
          field: 'email',
          minWidth: 180,
          maxWidth: 400,
          flex: 2,
          cellStyle: {'text-align': 'left'},
          headerClass: 'text-left',
          filter: true
        },
        {
          headerName: this.$t('users.suite.table.name'),
          field: 'name',
          filter: true,
          minWidth: 180,
          maxWidth: 400,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 24px'},
          headerClass: 'text-left'
        },
        {
          headerName: this.$t('users.suite.table.role'),
          field: 'role',
          minWidth: 130,
          maxWidth: 170,
          sortable: true
        },
        {
          headerName: this.$t('users.suite.table.trainings_results_count'),
          field: 'trainings_results_count',
          minWidth: 100,
          maxWidth: 130,
          sortable: true
        },
        {
          headerName: this.$t('users.suite.table.all_point'),
          field: 'all_point',
          minWidth: 100,
          maxWidth: 130,
          sortable: true
        },
        {
          headerName: this.$t('users.suite.table.actions'),
          field: 'id',
          minWidth: 110,
          maxWidth: 130,
          cellStyle: {'text-align': 'left'},
          headerClass: 'text-left',
          cellRenderer: 'CellRendererActions'
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deletePopupActive: false,
      deletePopupTitle: '',
      showFileName: false,
      fileName: '',
      file: null,
      showUploadButtons: false,
      deletePopupContent: '',
      date_since: null,
      date_until: null,
      dateFormat: 'dd.MM.yyyy H:i:s',
      datepicker: {
      },
      configdateTimePicker: {
        enableTime: false,
        dateFormat: 'd.m.Y',
        locale: locale[this.$i18n.locale]
      },
      uploading: false,
      loading: true
    }
  },
  watch: {
    showFileName (val) {
      if (val === false) {
        this.showUploadButtons = false
      }
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-create-admin'] === 'read'
      }
    },

    suite () {
      return this.$store.getters['user/suite'] 
    },
  
    paginationPageSize () {
      return this.gridApi?.paginationGetPageSize() || 30
    },
    totalPages () {
      return this.gridApi?.paginationGetTotalPages() || 1
    },
    currentPage: {
      get () {
        return this.gridApi?.paginationGetCurrentPage() + 1 || 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },
  methods: {

    onGridReady (params) {
      this.gridApi = params.api
    },

    getErrorString (data) {
      const rows = []
      if (data.hasOwnProperty('errors')) {
        const errors = data.errors
        let i = 0
        for (const prop in errors) {
          if (prop.includes('emails.')) {
            rows[i] = +prop.replace('emails.', '') + 1
          }
          i++
        }
      }
      return rows.length ? (this.$t('users.suite.errors.emails') + rows.join(', ')) : ''
    },
    uploadUsersFile (type) {
      this.uploading = true
      switch (type) {
      case 'add':
        this.$store.dispatch('user/uploadAddExcelFile', { file: this.file }).then(response => {
          this.$vs.notify({
            title: this.$t('users.suite.notifications.succsess_created_title'),
            text:  this.$t('users.suite.notifications.succsess_created_message').replace('[COUNT]', response.data.data.count),
            color: 'success',
            time: 10000
          })
          this.showFileName = this.showUploadButtons = false
        }).catch(error => {
          const errorString = this.getErrorString(error.response.data)
          this.$vs.notify({
            title: this.$t('users.suite.errors.title'),
            text: (errorString === '') ? error : errorString,
            color: 'danger',
            time: 10000
          })
        }).then(() => {
          this.$store.dispatch('user/getSuite').then(() => {
            this.uploading = false
          })
        })
        break
      case 'confirm-delete':
        this.$store.dispatch('user/uploadCheckDeleteExcelFile', { file: this.file }).then(response => {
          this.deletePopupActive = true
          this.showFileName = this.showUploadButtons = false
          this.deletePopupContent = this.$t('users.suite.popups.confirm_delete.message').replace('[COUNT]', response.data.data.count)
        }).catch(error => {
          const errorString = this.getErrorString(error.response.data)
          this.$vs.notify({
            title: this.$t('users.suite.errors.title'),
            text: (errorString === '') ? error : errorString,
            color: 'danger',
            time: 10000
          })
        }).then(() => {
          this.uploading = false
        })
        break
      case 'delete':
        this.$store.dispatch('user/uploadDeleteExcelFile', { file: this.file }).then(response => {
          this.deletePopupActive = false
          this.$vs.notify({
            title: this.$t('users.suite.notifications.succsess_deleted_title'),
            text:  this.$t('users.suite.notifications.succsess_deleted_message').replace('[COUNT]', response.data.data.count),
            color:'success',
            time: 10000
          })
        }).catch(error => {
          const errorString = this.getErrorString(error.response.data)
          this.$vs.notify({
            title: this.$t('users.suite.errors.title'),
            text: (errorString === '') ? error : errorString,
            color: 'danger',
            time: 10000
          })
        }).then(() => {
          this.$store.dispatch('user/getSuite').then(() => {
            this.uploading = false
          })
        })
        break
      }
    },
    previewFile (event) {
      this.file = event.target.files[0]
      this.fileName = this.file.name
      this.showFileName = true
      this.showUploadButtons = true
    },
    downloadUsersFileTrainingResults () {
      const payload = {
        date_since: this.date_since,
        date_until: this.date_until
      }
      this.$store.dispatch('user/downloadExcelFileTrainingResults', payload)
    },
    downloadUsersFileLevels () {
      const payload = {
        date_since: this.date_since,
        date_until: this.date_until
      }
      this.$store.dispatch('user/downloadExcelFileLevels', payload)
    }
  },
  created () {
    this.$store.dispatch('user/getSuite').then(() => {
      this.loading = false
    }).catch(err => { console.error(err) })
  }
}

</script>

<style lang="scss">
  #page-user-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
  .upload-excel-users-container, .demo-alignment {
    margin-bottom: 15px;
  }
  .delete-confirm-popup-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  .excel-file-buttons-container {
    display: flex;
    //justify-content: space-between;
    align-items: flex-end;
    justify-content: flex-end;

    & > button {
      margin-left: 15px;
    }

    & > button:first-child {
      margin-left: 0;
      margin-right: auto;
    }
  }
  .flatpickr-input {
    margin-left: 10px;
  }
</style>
