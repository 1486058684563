<template>

  <div id="page-chat-list">

    <div class="vx-row">
      <loading v-if="loading"></loading>

      <div class="vx-col w-full mb-base" v-if="!showSortTable&&!loading">
        <div class="vx-card p-6" v-if="!loading">
          <!-- AgGrid Table -->
          <ag-grid-vue
            @gridReady="onGridReady"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4"
            domLayout="autoHeight"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="suite"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl"
          />

          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage" />
        </div>
      </div>

      <vs-popup class="holamundo" :title="userName" :active.sync="chatMessagePopupActive">
        <p>{{ chatMessagePopupContent }}</p>
        <div v-if="imagePath" class="chat-messages-img">
          <img :src="getUrl(imagePath)" alt="" width="500">
        </div>
        <div v-if="deleteProcess">
          <font-awesome-icon icon="spinner" spin />
        </div>
        <div class="delete-confirm-popup-buttons" v-else>
          <vs-button v-if="!deleted_at" color="danger" type="filled" @click="deleteMessage()" :disabled="isOnlyRead">{{ $t('chat_messages.suite.popups.buttons.delete') }}</vs-button>
          <vs-button v-else color="warning" type="filled" @click="restoreMessage()" :disabled="isOnlyRead">{{ $t('chat_messages.suite.popups.buttons.recover') }}</vs-button>
          <vs-button color="warning" type="filled" @click="chatMessagePopupActive = false">{{ $t('chat_messages.suite.popups.buttons.cancel') }}</vs-button>
        </div>
      </vs-popup>


    </div>
  </div>

</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import draggable from 'vuedraggable'

// Cell Renderer
import CellRendererLink from '../../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../../components/cell-renderer/chat_messages/CellRendererActions.vue'

import loading from '@/views/components/Loading.vue'

export default {
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,

    loading,
    draggable
  },
  data () {
    return {
      chat_id: [],
      showSortTable: false,
      chatMessagePopupActive: false,
      chatMessagePopupContent: '',
      deleteProcess: false,
      loading: true,
      // AgGrid
      userName: '',
      imagePath: null,
      deleted_at: '',
      chatMessagePopupId: 0,
      chatId: 0,
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: { 'textAlign': 'center'},
        headerClass: 'text-center',
        cellClassRules: {
          deleted: params => params.data.deleted_at
        }
      },
      columnDefs: [
        {
          headerName: this.$t('chat_messages.suite.table.id'),
          field: 'chat_id',
          filter: false,
          minWidth: 50,
          maxWidth: 50,
          sortable: false,
          headerClass: 'px-0 text-center',
          cellStyle: {'padding': '0px 0px'}
        },
        {
          headerName: this.$t('chat_messages.suite.table.chat_name'),
          field: 'chat_name',
          filter: true,
          minWidth: 100,
          maxWidth: 200,
          sortable: true,
          headerClass: 'pl-0 text-left',
          cellClass: 'px-0 text-left',
          celStyle: {'line-height': '1.7', 'word-break': 'break-word'}
        },
        {
          headerName: this.$t('chat_messages.suite.table.user'),
          field: 'user_name',
          filter: true,
          minWidth: 150,
          maxWidth: 300,
          flex: 1,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 24px'},
          headerClass: 'text-left'
        },
        {
          headerName: this.$t('chat_messages.suite.table.message'),
          field: 'comment',
          filter: true,
          minWidth: 250,
          maxWidth: 1000,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 24px'},
          headerClass: 'text-left'
        },
        {
          headerName: '',
          field: 'id',
          minWidth: 50,
          maxWidth: 50,
          cellRenderer: 'CellRendererActions'
        },
        {
          headerName: this.$t('chat_messages.suite.table.created_at'),
          field: 'created_at',
          minWidth: 170,
          maxWidth: 200,
          sortable: true
        },
        {
          headerName:this.$t('chat_messages.suite.table.deleted_at'),
          field: 'deleted_at',
          minWidth: 170,
          maxWidth: 200,
          sortable: true
        }

      ],

      // Cell Renderer Components
      components: {
        CellRendererActions
      }
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-chat-messages'] === 'read'
      }
    },
    suite () {
      return this.$store.getters['chat_messages/suite']
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },
  methods:{
 
    onGridReady (params) {
      this.gridApi = params.api
    },
    deleteMessage () {
      this.$store.dispatch('chat_messages/delete', { id: this.chatMessagePopupId, chat_id: this.chatId }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.chatMessagePopupActive = false
      }).then(() => {
        this.$store.dispatch('chat_messages/getSuite')
      })
    },
    restoreMessage () {
      this.$store.dispatch('chat_messages/restore', { id: this.chatMessagePopupId, chat_id: this.chatId }).catch(error => {
        this.$vs.notify({
          title: this.$t('users.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.chatMessagePopupActive = false
      }).then(() => {
        this.$store.dispatch('chat_messages/getSuite')
      })
    }
  },
  created () {

    this.$store.dispatch('chat_messages/getSuite').then(() => {
      this.loading = false
    })
  }
}

</script>

<style lang="scss">
  #page-chat-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
  .add-button {
    margin-bottom: 30px;
  }

  .delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.chat-messages-img {
    width: 100%;
    text-align: center;
    margin: 2em 0;
  }

.deleted {
  background-color: rgb(249, 234, 234);
}
</style>
