<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <feather-icon v-if="!isOnlyRead" icon="EditIcon" svgClasses="h-5 w-5-3 hover:text-primary cursor-pointer" @click="editCode()" />

</div>
</template>

<script>

export default {
  name: 'CellRendererActions',
  methods: {
    editCode () {
      this.params.context.componentParent.productCodePopupActive = true
      this.params.context.componentParent.productCodePopupId = this.params.data.id
      this.params.context.componentParent.deleted_at = this.params.data.deleted_at
      
      this.params.context.componentParent.code = this.params.data.code
      this.params.context.componentParent.productCodePopupActive = true
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-shop'] === 'read'
      }
    }
  }
}
</script>