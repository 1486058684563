import state from './moduleClubState.js'
import mutations from './moduleClubMutations.js'
import actions from './moduleClubActions.js'
import getters from './moduleClubGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
