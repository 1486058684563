import FormError from '../../modules/formError'

export default {
  suite: [],
  groupsOptions: [],
  formError: new FormError(),
  edit: {
    id: null,
    title: null,
    content: null,
    isPublished: null,
    groups: null,
    isDisplayPreview: null
  }
}
