<!-- =========================================================================================
  File Name: Teams.vue
  Description: Teams List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="demo-basic-card">
    <div class="upload-excel-users-container">
      <div class="excel-file-buttons-container">
        <vs-button color="primary" type="filled" @click="$refs.file.click()" :disabled="isOnlyRead">{{ $t('teams.suite.buttons.upload_file') }} </vs-button>

        <input type="file" ref="file" style="display: none" @change="previewFile" />
      </div>
      <div>{{ $t('registration_codes.suite.description') }}</div>
      <div>{{ $t('registration_codes.suite.description_codes') }}</div>
      <div>{{ $t('registration_codes.suite.description_emails') }}</div>

      <vs-alert :active.sync="showFileName" closable icon-pack="feather" close-icon="icon-x">
        {{ fileName }}
      </vs-alert>
      <div class="demo-alignment" v-if="showUploadButtons">
        <vs-button color="success" type="filled" icon-pack="feather" icon="icon-upload" @click="uploadUsersFile('add')"></vs-button>
      </div>
      <div v-if="uploading">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <vs-button v-if="!isOnlyRead" class="mt-8 mb-4" @click="showAddCodePopup = true">{{`${$t('Create')} ${$t('code')}`}}</vs-button>

    <div class="vx-row">
      <loading v-if="loading"></loading>
      <div class="vx-card p-6" v-if="!loading">
        <!-- AgGrid Table -->
        <ag-grid-vue
          @gridReady="onGridReady"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4"
          domLayout="autoHeight"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="suite"
          rowSelection="multiple"
          @rowClicked="onRowClicked"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
        >
        </ag-grid-vue>

        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </div>

      <vs-popup class="holamundo" :title="$t('teams.suite.popups.confirm_delete.title')" :active.sync="deleteTeamPopupActive">
        <p>{{ deleteTeamPopupContent }}</p>
        <div v-if="deleteProcess">
          <font-awesome-icon icon="spinner" spin />
        </div>
        <div class="delete-confirm-popup-buttons" v-else>
          <vs-button color="primary" type="filled" @click="deleteTeam()">{{
            $t('teams.suite.popups.confirm_delete.buttons.confirm')
          }}</vs-button>
          <vs-button color="warning" type="filled" @click="deleteTeamPopupActive = false">{{
            $t('teams.suite.popups.confirm_delete.buttons.cancel')
          }}</vs-button>
        </div>
      </vs-popup>

      <vs-popup class="p-6 rounded-lg shadow-lg first-popup" :title="`id № ${rowData?.id}`" :active.sync="showCodePopup">
          <ul class="space-y-8">
            <li class="flex items-center">
              <span class="font-semibold mr-4">{{ $t('Registration code') }}:</span>
              <span>{{ rowData?.code }}</span>
              <vs-button
                @click="editCode('code')"
                :disabled="isOnlyRead"
                color="danger"
                type="filled"
                class="customizer-btn ml-auto"
                icon-pack="feather"
                icon="icon-edit" />
            </li>
            <li class="flex items-center">
              <span class="font-semibold mr-2">{{ $t('Email') }}:</span>
              <span>{{ rowData?.email }}</span>
              <vs-button
                @click="editCode('email')"
                :disabled="isOnlyRead"
                color="danger"
                type="filled"
                class="customizer-btn ml-auto"
                icon-pack="feather"
                icon="icon-edit" />
            </li>
            <li class="flex items-center">
              <span class="font-semibold mr-2">{{ $t('registration_codes.table.is_active') }}:</span>
              <span v-if="rowData?.is_active === null" class="font-semibold">{{ '' }}:</span>
              <span v-else class="font-semibold">{{ rowData?.is_active ? $t('No') : $t('Yes') }}</span>
            </li>
            <li class="flex items-center">
              <span class="font-semibold mr-2">{{ $t('registration_codes.table.coins') }}:</span>
              <span>{{ rowData?.additional_data?.coins }}</span>
              <vs-button
                @click="editCode('coins')"
                :disabled="isOnlyRead"
                color="danger"
                type="filled"
                class="customizer-btn ml-auto"
                icon-pack="feather"
                icon="icon-edit" />
            </li>
            <li class="flex items-center">
              <span class="font-semibold mr-2">{{ $t('registration_codes.table.product_id') }}:</span>
              <span>{{ rowData?.additional_data?.product_id }}</span>
              <vs-button
                @click="editCode('product_id')"
                :disabled="isOnlyRead"
                color="danger"
                type="filled"
                class="customizer-btn ml-auto"
                icon-pack="feather"
                icon="icon-edit" />
            </li>
            <li class="flex items-center">
              <span class="font-semibold mr-2">{{ $t('registration_codes.table.comment') }}:</span>
              <span>{{ rowData?.additional_data?.comment }}</span>
              <vs-button
                @click="editCode('comment')"
                :disabled="isOnlyRead"
                color="danger"
                type="filled"
                class="customizer-btn ml-auto"
                icon-pack="feather"
                icon="icon-edit" />
            </li>
          </ul>

          <vs-button 
            color="danger" type="filled" 
            :disabled="isOnlyRead"
            class="ml-auto mt-4" 
            @click="showDeletePopup = true"
            >
            {{ `${$t('Delete')} ${$t('code')}`}}
          </vs-button>
      </vs-popup>

      <vs-popup class="p-6 rounded-lg shadow-lg first-popup" :title="$t('Registration code')" :active.sync="showAddCodePopup">
          <ul class="space-y-8">
            <li class="flex items-center">
              <span class="font-semibold mr-4 w-1/3">{{ $t('Registration code') }}*:</span>
              <vs-input class="w-full" v-model="newCode.code" />
            </li>
            <li class="flex items-center">
              <span class="font-semibold mr-2 w-1/3">{{ $t('Email') }}:</span>
              <vs-input type="email"  :danger="isEmailInvalid(newCode.email)" :danger-text="$t('notify.errors.no_valid_data')" class="w-full" v-model="newCode.email" />
            </li>
            <li class="flex items-center">
              <span class="font-semibold mr-2 w-1/3">{{ $t('registration_codes.table.coins') }}:</span>
              <vs-input class="w-full" v-model="newCode.additional_data.coins" />
            </li>
            <li class="flex items-center">
              <span class="font-semibold mr-2 w-1/3">{{ $t('registration_codes.table.product_id') }}:</span>
              <vs-input class="w-full" v-model="newCode.additional_data.product_id" />
            </li>
            <li class="flex items-center">
              <span class="font-semibold mr-2 w-1/3">{{ $t('registration_codes.table.comment') }}:</span>
              <vs-textarea  class="w-full" v-model="newCode.additional_data.comment" />
            </li>
          </ul>

          <vs-button 
            color="danger" type="filled" 
            class="ml-auto mt-4" 
            @click="createCode()"
            :disabled="!newCode.code"
            >
            {{ `${$t('Create')} ${$t('code')}`}}
          </vs-button>
      </vs-popup>

      <vs-popup class="p-10 rounded-lg shadow-lg second-popup" :title="`${$t('Delete')} ${$t('code')}: ${rowData?.code}`" :active.sync="showDeletePopup">
        <p>{{ $t('Are you sure you want to delete the registration code?') }}</p>
        <div class="vx-row mt-6">
          <div class="vx-col">
            <vs-button color="danger" type="filled" class="ml-auto mt-4 " @click="deleteCode(rowData.id)">{{ $t('Delete') }}</vs-button>
          </div>
          <div class="vx-col ml-auto">
            <vs-button color="primary" type="filled" class="ml-auto mt-4" @click="showDeletePopup = false">{{ $t('Cancel') }}</vs-button>
          </div>
        </div>
      </vs-popup>      

      <vs-popup class="p-6 rounded-lg shadow-lg second-popup" :title="`${$t('Edit field:')} ${$t(editField)}`" :active.sync="showEditPopup">
        <vs-input 
          v-if="editField === 'email'"
          type="email" 
          :danger="isEmailInvalid(fieldValue)" 
          :danger-text="$t('notify.errors.no_valid_data')" 
          v-model="fieldValue">
        </vs-input>
        <vs-input v-else v-model="fieldValue" ></vs-input>
        <p class="text-sm text-grey mt-4" v-if="editField === 'is_active'"> 0 : код активирован<br> 1 : код не активирован</p>
        <vs-button color="primary" type="filled" class="ml-auto mt-4" @click="updateCode()">{{ $t('Save changes') }}</vs-button>
      </vs-popup>     
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererLink from '../../components/cell-renderer/CellRendererLink.vue'
import CellRendererActions from '../../components/cell-renderer/teams/CellRendererActions.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import loading from '@/views/components/Loading.vue'
import moment from 'moment'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)

export default {
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,

    loading
  },
  data () {
    return {
      loading: true,
      showFileName: false,
      fileName: '',
      file: null,
      showUploadButtons: false,
      uploading: false,
      // AgGrid
      gridApi: null,
      rowData: null,
      showCodePopup: false,
      showEditPopup: false,
      showDeletePopup: false,
      showAddCodePopup: false,
      editField: '',
      fieldValue: '',
      newCode: {
        code: '',
        email: '',
        is_active: 1,
        additional_data: {
          coins: '',
          product_id: '',
          comment: ''
        }
      },
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: { 'textAlign': 'center'},
        headerClass: 'text-center'
      },
      columnDefs: [
        {
          headerName: this.$t('registration_codes.table.id'),
          field: 'id',
          minWidth: 50,
          maxWidth: 75,
          sortable: true
        },
        {
          headerName: this.$t('registration_codes.table.code'),
          field: 'code',
          minWidth: 100,
          maxWidth: 150,
          sortable: true,
          filter: true
        },
        {
          headerName: this.$t('registration_codes.table.email'),
          field: 'email',
          filter: true,
          minWidth: 250,
          maxWidth: 1200,
          flex: 2,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': '1.7', 'text-align': 'left', 'word-break': 'break-word', 'padding': '12px 24px'},
          headerClass: 'text-left'
        },
        {
          headerName: this.$t('registration_codes.table.coins'),
          field: 'additional_data.coins',
          minWidth: 100,
          maxWidth: 150,
          sortable: true
        },
        {
          headerName: this.$t('registration_codes.table.product_id'),
          field: 'additional_data.product_id',
          minWidth: 100,
          maxWidth: 150,
          sortable: true
        },
        {
          headerName: this.$t('is_active'),
          field: 'is_active',
          cellRenderer: (params) => {
            console.log('params', params)
            if (params.value === null) {
              return ''
            } else {
              return params.value ? this.$t('No') : this.$t('Yes')
            }
          },
          minWidth: 100,
          maxWidth: 150,
          sortable: true
        },
        {
          headerName: this.$t('registration_codes.table.created_at'),
          field: 'created_at',
          valueFormatter: (params) => {
            return params.value ? moment(params.value).format('DD.MM.YYYY HH:mm') : ''
          },
          minWidth: 170,
          maxWidth: 200,
          sortable: true
        } /*,        {
          headerName: this.$t('registration_codes.table.actions'),
          field: 'id',
          width: 120,
          cellRenderer: 'CellRendererActions'
        }*/
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deleteTeamPopupActive: false,
      deleteTeamPopupContent: '',
      deleteProcess: false,
      deleteTeamId: 0
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-settings-registration-codes'] === 'read' ||  
               accessUser['admin-settings'] === 'read'
      }
    },
    suite () {
      return this.$store.getters['registration/suite']
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },
  watch: {
    editField (val) {
      if (!this.rowData.additional_data) {
        this.rowData.additional_data = {}
      }
      switch (val) {
      case 'code':
        this.fieldValue = this.rowData.code
        break
      case 'email':
        this.fieldValue = this.rowData.email
        break
      case 'is_active':
        this.fieldValue = this.rowData.is_active
        break
      case 'coins':
        this.fieldValue = this.rowData.additional_data?.coins || ''
        break
      case 'product_id':
        this.fieldValue = this.rowData.additional_data?.product_id || ''
        break
      case 'comment':
        this.fieldValue = this.rowData.additional_data?.comment || ''
        break
      }
    }
  },
  created () {
    this.$store.dispatch('registration/getSuite').then(() => {
      this.loading = false
    })
  },
  methods: {
    isEmailInvalid (text) {
      const email = text
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return email?.length > 5 && !emailRegex.test(email)
    },
    deleteTeam () {
      this.deleteProcess = true
      this.$store
        .dispatch('team/delete', { id: this.deleteTeamId })
        .catch((error) => {
          this.$vs.notify({
            title: this.$t('teams.suite.errors.title'),
            text: error,
            color: 'danger',
            time: 10000
          })
        })
        .then(() => {
          this.deleteProcess = false
          this.deleteTeamPopupActive = false
        })
    },
    previewFile (event) {
      this.file = event.target.files[0]
      this.fileName = this.file.name
      this.showFileName = true
      this.showUploadButtons = true
    },
    uploadUsersFile (type) {
      this.uploading = true
      switch (type) {
      case 'add':
        this.$store
          .dispatch('registration/uploadAddExcelFile', { file: this.file })
          .then((response) => {
            this.$vs.notify({
              title: this.$t('registration_codes.suite.notifications.success_created_title'),
              text: this.$t('registration_codes.suite.notifications.success_created_message').replace(
                '[COUNT]',
                response.data.data.count
              ),
              color: 'success',
              time: 10000
            })
            this.showFileName = this.showUploadButtons = false
          })
          .catch((error) => {
            console.log(error.response.data)
            const errorString = error.response.data
            this.$vs.notify({
              title: this.$t('registration_codes.suite.errors.title'),
              text: errorString === '' ? error : errorString,
              color: 'danger',
              time: 10000
            })
          })
          .then(() => {
            this.$store.dispatch('registration/getSuite').then(() => {
              this.uploading = false
            })
          })
        break
      }
    },
    downloadTeamsFile () {
      this.$store.dispatch('registration/downloadExcelFileTeams')
    },
    onGridReady (params) {
      this.gridApi = params.api
    },
    onRowClicked (params) {
      this.editField = null
      this.rowData = params.data
      this.showCodePopup = true
    },
    editCode (field) {
      console.log('editCode', field)
      if (!field) {
        return
      }
      // this.showCodePopup = false
      this.showEditPopup = true
      this.editField = field
    },
    updateCode () {
      const oldRowData = JSON.parse(JSON.stringify(this.rowData))
      switch (this.editField) {
      case 'code':
        this.rowData.code = this.fieldValue
        break
      case 'email':
        this.rowData.email = this.fieldValue
        break
      case 'is_active':
        this.rowData.is_active = this.fieldValue
        break
      case 'coins':
        this.rowData.additional_data.coins = this.fieldValue
        break
      case 'product_id':
        this.rowData.additional_data.product_id = this.fieldValue
        break
      case 'comment':
        this.rowData.additional_data.comment = this.fieldValue
        break
      default:
        break
      }
      this.rowData.is_active = +this.rowData.is_active
      this.$store.dispatch('registration/updateCode', this.rowData).then(() => {
        this.gridApi.refreshCells({ force: true })
        this.$vs.notify({
          title: this.$t('notify.success.title'),
          text: this.$t('notify.success.success_save'),
          color: 'success',
          time: 5000
        })
      }).catch((error) => {
        console.log(error)
        this.rowData = oldRowData
        this.$vs.notify({
          title: this.$t('notify.errors.title'),
          text: this.$t('notify.errors.no_valid_data'),
          color: 'danger',
          time: 5000
        })
      })
      this.showEditPopup = false
    },
    deleteCode (id) {
      this.$store.dispatch('registration/deleteCode', id).then(() => {
        this.$vs.notify({
          title: this.$t('notify.success.title'),
          text: this.$t('notify.success.success_delete'),
          color: 'success',
          time: 5000
        })
      }).catch((error) => {
        console.log(error)
        this.$vs.notify({
          title: this.$t('notify.error.title'),
          text: this.$t('notify.error.error_delete'),
          color: 'danger',
          time: 5000
        })
      }).then(() => {
        this.$store.dispatch('registration/getSuite')
        this.showCodePopup = false
        this.showDeletePopup = false
      })
      
    },
    createCode () {
      this.$store.dispatch('registration/createCode', this.newCode).then(() => {
        this.showAddCodePopup = false
        this.newCode = {
          code: '',
          email: '',
          is_active: 1,
          additional_data: {
            coins: '',
            product_id: '',
            comment: ''
          }
        }
        this.$vs.notify({
          title: this.$t('notify.success.title'),
          text: this.$t('notify.success.success_save'),
          color: 'success',
          time: 5000
        })
      }).catch((error) => {
        console.log(error)
        this.$vs.notify({
          title: this.$t('notify.errors.title'),
          text: this.$t('notify.errors.error_save'),
          color: 'danger',
          time: 5000
        })
      }).then(() => {
        this.$store.dispatch('registration/getSuite')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-button {
  margin-bottom: 30px;
}
.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.buttons-wrapper {
  margin-bottom: 30px;
}
.buttons-wrapper > button {
  display: inline-block;
  margin-right: 30px;
}
.sort-table-wrapper {
  width: 100%;
}
.list-item {
  transition: all 1s;
}
.list-item:hover {
  cursor: all-scroll;
}
.excel-file-buttons-container {
  display: flex;
  //justify-content: space-between;
  align-items: flex-end;
  justify-content: flex-end;

  & > button {
    margin-left: 15px;
    margin-bottom: 15px;
  }

  & > button:first-child {
    margin-left: 0;
    margin-right: auto;
  }
}

.first-popup {
  z-index: 5;
}

.second-popup {
  z-index: 6;
}
</style>

