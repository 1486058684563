import FormError from './../../modules/formError'

export default {
  suite: [],
  select: [],
  edit: {
    sum: '',
    status: '',
    lastname: ''
  },
  formError: new FormError()
}
