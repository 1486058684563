<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        <div v-show="type === 'task'">
          {{
            Object.entries(this.data).length === 0
              ? $t('exercises.create_edit.titles.create_task')
              : $t('exercises.create_edit.titles.edit_task')
          }}
        </div>
        <div v-show="type !== 'task'">
          {{ Object.entries(this.data).length === 0 ? $t('exercises.create_edit.titles.create') : $t('exercises.create_edit.titles.edit') }}
        </div>
      </h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component v-if="isSidebarActive" :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- CATEGORY -->
        <div class="v-select-wrapper">
          <label class="input-label">{{ $t('exercises.create_edit.labels.task_type') }} *</label>
          <v-select
            v-model="dataResultType"
            :options="result_type_choices"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            :clearable="false"
            name="item-result-type"
            :disabled="!!+data.trainingHasResults"
          />
          
        </div>
        <span class="text-danger text-sm" v-show="errors.has('item-result-type')">{{ errors.first('item-result-type') }}</span>
        <div class="mt-5">
          <label class="input-label">{{$t('exercises.create_edit.labels.name')}} *</label>
          <!-- NAME -->
          <vs-input
            v-model="dataName"
            :placeholder="$t('exercises.create_edit.labels.enter_a_name')"
            class="w-full"
            name="item-name"
          />
        </div>
        <span class="text-danger text-sm" v-show="errors.has('item-name')">{{ $t('form.errors.required_field') }}</span>

        <div class="vx-row mt-6">
          <div class="vx-col w-full">
            <label  class="input-label">{{ $t('exercises.create_edit.labels.point') }}</label>
          <vs-input type="number" v-model.number="point" class="py-1 text-lg font-bold" />
          </div>
        </div>
        <div class="vx-row mt-4">
          <div class="vx-col w-full mb-3">
            <vs-checkbox v-model="isRequired" color="#7367F0">{{ $t('trainings.create.labels.is_required') }}</vs-checkbox>
            <span class="text-danger text-sm" v-show="errors.has('is_required')">{{ errors.first('is_required') }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-6">
            <vs-checkbox v-model="isHideResults" color="#7367F0">{{ $t('trainings.exercises.labels.hide_results') }}</vs-checkbox>
          </div>
        </div>

        <div class="vx-row mt-4" v-if="dataResultType.value === 'enter_correct_answer'">
          <div class="vx-col w-full mb-6">
            <label class="input-label">{{ $t('exercises.create_edit.labels.answer') }} *</label>
            <vs-textarea
              :placeholder="$t('exercises.create_edit.labels.enter_a_current_answer')"
              v-model="dataCorrectAnswer"
              class="w-full"
              name="item-name"
            />
            <vs-alert class="alert shadow-md" active="true" color="#fff" icon="info" >
              <p class="pb-3">{{ $t('exercises.create_edit.description.specify_the_correct_answer') }}</p>
              <p>{{ $t('exercises.create_edit.description.example_correct_answer') }}</p>
            </vs-alert>
          </div>
        </div>

        <div class="vx-row mt-4" v-if="isChoosingFromAnswers">
          <div class="vx-col w-full mb-6">
            <div class="select-answer mt-2">
              <transition-group>
                <div v-for="(answer, index) in dataSelectAnswer" :key="answer.id || index" class="select-answer__item mt-1 mb-6">
                  <label class="input-label">{{ $t('exercises.create_edit.labels.answer') }} *</label>
                  <div class="select-answer__input">
                    <vs-input
                      :placeholder="$t('exercises.create_edit.labels.enter_a_answer')"
                      v-model="answer.answer"
                      class="mb-3 w-full"
                    />
                    <div style="position: relative;">
                      <label class="label">{{ $t('exercises.create_edit.labels.icon') }}</label>
                      <picture-input
                        class="picture-input-btn mb-3"
                        ref="pictureInput"
                        @change="onImgChange(answer, index)"
                        @remove="onImgRemove(answer)"
                        width="52"
                        height="36"
                        margin="0"
                        radius="6"
                        :accept="$baseImageAcceptTypes"
                        buttonClass="btn"
                        size="10"
                        :hideChangeButton="true"
                        :crop="false"
                        :prefill="
                          answer.image_file
                            ? answer.image_file
                            : getUrl(answer.image_path, `${$http.defaults.baseURL}/api/admin-panel`)
                        "
                        :alertOnError="false"
                        :removable="true"
                        removeButtonClass="btn-delete-icon"
                        :customStrings="{
                          drag: `<span class='material-icons'>get_app</span>`,
                          remove: $t('trainings.edit.labels.remove_image'),
                        }"
                      >
                    </picture-input>
                    </div>
                    <div class="relative">
                      <label class="label">Score</label>
                      <input type="number" 
                        v-model="answer.score" 
                        @input="
                          answer.score.length > 3 
                          ? answer.score = answer.score.slice(0,3) 
                          : ''" 
                        class="mb-3 py-1 score" />
                    </div>
                  </div>
                  <p v-if="!answer.answer && !answer.image && !answer.image_path" class="font-bold pb-3 text-center text-danger">
                    {{ $t('exercises.create_edit.labels.error_valid') }}
                  </p>
                  <vs-radio
                    v-if="(dataResultType.value === 'radio_answers') && !dataResultType.is_poll"
                    @click.capture="onChecked(answer)"
                    v-model="isRadioSelected"
                    :vs-name="answer.answer"
                    :checked="answer.is_correct"
                    :vs-value="answer.is_correct"
                    color="#7367F0"
                    >{{ $t('exercises.create_edit.labels.current_answer') }}</vs-radio
                  >
                  <div @click="onChecked(answer)">
                    <vs-checkbox
                      v-if="(dataResultType.value === 'flags_answers') && !dataResultType.is_poll"
                      :checked="answer.is_correct === 1"
                      :value="answer.is_correct"
                      color="#7367F0"
                      >{{ $t('exercises.create_edit.labels.current_answer') }}</vs-checkbox
                    >
                  </div>
                  <vs-button
                      v-if="dataSelectAnswer.length - 1 && !data.trainingHasResults"
                      @click="dataSelectAnswer.splice(index, 1)"
                      color="#fff"
                      type="filled"
                      size="small"
                      icon="clear"
                      class="mt-2 text-danger ml-auto pr-0 mt-0 pt-0"
                      style="top-12px"
                    >{{ $t('exercises.create_edit.labels.delete') }}
                  </vs-button>
                </div>
              </transition-group>
            </div>
            <div class="add-select-answer-item">
              <vs-button @click="addSelectAnswer" color="rgb(218 ,126 ,60)" type="Filled" icon="add" class="mr-auto text-orange pl-0" style="top: -20px">{{ $t('exercises.create_edit.labels.add') }}</vs-button>
            </div>
          </div>
        </div>

        <div class="vx-row mt-4" v-if="dataResultType.value === 'answer_by_scale'">
          <div class="vx-col w-full mb-6">
            <div class="select-answer mt-2">
              <div class="select-answer__item mt-1 mb-6">
                <label class="input-label">{{ $t('exercises.create_edit.labels.min_value') }} *</label>
                <div class="select-answer__input">
                  <vs-input
                    :placeholder="$t('exercises.create_edit.labels.enter_a_answer')"
                    v-model="dataScaleAnswer.from"
                    class=" mb-3"
                  />
                  <div style="position: relative;">
                      <label class="label">{{ $t('exercises.create_edit.labels.icon') }}</label>
                    <picture-input
                      ref="pictureInputFrom"
                      class="picture-input-btn mb-3"
                      @change="onImgFromChange"
                      @remove="onImgFromRemove"
                      width="52"
                      height="36"
                      margin="0"
                      radius="6"
                      :accept="$baseImageAcceptTypes"
                      buttonClass="btn"
                      size="10"
                      :hideChangeButton="true"
                      :crop="false"
                      :prefill="dataScaleAnswer.from_image_file || getUrl(dataScaleAnswer.from_image_path, `${$http.defaults.baseURL}/api/admin-panel`)"
                      :alertOnError="false"
                      :removable="true"
                      removeButtonClass="btn-delete-icon"
                      :customStrings="{
                        drag: `<span class='material-icons'>get_app</span>`,
                        remove: $t('trainings.edit.labels.remove_image'),
                      }"
                    >
                    </picture-input>
                  </div>
                  <div class="relative">
                    <label class="label">SCORE</label>
                    <input type="number" 
                      v-model="dataScaleAnswer.from_score" 
                      @input="
                        dataScaleAnswer.from_score.length > 3 
                        ? dataScaleAnswer.from_score = dataScaleAnswer.from_score.slice(0,3) 
                        :''
                          " 
                      class="mb-3 py-1 score" 
                      />
                  </div>
                </div>
                <p v-if="!dataScaleAnswer.from" class="font-bold pb-3 text-center text-danger">
                  {{ $t('exercises.create_edit.labels.error_valid_2') }}
                </p>
              </div>
              <div class="select-answer__item mt-1 mb-6">
                <label class="input-label">{{ $t('exercises.create_edit.labels.max_value') }} *</label>
                <div class="select-answer__input">
                  <vs-input
                    :placeholder="$t('exercises.create_edit.labels.enter_a_answer')"
                    v-model="dataScaleAnswer.to"
                    class="mb-3 w-full"
                  />
                  <div style="position: relative;">
                      <label class="label">{{ $t('exercises.create_edit.labels.icon') }}</label>
                      <picture-input
                        ref="pictureInputTo"
                        class="picture-input-btn mb-3"
                        @change="onImgToChange"
                        @remove="onImgToRemove"
                        width="52"
                        height="36"
                        margin="0"
                        radius="6"
                        :accept="$baseImageAcceptTypes"
                        buttonClass="btn"
                        size="10"
                        :hideChangeButton="true"
                        :crop="false"
                        :prefill=" dataScaleAnswer.to_image_file || getUrl(dataScaleAnswer.to_image_path, `${$http.defaults.baseURL}/api/admin-panel`)
                        "
                        :alertOnError="false"
                        :removable="true"
                        removeButtonClass="btn-delete-icon"
                        :customStrings="{
                          drag: `<span class='material-icons'>get_app</span>`,
                          remove: $t('trainings.edit.labels.remove_image'),
                        }"
                      >
                    </picture-input>
                  </div>
                  <div class="relative">
                    <label class="label">SCORE</label>
                    <input type="number" 
                      v-model="dataScaleAnswer.to_score" 
                      @input="
                        dataScaleAnswer.to_score.length > 3 
                        ? dataScaleAnswer.to_score = dataScaleAnswer.to_score.slice(0,3) 
                        : ''" 
                        class="mb-3 py-1 score" />
                  </div>
                </div>
                <p v-if="!dataScaleAnswer.to" class="font-bold pb-3 text-center text-danger">
                  {{ $t('exercises.create_edit.labels.error_valid_2') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || actionButtonDisabled">{{
        Object.entries(this.data).length === 0 ?
         $t('exercises.create_edit.buttons.create') :
         $t('exercises.create_edit.buttons.update')
      }}</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{
        $t('exercises.create_edit.buttons.cancel')
      }}</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import PictureInput from 'vue-picture-input'
import helpers from '@/helpers/helpers.js'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },
    type_exercise: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    }
  },
  components: {
    PictureInput,
    VuePerfectScrollbar,
    'v-select': vSelect
  },
  data () {
    return {
      dataId: null,
      dataIndex: null,
      dataName: '',
      imageUrl: '',
      dataCorrectAnswer: '',
      dataSelectAnswer: [],
      dataScaleAnswer: {
        from: '',
        to: '',
        from_score: 0,
        to_score: 0
      },
      isRadioSelected: 1,
      isCheckboxSelected: [],
      dataResultType: null,
      isRequired: false,
      isHideResults: false,
      isAnyAnswerCorrect: false,
      isPoll: 0,
      point: 0,
      result_type_choices: [
        { label: this.$t('trainings.exercises.labels.repetitions'), value: 'repetitions' },
        { label: this.$t('trainings.exercises.labels.time_less'), value: 'time_less' },
        { label: this.$t('trainings.exercises.labels.time_more'), value: 'time_more' },
        { label: this.$t('trainings.exercises.labels.comment'), value: 'comment' },
        { label: this.$t('trainings.exercises.labels.photo'), value: 'photo' },
        { label: this.$t('trainings.exercises.labels.enter_correct_answer'), value: 'enter_correct_answer' },
        { label: this.$t('trainings.exercises.labels.radio_answers'), value: 'radio_answers', is_poll: 0 },
        { label: this.$t('trainings.exercises.labels.flags_answers'), value: 'flags_answers', is_poll: 0 },
        { label: this.$t('trainings.exercises.labels.radio_answers_poll'), value: 'radio_answers', is_poll: 1 },
        { label: this.$t('trainings.exercises.labels.flags_answers_poll'), value: 'flags_answers', is_poll: 1 },
        { label: this.$t('trainings.exercises.labels.answer_by_scale'), value: 'answer_by_scale' }
      ],

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      actionButtonDisabled: false
    }
  },
  watch: {
    isSidebarActive (val) {
      this.dataScaleAnswer = {
        from: '',
        to: '',
        from_score: 0,
        to_score: 0
      }
      this.dataCorrectAnswer = ''
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
        this.loaded = true
      } else {
        const { name, result_type, is_required, hide_results, index, id, uuid, correct_answers, answer, answers, answers_by_scale, answers_scale, accept_any_answers, point } = this.data
        const lastId = this.dataId
        this.dataId = id
        this.dataIndex = index
        this.dataName = name
        this.dataUuid = uuid
        this.point = point || 0
        this.isRequired = is_required
        this.isHideResults = hide_results
        this.isPoll = accept_any_answers
        let correctAnswers = ''
        if (correct_answers) {
          correctAnswers = Object.values(correct_answers)
            .map(answer => answer.answer)
            .join(';')
        }
        this.dataCorrectAnswer = answer || correctAnswers
        this.dataSelectAnswer = answers || correct_answers
        
        this.dataScaleAnswer = answers_by_scale ? answers_by_scale[0] : answers_scale

        this.initValues()

        this.dataResultType = this.result_type_choices.find((obj) => {
          return obj.value === result_type && (obj.is_poll === this.isPoll || obj.is_poll === undefined)
        })
      }
    },
    dataResultType (val) {
      if  (Object.entries(this.data).length === 0) {
        this.isHideResults = (this.dataResultType.value === 'enter_correct_answer')
        if (this.type_exercise === '1') {
          this.isHideResults = true
        }
      }
    },
    point (val) {
      let point = String(val)
      if (point.includes('.') || point.includes(',')) {
        point = point.replace(/[.,].*$/, '')
        this.point = Number(point)
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isChoosingFromAnswers () {
      return (this.dataResultType.value === 'radio_answers' || this.dataResultType.value === 'flags_answers')
    },
    isFormValid () {
      if (this.dataResultType) {
        let validAnswer = true
        if (this.dataResultType.value === 'radio_answers' || this.dataResultType.value === 'flags_answers') {
          for (const answer of this.dataSelectAnswer) {
            if (!answer.answer && !answer.image && !answer.image_path) {
              validAnswer = false
            }
          }
        }
        if (this.dataResultType.value === 'answer_by_scale') {
          if (!this.dataScaleAnswer.from || !this.dataScaleAnswer.to) {
            validAnswer = false
          }
        }
        return !this.errors.any() && this.dataName && this.dataResultType && validAnswer
      }
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  mounted () {
  },
  methods: {
    onImgChange (answer = null, index) {
      answer.image_file = this.$refs.pictureInput[index].file
      answer.image = this.$refs.pictureInput[index].image
      answer.delete_image = 0
    },
    onImgFromChange () {
      this.dataScaleAnswer.from_image_file = this.$refs.pictureInputFrom.file
      this.dataScaleAnswer.from_image = this.$refs.pictureInputFrom.image
      delete this.dataScaleAnswer.from_image_delete
    },
    onImgToChange () {
      this.dataScaleAnswer.to_image_file = this.$refs.pictureInputTo.file
      this.dataScaleAnswer.to_image = this.$refs.pictureInputTo.image
      delete this.dataScaleAnswer.to_image_delete
    },

    onImgRemove (answer) {
      answer.image = null
      answer.image_path = null
      answer.delete_image = 1
    },
    onImgFromRemove () {
      this.dataScaleAnswer.from_image = null
      this.dataScaleAnswer.from_image_delete = 1
    },
    onImgToRemove () {
      this.dataScaleAnswer.to_image = null
      this.dataScaleAnswer.to_image_delete = 1
    },

    initValues () {
      if (this.data.index >= 0) return
      this.dataId = null
      this.dataName = ''
      this.dataSelectAnswer = [
        {
          answer: '',
          is_correct: 1,
          image: null,
          score: 0
        }
      ]

      this.dataResultType = { label: this.$t('trainings.exercises.labels.repetitions'), value: 'repetitions' }
    },
    submitData () {
      this.actionButtonDisabled = true

      this.$validator
        .validateAll()
        .then((result) => {
          if (!result) {
            this.actionButtonDisabled = false
            return
          }
          const obj = {
            id: this.dataId,
            index: this.dataIndex,
            name: this.dataName,
            result_type: this.dataResultType.value,
            is_required: +this.isRequired,
            point: +this.point || 0,
            hide_results: +this.isHideResults,
            uuid: this.dataUuid
          }
          if (this.dataResultType.value === 'enter_correct_answer') {
            obj.answer = this.dataCorrectAnswer
          }
          if (this.dataResultType.value === 'radio_answers' || this.dataResultType.value === 'flags_answers') {
            this.dataSelectAnswer.map((answer) => {
              answer.score = answer.score || 0
            })

            if (this.dataSelectAnswer) {
              obj.answers = this.dataSelectAnswer
            }
          }
          if (this.dataResultType.is_poll) {
            obj.accept_any_answers = 1
            this.dataSelectAnswer.forEach(answer => {
              answer.is_correct = 1
            })
          } else {
            obj.accept_any_answers = 0
          }
          if (this.dataResultType.value === 'answer_by_scale') {
            this.dataScaleAnswer.from_score = this.dataScaleAnswer.from_score || 0
            this.dataScaleAnswer.to_score = this.dataScaleAnswer.to_score || 0

            if (this.dataScaleAnswer) {
              obj.answers_by_scale = [this.dataScaleAnswer]
            }
            this.dataScaleAnswer = {}
          }

          if (typeof this.$props.type_exercise !== 'undefined') {
            obj.type_exercise = this.$props.type_exercise !== '' ? this.$props.type_exercise : ''
          }
          if (this.dataId) {
            this.$store
              .dispatch('training/updateExercise', obj)
              .catch((err) => {
                console.error(err)
              })
              .then(() => {
                this.actionButtonDisabled = false
              })
          } else {
            delete obj.id
            obj.uuid = obj.uuid === undefined ? new Date().getTime() : obj.uuid
            this.$store
              .dispatch('training/addExercise', obj)
              .catch((err) => {
                console.error(err)
              })
              .then(() => {
                this.actionButtonDisabled = false
              })
          }
          this.$emit('closeSidebar')
          this.initValues()     
        })
        .catch(() => {
          this.actionButtonDisabled = false
        })
    },

    addSelectAnswer () {
      this.dataSelectAnswer.push({
        answer: '',
        is_correct: 0,
        image: null,
        score: 0
      })
    },

    onChecked (answer) {

      if (this.dataResultType.value === 'radio_answers') {
        
        for (const answer of this.dataSelectAnswer) {
          this.$store.commit('category/setAnswerCorrectness', { answer, isCorrect: 0 })
        }
        this.$store.commit('category/setAnswerCorrectness', { answer, isCorrect: 1 })
      }
      
      if (this.dataResultType.value === 'flags_answers') {
        const isCorrect = answer.is_correct === 1 ? 0 : 1
        this.$store.commit('category/setAnswerCorrectness', { answer, isCorrect })
      }
    }
  }
}
</script>

<style lang="scss">
  .v-select *>* {
    line-height: 27px;
  }
  .focusx {
    border: 1px solid #DA7E3C !important;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25) !important;
  }
  
</style>

<style lang="scss" scoped>

.input-label {
  font-size: 12px;
  font-weight: 500;
  position: relative;
  bottom: 4px;
}

.text-danger {
  color: red !important;
}

  ::v-deep .vs-alert {
    color: #B9B9B9;
    padding-right: 80px;
    font-size: 12px;

    .vs-icon {
      color: #DA7E3C;
      font-size: 18px;
      height: 18px;
      top: 10px;
    }
  }

  .add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 51199;
  }

  ::v-deep .vs-sidebar {
    z-index: 51199;
    width: 600px;
    max-width: 90vw;
   
    .select-answer {
      &__item {
        padding-bottom: 12px;
        border-bottom: 1px solid #B9B9B9;
      }
      &__input {
        display: flex;
        flex-wrap: no-wrap;
        margin-bottom: 0.5em;
        align-items: center;
        gap: 1em;

        div:first-child {
          flex: 1 1 100%;
        }
        .score {
          flex: 1 1 auto;
          text-align: center;
          width: 54px;
          height: 38px;
          border-radius: 6px;
          border-top: 1px solid;
          border-left: 1px solid;
          border-width: 1px;
          border-color: #B9B9B9 !important;
          font-size: 16px;
          input {
            text-align: center;
          }
          &:hover {
            border: 1px solid var(--text, #DA7E3C) !important;
            box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25) !important;
          }
        }
        button {
          flex: 0 0 auto;
        }
      }
    }
  }
}

.label {
  position: absolute !important;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }

  .img-upload {
    margin-top: 1rem;
  }
}

.v-select-wrapper {
  margin-top: 1.25rem !important;
}

.prepend-text {
  font-size: 0.9em;
}

.vs-checkbox-primary input:checked+.vs-checkbox {
  border-width: 1px !important;
}

.add-select-answer-item {
  display: flex;
  justify-content: flex-end;
}
</style>
