import helpers from '@/helpers/helpers.js'

export default {
  retrieveSuite (state, suite) {
    state.suite = suite
  },
  retrieveList (state, list) {
    state.list = list
  },
  retrieveEdit (state, data) {
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) continue
      state.edit[helpers.kebabCaseToCamelCase(prop)] = (data[prop] === null) ? '' : data[prop]
    }
  },
  addExercise (state, item, test = 0, exercisesCreateName = 'exercisesCreate', exercisesName = 'exercises') {
    // была проблема при редактировании только что добавленного упражнения, т.к. он после обновления падал в массив обновляемых данных, хотя он еще даже не создан
    // поэтому ввел доп ключ uuid для поиска упражнений  разных массивах
    let isUpdating = false

    if (item.hasOwnProperty('type_exercise') && typeof item.type_exercise !== 'undefined') {
      exercisesCreateName = exercisesCreateName.concat(item.type_exercise)
      exercisesName = exercisesName.concat(item.type_exercise)
    }
    //Для событий есть входное и выходное задание, обозначеные индексами 1 и 2 соответственно
    let nameEx = state[exercisesCreateName]
    if (test !== 0) {
      nameEx = state[exercisesCreateName] + test
    }
    for (const key in state[exercisesCreateName]) {
      if (state[exercisesCreateName][key].uuid !== undefined && item.uuid === state[exercisesCreateName][key].uuid) {
        state[exercisesCreateName][key] = item
        isUpdating = true
      }
    }
    for (const key in state[exercisesName]) {
      if (state[exercisesName][key].uuid !== undefined && item.uuid === state[exercisesName][key].uuid) {
        state[exercisesName][key] = item
        isUpdating = true
      }
    }

    if (!isUpdating) {
      state[exercisesCreateName].push(JSON.parse(JSON.stringify(item)))
      state[exercisesName].push(item)
    }
  },
  removeExercise (state, obj) {
    let exercisesDeleteName = 'exercisesDelete'
    let nameExercises = 'exercises'
    if (typeof obj.type_exercise !== 'undefined' && obj.hasOwnProperty('type_exercise')) {
      exercisesDeleteName = exercisesDeleteName.concat(obj.type_exercise)
      nameExercises = nameExercises.concat(obj.type_exercise)
    }

    //Проверяем, есть ли id у упражнения
    if (state[nameExercises][obj.index].hasOwnProperty('id') === true) {
      state[exercisesDeleteName].push({id: state[nameExercises][obj.index].id})
      //Если удаляют только что добавленную тренировку(у них нет id-шника)
    } else if (typeof obj.type_exercise !== 'undefined' &&  state['exercisesCreate' . concat(obj.type_exercise)].hasOwnProperty(obj.index))  {
      state['exercisesCreate' . concat(obj.type_exercise)].splice(obj.index, 1)
    }
    state[nameExercises].splice(obj.index, 1)
  },
  updateExercise (state, item) {
    let exercisesUpdateName = 'exercisesUpdate'
    let nameExercises = 'exercises'
    if (item.hasOwnProperty('type_exercise')) {
      exercisesUpdateName = exercisesUpdateName.concat(item.type_exercise)
      nameExercises = nameExercises.concat(item.type_exercise)
    }
    state[exercisesUpdateName].push(JSON.parse(JSON.stringify(item)))
    Object.assign(state[nameExercises][item.index], item)
  },
  setExercises (state, payload) {
    state.exercises = payload
  },
  setExercises1 (state, payload) {
    state.exercises1 = payload
  },
  setExercises2 (state, payload) {
    state.exercises2 = payload
  },
  resetExercises (state) {
    state.exercises = []
    state.exercises1 = []
    state.exercises2 = []

    state.exercisesCreate = []
    state.exercisesCreate1 = []
    state.exercisesCreate2 = []

    state.exercisesUpdate = []
    state.exercisesUpdate1 = []
    state.exercisesUpdate2 = []

    state.exercisesDelete = []
    state.exercisesDelete1 = []
    state.exercisesDelete2 = []
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  },
  copyTraining (state, [index, item]) {
    state.suite.splice(index + 1, 0, item)
  },
  removeTraining (state, index) {
    state.suite.splice(index, 1)
  }
}
