import FormError from './../../modules/formError'

export default {
  suite: [],
  select: [],

  templates: [
    {
      name: 'physical'
    },
    {
      name: 'digital'
    },
    {
      name: 'virtual_card'
    },
    {
      name: 'benefit'
    }
  ],

  edit: {
    name: '',
    price: '',
    description: '',
    blockDescription: '',
    minUserLevel: '',
    isDigital: '',
    stock: ''
  },
  codes: {},
  virtualCardTypes: {},
  formError: new FormError() 
}
