<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-2">
        <loading v-if="loading"></loading>
      </div>
    </div>

    <div v-if="!loading" class="vx-card tabs-container px-6 pt-6 pb-3">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col w-full mb-4">
          <vs-input class="w-full" :label-placeholder="$t('shop_categories.' + type + '.labels.title')" v-model="label" @change="getSlug()" />
          <span class="text-danger text-sm" v-show="formError.has('label')">{{ $t(formError.get('label')) }}</span>
        </div>
      </div>
      <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input class="w-full" :label-placeholder="$t('shop_categories.' + type + '.labels.slug')" v-model="slug" />
            <span class="text-danger text-sm" v-show="formError.has('slug')">{{ $t(formError.get('slug')) }}</span>
          </div>
        </div>
    </div>

    <div class="vx-row fixed-buttons">
      <div class="vx-col w-full flex gap-3">
        <vs-button 
          @click="action(stayAfter = true)" 
          :disabled="actionButtonDisabled || isOnlyRead">
          {{ $t('shop_categories.' + type + '.buttons.' + type) }}
        </vs-button>
        <vs-button 
          @click="action()" 
          :disabled="actionButtonDisabled || isOnlyRead">
          {{ $t('shop_categories.' + type + '.buttons.' + type) }} {{ $t('suite.and_exit') }}
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import loading from '@/views/components/Loading.vue'
import helpers from '@/helpers/helpers.js'

export default {
  components: {
    loading
  },
  data () {
    return {
      loading: true,
      type: 'create',
      label: '',
      slug: '',
      id: null,
      actionButtonDisabled: false
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-shop'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['shop_categories/formError']
    }
  },
  methods: {
    action (stayAfter) {
      this.loading = !stayAfter
      this.actionButtonDisabled = true
      const patch = (this.type === 'create') ? 'shop_categories/store' : 'shop_categories/update'
      this.dispatch(patch, stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        const payload = {
          id: this.id,
          label: this.label,
          slug: this.slug === '' ? helpers.slugify(this.label) : this.slug
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name: 'shop-categories'
          },
          routerPushToEdit: {
            name: 'shop-category-edit'
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
      } finally {
        this.loading = false
        this.actionButtonDisabled = false
      }
    },
    getSlug () {
      this.slug = helpers.slugify(this.label)
    }
  },
  created () {
    this.id = this.$route.params.id
    if (this.$router.currentRoute.name === 'shop-category-edit') {
      this.type = 'edit'
      this.$store.dispatch('shop_categories/edit', {id: this.id})
        .then(() => {
          const edit = this.$store.getters['shop_categories/edit']
          console.log('edit', edit)
          this.id = edit.id
          this.label = edit.label
          this.slug = edit.slug
          this.loading = false
        })
    } else {
      this.loading = false
    }
    this.$store.commit('shop_categories/clearFormError')
  }
}
</script>

<style scoped>
</style>
