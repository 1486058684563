import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'

export default {
  getSuite ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/trainings', { params: payload })
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/trainings/list')
        .then((response) => {
          commit('retrieveList', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  create ({ commit, state }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    payload.exercises = state.exercises
    helpers.getFormData(formData, payload)
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/trainings', formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },

  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/trainings/${payload.id}/edit`, { params: payload })
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          commit('setExercises', response.data.data.exercises)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  update ({ commit, state }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    payload.exercises = {
      update: state.exercisesUpdate,
      delete: state.exercisesDelete,
      create: state.exercisesCreate
    }
    helpers.getFormData(formData, payload)
    formData.append('_method', 'PATCH')
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/trainings/${payload.id}`, formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },

  addExercise ({ commit }, payload) {
    commit('addExercise', payload)
  },

  removeExercise ({ commit }, obj) {
    commit('removeExercise', obj)
  },

  updateExercise ({ commit }, payload) {
    commit('updateExercise', payload)
  },

  delete ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/trainings/${payload.id}`)
        .then((response) => {
          commit('removeTraining', payload.index)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  copy ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/admin-panel/trainings/${payload.id}/copy`)
        .then((response) => {
          commit('copyTraining', [payload.index, response.data.data])
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  sort ({ commit }, payload) {
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    formData.append('_method', 'PATCH')
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/trainings/sort', formData)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

}
