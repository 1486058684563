import helpers from '@/helpers/helpers.js'
import moment from 'moment'
export default {
  retrieveSuite (state, suite) {
    suite.map(message => {
      message.deleted_at = message.deleted_at ? moment(message.deleted_at).format('DD.MM.YYYY HH:mm') : null
      message.created_at = message.created_at ? moment(message.created_at).format('DD.MM.YYYY HH:mm') : null
      return message
    })
    state.suite = suite
  }
}
