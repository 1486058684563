<template>
  <vs-chip class="ag-grid-cell-chip" color="success">
    <span>{{ params.value + '/' + params.trainingsCount}}</span>
  </vs-chip>
</template>

<script>
export default {
  name: 'CellRendererStatus'
}
</script>

<style lang="scss" scpoped>
  .ag-grid-cell-chip {
    &.vs-chip-success {
      background: rgba(var(--vs-success),.15);
      color: rgba(var(--vs-success),1) !important;
      font-weight: 500;
    }
  }
</style>
