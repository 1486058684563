import FormError from './../../modules/formError'

export default {
  suite: [],
  list: [],
  trainings: [],
  editData: {
    name: '',
    email: '',
    email_validate: '',
    id: '',
    role: '',
    avatar_url: '',
    onesignal_external_id: '',
    onesignal_players_id: '',
    all_coin: '',
    all_point: '',
    coins: '',
    shop_point: ''
  },

  edit: {
    name: '',
    email: '',
    city: '',
    department: '',
    team: '',
    id: '',
    role: ''
  },
  points: [],
  coins: [],
  steps: [],
  challenges: [],
  userData: {},
  editPointData: null,
  adminAccesses: {
    accesses: [],
    role: '',
    name: '',
    email: '',
    id: ''
    
  },
  adminAccessesModules: {
    access_values: [],
    modules: []
  },
  formError: new FormError()
}
