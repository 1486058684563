<template>
  <div id="demo-basic-card">


    <div v-if="!isOnlyRead" class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-button color="primary" type="gradient" icon-pack="feather" icon="icon-plus" class="add-button"
          @click="$router.push({ name: 'shop-category-create' })"></vs-button>
      </div>
      <div class="vx-col md:w-1/2 w-full mb-base flex justify-end">
        <vs-button color="primary" type="border" icon-pack="feather" icon="icon-list"
          @click="showSortTable = !showSortTable">{{ $t('shop_categories.sort') }}</vs-button>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <loading v-if="loading"></loading>
        <div class="vx-card p-6" v-if="!loading && !showSortTable">
          <ag-grid-vue 
            @gridReady="onGridReady" 
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4" 
            domLayout="autoHeight"
            :columnDefs="columnDefs" 
            :defaultColDef="defaultColDef"
            :rowData="suite" 
            rowSelection="multiple" 
            colResizeDefault="shift" 
            :animateRows="true" 
            :floatingFilter="true"
            :pagination="true" 
            :paginationPageSize="paginationPageSize" 
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl">
          </ag-grid-vue>

          <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
        </div>

        <vs-popup class="holamundo" title="" :active.sync="deleteShopCategoriesPopupActive">
          <p>{{ deleteShopCategoriesPopupContent }}</p>
          <div v-if="deleteProcess">
            <font-awesome-icon icon="spinner" spin />
          </div>
          <div class="delete-confirm-popup-buttons" v-else>
            <vs-button color="primary" type="filled" @click="deleteShopCategories()">{{
              $t('shop_categories.confirm_delete.buttons.confirm') }}</vs-button>
            <vs-button color="warning" type="filled" @click="deleteShopCategoriesPopupActive = false">{{
              $t('shop_categories.confirm_delete.buttons.cancel') }}</vs-button>
          </div>
        </vs-popup>
      </div>

    </div>
    <div v-if="showSortTable && !loading" class="sort-table-wrapper">
      <vs-list>
        <draggable :list="suite" @change="sort()">
          <transition-group>
            <vs-list-item class="list-item" v-for="categories in suite" :key="categories.id" :title="categories.label">
            </vs-list-item>
          </transition-group>
        </draggable>
      </vs-list>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import loading from '../../components/Loading'
import CellRendererLink from '../../components/cell-renderer/CellRendererLink'
import CellRendererActions from '../../components/cell-renderer/ShopCategories/CellRenderActions'
import draggable from 'vuedraggable'

export default {
  components: {
    AgGridVue,
    loading,
    CellRendererActions,
    CellRendererLink,
    draggable
  },
  data () {
    return {
      loading: true,
      gridApi: null,
      icons: null,
      gridOptions: {
        context: {
          componentParent: this
        },
        suppressMovableColumns: true
      },
      maxPageNumbers: 7,
      defaultColDef: {
        resizable: true,
        flex: 1,
        cellStyle: { 'text-align': 'center' }
      },
      columnDefs: [
        {
          headerName: this.$t('shop_categories.table.id'),
          field: 'id',
          minWidth: 70,
          maxWidth: 80,
          headerClass: 'text-center',
          sortable: true
        },
        {
          headerName: this.$t('shop_categories.table.title'),
          field: 'label',
          minWidth: 250,
          flex: 2,
          cellStyle: { 'text-align': 'left' },
          filter: true
        },
        {
          headerName: this.$t('shop_categories.table.actions'),
          field: 'id',
          minWidth: 80,
          maxWidth: 120,
          cellRenderer: 'CellRendererActions'
        }
      ],
      components: {
        CellRendererLink,
        CellRendererActions
      },
      deleteShopCategoriesPopupActive: false,
      deleteShopCategoriesPopupContent: '',
      deleteProcess: false,
      deleteShopCategoriesId: 0,
      showSortTable: false
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-shop'] === 'read'
      }
    },
    suite () { return this.$store.getters['shop_categories/suite'] },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 30
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 1
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        if (this.gridApi) this.paginationGoToPageCached(val)
      }
    }
  },
  created () {
    this.$store.dispatch('shop_categories/getSuite').then(() => {
      this.loading = false
    })
  },
  methods: {
    sort () {
      const payload = {
        suite: this.suite
      }
      this.$store.dispatch('shop_categories/sort', payload)
    },
    deleteShopCategories () {
      this.deleteProcess = true
      this.$store.dispatch('shop_categories/destroy', { id: this.deleteShopCategoriesId }).catch(error => {
        this.$vs.notify({
          title: this.$t('shop_categories.suite.errors.title'),
          text: error,
          color: 'danger',
          time: 10000
        })
      }).then(() => {
        this.deleteProcess = false
        this.deleteShopCategoriesPopupActive = false
      })
    },
    onGridReady (params) {
      this.gridApi = params.api
    }
  }
}
</script>

<style lang="scss" scoped>
.add-button {
  margin-bottom: 30px;
}

.delete-confirm-popup-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

</style>
