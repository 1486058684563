<template>
  <div id="demo-basic-card" class="h-screen">
    <div class="vx-row">
      <div v-if="!isOnlyRead" class="vx-col w-full mb-base">
        <vs-button color="primary" type="gradient" icon-pack="feather" icon="icon-plus" class="add-button" @click="$router.push({ name: 'create-content-page'})"></vs-button>
      </div>
      <div class="vx-col w-full mb-base">
        <loading v-if="loading"></loading>
        <vs-table :data="suite" v-else>

          <template slot="thead">
            <vs-th>{{ $t('content_pages.suite.table.id') }}</vs-th>
            <vs-th>{{ $t('content_pages.suite.table.slug') }}</vs-th>
            <vs-th>{{ $t('content_pages.suite.table.name') }}</vs-th>
            <vs-th>{{ $t('content_pages.suite.table.date') }}</vs-th>
            <vs-th>{{ $t('content_pages.suite.table.edit') }}</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td :data="data[indextr].id">
                {{ data[indextr].id }}
              </vs-td>

              <vs-td :data="data[indextr].slug">
                {{ data[indextr].slug }}
              </vs-td>

              <vs-td :data="data[indextr].name">
                {{ data[indextr].name }}
              </vs-td>

              <vs-td :data="data[indextr].created_at">
                {{ data[indextr].created_at }}
              </vs-td>

              <vs-td :data="data[indextr].date">
                <vs-button type="line"  @click="$router.push({ name: 'edit-content-page', params: { id: data[indextr].id }})">Edit</vs-button>
              </vs-td>

            </vs-tr>
          </template>

        </vs-table>
      </div>
    </div>
  </div>
</template>

<script>
import loading from '@/views/components/Loading.vue'

export default{
  components: {
    loading
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        const accessUser = this.$store.getters.accessUser
        return accessUser['admin-settings-content-pages'] === 'read' ||  
               accessUser['admin-settings'] === 'read'
      }
    },
    suite () { return this.$store.getters['content_page/suite'] }
  },
  created () {
    this.$store.dispatch('content_page/getSuite').then(() => {
      this.loading = false
    })
  }
}
</script>

<style lang="scss" scoped>
  .add-button {
    margin-bottom: 30px;
  }
</style>
