<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showMessage()" />
  </div>
</template>

<script>

export default {
  name: 'CellRendererActions',
  methods: {
    showMessage () {
      this.params.context.componentParent.chatMessagePopupActive = true
      this.params.context.componentParent.chatMessagePopupContent = this.params.data.comment
      this.params.context.componentParent.chatMessagePopupId = this.params.data.id
      this.params.context.componentParent.categoryId = this.params.data.category_id
      this.params.context.componentParent.userName = this.params.data.user_name
      this.params.context.componentParent.imagePath = this.params.data.image_path
      this.params.context.componentParent.deleted_at = this.params.data.deleted_at
      this.params.context.componentParent.chatMessagePopupActive = true
    }
  }
}
</script>