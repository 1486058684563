import helpers from '@/helpers/helpers.js'
// import moment from 'moment'
export default {
  retrieveSuite (state, suite) {
    state.suite = [...state.suite, ...suite]
  },
  retrieveReject (state, id) {
    const index = helpers.searchInArray('id', id, state.suite, true)
    state.suite[index].moderation_status = 'rejected'
  },
  retrieveRecover (state, id) {
    const index = helpers.searchInArray('id', id, state.suite, true)
    state.suite[index].moderation_status = 'pending'
  },
  retrieveStatuses (state, statuses) {
    state.statuses = Object.entries(statuses).map(([id, label]) => ({ id, label }))
  },
  setFormError (state, err) {
    state.formError.record(err.errors ? err.errors : {})
  },
  clearFormError (state) {
    state.formError.clear()
  }
}
