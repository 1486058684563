<template>
  <vx-card>
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-32 mt-32">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-show="!loading">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('clubs.edit.labels.opened') }}</label>
          <vs-switch v-model="opened" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
          <span class="text-danger text-sm" v-show="formError.has('opened')">{{ $t(formError.get('opened')) }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <label>{{ $t('clubs.edit.labels.expand_on_home') }}</label>
          <vs-switch v-model="expandOnHome" icon-pack="feather" vs-icon="icon-check"> </vs-switch>
          <span class="text-danger text-sm" v-show="formError.has('expandOnHome')">{{ $t(formError.get('expandOnHome')) }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 mb-2 w-full">
          <vs-input class="w-full" :label-placeholder="$t('clubs.edit.labels.name')" v-model="name" />
          <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
        </div>
        <div class="vx-col md:w-1/2 mb-2 w-full">
          <vs-input class="w-full" :label-placeholder="$t('clubs.edit.labels.address')" v-model="address" />
          <span class="text-danger text-sm" v-show="formError.has('address')">{{ $t(formError.get('address')) }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <vs-textarea class="w-full mb-2" :label="$t('clubs.edit.labels.description')" v-model="description" />
          <span class="text-danger text-sm" v-show="formError.has('description')">{{ $t(formError.get('description')) }}</span>
        </div>
      </div>
      <div class="vx-row" v-if="loaded">
        <div class="vx-col w-full mb-2">
          <vx-card no-shadow card-border :title="$t('clubs.edit.labels.block_description')">
            <editor ref="editor" :config="editorJsConfig" />
            <span class="text-danger text-sm" v-show="formError.has('block_description')">{{ $t(formError.get('block_description')) }}</span>
          </vx-card>
        </div>
      </div>
      <vx-card class="mt-base" no-shadow no-border>
        <div class="vx-col w-full mb-2">
          <div class="image-container" style="width: 150px; height:150px; margin: 0 auto;">
            <picture-input
              v-if="!loading"
              ref="pictureInput"
              @prefill="onImgPrefill"
              @change="onImgChange"
              @error="onImgError"
              width="150"
              height="150"
              margin="16"
              :accept="this.$baseImageAcceptTypes"
              buttonClass="btn"
              size="10"
              :hideChangeButton="true"
              :crop="false"
              :prefill="imageUrl"
              :alertOnError="false"
              :customStrings="{
              drag: $t('clubs.edit.labels.image'),
            }"
            >
            </picture-input>
            <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
          </div>
        </div>
        <div v-if="imgCrop" class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-crop-container">
              <cropper
                ref="cropper"
                classname="cropper"
                :src="imgCrop"
                :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }"
                @change="changeCropImg"
                :stencil-props="{
                aspectRatio: 1 / 1,
              }"
              ></cropper>
            </div>
          </div>
        </div>
      </vx-card>

      <div class="vx-row fixed-buttons">
        <div class="vx-col w-full flex gap-3">
          <vs-button @click="action(stayAfter = true)" :disabled="updateButtonDisabled || isOnlyRead">{{ $t('clubs.' + type + '.button') }}</vs-button>
          <vs-button  @click="action()" :disabled="updateButtonDisabled || isOnlyRead">{{ $t('clubs.' + type + '.button') }} {{ $t('suite.and_exit') }}</vs-button>
        </div>
      </div>
    </div>
    <vs-popup class="holamundo" title="Image error" :active.sync="imageErrorPopupActive">
      <p>{{ imageErrorPopupMessage }}</p>
    </vs-popup>

    <vs-popup class="holamundo" title="" :active.sync="ErrorParsePopupActive">
      <p>{{ $t('clubs.edit.error_parse.content') }}</p>
      <div class="error-parse-button-confirm">
        <vs-button color="warning" type="filled" @click="ErrorParsePopupActive=false">{{ $t('clubs.edit.error_parse.button_confirm') }}</vs-button>
      </div>
    </vs-popup>
  </vx-card>
</template>

<script>

import PictureInput from 'vue-picture-input'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import editorJsConfig from '@/editorjs/config.js'
import loading from '@/views/components/Loading.vue'
import helpers from '@/helpers/helpers.js'

export default {
  components: {
    PictureInput,
    Cropper,
    loading
  },
  data () {
    return {
      ErrorParsePopupActive: false,
      id: 0,
      name: '',
      image: null,
      imageUrl: '',
      imgCrop: null,
      imgWidth: null,
      imgHeight: null,
      isChangedImg: false,
      address: '',
      description: '',
      blockDescription: null,
      opened: false,
      expandOnHome: false,
      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error',
      editorJsConfig: Object.assign({}, editorJsConfig),
      editorOption: {
        modules: {
          toolbar: '#toolbar'
        }
      },
      updateButtonDisabled: false,
      loaded: false,
      loading: true,
      type: ''
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        return this.$store.getters.accessUser['admin-clubs-with-program'] === 'read'
      }
    },
    formError () {
      return this.$store.getters['club/formError']
    }
  },
  methods: {
    onImgPrefill () {
      this.imgCrop = this.$refs.pictureInput.image
      this.image = this.imgCrop
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    changeCropImg ({coordinates, canvas}) {
      if (!this.imgWidth && !this.imgHeight) {
        this.imgWidth = coordinates.width
        this.imgHeight = coordinates.height
      }
      if ((coordinates.width === this.imgWidth || coordinates.height === this.imgHeight) && this.imageUrl) {
        this.isChangedImg = false
      } else {
        this.isChangedImg = true
      }
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },
    
    action (stayAfter) {
      this.loading = !stayAfter
      this.updateButtonDisabled = true

      const patch = (this.type === 'create') ? 'club/create' : 'club/update'
      this.dispatch(patch, stayAfter)
    },

    async dispatch (patch, stayAfter) {
      try {
        const blockDescription = await this.$refs.editor.state.editor.save()
        const payload = {
          id: this.id,
          name: this.name,
          image: (this.isChangedImg && this.image !== null) ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : null,
          address: this.address,
          description: this.description,
          block_description: JSON.stringify(blockDescription),
          opened: +this.opened,
          expand_on_home: +this.expandOnHome
        }

        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          stayAfter: stayAfter,
          routerPush: {
            name:'clubs'
          },
          routerPushToEdit: {name: 'edit-club'}
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
        
      } finally {
        this.loading = false
        this.updateButtonDisabled = false
      }
      
    }
  },
  
  created () {
    if (this.$router.currentRoute.name === 'edit-club') {
      this.type = 'edit'
      this.id = this.$route.params.id
      this.$store.dispatch('club/edit', { id: this.id }).then(() => {
        const edit = this.$store.getters['club/edit']
        this.name = edit.name
        this.imageUrl = this.getUrl(edit.logoPath, `${this.$http.defaults.baseURL}/api/admin-panel`)

        this.address = edit.address

        this.description = edit.description
        this.blockDescription = edit.blockDescription
        if (this.blockDescription !== '') {
          try {
            this.editorJsConfig.data = JSON.parse(edit.blockDescription)
          } catch (e) {
            this.ErrorParsePopupActive = true
            this.editorJsConfig.data = ''
          }
        }
        this.opened = edit.opened
        this.expandOnHome = edit.expandOnHome
        this.loaded = true
        this.loading = false
      })
    } else {
      this.type = 'create'
      this.loaded = true
      this.loading = false
    }
  }
}
</script>

<style>
.image-container .preview-container {
  margin: 0 !important;
}

.error-parse-button-confirm {
  margin-top: 20px;
}
</style>
