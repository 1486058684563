import FormError from './../../modules/formError'

export default {
  suite: [],
  select: [],
  edit: {
    title: '',
    content: '',
    url: '',
    image_path: ''
  },
  formError: new FormError()
}
