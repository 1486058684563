import axios from '@/axios.js'
import FileSaver from 'file-saver'
import helpers from '@/helpers/helpers.js'

export default {
  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/shop/orders')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/shop/orders/${payload.id}/edit`, { params: payload })
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    const formData = new FormData()
    helpers.getFormData(formData, payload)
    formData.append('_method', 'PATCH')
    return new Promise((resolve, reject) => {
      axios.post(`/api/admin-panel/shop/orders/${payload.id}`, formData, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  delete (context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/shop/orders/${payload.id}`)
        .then((response) => {
          context.commit('removeShopOrderById', payload.id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  downloadOrders (context) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/shop/orders/download', {
        responseType: 'blob'
      })
        .then((response) => {
          FileSaver.saveAs(response.data, 'orders.xlsx')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  downloadFile (context, id) {
    console.log('downloadFile', id)
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/shop/orders/files/${id}`, {
        responseType: 'blob'
      })
        .then((response) => {
          FileSaver.saveAs(response.data)
          resolve()
        })
        .catch((error) => {
          console.error('Error downloading file:', error)
          reject(error)
        })
    })
  }
}
