import state from './moduleTeamState.js'
import mutations from './moduleTeamMutations.js'
import actions from './moduleTeamActions.js'
import getters from './moduleTeamGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
