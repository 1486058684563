import state from './moduleServicesState.js'
import mutations from './moduleServicesMutations.js'
import actions from './moduleServicesActions.js'
import getters from './moduleServicesGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
