import state from './moduleCommonGoalsState.js'
import mutations from './moduleCommonGoalsMutations.js'
import actions from './moduleCommonGoalsActions.js'
import getters from './moduleCommonGoalsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
