<!-- =========================================================================================
    File Name: FeatherIcon.vue
    Description: Feather icon component. Creates feather icon based on 'icon' prop
    Component Name: FeatherIcon
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<script>
import ChallengeIcon from '../assets/icons/challenge.vue'
import ClipboardList from '../assets/icons/clipboard-list.vue'
import Book2 from '../assets/icons/book2.vue'
import Messages from '../assets/icons/messages.vue'
import Push from '../assets/icons/push.vue'
import Coin from '../assets/icons/coin.vue'
import Checkbox from '../assets/icons/checkbox.vue'
import LayoutBoardSplit from '../assets/icons/layout-board-split.vue'
import CalendarTime from '../assets/icons/calendar-time.vue'
import ClipboardCheck from '../assets/icons/clipboard-check.vue'
import ShoppingCard from '../assets/icons/shopping-card.vue'
import Package from '../assets/icons/package.vue'
import Ticket from '../assets/icons/ticket.vue'
import DeviceMobile from '../assets/icons/device-mobile.vue'

import * as featherIcons from 'vue-feather-icons'

export default {
  functional:true,
  name: 'feather-icon',
  props: {
    icon: { required: true },
    svgClasses: { type: [String, Object, Array], default: '' },
    badge: {}
  },
  render (h, {props, data}) {
    // Add feather classes
    data.staticClass ? data.staticClass = `${data.staticClass  } feather-icon select-none relative` : data.staticClass = 'feather-icon select-none relative'

    const icons = {
      ...featherIcons,
      Challenge: ChallengeIcon,
      Clipboard: ClipboardList,
      Book: Book2,
      Messages: Messages,
      Push: Push,
      Coin: Coin,
      Checkbox: Checkbox,
      LayoutBoardSplit: LayoutBoardSplit,
      CalendarTime: CalendarTime,
      ClipboardCheck: ClipboardCheck,
      ShoppingCard: ShoppingCard,
      Package: Package,
      Ticket: Ticket,
      DeviceMobile: DeviceMobile
    }
    const svg = h(icons[props.icon] || props.icon, {class: props.svgClasses})
    const badgeEl = h('span', {class: 'feather-icon-badge bg-primary text-white h-5 w-5 absolute rounded-full text-xs flex items-center justify-center', style: 'top: -7px; right: -5px'}, [props.badge])
    const children = [svg]
    if (props.badge) children.push(badgeEl)
    return h('span', data, children)
  }
}

</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/featherIcon.scss"
</style>
