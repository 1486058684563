import state from './moduleFaqState.js'
import mutations from './moduleFaqMutations.js'
import actions from './moduleFaqActions.js'
import getters from './moduleFaqGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
