<template>
  <div class="h-full">
    <div class="vx-row" v-if="loading">
      <div class="vx-col w-full mb-32 mt-32">
        <loading v-if="loading"></loading>
      </div>
    </div>
    <div v-show="!loading">
      <p v-if="isOnlyRead" class="vx-col text-black text-center font-bold bg-warning py-4 mb-10">{{ $t('users.edit.access_settings.massage_just_watch') }}</p>
      <vx-card class="mb-6" :title="$t(`trainings.suite.labels.${type}.training_description`)">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <vs-input class="w-full mb-4" :label-placeholder="$t('trainings.edit.labels.name')" v-model="name" />
            <span class="text-danger text-sm" v-show="formError.has('name')">{{ $t(formError.get('name')) }}</span>
          </div>

          <div v-if="['polling', 'polling_health'].includes(type) === false" class="vx-col md:w-1/2 w-full">
            <datepicker
              class="w-full mt-5"
              :format="format"
              v-model="date"
              :placeholder="$t('trainings.edit.labels.date')"
              :language="datepicker.locales[$i18n.locale]"
              :monday-first="$i18n.locale === 'ru' ? true : false"
            ></datepicker>
            <span class="text-danger text-sm" v-show="formError.has('date')">{{ $t(formError.get('date')) }}</span>
          </div>
        </div>
        <div v-if="['polling', 'polling_health'].includes(type) === false" class="vx-row">
          <div class="vx-col w-full mb-2">
            <v-select
              v-model="tags"
              multiple
              :placeholder="$t('categories.edit.labels.tags')"
              :closeOnSelect="false"
              :options="tagsOptions"
              :selectable="option => tags.indexOf(option) < 0"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
            <span class="text-danger text-sm" v-show="formError.has('tags')">{{ $t(formError.get('tags')) }}</span>
          </div>
        </div>
        <div v-if="['polling', 'polling_health'].includes(type) === false" class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-textarea class="w-full mb-2" :label="$t('trainings.edit.labels.announcement')" v-model="announcement" />
            <span class="text-danger text-sm" v-show="formError.has('announcement')">{{ $t(formError.get('announcement')) }}</span>
          </div>
        </div>
        <div v-if="loaded" v-show="['polling', 'polling_health'].includes(type) === false" class="vx-row">
          <div class="vx-col w-full mb-2">
            <vx-card no-shadow card-border :title="$t('trainings.edit.labels.block_description')">
              <editor ref="editor" :config="editorJsConfig" />
              <span class="text-danger text-sm" v-show="formError.has('block_description')">{{
                $t(formError.get('block_description'))
              }}</span>
            </vx-card>
          </div>
        </div>
      </vx-card>
      <vx-card v-if="['polling', 'polling_health'].includes(type) === false" class="mb-6" :title="$t(`trainings.suite.labels.${type}.training_image`)">
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-container" style="width: 150px; height: 150px; margin: 0 auto">
              <picture-input
                v-if="!loading"
                ref="pictureInput"
                @prefill="onImgPrefill"
                @change="onImgChange"
                @remove="onImgRemove"
                @error="onImgError"
                width="150"
                height="150"
                margin="16"
                :accept="this.$baseImageAcceptTypes"
                buttonClass="btn"
                size="10"
                :hideChangeButton="true"
                :crop="false"
                :prefill="imageUrl"
                :alertOnError="false"
                :removable="true"
                removeButtonClass="vs-button vs-button-primary vs-button-filled vs-button-danger small"
                :customStrings="{
                  drag: $t('trainings.edit.labels.image'),
                  remove: $t('trainings.edit.labels.remove_image'),
                }"
              >
              </picture-input>
              <span class="text-danger text-sm" v-show="formError.has('image')">{{ $t(formError.get('image')) }}</span>
            </div>
          </div>
        </div>
        <div v-if="imgCrop" class="vx-row">
          <div class="vx-col w-full mb-2">
            <div class="image-crop-container">
              <cropper
                ref="cropper"
                classname="cropper"
                :src="imgCrop"
                :default-size="cropperDefaultSize"
                :resizeImage="{ wheel: false }"
                @change="changeCropImg"
              ></cropper>
            </div>
          </div>
        </div>
      </vx-card>
      <vx-card :title="$t(`trainings.suite.labels.${type}.training_results`)">
        <div v-if="['polling', 'polling_health'].includes(type) === false">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <vs-input class="w-full mb-4" :label="pointPlaceholder" v-model="point" type="number" />
              <span class="text-danger text-sm" v-show="formError.has('point')">{{ $t(formError.get('point')) }}</span>
            </div>
            <div class="vx-col md:w-1/2 w-full mb-6">
              <vs-input class="w-full mb-4" :label="$t('trainings.create.labels.coin')" v-model="coins" type="number" />
              <span class="text-danger text-sm" v-show="formError.has('coins')">{{ $t(formError.get('coins')) }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-6">
              <vs-input
                class="w-full mb-4"
                :label="$t('trainings.create.labels.required_viewing_time')"
                v-model="requiredViewingTime"
                type="time"
                step="2"
              />
              <span class="text-danger text-sm" v-show="formError.has('required_viewing_time')">{{
                $t(formError.get('required_viewing_time'))
              }}</span>
            </div>
          </div>

          <!-- Скрываем блок. значение 'cancelGender' по умолчанию ставим 'true' -->
          <!-- <div class="vx-row">
            <div class="vx-col w-full mb-6">
              <vs-checkbox v-model="cancelGender">{{ $t('trainings.edit.labels.gender') }} </vs-checkbox>
              <span class="text-danger text-sm" v-show="formError.has('cancel_gender')">{{ $t(formError.get('cancel_gender')) }}</span>
            </div>
          </div> -->
          <div class="vx-row">
            <div class="vx-col w-full mb-6">
              <vs-checkbox v-model="notIncludedToStatistics">{{ $t('trainings.edit.labels.not_included_to_statistics') }}</vs-checkbox>
              <span class="text-danger text-sm" v-show="formError.has('not_included_to_statistics')">{{
                $t(formError.get('not_included_to_statistics'))
              }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <vx-input-group prependClasses="border border-solid border-grey border-r-0">
                <template slot="prepend">
                  <div class="prepend-text">
                    <vs-checkbox icon-pack="feather" icon="icon-camera" class="input-checkbox" v-model="photoRequired">{{
                      $t('trainings.edit.labels.photo_required')
                    }}</vs-checkbox>
                  </div>
                </template>

                <vs-input
                  :disabled="!photoRequired"
                  class="w-full"
                  :placeholder="$t('trainings.edit.labels.photo_required_description')"
                  v-model="photoRequiredDescription"
                />
              </vx-input-group>
              <span class="text-danger text-sm" v-show="formError.has('photo_required')">{{ $t(formError.get('photo_required')) }}</span>
              <span class="text-danger text-sm" v-show="formError.has('photo_required_description')">{{
                $t(formError.get('photo_required_description'))
              }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <vx-input-group class="mb-base" prependClasses="border border-solid border-grey border-r-0">
                <template slot="prepend">
                  <div class="prepend-text">
                    <vs-checkbox icon-pack="feather" icon="icon-message-square" class="input-checkbox" v-model="commentRequired">{{
                      $t('trainings.edit.labels.comment_required')
                    }}</vs-checkbox>
                  </div>
                </template>

                <vs-input
                  :disabled="!commentRequired"
                  class="w-full"
                  :placeholder="$t('trainings.edit.labels.comment_required_description')"
                  v-model="commentRequiredDescription"
                />
              </vx-input-group>
              <span class="text-danger text-sm" v-show="formError.has('comment_required')">{{ $t(formError.get('comment_required')) }}</span>
              <span class="text-danger text-sm" v-show="formError.has('comment_required_description')">{{
                $t(formError.get('comment_required_description'))
              }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

            <vs-table ref="table" :data="exercises">
              <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
                <div class="flex flex-wrap-reverse items-center data-list-btn-container">
                  <!-- ADD NEW -->
                  <div
                    class="
                      btn-add-new
                      p-3
                      mb-4
                      mr-4
                      rounded-lg
                      cursor-pointer
                      flex
                      items-center
                      justify-center
                      text-lg
                      font-medium
                      text-base text-primary
                      border border-solid border-primary
                    "
                    @click="addNewData"
                  >
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                    <span class="ml-2 text-base text-primary">{{ $t(`trainings.edit.buttons.${type}.add_task`) }}</span>
                  </div>
                </div>
              </div>

              <template slot="thead">
                <vs-th sort-key="name">{{ $t('exercises.suite.table.name') }}</vs-th>
                <vs-th sort-key="category">{{ $t('exercises.suite.table.result_type') }}</vs-th>
                <vs-th sort-key="is_required">{{ $t('exercises.suite.table.is_required') }}</vs-th>
                <vs-th>{{ $t('exercises.suite.table.actions') }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                      <p class="product-name font-medium truncate">{{ tr.name }}</p>
                    </vs-td>

                    <vs-td>
                      <p class="product-category">{{ $t(`trainings.exercises.labels.${tr.accept_any_answers ? tr.result_type + '_poll' : tr.result_type }`) }}</p>
                    </vs-td>

                    <vs-td>
                      <p class="product-is-required">{{ tr.is_required ? '+' : '' }}</p>
                    </vs-td>

                    <vs-td class="whitespace-no-wrap">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="w-5 h-5 hover:text-primary stroke-current"
                        @click.stop="editData(tr, indextr)"
                      />
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="ml-2"
                        @click.stop="deleteData(indextr)"
                      />
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </div>
        </div>
        <div class="vx-row fixed-buttons">
          <div class="vx-col w-full">
            <div class="flex gap-3">
              <vs-button @click="update(stayAfter = true)" :disabled="updateButtonDisabled || isOnlyRead">{{
                $t('trainings.edit.buttons.update') 
              }}</vs-button>
              <vs-button @click="update()" :disabled="updateButtonDisabled || isOnlyRead">
                {{ $t('trainings.edit.buttons.update')}} {{ $t('suite.and_exit') }}
              </vs-button>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <vs-popup class="holamundo" :title="$t('trainings.edit.popup.title')" :active.sync="popupActive">
      <p>{{ $t('trainings.edit.popup.message') }}</p>
    </vs-popup>
    <vs-popup class="holamundo" title="Image error" :active.sync="imageErrorPopupActive">
      <p>{{ imageErrorPopupMessage }}</p>
    </vs-popup>

    <vs-popup class="holamundo" title="" :active.sync="ErrorParsePopupActive">
      <p>{{ $t('trainings.edit.error_parse.content') }}</p>
      <div class="error-parse-button-confirm">
        <vs-button color="warning" type="filled" @click="ErrorParsePopupActive=false">{{ $t('trainings.edit.error_parse.button_confirm') }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import PictureInput from 'vue-picture-input'
import Datepicker from 'vuejs-datepicker'
import { en, ru } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import DataViewSidebar from '../components/DataViewSidebar.vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import editorJsConfig from '@/editorjs/config.js'
import loading from '@/views/components/Loading.vue'
import helpers from '@/helpers/helpers.js'

import vSelect from 'vue-select'

export default {
  components: {
    PictureInput,
    quillEditor,
    Datepicker,
    DataViewSidebar,
    Cropper,
    loading,
    'v-select': vSelect
  },
  data () {
    return {
      ErrorParsePopupActive: false,
      id: 0,
      name: '',
      announcement: '',
      description: '',
      blockDescription: null,
      editorJsConfig: Object.assign({}, editorJsConfig),
      editorOption: {
        formats: ['bold', 'header', 'italic', 'link', 'list', 'blockquote', 'image', 'indent'],
        modules: {
          toolbar: '#toolbar'
        }
      },
      image: null,
      imageUrl: '',
      imgCrop: null,
      imgWidth: null,
      imgHeight: null,
      isChangedImg: false,
      imgRemove: false,
      date: '',
      format: 'dd.MM.yyyy',
      cancelGender: true,
      point: 0,
      coins: 0,
      defaultPoint: 30,
      notIncludedToStatistics: false,
      photoRequired: false,
      photoRequiredDescription: '',
      commentRequired: false,
      commentRequiredDescription: '',
      requiredViewingTime: '00:00:00',
      hasResults: 0,
      isMounted: false,
      tags: [],
      tagsOptions: [],

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},

      popupActive: false,
      imageErrorPopupActive: false,
      imageErrorPopupMessage: 'Error',
      updateButtonDisabled: false,
      datepicker: {
        locales: {
          en,
          ru
        }
      },
      loaded: false,
      loading: true,
      type: 'programm'
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        if (this.type === 'polling') {
          return this.$store.getters.accessUser['admin-pollings'] === 'read'
        }
        if (this.type === 'programm') {
          return this.$store.getters.accessUser['admin-clubs-with-program'] === 'read'
        } 
      } 
    },
    exercises () {
      return this.$store.getters['training/exercises']
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.exercises.length
    },
    pointPlaceholder () {
      return this.$t('trainings.create.labels.point').replace('[POINT]', this.defaultPoint)
    },
    formError () {
      return this.$store.getters['training/formError']
    }
  },
  methods: {
    onImgPrefill () {
      this.imgCrop = this.$refs.pictureInput.image
      this.image = this.imgCrop
    },
    onImgChange (img) {
      this.image = this.$refs.pictureInput.file
      this.imgCrop = img
      this.imgRemove = false
      console.log('onImgChange')
    },
    onImgRemove () {
      this.image = null
      this.imgCrop = null
      this.imgRemove = true
    },
    changeCropImg ({coordinates, canvas}) {
      if (!this.imgWidth && !this.imgHeight) {
        this.imgWidth = coordinates.width
        this.imgHeight = coordinates.height
      }
      if ((coordinates.width === this.imgWidth || coordinates.height === this.imgHeight) && this.type === 'edit' && this.imageUrl) {
        this.isChangedImg = false
      } else {
        this.isChangedImg = true
      }
    },
    onImgError (error) {
      this.imageErrorPopupMessage = error.message
      this.imageErrorPopupActive = true
    },
    cropperDefaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },
    update (stayAfter) {
      this.loading = !stayAfter
      this.updateButtonDisabled = true
      this.dispatch('training/update', stayAfter)
    },
    async dispatch (patch, stayAfter) {
      try {
        const blockDescription = await this.$refs.editor.state.editor.save()

        const payload = {
          id: this.id,
          name: this.name,
          announcement: this.announcement,
          description: this.description,
          block_description: JSON.stringify(blockDescription),
          image:
            (this.isChangedImg && this.image !== null) ? helpers.resizeCanvasImage(this.$refs.cropper.getResult().canvas, 800).toDataURL(this.image.type, 1.0) : null,
          image_remove: +this.imgRemove,
          date: moment(this.date).format('DD.MM.YYYY'),
          cancel_gender: +this.cancelGender,
          point: this.point,
          coins: this.coins || 0,
          tags: this.tags,
          not_included_to_statistics: +this.notIncludedToStatistics,
          photo_required: +this.photoRequired,
          photo_required_description: +this.photoRequired ? this.photoRequiredDescription : '',
          comment_required: +this.commentRequired,
          comment_required_description: +this.commentRequired ? this.commentRequiredDescription : '',
          required_viewing_time: this.requiredViewingTime ||  '00:00:00'
        }
        const dispatchParams = {
          vueInstance: this,
          patch: patch,
          payload: payload,
          type: 'edit',
          stayAfter: stayAfter,
          routerPush: {
            name: `${this.type}-trainings`,
            params: { categoryId: this.$route.params.categoryId }
          }
        }
        await helpers.createOrEditPage(dispatchParams)
      } catch (e) {
        console.log('error', e)
        
      } finally {
        this.loading = false
        this.updateButtonDisabled = false
      }
    },
    addNewData () {
      if (this.hasResults) {
        this.popupActive = true
        return
      }
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    deleteData (index) {
      if (this.hasResults) {
        this.popupActive = true
        return
      }
      this.$store.dispatch('training/removeExercise', { index: index }).catch((err) => {
        console.error(err)
      })
    },
    editData (data, index) {

      // if (this.hasResults) {
      //   this.popupActive = true
      //   return
      // }

      this.sidebarData = data
      this.sidebarData.index = index
      this.sidebarData.trainingHasResults = this.hasResults
      this.toggleDataSidebar(true)
    },
    getOrderStatusColor (status) {
      if (status === 'on_hold') return 'warning'
      if (status === 'delivered') return 'success'
      if (status === 'canceled') return 'danger'
      return 'primary'
    },
    getPopularityColor (num) {
      if (num > 90) return 'success'
      if (num > 70) return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50) return 'danger'
      return 'primary'
    },
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    }
  },
  created () {
    this.$store.dispatch('tag/getTagsOptions').then(() => {
      this.tagsOptions = this.$store.getters['tag/tagsOptions']
    })

    this.$store.dispatch('point_setting/getSuite').then(() => {
      this.defaultPoint = this.$store.getters['point_setting/suite'].find(obj => obj.point_type === 'training_result').point
    })

    switch (this.$router.currentRoute.name) {
    case 'programm-edit-training':
      this.type = 'programm'
      break
    case 'challenge-edit-training':
      this.type = 'challenge'
      break
    case 'polling-edit-training':
      this.type = 'polling'
      break
    case 'polling_health-edit-training':
      this.type = 'polling_health'
      break
    }

    this.$store.commit('training/clearFormError')
    this.$store.commit('training/resetExercises')
    this.id = this.$route.params.id
    this.$store.dispatch('training/edit', { id: this.id }).then(() => {
      const edit = this.$store.getters['training/edit']
      this.name = edit.name
      this.announcement = edit.announcement
      this.description = edit.description
      this.blockDescription = edit.blockDescription
      if (this.blockDescription !== '') {
        try {
          this.editorJsConfig.data = JSON.parse(edit.blockDescription)
        } catch (e) {
          this.ErrorParsePopupActive = true
          this.editorJsConfig.data = ''
        }
      }
      this.date = new Date(edit.date)

      this.imageUrl = this.getUrl(edit.imagePath, `${this.$http.defaults.baseURL}/api/admin-panel`)
      
      // this.cancelGender = edit.cancelGender
      this.point = edit.point
      this.coins = edit.coins || 0
      this.tags = edit.tags
      this.tags.map((tag) => {
        tag.id = tag.value
      })
      this.notIncludedToStatistics = edit.notIncludedToStatistics
      this.photoRequired = edit.photoRequired
      this.photoRequiredDescription = edit.photoRequiredDescription
      this.commentRequired = edit.commentRequired
      this.commentRequiredDescription = edit.commentRequiredDescription
      this.requiredViewingTime = edit.requiredViewingTime || '00:00:00'
      this.hasResults = edit.hasResults
      this.loaded = true
      this.loading = false
    })
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style>
.image-container {
  margin-bottom: 15px !important;
}

.error-parse-button-confirm {
  margin-top: 20px;
}
</style>
