import axios from '@/axios.js'
import helpers from '@/helpers/helpers.js'

export default {
  getSuite ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/achievements')
        .then((response) => {
          commit('retrieveSuite', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  trainings ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/trainings/get', { params: payload })
        .then((response) => {

          commit('retrieveTrainings', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  programmsAndChallenges ({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/achievements/categories')
        .then((response) => {
          commit('retrieveProgrammsAndChallenges', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },


  programms ({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/achievements/categories?type=programm')
        .then((response) => {
          commit('retrieveProgramms', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  events ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/achievements/categories?type=event')
        .then((response) => {
          commit('retrieveEvents', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  challenges ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin-panel/achievements/categories?type=challenge')
        .then((response) => {
          commit('retrieveChallenges', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  create ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/admin-panel/achievements', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  edit ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin-panel/achievements/${payload.id}/edit`, { params: payload })
        .then((response) => {
          commit('retrieveEdit', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/api/admin-panel/achievements/${payload.id}`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          commit('setFormError', error.response.data)
          reject(error)
        })
    })
  },
  delete ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/admin-panel/achievements/${payload.id}`)
        .then((response) => {
          commit('removeAchievement', payload.index)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
