export default {
  suite (state) {
    return state.suite
  },
  list (state) {
    return state.list
  },
  serviceList (state) {
    return state.serviceList
  },
  edit (state) {
    return state.edit
  },
  members (state) {
    return state.members
  },
  membersTrainingsCount (state) {
    return state.membersTrainingsCount
  },
  statistics (state) {
    return state.statistics
  },

  typeProgram (state) {
    return state.typeProgram
  },
  formError (state) {
    return state.formError
  }
}
