<template>
  <div id="demo-basic-card">
    <div class="">
      <div class="flex">
        <template v-if="type !== 'programm'">
          <div class="vx-col w-full mb-base flex gap-4">
            <vs-button
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-plus"
              :disabled="isOnlyRead"
              @click="$router.push({ name: `create-${type}` })"
              >{{ $t(`categories.suite.${type}.create_button`) }}</vs-button
            >
            <vs-button
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-upload"
              :disabled="isOnlyRead"
              @click="$refs.file.click()"
            >{{ $t(`categories.suite.${type}.upload_button`) }}
            </vs-button
            >
          </div>
          <input type="file" ref="file" style="display: none" @change="previewFile" />
        </template>

        <template v-else>
          <div class="vx-col md:w-1/2 w-full mb-base flex gap-4">
              <vs-button
                color="primary"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
                :disabled="isOnlyRead"
                @click="$router.push({ name: 'choose-type-category' })"
                >{{ $t(`categories.suite.${type}.create_button`) }}</vs-button
              >

              <vs-button
                color="primary"
                type="filled"
                icon-pack="feather"
                icon="icon-upload"
                :disabled="isOnlyRead"
                @click="$refs.file.click()"
              >{{ $t(`categories.suite.${type}.upload_button`) }}</vs-button
              >
              <input type="file" ref="file" style="display: none" @change="previewFile" />
          </div>
          <div class="vx-col md:w-1/2 w-full mb-base flex justify-end">
            <vs-button color="primary" type="border" icon-pack="feather" icon="icon-list" :disabled="isOnlyRead" @click="showSortTable = !showSortTable">{{
              $t('categories.suite.buttons.sort')
            }}</vs-button>
          </div>
        </template>
      </div>

      <vs-alert :active.sync="showFileName" closable icon-pack="feather" close-icon="icon-x">
        {{ fileName }}
      </vs-alert>
      <div class="demo-alignment p-3" v-if="showUploadButtons">
        <vs-button color="success" type="filled" icon-pack="feather" icon="icon-upload" @click="uploadUsersFile('add')"></vs-button>
      </div>
      <div v-if="uploading">
        <font-awesome-icon icon="spinner" spin />
      </div>

      <loading v-if="loading"></loading>

      <div v-if="!showSortTable && !loading" class="grid">
        <div class="w-full flex" v-for="category in suite" :key="category.id">
          <vx-card class="">
            <div slot="no-body" class="relative">
              <img
                :src="category.hasOwnProperty('image_path_600') ? getUrl(category.image_path_600) : getUrl(category.image_path)"
                alt="content-img"
                class="card-img card-img-top object-cover h-56 m-auto max-w-full w-full" :class="getStatus(category).status"
              />
              <p class="status-badge" :class="getStatus(category).status">{{ getStatus(category).label }}</p>
            </div>
            <h5 class="mb-2">{{ category.name }}</h5>

            <div class="flex justify-between mt-auto">
              <vs-button
                class="mt-4 mr-2 shadow-lg"
                type="gradient"
                color="#7367F0"
                gradient-color-secondary="#CE9FFC"
                @click="$router.push({ name: `edit-${type}`, params: { id: category.id } })"
                >{{ $t('categories.suite.buttons.edit') }}</vs-button
              >

              <vs-button
                v-if="type !== 'polling' && type !== 'polling_health'"
                class="pb-1"
                type="line"
                @click="$router.push({ name: `${type}-trainings`, params: { categoryId: category.id } })"
                >{{ $t('categories.suite.buttons.tasks') }}</vs-button
              >
              <vs-button
                v-else
                class="pb-1"
                type="line"
                @click="$router.push({ name: `${type}-trainings`, params: { categoryId: category.id } })"
                >{{ $t('categories.suite.buttons.questions') }}</vs-button
              >
            </div>
            <template slot="footer">
              <div class="flex justify-between mt-auto">
                <span class="flex items-center">
                  <feather-icon icon="MessageSquareIcon" svgClasses="h-5 w-5 text-primary stroke-current" />
                  <span class="ml-2">{{ category.results_count + ' ' + $t('categories.suite.statistics.results') }}</span>
                </span>
                <span class="flex items-center">
                  <feather-icon icon="SmileIcon" svgClasses="h-5 w-5 text-success stroke-current" />
                  <span class="ml-2">{{ category.favorites_count + ' ' + $t('categories.suite.statistics.participants') }}</span>
                </span>
                <span class="cursor-pointer px-3" @click="downloadCategoryJson(category.id, category.name)">
                  <font-awesome-icon :icon="['fas', 'download']"/>
                </span>
              </div>
            </template>
          </vx-card>
        </div>
      </div>

      <div v-if="showSortTable && !loading" class="sort-table-wrapper">
        <vs-list>
          <draggable :list="suite" @change="sort()">
            <transition-group>
              <vs-list-item class="list-item" v-for="category in suite" :key="category.id" :title="category.name">
                <vs-avatar
                  slot="avatar"
                  :src="category.hasOwnProperty('image_path_600') ? getUrl(category.image_path_600) : getUrl(category.image_path)"
                />
              </vs-list-item>
            </transition-group>
          </draggable>
        </vs-list>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import loading from '@/views/components/Loading.vue'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faDownload, faFileExcel, faSpinner, faUpload} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

library.add(faSpinner)
library.add(faFileExcel)
library.add(faDownload)
library.add(faUpload)

export default {
  components: {
    draggable,
    loading
  },
  data () {
    return {
      showSortTable: false,
      suite: [],
      loading: true,
      type: 'programm',
      file: null,
      showFileName: false,
      fileName: '',
      showUploadButtons: false,
      uploading: false
    }
  },
  computed: {
    isOnlyRead () {
      if (this.$store.getters.accessUser) {
        if (this.type === 'polling') {
          return this.$store.getters.accessUser['admin-pollings'] === 'read'
        }
        if (this.type === 'programm') {
          return this.$store.getters.accessUser['admin-clubs-with-program'] === 'read'
        }
      }
    }
  },
  methods: {
    downloadCategoryJson (id, name) {
      const payload = {
        id: id,
        name:name
      }
      this.$store.dispatch('category/downloadCategoryJson', payload)
    },
    previewFile (event) {
      this.file = event.target.files[0]
      this.fileName = this.file.name
      this.showFileName = true
      this.showUploadButtons = true
    },
    uploadUsersFile (type) {
      this.uploading = true
      switch (type) {
      case 'add':
        this.$store
          .dispatch('category/uploadAddJsonFile', {
            file: this.file,
            club_id: this.$store.getters['club/getActiveClubId'] ? this.$store.getters['club/getActiveClubId'] : window.localStorage.getItem('clubId')
          })
          .then((response) => {
            this.$vs.notify({
              title: this.$t('Успех'),
              text: this.$t('Программа успешно добавлена'),
              color: 'success',
              time: 10000
            })
            this.showFileName = this.showUploadButtons = false
          })
          .catch((error) => {
            const errorString = this.getErrorString(error.response.data)
            this.$vs.notify({
              title: this.$t('teams.suite.errors.title'),
              text: errorString === '' ? error : errorString,
              color: 'danger',
              time: 10000
            })
          })
          .then(() => {
            const payload = {
              type: this.type,
              club_id: this.$store.getters['club/getActiveClubId']
            }
            this.$store.dispatch('category/getSuite', payload).then(() => {
              this.suite = JSON.parse(JSON.stringify(this.$store.getters['category/suite']))
              this.uploading = false
            })
          })
        break
      }
    },
    sort () {
      const payload = {
        suite: this.suite
      }
      this.$store.dispatch('category/sort', payload)
    },
    getStatus (item) {
      const now = moment()
      const publicDate = moment(item.publication_at)
      const publicationLater = now.isBefore(publicDate)

      if (item.is_active && !publicationLater) {
        return {
          status:'active',
          label:'Активная'
        }
      }

      if (!item.is_active && !publicationLater) {
        return {
          status:'inactive',
          label:'Не активная'
        }
      }

      if (publicationLater) {
        const label = `Старт ${publicDate.format('DD.MM.YY')}`
        return {
          status:'publicationLater',
          label: label
        }
      }
    }
  },
  async created () {
    let clubId = this.$store.getters['club/getActiveClubId'] ? this.$store.getters['club/getActiveClubId'] : window.localStorage.getItem('clubId')
    switch (this.$router.currentRoute.name) {
    case 'programms':
      this.type = 'programm'
      break
    case 'pollings':
      this.type = 'polling'
      clubId = ''
      break
    case 'polling_healths':
      this.type = 'polling_health'
      clubId = ''
      break
    }

    const payload = (this.type !== 'programm') ? {type: this.type, club_id: clubId} : {club_id: clubId}


    this.$store.dispatch('category/getSuite', payload).then(() => {
      this.suite = JSON.parse(JSON.stringify(this.$store.getters['category/suite']))
      console.log('suite', this.suite)
      this.loading = false
    })
  }
}
</script>

<style lang="scss" scoped>

.buttons-wrapper {
  margin-bottom: 30px;
}
.buttons-wrapper > button {
  display: inline-block;
  margin-right: 30px;
}
.sort-table-wrapper {
  width: 100%;
}
.list-item {
  transition: all 1s;
}
.list-item:hover {
  cursor: all-scroll;
}

.card-img {
  background: #000;
  &.inactive, &.publicationLater {
    filter: brightness(60%)
  }
}

.status-badge {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background: #2D3748;
  line-height: 1.6;
  &.active {
    background-color: #DA7E3C;
  }
  &.inactive {
    background-color: #808080;
  }
  &.publicationLater {
    background-color: #07CE03;
  }
}

:deep(.vx-card__collapsible-content) {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .vx-card__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
